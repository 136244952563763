/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { contactservice } from 'app/sd-services/contactservice';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cacheservice } from 'app/sd-services/cacheservice';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import {debounceTime,map,distinctUntilChanged,filter} from "rxjs/operators";
import { Observable, Subject } from 'rxjs';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-form_team_contact_filter',
    templateUrl: './form_team_contact_filter.template.html'
})

export class form_team_contact_filterComponent extends NBaseComponent implements OnInit {

    title = 'SET FILTER';
    optionsAddBtnLabel = 'Add Label';
    sectionName = 'Define contact owner filters for team contacts';
    disableActions: boolean;
    filterOptions = [];
    // searchOption = '';
    formGroup = new FormGroup({
        searchOption: new FormControl('')
    });
    filterCount = 0;
    filters = [];
    appliedFilters = [];
    disableFilter = true
    @Input() panelTitle;
    searchTerm = new Subject<string>();

    constructor(
        private _ps: NPubSubService,
        private cs: contactservice,
        private cacheService: cacheservice
    ) {
        super();
        this.searchTerm.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                this.getFilterOptions()
            });
    }

    async ngOnInit() {
        await this.getFilterOptions();
        if (this.filterCount > 0) {
            this.disableFilter = false;
        }
    }
    close() {
        if (!this.disableActions) {
            this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
                ACTION: 'close'
            })
        }
    }

    async getFilterOptions() {
        this.formGroup.controls.searchOption.disable()
        let reportingUsers = (await this.cs.getReportingContacts()).local.result.data;
        this.formGroup.controls.searchOption.enable()
        let final_reporting_users = reportingUsers.map(u => {
            var temp = {};
            temp["tag"] = u["first_name"] + " " + u["last_name"];
            temp["user_id"] = u["userid"];
            return temp;
        })
        // this.filterOptions = (await this.cs.getContactLabels()).local.result.data;
        this.filterOptions = final_reporting_users;
        this.cacheService.getc('MY_TEAM_CONTACT_FILTER').then((result) => {
            let d = result.local.data;
            if (d) {
                this.appliedFilters = U.JSONClone(d.filters);
              
                if (this.appliedFilters.length > 0) {
                    for (let i in this.filterOptions) {
                        this.filterOptions[i]['isSelected'] = false;
                        for (let j in this.appliedFilters) {
                            if (this.filterOptions[i]['user_id'] == this.appliedFilters[j]) {
                                this.filterOptions[i]['isSelected'] = true;
                            }

                        }
                    }
                    // this.filterCount = this.appliedFilters.length;
                    // this.filters = this.appliedFilters;
                    this.disableFilter = false;
                }
            }
            // this.cacheService.setc(null, 'MY_CONTACT_FILTER');
        }).catch(e => console.log(e));
        this.filterOptions = this.filterOptions.length > 0 ? this.filterOptions.filter(o => o.tag.toLowerCase().includes(this.formGroup.controls.searchOption.value.toLowerCase())) : this.filterOptions;
        if (this.filterOptions.length > 0) {
            this.filterOptions.unshift({ tag: "Select All", user_id: "Select All" });
        } else {
            // this.filterOptions.unshift({tag: "No Label", isSelected: false});
        }
    }
    selectedFilters(event) {
        if (event.count > 0) {
            this.disableFilter = false;
            this.filterCount = event.count;
            this.filters = event.filters;
        }
    }
    async applyFilter() {
        if (this.filterCount > 0) {
            await this.cacheService.setc({ 'filters': this.filters }, 'MY_TEAM_CONTACT_FILTER');
            this._ps.$pub('refreshteamcontactlist');
            this._ps.$pub('refreshteamfilter');
            this.disableFilter = false;
            this.close()
        }
    }
}
