<div fxLayout="column wrap" fxLayoutGap="5px" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" class="lr-padding border-radius card-like" fxFlex="60px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
    <h2>
      CONTACTS</h2>
  </div>
  <div fxLayout="column wrap" class="border-radius card-like" fxFlex="calc(100%-66px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="&apos;light-white-bg&apos;" fxLayoutAlign.xs="center start" [ngClass.sm]="&apos;list-tab-sm width-100&apos;">
    <div fxLayout="row wrap" style="margin-top:10px;margin-right:10px;" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 100%&apos;" fxLayoutAlign.xs="center center" [ngClass.gt-xs]="&apos;pagination-align-gt-xs&apos;" [fxHide.xs]="true">
      <h3 *ngIf="selectedContactsCount &gt; 0" class="lr-padding">
        <div *ngIf="displayedContactsCountValue == selectedContactsCount &amp;&amp; allContactsSelected==false &amp;&amp; totalCount&gt;pageSize  ">{{selectedContactsCount}} Contacts Selected.&#xA0;<a role="button" (click)="selectAllContacts()" style="color:&apos;var(--primary-color)&apos;;cursor:&apos;pointer&apos;">Select All {{totalCount}} Contacts.</a></div>
        <div *ngIf="allContactsSelected">All {{totalCount}} Contacts Selected. <a role="button" (click)="deselectAllSelectedContacts()" style="color:&apos;var(--primary-color)&apos;;cursor:&apos;pointer&apos;">Clear Selection </a></div>
      </h3>
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" matTooltip="Add Label" *ngIf="selectedIndex==0 &amp;&amp; selectedContactsCount"><button mat-button style="margin-right:15px;" class="border-radius-4 action-button" matTooltip="Add Label" [ngStyle]="{&apos;background-color&apos;:selectedContactsCount == 0 ? &apos;#b2b2b2&apos; : &apos;var(--primary-color)&apos;,&apos;pointer-events&apos;:selectedContactsCount==0 ? &apos;none&apos; : &apos;all&apos; }" (click)="addLabel()">
          <div class="label-image"></div>
        </button></div>
      <div fxLayout="row wrap" style="padding: 0.5rem;" class="border-radius-4" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" (click)="openForm()" *ngxPermissionsOnly="[&apos;CONTACT_REASSIGN&apos;]" [ngStyle]="{ &apos;background-color&apos;:selectedContactsCount == 0 ? &apos;#b2b2b2&apos; : &apos;var(--primary-color)&apos;}" [ngClass]="{&apos;cursor-pointer&apos; : selectedContactsCount !== 0,&apos;hideDiv&apos;: selectedContactsCount == 0}" matTooltip="Re-assign Contacts">
        <div class="add-reassign-img">
        </div>
      </div>
      <div fxLayout="row wrap" style="padding: 0.6rem;margin-right:15px;" class="border-radius-4 document-margin cursor-pointer background-actual-primary" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" (click)="downloadContactasCSV()" *ngIf="selectedIndex == 0 &amp;&amp; selectedContactsCount" matTooltip="Export as CSV">
        <div class="document_info-image">
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false" *ngIf="selectedIndex == 0">
        <bh-filtercount [labelsCount]="labelsCount" *ngIf="labelsCount &gt; 0" [labels]="labels"></bh-filtercount><button mat-button class="margin-left-1 action-button" matTooltip="Set FIlter" (click)="filter()">
          <div class="contact-filter-image"></div>
        </button>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false" *ngIf="selectedIndex == 1">
        <bh-teamfiltercount [reportingUsersCount]="reportingUsersCount" [reportingUsers]="reportingUsers" *ngIf="reportingUsersCount &gt; 0"></bh-teamfiltercount><button mat-button class="margin-left-1 action-button" matTooltip="Set FIlter" (click)="teamContactFilter()">
          <div class="contact-filter-image"></div>
        </button>
      </div>
    </div>
    <mat-tab-group mat-align-tabs="start" class="width-100 h3-tab-label contact-tabs height-100" [(selectedIndex)]="selectedIndex" [ngClass.xs]="permissionsList &amp;&amp; permissionsList[&apos;HAS_TEAM&apos;] ? &apos;mobile-tab mobile-tab-label&apos; : &apos;mobile-tab&apos;" animationDuration="0" (selectedIndexChange)="tabChange($event)">
      <mat-tab label="MY CONTACTS" class="height-100" [disabled]="!ready">
        <bh-mycontacts class="height-100" [pageSize]="pageSize" [pageOffset]="pageOffset" (listRefreshed)="totalCountEvent(mycontacts, $event.value)" #mycontacts (selectedContacts)="selectedContacts($event)" (reassignContact)="reassignContact($event)" [media_view]="activeMediaQuery" (paginationEvent)="paginate($event)" [totalCountP]="totalCount" [pageSizeP]="pageSize" [pageOffsetP]="pageOffset" [readyP]="ready" (displayedContactsCount)="displayedContactsCount($event)"></bh-mycontacts>
      </mat-tab>
      <mat-tab label="TEAM CONTACTS" *ngxPermissionsOnly="[&apos;HAS_TEAM&apos;]" [disabled]="!ready">
        <bh-teamcontacts class="height-100" [pageSize]="pageSize" [pageOffset]="pageOffset" (listRefreshed)="totalCountEvent(teamcontacts, $event.value)" #teamcontacts (selectedContacts)="selectedContacts($event)" (reassignContact)="reassignContact($event)" [media_view]="activeMediaQuery" [totalCountP]="totalCount" [pageSizeP]="pageSize" [pageOffsetP]="pageOffset" [readyP]="ready" (paginationEvent)="paginate($event)" (displayedContactsCount)="displayedContactsCount($event)"></bh-teamcontacts>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>