/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChildren } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { dialogService } from '../../services/dialog/dialog.service';
import { contactservice } from 'app/sd-services/contactservice';
import { Router } from '@angular/router';
import { userservice } from 'app/sd-services/userservice';
import { labelService } from 'app/services/label/label.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { deleteService } from 'app/services/delete/delete.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { cacheservice } from 'app/sd-services/cacheservice';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';



/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-contactinfo',
    templateUrl: './contactinfo.template.html'
})

export class contactinfoComponent extends NBaseComponent implements OnInit, OnDestroy {
    @Input('contact_name') contact_name;
    @Input('contact_owner') contact_owner;
    @Input('contactid') contactid;
    @Input('accessDenied') accessDenied = true;
    @Output() notesTrigger = new EventEmitter();
    @Input('contact_owner_id') contact_owner_id;
    @ViewChildren('tooltipMobile') tooltipMobile;
    labels;
    labelsRefreshSub;
    labelsTooltip = [];
    labelsMobileTooltip = [];

    // canDelete = false;
    constructor(private dialog: dialogService, private contactservice: contactservice, private router: Router,
        public userservice: userservice, private labelService: labelService, private snackBar: MatSnackBar,
        private deleteService: deleteService,private cacheService: cacheservice, private pubsub: NPubSubService) {
        super();
    }

    ngOnInit() {
        // add sub to refresh labels
        this.labelsRefreshSub = this.pubsub.$sub('refreshlabelslist', () => {
            this.cacheService.getc('CONTACT_LABELS').then((result) => {
            let d = result.local.data;
            if (d) {
                this.labels = U.JSONClone(d.contact_labels);
                if (this.labels && this.labels.length > 3) {
                    this.labelsTooltip = [];
                    for ( let i = 3; i < this.labels.length; i++) {
                        this.labelsTooltip.push(this.labels[i]['label']);
                    }
                }
                if (this.labels && this.labels.length > 1) {
                    this.labelsMobileTooltip = [];
                    for ( let i = 1; i < this.labels.length; i++) {
                        this.labelsMobileTooltip.push(this.labels[i]['label']);
                    }
                }
            }
            // this.cacheService.setc(null, 'CONTACT_LABELS');
        }).catch(e => console.log(e));
        });
    }

    
    initialCall() {
        if (this.contactid) {
            this.dialog.makeCallDialog({
                modulename: 'contacts',
                moduleid: this.contactid
            });
        }
    }
    addLabel() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'CONTACT_LABEL'
        })
    }

    deleteContact() {
        if (this.contactid) {
            this.deleteService.deleteContact(this.contactid);
        }
    }
    
    notes(){
        this.notesTrigger.emit();
    }
    tooltipToggle() {
        this.tooltipMobile._results['0'].show()
    }
    ngOnDestroy() {
        if (this.labelsRefreshSub) {
            this.labelsRefreshSub.unsubscribe();
        }
    }
}
