<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <bh-sidenavformheader [title]="title" [titleIconUrl]="&apos;assets/Web/Icons/contact_filter.svg&apos;" (closeClicked)="close()"></bh-sidenavformheader>
  <form style="height: calc(100% - 115px);">
    <bh-formsectiontitle [sectionName]="sectionName"></bh-formsectiontitle>
    <div fxLayout="column " style="height: calc(100% - 50px);" class="height-100 lr-padding" fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column " class="padding-right-1 height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
          <input matInput placeholder="Search Labels" [required]="false" (input)="getFilterOptions()" [formControl]="formGroup.controls?.searchOption">
          <mat-label>Label</mat-label>
        </mat-form-field>
        <bh-filteroptionsconfig [filterOptions]="filterOptions" (filters)="selectedFilters($event)" [selectedFilters]="appliedFilters" [beingUsedInTeamFilter]="false"></bh-filteroptionsconfig>
      </div>
    </div>
  </form>
  <div fxLayout="row wrap" style="padding-right: 2em !important;" class="padding-1" fxLayoutGap="1rem" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="primary-action-button" (click)="applyFilter()" [disabled]="disableFilter">Apply</button><button mat-button class="secondary-action-button" (click)="close()" [disabled]="disableActions">Cancel</button></div>
</div>