/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { callcontactsservice } from 'app/sd-services/callcontactsservice';
import { leadservice } from 'app/sd-services/leadservice';
import { confirmpopupComponent } from '../../components/confirmpopupComponent/confirmpopup.component';
// import { callcontactsservice } from 'app/sd-services/callcontactsservice';
import { delete_dialogComponent } from 'app/components/delete_dialogComponent/delete_dialog.component';

import { ldcallComponent, LDCallDialogData, ModulePhoneNumber } from '../../components/ldcallComponent/ldcall.component';
import { genericdialogComponent } from '../../components/genericdialogComponent/genericdialog.component';


@Injectable()
export class dialogService {
    mobileValue: LDCallDialogData = <LDCallDialogData>{};
    constructor(public dialog: MatDialog,
        private leadS: leadservice,
        private callContactsService: callcontactsservice) {
    }

    makeCallDialog(options: {
        modulename: string,
        moduleid: number, // contact id
        contactid?: number, // another module id, currently dealid
        dealid?: number
    }) {

        this.callContactsService.getmodulephonenumbers(options.modulename, options.moduleid, options.contactid)
            .then(result => {
                const phoneNoData = <ModulePhoneNumber[]>result.local.phoneValue.data;
                if (result && phoneNoData) {
                    this.mobileValue['phoneNumber'] = phoneNoData;
                    this.mobileValue['moduleid'] = options.moduleid;
                    this.mobileValue['modulename'] = options.modulename;
                    this.mobileValue['phoneData'] = '';
                    this.mobileValue['dealid'] = options.dealid;
                    this.mobileValue['contactid'] = options.contactid;
                    this.callDialog(this.mobileValue)
                }
            }).catch((err) => {
                this.mobileValue['phoneData'] = err.error.message;
                this.mobileValue['phoneNumber'] = null;
                return this.callDialog(this.mobileValue);
            });
    }

    callDialog(value) {
        return this.dialog.open(ldcallComponent, {
            width: '19em',
            panelClass: 'dialogData',
            hasBackdrop: true,
            data: value,
            autoFocus: false
        }).afterClosed();
    }

    taskDeleteDialog(value) {
        var temp = {
            title: 'Delete the Task?',
            name: 'Task',
            idValue: value
        }
        return this.dialog.open(confirmpopupComponent, {
            width: '19em',
            panelClass: 'dialogData',
            hasBackdrop: true,
            data: temp,
            autoFocus: false
        }).afterClosed();
    }

    appointmentDeleteDialog(value) {
        var temp = {
            title: 'Cancel the appointment?',
            name: 'Appointment',
            idValue: value
        }
        return this.dialog.open(confirmpopupComponent, {
            width: '19em',
            panelClass: 'dialogData',
            hasBackdrop: true,
            data: temp,
            autoFocus: false
        }).afterClosed();
    }

    documentDeleteDialog(value) {
        var temp = {
            title: 'Delete the Document?',
            name: 'Document',
            idValue: value
        }
        return this.dialog.open(confirmpopupComponent, {
            width: '19em',
            panelClass: 'dialogData',
            hasBackdrop: true,
            data: temp,
            autoFocus: false
        }).afterClosed();
    }

    openDialog(temp) {
        return this.dialog.open(genericdialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'sortDialog',
            hasBackdrop: false,
            data: temp,
            autoFocus: false
        }).afterClosed();
    }

    openConfirmationDialog(opts: ConfirmOpts, data: any = {}) {
        data = {
            ...opts,
            ...data
        };
        return this.dialog.open(confirmpopupComponent, {
            width: '19rem',
            panelClass: 'dialogData',
            hasBackdrop: true,
            data: data,
            autoFocus: false,
            disableClose: data.disableClose 
        }).afterClosed();
    }

    deleteContactOrDeal(msg) {
        return this.dialog.open(delete_dialogComponent, {
            data: msg,
            width: '19rem',
        }).afterClosed();
    }

}

export interface ConfirmOpts {
    header?: string,
    title?: string,
    subtitle?: string,
    dangerWarning?: string,
    name?: string,
    yes?: any,
    no?: any
}

