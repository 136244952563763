/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CacheKey, PubSubChannel } from 'app/constants';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { udfservice } from 'app/sd-services/udfservice';
import { usernotificationservice } from 'app/sd-services/usernotificationservice';
import { labelService } from 'app/services/label/label.service';
import { udfsyncService } from 'app/services/udfsync/udfsync.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { UDFConfig } from '../udf_fieldComponent/udf_field.component';
import { UDFSectionConfig } from '../udf_sectionComponent/udf_section.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-setting_dealmodule',
	templateUrl: './setting_dealmodule.template.html',
	styleUrls: ['./setting_dealmodule.style.scss']
})

export class setting_dealmoduleComponent extends NBaseComponent implements OnInit, OnDestroy {
	tabAddBtnMap = {
		'SECTIONS & FIELDS': {
			label: 'Add Section',
			chl: PubSubChannel.udfAddSection
		},
		'FIELD DEPENDENCIES': {
			label: 'Add Dependency',
			chl: PubSubChannel.udfAddDep
		},
	}
	tableLabel = 'SECTIONS & FIELDS';
	addBtnLabel = this.tabAddBtnMap[this.tableLabel].label;
	udfSections: UDFSectionConfig[];
	disableActions = true;
	header: string;

	private _modulename = 'deal';
	private _udfsectionsBackup: UDFSectionConfig[] = [];
	depFields: UDFConfig[] = [];

	constructor(private _pubsub: NPubSubService,
		private _udfs: udfservice,
		private _udfSync: udfsyncService,
		private _uns: usernotificationservice,
		public _label: labelService) {
		super();
		this._udfs.getTenantModuleUdfs(this._modulename).then((d) => {
			this.udfSections = d.local.udfs;
			this._udfsectionsBackup = U.JSONClone(this.udfSections);
			this._udfSync.setc(this.udfSections, CacheKey.entireUDFsConfig)
			this.depFields = this._udfSync.refreshUDFDependencyConfig(this.udfSections).depFields;
			this.disableActions = false;
		})
	}

	ngOnInit() {
		this.header = `DEAL (${this._label.deal.toUpperCase()}) MODULE`;
	}

	async save(configs: UDFSectionConfig[]) {
		this.disableActions = true;
		try {
			this.depFields = this._udfSync.refreshUDFDependencyConfig(configs).depFields;
			const udf_metadata = this._getSortOrderedUDFs(configs);
			const bh = await this._udfs.putTenantModuleUdfs(udf_metadata, this._modulename);
			this._udfsectionsBackup = U.JSONClone(configs);
		} catch (e) {
			this.udfSections = U.JSONClone(this._udfsectionsBackup);
			this.depFields = this._udfSync.refreshUDFDependencyConfig(this.udfSections).depFields;
			console.error('udf put err:', e);
			this._uns.openSnackbar('Could not save the changes');
		}
		this.disableActions = false;
	}

	private _getSortOrderedUDFs(configs: UDFSectionConfig[]) {
		// IMPORTANT: Offset is being calculated assuming that there is only one "Default" section and
		// it is ALWAYS the first section
		const sortOffset = configs[0].fields.filter(f => f.default).length;

		return this.udfSections.flatMap(sec => sec.fields).filter(f => !f.default).map((_, order) => {
			_.field_sort_order = order + sortOffset;
			return _;
		});
	}

	tabChange(e: MatTabChangeEvent) {
		this.tableLabel = e.tab.textLabel;
		this._toggleAddBtnLabel();
	}

	private _toggleAddBtnLabel() {
		this.addBtnLabel = this.tabAddBtnMap[this.tableLabel].label;
	}

	addBtnClick() {
		this._pubsub.$pub(this.tabAddBtnMap[this.tableLabel].chl, {});
	}

	ngOnDestroy() {
		this._udfSync.deletec(CacheKey.entireUDFsConfig);
		this._udfSync.fieldNameToFieldMap = null;
	}
	editModule() {
        this._pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'SETTING_MODULE_RENAME'
        })
    }
}
