/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormControl } from '@angular/forms';
import { PhoneCountryCode } from '../phonecountrycodeselectComponent/phonecountrycodeselect.component';
import { srmappservice } from 'app/sd-services/srmappservice';
import { tenantservice } from 'app/sd-services/tenantservice';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-nationalityselect',
	templateUrl: './nationalityselect.template.html'
})

export class nationalityselectComponent extends NBaseComponent implements OnInit {

	@Input('nationalityFormControl') fc = new FormControl('');
	@Input('placeholder') placeholder = '';
	@Input('options') phoneCoutryCodes: PhoneCountryCode[] = [];
	@Input('valueMapsTo') vMap = '';
	@Input('viewMapsTo') vwMap = '';

	constructor(private srmappS: srmappservice,
		private tenantS: tenantservice) {
		super();
	}

	async ngOnInit() {
		await this.getPhoneCountryCodes();
		this.phoneCoutryCodes.unshift({
			country: '',
			nationality: '',
			phone_country_code: ''
		});
	}

	async getPhoneCountryCodes() {
		try {
			this.phoneCoutryCodes = (await this.srmappS.getPhoneCountryCodes()).local?.phonecountrycodes.filter((p: PhoneCountryCode) => p.nationality);
			const tenantSettings = (await this.tenantS.getTenantSettings()).local.tenantSettings;
			// if (!this.fc.value && tenantSettings && tenantSettings.PHONE_COUNTRY_CODE) {
			// 	this.fc.setValue(U.findFirst(this.phoneCoutryCodes, tenantSettings.PHONE_COUNTRY_CODE, 'phone_country_code', this.vMap || 'nationality'));
			// } else {
			// 	console.log('No default country code set for the tenant');
			// }
		} catch (e) {
			console.error(e);
		}
	}
}
