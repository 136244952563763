/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Validators, FormBuilder } from '@angular/forms';


export interface Note {
    title: string;
    date: string;
    content: string;
    options?: any
}

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-notes',
    templateUrl: './notes.template.html'
})

export class notesComponent extends NBaseComponent implements OnInit {
    @Input('notes') notes = []; // Note[]
    @Output('newNote') newNote = new EventEmitter();
    @Output('deletedNote') deletedNote = new EventEmitter();
    @Output('editedNote') editedNote = new EventEmitter();
    @Input('accessDenied') accessDenied = false;
    @Output('exitNotes') exitNotes = new EventEmitter();
    @Output('editedPinned') editedPinned = new EventEmitter();
    @Output('editedUnpinned') editedUnpinned = new EventEmitter();
    notesAction;
    showNotesEditor = false;
    currentNote;
    count = 0;
    sequence;
    pinnedData;
    pinValue;
    


    addNotesFormGroup = this.fb.group({
        notes: ['', Validators.required]
    })
    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit() {
    }

    newAddNotes() {
        this.notesAction = 'Add Note'
        this.showNotesEditor = true;
        this.addNotesFormGroup.patchValue({ notes: '' });
    }

    addNewNote() {
        if (this.currentNote) {
            this.editedNote.emit({
                data: this.currentNote,
                note: this.addNotesFormGroup.value.notes
            });
            this.currentNote = null;
        } else {
            this.newNote.emit({
                note: this.addNotesFormGroup.value.notes,
                pinnedSequence: 0
            })
        }

        this.addNotesFormGroup.patchValue({
            notes: ''
        });
        this.showNotesEditor = false;
    }

    deleteNote(note) {
        this.deletedNote.emit({
            data: note
        })
    }

    close() {
        this.showNotesEditor = false;
        this.currentNote = null;
    }

    back() {
        this.exitNotes.emit();
    }

    updateNotes(value) {
        this.notesAction = 'Update Note';
        this.showNotesEditor = true;
        this.addNotesFormGroup.patchValue({ notes: value.notes });
        this.currentNote = value;
    }

    addPinsequence(){
        this.editedPinned.emit({
                data: this.currentNote,
                note: this.addNotesFormGroup.value.notes
            });
            this.currentNote = null;
            this.addNotesFormGroup.patchValue({
            notes: ''
        });
        this.showNotesEditor = false;
    }

    addUnpinsequence(){
        this.editedUnpinned.emit({
                data: this.currentNote,
                note: this.addNotesFormGroup.value.notes,
                pinnedSequence: 0
            });
            this.currentNote = null;
            this.addNotesFormGroup.patchValue({
            notes: ''
        });
        this.showNotesEditor = false;
    }

    togglePin(notesElement) {
      let currentNote = this.notes.find(a=> a.activitynotesid === notesElement.activitynotesid)
        currentNote.ispinned = notesElement.ispinned ? false : true;

       if(currentNote.ispinned === true) {
           this.addNotesFormGroup.patchValue({ notes: notesElement.notes});
            this.currentNote = notesElement;
            this.addPinsequence();
        } else if (currentNote.ispinned === false) {
            this.addNotesFormGroup.patchValue({ notes: notesElement.notes});
            this.currentNote = notesElement;
            this.addUnpinsequence();
        }
    }
}
