<div fxLayout="column " fxLayoutGap="5px" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="row " class="card-like border-radius lr-padding" fxFlex="60px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <h2>
      SETTINGS</h2>
  </div>
  <div fxLayout="column wrap" class="border-radius card-like" fxFlex="calc(100%-66px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxLayoutAlign="end end" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 100%&apos;" fxLayoutAlign.xs="center center" [ngClass.gt-xs]="&apos;pagination-align-gt-xs&apos;">
      <div fxLayout="row wrap" class="pagination-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" style="padding: 0.5rem;" class="cursor-pointer background-actual-primary border-radius" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" (click)="openForm()" matTooltip="Add User">
          <div class="add-person-img">
          </div>
        </div>
        <div [ngClass]="(!progressCheck) ? &apos;icon-disable&apos; : &apos;&apos; " class="common-pagination">
          <mat-paginator [length]="totalCount" [pageIndex]="pageOffset" [pageSize]="pageSize" [pageSizeOptions]="[50, 200, 500]" (page)="pageEvents($event)"></mat-paginator>
        </div>
      </div>
    </div>
    <mat-tab-group mat-align-tabs="start" class="h3-tab-label contact-tabs height-100" animationDuration="0" (selectedTabChange)="tabChange($event)">
      <mat-tab label="USERS" class="height-100">
        <bh-users (pageData)="updatePageData($event)"></bh-users>
      </mat-tab>
      <mat-tab label="ROLES">
        <bh-roles (pageData)="updatePageData($event)"></bh-roles>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>