/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { dealservice } from 'app/sd-services/dealservice';
import { searchservice } from 'app/sd-services/searchservice';
import { userservice } from 'app/sd-services/userservice';
import { labelService } from 'app/services/label/label.service';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { DYN_SEARCH_INPUT_MODE } from '../dyncontactsearchComponent/dyncontactsearch.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-dyndealssearch',
	templateUrl: './dyndealssearch.template.html',
	exportAs: 'dealSearch'
})

export class dyndealssearchComponent extends NBaseComponent implements OnInit {

	@Input('fc') fc = new FormControl();
	@Input('placeholder') placeholder = '';
	@Input() defaultdeals = [];
	@Input() label = '';
	@Input('dealContactId') _dealContactId: number;
	@Input('mode') mode: DYN_SEARCH_INPUT_MODE = 'EDIT';
	@Input() value: number;
	@Input() disable = false;

	@Output() resultSelected = new EventEmitter();
	@Output('navigationInit') navigationInitiated = new EventEmitter();

	@ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
	@ViewChild('fci') fci;

	deals = [];
	filteredDeals: DealsSearch[] = <DealsSearch[]>[];
	intializationComplete = false;
	public dwbind = null;
	selectedid: number;

	private _changesForSearchSub: Subscription;
	private _userId: number;
	private _pageSize = 10;
	private _pageOffset = 0;
	private searchInProgress = false;
	private _baseUrl = '/home/deals/';
	private _setErrSub: Subscription;

	constructor(
		private userService: userservice,
		private searchService: searchservice,
		private dealService: dealservice,
		public labelS: labelService,
		private router: Router,
		private z: NgZone
	) {
		super();
		this.userService.getcu().then((result: bh) => this._userId = result.local.user.srmUserid).catch(e => { })
	}

	ngOnInit() {
		this._changesForSearchSub = this.fc.valueChanges.pipe(
			debounceTime(300),
			tap(v => {
				if (v && !this.searchInProgress && this.intializationComplete) {
					this.search(v, this._dealContactId);
				}
			})
		).subscribe();
		this._setErrSub = this.fc.valueChanges.subscribe((v) => this._setFCErrs(v));
		this.dwbind = this.displayWith.bind(this);
	}

	private _setFCErrs(v: string | number) {
		const dnsErr = (v && typeof v !== 'number') ? { invalidDeal: "Deal not selected" } : null;
		const er = { ...this.fc.errors, ...dnsErr };
		this.fc.setErrors(Object.keys(er).length ? er : null);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.fc && this.fc.value &&
			!(changes.defaultdeals && changes.defaultdeals.currentValue)) {
			this.fetchAndSetDealByID(this.fc.value)
		} else {
			this.intializationComplete = true;
		}
		if (changes.defaultdeals && changes.defaultdeals.currentValue instanceof Array && changes.defaultdeals.currentValue.length) {
			this.filteredDeals = changes.defaultdeals.currentValue;
			this.intializationComplete = true;
			this.fc.setValue(this.fc.value);
		}
		if (changes.disable) {
			this._setDisabled(changes.disable.currentValue);
		}
		if (changes.value) {
			this._setValue(changes.value.currentValue);
		}
	}

	private _setDisabled(d: boolean) {
		if (this.fc) {
			!!d ? this.fc.disable() : this.fc.enable();
		}
	}

	private _setValue(v: number) {
		if (this.fc) {
			if (!v) {
				this.fc.setValue('');
			} else {
				this.setValueBasedOnId(v);
			}
		}
	}

	search(searchValue: string, searchForContactId?: number, searchForUserId?: number,) {
		if (!searchValue) {
			this.filteredDeals = [];
		}
		if (this.mode === 'EDIT' && (searchForUserId || this._userId) && typeof searchValue !== 'number') {
			this.searchInProgress = true;
			this.searchService.contactDealSearch(searchForUserId || this._userId, searchValue,
				this._pageSize, this._pageOffset, null, true, null, true, searchForContactId || this._dealContactId).then((result: bh) => {
					if (result.local.searchList && result.local.searchList.data && result.local.searchList.data.deals) {
						this.filteredDeals = <DealsSearch[]>result.local.searchList.data.deals;
					} else {
						this.filteredDeals = [];
					}
					this.searchInProgress = false;
				}).catch(e => this.searchInProgress = false)
		}
	}

	setValueBasedOnId(id: number) {
		if (this.fc && typeof id === 'number') {
			const found = this.filteredDeals.find(c => c.dealid === id)
			if (Array.isArray(this.filteredDeals) && found) {
				this.fc.setValue(id);
				this.selectedid = id;
			} else {
				this.fetchAndSetDealByID(id);
			}
		}
	}

	displayWith(dealid: number) {
		if (dealid) {
			const k = this.filteredDeals.find(v => {
				return v.dealid == dealid;
			})
			if (k) {
				return k.deal_name;
			}
		}
		return null;
	}

	autoCompleteFocused() {
		// TODO: @sankarshanaj - do not use internal "_onChange"
		if (this.fc.value === '' || this.fc.value === undefined || this.fc.value === null) {
			this.trigger._onChange("");
			this.trigger.openPanel();
		} else if (this.fc.value) {
			this.trigger._onChange(this.fc.value);
			this.trigger.openPanel();
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.selectedid = event.option.value;
		this.resultSelected.next(event.option.value)
	}

	navigateToContactDetails(e) {
		e.preventDefault();
		e.stopPropagation();
		if (this.selectedid) {
			const url = this._baseUrl + this.selectedid + '/contact/' + this._dealContactId;
			this.navigationInitiated.next({
				url: url,
				dealid: this.selectedid
			});
			this.router.navigate([url]);
		}
	}

	fetchAndSetDealByID(v: number) {
		if (typeof v === 'number' && v) {
			this.dealService.getDealById(v, this._dealContactId).then((result: bh) => {
				if (result.local.dealsValue) {
					const d = result.local.dealsValue;
					this.filteredDeals.push(d);
					this.selectedid = this.fc?.value || d.dealid;
					this.fc.setValue(this.selectedid);
				}
				this.intializationComplete = true;
			}).catch(e => console.log(e))
		}
	}

	clear() {
        this.z.run(() => {
            this.fc.setValue('');
            this.fci.nativeElement.focus();
            setTimeout(() => {
                this.trigger.openPanel();
            }, 100);
        });
    }

	ngOnDestroy() {
		if (this._changesForSearchSub) {
			this._changesForSearchSub.unsubscribe();
		}
		if (this._setErrSub) {
			this._setErrSub.unsubscribe();
		}
	}
}

interface bh {
	input: {},
	local: {
		dealsValue?: any,
		searchList?: {
			data: {
				deals: any[]
			}
		},
		user?: {
			srmUserid: number
		}
	}
}

interface DealsSearch {
	contact_display_name: string,
	contact_owner_name: string,
	count: string,
	deal_name: string,
	dealid: number,
}

// Samplae Deal by Id object
/*
{
	contact_created_by: 89
	contact_display_name: "can i add"
	contact_email_id: ""
	contact_first_name: "can i"
	contact_last_name: "add"
	contact_last_updated_at: "2020-08-05T08:46:04.080Z"
	contact_owner_id: 89
	contact_owner_name: "Sankarshan Joshi"
	contact_owner_phone_country_code: "+91"
	contact_owner_phone_no: "9535405531"
	contact_owner_username: "sankarshan.joshi@neutrinos.co"
	contact_phone_country_code: "+91"
	contact_phone_no: "923932"
	contact_updated_at: "2020-08-05T08:46:04.080Z"
	contactid: 338
	currencyid: 12
	deal_created_at: "2020-08-05T10:14:40.947Z"
	deal_created_by: null
	deal_name: "Once In a Lifetime"
	deal_pipe_name: "New"
	deal_pipeid: 1
	deal_status: "Ongoing"
	deal_status_updated_at: "2020-08-05T10:14:40.947Z"
	deal_udf: {}
	deal_updated_at: "2020-08-05T10:14:40.947Z"
	deal_value: "29892999"
	dealid: 304
	tenantid: 1
	udf_data: [{...}]
}
*/