/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { tenantservice } from 'app/sd-services/tenantservice';
import { FormControl, Validators } from '@angular/forms';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-setting_vcall_msg',
    templateUrl: './setting_vcall_msg.template.html',
    styleUrls: ['../setting_currComponent/setting_curr.style.scss']

})

export class setting_vcall_msgComponent extends NBaseComponent implements OnInit {
    vcall_msg: FormControl = new FormControl('', [Validators.required]);
    disableAddButton = false;
    constructor(
        private pubsub: NPubSubService,
        private tenantService: tenantservice,

    ) {
        super();
    }

    ngOnInit() {
        //set existing setting value in UI 
        this.tenantService.getTenantSettings()
            .then(result => {

                if (result.local.tenantSettings &&
                    result.local.tenantSettings.RECORDING_TALK_MESSAGE &&
                    !this.vcall_msg.value) {
                    this.vcall_msg.setValue(result.local.tenantSettings.RECORDING_TALK_MESSAGE);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }
    saveSetting() {

        if (this.vcall_msg.valid) {
            this.disableAddButton = true;
            this.tenantService.setTenantSettingValue('RECORDING_TALK_MESSAGE', this.vcall_msg.value).then((data) => {
                this.pubsub.$pub('refresh-settings');
                this.close();
            }).catch(err => {
                this.disableAddButton = false;
            })
        }

    }
}
