/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { labelService } from 'app/services/label/label.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { NgxPermissionsService } from 'ngx-permissions';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-settings',
	templateUrl: './settings.template.html'
})

export class settingsComponent extends NBaseComponent implements OnInit {

	settings: SectionConfig[] = [];
	private _settingsBP = '/home/settings';

	private _sidenavChl = 'sidenavformevents'

	constructor(private _router: Router,
		public permissionService: NgxPermissionsService,
		private _labels: labelService,
		private _pubsub: NPubSubService,
		private acRoute: ActivatedRoute) {
		super();
	}

	ngOnInit() {
		this.populateSettingsConfig();
	}

	populateSettingsConfig() {
		const settings: SectionConfig[] = [
			{
				section: 'USER MANAGEMENT',
				setting: [
					{
						label: 'Users & Roles',
						relPath: 'user-and-roles',
						icon: 'settings-user_and_roles.svg',
						permissions: ['SETTINGS_ADMIN']
					}
				]

			},
			{
				section: 'MODULE MANAGEMENT',
				setting: [
					{
						label: 'Contact Module',
						relPath: 'contact-module',
						icon: 'settings-contact_module.svg',
						permissions: ['SETTINGS_ADMIN']
					},
					{
						label: `Deal (${this._labels.toTitleCase(this._labels.deal)}) Module`,
						relPath: 'deal-module',
						icon: 'settings-deal_module.svg',
						permissions: ['SETTINGS_ADMIN'],
						sidenavform: 'SETTING_MODULE_RENAME'
					}
				]

			},
			{
				section: 'SYSTEM SETTINGS',
				setting: [
					{
						label: 'Theme & Logo',
						sidenavform: 'UPD_THEME',
						icon: 'theme_logo.svg',
						permissions: ['SETTINGS_ADMIN']
					},
					{
						label: 'Default Currency',
						sidenavform: 'SETTING_CURRENCY',
						icon: 'dollar.svg',
						permissions: ['SETTINGS_ADMIN']
					},
					{
						label: 'Phone Country Code',
						sidenavform: 'SETTING_PHONE_COUNTRY',
						icon: 'phone-call.svg',
						permissions: ['SETTINGS_ADMIN']
					},
					{
						label: 'Feedback Outcome',
						sidenavform: 'SETTING_FEEDBACK_OUTCOMES',
						icon: 'settings-feedback_outcomes.svg',
						permissions: ['SETTINGS_ADMIN']
					},
					{
						label: `Deal (${this._labels.toTitleCase(this._labels.deal)}) Pipeline`,
						sidenavform: 'SETTING_DEAL_PIPE',
						icon: 'settings-feedback_outcomes.svg',
						permissions: ['SETTINGS_ADMIN']
					},
					{
						label: 'Voice Call Recording Message',
						sidenavform: 'SETTING_VOICE_CALL_REC',
						icon: 'microphone_white.svg',
						permissions: ['SETTINGS_ADMIN']
					}
				]

			}
		]
		this.settings = this.filterPermissions(settings);
		/* 
		// Uncomment this if you are too lazy to test if every section's label is title cased.
		.map(section => {
			(section.setting || []).map(setting => {
				setting.label = this._labels.toTitleCase(setting.label);
				return setting;
			});
			return section;
		});
		*/;
	}

	filterPermissions(settings: SectionConfig[]): SectionConfig[] {
		const ngxPermissions: Object = this.permissionService.getPermissions();
		return settings.filter(v => {
			// return ngxPermissions.hasOwnProperty(v.);
			for (let j = 0; j < v.setting.length; j++) {
				if (v.setting[j].permissions instanceof Array) {
					for (let i = 0; i < v.setting[j].permissions.length; i++) {
						if (ngxPermissions.hasOwnProperty(v.setting[j].permissions[i])) {
							return true;
						}
					}
					return false;
				}
			}
			return true;
		})
	}

	openSetting(setting: SectionConfig, e: MouseEvent | KeyboardEvent) {
		(<HTMLElement>e.target).blur();
		if (setting['relPath']) {
			this._router.navigate([setting['relPath']], {
				relativeTo: this.acRoute
			});
		} else if (setting['sidenavform']) {
			this._pubsub.$pub(this._sidenavChl, <SideNavFormEventData>{
				ACTION: 'open',
				PAGE: setting['sidenavform']
			})
		} else {
			console.warn('This setting does not have any form or path associated with it');
		}
	}
}

interface SectionConfig {
	section: string,
	setting: SettingConfig[]
}
interface SettingConfig {
	label: string,
	icon: string,
	relPath?: string,
	sidenavform?: string,
	permissions?: string[]
}
