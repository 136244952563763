/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { mycontactsComponent } from 'app/components/mycontactsComponent/mycontacts.component';
import { teamcontactsComponent } from 'app/components/teamcontactsComponent/teamcontacts.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { cacheservice } from 'app/sd-services/cacheservice';
import { environment } from 'environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { sortdailogService } from 'app/services/sortdailog/sortdailog.service';
import { contactservice } from 'app/sd-services/contactservice';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-contacts',
    templateUrl: './contacts.template.html'
})

export class contactsComponent extends NBaseComponent implements OnInit, OnDestroy {
    pageSize = 50;
    pageOffset = 0;
    totalCount = 0;
    displayedContactsCountValue=0
    ready = false;
    allContactsSelected = false
    selectedIndex = 0;
    sortCache = [];
    selectedContactsCount = 0;
    selectedContactsObj = {};
    permissionsList;
    watcher: Subscription;
    activeMediaQuery = '';
    labelsCount = 0;
    labels = [];
    reportingUsersCount = 0;
    reportingUsers = [];
    filterRefreshSub;
    teamfilterRefreshSub
    mycontactsList = [{ name: 'Name (A to Z)', key: 'contact_name', sort_type: 'ASC' }, { name: 'Name (Z to A)', key: 'contact_name', sort_type: 'DESC' },
    { name: 'Last Updated Date (Newest First)', key: 'updated_at', sort_type: 'DESC' }, { name: 'Last Updated Date (Oldest First)', key: 'updated_at', sort_type: 'ASC' }];
    teamcontactsList = [{ name: 'Name (A to Z)', key: 'display_name', sort_type: 'ASC' }, { name: 'Name (Z to A)', key: 'display_name', sort_type: 'DESC' },
    { name: 'Last Updated Date (Newest First)', key: 'updated_at', sort_type: 'DESC' }, { name: 'Last Updated Date (Oldest First)', key: 'updated_at', sort_type: 'ASC' }];

    @ViewChild(mycontactsComponent) mycontacts: mycontactsComponent;
    @ViewChild(teamcontactsComponent) teamcontacts: teamcontactsComponent;

    constructor(private _pubSub: NPubSubService,
        private cacheService: cacheservice,
        private ngxPermissionsService: NgxPermissionsService,
        private mediaObserver: MediaObserver,
        private sortdialogservice: sortdailogService,
        private pubsub: NPubSubService,
        private contactService: contactservice) {
        super();
        this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;
            this.pageOffset = 0;
            this.pageSize = 50;
            this.setSortChache(this.pageSize, this.pageOffset);
        });
    }

    async ngOnInit() {
        await this.cacheService.setc({ 'index': 0}, 'CONTACT_TAB_INDEX');
        this._pubSub.$pub('actionlist', { list: 'contacts' });
        this.setSortChache(this.pageSize, this.pageOffset);
        this.ngxPermissionsService.permissions$.subscribe((permissions) => {
            this.permissionsList = permissions;
        })
        // sub for refresh filter count
        this.cacheService.setc(null, 'MY_CONTACT_FILTER');
        this.cacheService.setc(null, 'MY_TEAM_CONTACT_FILTER');
        this.filterRefreshSub = this._pubSub.$sub('refreshfilter', () => {
            this.cacheService.getc('MY_CONTACT_FILTER').then((result) => {
                let d = result.local.data;
                if (d) {
                    this.labelsCount = d.filters.length;
                    this.labels = d.filters;
                } else {
                    this.labelsCount = 0;
                    this.labels = [];
                }
                // this.cacheService.setc(null, 'APPLY_CONTACT_FILTER');
            }).catch(e => console.log(e));
        });
        this._pubSub.$pub('refreshfilter');


        this._pubSub.$sub('allContactsSelected', (res) => {
            if (res.allContactsSelected == 'true') {
                this.allContactsSelected = true
                localStorage.setItem('allContactsSelected', 'true');
            } else if (res.allContactsSelected == 'false') {
                this.allContactsSelected = false
                localStorage.setItem('allContactsSelected', 'false');
            }
        })

        this.teamfilterRefreshSub = this._pubSub.$sub('refreshteamfilter', () => {
            this.cacheService.getc('MY_TEAM_CONTACT_FILTER').then((result) => {
                let d = result.local.data;
                if (d) {
                    this.reportingUsersCount = d.filters.length;
                    this.reportingUsers = d.filters;
                } else {
                    this.reportingUsersCount = 0;
                    this.reportingUsers = [];
                }
                // this.cacheService.setc(null, 'APPLY_CONTACT_FILTER');
            }).catch(e => console.log(e));
        });
        this._pubSub.$pub('refreshteamfilter');

    }
    paginate($event){
        this.pageEvents($event);
    }
    selectAllContacts(){        
        this.allContactsSelected=true;
        localStorage.setItem('allContactsSelected','true');
        localStorage.setItem('usersChoiceSelectAll','true')
    }
    deselectAllSelectedContacts(){
        this.allContactsSelected=false;
        localStorage.setItem('allContactsSelected','false');
        localStorage.setItem('usersChoiceSelectAll','false')
        this.mycontacts.resetSelectedContacts()
        this.teamcontacts.resetSelectedContacts()
    }

    pageEvents($event) {
        this.pageSize = $event.pageSize;
        this.pageOffset = $event.pageIndex;
        this.refresh(this.pageSize, this.pageOffset);
        this.setSortChache(this.pageSize, this.pageOffset);
    }

    /**
     * 
     * @param c template reference of the component
     */
    totalCountEvent(c: mycontactsComponent | teamcontactsComponent, event) {
        if (event == false && this.selectedIndex == 0) {
            this.ready = event;
        }
        if (!this.ready) {
            this.ready = true;
            this.totalCount = c.totalContacts;
        }
        if (event == true && this.selectedIndex == 1) {
             this.ready = event;
             this.totalCount = c.totalContacts;
        }
        
    }

    async tabChange(index) {
        await this.cacheService.setc({ 'index': index}, 'CONTACT_TAB_INDEX');
        if (this.sortCache[index]) {
            this.pageSize = this.sortCache[index].pageSize;
            this.pageOffset = this.sortCache[index].pageOffset;
        }
        this.refresh(this.pageSize, this.pageOffset);
        this.selectedContactsCount = 0;
        this.selectedContactsObj = {};
    }

    refresh(pageSize, pageOffset) {
        this.ready = false;
        this.selectedContactsCount = 0;
        this.selectedContactsObj = {};
        if (this.selectedIndex == 0) {
            this.mycontacts.mycontacts = [];
            let sortObj = {};
            sortObj['name'] = "my_contacts_sort";
            sortObj['sortlist'] = this.mycontactsList;
            this.sortdialogservice.setSortObj(sortObj);
            this.mycontacts.getMyContacts(pageSize, pageOffset, this.labels);
        }

        if (this.selectedIndex == 1) {
            this.teamcontacts.mycontacts = []; 
            let sortObj = {};
            sortObj['name'] = "team_contacts_sort";
            sortObj['sortlist'] = this.teamcontactsList;
            this.sortdialogservice.setSortObj(sortObj);
            this.teamcontacts.getTeamContacts(pageSize, pageOffset,this.reportingUsers);
        }
    }

    setSortChache(pageSize, pageOffset) {
        let sortObj = {};
        sortObj['name'] = "my_contacts_sort";
        sortObj['sortlist'] = this.mycontactsList;
        this.sortdialogservice.setSortObj(sortObj);
        this.sortCache[this.selectedIndex] = {
            pageSize,
            pageOffset
        }
    }
    selectedContacts(event) {
        this.selectedContactsCount = event.count;
        this.selectedContactsObj = event.obj;
        if(this.selectedContactsCount==0){
            localStorage.removeItem("bulkAddLabel");
            localStorage.removeItem("selectedContactsObj")
            this.allContactsSelected=false;
        }
    }
    displayedContactsCount(event){
        this.displayedContactsCountValue=event.value
    }
    async openForm() {
        if (this.selectedContactsCount > 0) {
            if(this.allContactsSelected){
                this.selectedContactsCount=this.totalCount
            }
            await this.cacheService.setc({ 'contacts_count': this.selectedContactsCount, 'contacts_obj': this.selectedContactsObj }, 'FORM_CONTACT_REASSIGN');
            this._pubSub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'FORM_CONTACT_REASSIGN'
            });
        }
    }
    addLabel() {
        localStorage.setItem("bulkAddLabel","true");
        localStorage.setItem("selectedContactsObj",JSON.stringify(this.selectedContactsObj))
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'CONTACT_LABEL'
        })
    }

    async reassignContact(event) {
        this.selectedContactsCount = event.count;
        this.selectedContactsObj = event.obj;
        await this.openForm();
    }

    downloadContactasCSV() {
        let contactsIds = []
        for (var c in this.selectedContactsObj) {
            console.log("c", c)
            contactsIds.push(this.selectedContactsObj[c].contactid)
        }
        if (this.allContactsSelected)
            contactsIds = []
        this.contactService.exportContacts(contactsIds).then(r => {
            var data = r.local.result;
            let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
            let dwldLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", 'contacts' + ".csv");
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        })
    }
    filter() {
        this._pubSub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'CONTACT_FILTER'
        });
    }

    teamContactFilter() {
        this._pubSub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'CONTACT_TEAM_FILTER'
        });
    }

    ngOnDestroy() {
        if (this.filterRefreshSub) {
            this.filterRefreshSub.unsubscribe();
        }
        if(this.teamfilterRefreshSub){
            this.teamfilterRefreshSub.unsubscribe()
        }
    }
}
