<div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="{&apos;disabled-appearance&apos;: disableActions}">
  <div fxLayout="row " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="header">
    <h2 class="font-primary-color">
      {{header}}</h2>
  </div>
  <div fxLayout="column " class="options-configurator-options-container" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" cdkDropList (cdkDropListDropped)="drop($event)">
    <ng-container *ngIf="!iem">
      <div fxLayout="row " class="flex-row-nowrap white-back" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" cdkDrag cdkDragBoundary=".options-configurator-options-container" *ngFor="let option of options; let i = index;" [cdkDragDisabled]="disableActions || !option.sortable">
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="option.sortable ? &apos;visible&apos; : &apos;invisible&apos;"><img imgSrc="/Web/Icons/drag-handle.svg" cdkDragHandle class="cursor-move"></div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="option.deletable ? &apos;visible&apos; : &apos;invisible&apos;"><img imgSrc="/Web/Icons/red-minus.svg" (click)="removeOption(i)" tabindex="0" (keyup.enter)="removeOption(i)" class="cursor-pointer"></div>
        <div fxLayout="row wrap" class="width-100 form-mat-form-field-cont" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;never&apos;" [hideRequiredMarker]="false">
            <input matInput [required]="true" [placeholder]="placeholder" [formControl]="option.ifc">
            <mat-error align="start" *ngIf="option.ifc.errors?.duplicate">Duplicate value</mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="iem">
      <div fxLayout="row " class="flex-row-nowrap white-back" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" cdkDrag cdkDragBoundary=".options-configurator-options-container" *ngFor="let option of options; let i = index;" [cdkDragDisabled]="disableActions || !option.sortable">
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="option.sortable ? &apos;visible&apos; : &apos;invisible&apos;"><img imgSrc="/Web/Icons/drag-handle.svg" cdkDragHandle class="cursor-move"></div>
        <div fxLayout="row wrap" class="width-100 form-mat-form-field-cont" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="disabledLooking(option)">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;never&apos;" [hideRequiredMarker]="false">
            <input matInput [required]="true" [placeholder]="placeholder" [readonly]="readOnly(option)" [formControl]="option.ifc">
            <mat-error align="start" *ngIf="option.ifc.errors?.duplicate">Duplicate value</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="readOnly(option)" [ngClass]="option.editable ? &apos;visible&apos; : &apos;invisible&apos;"><img imgSrc="/Web/Icons/edit-generic.svg" (click)="!this.disableActions &amp;&amp; enterEditMode(i)" tabindex="0" (keyup.enter)="!this.disableActions &amp;&amp; enterEditMode(i)" matTooltip="Edit Outcome" class="cursor-pointer"></div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="!readOnly(option)" [ngClass]="{&apos;disabled-appearance&apos;: option.ifc.invalid}"><img imgSrc="/Web/Icons/save-generic.svg" (click)="!this.disableActions &amp;&amp; saveOption(i)" tabindex="0" (keyup.enter)="!this.disableActions &amp;&amp; saveOption(i)" matTooltip="Save Outcome" class="cursor-pointer"></div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="!readOnly(option)" [ngClass]="option.deletable ? &apos;visible&apos; : &apos;invisible&apos;"><img imgSrc="/Web/Icons/delete-generic.svg" (click)="!this.disableActions &amp;&amp; removeOption(i)" tabindex="0" (keyup.enter)="!this.disableActions &amp;&amp; removeOption(i)" matTooltip="Delete Outcome" class="cursor-pointer"></div>
      </div>
    </ng-container>
  </div>
  <div fxLayout="row " class="font-primary-color" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " class="cursor-pointer font-primary-color" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" (click)="!this.disableActions &amp;&amp; addOption()" (keyup.enter)="!this.disableActions &amp;&amp; addOption()" tabindex="0">
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><img imgSrc="/Web/Icons/green-add.svg"></div>
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><span>
        {{addBtnLabel}}</span></div>
    </div>
  </div>
</div>