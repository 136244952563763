<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" class="sidenav-form-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/call.svg">
          <h2>
            CALL LOG</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="feedbackFormGroup" fxLayout="column" fxFlex="calc(100% - 64px)" class="add-lead-content" (ngSubmit)="update()">
    <div fxLayout="column wrap" class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div class="margin-bottom-1">
        <bh-linkfield [text]="feedbackObject?.contact_name" [label]="contactLable" [url]="conUrl" (navigationInit)="close()"></bh-linkfield>
      </div>
      <div *ngIf="feedbackObject?.dealid" class="margin-bottom-1">
        <bh-linkfield [text]="feedbackObject?.deal_name" [label]="relatedDealLabel" [url]="dealUrl" (navigationInit)="close()"></bh-linkfield>
      </div>
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
          <mat-select formControlName="callfeedbackid" placeholder="Select Outcome" [required]="false">
            <ng-template ngFor let-option [ngForOf]="outcomes" let-i="index">
              <mat-option [value]="option.callfeedbackid">{{option.feedback_label}}</mat-option>
            </ng-template>
          </mat-select>

        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayoutAlign="center start" class="task-description input-focus-color">
        <label class="label">Feedback</label>
        <mat-form-field class="width-100" appearance="outline">
          <textarea placeholder="Enter feedback." matInput formControlName="feedback_notes"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column wrap" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" type="submit">Add</button></div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>