/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-teamfiltercount',
    templateUrl: './teamfiltercount.template.html'
})

export class teamfiltercountComponent extends NBaseComponent implements OnInit {

    @Input('reportingUsersCount') reportingUsersCount = 0;
    @Input('reportingUsers') reportingUsers = [];
    @ViewChildren('tooltip') tooltip;

    constructor(private cacheService: cacheservice, private _ps: NPubSubService) {
        super();
    }

    ngOnInit() {

    }
    clearFilters() {
        this.cacheService.setc(null, 'MY_TEAM_CONTACT_FILTER');
        this._ps.$pub('refreshteamcontactlist');
        this._ps.$pub('refreshteamfilter');
    }
    tooltipToggle() {
        this.tooltip._results['0'].show()
    }

}
