/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

import { utilservice } from './utilservice'; //_splitter_
import { cacheservice } from './cacheservice'; //_splitter_
//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class feedbackservice {
  constructor(
    private cacheservice: cacheservice,
    private utilservice: utilservice,
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_feedbackservice

  async upateFeedback(
    body: any = { feedback_notes: '', feedbackid: null },
    activityType = 'call',
    activityid = 0,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          body: body,
          activityType: activityType,
          activityid: activityid,
          contactid: contactid
        },
        local: {
          seg: '/feedback/appointment',
          url: '',
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_miLghF9uFUPbSQGX(bh);
      //appendnew_next_upateFeedback
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ZTQRJvoKfIDjE1F2');
    }
  }

  async getFeedbackOutcomes(activityType = '', ...others) {
    try {
      var bh = {
        input: {
          activityType: activityType
        },
        local: {
          url: '',
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_QwxtrCgJEGBmGaCv(bh);
      //appendnew_next_getFeedbackOutcomes
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_tXRJLwBUlCM3OheN');
    }
  }

  async __validateActivityType(bh) {
    try {
      bh = await this.checkType(bh);
      //appendnew_next___validateActivityType
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bOfXlNxDr73XoZBO');
    }
  }

  async createFbOutcome(
    activityType = '',
    feedback_label = '',
    sort_order = null,
    ...others
  ) {
    try {
      var bh = {
        input: {
          activityType: activityType,
          feedback_label: feedback_label,
          sort_order: sort_order
        },
        local: {
          url: '',
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_mPmmSjAAf9snZ0L9(bh);
      //appendnew_next_createFbOutcome
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bEFiod61br9G2Z6l');
    }
  }

  async updateFbOutcome(
    activityType = '',
    feedback_label = '',
    sort_order = null,
    feedbackid = null,
    ...others
  ) {
    try {
      var bh = {
        input: {
          activityType: activityType,
          feedback_label: feedback_label,
          sort_order: sort_order,
          feedbackid: feedbackid
        },
        local: {
          url: '',
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_1F9JXGzuEpgbF0Sn(bh);
      //appendnew_next_updateFbOutcome
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_K92Wj4zDoOAUTVLw');
    }
  }

  async delFbOutcome(
    feedbackid = null,
    activityType: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          feedbackid: feedbackid,
          activityType: activityType
        },
        local: {
          url: '',
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_EMQ3gE2a6E1aKQ48(bh);
      //appendnew_next_delFbOutcome
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MtG7lgUhSBsPjTUU');
    }
  }

  async updateBulkFbOutcome(
    activityType = '',
    feedback_data: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          activityType: activityType,
          feedback_data: feedback_data
        },
        local: {
          url: '',
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_lvJ0vRQuOTekGk0t(bh);
      //appendnew_next_updateBulkFbOutcome
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bcVCa7w6jyOCciaE');
    }
  }

  //appendnew_flow_feedbackservice_Start

  async sd_miLghF9uFUPbSQGX(bh) {
    try {
      bh = await this.__validateActivityType(bh);

      bh = await this.__sendFeedbackUrl(bh);
      //appendnew_next_sd_miLghF9uFUPbSQGX
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_miLghF9uFUPbSQGX');
    }
  }

  async __sendFeedbackUrl(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `feedback/${bh.input.activityType}/${bh.input.activityid}?contactid=${bh.input.contactid}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.__reqUpdateFeedback(bh);
      //appendnew_next___sendFeedbackUrl
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0W5ElI1XnDgzHkE6');
    }
  }

  async __reqUpdateFeedback(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next___reqUpdateFeedback
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_74xhlyIf5vDOOTCW');
    }
  }

  async sd_QwxtrCgJEGBmGaCv(bh) {
    try {
      bh = await this.__validateActivityType(bh);

      bh = await this.__feedbackOutcomesUrl(bh);
      //appendnew_next_sd_QwxtrCgJEGBmGaCv
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_QwxtrCgJEGBmGaCv');
    }
  }

  async __feedbackOutcomesUrl(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `feedback-outcomes/${bh.input.activityType}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.__reqGetFeedbackOutcome(bh);
      //appendnew_next___feedbackOutcomesUrl
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lkMJQ8gpsN4j6evU');
    }
  }

  async __reqGetFeedbackOutcome(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      bh = await this.setCacheKeys(bh);
      //appendnew_next___reqGetFeedbackOutcome
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wjg2tqb42dJmL2vY');
    }
  }

  async setCacheKeys(bh) {
    try {
      bh.outcomes_ck =
        bh.input.activityType === 'call'
          ? 'CALL_FEEDBACK_OUTCOMES'
          : 'APPOINTMENTS_FEEDBACK_OUTCOMES';
      bh.outcomes_map_ck =
        bh.input.activityType === 'call'
          ? 'CALL_FEEDBACK_OUTCOMES_MAP'
          : 'APPOINTMENTS_FEEDBACK_OUTCOMES_MAP';
      const id =
        bh.input.activityType === 'call'
          ? 'callfeedbackid'
          : 'appointmentfeedbackid';
      bh.outcomes_map = bh.local.res.data.reduce((a, c) => {
        a[c[id]] = c;
        return a;
      }, {});
      bh = await this.cacheOutcomes(bh);
      //appendnew_next_setCacheKeys
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_La5dRQYpj4WzilqP');
    }
  }

  async cacheOutcomes(bh) {
    try {
      let outputVariables = await this.cacheservice.setc(
        bh.local.res.data,
        bh.outcomes_ck
      );

      bh = await this.cacheOutcomesMap(bh);
      //appendnew_next_cacheOutcomes
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_vTnlLiDfn4TqQNWp');
    }
  }

  async cacheOutcomesMap(bh) {
    try {
      let outputVariables = await this.cacheservice.setc(
        bh.outcomes_map,
        bh.outcomes_map_ck
      );

      //appendnew_next_cacheOutcomesMap
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_pNC9kOnQMADvX3Kh');
    }
  }

  async checkType(bh) {
    try {
      let otherwiseFlag = true;
      if (
        this.sdService.operators['se'](
          bh.input.activityType,
          'call',
          undefined,
          undefined
        )
      ) {
        otherwiseFlag = false;
      } else if (
        this.sdService.operators['se'](
          bh.input.activityType,
          'appointment',
          undefined,
          undefined
        )
      ) {
        otherwiseFlag = false;
      }
      if (
        this.sdService.operators['else'](
          otherwiseFlag,
          undefined,
          undefined,
          undefined
        )
      ) {
        bh = await this.throwInvlidType(bh);
        otherwiseFlag = false;
      }

      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_zLWoBZWCjwAAPJhq');
    }
  }

  async throwInvlidType(bh) {
    try {
      throw new Error(
        'Invalid "activityType". Value can either be "call" or "appointment", but received ' +
          bh.input.activityType
      );
      //appendnew_next_throwInvlidType
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ETrgxCQBuUd2Qhkx');
    }
  }

  async sd_mPmmSjAAf9snZ0L9(bh) {
    try {
      bh = await this.__validateActivityType(bh);

      bh = await this.__feedbackOutcomesUrl2(bh);
      //appendnew_next_sd_mPmmSjAAf9snZ0L9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mPmmSjAAf9snZ0L9');
    }
  }

  async __feedbackOutcomesUrl2(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `feedback-outcomes/${bh.input.activityType}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.putFbOutcomesBody(bh);
      //appendnew_next___feedbackOutcomesUrl2
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_clkbXKV3NE2agGow');
    }
  }

  async putFbOutcomesBody(bh) {
    try {
      bh.body = {
        feedback_label: bh.input.feedback_label,
        sort_order: bh.input.sort_order
      };
      bh = await this.__postFbOutcome(bh);
      //appendnew_next_putFbOutcomesBody
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_qb2GUcPCYL2XDNIp');
    }
  }

  async __postFbOutcome(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next___postFbOutcome
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ahugZU5QEKky1s8e');
    }
  }

  async sd_1F9JXGzuEpgbF0Sn(bh) {
    try {
      bh = await this.__validateActivityType(bh);

      bh = await this.__feedbackOutcomesUrl3(bh);
      //appendnew_next_sd_1F9JXGzuEpgbF0Sn
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1F9JXGzuEpgbF0Sn');
    }
  }

  async __feedbackOutcomesUrl3(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `feedback-outcomes/${bh.input.activityType}/${bh.input.feedbackid}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.updateFbOutcomesBody(bh);
      //appendnew_next___feedbackOutcomesUrl3
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_FtLtImf2SBvdlBIF');
    }
  }

  async updateFbOutcomesBody(bh) {
    try {
      bh.body = {
        ...(bh.input.feedback_label && {
          feedback_label: bh.input.feedback_label
        }),
        ...(bh.input.sort_order && { sort_order: bh.input.sort_order })
      };
      bh = await this.__putFbOutcome(bh);
      //appendnew_next_updateFbOutcomesBody
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_FBxuSNqrX1qnDsyD');
    }
  }

  async __putFbOutcome(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next___putFbOutcome
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3JraMXx3pQdCZa1v');
    }
  }

  async sd_EMQ3gE2a6E1aKQ48(bh) {
    try {
      bh = await this.__validateActivityType(bh);

      bh = await this.__feedbackOutcomesUrl4(bh);
      //appendnew_next_sd_EMQ3gE2a6E1aKQ48
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EMQ3gE2a6E1aKQ48');
    }
  }

  async __feedbackOutcomesUrl4(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `feedback-outcomes/${bh.input.activityType}/${bh.input.feedbackid}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.__delFbOutcome(bh);
      //appendnew_next___feedbackOutcomesUrl4
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Vd2GqxNgSahrKbJe');
    }
  }

  async __delFbOutcome(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next___delFbOutcome
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Vh4u3qstwEwKuUDA');
    }
  }

  async sd_lvJ0vRQuOTekGk0t(bh) {
    try {
      bh = await this.__validateActivityType(bh);

      bh = await this.__fbOutcomesUrl4(bh);
      //appendnew_next_sd_lvJ0vRQuOTekGk0t
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lvJ0vRQuOTekGk0t');
    }
  }

  async __fbOutcomesUrl4(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `feedback-outcomes-bulk/${bh.input.activityType}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.updateBulkFbOutcomesBody(bh);
      //appendnew_next___fbOutcomesUrl4
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_oDKf09sKjAkJArtP');
    }
  }

  async updateBulkFbOutcomesBody(bh) {
    try {
      bh.body = {
        feedback_data: bh.input.feedback_data
      };
      bh = await this.__putBulkFbOutcome(bh);
      //appendnew_next_updateBulkFbOutcomesBody
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wfejKlxK4eofP86b');
    }
  }

  async __putBulkFbOutcome(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next___putBulkFbOutcome
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_KILfBs2Rho0QKLp6');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_feedbackservice_Catch
}
