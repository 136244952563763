/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

import { utilservice } from './utilservice'; //_splitter_
//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class searchservice {
  constructor(
    private utilservice: utilservice,
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_searchservice

  async contactDealSearch(
    user_id: any = undefined,
    searchValue: any = undefined,
    pageSize: any = undefined,
    pageOffset: any = undefined,
    enableContact: any = undefined,
    enableDeal: any = undefined,
    search_field_name: any = undefined,
    myOnly = false,
    dealContactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          user_id: user_id,
          searchValue: searchValue,
          pageSize: pageSize,
          pageOffset: pageOffset,
          enableContact: enableContact,
          enableDeal: enableDeal,
          search_field_name: search_field_name,
          myOnly: myOnly,
          dealContactid: dealContactid
        },
        local: {
          searchList: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_TVa4599JXQZYdvNU(bh);
      //appendnew_next_contactDealSearch
      return (
        // formatting output variables
        {
          input: {},
          local: {
            searchList: bh.local.searchList
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_w9EoaDDIXSwWGIFc');
    }
  }

  async getContactName(
    searchName: any = undefined,
    user_id: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          searchName: searchName,
          user_id: user_id
        },
        local: {
          contactNames: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_BJGOUWu4EmlaebQx(bh);
      //appendnew_next_getContactName
      return (
        // formatting output variables
        {
          input: {},
          local: {
            contactNames: bh.local.contactNames
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_icigm3uCUaIyj5bW');
    }
  }

  async getSearchedUsers(
    searchstr = '',
    pageSize = 5,
    pageOffset = 0,
    onlyHeirarchy: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          searchstr: searchstr,
          pageSize: pageSize,
          pageOffset: pageOffset,
          onlyHeirarchy: onlyHeirarchy
        },
        local: {
          users: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_d67SDIGrRgLtSYaY(bh);
      //appendnew_next_getSearchedUsers
      return (
        // formatting output variables
        {
          input: {},
          local: {
            users: bh.local.users
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_OGxu0oXBd2DqNqj0');
    }
  }

  //appendnew_flow_searchservice_Start

  async sd_TVa4599JXQZYdvNU(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'search';

      bh.body = {
        q: bh.input.searchValue,
        search_for_user_id: bh.input.user_id,
        module: {
          contact: {
            enabled: bh.input.enableContact,
            pageSize: bh.input.pageSize,
            pageOffset:
              bh.input.search_field_name == 'contacts'
                ? bh.input.pageOffset
                : 0,
            filters: {},
            myOnly: bh.input.myOnly
          },
          deal: {
            enabled: bh.input.enableDeal,
            pageSize: bh.input.pageSize,
            pageOffset:
              bh.input.search_field_name == 'deals' ? bh.input.pageOffset : 0,
            filters: {},
            myOnly: bh.input.myOnly
          }
        }
      };
      if (bh.input.dealContactid) {
        bh.body.module.deal.contactid = bh.input.dealContactid;
      }
      bh = await this.sd_YS0HMjEvoFS1LKfC(bh);
      //appendnew_next_sd_TVa4599JXQZYdvNU
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TVa4599JXQZYdvNU');
    }
  }

  async sd_YS0HMjEvoFS1LKfC(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.searchList = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_YS0HMjEvoFS1LKfC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_YS0HMjEvoFS1LKfC');
    }
  }

  async sd_BJGOUWu4EmlaebQx(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'search';

      bh.body = {
        q: bh.input.searchName,
        search_for_user_id: bh.input.user_id,
        module: {
          contact: {
            enabled: true,
            filters: {}
          },
          deal: {
            enabled: false,
            filters: {}
          }
        }
      };
      bh = await this.sd_3RMxqAkeqteKaEPL(bh);
      //appendnew_next_sd_BJGOUWu4EmlaebQx
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BJGOUWu4EmlaebQx');
    }
  }

  async sd_3RMxqAkeqteKaEPL(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.contactNames = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_3RMxqAkeqteKaEPL
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3RMxqAkeqteKaEPL');
    }
  }

  async sd_d67SDIGrRgLtSYaY(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `search/users`
      );
      bh.url = outputVariables.local.url;

      bh = await this.searchUsersQuery(bh);
      //appendnew_next_sd_d67SDIGrRgLtSYaY
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_d67SDIGrRgLtSYaY');
    }
  }

  async searchUsersQuery(bh) {
    try {
      bh.q = {
        searchstr: bh.input.searchstr,
        pageSize: bh.input.pageSize,
        pageOffset: bh.input.pageOffset
      };

      if (bh.input.onlyHeirarchy) {
        bh.q['onlyHeirarchy'] = true;
      }
      bh = await this.sd_aDm5aOuO3DvMlYyh(bh);
      //appendnew_next_searchUsersQuery
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_kIrzGYxEdhKJvm41');
    }
  }

  async sd_aDm5aOuO3DvMlYyh(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: bh.q,
        body: undefined
      };
      bh.local.users = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_aDm5aOuO3DvMlYyh
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_aDm5aOuO3DvMlYyh');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_searchservice_Catch
}
