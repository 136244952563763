/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { dealservice } from 'app/sd-services/dealservice';
import { Router } from '@angular/router';
import { NPubSubService } from 'neutrinos-seed-services';
import { Deal } from 'app/components/dealsComponent/deals.component';
import { userservice } from 'app/sd-services/userservice';
import { SortChangeEvent, SortOrder } from '../sortComponent/sort.component';
import { Header } from '../usersComponent/users.component';
import { labelService } from 'app/services/label/label.service';
import { Subscription } from 'rxjs';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-teamdeals',
    templateUrl: './teamdeals.template.html'
})

export class teamdealsComponent extends NBaseComponent implements OnInit, OnDestroy {

    @Input('pageSize') pageSize = 50;
    @Input('pageOffset') pageOffset = 0;
    @Output('totalCount') totalCount = new EventEmitter();
    @Input('media_view') media_view;

    @Input('totalCountP') totalCountP;

    @Input('pageSizeP') pageSizeP;

    @Input('pageOffsetP') pageOffsetP;

    @Input('readyP') readyP;

    @Output() paginationEvent = new EventEmitter<string>();

    mydeals = [];
    totalDeals = 0;
    scrollPageOffset = 0;
    nameSorting = 'ASC';
    lastUpdateSorting = 'DESC';
    checkAppendStatus = false;
    sum = 15;
    sortOrder: Array<{ key: string, order: 'ASC' | 'DESC' }> = [
        {
            key: 'deal_name',
            order: 'ASC'
        },
        {
            key: 'contact_display_name',
            order: 'ASC'
        },
        {
            key: 'deal_value',
            order: 'ASC'
        },
        {
            key: 'deal_pipe_name',
            order: 'ASC'
        },
        {
            key: 'contact_owner_name',
            order: 'ASC'
        },
        {
            key: 'deal_updated_at',
            order: 'DESC'
        },
    ];
    headers: Header[] = [
        {
            displayText: 'Name', // deal_name
            sort: true,
            sortKey: 'deal_name',
            sortValue: 'ASC'
        },
        {
            displayText: 'Contact Name',
            sort: true,
            sortKey: 'contact_display_name',
            sortValue: 'ASC'
        },
        {
            displayText: 'Value', // 
            sort: true,
            sortKey: 'deal_value',
            sortValue: 'ASC'
        },
        {
            displayText: 'Stage',
            sort: true,
            sortKey: 'deal_pipe_name',
            sortValue: 'ASC'
        },
        {
            displayText: 'Owner', // contact_owner_name
            sort: true,
            sortKey: 'contact_owner_name',
            sortValue: 'ASC'
        },
        {
            displayText: 'Last Updated On',
            sort: true,
            sortKey: 'deal_updated_at',
            sortValue: 'DESC'
        }
    ]
    currentSortKey = 'deal_updated_at';
    dealRefreshSub: Subscription;
    user;
    sortSub;
    constructor(private dealsService: dealservice, private router: Router,
        private pubsub: NPubSubService,
        private userService: userservice,
        public label: labelService) {
        super();
    }

    ngOnInit() {
        this.userService.getcu()
            .then((result: {
                input: {},
                local: {
                    user: any
                }
            }) => {
                this.user = result.local.user;
                this.getTeamDeals(this.pageSize, this.pageOffset);
            })
            .catch(e => {
                console.log(e);
            })
        this.dealRefreshSub = this.pubsub.$sub('deal-added-event', () => {
            this.getTeamDeals(this.pageSize, this.pageOffset);
        })

        this.sortSub = this.pubsub.$sub('team_deals_sort', (result) => {
            this.checkAppendStatus = true;
            let dealsortCount = this.mydeals.length
            this.mydeals = [];
            this.findNReplaceSortOrder(result.res.data.key, result.res.data.sort_type);
            this.getTeamDeals(dealsortCount, 0);
        })
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     if (changes) {
    //         this.userService.getcu()
    //             .then((result: {
    //                 input: {},
    //                 local: {
    //                     user: any
    //                 }
    //             }) => {
    //                 this.user = result.local.user;
    //                 this.pageSize = 15;
    //                 this.pageOffset = 0;
    //                 this.getTeamDeals(this.pageSize, this.pageOffset);
    //             })
    //             .catch(e => {
    //                 console.log(e);
    //             })
    //         // this.getTeamDeals(this.pageSize, this.pageOffset);
    //     }
    // }

    onScroll() {
        this.checkAppendStatus = true;
        this.scrollPageOffset += 1;
        this.getTeamDeals(this.sum, this.scrollPageOffset);
    }

    pageEvents($event) {
         this.paginationEvent.next($event);;
    }

    getTeamDeals(pageSize: number, pageOffset: number) {
        if (typeof pageSize == 'undefined') {
            pageSize = 50;
        }

        if (typeof pageOffset == 'undefined') {
            pageOffset = 0;
        }

        this.pageOffset = pageOffset;
        this.pageSize = pageSize;

        this.dealsService.getTeamDeals(
            pageSize,
            pageOffset,
            this.sortOrder.map(v => {
                return v.key + ' ' + v.order;
            }).filter(v => v).join(','),
            this.user.srmUserid
        )
            .then((result: {
                input: {},
                local: {
                    result: {
                        count: number,
                        result: Deal[]
                    }
                }
            }) => {
                if (result.local.result && result.local.result.result) {
                    if (this.checkAppendStatus) {
                        let temp = this.mydeals;
                        this.mydeals = result.local.result.result;
                        this.mydeals = temp.concat(this.mydeals);
                        this.totalDeals = result.local.result.count;
                        this.totalCount.emit({ value: true });
                        this.checkAppendStatus = false;
                    } else {
                        this.mydeals = result.local.result.result;
                        this.totalDeals = result.local.result.count;
                        this.totalCount.emit({ value: true });
                    }
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    navigateToDeal(dealid, contactid) {
        this.router.navigate(['/home/deals/' + dealid + '/contact/' + contactid])
    }

    sortChange($event: SortChangeEvent) {
        this.currentSortKey = $event.key;
        this.findNReplaceSortOrder($event.key, $event.sort);
        this.getTeamDeals(this.pageSize, this.pageOffset);
    }

    findNReplaceSortOrder(key, sort: SortOrder) {
        let i = this.sortOrder.findIndex(v => {
            return v.key === key;
        });

        this.sortOrder.splice(i, 1);
        this.sortOrder.unshift({
            key,
            order: sort
        })
    }

    trackByFn(i): number {
        return i;
    }

    ngOnDestroy() {
        if (this.dealRefreshSub) {
            this.dealRefreshSub.unsubscribe();
        }
    }

}
