/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { tenantservice } from 'app/sd-services/tenantservice';
import { FormControl } from '@angular/forms';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-setting_phone_country_code',
    templateUrl: './setting_phone_country_code.template.html',
    styleUrls: ['../setting_currComponent/setting_curr.style.scss'
        , 'setting_phone_country.style.scss']
})

export class setting_phone_country_codeComponent extends NBaseComponent implements OnInit {
    countryCode: FormControl = new FormControl();
    placeholder = "Phone Country Code";
    disableAddButton = false;
    constructor(
        private pubsub: NPubSubService,
        private tenantService: tenantservice

    ) {
        super();
    }

    ngOnInit() {

    }
    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }
    saveCountryValue() {
        if (this.countryCode.value && this.countryCode.value.phone_country_code) {
            this.disableAddButton = true;
            this.tenantService.setTenantSettingValue('PHONE_COUNTRY_CODE', this.countryCode.value.phone_country_code).then((data) => {
                this.pubsub.$pub('refresh-settings');
                this.close();
            }).catch(err=>{
                this.disableAddButton = false;
            })
        }
    }

}
