<div fxLayout="column " class="list-padding height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="roles.length &gt; 0">
  <div fxLayout="row wrap" class="list-header" fxFlex="48px" fxLayoutGap="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div fxLayout="row wrap" class="lr-padding" fxFlex="20" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;lead-header-field-gt-xs&apos;" *ngFor="let header of headers">
        <h3 class="color-view">
          {{header.displayText}}</h3>
        <bh-sort [sortKey]="header.sortKey" [sort]="header.sortValue" (changeEvent)="sortChange($event)" *ngIf="header.sort" [ngClass]="(header.sortKey != currentSortKey) ? &apos;not-sorting-icon&apos; : &apos;&apos;"></bh-sort>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" class="border-bottom-gt-xs display-block" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let role of roles; trackBy:trackByRoleId">
      <div fxLayout="row wrap" class="color-black" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="20" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <h3 class="font-normal-size text-ellipsis">
            <div *ngIf="role.role_name" class="text-ellipsis">{{role.role_name}}</div>
            <div *ngIf="!role.role_name" class="label-font-color">-------</div>
          </h3>
        </div>
        <div fxLayout="row wrap" class="height-gt-xs lr-padding" fxFlex="20" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <h3 class="font-normal-size text-ellipsis">
            {{role.no_of_users || 0}}</h3>
        </div>
        <div fxLayout="row wrap" class="cursor-pointer lr-padding leads-height-gt-xs" fxFlex="20" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div (click)="openEditRoleForm(role)" click-stop-propagation matTooltip="Edit Role" class="edit-role-image">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="column wrap" fxLayoutGap="1rem" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" fxFill *ngIf="roles.length === 0"><img imgSrc="/Web/Icons/settings-no_roles.svg">
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <h2 [ngClass]="(!progressCheck) ? &apos;icon-disable&apos; : &apos;&apos; " class="illustration-label">
      NO ROLES</h2>
  </div>
</div>