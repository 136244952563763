/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class contactservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_contactservice

  async getMyContacts(
    pageSize: any = undefined,
    pageOffset: any = undefined,
    sortBy: any = undefined,
    filters: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          sortBy: sortBy,
          filters: filters
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_8IjSgLOq44nCqVWO(bh);
      //appendnew_next_getMyContacts
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ANeJmjLu59xlkRT5');
    }
  }

  async getTeamContacts(
    pageSize: any = undefined,
    pageOffset: any = undefined,
    sortBy = '',
    filters: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          sortBy: sortBy,
          filters: filters
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_uaAC5PfP7RStoJkC(bh);
      //appendnew_next_getTeamContacts
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_aODPBlYyz7JW96Ed');
    }
  }

  async getContactById(contactid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          contactid: contactid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_EcOGpthfpn1CYxI3(bh);
      //appendnew_next_getContactById
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_kfPt5R3BDlkXJfwK');
    }
  }

  async createContact(
    first_name: any = undefined,
    last_name: any = undefined,
    phone_no: any = undefined,
    phone_country_code: any = undefined,
    email_id: any = undefined,
    contact_owner: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          first_name: first_name,
          last_name: last_name,
          phone_no: phone_no,
          phone_country_code: phone_country_code,
          email_id: email_id,
          contact_owner: contact_owner
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_yd4WhYId4IYQGtQw(bh);
      //appendnew_next_createContact
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_LtVZVC0e59lV6ld2');
    }
  }

  async updateContact(
    contactid: any = undefined,
    contact_udf: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          contactid: contactid,
          contact_udf: contact_udf
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_0HMTWYA2PbEb8dac(bh);
      //appendnew_next_updateContact
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wRckSS7QD85cXBu5');
    }
  }

  async contactReassign(
    contactsArr: any = undefined,
    ownerId: any = undefined,
    ownername: any = undefined,
    pageIndex: any = undefined,
    filters: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          contactsArr: contactsArr,
          ownerId: ownerId,
          ownername: ownername,
          pageIndex: pageIndex,
          filters: filters
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_IAtueWro69jVqyZ0(bh);
      //appendnew_next_contactReassign
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_vdqDiqpbKALDgZKW');
    }
  }

  async contactDelete(contactid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          contactid: contactid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_kjPewOP68YSTrLyK(bh);
      //appendnew_next_contactDelete
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_hlmnAJ78H0Prhjsx');
    }
  }

  async addLabel(
    contactid: any = undefined,
    tag: any = undefined,
    tag_order: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          contactid: contactid,
          tag: tag,
          tag_order: tag_order
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_d4TtEc3DwiHDh5AP(bh);
      //appendnew_next_addLabel
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_hCOIvo0YADqh9s5G');
    }
  }

  async updateLabel(
    tagid: any = undefined,
    tag: any = undefined,
    tag_order: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          tagid: tagid,
          tag: tag,
          tag_order: tag_order,
          contactid: contactid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_ktmGzfR04QkfA4FU(bh);
      //appendnew_next_updateLabel
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_u4E7jjV29byr6EaY');
    }
  }

  async bulkUpdateLabel(
    contactid: any = undefined,
    tagsArr: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          contactid: contactid,
          tagsArr: tagsArr
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_jFIzWxhoK9uV0KfP(bh);
      //appendnew_next_bulkUpdateLabel
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ioZF982n0hbxo3K7');
    }
  }

  async deleteLabel(
    contactid: any = undefined,
    tagid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          contactid: contactid,
          tagid: tagid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_iV3iMIZszECKg4fK(bh);
      //appendnew_next_deleteLabel
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EIgVb7raLfUAXQEd');
    }
  }

  async getContactLabels(...others) {
    try {
      var bh = {
        input: {},
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_LvJqhWc1rlGG2VfK(bh);
      //appendnew_next_getContactLabels
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jdxl5869Q9aVjl6e');
    }
  }

  async addLabelInBulk(
    contact_ids: any = undefined,
    tag: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          contact_ids: contact_ids,
          tag: tag
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_wWrjmaDg53bAsUra(bh);
      //appendnew_next_addLabelInBulk
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3nCPiHpk3PzVL50B');
    }
  }

  async getReportingContacts(...others) {
    try {
      var bh = {
        input: {},
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_4BwV7uO5SMCIYP8t(bh);
      //appendnew_next_getReportingContacts
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ZgXgDHV21INb05FY');
    }
  }

  async exportContacts(contactsIds: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          contactsIds: contactsIds
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_tQjSe6maJvZ17bUG(bh);
      //appendnew_next_exportContacts
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_UMPD07Q0waBKYV1f');
    }
  }

  //appendnew_flow_contactservice_Start

  async sd_8IjSgLOq44nCqVWO(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'contacts?';

      if (bh.input.pageSize) {
        bh.url += 'pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }

      if (bh.input.sortBy) {
        bh.url += '&sortBy=' + bh.input.sortBy;
      }
      if (bh.input.filters) {
        bh.body = { filters: bh.input.filters };
      }
      bh = await this.sd_c4Grg2tldyOkhppU(bh);
      //appendnew_next_sd_8IjSgLOq44nCqVWO
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_8IjSgLOq44nCqVWO');
    }
  }

  async sd_c4Grg2tldyOkhppU(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_c4Grg2tldyOkhppU
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_c4Grg2tldyOkhppU');
    }
  }

  async sd_uaAC5PfP7RStoJkC(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'contacts/teams?';

      if (bh.input.pageSize) {
        bh.url += 'pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }

      if (bh.input.userid) {
        bh.url += '&userid=' + bh.input.userid;
      }

      if (bh.input.sortBy) {
        bh.url += '&sortBy=' + bh.input.sortBy;
      }

      if (bh.input.filters) {
        bh.body = { filters: bh.input.filters };
      }
      bh = await this.sd_AlE2trLjT25MetEr(bh);
      //appendnew_next_sd_uaAC5PfP7RStoJkC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_uaAC5PfP7RStoJkC');
    }
  }

  async sd_AlE2trLjT25MetEr(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_AlE2trLjT25MetEr
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_AlE2trLjT25MetEr');
    }
  }

  async sd_EcOGpthfpn1CYxI3(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'contact/' +
        bh.input.contactid;
      bh = await this.sd_o1t25hDHIMVh1aI9(bh);
      //appendnew_next_sd_EcOGpthfpn1CYxI3
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EcOGpthfpn1CYxI3');
    }
  }

  async sd_o1t25hDHIMVh1aI9(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_o1t25hDHIMVh1aI9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_o1t25hDHIMVh1aI9');
    }
  }

  async sd_yd4WhYId4IYQGtQw(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'contact?';

      bh.body = {
        first_name: bh.input.first_name,
        last_name: bh.input.last_name,
        phone_no: bh.input.phone_no,
        phone_country_code: bh.input.phone_country_code,
        contact_owner: bh.input.contact_owner,
        email_id: bh.input.email_id
      };
      bh = await this.sd_LAtJcsm6yt1jCBGC(bh);
      //appendnew_next_sd_yd4WhYId4IYQGtQw
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_yd4WhYId4IYQGtQw');
    }
  }

  async sd_LAtJcsm6yt1jCBGC(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_LAtJcsm6yt1jCBGC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_LAtJcsm6yt1jCBGC');
    }
  }

  async sd_0HMTWYA2PbEb8dac(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'contact/' +
        bh.input.contactid;

      bh.body = {
        contact_udf: bh.input.contact_udf
      };
      bh = await this.sd_HHDDQ3iZaUiRAiwp(bh);
      //appendnew_next_sd_0HMTWYA2PbEb8dac
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0HMTWYA2PbEb8dac');
    }
  }

  async sd_HHDDQ3iZaUiRAiwp(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_HHDDQ3iZaUiRAiwp
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HHDDQ3iZaUiRAiwp');
    }
  }

  async sd_IAtueWro69jVqyZ0(bh) {
    try {
      let allContactsSelected = localStorage.getItem('allContactsSelected');
      bh.url = bh.system.environment.properties.ssdURL + 'contacts/reassign';
      bh.body = {
        contactsArr: bh.input.contactsArr,
        ownerid: bh.input.ownerId,
        ownername: bh.input.ownername,
        allContactsSelected: allContactsSelected,
        pageIndex: bh.input.pageIndex,
        filters: bh.input.filters
      };

      bh = await this.sd_Qv7QWlTmXn8lPeuo(bh);
      //appendnew_next_sd_IAtueWro69jVqyZ0
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_IAtueWro69jVqyZ0');
    }
  }

  async sd_Qv7QWlTmXn8lPeuo(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_Qv7QWlTmXn8lPeuo
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Qv7QWlTmXn8lPeuo');
    }
  }

  async sd_kjPewOP68YSTrLyK(bh) {
    try {
      bh.local.url = `${bh.system.environment.properties.ssdURL}contact/${bh.input.contactid}`;

      bh = await this.sd_6HcujmfgmHOhRWd9(bh);
      //appendnew_next_sd_kjPewOP68YSTrLyK
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_kjPewOP68YSTrLyK');
    }
  }

  async sd_6HcujmfgmHOhRWd9(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_6HcujmfgmHOhRWd9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_6HcujmfgmHOhRWd9');
    }
  }

  async sd_d4TtEc3DwiHDh5AP(bh) {
    try {
      bh.local.url = `${bh.system.environment.properties.ssdURL}contact/${bh.input.contactid}/tag`;

      bh.body = {
        tag: bh.input.tag,
        tag_order: bh.input.tag_order
      };

      bh = await this.sd_TbGgbyaIslWy56k9(bh);
      //appendnew_next_sd_d4TtEc3DwiHDh5AP
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_d4TtEc3DwiHDh5AP');
    }
  }

  async sd_TbGgbyaIslWy56k9(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_TbGgbyaIslWy56k9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TbGgbyaIslWy56k9');
    }
  }

  async sd_ktmGzfR04QkfA4FU(bh) {
    try {
      bh.local.url = `${bh.system.environment.properties.ssdURL}contact/${bh.input.contactid}/tag/${bh.input.tagid}`;

      bh.body = {
        tag: bh.input.tag,
        tag_order: bh.input.tag_order
      };

      bh = await this.sd_lL4ssSnphu51qVMb(bh);
      //appendnew_next_sd_ktmGzfR04QkfA4FU
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ktmGzfR04QkfA4FU');
    }
  }

  async sd_lL4ssSnphu51qVMb(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_lL4ssSnphu51qVMb
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lL4ssSnphu51qVMb');
    }
  }

  async sd_jFIzWxhoK9uV0KfP(bh) {
    try {
      bh.local.url = `${bh.system.environment.properties.ssdURL}contact/${bh.input.contactid}/tags`;

      bh.body = {
        tagsArr: bh.input.tagsArr
      };

      bh = await this.sd_otKyMvdDDlGiCkff(bh);
      //appendnew_next_sd_jFIzWxhoK9uV0KfP
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jFIzWxhoK9uV0KfP');
    }
  }

  async sd_otKyMvdDDlGiCkff(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_otKyMvdDDlGiCkff
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_otKyMvdDDlGiCkff');
    }
  }

  async sd_iV3iMIZszECKg4fK(bh) {
    try {
      bh.local.url = `${bh.system.environment.properties.ssdURL}contact/${bh.input.contactid}/tag/${bh.input.tagid}`;

      bh = await this.sd_3OEf3qcN5Rd1Kx05(bh);
      //appendnew_next_sd_iV3iMIZszECKg4fK
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_iV3iMIZszECKg4fK');
    }
  }

  async sd_3OEf3qcN5Rd1Kx05(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_3OEf3qcN5Rd1Kx05
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3OEf3qcN5Rd1Kx05');
    }
  }

  async sd_LvJqhWc1rlGG2VfK(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'contacts/tags';

      bh = await this.sd_1E0WXLBt7VhLlWiJ(bh);
      //appendnew_next_sd_LvJqhWc1rlGG2VfK
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_LvJqhWc1rlGG2VfK');
    }
  }

  async sd_1E0WXLBt7VhLlWiJ(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_1E0WXLBt7VhLlWiJ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1E0WXLBt7VhLlWiJ');
    }
  }

  async sd_wWrjmaDg53bAsUra(bh) {
    try {
      bh.local.url = `${bh.system.environment.properties.ssdURL}addlabelsnnbulk`;
      let allContactsSelected = localStorage.getItem('allContactsSelected');
      bh.body = {
        contact_ids: bh.input.contact_ids,
        tag: bh.input.tag,
        allContactsSelected: allContactsSelected
      };
      bh = await this.sd_EODFuCuyP0jimZBX(bh);
      //appendnew_next_sd_wWrjmaDg53bAsUra
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wWrjmaDg53bAsUra');
    }
  }

  async sd_EODFuCuyP0jimZBX(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_EODFuCuyP0jimZBX
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EODFuCuyP0jimZBX');
    }
  }

  async sd_4BwV7uO5SMCIYP8t(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'reporting/users';

      bh = await this.sd_mGDHTsKaQwxSTOWj(bh);
      //appendnew_next_sd_4BwV7uO5SMCIYP8t
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_4BwV7uO5SMCIYP8t');
    }
  }

  async sd_mGDHTsKaQwxSTOWj(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_mGDHTsKaQwxSTOWj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mGDHTsKaQwxSTOWj');
    }
  }

  async sd_tQjSe6maJvZ17bUG(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'export/contacts';
      bh.body = {
        contact_ids: bh.input.contactsIds
      };
      bh = await this.sd_XRNL0aYS7Ullh06C(bh);
      //appendnew_next_sd_tQjSe6maJvZ17bUG
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_tQjSe6maJvZ17bUG');
    }
  }

  async sd_XRNL0aYS7Ullh06C(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'text',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_XRNL0aYS7Ullh06C
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_XRNL0aYS7Ullh06C');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_contactservice_Catch
}
