/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { dialogService } from '../../services/dialog/dialog.service';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { userservice } from 'app/sd-services/userservice';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-documents',
    templateUrl: './documents.template.html'
})

export class documentsComponent extends NBaseComponent implements OnInit {
    @Input('documentsArr') documentsArr = [];
    @Input('accessDenied') accessDenied = true;;
    @Output('download_file_id') download_file_id = new EventEmitter();
    @ViewChildren('tooltip') tooltip;

    constructor(private dialog: dialogService,
        private cacheService: cacheservice,
        private pubsub: NPubSubService,
        public userservice: userservice) {
        super();
    }

    ngOnInit() {
    }

    add_document() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_DOCUMENT'
        })
    }

    downloadDocument(file_id) {
        this.download_file_id.emit({ value: file_id });
    }

    deleteDocument(file) {
        this.dialog.documentDeleteDialog(file);
    }

    editDocument(file) {
        if (!this.accessDenied) {
            this.cacheService.setc(file, 'EDIT_DOCUMENT');
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'ADD_DOCUMENT'
            })
        }
    }

    tooltipToggle(i) {
        this.tooltip._results.forEach((item, index) => {
        this.tooltip._results[index].hide();
            if (index == i) {
                this.tooltip._results[index].show();
            }
        });
    }

    getMoreInformation(file): string {
        return 'Document Tags :' + file.tags.join(",\r")
            + '\nUploaded By: ' + file.created_by_display_name
            + ' \nUploaded at: ';
    }
}
