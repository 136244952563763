import { SDBaseService } from 'app/n-services/SDBaseService';
//CORE_REFERENCE_IMPORTS
//CORE_REFERENCE_IMPORT-pipelineservice
import { pipelineservice } from '../sd-services/pipelineservice';
//CORE_REFERENCE_IMPORT-importcontactservice
import { importcontactservice } from '../sd-services/importcontactservice';
//CORE_REFERENCE_IMPORT-fileservice
import { fileservice } from '../sd-services/fileservice';
//CORE_REFERENCE_IMPORT-navservice
import { navservice } from '../sd-services/navservice';
//CORE_REFERENCE_IMPORT-summaryservice
import { summaryservice } from '../sd-services/summaryservice';
//CORE_REFERENCE_IMPORT-dashboardservice
import { dashboardservice } from '../sd-services/dashboardservice';
//CORE_REFERENCE_IMPORT-permissionservice
import { permissionservice } from '../sd-services/permissionservice';
//CORE_REFERENCE_IMPORT-roleservice
import { roleservice } from '../sd-services/roleservice';
//CORE_REFERENCE_IMPORT-externallinkservice
import { externallinkservice } from '../sd-services/externallinkservice';
//CORE_REFERENCE_IMPORT-usernotificationservice
import { usernotificationservice } from '../sd-services/usernotificationservice';
//CORE_REFERENCE_IMPORT-utilservice
import { utilservice } from '../sd-services/utilservice';
//CORE_REFERENCE_IMPORT-feedbackservice
import { feedbackservice } from '../sd-services/feedbackservice';
//CORE_REFERENCE_IMPORT-cacheservice
import { cacheservice } from '../sd-services/cacheservice';
//CORE_REFERENCE_IMPORT-udfservice
import { udfservice } from '../sd-services/udfservice';
//CORE_REFERENCE_IMPORT-callcontactsservice
import { callcontactsservice } from '../sd-services/callcontactsservice';
//CORE_REFERENCE_IMPORT-notesservice
import { notesservice } from '../sd-services/notesservice';
//CORE_REFERENCE_IMPORT-taskservice
import { taskservice } from '../sd-services/taskservice';
//CORE_REFERENCE_IMPORT-dealservice
import { dealservice } from '../sd-services/dealservice';
//CORE_REFERENCE_IMPORT-appointmentservice
import { appointmentservice } from '../sd-services/appointmentservice';
//CORE_REFERENCE_IMPORT-autocompleteservice
import { autocompleteservice } from '../sd-services/autocompleteservice';
//CORE_REFERENCE_IMPORT-searchservice
import { searchservice } from '../sd-services/searchservice';
//CORE_REFERENCE_IMPORT-contactservice
import { contactservice } from '../sd-services/contactservice';
//CORE_REFERENCE_IMPORT-activityservice
import { activityservice } from '../sd-services/activityservice';
//CORE_REFERENCE_IMPORT-srmappservice
import { srmappservice } from '../sd-services/srmappservice';
//CORE_REFERENCE_IMPORT-tenantservice
import { tenantservice } from '../sd-services/tenantservice';
//CORE_REFERENCE_IMPORT-userservice
import { userservice } from '../sd-services/userservice';
//CORE_REFERENCE_IMPORT-leadservice
import { leadservice } from '../sd-services/leadservice';

export const sdProviders = [
  SDBaseService,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-pipelineservice
  pipelineservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-importcontactservice
  importcontactservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-fileservice
  fileservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-navservice
  navservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-summaryservice
  summaryservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-dashboardservice
  dashboardservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-permissionservice
  permissionservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-roleservice
  roleservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-externallinkservice
  externallinkservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-usernotificationservice
  usernotificationservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-utilservice
  utilservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-feedbackservice
  feedbackservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-cacheservice
  cacheservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-udfservice
  udfservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-callcontactsservice
  callcontactsservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-notesservice
  notesservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-taskservice
  taskservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-dealservice
  dealservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-appointmentservice
  appointmentservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-autocompleteservice
  autocompleteservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-searchservice
  searchservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-contactservice
  contactservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-activityservice
  activityservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-srmappservice
  srmappservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-tenantservice
  tenantservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-userservice
  userservice,
  //CORE_REFERENCE_PUSH_TO_SD_ARRAY-leadservice
  leadservice
];
