/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CacheKey, PubSubChannel as PSChnnl } from 'app/constants';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { ConfirmOpts, dialogService } from 'app/services/dialog/dialog.service';
import { udfsyncService } from 'app/services/udfsync/udfsync.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { UDFSectionFieldChangedEventData } from '../udf_fieldComponent/udf_field.component';
import { UDFSectionConfig, UDFSectionEmitData } from '../udf_sectionComponent/udf_section.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-udfsectionandfields',
	templateUrl: './udfsectionandfields.template.html'
})

export class udfsectionandfieldsComponent extends NBaseComponent implements OnInit {
	// sections = ['Basic', 'Complex', 'Useless', 'Piece', 'Of'];
	@Input('udfSections') udfSections: UDFSectionConfig[] = <UDFSectionConfig[]>[];
	@Input('modulename') modulename: 'contact' | 'deal' = 'contact';
	@Input('disableActions') disableActions = false;
	@Output('udfSectionsChanged') configChanged = new EventEmitter<UDFSectionConfig[]>();

	private _addsub: Subscription;
	private initialChange = true;
	private _fieldChangeSub: Subscription;

	constructor(private _ds: dialogService,
		private _ps: NPubSubService,
		private _udfSync: udfsyncService) {
		super();
	}

	ngOnInit() {
		this._fieldChangeSub = this._ps.$sub(PSChnnl.udfSectionFieldChanged, (e: UDFSectionFieldChangedEventData) => {
			this.fieldChanged(e);
		});
		this._addsub = this._ps.$sub(PSChnnl.udfAddSection, (d) => {
			if (!this.disableActions) {
				this.addEmptySection();
			}
		});
	}

	ngOnChanges(scs: SimpleChanges) {
		if (scs.udfSections?.currentValue?.length && this.initialChange) {
			// no need to trigger validation as the newly added section's name will always be empty and that validation is triggered for the section name formcontrol within section component
			this._updateSectionNamesInCacheAndOptionallyTriggerValidation(false);
			this.initialChange = false;
		}
	}

	addEmptySection() {
		const emptySec: UDFSectionConfig = {
			field_section: '', fields: [
				{
					field_name: U.generateUUID(),
					field_placeholder: 'Default Field',
					field_section: '',
					field_ui_type: 'text',
					field_is_required: false,
					field_sort_order: null,
					field_options: null,
					field_value: '',
					dependency_field_name: null,
					dependency_field_value: null,
					dependency_comparator: null,
					update_by_dependency_as: null,
					field_country_code_field: null,
					field_country_code_value: null,
				}
			]
		};
		this.udfSections.push(emptySec);
		this._updateSectionNamesInCacheAndOptionallyTriggerValidation();
	}

	UDFSectionAdded(e: UDFSectionEmitData) {
		this._scrollElmIntoView(e.data.hostDOM);
	}

	moveUp(conf: UDFSectionEmitData, curI: number) {
		this.udfSections = U.swap(this.udfSections, curI, curI - 1);
		this._updateSectionNamesInCacheAndOptionallyTriggerValidation();
		this._scrollElmIntoView(conf.data.hostDOM);
		this.emitUdfSectionChanged();
	}

	moveDown(conf: UDFSectionEmitData, curI: number) {
		this.udfSections = U.swap(this.udfSections, curI, curI + 1);
		this._updateSectionNamesInCacheAndOptionallyTriggerValidation();
		this._scrollElmIntoView(conf.data.hostDOM);
		this.emitUdfSectionChanged();
	}

	deleteSection(conf: UDFSectionEmitData, i: number) {
		if (!conf.section.field_section) {
			U.deleteAtIdx(this.udfSections, i);
			this._updateSectionNamesInCacheAndOptionallyTriggerValidation();
		} else {
			const opts: ConfirmOpts = {
				header: `Delete ${conf.section.field_section} Section`,
				subtitle: `Deleting the section will delete all existing fields and respective data within the section.`,
				dangerWarning: 'Confirm Delete?'
			};
			this._ds.openConfirmationDialog(opts).subscribe((v) => {
				if (v && v.option === 'yes') {
					U.deleteAtIdx(this.udfSections, i);
					this._updateSectionNamesInCacheAndOptionallyTriggerValidation();
					this.emitUdfSectionChanged();
				}
			});
		}
	}

	updateSectionNames(conf: UDFSectionEmitData) {
		this._updateSectionNamesInCacheAndOptionallyTriggerValidation();
		this.emitUdfSectionChanged();
	}

	private _scrollElmIntoView(elm: HTMLElement, top = true) {
		setTimeout(() => elm.scrollIntoView(!!top))
	}

	fieldChanged(ufsce: UDFSectionFieldChangedEventData) {
		const udfSectionConfig = this.udfSections.find(u => u.field_section === ufsce.ufc.field_section);
		switch (ufsce.action) {
			case 'add':
				udfSectionConfig.fields.push(ufsce.ufc);
				break;
			case 'update':
				let field = udfSectionConfig.fields.find(f => f.field_name === ufsce.ufc.field_name);
				// if (!field) {
				// 	ufsce.action = 'add';
				// 	udfSectionConfig.fields.push(ufsce.ufc);
				// } else {
				Object.assign(field, ufsce.ufc);
				// }
				break;
			case 'moved':
				break;
			case 'delete':
				break;
			default:
				break;
		}
		this.emitUdfSectionChanged();

	}

	emitUdfSectionChanged() {
		this.configChanged.next(this.udfSections);
	}

	private _updateSectionNamesInCacheAndOptionallyTriggerValidation(triggerValidation = true) {
		this._udfSync.setc(this.udfSections.map(s => s.field_section), CacheKey.udfSectionNames);
		if (triggerValidation) {
			this._ps.$pub(PSChnnl.udfSectionValidateName);
		}
	}

	ngOnDestroy() {
		this._addsub.unsubscribe();
		this._fieldChangeSub.unsubscribe();
		this._udfSync.deletec(CacheKey.udfSectionNames);
	}
}
