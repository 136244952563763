<div fxLayout="column " fxFlex="100" fxLayoutGap="6px" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" class="lr-padding border-radius card-like" fxFlex="60px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <h2>
      DAILY SUMMARY</h2>
  </div>
  <div fxLayout="column " fxFlex="calc(100%-66px)" fxLayoutGap="4px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " class="layout-padding lr-padding tb-padding" fxFlex="50" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" class="height-100 border-radius card-like" fxFlex="32.8" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let summary of summaryListArr">
        <bh-summaryinfolist [summary]="summary" (summaryDetail)="viewSummaryDetails($event.value)" (updateTaskStatus)="setTaskDone($event)"></bh-summaryinfolist>
      </div>
    </div>
    <div fxLayout="row " class="layout-padding lr-padding tb-padding" fxFlex="50" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" class="height-100 border-radius card-like" fxFlex="32.8" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let summary of feedbackArr">
        <bh-summaryinfolist [summary]="summary" (summaryDetail)="viewSummaryDetails($event.value)" (feedbackEvent)="viewFeedbackDetails($event.value)"></bh-summaryinfolist>
      </div>
      <div fxLayout="column wrap" class="height-100 border-radius card-like" fxFlex="32.8" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let value of dealStageArr">
        <bh-summarystageinfolist class="width-100 height-100" [stageValue]="value"></bh-summarystageinfolist>
      </div>
    </div>
  </div>
</div>