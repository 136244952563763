<div fxLayout="row wrap" style="padding-top: 10px;" class="lr-padding" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true" *ngIf="labelsCount &gt; 0">
  <bh-filtercount [labelsCount]="labelsCount" [labels]="filters"></bh-filtercount>
</div>
<div fxLayout="column wrap" fxLayoutGap="1em" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="mycontacts &amp;&amp; mycontacts.length === 0" fxFill>
  <div class="no-contact-image">
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <h2 class="light-gray-color">
      No Contacts Available</h2>
  </div>
</div>
<div fxLayout="column " class="height-100 common-lead-list-padding" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="mycontacts &amp;&amp; mycontacts.length &gt; 0" [ngClass.xs]="&apos;circle-padding absolute-position width-100&apos;">
  <div fxLayout="row wrap" class="lead-list-header" fxFlex="48px" fxLayoutGap="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="lr-padding" fxFlex="5" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;lead-header-field-gt-xs&apos;" *ngxPermissionsOnly="[&apos;CONTACT_REASSIGN&apos;]">
        <mat-checkbox class="select-all-check" [(ngModel)]="selectAllCheck" (change)="selectAll($event)" [checked]="false" color="var(--color-white)" [required]="true" [disabled]="disableCheck"></mat-checkbox>
      </div>
      <div fxLayout="row " class="lr-padding" fxFlex="19" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;lead-header-field-gt-xs&apos;" *ngFor="let header of headers" [fxFlex.sm]="header.displayText != &apos;Last Updated Date&apos; ? &apos;18&apos; : &apos;23&apos; ">
        <h3 class="color-view">
          {{header.displayText}}</h3>
        <bh-sort [sortKey]="header.sortKey" [sort]="header.sortValue" (changeEvent)="sortChange($event)" *ngIf="header.sort" [ngClass]="(header.sortKey != currentSortKey) ? &apos;not-sorting-icon&apos; : &apos;&apos;"></bh-sort>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y" fxFlex="calc(100% - 48px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="&apos;border-radius-4 circle-padding background-primary&apos;">
    <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div fxLayout="column wrap" class="display-block leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let contact of mycontacts" (click)="navigateToContact(contact.contactid)" [ngClass.xs]="&apos;menu-margin-xs&apos;">
        <div fxLayout="row wrap" class="cursor-pointer color-black" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="contact" [ngClass.xs]="&apos;border-lightgray-1px border-radius-4&apos;">
          <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="5" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" click-stop-propagation *ngxPermissionsOnly="[&apos;CONTACT_REASSIGN&apos;]" [fxHide.xs]="true">
            <mat-checkbox class="reassign-checkbox" [(ngModel)]="contact.isSelected" (change)="onRowClick($event, contact.contactid, contact.contact_owner)" [required]="true" [disabled]="disableCheck"></mat-checkbox>
          </div>
          <div fxLayout="row " class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;" [fxFlex.sm]="18">
            <h3 class="font-normal-size text-ellipsis">
              <span *ngIf="contact.last_name || contact.first_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Name : </span>{{contact.first_name}} {{contact.last_name}}</span>
              <span *ngIf="!contact.last_name &amp;&amp; !contact.first_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Name : </span><span class="label-font-color">-------</span></span></h3>
          </div>
          <div fxLayout="row " class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;" [fxFlex.sm]="18">
            <h3 class="font-normal-size text-ellipsis">
              <span *ngIf="contact.phone_country_code || contact.phone_no"><span class="font-weight-size" [fxHide.gt-xs]="true">Mobile : </span>{{contact.phone_country_code}} {{contact.phone_no}}</span>
              <span *ngIf="!contact.phone_country_code &amp;&amp; !contact.phone_no"><span class="font-weight-size" [fxHide.gt-xs]="true">Mobile : </span><span class="label-font-color">-------</span></span></h3>
          </div>
          <div fxLayout="row " class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;" [fxFlex.sm]="18">
            <h3 class="font-normal-size text-ellipsis">
              <span *ngIf="contact.email_id"><span class="font-weight-size" [fxHide.gt-xs]="true">Email : </span>{{contact.email_id}}</span>
              <span *ngIf="!contact.email_id"><span class="font-weight-size" [fxHide.gt-xs]="true">Email : </span><span class="label-font-color">-------</span></span></h3>
          </div>
          <div fxLayout="row " class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;" [fxFlex.sm]="23">
            <h3 class="font-normal-size">
              <!-- {{val.lead_last_updated_at}} -->
              <span *ngIf="contact.updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span>{{contact.updated_at | date: &apos;dd&apos;}} - {{contact.updated_at | date:&apos;MM&apos;}} - {{contact.updated_at | date: &apos;yyyy&apos;}}, {{contact.updated_at | date: &apos;HH:mm&apos;}}</span>
              <span *ngIf="!contact.updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated : </span><span class="label-font-color">------</span>
              </span></h3>
          </div>
          <div fxLayout="row " class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngClass.gt-xs]="&apos;lr-padding&apos;" [ngStyle.xs]="&apos;border-top: 1px solid #b8bdc7&apos;" [fxFlex.sm]="18">
            <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="initialCall(contact.contactid)" click-stop-propagation matTooltip="Call" [fxFlex.xs]="25" fxLayoutAlign.xs="center center" [ngStyle.xs]="&apos;border-right: 1px solid  #b8bdc7&apos;">
              <div class="add-call-image">
              </div>
            </div>
            <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="createTask(contact)" click-stop-propagation matTooltip="Add Task" [fxFlex.xs]="25" fxLayoutAlign.xs="center center" [ngStyle.xs]="&apos;border-right: 1px solid  #b8bdc7&apos;">
              <div [ngClass.gt-xs]="&apos;common-lead-task-image&apos;" class="add-task-image">
              </div>
            </div>
            <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="scheduleAppointment(contact.contactid,contact.first_name+ &apos; &apos; +contact.last_name)" click-stop-propagation matTooltip="Add Appointment" [fxFlex.xs]="25" fxLayoutAlign.xs="center center" [ngStyle.xs]="&apos;border-right: 1px solid  #b8bdc7&apos;">
              <div class="add-appointment-image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column wrap" class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
      <div [fxHide.gt-xs]="true" class="overlay-scroll search-results height-100">
        <cdk-virtual-scroll-viewport infinite-scroll style="height: 100%" [itemSize]="160" [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="200" [scrollWindow]="false" (scrolled)="onScroll()">
          <div fxLayout="column wrap" class="display-block leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *cdkVirtualFor="let contact of mycontacts" (click)="navigateToContact(contact.contactid)" [ngClass.xs]="&apos;menu-margin-xs&apos;">
            <div fxLayout="row wrap" class="cursor-pointer color-black" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="contact" [ngClass.xs]="&apos;border-lightgray-1px border-radius-4 mobile-tap-view&apos;">
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="5" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" click-stop-propagation *ngxPermissionsOnly="[&apos;CONTACT_REASSIGN&apos;]" [fxHide.xs]="true">
                <mat-checkbox class="reassign-checkbox" [(ngModel)]="contact.isSelected" (change)="onRowClick($event, contact.contactid, contact.contact_owner)" [required]="true" [disabled]="disableCheck"></mat-checkbox>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="contact.last_name || contact.first_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Name : </span>{{contact.first_name}} {{contact.last_name}}</span>
                  <span *ngIf="!contact.last_name &amp;&amp; !contact.first_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Name : </span><span class="label-font-color">-------</span></span></h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="contact.phone_country_code || contact.phone_no"><span class="font-weight-size" [fxHide.gt-xs]="true">Mobile : </span>{{contact.phone_country_code}} {{contact.phone_no}}</span>
                  <span *ngIf="!contact.phone_country_code &amp;&amp; !contact.phone_no"><span class="font-weight-size" [fxHide.gt-xs]="true">Mobile : </span><span class="label-font-color">-------</span></span></h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="contact.email_id"><span class="font-weight-size" [fxHide.gt-xs]="true">Email : </span>{{contact.email_id}}</span>
                  <span *ngIf="!contact.email_id"><span class="font-weight-size" [fxHide.gt-xs]="true">Email : </span><span class="label-font-color">-------</span></span></h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size">
                  <!-- {{val.lead_last_updated_at}} -->
                  <span *ngIf="contact.updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span>{{contact.updated_at | date: &apos;dd&apos;}} - {{contact.updated_at | date:&apos;MM&apos;}} - {{contact.updated_at | date: &apos;yyyy&apos;}}, {{contact.updated_at | date: &apos;HH:mm&apos;}}</span>
                  <span *ngIf="!contact.updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated : </span><span class="label-font-color">------</span>
                  </span></h3>
              </div>
              <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="19" [ngClass.gt-xs]="&apos;lr-padding&apos;" [ngStyle.xs]="&apos;border-top: 1px solid #b8bdc7&apos;">
                <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="initialCall(contact.contactid)" click-stop-propagation [fxFlex.xs]="(!permissions) ? &apos;33.3&apos; : &apos;25&apos;" fxLayoutAlign.xs="center center" [ngStyle.xs]="&apos;border-right: 1px solid  #b8bdc7&apos;">
                  <div class="add-call-image">
                  </div>
                </div>
                <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="createTask(contact)" click-stop-propagation [fxFlex.xs]="(!permissions) ? &apos;33.3&apos; : &apos;25&apos;" fxLayoutAlign.xs="center center" [ngStyle.xs]="&apos;border-right: 1px solid  #b8bdc7&apos;">
                  <div [ngClass.gt-xs]="&apos;common-lead-task-image&apos;" class="add-task-image">
                  </div>
                </div>
                <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="scheduleAppointment(contact.contactid,contact.first_name+ &apos; &apos; +contact.last_name)" click-stop-propagation [fxFlex.xs]="(!permissions) ? &apos;33.3&apos; : &apos;25&apos;" fxLayoutAlign.xs="center center" [ngStyle.xs]="{&apos;border-right&apos;: (!permissions) ? &apos;&apos; : &apos;1px solid  #b8bdc7&apos;}">
                  <div class="add-appointment-image">
                  </div>
                </div>
                <div *ngxPermissionsOnly="[&apos;CONTACT_REASSIGN&apos;]" fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="reassign(contact)" click-stop-propagation [fxHide.gt-xs]="true" [fxFlex.xs]="(!permissions) ? &apos;33.3&apos; : &apos;25&apos;" fxLayoutAlign.xs="center center">
                  <div class="re-assign-image">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <div [ngClass.sm]="&apos;deal-page-label&apos;" class="pagination-gt-xs common-pagination">
      <mat-paginator [length]="totalCountP" [pageSize]="pageSizeP" [pageIndex]="pageOffsetP" [pageSizeOptions]="[50, 200, 500]" (page)="pageEvents($event)" [disabled]="!readyP"></mat-paginator>
    </div>
  </div>
</div>