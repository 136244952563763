/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormControl } from '@angular/forms';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dyninput',
    templateUrl: './dyninput.template.html'
})

export class dyninputComponent extends NBaseComponent implements OnInit, OnChanges {
    @Input('placeholder') placeholder = '__no_placeholder__';
    @Input('fc') fc = new FormControl();
    @Input('required') required = false;
    @Input('field_name') field_name = '';
    display = false;
    constructor() {
        super();
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fc) {
            this.display = true;
        }
    }
}
