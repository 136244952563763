/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { tenantservice } from 'app/sd-services/tenantservice';
import { FormControl } from '@angular/forms';


/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-setting_curr',
    templateUrl: './setting_curr.template.html',
    styleUrls: ['./setting_curr.style.scss']
})

export class setting_currComponent extends NBaseComponent implements OnInit {
    currencyId;
    currencyCode: FormControl = new FormControl();
    disableAddButton = false;
    constructor(
        private pubsub: NPubSubService,
        private tenantService: tenantservice
    ) {
        super();
    }

    ngOnInit() {
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }
    saveCurrencyValue() {
        if (this.currencyCode.value && this.currencyCode.value.currencyid) {
            this.disableAddButton = true;
            this.tenantService.setTenantSettingValue('CURRENCY_CODE', this.currencyCode.value.currencyid).then((data) => {
                this.pubsub.$pub('refresh-settings');
                this.close();
            }).catch(err=>{
                this.disableAddButton= false;
            })
        }

    }
}
