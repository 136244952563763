/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, ViewChild, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormBuilder, Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { NHTTPLoaderService } from 'neutrinos-seed-services';
// import { MatExpansionPanel } from '@angular/material';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';

import { dyninputComponent } from 'app/components/dyninputComponent/dyninput.component';
import { dynemailComponent } from 'app/components/dynemailComponent/dynemail.component';
import { dynphonenoComponent } from 'app/components/dynphonenoComponent/dynphoneno.component';
import { dynselectComponent } from 'app/components/dynselectComponent/dynselect.component';
import { dyndatepickerComponent } from 'app/components/dyndatepickerComponent/dyndatepicker.component';
import { dyncountryComponent } from 'app/components/dyncountryComponent/dyncountry.component';
import { dynnumberComponent } from 'app/components/dynnumberComponent/dynnumber.component';
import { dynmoneyComponent } from 'app/components/dynmoneyComponent/dynmoney.component';
import { dyncontactsearchComponent } from 'app/components/dyncontactsearchComponent/dyncontactsearch.component';
import { dynnationalityComponent } from 'app/components/dynnationalityComponent/dynnationality.component';

interface UDF {
    field_name: string; // identifier for a field
    field_order: number; // sort order for the field, it is sorted by API response
    field_value: any; // Value of the field
    field_options?: any[] // Select options if the ui_field_type is of type select
    field_section?: string; // label for the field section
    field_ui_type: string; // mobilenumber | number | email | input | select
    field_is_required?: boolean; // whether the field is required
    field_placeholder: string;
    fieldInputOptions?: any; // for the UI
    field_ui?: any; // for the UI,
    field_country_code_value?: any; // for country code value for mobilenumber | phonenumber
    field_country_code_field?: any; // for country code field name for mobilenumer | phonenumber
    field_currency_code_field?: any; // for currency code field name for money
}

interface UDFS {
    field_section_name: string; // identifier for field section
    field_section: string; // label for field section
    fields: Array<UDF>;
}

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dynform',
    templateUrl: './dynform.template.html'
})

export class dynformComponent extends NBaseComponent implements OnInit, OnChanges {
    // TODO: @paulthomas0 When Neutrinos Studio bug for type definition is fixed. This should be properly typed.
    @Input('udf_for_view') udf_for_view = []; // Array<UDFS>; interface above
    @Output('changeEvent') changeEvent = new EventEmitter();
    // @ViewChild('panel', { static: true }) panel: any;
    @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;

    displayForm = false; // Flag for displaying form, to enable loading of all configuration before showing the form
    dynamicFormGroup: FormGroup = new FormGroup({}); // The value of this form group is lead_udf value
    panelCount;

    constructor(private fb: FormBuilder, private loader: NHTTPLoaderService) {
        super();
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.udf_for_view && changes.udf_for_view.currentValue instanceof Array &&
            changes.udf_for_view.currentValue.length) {
            this.toggleChange(this.panelCount);
            const lufv = this.udf_for_view;
            lufv.map((section: UDFS, sectionIndex) => {
                section.fields.map((field: UDF, fieldIndex) => {
                    const fc = new FormControl();
                    fc.setValue(field.field_value);

                    if (field.field_is_required) {
                        fc.setValidators([Validators.required]);
                    }

                    this.dynamicFormGroup.setControl(field.field_name, fc);

                    // @Inputs
                    field.fieldInputOptions = this.setFormControlConfig(field, field.field_ui_type, fc);
                    field.field_ui = this.getComponentClass(field.field_ui_type);
                })
            })
            this.displayForm = true;
        }
    }

    toggleChange(index) {
        this.panelCount = index;
    }

    toggleClose(index) {
        if (this.panelCount === index) {
            this.panelCount = -1;
        }
    }

    // TODO: Handle attribute case senstivity.
    getComponentClass(componentKey: string): any {
        let componentClass: any;
        componentKey = <'input' |
            'select' |
            'phonenumber' |
            'email'>componentKey.toLowerCase();
        switch (componentKey) {
            case 'text':
                componentClass = dyninputComponent;
                break;
            case 'email':
                componentClass = dynemailComponent;
                break;
            case 'phonenumber':
            case 'mobilenumber':
                componentClass = dynphonenoComponent;
                break;
            case 'select':
                componentClass = dynselectComponent;
                break;
            case 'date':
                componentClass = dyndatepickerComponent;
                break;
            case 'country':
                componentClass = dyncountryComponent;
                break;
            case 'number':
                componentClass = dynnumberComponent;
                break;
            case 'money':
                componentClass = dynmoneyComponent;
                break;
            case 'contactsearch':
                componentClass = dyncontactsearchComponent;
                break;
            case 'nationality':
                componentClass = dynnationalityComponent;
                break;
            default:
                componentClass = dyninputComponent;
                break;
        }
        return componentClass;
    }

    changeCallbackFn(field_name, value) {
        this.changeEvent.emit({
            field_name,
            value
        })
    }

    setFormControlConfig(field: UDF, field_ui_type, fc: FormControl) {
        let inputs: any = {
            placeholder: field.field_placeholder,
            required: field.field_is_required,
            fc: fc,
            field_name: field.field_name,
            changeCallbackFn: this.changeCallbackFn.bind(this)
        }
        switch (field_ui_type) {
            case 'phonenumber':
            case 'mobilenumber':
                const cc = new FormControl();
                cc.setValue(field['field_country_code_value'])
                inputs['phoneCountryCodeControl'] = cc;
                this.dynamicFormGroup.setControl(field.field_country_code_field, cc);
                break;
            case 'select':
                inputs.selectOptions = field.field_options;
                break;
            case 'money':
                const cuc = new FormControl();
                cuc.setValue(field['field_currency_code_value']);
                inputs['currencyCodeControl'] = cuc;
                this.dynamicFormGroup.setControl(field.field_currency_code_field, cuc);
                break;

        }
        return inputs;
    }
}
