/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CacheKey, Page, PubSubChannel } from 'app/constants';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { dialogService } from 'app/services/dialog/dialog.service';
import { udfsyncService } from 'app/services/udfsync/udfsync.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { UDFConfig, UDFSectionFieldChangedEventData } from '../udf_fieldComponent/udf_field.component';
import { UDFSectionConfig } from '../udf_sectionComponent/udf_section.component';
import { Header } from '../usersComponent/users.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-udfdependencies',
	templateUrl: './udfdependencies.template.html'
})

export class udfdependenciesComponent extends NBaseComponent implements OnInit, OnDestroy {
	@Input('depFields') depFields: UDFConfig[] = <UDFConfig[]>[];
	@Input('disableActions') disableActions = false;

	headers: Header[] = [
		{ displayText: 'Dependency Mapping' },
		// { displayText: 'Updated By' },
		// { displayText: 'Updated Date' },
		{ displayText: 'Actions' }
	]
	fxFlex = 100 / this.headers.length;
	initialized = false;
	fieldNameToFieldMap: { [key: string]: UDFConfig };

	private _addsub: Subscription;

	constructor(private _ps: NPubSubService,
		public _udfSync: udfsyncService,
		private _ds: dialogService) {
		super();
	}

	ngOnInit() {
		this._addsub = this._ps.$sub(PubSubChannel.udfAddDep, (d) => {
			this._openAddDepForm();
		});
	}

	private _openAddDepForm() {
		if (!this.disableActions) {
			this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
				ACTION: 'open',
				PAGE: Page.UDFDepForm
			});
		}
	}

	openEditDepForm(f: UDFConfig) {
		if (!this.disableActions) {
			this._udfSync.setc(U.JSONClone(f), CacheKey.depUdfEditForm);
			this._openAddDepForm();
		}
	}

	deleteDep(f: UDFConfig) {
		if (!this.disableActions) {
			this._ds.openConfirmationDialog({
				header: 'DELETE DEPENDENCY',
				title: `Deleting the dependency will delete all the dependency configuration for ${this._udfSync.fieldNameToFieldMap[f.field_name].field_placeholder} field`,
				dangerWarning: 'Confirm Delete?'
			}).subscribe((v) => {
				if (v?.option === 'yes') {
					this._udfSync.removeUDFDepConfig(f);
					// May be use a different channel for updating dependencies instead, when there is time.
					this._ps.$pub(PubSubChannel.udfSectionFieldChanged, <UDFSectionFieldChangedEventData>{
						action: 'update',
						ufc: Object.assign(this._udfSync.fieldNameToFieldMap[f.field_name], f)
					});
				}
			});
		}
	}

	ngOnDestroy() {
		this._addsub.unsubscribe();
		this._udfSync.deletec(CacheKey.depUdfEditForm);
	}
}
