/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Inject } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { callcontactsservice } from 'app/sd-services/callcontactsservice';
import { MatRadioChange } from '@angular/material/radio';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-ldcall',
    templateUrl: './ldcall.template.html'
})

export class ldcallComponent extends NBaseComponent implements OnInit {
    selectedValue: number;
	selectedPhData: ModulePhoneNumber;


    constructor(private dialogRef: MatDialogRef<ldcallComponent>,
		@Inject(MAT_DIALOG_DATA) public data: LDCallDialogData,
        private callContactsService: callcontactsservice) {
        super();
    }

    ngOnInit() {
        if (this.data && this.data.hasOwnProperty('phoneNumber') && Array.isArray(this.data.phoneNumber) && this.data.phoneNumber.length > 0) {
			this.setSelectedValue(0);
        }
    }


	setSelectedValue(idx: number) {
		this.selectedValue = this.data.phoneNumber[idx].value;
		this.selectedPhData = this.data.phoneNumber[idx];
    }

	makeCall(value: LDCallDialogData) {
        if (this.data['modulename'] === 'contacts') {
			this.callContactsService.makeCallToContact(this.selectedValue, value.moduleid, this.selectedPhData.phone_country_code).then(result => {
                this.close();
            }, (err) => {
                return false;
            });
        }

        if (this.data['modulename'] === 'deals') {
			this.callContactsService.makeCallToDeal(this.selectedValue, value.contactid, value.moduleid, this.selectedPhData.phone_country_code).then(result => {
                this.close();
            }, (err) => {
                return false;
            })
        }
    }

    close() {
        this.dialogRef.close();
    }
}

export interface ModulePhoneNumber {
	label: string,
	phone_country_code: string,
	phone_no: number,
	displayValue: string,
	value: number
}

export interface LDCallDialogData {
	phoneNumber: ModulePhoneNumber[],
	moduleid: number,
	modulename: string,
	phoneData: string,
	dealid: number,
	contactid: number,
}