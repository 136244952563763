/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NPubSubService } from 'neutrinos-seed-services';
import { sort_by_listComponent } from '../../components/sort_by_listComponent/sort_by_list.component';


@Injectable()
export class sortdailogService {
    sortData;

    constructor(public dialog: MatDialog,
        private pubsub: NPubSubService) {
    }

    setSortObj(obj) {
        this.sortData = obj;
    }

    sortList() {
        return new Promise((resolve, reject) => {
            let windowProp = {
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                panelClass: 'sortDialog',
                hasBackdrop: false,
                data: this.sortData,
                autoFocus: false
            }
            const dialogRef = this.dialog.open(sort_by_listComponent, windowProp).afterClosed().subscribe(async res => {
                if (res) {
                    this.pubsub.$pub(res.sort_key, { res });
                    return resolve(res);
                }
            });
        });
    }

}
