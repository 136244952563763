/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilityMethods } from 'app/functions/UtilityMethods';
import { cacheservice } from 'app/sd-services/cacheservice';
import { taskservice } from 'app/sd-services/taskservice';
import { labelService } from 'app/services/label/label.service';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { dialogService } from '../../services/dialog/dialog.service';
import { dyndealssearchComponent } from '../dyndealssearchComponent/dyndealssearch.component';
import { Contact } from '../contactdetailsComponent/contactdetails.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/
export const PICK_FORMATS = {
    parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};
/* TODO
** Change time 00:00 am to 12:00 am 
*/
class PickDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            return formatDate(date, 'dd-MM-yyyy', this.locale);;
        } else {
            return date.toDateString();
        }
    }
}
@Component({
    selector: 'bh-addtask',
    templateUrl: './addtask.template.html',
    styleUrls: ['./addtask.style.scss'],
    providers: [
        { provide: DateAdapter, useClass: PickDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
    ]
})

export class addtaskComponent extends NBaseComponent implements OnInit, OnDestroy {
    readonly page_size = 100;
    contact_page_no = 1;
    deal_page_no = 1;
    autoContacts = [];
    autoDeals = [];
    todayDate: string;
    currentTime: string;
    addTaskFormGroup: FormGroup;
    selectedValue;
    deal_id;
    selectedTaskValue;
    taskLabelName;
    isReadonly: boolean = false;
    errorMsg = "";
    disableAddButton = false;
    readonly timeSugestionValues = ["00:00am", "00:15am", "00:30am", "00:45am", "01:00am", "01:15am", "01:30am", "01:45am", "02:00am", "02:15am", "02:30am", "02:45am", "03:00am", "03:15am", "03:30am", "03:45am", "04:00am", "04:15am", "04:30am", "04:45am", "05:00am", "05:15am", "05:30am", "05:45am", "06:00am", "06:15am", "06:30am", "06:45am", "07:00am", "07:15am", "07:30am", "07:45am", "08:00am", "08:15am", "08:30am", "08:45am", "09:00am", "09:15am", "09:30am", "09:45am", "10:00am", "10:15am", "10:30am", "10:45am", "11:00am", "11:15am", "11:30am", "11:45am", "12:00pm", "12:15pm", "12:30pm", "12:45pm", "01:00pm", "01:15pm", "01:30pm", "01:45pm", "02:00pm", "02:15pm", "02:30pm", "02:45pm", "03:00pm", "03:15pm", "03:30pm", "03:45pm", "04:00pm", "04:15pm", "04:30pm", "04:45pm", "05:00pm", "05:15pm", "05:30pm", "05:45pm", "06:00pm", "06:15pm", "06:30pm", "06:45pm", "07:00pm", "07:15pm", "07:30pm", "07:45pm", "08:00pm", "08:15pm", "08:30pm", "08:45pm", "09:00pm", "09:15pm", "09:30pm", "09:45pm", "10:00pm", "10:15pm", "10:30pm", "10:45pm", "11:00pm", "11:15pm", "11:30pm", "11:45pm"];
    taskFormObj: any = {};
    dealDisabled = true;

    editingConId: number;
    editingDealId: number;
    dealContactId: number;
    relatedContactPlaceholder = 'Related Contact';
    relatedDealPlaceholder = 'Ex. $7000 Life Insurance';
    relatedDealLabel = 'Related ';
    dsearchmode = 'EDIT';
    csearchmode = 'EDIT';
    defaultContactValues: any[];
    defaultDeals: { deal_name: any; dealid: any; }[];

    @ViewChild(dyndealssearchComponent) dealSearch: dyndealssearchComponent;


    get disableLooking() {
        return this.taskLabelName == 'VIEW TASK' ? 'disabled-appearance' : ''
    }
    get isViewing() {
        return this.taskLabelName === 'VIEW TASK';
    }
    get isCreating() {
        return this.taskLabelName === 'ADD TASK';
    }
    get isEditing() {
        return this.taskLabelName === 'EDIT TASK';
    }

    constructor(
        private pubsub: NPubSubService,
        private snackBar: MatSnackBar,
        private taskS: taskservice,
        private cacheService: cacheservice,
        private dialog: dialogService,
        private labelS: labelService
    ) {
        super();
        // this.getDealNameByID(27); //get deals name based if task is created based on deal
    }

    ngOnInit() {
        this.relatedDealLabel = this.relatedDealLabel + this.labelS.toTitleCase(this.labelS.deal);
        this.todayDate = this.getTodayDate();
        // this.currentTime = this.hourwithAMPM();
        this.selectedValue = 'No';
        let nextFromDateTime = this.nextmod15DateTime();
        this.currentTime = this.hourwithAMPMNew(nextFromDateTime);
        this.addTaskFormGroup = new FormGroup({
            title: new FormControl('', [Validators.required]),
            contact: new FormControl(''),
            deal: new FormControl(''),
            description: new FormControl(''),
            duedate: new FormControl(new Date(), [Validators.required]),
            duetime: new FormControl(this.currentTime, [Validators.required]),
            completed: new FormControl(''),
        })

        this.cacheService.getc('EDIT_TASK_FORM').then((result) => {
            this.taskFormObj = result.local.data;
            if (!this.taskFormObj) {
                return;
            }
            if (this.taskFormObj.taskIdObj) {
                this.taskLabelName = 'VIEW TASK';
                this.isReadonly = true;
                this.addTaskFormGroup.get('completed').disable();
                if (this.taskFormObj.taskIdObj['contactid']) {
                    this.csearchmode = 'VIEW';
                    this.defaultContactValues = [{ 'contactid': this.taskFormObj.taskIdObj.contactid, display_name: this.taskFormObj.taskIdObj.contact_name }];
                    this.setEditingCID(this.taskFormObj.taskIdObj.contactid);
                }
                if (this.taskFormObj.taskIdObj['dealid']) {
                    this.dsearchmode = 'VIEW';
                    this.defaultDeals = [{ deal_name: this.taskFormObj.taskIdObj.deal_name, dealid: this.taskFormObj.taskIdObj.dealid }];
                    this.editingDealId = this.taskFormObj.taskIdObj['dealid'];
                }
            }
            if (this.taskFormObj.taskEventObj) {
                this.taskLabelName = 'VIEW TASK';
                this.selectedValue = this.taskFormObj.taskEventObj['completed'];
                this.addTaskFormGroup.patchValue(this.taskFormObj.taskEventObj);
            }
            // This cache exists when adding task from `contactdetails` page
            // this cache SHOULD ONLY be cleared by `contactdetails` page
            this.cacheService.getc('CONTACT_ID_VALUE').then((result) => {
                if (result && result.local.data) {
                    const d = <Contact>result.local.data;
                    this.defaultContactValues = [d];
                    this.setEditingCID(d.contactid);
                }
            })
            // This cache exists when adding task from `dealdetails` page and
            // this cache SHOULD ONLY be cleared by `dealdetails` page
            this.cacheService.getc('DEAL_ID_VALUE').then((result) => {
                if (result && result.local.data) {
                    const data = result.local.data;
                    this.defaultDeals = [{ deal_name: data.dealName, dealid: data.dealId }];
                    this.defaultContactValues = [{ 'contactid': data['contactId'], 'first_name': data['first_name'], 'last_name': data['last_name'], display_name: data.contactName }];
                    this.initCD({
                        contactid: data.contactId,
                        contact_first_name: data.first_name,
                        contact_last_name: data.last_name,
                        dealid: data.dealId,
                        deal_name: data.dealName
                    });
                }
            });
            this.cacheService.setc(null, 'EDIT_TASK_FORM');
        }).catch(e => console.log(e));
        this.taskLabelName = 'ADD TASK';
        this.cacheService.getc('CREATE_TASK_FORM').then((result) => {
            if (result && result.local.data) {
                const d = result.local.data;
                this.defaultDeals = [{ 'dealid': d.dealid, deal_name: d.deal_name }];
                this.defaultContactValues = [{ 'contactid': d.contactid, display_name: d.contact_name || ((d.first_name || '') + (d.last_name || '')) }];
                this.editingDealId = d.dealid;
                this.setEditingCID(result.local.data['contactid']);
            }
        }).catch(e => console.log(e));
    }

    // intializing contact and deal details for auto-fill
    initCD(data: {
        contactid: number,
        dealid?: number,
        contact_first_name: string,
        contact_last_name: string,
        deal_name?: string
    }) {
        this.setEditingCID(data.contactid);
        // This is being done for my deals page
        // when both dealid and contactid is present
        // and has to be autopopulated
        if (data.dealid) {
            this.editingDealId = data.dealid;
        }
    }

    hourwithAMPMNew(inputDate: Moment) {
        let d = _moment();
        if (inputDate) {
            d = _moment(inputDate);
        }
        let ampm = (d.hour() >= 12) ? "pm" : "am";
        let hours = (d.hour() > 12) ? d.hour() - 12 : d.hour();
        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.minute() < 10 ? '0' + d.minute() : d.minute()) + ampm;
    }

    nextmod15DateTime() {// get next time after 15 mins
        let d = _moment();
        let currentMinutes = d.minutes();
        currentMinutes %= 15;
        d.add(15 - currentMinutes, 'm');
        return d;
    }


    setFromTimeScroll() {
        if (!document.querySelector(`.from-${this.addTaskFormGroup.get('duetime').value.replace(':', '_')}`)) {
            return;
        }
        //scroll to the current time
        this.scrollTocurrentTime(
            document.querySelector(`.from-${this.addTaskFormGroup.get('duetime').value.replace(':', '_')}`)
        );
    }
    scrollTocurrentTime(timeContainer: Element) {
        timeContainer.scrollIntoView();
    }

    setEditingCID(contactid: number) {
        this.editingConId = contactid;
        this.dealDisabled = false;
        // Searching with "e" as it is the most frequent english letter
        this.dealSearch && !this.isViewing && this.dealSearch.search('e', contactid);
    }

    hourwithAMPM() {
        let d = new Date();
        let ampm = (d.getHours() >= 12) ? "pm" : "am";
        let hours = (d.getHours() > 12) ? d.getHours() - 12 : d.getHours();

        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) + ampm;

    }

    getTodayDate() {
        let d = new Date();
        return `${d.getDate() < 10 ? '0' + d.getDate().toString() : d.getDate()}-${d.getMonth() < 10 ? '0' + d.getMonth().toString() : d.getMonth()}-${d.getFullYear()}`;
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }

    cancel() {
        if (this.taskLabelName == 'ADD TASK') {
            this.close();
        }
        if (this.taskLabelName == 'EDIT TASK') {
            this.taskLabelName = 'VIEW TASK';
            this.isReadonly = true;
        }
        this.addTaskFormGroup.get('completed').disable();
    }

    getDateString(date_value) {
        var dt = date_value;
        var year = dt.getFullYear();
        var month = (dt.getMonth() + 1).toString().padStart(2, "0");
        var day = dt.getDate().toString().padStart(2, "0");
        return year + '-' + month + '-' + day;
    }

	validateDataBeforeInsert() {
		if (this.addTaskFormGroup.get('title').invalid) {
			this.errorMsg = 'Invalid Title';
		} else if (this.addTaskFormGroup.get('contact').invalid) {
			this.errorMsg = 'Invalid Related Contact';
		} else if (this.addTaskFormGroup.get('deal').invalid) {
			this.errorMsg = 'Invalid Related Deal';
		}
		return this.addTaskFormGroup.valid;
	}

    add() {
        this.errorMsg = "";
        if (!this.addTaskFormGroup.value.duedate.length && !this.addTaskFormGroup.value.duetime.length) {
			this.errorMsg = 'Invalid due date';
            return false;
        }

        let due_date_value = new Date(this.addTaskFormGroup.get('duedate').value);
        let due_date_string = this.getDateString(due_date_value);

        const date = due_date_string;
        const time = UtilityMethods.timeampmTo24(this.addTaskFormGroup.get('duetime').value);

        let date_time = _moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').format();
        let due_date_time = new Date(date_time);

        if (this.selectedValue == 'Yes') {
            this.selectedTaskValue = true;
        } else {
            this.selectedTaskValue = false;
        }
        this.addTaskFormGroup.patchValue({ completed: this.selectedValue })
		if (this.validateDataBeforeInsert()) {
            var taskDetails = {
                "task_title": this.addTaskFormGroup.value.title,
                "task_description": this.addTaskFormGroup.value.description,
                "due_date": due_date_time,
                "task_status": this.selectedTaskValue
            }
            var taskData = {};
            taskData['task_title'] = this.addTaskFormGroup.value.title;
            taskData['task_description'] = this.addTaskFormGroup.value.description;
            taskData['due_date'] = due_date_time;
			taskData['task_status'] = this.selectedTaskValue;
            if (this.taskLabelName == 'ADD TASK') {
                if (this.addTaskFormGroup.value.contact && !this.addTaskFormGroup.value.deal) {
                    taskData['contactid'] = this.addTaskFormGroup.value.contact;
                    this.createTaskValue(taskData);
                } else if (this.addTaskFormGroup.value.deal && !this.addTaskFormGroup.value.contact) {
                    taskData['dealid'] = this.addTaskFormGroup.value.deal;
                    this.createTaskValue(taskData);
                } else if (this.addTaskFormGroup.value.contact && this.addTaskFormGroup.value.deal) {
                    taskData['contactid'] = this.addTaskFormGroup.value.contact;
                    taskData['dealid'] = this.addTaskFormGroup.value.deal;
                    this.createTaskValue(taskData);
                } else if (!this.addTaskFormGroup.value.contact && !this.addTaskFormGroup.value.deal) {
                    this.createTaskValue(taskData);
                }
            } else {
				this.disableAddButton = true;
                taskDetails['leadid'] = null;
                taskDetails['contactid'] = this.addTaskFormGroup.value.contact || null;
                taskDetails['dealid'] = this.addTaskFormGroup.value.deal || null;
                this.taskS.updateTask(this.taskFormObj.taskIdObj['activitytaskid'], taskDetails).then((res) => {
                    if (res) {
                        this.snackBar.open('Task successfully updated.', 'OK', {
                            duration: 3000
                        })
                        this.pubsub.$pub('refreshtasklist');
                        this.pubsub.$pub('refreshevents');
                        this.close();
                    }
					this.disableAddButton = false;
				}).catch(e => {
					this.disableAddButton = false;
				})
			}
        }
    }

    createTaskValue(payload) {
		this.disableAddButton = true;
        this.taskS.createTask(payload).then((res) => {
            this.successMsg(res);
			this.disableAddButton = false;
		}).catch(e => {
			this.disableAddButton = false;
		});
    }

    successMsg(res) {
        if (res) {
            this.snackBar.open('Task successfully added.', 'OK', {
                duration: 3000
            })
            this.pubsub.$pub('refreshtasklist');
            this.pubsub.$pub('refreshevents');
            this.close();
        }
    }

    editTask() {
        this.taskLabelName = 'EDIT TASK';
        this.isReadonly = false;
        this.addTaskFormGroup.get('completed').enable();
    }

    contactSelected(id) {
        if (id !== this.editingConId) {
            this.editingDealId = null;
            this.setEditingCID(id);
        }
        this.dealDisabled = false;
    }

    dealSelected($event) {
        this.editingDealId = $event;
    }

    deleteTask() {
        this.dialog.taskDeleteDialog(this.taskFormObj.taskIdObj);
    }

    dupTask(){
        this.taskLabelName = 'ADD TASK';
        this.isReadonly = false;
        this.addTaskFormGroup.get('completed').enable();
        let nextFromDateTime = this.nextmod15DateTime();
        let currentTime = this.hourwithAMPMNew(nextFromDateTime);
        this.addTaskFormGroup.get('duedate').setValue(new Date());
        this.addTaskFormGroup.get('duetime').setValue(currentTime);
        this.addTaskFormGroup.get('completed').setValue('No');
    }

    ngOnDestroy() {
        this.cacheService.deletec('CREATE_TASK_FORM');
    }
}
