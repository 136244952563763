/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-settinguserandroles',
	templateUrl: './settinguserandroles.template.html'
})

export class settinguserandrolesComponent extends NBaseComponent implements OnInit, AfterViewInit, OnDestroy {

	pageSize = 50;
	pageOffset = 0;
	totalCount = 0;
	progressCheck = true;
	tabLabelFormMap = {
		USERS: {
			form: 'FORM_ADD_USER',
			evtChnl: 'PAGE_EVENT_SETTING_USERS',
			// resolvers: ['_cacheRolesLiteData']
		},
		ROLES: {
			form: 'FORM_ADD_ROLE',
			evtChnl: 'PAGE_EVENT_SETTING_ROLES'
		},
	}
	pageKey = this.tabLabelFormMap.USERS;
	private _cacheKey = 'SETTINGS_DATA';

	constructor(private _pubSub: NPubSubService,
		private _caches: cacheservice) {
		super();
	}

	ngOnInit() { }

	ngAfterViewInit() {
		this._refreshAllList();
	}

	private _refreshAllList() {
		this._pubSub.$pub(this.tabLabelFormMap.USERS.evtChnl, {
			evt: 'refresh-list',
			evtdata: {
				pageSize: this.pageSize,
				pageOffset: this.pageOffset
			}
		})
	}

	pageEvents($events: PageEvent) {
		this.pageSize = $events.pageSize;
		this.pageOffset = $events.pageIndex;
		this._pubSub.$pub(this.pageKey.evtChnl, {
			evt: 'refresh-list',
			evtdata: {
				pageSize: this.pageSize,
				pageOffset: this.pageOffset
			}
		});
	}

	updatePageData($event) {
		this.totalCount = $event.totalCount;
		this.pageSize = $event.pageSize;
		this.pageOffset = $event.pageOffset;
	}

	tabChange(e: MatTabChangeEvent) {
		this.pageKey = this.tabLabelFormMap[e.tab.textLabel];
		this._pubSub.$pub(this.pageKey.evtChnl, {
			evt: 'refresh-list'
		});
	}

	async openForm() {
		this._pubSub.$pub('sidenavformevents', {
			ACTION: 'open',
			PAGE: this.pageKey.form
		})
	}

	private async _clearCache() {
		await this._caches.deletec(this._cacheKey)
	}

	ngOnDestroy() {
		this._clearCache();
	}
}

export interface PageEvtData {
	pageSize?: number,
	pageOffset?: number
}

export interface Role {
	role_name: string,
	roleid: number,
	count: string,
	no_of_users: number,
	permission_groups: string[]
}