/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

import { utilservice } from './utilservice'; //_splitter_
//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class pipelineservice {
  constructor(
    private utilservice: utilservice,
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_pipelineservice

  async deletePipe(
    pipeid: any = undefined,
    modulename: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pipeid: pipeid,
          modulename: modulename
        },
        local: {
          url: undefined,
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_lpYjAtmKob9lCfHt(bh);
      //appendnew_next_deletePipe
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TaL0iE8IUSUxnAM2');
    }
  }

  async createPipe(
    pipelineid: any = undefined,
    modulename: any = undefined,
    pipe_name = '',
    pipe_order: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pipelineid: pipelineid,
          modulename: modulename,
          pipe_name: pipe_name,
          pipe_order: pipe_order
        },
        local: {
          url: undefined,
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_D02RVAZnvuSE7tZB(bh);
      //appendnew_next_createPipe
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_CsGmQwewihpra9eW');
    }
  }

  async updatePipe(
    pipelineid: any = undefined,
    modulename: any = undefined,
    pipe_name = '',
    pipe_order: any = undefined,
    pipeid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pipelineid: pipelineid,
          modulename: modulename,
          pipe_name: pipe_name,
          pipe_order: pipe_order,
          pipeid: pipeid
        },
        local: {
          url: undefined,
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_VMc2LDkSofjsyAB9(bh);
      //appendnew_next_updatePipe
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_4svRB8cyuaUWAtRK');
    }
  }

  async bulkUpdatePipes(
    pipes: any = undefined,
    modulename: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pipes: pipes,
          modulename: modulename
        },
        local: {
          url: undefined,
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_AFFWnzl4dk9uNyHu(bh);
      //appendnew_next_bulkUpdatePipes
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_sJpzfK3a3V571uOs');
    }
  }

  async getActivePipeline(modulename: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          modulename: modulename
        },
        local: {
          url: undefined,
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_80PJGici4csOjDcu(bh);
      //appendnew_next_getActivePipeline
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ca2dtt6CYFehN3YM');
    }
  }

  //appendnew_flow_pipelineservice_Start

  async sd_lpYjAtmKob9lCfHt(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `pipe/${bh.input.pipeid}/${bh.input.modulename}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_cMKYuvOnXj0WUsBb(bh);
      //appendnew_next_sd_lpYjAtmKob9lCfHt
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lpYjAtmKob9lCfHt');
    }
  }

  async sd_cMKYuvOnXj0WUsBb(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_cMKYuvOnXj0WUsBb
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_cMKYuvOnXj0WUsBb');
    }
  }

  async sd_D02RVAZnvuSE7tZB(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `pipe/${bh.input.modulename}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_HdjeMtmBmDSpWaxU(bh);
      //appendnew_next_sd_D02RVAZnvuSE7tZB
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_D02RVAZnvuSE7tZB');
    }
  }

  async sd_HdjeMtmBmDSpWaxU(bh) {
    try {
      bh.body = {
        pipelineid: bh.input.pipelineid,
        pipe_name: bh.input.pipe_name,
        pipe_order: bh.input.pipe_order
      };
      bh = await this.sd_oQJcDix0C772g2KK(bh);
      //appendnew_next_sd_HdjeMtmBmDSpWaxU
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HdjeMtmBmDSpWaxU');
    }
  }

  async sd_oQJcDix0C772g2KK(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_oQJcDix0C772g2KK
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_oQJcDix0C772g2KK');
    }
  }

  async sd_VMc2LDkSofjsyAB9(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `pipe/${bh.input.pipeid}/${bh.input.modulename}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_ADTREje5sypMf4z5(bh);
      //appendnew_next_sd_VMc2LDkSofjsyAB9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_VMc2LDkSofjsyAB9');
    }
  }

  async sd_ADTREje5sypMf4z5(bh) {
    try {
      bh.body = {
        pipelineid: bh.input.pipelineid,
        pipe_name: bh.input.pipe_name,
        pipe_order: bh.input.pipe_order
      };
      bh = await this.sd_tad3E9LCaXjffttQ(bh);
      //appendnew_next_sd_ADTREje5sypMf4z5
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ADTREje5sypMf4z5');
    }
  }

  async sd_tad3E9LCaXjffttQ(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_tad3E9LCaXjffttQ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_tad3E9LCaXjffttQ');
    }
  }

  async sd_AFFWnzl4dk9uNyHu(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `pipe-bulk/${bh.input.modulename}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_RmhsRiWq0FSl8OA8(bh);
      //appendnew_next_sd_AFFWnzl4dk9uNyHu
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_AFFWnzl4dk9uNyHu');
    }
  }

  async sd_RmhsRiWq0FSl8OA8(bh) {
    try {
      bh.body = {
        pipes: bh.input.pipes
      };
      bh = await this.sd_xfdsNaFryq3Nqtf4(bh);
      //appendnew_next_sd_RmhsRiWq0FSl8OA8
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_RmhsRiWq0FSl8OA8');
    }
  }

  async sd_xfdsNaFryq3Nqtf4(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_xfdsNaFryq3Nqtf4
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_xfdsNaFryq3Nqtf4');
    }
  }

  async sd_80PJGici4csOjDcu(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `pipeline/${bh.input.modulename}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_LEHIaJAeVptClYlJ(bh);
      //appendnew_next_sd_80PJGici4csOjDcu
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_80PJGici4csOjDcu');
    }
  }

  async sd_LEHIaJAeVptClYlJ(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      bh = await this.sd_jpV8FVJHM2N5SkaL(bh);
      //appendnew_next_sd_LEHIaJAeVptClYlJ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_LEHIaJAeVptClYlJ');
    }
  }

  //__client_service_designer_class_variable_declaration__pipeData
  pipeData: any;
  async sd_jpV8FVJHM2N5SkaL(bh) {
    try {
      this.pipeData = {};
      bh = await this.sd_2SN7ozCmzSz4rOn0(bh);
      //appendnew_next_sd_jpV8FVJHM2N5SkaL
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jpV8FVJHM2N5SkaL');
    }
  }

  async sd_2SN7ozCmzSz4rOn0(bh) {
    try {
      bh.local.res.data = bh.local.res.data || [];
      const pipelineid = bh.local.res.data[0]
        ? bh.local.res.data[0].pipelineid
        : null;
      this.pipeData[bh.input.modulename] = {
        pipelineid,
        pipes: bh.local.res.data
      };

      //appendnew_next_sd_2SN7ozCmzSz4rOn0
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_2SN7ozCmzSz4rOn0');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_pipelineservice_Catch
}
