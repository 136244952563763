<div fxLayout="column " style="padding: 0.5rem;" class="light-white-bg" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <form *ngIf="editing || adding; else nameView;" class="udf-section-name-edit-form" (ngSubmit)="updateNameChange()" (reset)="exitEditingMode(true)">
    <div fxLayout="row " class="height-fit-content udf-section-name-edit-form" fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " class="udf-section-edit-name udf-section-name-action-cont" fxFlex="50" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" class="width-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><input [formControl]="editNameFc" placeholder="ENTER SECTION NAME" #input [ngClass]="{ &apos;red-outline&apos;: editNameFc.invalid}">
          <div [style.visibility]="editNameFc.invalid ? &apos;visible&apos; : &apos;hidden&apos;" style="height: 22px;">
            <span *ngIf="editNameFc.errors?.duplicate" class="formfield-error-message">There is already a section with this name</span>
            <span *ngIf="editNameFc.errors?.required" class="formfield-error-message">Section name can not be empty</span></div>
        </div>
      </div>
      <div fxLayout="row wrap" style="margin-bottom: 12px;" fxFlex="100" fxLayoutGap="1rem" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false"><button mat-button class="primary-action-button" [disabled]="editNameFc.invalid || disableActions" type="submit">Save</button><button mat-button class="secondary-action-button" [disabled]="disableActions" type="reset">Cancel</button></div>
    </div>
  </form>
  <ng-template #nameView>
    <div fxLayout="row " style="padding: 0 2rem" fxFlex="2.62rem" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " class="udf-section-name-action-cont" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div style="max-width: 70%;" class="text-ellipsis udf-section-name-font">
          {{this.udfSectionConfig.field_section}}</div>
        <div fxLayout="row wrap" class="udf-section-action-cont" fxLayoutGap="1.6rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="!udfSectionConfig.default">
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [matTooltip]="&apos;Move Up&apos;" *ngIf="upIcn" (click)="emitMoveUp()" [ngClass]="disableLooking"><img imgSrc="/Web/Icons/udf-section-name-move-up.svg"></div>
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [matTooltip]="&apos;Move Down&apos;" *ngIf="downIcn" (click)="emitMoveDown()" [ngClass]="disableLooking"><img imgSrc="/Web/Icons/udf-section-name-move-down.svg"></div>
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [matTooltip]="&apos;Edit Section Name&apos;" (click)="enterEditMode()" *ngIf="editIcn" [ngClass]="disableLooking"><img imgSrc="/Web/Icons/udf-section-name-edit.svg"></div>
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [matTooltip]="&apos;Delete Section&apos;" (click)="emitDeleteSection()" *ngIf="delIcn" [ngClass]="disableLooking"><img imgSrc="/Web/Icons/udf-section-delete.svg"></div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="1rem" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false"><button mat-button class="button-normal" (click)="openAddFieldForm()" [disabled]="disableActions">Add Field</button></div>
    </div>
  </ng-template>
  <bh-udf_field class="card-like border-radius" [udfsConfig]="udfSectionConfig.fields" (fieldsChanged)="updateFields($event)" [disableActions]="disableActions || adding"></bh-udf_field>
</div>