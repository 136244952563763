<div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column " class="overflow-hidden height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column " class="display-block" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" class="add-contact-title lr-padding add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/documents.svg">
              <h2>
                {{documentTitle}}</h2>
            </div>
          </div>
          <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
            <mat-icon class="close-button" (click)="close()">close</mat-icon>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" class="form-sections-title" fxFlex="38px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div [ngClass.xs]="&apos;h5&apos;">
          Maximum size for document upload is 100 MB</div>
      </div>
      <div fxLayout="row wrap" class="display-block padding-1" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row" fxLayoutAlign="start start" class="upload-layout width-100">
          <div *ngIf="!file_name" class="height-100 padding-1-0" fxLayout="column" fxLayoutAlign="center center" fxFlex="100" fxLayoutGap="15px" fileDrag (files)="handleChange($event)">
            <div><img class="video-icon" src="assets/Web/Icons/upload.svg"></div>
            <input #fileInput type="file" hidden (change)="fileUpload($event.target.files)">
            <h3 mat-button class="primary-font-color cursor-pointer" (click)="fileInput.click()">Upload the document here</h3>
            <h3 [ngStyle.xs]="&apos;display: none&apos;">or</h3>
            <h3 [ngStyle.xs]="&apos;display: none&apos;">Drag the document</h3>
          </div>

          <div *ngIf="file_name" class="height-100 padding-1-0" fxLayout="column" fxLayoutAlign="center center" fxFlex="100" fxLayoutGap="10px">
            <div><img class="video-icon" src="assets/Web/Icons/file_icon.svg"></div>
            <span class="padding-0-1-1-1" *ngIf="file">{{file_name}}</span>
            <div *ngIf="documentTitle == &apos;ADD DOCUMENT&apos;" class="primary-font-color cursor-pointer" (click)="removeFile()">Remove</div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" class="padding-0-1 h3" fxFlex="20px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <mat-error align *ngIf="errorMsg.length &gt; 0">{{errorMsg}}</mat-error>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <mat-form-field class="width-100 padding-0-1" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
          <input matInput type="Documents tag(s)" placeholder="Document tag(s)" [required]="false" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">

        </mat-form-field>
      </div>
      <div fxLayout="row wrap" style="margin-bottom: 6px;" class="padding-0-1 h3" fxFlex="20px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <mat-error align *ngIf="tagErrorMsg.length &gt; 0">{{tagErrorMsg}}</mat-error>
      </div>
    </div>
    <div fxLayout="column wrap" class="display-block overflow-y" fxFlex="35" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " class="tags-input-chips" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div class="padding-0-1 width-100">
            <div fxLayout="column" fxLayoutAlign="center start">
              <mat-chip-list #chipList class="common-word-wrap width-gt-xs">
                <mat-chip *ngFor="let tags_name of tags_Arr" [selectable]="selectable" [removable]="removable" (removed)="remove(tags_name)">
                  {{tags_name}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column wrap" class="add-lead-content" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="documentTitle == &apos;EDIT DOCUMENT TAG(S)&apos;" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view add-button" (click)="updateDocument()" [disabled]="disableButton">Update</button></div>
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="documentTitle == &apos;ADD DOCUMENT&apos;" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view add-button" (click)="addDocument()" [disabled]="disableButton">Add</button></div>
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="cancel-button color-view" (click)="close()" type="button">Cancel</button></div>
    </div>
  </div>
</div>