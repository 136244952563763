/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { usernotificationservice } from 'app/sd-services/usernotificationservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { ContactLabel } from '../contactdetailsComponent/contactdetails.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { OptionDroppedEvtData, OptionEvtData, SerializeableOption } from '../optionsconfiguratorComponent/optionsconfigurator.component';
import { cacheservice } from 'app/sd-services/cacheservice';
import { contactservice } from 'app/sd-services/contactservice';
import { Contact } from '../contactdetailsComponent/contactdetails.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-form_contact_label',
    templateUrl: './form_contact_label.template.html'
})

export class form_contact_labelComponent extends NBaseComponent implements OnInit {
    title = 'ADD & VIEW LABELS';
	optionsAddBtnLabel = 'Add Label';
	sectionName = 'Add & view custom labels for your contacts';
	formGroup = new FormGroup({
		contact_label: new FormControl('', Validators.required)
	});
	disableActions: boolean;
    contactLabels;
    contactid;

	constructor(
        private cacheService: cacheservice,
        private contactService: contactservice,
		private _ps: NPubSubService,
		private _uns: usernotificationservice
        ) {
		super();
        this.getLabels();
		this.formGroup.patchValue({
			contact_label: this.contactLabels
		});
	}

	ngOnInit() { }
    getLabels() {
        this.cacheService.getc('CONTACT_LABELS').then((result) => {
            let d = result.local.data;
            if (d) {
                this.contactid = d.contactid;
                this.contactLabels = U.JSONClone(d.contact_labels);
            }
            // this.cacheService.setc(null, 'CONTACT_LABELS');
        }).catch(e => console.log(e));
    }

	get disableSubmit() {
		return this.formGroup.invalid || this.disableActions;
	}

	async updateOption(e: OptionEvtData<ContactLabel>) {
		this.disableActions = true;
		const oc = e.value;
		oc.sort_order = e.index + 1;
		let msg = `${oc['label']} label added.`;
		try {
            if (oc['label'] === ' ') {
                msg = `Enter a valid label`
            } else {
                if (e.isNew) {
                    if(localStorage.getItem("bulkAddLabel")){
                        let contacts=JSON.parse(localStorage.getItem("selectedContactsObj"))
                        let c_ids=[]
                        contacts.forEach(c=>{
                            c_ids.push(c.contactid);
                        })
                        await this.contactService.addLabelInBulk(c_ids,oc['label']);
                    }
                    else
                    await this.contactService.addLabel(this.contactid, oc['label'], oc['sort_order']);
                } else {
                    await this.contactService.updateLabel(oc['tagid'], oc['label'], oc['sort_order'], this.contactid);
                    msg = `${oc['label']} label updated.`;
                }
            }
		} catch (e) {
			msg = e.error?.message || e.error;
		}
        if(localStorage.getItem("bulkAddLabel")){
            this._uns.openSnackbar(msg);
            this.disableActions = false;
            // localStorage.removeItem("bulkAddLabel");
            // localStorage.removeItem("selectedContactsObj")
        }
        else
		await this._setNewLabels(msg);
	}

	async updateOptionOrder(es: OptionDroppedEvtData) {
		this.disableActions = true;
		const ems = es.map(e => { e.value.sort_order = e.index + 1; return e.value });
        console.log(ems)
		let msg = `Labels order updated.`;
		try {
			await this.contactService.bulkUpdateLabel(this.contactid, ems);
		} catch (e) {
			msg = e.error?.message || e.error;
		}
		await this._setNewLabels(msg);
	}

	async deleteOption(e: OptionEvtData<ContactLabel>) {
		if (!e.isNew) {
			this.disableActions = true;
			const oc = e.value;
			oc.sort_order = e.index + 1;
			let msg = `"${oc['label']}" deleted .`;
			try {
				await this.contactService.deleteLabel(this.contactid, oc['tagid']);
			} catch (e) {
				msg = e.error?.message || e.error;
			}
			await this._setNewLabels(msg);
		}
	}

	private async _setNewLabels(msg = '') {
		try {
            this.contactService.getContactById(this.contactid)
            .then(async (result: {
                input: {},
                local: {
                    result: {
                        data: Contact
                    }
                }
            }) => {
                if (result.local.result && result.local.result.data) {
                    this.disableActions = true;
                    this.contactLabels = result.local.result.data.labels;
                    // caching contact labels
                    this.cacheService.setc({
                        contact_labels: result.local.result.data.labels,
                        contactid: this.contactid
                    }, 'CONTACT_LABELS');
                    this._ps.$pub('refreshlabelslist');
                }
                this.disableActions = false;
            });
		} catch (e) {
			msg = e.error?.message || e.error;
		}
		this._uns.openSnackbar(msg);
	}

	close() {
		if (!this.disableActions) {
			this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
				ACTION: 'close'
			})
            localStorage.removeItem("bulkAddLabel");
            localStorage.removeItem("selectedContactsObj")
            this._ps.$pub('resetSelectedContacts');
		}
        
	}
}