<div fxLayout="column " style="height: 350px;" class="height-100 overflow-y-only card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="header">
    <h2 class="font-primary-color">
      {{header}}</h2>
  </div>
  <div fxLayout="column " class="padding-top-1" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="padding-bottom-1" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="filterOptions.length &lt; 0">
      <div>
        No labels available</div>
    </div>
    <div fxLayout="row wrap" class="padding-bottom-1" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let option of filterOptions">
      <mat-checkbox class="lr-padding" [(ngModel)]="option.isSelected" (change)="selectFilters($event, beingUsedInTeamFilter ? option.user_id:option.tag)" [required]="true"></mat-checkbox>
      <div>
        {{option.tag}}</div>
    </div>
  </div>
</div>