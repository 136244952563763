/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChange, SimpleChanges, HostListener, Inject } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-sort_by_list',
    templateUrl: './sort_by_list.template.html'
})

export class sort_by_listComponent extends NBaseComponent implements OnInit {
    sortValue;
    constructor(private dialogRef: MatDialogRef<sort_by_listComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
    }

    ngOnInit() {

    }

    selectedOption(option) {
        this.sortValue = option;
    }

    sortColumn() {
        this.dialogRef.close({ data: this.sortValue, sort_key: this.data.name });
    }

    close() {
        this.dialogRef.close();
    }
}
