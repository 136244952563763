/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Header } from '../usersComponent/users.component';
import { importcontactservice } from 'app/sd-services/importcontactservice';
import { SortOrder, SortChangeEvent } from '../sortComponent/sort.component';
import * as moment from 'moment';
import { NPubSubService } from 'neutrinos-seed-services';
import { cacheservice } from 'app/sd-services/cacheservice';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { dialogService } from 'app/services/dialog/dialog.service';
import { importcanceldialogComponent } from 'app/components/importcanceldialogComponent/importcanceldialog.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-importcontacthistory',
    templateUrl: './importcontacthistory.template.html'
})

export class importcontacthistoryComponent extends NBaseComponent implements OnInit, OnDestroy {
    headers: Header[] = [
        { displayText: 'File Name', sort: true, sortKey: 'file_name', sortValue: 'ASC' },
        { displayText: 'Imported Date', sort: true, sortKey: 'imported_date', sortValue: 'DESC' },
        { displayText: 'Valid Contacts' },
        { displayText: 'Invalid Contacts' },
        { displayText: 'Contact assigned' },
        { displayText: 'Status', sort: true, sortKey: 'status', sortValue: 'DESC' },
        {
            displayText: 'Action'
        }
    ];
    currentSortKey = 'imported_date';
    private sortOrder: { key: string, order: SortOrder }[] = [
        {
            key: 'imported_date',
            order: 'DESC'
        },
        {
            key: 'file_name',
            order: 'ASC'
        },
        {
            key: 'status',
            order: 'ASC'
        }
    ];
    myfilestatuses = [];
    pageSize = 50;
    pageOffset = 0;
    ssdURL = environment.properties.ssdURL;
    sampleDownloadLink = `${this.ssdURL}imports/download/`;
    totalCount = 0;
    ready = false;
    sub: Subscription;

    constructor(
        private importContactService: importcontactservice,
        private pubsub: NPubSubService,
		private _caches: cacheservice,
        private snackbar: MatSnackBar,
        private dialog: dialogService
    ) {
        super();
    }

    ngOnInit() {
        this.getData();
        this.sub = this.pubsub.$sub('refreshimporthistory', () => {
            this.getData();
        })
    }
    getData() {
        this.ready = false;
        const sortBy = this.sortOrder.map(v => v.key + ' ' + v.order).join(',');
        this.importContactService.getContactUploadStatus(this.pageSize, this.pageOffset, sortBy)
            .then(data => {
                this.ready = true;
                this.myfilestatuses = data.local.result.data.result;
                this.myfilestatuses.map((item) => {
                    item.imported_date = moment(item.imported_date).format('DD-MM-YYYY, hh:mm');
                });
                this.totalCount = data.local.result.data.count;
            })
            .catch(e => {
                this.ready = true;
            })
    }

    pageEvents($event) {
        this.pageSize = $event.pageSize;
        this.pageOffset = $event.pageIndex;
        this.getData();
    }

    sortChange($event: SortChangeEvent) {
        this.currentSortKey = $event.key;
        this.findNReplaceSortOrder($event.key, $event.sort);
        this.getData();
    }

    findNReplaceSortOrder(key, sort: 'ASC' | 'DESC') {
        let i = this.sortOrder.findIndex(v => {
            return v.key === key;
        });

        this.sortOrder.splice(i, 1);
        this.sortOrder.unshift({
            key,
            order: sort
        })
    }
    openAssignContact(fileItem:number){console.log(fileItem);
        this._caches.setc(
            fileItem, 'ASSIGN_CONTACT').then((_)=>{
            this.pubsub.$pub('sidenavformevents', {
                'ACTION': 'open',
                PAGE: 'ASSIGN_CONTACT'
            })
        });
        
    }

    download(v) {
        fetch(this.sampleDownloadLink + v.importsstagingid, {
            method: 'GET'
        })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = v.file_name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });
    }

    cancel(v) {
        this.dialog.dialog.open(importcanceldialogComponent, {
            width: '300px',
            panelClass: 'import-cancel-dialog'
        })
            .afterClosed().subscribe(yes => {
                console.log(yes);
                if (yes) {
                    this.importContactService.cancelFile(v.importsstagingid)
                    .then(result => {
                        this.snackbar.open('Import cancelled.', 'OK', {
                            duration: 3000
                        })
                        this.getData();
                    })
                    .catch(e => {
                        console.log(e);
                    })
                }
            })

    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
