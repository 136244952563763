/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilityMethods } from 'app/functions/UtilityMethods';
import { CustomValidators } from 'app/functions/Validators';
import { appointmentservice } from 'app/sd-services/appointmentservice';
import { cacheservice } from 'app/sd-services/cacheservice';
import { labelService } from 'app/services/label/label.service';
import { environment } from 'environments/environment';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { NPubSubService } from 'neutrinos-seed-services';
import { v5 as uuidv5 } from 'uuid';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
// import { dialogService } from '../../services/dialog/dialog.service';
import { ConfirmOpts, dialogService } from 'app/services/dialog/dialog.service';
import { DYN_SEARCH_INPUT_MODE } from '../dyncontactsearchComponent/dyncontactsearch.component';
import { dyndealssearchComponent } from '../dyndealssearchComponent/dyndealssearch.component';
import { Contact } from '../contactdetailsComponent/contactdetails.component';
import { contactservice } from 'app/sd-services/contactservice';
import { genericService } from 'app/services/generic/generic.service';

export const PICK_FORMATS = {
    parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

class PickDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            return formatDate(date, 'dd-MM-yyyy', this.locale);;
        } else {
            return date.toDateString();
        }
    }
}
@Component({
    selector: 'bh-scheduleappointment',
    templateUrl: './scheduleappointment.template.html',
    styleUrls: [
        './scheduleappointment.style.scss'
    ],
    providers: [
        { provide: DateAdapter, useClass: PickDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
    ]
})
/* TODO
** Change time 00:00 am to 12:00 am
*/
export class scheduleappointmentComponent extends NBaseComponent implements OnInit, OnDestroy {
    @ViewChild('picker') picker;
    readonly page_size = 100;
    contact_page_no = 1;
    deal_page_no = 1;
    appointmentGroup: FormGroup;
    autoContacts = [];
    autoDeals = [];
    autoLeads = [];
    todayDate: string;
    currentTime: string;
    currentTimeAfter30Mins: string;
    selectContact = new FormControl('', Validators.required);
    formErrorMessage = "";
    appointmentLabelName;
    isReadonly: boolean = false;
    readonly timeSugestionValues = ["00:00am", "00:15am", "00:30am", "00:45am", "01:00am", "01:15am", "01:30am", "01:45am", "02:00am", "02:15am", "02:30am", "02:45am", "03:00am", "03:15am", "03:30am", "03:45am", "04:00am", "04:15am", "04:30am", "04:45am", "05:00am", "05:15am", "05:30am", "05:45am", "06:00am", "06:15am", "06:30am", "06:45am", "07:00am", "07:15am", "07:30am", "07:45am", "08:00am", "08:15am", "08:30am", "08:45am", "09:00am", "09:15am", "09:30am", "09:45am", "10:00am", "10:15am", "10:30am", "10:45am", "11:00am", "11:15am", "11:30am", "11:45am", "12:00pm", "12:15pm", "12:30pm", "12:45pm", "01:00pm", "01:15pm", "01:30pm", "01:45pm", "02:00pm", "02:15pm", "02:30pm", "02:45pm", "03:00pm", "03:15pm", "03:30pm", "03:45pm", "04:00pm", "04:15pm", "04:30pm", "04:45pm", "05:00pm", "05:15pm", "05:30pm", "05:45pm", "06:00pm", "06:15pm", "06:30pm", "06:45pm", "07:00pm", "07:15pm", "07:30pm", "07:45pm", "08:00pm", "08:15pm", "08:30pm", "08:45pm", "09:00pm", "09:15pm", "09:30pm", "09:45pm", "10:00pm", "10:15pm", "10:30pm", "10:45pm", "11:00pm", "11:15pm", "11:30pm", "11:45pm"]
    appointmentFormObj: any = {};
    isInitialRunDate = true; //used for edit and view end date set on change of start date
    isInitialRunTime = true; //used for edit and view end time set on change of start time
    disableAddButton = false;
    relatedContactPlaceholder = 'Related Contact';
    relatedDealPlaceholder = 'Ex. $7000 Life Insurance'
    editingConId: number;
    editingDealId: number;
    dealDisabled = true;
    videoConfUrl;
    rematedcontactnoemialwarning: string;
    @ViewChild(dyndealssearchComponent) dealSearch: dyndealssearchComponent;
    defaultContactValues: any[];
    defaultDeals: { dealid: any; deal_name: any; }[];
    relatedDealLabel = '';
    searchmode: DYN_SEARCH_INPUT_MODE = 'EDIT';
    confUrlNotAdded = true;
    getContactByIdLatestRequestTime: Date;
    get showVidConf() {
        return this.confUrlNotAdded && this.videoConfExists && !this.isCreating;
    }
    get showAddConf() {
        return !this.showVidConf;
        // !this.confUrlNotAdded || !this.videoConfExists || this.isCreating
    }
    get disableLooking() {
        return this.isViewing ? 'disabled-appearance' : '';
    }

    get videoConfExists() {
        return this.appointmentGroup.get('room_id')?.value;
    }

    get isViewing() {
        return this.appointmentLabelName === 'VIEW APPOINTMENT';
    }
    get isCreating() {
        return this.appointmentLabelName === 'ADD APPOINTMENT';
    }
    get isEditing() {
        return this.appointmentLabelName === 'EDIT APPOINTMENT';
    }

    constructor(private pubsub: NPubSubService,
        private appointmentService: appointmentservice,
        public generic : genericService,
        private dialog: dialogService,
        private label: labelService,
        private snackBar: MatSnackBar,
        private cacheService: cacheservice,
        private contactService: contactservice) {
        super();
        let currentDomain = `${window.location.protocol}//${window.location.hostname}`;
        if (window.location.port && window.location.port.length > 0) {
            currentDomain += `:${window.location.port}`;
        }
        this.videoConfUrl = currentDomain + environment.properties.ssdURL + 'conference/room/';
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }

    setEndDateTimeOnStartDateTimeChange() {
        let from_time = this.appointmentGroup.get('start_time').value;
        let from_date = new Date(this.appointmentGroup.get('start_date').value);
        let from_date_string = this.getDateString(from_date);

        let from_time_stringin24hrs;
        try {
            from_time_stringin24hrs = UtilityMethods.timeampmTo24(from_time);
        } catch (err) {
            return;
        }
        //create date time moment object
        let from_date_timestamp = _moment(`${from_date_string} ${from_time_stringin24hrs}`);
        let to_date_timestamp = _moment(from_date_timestamp);
        let to_ampmafter30 = this.hourwithAMPMPlus30(to_date_timestamp);// will increment to_date_timestamp by 30 mins
        this.appointmentGroup.get('end_time').setValue(to_ampmafter30);
        this.appointmentGroup.get('end_date').setValue(to_date_timestamp.toDate());
    }
    ngOnInit() {
        this.relatedDealLabel = this.label.toTitleCase(`Related ${this.label.deal}`);
        this.todayDate = this.getTodayDate();
        let nextFromDateTime = this.nextmod15DateTime();
        this.currentTime = this.hourwithAMPM(nextFromDateTime);
        this.currentTimeAfter30Mins = this.hourwithAMPMPlus30(nextFromDateTime);

        this.appointmentGroup = new FormGroup({
            event_title: new FormControl('', [Validators.required]),
            event_description: new FormControl(''),
            event_location: new FormControl(''),
            dealText: new FormControl(''),
            contactText: new FormControl(''),
            appointment_feedback_id: new FormControl(''),
            feedback_notes: new FormControl(''),
            meeting_url: new FormControl(''),
            start_time: new FormControl(this.currentTime, CustomValidators.timeInputValidator()),
            end_time: new FormControl(this.currentTimeAfter30Mins, CustomValidators.timeInputValidator()),
            start_date: new FormControl(new Date()),
            end_date: new FormControl(_moment().add(30, 'm').toDate()),
            dealId: new FormControl(),
            contactId: new FormControl('', Validators.required),
            location: new FormControl(''),
            room_id: new FormControl()
        });
        this.appointmentLabelName = 'ADD APPOINTMENT';
        this._cacheData();
        this.appointmentGroup.get('contactId').valueChanges.subscribe((contactid) => {
            this.disableAddButton = false;
            this.rematedcontactnoemialwarning = null;
            if (contactid && typeof (contactid) !== 'string') {

                this.getContactByIdLatestRequestTime = new Date();
                let localThreadGetContactByIdTime = new Date();
                this.disableAddButton = true;
                this.contactService.getContactById(contactid).then(resp => {
                    if (localThreadGetContactByIdTime.getTime() < this.getContactByIdLatestRequestTime.getTime()) {
                        //new Thread already started by new search ID
                        // terminate this request
                        return;
                    }
                    if (resp.local.result.data.contact_email_id === null ||
                        resp.local.result.data.contact_email_id.trim().length === 0) {

                        this.rematedcontactnoemialwarning =
                            "Email address for this contact has not been captured and hence meeting invite details " +
                            "will not be shared.";
                    } else {
                        this.rematedcontactnoemialwarning = null;
                    }
                    this.disableAddButton = false;
                }).catch(err => {
                    this.rematedcontactnoemialwarning =
                        "Unable to check for The Related Contact Email"
                    this.disableAddButton = false;
                });
            }
        });
    }
    setFromTimeScroll() {
        if (!document.querySelector(`.from-${this.appointmentGroup.get('start_time').value.replace(':', '_')}`)) {
            return;
        }
        //scroll to the current time
        this.scrollTocurrentTime(
            document.querySelector(`.from-${this.appointmentGroup.get('start_time').value.replace(':', '_')}`)
        );
    }
    setToTimeScroll() {
        if (!document.querySelector(`.to-${this.appointmentGroup.get('end_time').value.replace(':', '_')}`)) {
            return;
        }
        //scroll to the current time
        this.scrollTocurrentTime(
            document.querySelector(`.to-${this.appointmentGroup.get('end_time').value.replace(':', '_')}`)
        );
    }
    scrollTocurrentTime(timeContainer: Element) {
        timeContainer.scrollIntoView();
    }
    _cacheData() {
        this.cacheService.getc('EDIT_APPOINTMENT_FORM').then((result) => {
            this.appointmentFormObj = result.local.data;
            if (!this.appointmentFormObj) {
                return;
            }
            if (this.appointmentFormObj.appointmentIdObj) {
                this.searchmode = 'VIEW';
                this.appointmentLabelName = 'VIEW APPOINTMENT';
                this.isReadonly = true;
                if (this.appointmentFormObj.appointmentIdObj['contactid']) {
                    this.defaultContactValues = [{
                        'contactid': this.appointmentFormObj.appointmentIdObj.contactid,
                        first_name: this.appointmentFormObj.appointmentIdObj.contact_first_name,
                        last_name: this.appointmentFormObj.appointmentIdObj.contact_first_name,
                        display_name: this.appointmentFormObj.appointmentIdObj.contact_name || ((this.appointmentFormObj.appointmentIdObj.contact_first_name || '') + ' ' + (this.appointmentFormObj.appointmentIdObj.contact_last_name || ''))
                    }];
                    this.setEditingCID(this.appointmentFormObj.appointmentIdObj.contactid);
                }
                if (this.appointmentFormObj.appointmentIdObj['dealid']) {
                    this.defaultDeals = [{ 'dealid': this.appointmentFormObj.appointmentIdObj.dealid, deal_name: this.appointmentFormObj.appointmentIdObj.deal_name }];
                    this.editingDealId = this.appointmentFormObj.appointmentIdObj.dealid
                }
            }
            if (this.appointmentFormObj.appointmentEventObj) {
                this.appointmentLabelName = 'VIEW APPOINTMENT';
                this.appointmentGroup.get('event_title').setValue(this.appointmentFormObj.appointmentEventObj['event_title']);
                this.appointmentGroup.get('event_description').setValue(this.appointmentFormObj.appointmentEventObj['event_description']);
                this.appointmentGroup.get('event_location').setValue(this.appointmentFormObj.appointmentEventObj['event_location']);
                this.appointmentGroup.get('contactId').setValue(this.appointmentFormObj.appointmentEventObj['contactid']);
                this.appointmentGroup.get('dealId').setValue(this.appointmentFormObj.appointmentEventObj['dealid']);
                this.appointmentGroup.get('start_date').setValue(this.appointmentFormObj.appointmentEventObj['start_date']);
                this.appointmentGroup.get('start_time').setValue(this.appointmentFormObj.appointmentEventObj['start_time']);
                this.appointmentGroup.get('end_date').setValue(this.appointmentFormObj.appointmentEventObj['end_date']);
                this.appointmentGroup.get('end_time').setValue(this.appointmentFormObj.appointmentEventObj['end_time']);
                this.appointmentGroup.get('room_id').setValue(this.appointmentFormObj.appointmentEventObj['room_id']);
            }
            this.appointmentGroup.get('start_time').valueChanges.subscribe(() => {
                this.setEndDateTimeOnStartDateTimeChange();
                this.setFromTimeScroll();
            });
            this.appointmentGroup.get('start_date').valueChanges.subscribe(() => {
                this.setEndDateTimeOnStartDateTimeChange();
                this.setFromTimeScroll();
            });
            this.cacheService.setc(null, 'EDIT_APPOINTMENT_FORM');
        }).catch(e => console.log(e));

        // This cache exists when adding appointment from `contactdetails` page
        // this cache SHOULD ONLY be cleared by `contactdetails` page
        this.appointmentGroup.get('end_time').valueChanges.subscribe(() => {
            this.setToTimeScroll();
        });
        this.appointmentGroup.get('start_date').valueChanges.subscribe(() => this.setEndDateTimeOnStartDateTimeChange());
        // this.cacheService.setc(null, 'EDIT_APPOINTMENT_FORM')


        // This cache exists when adding appointment from `contactdetails` page
        this.cacheService.getc('CONTACT_ID_VALUE').then((result) => {
            if (result && result.local.data) {
                const d = <Contact>result.local.data;
                this.defaultContactValues = [d];
                this.setEditingCID(d.contactid);
            }
        });
        // This cache exists when adding appointment from `dealdetails` page
        this.cacheService.getc('DEAL_ID_VALUE').then((result) => {
            let cachedData = result.local.data;
            if (cachedData) {
                this.defaultContactValues = [
                    {
                        'contactid': cachedData.contactId,
                        first_name: cachedData['first_name'],
                        'last_name': cachedData['last_name'],
                        display_name: cachedData.contactName
                    }
                ];
                this.defaultDeals = [{ deal_name: cachedData.dealName, dealid: cachedData.dealId }];
                this.setEditingCID(cachedData.contactId);
                this.editingDealId = cachedData.dealId
            }
        });
        this.cacheService.getc('CREATE_APPOINTMENT_FORM').then((result) => {
            let d = result.local.data;
            if (d) {
                this.defaultContactValues = [{ 'contactid': d.contactid, display_name: d.contact_name }];
                this.setEditingCID(d.contactid);
                if (d.dealid) {
                    this.defaultDeals = [{ 'dealid': d.dealid, deal_name: d.deal_name }];
                    this.editingDealId = d.dealid
                }
            }
            this.cacheService.setc(null, 'CREATE_APPOINTMENT_FORM');
        }).catch(e => console.log(e));
    }

    hourwithAMPM(inputDate: Moment) {
        let d = _moment();
        if (inputDate) {
            d = _moment(inputDate);
        }
        let ampm = (d.hour() >= 12) ? "pm" : "am";
        let hours = (d.hour() > 12) ? d.hour() - 12 : d.hour();
        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.minute() < 10 ? '0' + d.minute() : d.minute()) + ampm;
    }

    nextmod15DateTime() {// get next time after 15 mins
        let d = _moment();
        let currentMinutes = d.minutes();
        currentMinutes %= 15;
        d.add(15 - currentMinutes, 'm');
        return d;
    }

    hourwithAMPMPlus30(currentTime?: Moment) {
        let d = _moment().add(30, 'm');
        if (currentTime) {
            d = currentTime.add(30, 'm');
        }
        let ampm = (d.hour() >= 12) ? "pm" : "am";
        let hours = (d.hour() > 12) ? d.hour() - 12 : d.hour();
        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.minute() < 10 ? '0' + d.minute() : d.minute()) + ampm;
    }

    getTodayDate() {
        let d = new Date();
        return `${d.getDate() < 10 ? '0' + d.getDate().toString() : d.getDate()}-${d.getMonth() < 10 ? '0' + d.getMonth().toString() : d.getMonth()}-${d.getFullYear()}`;
    }

    validateDate(
        title: string,
        from_datetime: Moment,
        to_datetime: Moment,
        contactid: number,
        dealId: number
    ) {
        if (title.trim().length === 0) {
            this.formErrorMessage = "Invalid title";
            return false;
        }
        if (!from_datetime.isValid() ||
            !to_datetime.isValid()
        ) {
            this.formErrorMessage = "Invalid From / to Date";
            return false;
        }
        if (this.appointmentGroup.get('contactId').invalid) {
            // error please select contact ID
            this.formErrorMessage = "Contact User is Invalid";
            return false;
        }
        // validate dealID optional if passed
        if (this.appointmentGroup.get('dealId').invalid) {
            // error please select contact ID
            this.formErrorMessage = "Selected Deal is Invalid";
            return false;
        }
        if (from_datetime.isAfter(to_datetime)) {
            this.formErrorMessage = "From Date cannot be greater than To Date";
            return;
        }
        this.formErrorMessage = "";
        return true;
    }

    getDateString(date_value) {
        var dt = date_value;
        var year = dt.getFullYear();
        var month = (dt.getMonth() + 1).toString().padStart(2, "0");
        var day = dt.getDate().toString().padStart(2, "0");
        return year + '-' + month + '-' + day;
    }

    createAppointment() {
        let from_date = new Date(this.appointmentGroup.get('start_date').value);
        let to_date = new Date(this.appointmentGroup.get('end_date').value);
        let from_date_string = this.getDateString(from_date);
        let to_date_string = this.getDateString(to_date);

        const date_start = from_date_string;
        const time_start = UtilityMethods.timeampmTo24(this.appointmentGroup.get('start_time').value);
        let date_time_start = _moment(`${date_start} ${time_start}`, 'YYYY-MM-DD HH:mm:00Z').format();

        const date_end = to_date_string;
        const time_end = UtilityMethods.timeampmTo24(this.appointmentGroup.get('end_time').value);
        let date_time_end = _moment(`${date_end} ${time_end}`, 'YYYY-MM-DD HH:mm:00Z').format();

        if (!this.validateDate(
            this.appointmentGroup.get('event_title').value,
            _moment(`${date_start} ${time_start}`),
            _moment(`${date_end} ${time_end}`),
            this.appointmentGroup.get('contactId').value,
            this.appointmentGroup.get('dealId').value
        ) || !this.appointmentGroup.valid) {
            return;
        }
        this.disableAddButton = true;
        let payload: any = {
            event_title: this.appointmentGroup.get('event_title').value,
            event_description: this.appointmentGroup.get('event_description').value,
            dealid: typeof this.appointmentGroup.get('dealId').value === 'number' ? this.appointmentGroup.get('dealId').value : null,
            contactid: this.appointmentGroup.get('contactId').value,
            start_time: date_time_start,
            end_time: date_time_end,
            event_location: this.appointmentGroup.get('event_location').value,
            room_id: this.appointmentGroup.get('room_id').value,

        };
        if (this.appointmentLabelName == 'ADD APPOINTMENT') {
            const opts: any = {
                header: `Email confirmation`,
                subtitle: `Do you want to send the email?`,
                hideCloseButton: true,
                disableClose: true
            };
            this.dialog.openConfirmationDialog(opts).subscribe((v) => {
                if (v && v.option === 'yes') {
                    payload["send_email"] = true;
                }
                else if (v && v.option === 'no') {
                    payload["send_email"] = false;
                }
                this.appointmentService.createAppointment(payload).then(
                    (res) => {
                        this.snackbarMsg('Appointment successfully added.');
                        this.close();
                        this.pubsub.$pub('refreshappointmentslist');
                        this.pubsub.$pub('refreshevents');
                        this.pubsub.$pub('refreshstatslist');
                        this.disableAddButton = false;
                    }
                ).catch(e => {
                    this.snackbarMsg(e.error.message);
                    this.disableAddButton = false;
                })
            });
        } else {
            payload.appointment_feedback_id = this.appointmentFormObj.appointmentEventObj['appointment_feedback_id'];
            payload.feedback_notes = this.appointmentFormObj.appointmentEventObj['feedback_notes'];

            const opts: any = {
                header: `Email confirmation`,
                subtitle: `Do you want to send the email?`,
                hideCloseButton: true,
                disableClose: true
            };
            this.dialog.openConfirmationDialog(opts).subscribe((v) => {
                if (v && v.option === 'yes') {
                    payload["send_email"] = true;
                }
                else if (v && v.option === 'no') {
                    payload["send_email"] = false;
                }
                this.appointmentService.updateAppointment(this.appointmentFormObj.appointmentIdObj['activityeventid'], payload).then(
                    (res) => {
                        if (res) {
                            this.snackbarMsg('Appointment successfully updated.');
                            this.close();
                            if(this.generic.fromappView == true){
                                this.pubsub.$pub('refresh-activities');
                                this.generic.fromappView = false;
                            }else {
                                this.pubsub.$pub('refreshevents');
                                this.pubsub.$pub('refreshappointmentslist');
                            }
                            this.disableAddButton = false;
                        }
                    }).catch(e => {
                        this.snackbarMsg(e.error.message);
                        this.disableAddButton = false;
                    })
            });
        }
    }

    snackbarMsg(msg) {
        this.snackBar.open(msg, 'OK', {
            duration: 3000
        })
    }

    async generateVideoCallUUID() {
        this.confUrlNotAdded = false;
        try {
          const {  input: { result: { roomId } } } = await this.appointmentService.getRoomID();
          this.appointmentGroup.get('room_id').setValue(roomId);
        } catch (e) {
          this.snackbarMsg('Could not generate Meeting URL');
        }
    }

    editAppointment() {
        this.appointmentLabelName = 'EDIT APPOINTMENT';
        this.isReadonly = false;
    }

    dupAppointment() {
        this.appointmentLabelName = 'ADD APPOINTMENT';
        this.isReadonly = false;
        let nextFromDateTime = this.nextmod15DateTime();
        let currentTime = this.hourwithAMPM(nextFromDateTime);
        let currentTimeAfter30Mins = this.hourwithAMPMPlus30(nextFromDateTime);
        this.appointmentGroup.get('start_date').setValue(new Date());
        this.appointmentGroup.get('start_time').setValue(currentTime);
        this.appointmentGroup.get('end_date').setValue(_moment().add(30, 'm').toDate());
        this.appointmentGroup.get('end_time').setValue(currentTimeAfter30Mins);
        this.appointmentGroup.get('room_id').setValue('');
    }


    cancel() {
        if (this.appointmentLabelName == 'ADD APPOINTMENT') {
            this.close();
        }
        if (this.appointmentLabelName == 'EDIT APPOINTMENT') {
            this.appointmentLabelName = 'VIEW APPOINTMENT';
            this.isReadonly = true;
        }
    }

    deleteAppointment() {
        var send_email = true;
        var id = this.appointmentFormObj.appointmentIdObj;

        const opts: any = {
            header: `DELETE`,
            subtitle: `Cancel the appointment?`
        };
        this.dialog.openConfirmationDialog(opts).subscribe((v) => {
            if (v && v.option === 'yes') {
                const opts: any = {
                    header: `Email confirmation`,
                    subtitle: `Do you want to send the email?`,
                    hideCloseButton: true,
                    disableClose: true
                };
                this.dialog.openConfirmationDialog(opts).subscribe((v) => {
                    if (v && v.option === 'yes') {
                        send_email = true;
                    }
                    else if (v && v.option === 'no') {
                        send_email = false;
                    }
                    this.appointmentService.deleteAppointment(id['activityeventid'], id['contactid'], send_email)
                        .then(result => {
                            if (result) {
                                this.pubsub.$pub('sidenavformevents', { ACTION: 'close' })
                                this.pubsub.$pub('refreshappointmentslist');
                                this.pubsub.$pub('refreshevents');
                            }
                        })
                });
            }
            else if (v && v.option === 'no') {
                console.log("no clicked")
            }
        });
    }

    openUrlLink() {
        if (this.appointmentGroup.get('room_id').value) {
            let urlPath = this.videoConfUrl + this.appointmentGroup.get('room_id').value;
            window.open(urlPath);
        }
    }

    copyLink(elem) {
        var range = document.createRange();
        range.selectNode(document.getElementById(elem));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        this.snackBar.open('Link Copied.', 'OK', {
            duration: 3000
        })
    }

    contactSelected(id: number) {
        if (id !== this.editingConId) {
            this.editingDealId = null;
            this.setEditingCID(id);
        }
        this.dealDisabled = false;
    }

    dealSelected($event) {
        this.editingDealId = $event;
    }

    setEditingCID(contactid: number) {
        this.editingConId = contactid;
        this.dealDisabled = false;
        // Searching with "e" as it is the most frequent english letter
        this.dealSearch && !this.isViewing && this.dealSearch.search('e', contactid);
    }

    ngOnDestroy() {
        this.cacheService.deletec('CREATE_APPOINTMENT_FORM');
    }
}

//
/*
---------------------Sample Appointment Activity Obj----------------
{
	"count": "2",
	"user_name": "Sankarshan Joshi",
	"activity_title": "Title: Appointment Edit 1230",
	"activityfileid": null,
	"activityeventid": 427,
	"tenantid": 1,
	"start_time": "2020-08-05T19:00:00.000Z",
	"end_time": "2020-08-05T19:30:00.000Z",
	"created_at": "2020-08-05T21:58:42.733Z",
	"created_by": 89,
	"event_title": "Appointment Edit 1230",
	"event_description": "skldjfslkdjf",
	"event_location": "slkdjkdsj",
	"dealid": 304,
	"contactid": 338,
	"appointment_feedback_id": null,
	"feedback_notes": null,
	"room_id": null,
	"rec_download_in_progress": false,
	"leadid": null,
	"status": true,
	"contact_name": "can i add",
	"deal_name": "Once In a Lifetime"
},

------------------------Sample Appointment Object------------------------

{
	"start_time": "2020-08-30T19:30:00.000Z",
	"activityeventid": 415,
	"tenantid": 1,
	"end_time": "2020-08-30T20:00:00.000Z",
	"event_title": "Anew",
	"event_description": "sdkjlkjds",
	"event_location": "lkdjalksj",
	"dealid": null,
	"contactid": 336,
	"appointment_feedback_id": null,
	"feedback_notes": null,
	"room_id": "a77bfdee-f1c4-530a-85f2-3e582a995160",
	"rec_download_in_progress": false,
	"created_by": 89,
	"status": true,
	"contact_first_name": "me",
	"contact_last_name": "",
	"deal_name": null,
	"deal_value": null
},
*/
