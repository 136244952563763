/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { NPubSubService } from 'neutrinos-seed-services';
import { Observable } from "rxjs";
import { tap, map, filter, catchError, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class header_interceptorService implements HttpInterceptor {
    
    constructor(
        private pubsub: NPubSubService
        ) {}

     intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
            return next.handle(request).pipe(
                filter(event => event instanceof HttpResponse ),
                map( (event: HttpResponse<any>) => {
                        if(event.headers.get('refresh-session') === 'true' ) {
                             this.pubsub.$pub('refresh-settings');
                        } 
                        return event;
                    }
                )
            );
            
        }
}
