<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <bh-addcontact class="height-100" *ngIf="data.loadInSidenav === &apos;ADD_CONTACT&apos;"></bh-addcontact>
  <bh-scheduleappointment class="height-100" *ngIf="data.loadInSidenav === &apos;SCHEDULE_APPOINTMENT&apos;"></bh-scheduleappointment>
  <bh-adddeal class="height-100" *ngIf="data.loadInSidenav === &apos;ADD_DEAL&apos;"></bh-adddeal>
  <bh-editcontact class="height-100" *ngIf="data.loadInSidenav === &apos;EDIT_CONTACT_XS&apos;"></bh-editcontact>
  <bh-editdeal class="height-100" *ngIf="data.loadInSidenav === &apos;EDIT_DEAL_XS&apos;"></bh-editdeal>
  <bh-addtask class="height-100" *ngIf="data.loadInSidenav === &apos;ADD_TASK&apos;"></bh-addtask>
  <bh-feedback_form_call *ngIf="data.loadInSidenav === &apos;FEEDBACK_FORM_CALL_ACTIVITY&apos;"></bh-feedback_form_call>
  <bh-feedback_form_appointment *ngIf="data.loadInSidenav === &apos;FEEDBACK_FORM_APPOINTMENTS_ACTIVITY&apos;"></bh-feedback_form_appointment>
  <bh-form_add_role *ngIf="data.loadInSidenav === &apos;FORM_ADD_ROLE&apos;"></bh-form_add_role>
  <bh-form_add_user *ngIf="data.loadInSidenav === &apos;FORM_ADD_USER&apos;"></bh-form_add_user>
  <bh-setting_feedbackoutcomes *ngIf="data.loadInSidenav === &apos;SETTING_FEEDBACK_OUTCOMES&apos;"></bh-setting_feedbackoutcomes>
  <bh-form_contact_reassign *ngIf="data.loadInSidenav === &apos;FORM_CONTACT_REASSIGN&apos;"></bh-form_contact_reassign>
  <bh-adddocument class="height-100" *ngIf="data.loadInSidenav === &apos;ADD_DOCUMENT&apos;"></bh-adddocument>
  <bh-form_theme_logo *ngIf="data.loadInSidenav === &apos;UPD_THEME&apos;"></bh-form_theme_logo>
  <bh-importcontacts *ngIf="data.loadInSidenav === &apos;IMPORT_CONTACT&apos;"></bh-importcontacts>
  <bh-assigncontacts *ngIf="data.loadInSidenav === &apos;ASSIGN_CONTACT&apos;"></bh-assigncontacts>
  <bh-setting_phone_country_code *ngIf="data.loadInSidenav === &apos;SETTING_PHONE_COUNTRY&apos;"></bh-setting_phone_country_code>
  <bh-setting_curr *ngIf="data.loadInSidenav === &apos;SETTING_CURRENCY&apos;"></bh-setting_curr>
  <bh-module_rename *ngIf="data.loadInSidenav === &apos;SETTING_MODULE_RENAME&apos;"></bh-module_rename>
  <bh-setting_dealpipeline *ngIf="data.loadInSidenav === &apos;SETTING_DEAL_PIPE&apos;"></bh-setting_dealpipeline>
  <bh-form_contact_label *ngIf="data.loadInSidenav === &apos;CONTACT_LABEL&apos;"></bh-form_contact_label>
  <bh-form_contact_filter *ngIf="data.loadInSidenav === &apos;CONTACT_FILTER&apos;"></bh-form_contact_filter>
</div>