<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill fxLayout.xs="column">
  <div fxLayout="column wrap" class="lr-padding add-contact-title add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="&apos;48px&apos;">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="60" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/add_task_icon.svg">
          <h2>
            {{taskLabelName}}</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="40" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " style="width:100%" fxLayoutGap="1em" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="taskLabelName == &apos;VIEW TASK&apos;">
            <mat-icon class="cursor-pointer" (click)="dupTask()" matTooltip="Create Duplicate">content_copy</mat-icon>
          </div>
          <div fxLayout="column wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="taskLabelName == &apos;VIEW TASK&apos;"><img imgSrc="Web/Icons/editevent.svg" (click)="editTask()" class="cursor-pointer"></div>
          <div fxLayout="column wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="taskLabelName == &apos;VIEW TASK&apos;"><img imgSrc="Web/Icons/deleteevent.svg" (click)="deleteTask()" class="cursor-pointer"></div>
          <div fxLayout="column wrap" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
            <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form NgForm [formGroup]="addTaskFormGroup" fxLayout="column" [ngStyle.xs]="&apos;padding: 6px 16px 10px 16px;&apos;" [ngClass.xs]="&apos;overflow-y&apos;" [fxFlex.xs]="&apos;calc(100% - 48px)&apos;" style="height: calc(100% - 64px)" class="add-lead-content" (ngSubmit)="add()">
    <div fxLayout="column " fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" fxFill="fxFill">
      <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" class="add-task-title-wrapper" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="disableLooking">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="Ex. FInish Linda&apos;s FNA" [required]="true" [readonly]="isReadonly" trim="blur" [formControl]="addTaskFormGroup.controls.title">
            <mat-label>Title</mat-label>
          </mat-form-field>
        </div>
        <div [ngClass]="dsearchmode === &apos;EDIT&apos; &amp;&amp; taskLabelName == &apos;VIEW TASK&apos; ? &apos;disabled-appearance&apos; : &apos;&apos;" *ngIf="!(taskLabelName == &apos;VIEW TASK&apos; &amp;&amp; !editingConId)" class="enabled-appearance">
          <bh-dyncontactsearch [fc]="addTaskFormGroup?.controls.contact" [placeholder]="relatedContactPlaceholder" [defaultcontacts]="defaultContactValues" (resultSelected)="contactSelected($event)" [value]="editingConId" [mode]="isViewing ? &apos;VIEW&apos; : &apos;EDIT&apos;" (navigationInit)="close()"></bh-dyncontactsearch>
        </div>
        <div [ngClass]="dsearchmode === &apos;EDIT&apos; &amp;&amp; taskLabelName == &apos;VIEW TASK&apos; ? &apos;disabled-appearance&apos; : &apos;&apos;" *ngIf="!(taskLabelName == &apos;VIEW TASK&apos; &amp;&amp; !editingDealId)" class="enabled-appearance">
          <bh-dyndealssearch [fc]="addTaskFormGroup?.controls.deal" [placeholder]="relatedDealPlaceholder" (resultSelected)="dealSelected($event)" [label]="relatedDealLabel" [value]="editingDealId" [dealContactId]="editingConId" [disabled]="dealDisabled" #dealSearch [defaultdeals]="defaultDeals" [mode]="isViewing ? &apos;VIEW&apos; : &apos;EDIT&apos;" (navigationInit)="close()" [disable]="dealDisabled"></bh-dyndealssearch>
        </div>
        <div [ngClass]="disableLooking" style="pointer-events: all !important;" class="input-focus-color">
          <div fxLayout="column" fxLayoutAlign="center start" class="task-description">
            <label class="label"><span style="color: #949393;font-size: 12px">Description</span></label>
            <mat-form-field class="width-100" appearance="outline">
              <textarea placeholder="Enter task description." [readonly]="isReadonly" matInput formControlName="description"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="disableLooking">
          <div>

            <div fxLayout="row" fxLayoutAlign="start center" class="clock-height">

              <mat-form-field class="cursor-pointer no-border-field width-dt" style="width: 8em;">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="99">
                  <img style="margin-right: 20px;" src="assets/Web/Icons/clock.svg">
                  <input matInput class="custom-date-input" formControlName="duedate" [matDatepicker]="pickerFrom" (focus)="taskLabelName !== &apos;VIEW TASK&apos; &amp;&amp; pickerFrom.open()" (click)="taskLabelName !== &apos;VIEW TASK&apos; &amp;&amp; pickerFrom.open()" readonly>

                  <mat-datepicker #pickerFrom></mat-datepicker>
                </div>
              </mat-form-field>

              <mat-form-field class="no-border-field width-time padding-1" [ngClass.xs]="&apos;time-field&apos;">
                <div fxLayout="row" fxFlex="42" [fxFlex.xs]="60" style="padding-left:4px" fxLayoutAlign="start center">
                  <input type="text" aria-label="Number" matInput class="custom-date-input" formControlName="duetime" [readonly]="this.taskLabelName == &apos;VIEW TASK&apos;" [style.color]="addTaskFormGroup.get(&apos;duetime&apos;).valid ? &apos;inherit&apos;:&apos;red&apos;" (click)="setFromTimeScroll()" [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option [class]="&apos;from-&apos;+(option.replace(&apos;:&apos;,&apos;_&apos;))" *ngFor="let option of timeSugestionValues" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </div>

              </mat-form-field>


            </div>
          </div>
        </div>
        <mat-radio-group class="marg-tp8 radio-group-color radio-group-label" [(ngModel)]="selectedValue" formControlName="completed" [ngClass]="disableLooking" [labelPosition]="&apos;after&apos;" [required]="false">Task complete: <mat-radio-button value="Yes" class="padding-0-1" [required]="true">Yes</mat-radio-button>
          <mat-radio-button value="No" [required]="false">No</mat-radio-button>
        </mat-radio-group>
        <mat-error align *ngIf="errorMsg.length &gt; 0">{{errorMsg}}</mat-error>
      </div>
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" *ngIf="taskLabelName != &apos;VIEW TASK&apos;">
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="taskLabelName == &apos;EDIT TASK&apos;" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view add-button" [disabled]="disableAddButton">Update</button></div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="taskLabelName == &apos;ADD TASK&apos;" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view add-button" [disabled]="disableAddButton">Add</button></div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="cancel-button color-view" (click)="cancel()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>