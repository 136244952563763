import { UtilityMethods as U } from './functions/UtilityMethods'

export const PubSubChannel = {
	udfSectionFieldChanged: U.generateUUID(),
	refreshUDFDependencies: U.generateUUID(),
	udfSectionValidateName: U.generateUUID(),
	udfAddSection: U.generateUUID(),
	udfAddDep: U.generateUUID(),
}

export const CacheKey = {
	entireUDFsConfig: U.generateUUID(),
	udfSectionNames: U.generateUUID(),
	udfAddFieldFormSectionInfo: U.generateUUID(),
	udfEditForm: U.generateUUID(),
	depUdfEditForm: U.generateUUID(),
	udfFieldNameToFieldMap: U.generateUUID(),
	depAllUdfConfigs: U.generateUUID(),
}

export const Page = {
	UDFForm: U.generateUUID(),
	UDFDepForm: U.generateUUID(),
}