<div fxLayout="column " fxLayoutGap="5px" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill id="contact-module-setting">
  <div fxLayout="row " class="card-like border-radius lr-padding" fxFlex="60px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <h2>
      {{header}}</h2>
  </div>
  <div fxLayout="column wrap" class="relative-position border-radius card-like" fxFlex="calc(100%-66px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" style="right: 2.85rem; top: 0.3rem;" class="absolute-position" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button style="z-index: 2;" class="button-normal" (click)="addBtnClick()" [disabled]="false">{{addBtnLabel}}</button></div>
    <mat-tab-group mat-align-tabs="start" class="h3-tab-label contact-tabs height-100" animationDuration="0" (selectedTabChange)="tabChange($event)">
      <mat-tab label="SECTIONS &amp; FIELDS" class="light-white-bg height-100">
        <bh-udfsectionandfields [udfSections]="udfSections" (udfSectionsChanged)="save($event)" [disableActions]="disableActions"></bh-udfsectionandfields>
      </mat-tab>
      <mat-tab label="FIELD DEPENDENCIES">
        <bh-udfdependencies [disableActions]="disableActions" [depFields]="depFields"></bh-udfdependencies>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>