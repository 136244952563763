<div fxLayout="column wrap" class="relative-position" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><label class="text-ellipsis width-100 absolute-position label">
    {{placeholder || &apos;&apos;}}</label>
  <div fxLayout="row " fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxFlex="29" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div class="width-100">
        <mat-form-field class="phone-country-code-autocomplete width-100 search-field" [floatLabel]="&apos;always&apos;">
          <input type="text" aria-label="Number" matInput [formControl]="currencyCodeControl" [matAutocomplete]="auto" (focus)="autoCompleteFocused()">
          <img matSuffix class="autocomplete-icon" src="assets/Web/Icons/autocomplete.svg">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayWith" panelWidth="fit-content">
            <mat-option *ngFor="let option of filteredCurrencyCodes | async;" [value]="option.currencyid">
              <span class="dynmoney-currency-symbol">{{option.currency_symbol}}</span><span class="dynmoney-currency-value">  ({{option.currency_name}})</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column wrap" fxFlex="69" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <mat-form-field class="width-100" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
        <input matInput [required]="required" oninput="this.value = this.value.replace(/[^0-9]/g, &apos;&apos;)" [formControl]="fc">

      </mat-form-field>
    </div>
  </div>
</div>