<div fxLayout="column " class="background-primary height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column wrap" class="width-100" fxFlex="40px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="font-weight-size width-100" fxFlex="100" fxLayoutAlign="space-evenly center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="78" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          Call Directory</div>
      </div>
      <div fxLayout="row wrap" fxFlex="8" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <mat-divider>
  </mat-divider>
  <div fxLayout="column " class="padding-1" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="data?.phoneNumber?.length">
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <mat-radio-group class="radio-group-color" [required]="false">
        <div fxLayout="row " class="margin-bottom-1" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let val of data.phoneNumber; let i = index">
          <mat-radio-button value="{{val.value}}" class="h5 font-normal-size" [required]="true" [checked]="selectedValue == val.value" (change)="setSelectedValue(i)">{{val.label}}: {{val.displayValue}}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <div fxLayout="row wrap" class="padding-0-1 add-lead-content" fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="makeCall(data)" [disabled]="!selectedValue">Call</button></div>
      <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
    </div>
  </div>
  <div fxLayout="column " class="padding-1" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="data &amp;&amp; data.phoneData &amp;&amp; data.phoneData.length">
    <h4 class="color-black font-normal-size">
      {{data.phoneData}}</h4>
  </div>
</div>