/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit,ElementRef, HostListener, Input } from '@angular/core';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { searchservice } from 'app/sd-services/searchservice';
import { userservice } from 'app/sd-services/userservice';
import { Router } from '@angular/router';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-blsearchbar',
    templateUrl: './blsearchbar.template.html',
    host: {'(window:mouseup)': 'handleMouseUp($event)'},
})

export class blsearchbarComponent extends NBaseComponent implements OnInit {
    searchValue = "";
    searchTask = 'All';
    listSize = 5;
    contactOffset = 0;
    dealOffset = 0;
    contactSearchArr;
    dealSearchArr;
    displayImage;
    enableContact: boolean;
    enableDeal: boolean;
    checkSearchList: boolean = false;
    contactsCount;
    dealsCount;
    constructor(private searchS: searchservice,
        private userS: userservice,
        private router: Router,
        private eRef: ElementRef,
        public label: labelService) {
        super();
    }

    ngOnInit() {
        this.selectedValue('All');
    }

    handleMouseUp(e: MouseEvent) {
        if (this.eRef.nativeElement.contains(e.target)) {
        } else {
            this.clear()
        }
    }


    selectedValue(value) {
        this.contactOffset = this.dealOffset = 0;
        if (value == 'All') {
            this.displayImage = 'search';
            this.enableContact = true;
            this.enableDeal = true;
        } else if (value == 'Contacts') {
            this.displayImage = 'searchcontact';
            this.enableContact = true;
            this.enableDeal = false;
        } else if (value == 'Deals') {
            this.displayImage = 'searchdeal';
            this.enableContact = false;
            this.enableDeal = true;
        }
    }

    search() {
        if (this.searchValue.length) {
            var offsetValue = 0;
            this.searchS.contactDealSearch(this.userS.user.srmUserid, this.searchValue, this.listSize, offsetValue, this.enableContact, this.enableDeal, 'All')
                .then(result => {
                    if (result && result.local.searchList && result.local.searchList.data && this.searchValue.length) {
                        this.contactSearchArr = this.dealSearchArr = [];
                        this.contactOffset = this.dealOffset = 0;
                        if (this.enableContact && result.local.searchList.data.contacts) {
                            this.contactSearchArr = result.local.searchList.data.contacts;
                            this.getContactsCount();
                        }
                        if (this.enableDeal && result.local.searchList.data.deals) {
                            this.dealSearchArr = result.local.searchList.data.deals;
                            this.getDealsCount();
                        }
                        if(!this.contactSearchArr.length && !this.dealSearchArr.length){
                            this.contactOffset = this.dealOffset = 0;
                        }
                        this.checkSearchList = false;
                    }
                });
        } else {
            this.clear();
        }
    }

    getContactsCount() {
        this.contactSearchArr.filter((ele, i) => {
            this.contactsCount = this.contactSearchArr[i].count;
        })
    }

    getDealsCount() {
        this.dealSearchArr.filter((ele, i) => {
            this.dealsCount = this.dealSearchArr[i].count;
        })
    }

    clear() {
        this.checkSearchList = true;
        this.searchValue = "";
        this.contactSearchArr = [];
        this.dealSearchArr = [];
        this.contactOffset = this.dealOffset = 0;

    }

    viewMore(value) {
        if (value == 'contacts') {
            this.contactOffset += 5;
            var searchArrNew = [];
            this.searchS.contactDealSearch(this.userS.user.srmUserid, this.searchValue, this.listSize, this.contactOffset, this.enableContact, this.enableDeal, value)
                .then(result => {
                    if (result && result.local.searchList && result.local.searchList.data && result.local.searchList.data.contacts) {
                        searchArrNew = result.local.searchList.data.contacts;
                        this.contactSearchArr = [...this.contactSearchArr, ...searchArrNew];
                        this.getContactsCount();
                    }
                });
        }
        if (value == 'deals') {
            this.dealOffset += 5;
            var searchArrNew = [];
            this.searchS.contactDealSearch(this.userS.user.srmUserid, this.searchValue, this.listSize, this.dealOffset, this.enableContact, this.enableDeal, value)
                .then(result => {
                    if (result && result.local.searchList && result.local.searchList.data && result.local.searchList.data.deals) {
                        searchArrNew = result.local.searchList.data.deals;
                        this.dealSearchArr = [...this.dealSearchArr, ...searchArrNew];
                        this.getDealsCount();
                    }
                });
        }

    }

    navigateContact(value, index) {
        this.router.navigate(['/home/contacts/' + value.contactid])
        this.clear();
    }

     navigateDeal(value,id) {
        this.router.navigate(['/home/deals/' + value.dealid + '/contact/' + value.contactid])
        this.clear();
    }
}
