/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class notesservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_notesservice

  async addNoteByModule(
    moduleName: any = undefined,
    moduleId: any = undefined,
    note: any = undefined,
    supermodulename: any = undefined,
    supermoduleid: any = undefined,
    pinnedSequence: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          note: note,
          supermodulename: supermodulename,
          supermoduleid: supermoduleid,
          pinnedSequence: pinnedSequence
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_UNP9qAZVErUBE863(bh);
      //appendnew_next_addNoteByModule
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_DVCQZqSDxsV1ObcD');
    }
  }

  async deleteNoteByIdByModule(
    moduleName: any = undefined,
    moduleId: any = undefined,
    noteId: any = undefined,
    supermodulename: any = undefined,
    supermoduleid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          noteId: noteId,
          supermodulename: supermodulename,
          supermoduleid: supermoduleid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_GrhbTGVebDxVzEuv(bh);
      //appendnew_next_deleteNoteByIdByModule
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0BjYssrgkdsldki8');
    }
  }

  async getAllNotesByModule(
    moduleName: any = undefined,
    moduleId: any = undefined,
    supermodulename: any = undefined,
    supermoduleid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          supermodulename: supermodulename,
          supermoduleid: supermoduleid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_Aq4SwypBROUEKhEx(bh);
      //appendnew_next_getAllNotesByModule
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wdiO5s0lB3a4H0Sl');
    }
  }

  async getAllNotesByIdByModule(
    moduleName: any = undefined,
    moduleId: any = undefined,
    noteId: any = undefined,
    supermodulename: any = undefined,
    supermoduleid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          noteId: noteId,
          supermodulename: supermodulename,
          supermoduleid: supermoduleid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_xoJRhqUDupkyHftZ(bh);
      //appendnew_next_getAllNotesByIdByModule
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lUIP4QJ3YAJz6U6t');
    }
  }

  async updateNoteByidByModule(
    moduleName: any = undefined,
    moduleId: any = undefined,
    noteId: any = undefined,
    note: any = undefined,
    supermodulename: any = undefined,
    supermoduleid: any = undefined,
    pinnedSequence: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          noteId: noteId,
          note: note,
          supermodulename: supermodulename,
          supermoduleid: supermoduleid,
          pinnedSequence: pinnedSequence
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_6gNIDDtHXdrfLKGy(bh);
      //appendnew_next_updateNoteByidByModule
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lnieEK7p79QrJSZG');
    }
  }

  //appendnew_flow_notesservice_Start

  async sd_UNP9qAZVErUBE863(bh) {
    try {
      let m = bh.input.moduleName;
      let mId = bh.input.moduleId;

      let url =
        bh.system.environment.properties.ssdURL + 'notes/' + m + '/' + mId;

      if (bh.input.supermodulename) {
        url += '/' + bh.input.supermodulename;
      }

      if (bh.input.supermoduleid) {
        url += '/' + bh.input.supermoduleid;
      }

      bh.url = url;

      bh.body = {
        notes: bh.input.note,
        pinnedSequence: bh.input.pinnedSequence
      };
      bh = await this.sd_Ercc2nHhcGAH4BuR(bh);
      //appendnew_next_sd_UNP9qAZVErUBE863
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_UNP9qAZVErUBE863');
    }
  }

  async sd_Ercc2nHhcGAH4BuR(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_Ercc2nHhcGAH4BuR
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Ercc2nHhcGAH4BuR');
    }
  }

  async sd_GrhbTGVebDxVzEuv(bh) {
    try {
      let m = bh.input.moduleName;
      let mId = bh.input.moduleId;
      let nId = bh.input.noteId;

      let url =
        bh.system.environment.properties.ssdURL +
        'notes/' +
        m +
        '/' +
        mId +
        '/note/' +
        nId;

      if (bh.input.supermodulename) {
        url += '/' + bh.input.supermodulename;
      }

      if (bh.input.supermoduleid) {
        url += '/' + bh.input.supermoduleid;
      }

      bh.url = url;

      bh = await this.sd_cL0Ugid5xveSVQSK(bh);
      //appendnew_next_sd_GrhbTGVebDxVzEuv
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_GrhbTGVebDxVzEuv');
    }
  }

  async sd_cL0Ugid5xveSVQSK(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_cL0Ugid5xveSVQSK
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_cL0Ugid5xveSVQSK');
    }
  }

  async sd_Aq4SwypBROUEKhEx(bh) {
    try {
      let m = bh.input.moduleName;
      let mId = bh.input.moduleId;

      let url =
        bh.system.environment.properties.ssdURL + 'notes/' + m + '/' + mId;

      if (bh.input.supermodulename) {
        url += '/' + bh.input.supermodulename;
      }

      if (bh.input.supermoduleid) {
        url += '/' + bh.input.supermoduleid;
      }

      bh.url = url;
       bh = await this.sd_hiH806ErxzcIAmwm(bh);
      //appendnew_next_sd_Aq4SwypBROUEKhEx
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Aq4SwypBROUEKhEx');
    }
  }

  async sd_hiH806ErxzcIAmwm(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_hiH806ErxzcIAmwm
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_hiH806ErxzcIAmwm');
    }
  }

  async sd_xoJRhqUDupkyHftZ(bh) {
    try {
      let m = bh.input.moduleName;
      let mId = bh.input.moduleId;
      let nId = bh.input.noteId;

      let url =
        bh.system.environment.properties.ssdURL +
        'notes/' +
        m +
        '/' +
        mId +
        '/note/' +
        nId;

      if (bh.input.supermodulename) {
        url += '/' + bh.input.supermodulename;
      }

      if (bh.input.supermoduleid) {
        url += '/' + bh.input.supermoduleid;
      }

      bh.url = url;

      bh = await this.sd_hiH806ErxzcIAmwm(bh);
      //appendnew_next_sd_xoJRhqUDupkyHftZ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_xoJRhqUDupkyHftZ');
    }
  }

  async sd_6gNIDDtHXdrfLKGy(bh) {
    try {
      let m = bh.input.moduleName;
      let mId = bh.input.moduleId;
      let nId = bh.input.noteId;

      let url =
        bh.system.environment.properties.ssdURL +
        'notes/' +
        m +
        '/' +
        mId +
        '/note/' +
        nId;

      if (bh.input.supermodulename) {
        url += '/' + bh.input.supermodulename;
      }

      if (bh.input.supermoduleid) {
        url += '/' + bh.input.supermoduleid;
      }

      bh.url = url;

      bh.body = {
        notes: bh.input.note,
        pinnedSequence: bh.input.pinnedSequence
      };
      bh = await this.sd_Gf6LhCo5HkQccvkW(bh);
      //appendnew_next_sd_6gNIDDtHXdrfLKGy
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_6gNIDDtHXdrfLKGy');
    }
  }

  async sd_Gf6LhCo5HkQccvkW(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_Gf6LhCo5HkQccvkW
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Gf6LhCo5HkQccvkW');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_notesservice_Catch
}
