<div fxLayout="row " fxLayoutGap="0.5em" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.gt-xs]="&apos;height: 2em;&apos;">
  <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true" [matMenuTriggerFor]="profileMenu">
    <div [matMenuTriggerFor]="profileMenu" class="profile-view-image">
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true"><img imgSrc="Web/Icons/userprofile.svg" [ngStyle.xs]="&apos;height: 35px&apos;" class="common-element-border common-menu-image-height"></div>
  <div fxLayout="row " class="cursor-pointer" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true" [matMenuTriggerFor]="profileMenu">
    <h3 class="common-font-size">
      {{userDisplayName}}</h3>
    <mat-icon class="generic-text-color">expand_more</mat-icon>
  </div>
</div>
<mat-menu #profileMenu class="common-menu-list-items" #menu="matMenu"><button mat-menu-item (click)="logout()" class="font-Family common-menu-item-align"> <img class="common-add-list-images" imgSrc="/Web/Icons/logout.svg">Logout </button></mat-menu>