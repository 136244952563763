<div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill id="role-sidenav-form">
  <div fxLayout="column wrap" class="sidenav-form-tile" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div class="role-form-icon">
          </div><span class="role-form-title">
          {{mode}} ROLE</span>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="roleFormGroup" fxLayout="column" class="height-100 sidenav-form-content" (ngSubmit)="update()">
    <div fxLayout="column " class="height-100" fxLayoutGap="0.5rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <mat-form-field class="width-100" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
          <input matInput placeholder="Enter Role Name" [required]="false" trim="blur" [formControl]="roleFormGroup?.controls.rolename" id="add-role-rolename">
          <mat-error align="start" *ngIf="this.roleFormGroup?.get(&apos;rolename&apos;)?.errors?.required">Role Name cannot be empty</mat-error>
          <mat-error align="start" *ngIf="this.roleFormGroup?.get(&apos;rolename&apos;)?.errors?.maxlength">Name can have a maximum of 25 characters</mat-error>
          <mat-label>Role Name</mat-label>
        </mat-form-field>
      </div>
      <div fxLayout="column " fxLayoutGap="0.5rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" class="margin-bottom-1" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div class="role-form-section-title">
            Permissions</div>
        </div>
        <div fxLayout="column " class="margin-bottom-1" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column " fxFlex="100" fxLayoutGap="0.5rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div class="font-primary-color checkbox-group-title">
              Primary Permissions</div>
            <div fxLayout="column " fxFlex="100" fxLayoutGap="0.375rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let c of roleFormGroup?.controls.primaryPermission?.controls; let  i = index;">
              <div fxLayout="row " fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <mat-checkbox value="pPerms[i].value" [required]="false" [formControl]="c">{{pPerms[i].label}}</mat-checkbox>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><span class="checkbox-item-description">
                {{pPerms[i].description}}</span></div>
            </div>
            <mat-error align="start" *ngIf="roleFormGroup?.get(&apos;primaryPermission&apos;)?.errors?.atleastOneTruthy">Atleast one of the &quot;Primary Permissions&quot; should be selected</mat-error>
          </div>
        </div>
        <div fxLayout="column " fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column " fxFlex="100" fxLayoutGap="0.5rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div class="font-primary-color checkbox-group-title">
              Additional Permissions</div>
            <div fxLayout="column " fxFlex="100" fxLayoutGap="0.375rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let c of roleFormGroup?.controls.secondaryPermission?.controls; let  i = index;">
              <div fxLayout="row " fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <mat-checkbox [required]="false" [formControl]="c">{{sPerms[i].label}}</mat-checkbox>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><span class="checkbox-item-description">
                {{sPerms[i].description}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column wrap" fxFlex="4rem" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="primary-form-action-button" [disabled]="addDisabled" type="submit">{{ mode === &apos;EDIT&apos; ? &apos;Update&apos; : &apos;Add&apos; }}</button></div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-form-action-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>