<div fxLayout="column " fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row wrap" class="lr-padding border-radius card-like" fxFlex="60px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <h2>
          DASHBOARD</h2>
      </div>
    </div>
    <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
      <div>
        <mat-button-toggle-group [(ngModel)]="statsValue" class="custom-mat-toggle custom-stats-toggle margin-left-1" name="buttonTogg">
          <mat-button-toggle (click)="getStats()" style="text-align: center;width: 200px;" value="my"> My Stats
          </mat-button-toggle>
          <mat-button-toggle (click)="getStats()" style="text-align: center;width: 200px;" value="team" *ngxPermissionsOnly="[&apos;HAS_TEAM&apos;]"> Team Stats </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="height-100 display-block overflow-y" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column " class="height-100 padding-1-0 border-radius card-like" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="statsArr.length">
      <div fxLayout="row wrap" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div>
          <mat-button-toggle-group [(ngModel)]="selectedView" class="custom-mat-toggle custom-stats-toggle margin-left-1" name="buttonTogg">
            <mat-button-toggle (click)="getView(&apos;Weekly&apos;)" style="text-align: center;width: 125px;" value="Weekly"> Weekly
            </mat-button-toggle>
            <mat-button-toggle (click)="getView(&apos;Monthly&apos;)" style="text-align: center;width: 125px;" value="Monthly"> Monthly</mat-button-toggle>
            <mat-button-toggle (click)="getView(&apos;Custom&apos;)" style="text-align: center;width: 125px;" value="Custom"> Custom Date</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div fxLayout="row " fxFlex="8" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <mat-icon class="cursor-pointer" (click)="(selectedView == &apos;Monthly&apos;) ? previousMonth(first_date) : previousWeek(first_date)" [ngStyle]="{&apos;display&apos;: (selectedView == &apos;Custom&apos;) ? &apos;none&apos; : &apos;&apos;}"><i class="material-icons"> keyboard_arrow_left </i></mat-icon>
        </div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle]="{&apos;pointer-events&apos;: (selectedView == &apos;Custom&apos;) ? &apos;&apos; : &apos;none&apos;}">
          <div>
            <div fxLayout="row" fxLayoutAlign="start center" class="clock-height" style="margin: 1px -2px -6px 8px">

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100px">
                <mat-form-field class="no-border-field width-dt" style="width: 100px;">
                  <input [ngClass]="(selectedView == &apos;Custom&apos;) ? &apos;custom-date-input cursor-pointer&apos; : &apos;&apos;" matInput [(ngModel)]="first_date" (dateChange)="getCustomDate()" [matDatepicker]="pickerFrom" (focus)="pickerFrom.open()" (click)="pickerFrom.open()" readonly>

                  <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
              </div>

              <span style="padding: 0px 6px;margin: -2px 15px 5px 0px;">to</span>

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100px">
                <mat-form-field class="no-border-field width-dt" style="width: 100px;">
                  <input [ngClass]="(selectedView == &apos;Custom&apos;) ? &apos;custom-date-input cursor-pointer&apos; : &apos;&apos;" matInput [(ngModel)]="last_date" (dateChange)="getCustomDate()" [min]="first_date" [matDatepicker]="pickerTo" (focus)="pickerTo.open()" (click)="pickerTo.open()" readonly>

                  <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <mat-icon class="cursor-pointer" (click)="(selectedView == &apos;Monthly&apos;) ? nextMonth(first_date) : nextWeek(last_date)" [ngStyle]="{&apos;display&apos;: (selectedView == &apos;Custom&apos;) ? &apos;none&apos; : &apos;&apos;}"><i class="material-icons"> keyboard_arrow_right </i></mat-icon>
        </div>
      </div>
      <div fxLayout="row " class="lr-padding tb-padding" fxFlex="80" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" class="height-100" fxFlex="24.5" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let stats of statsArr">
          <bh-dashboardstats [stats]="stats"></bh-dashboardstats>
        </div>
      </div>
    </div>
    <div fxLayout="column " style="height: 92%;" class="overflow-hidden border-radius card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="averageArr.length &amp;&amp; usersList.length  &amp;&amp; statsCheck">
      <div fxLayout="row wrap" style="width: 50%; padding: 1em 0em 0em 1em !important; " class="padding-1" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <h2>
            PERFORMANCE COMPARISON</h2>
        </div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true"><button mat-button class="action-button common-filter-image cursor-pointer" [matMenuTriggerFor]="userList" matTooltip="Select User">
              <div class="filter-search-image"></div>
            </button></div>
        </div>
      </div>
      <div fxLayout="row " style="width: 50%;padding: 16px 0px 12px 10px;" fxFlex="80" fxLayoutGap="5px" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" class="height-100" fxFlex="49.5" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let stats of averageArr">
          <bh-dashboardstats class="width-100" [stats]="stats"></bh-dashboardstats>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-menu #userList class="user-menu-list" xPosition="before" #menu="matMenu">
  <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let val of usersList"><button mat-menu-item (click)="getUser(val)" class="font-Family common-menu-item-align generic-text-color"> {{val.first_name}} {{val.last_name}} </button></div>
</mat-menu>