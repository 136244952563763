/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
// import { cacheservice } from 'app/sd-services/cacheservice';
// import { fileservice } from 'app/sd-services/fileservice';
import { ColorPickerService, Cmyk } from "ngx-color-picker";
import { tenantservice } from 'app/sd-services/tenantservice';
import { NeutrinosOAuthClientService } from 'neutrinos-oauth-client';
import { MatSnackBar } from '@angular/material/snack-bar';


/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-form_theme_logo',
    templateUrl: './form_theme_logo.template.html'
})

export class form_theme_logoComponent extends NBaseComponent implements OnInit {
    file: any = [];
    file_name: any;
    file_size;
    invalidImage= false;
    errorMsg = "";
    tagErrorMsg = "";
    contactid;
    dealid;
    file_id;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    documentTitle;
    disableButton = true;
    // readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    primaryColor;
    secondaryColor;
    fileChanged = false;
    primaryColorChanged = false;
    secondaryColorChanged = false;
    tenantSetting;
    tenantIcon;
    msg = '';

    constructor(
        private _pubsub: NPubSubService,
        private cpService: ColorPickerService,
        private tenantService: tenantservice,
        private authService: NeutrinosOAuthClientService,
        private snackBar: MatSnackBar
        ) {
        super();
    }

    async ngOnInit() {
        this.tenantSetting = (await this.tenantService.getTenantSettings()).local.tenantSettings;
        this.file_name = this.tenantSetting['TENANT_LOGO_LOCATION'];
        this.primaryColor = this.tenantSetting['COLOR_PRIMARY'];
        this.secondaryColor = this.tenantSetting['COLOR_SECONDARY'];
        if (this.tenantSetting['TENANT_LOGO_LOCATION']) {
            this.file_name = 'tenant_logo';
        } else {
            this.file_name = '';
        }
        if (!this.primaryColor) {
            this.primaryColor = "#204f9c";
        }
        if (!this.secondaryColor) {
            this.secondaryColor = "#8db3ed";
        }
    }
    close() {
		this._pubsub.$pub('sidenavformevents', { ACTION: 'close' });
	}

	cancel() {
		this.close();
	}
    async save() {
        if (this.file_size >= (1024 * 1024 * 25)) {
            this.errorMsg = "Maximum size for document upload is 25 MB";
            return false;
        }
        
        if (this.fileChanged) {
            this.disableButton = true;
            if (this.file && this.file.length === 0) {
                await this.tenantService.setTenantSettingValue('TENANT_LOGO_LOCATION', null)
            } else {
                let body: FormData = new FormData();
                body.append('document', this.file, this.file.name);
                await this.tenantService.updateTenantIcon(body)
            }
            this._pubsub.$pub('refresh-logo');
        }
        if (this.primaryColorChanged || this.secondaryColorChanged) {
            this.disableButton = true;
            await this.tenantService.updateTheme(this.primaryColor, this.secondaryColor)
            this._pubsub.$pub('refresh-settings');
            // console.log('primary chnaged', new Date().getTime());
        }
        // if (this.secondaryColorChanged) {
        //     this.disableButton = true;
        //     console.log('before secondary', new Date().getTime())
        //     await this.tenantService.setTenantSettingValue('COLOR_SECONDARY', this.secondaryColor)
        //     console.log('after secondary', new Date().toTimeString())
        //     this._pubsub.$pub('refresh-settings');
        //     console.log('secondary chnaged', new Date().toTimeString())
        // }
        // this.msg += ' Updated Successfully';
        this.disableButton = false;
        // await this.tenantService.initTenantSettings(this.authService['currentUserInfo'].tenantSettings);
        
        this.snackBar.open('Theme & Logo Updated successfully', 'OK', {
            duration: 3000
        });
        this.close();
    }
    removeFile() {
        this.file = [];
        this.file_name = '';
        this.fileChanged = true;
        this.disableButton = false;
    }

    handleChange(files) {
        this.file = [];
        if (files && files[0] && files[0].file) {
            var t = files[0].file.type.split('/').pop().toLowerCase();
            if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
                this.invalidImage = true;
                this.errorMsg = "Select a valid image type";
                return false;
            }
            this.file = files[0].file;
            this.file_name = this.file.name;
            this.file_size = this.file.size;
            this.fileChanged = true;
            this.disableButton = false;
        }
        if (this.file && this.file.length != 0) {
            this.errorMsg = "";
            return false;
        }
    }

    fileUpload(files: FileList) {
        this.file = [];
        if (files && files.length) {
            this.file = files[0];
            this.file_name = this.file.name;
            this.file_size = this.file.size;
            this.fileChanged = true;
            this.disableButton = false;
        }
        if (this.file && this.file.length != 0) {
            this.errorMsg = "";
            return false;
        }
    }
    // color selection
    public onEventLog(event: string, data: any): void {
        // console.log(event, data);
        this.primaryColorChanged = true;
        this.disableButton = false;
        this.primaryColor = data;
        console.log(data)
    }
    public onEventLog2(event: string, data: any): void {
        this.secondaryColorChanged = true;
        this.disableButton = false;
        console.log(event, data);
        this.secondaryColor = data;
    }

    public onChangeColor(color: string): void {
        console.log("Color changed:", color);
    }

    public onChangeColorCmyk(color: string): Cmyk {
        const hsva = this.cpService.stringToHsva(color);

        if (hsva) {
        const rgba = this.cpService.hsvaToRgba(hsva);

        return this.cpService.rgbaToCmyk(rgba);
        }

        return new Cmyk(0, 0, 0, 0);
    }

    public onChangeColorHex8(color: string): string {
        const hsva = this.cpService.stringToHsva(color, true);

        if (hsva) {
        return this.cpService.outputFormat(hsva, "rgba", null);
        }

        return "";
    }
    openPicker() {
        // this.cpService.colorPickerOpen();
    }
}
