/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

import { utilservice } from './utilservice'; //_splitter_
//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class roleservice {
  constructor(
    private utilservice: utilservice,
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_roleservice

  async addRole(roleobj: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          roleobj: roleobj
        },
        local: {
          res: undefined,
          url: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_gchjYRPmXND7QJy6(bh);
      //appendnew_next_addRole
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_d3X51mTtmYcRAQZ7');
    }
  }

  async getRoles(
    pageSize = 10,
    pageOffset = 0,
    lite = false,
    sortBy: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          lite: lite,
          sortBy: sortBy
        },
        local: {
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_dlmzjXPqSkNbfz2g(bh);
      //appendnew_next_getRoles
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_zcJv3FM9IVWVqt67');
    }
  }

  async updateRole(roleObj: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          roleObj: roleObj
        },
        local: {
          res: undefined,
          url: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_meDbdAHhE8L233kt(bh);
      //appendnew_next_updateRole
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1kgLHYA71AsMDzvX');
    }
  }

  //appendnew_flow_roleservice_Start

  async sd_gchjYRPmXND7QJy6(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `user/roles`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_55myTvy0uRpetABQ(bh);
      //appendnew_next_sd_gchjYRPmXND7QJy6
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_gchjYRPmXND7QJy6');
    }
  }

  async sd_55myTvy0uRpetABQ(bh) {
    try {
      bh.local.body = bh.input.roleobj;
      bh = await this.sd_q2sanM95Ijk2kQWv(bh);
      //appendnew_next_sd_55myTvy0uRpetABQ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_55myTvy0uRpetABQ');
    }
  }

  async sd_q2sanM95Ijk2kQWv(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.local.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_q2sanM95Ijk2kQWv
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_q2sanM95Ijk2kQWv');
    }
  }

  async sd_dlmzjXPqSkNbfz2g(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `user/roles/tenant`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.setPageQueryData(bh);
      //appendnew_next_sd_dlmzjXPqSkNbfz2g
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_dlmzjXPqSkNbfz2g');
    }
  }

  async setPageQueryData(bh) {
    try {
      if (bh.input.lite) {
        bh.local.q = {
          lite: true
        };
      } else {
        bh.local.q = {
          pageSize: bh.input.pageSize,
          pageOffset: bh.input.pageOffset,
          ...(Array.isArray(bh.input.sortBy)
            ? {
                sortBy: bh.input.sortBy
                  .map(c => `${c.sortKey} ${c.sortValue}`)
                  .toString()
              }
            : undefined)
        };
      }
      bh = await this.sd_oUOwvUQzdOIZBkyF(bh);
      //appendnew_next_setPageQueryData
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_aaKjOQX6wbZMEjAr');
    }
  }

  async sd_oUOwvUQzdOIZBkyF(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: bh.local.q,
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_oUOwvUQzdOIZBkyF
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_oUOwvUQzdOIZBkyF');
    }
  }

  async sd_meDbdAHhE8L233kt(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `user/roles`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_zPlujJ7IJRj6ESM1(bh);
      //appendnew_next_sd_meDbdAHhE8L233kt
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_meDbdAHhE8L233kt');
    }
  }

  async sd_zPlujJ7IJRj6ESM1(bh) {
    try {
      bh.local.body = bh.input.roleObj;
      bh = await this.sd_Oc5TovvY8XuekNux(bh);
      //appendnew_next_sd_zPlujJ7IJRj6ESM1
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_zPlujJ7IJRj6ESM1');
    }
  }

  async sd_Oc5TovvY8XuekNux(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.local.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_Oc5TovvY8XuekNux
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Oc5TovvY8XuekNux');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_roleservice_Catch
}
