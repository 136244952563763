/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { TreeControl, NestedTreeControl, FlatTreeControl } from '@angular/cdk/tree';

import { of } from 'rxjs/observable/of';

interface TestData {
    name: string;
    // level: number;
    children?: TestData[];
}

const GetChildren = (node: TestData) => of(node.children);
// const TC = new FlatTreeControl(GetLevel, IsExpandable);
const TC = new NestedTreeControl(GetChildren);


interface PropData {
    prop: string;
    children?: PropData[];
}
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-blmenutree',
    templateUrl: './blmenutree.template.html'
})

export class blmenutreeComponent extends NBaseComponent implements OnInit {

    tc = TC;
    data = [
        {
            name: 'Leads',
            children: [
                { name: 'Lead 1' },
                { name: 'Lead 2' }
            ]
        }];

    // properDataSource = new MatTreeNestedDataSource<PropData>();




    constructor() {
        super();
    }


    ngOnInit() {

    }

    hasChild(_: number, node: TestData) {
    return node.children != null && node.children.length > 0;
  }

}
