/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, ViewChild, SimpleChanges, NgZone } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { srmappservice } from 'app/sd-services/srmappservice';
import { startWith, map } from 'rxjs/operators';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dynnationality',
    templateUrl: './dynnationality.template.html'
})

export class dynnationalityComponent extends NBaseComponent implements OnInit {
    @Input('placeholder') placeholder = '';
    @Input('fc') fc = new FormControl();
    @Input('required') required = false;
    @Input('field_name') field_name = '';
    @Input('changeCallbackFn') changeCallbackFn;
    countries: Array<{ country: string, nationality: string }> = [];
    filteredCountries: Observable<Array<{ country: string, nationality: string }>>;
    @ViewChild(MatAutocompleteTrigger) trigger;
    @ViewChild('fci') fci;

    constructor(public srmappS: srmappservice, private z: NgZone) {
        super();
    }

    ngOnInit() {
        this.srmappS.getPhoneCountryCodes()
            .then(result => {
                this.countries = result.local.phonecountrycodes.filter(v => v.nationality);
            })
            .catch(e => {
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fc) {
            this.filteredCountries = changes.fc.currentValue.valueChanges.pipe(
                startWith(''),
                map(v => this.filter(v))
            );
        }
    }

    autoCompleteFocused() {
        if (this.fc.value === '' || this.fc.value === undefined || this.fc.value === null) {
            this.trigger._onChange("");
            this.trigger.openPanel();
        } else if (this.fc.value) {
            this.trigger._onChange(this.fc.value);
            this.trigger.openPanel();
        }
    }

    private filter(searchValue) {
        return this.countries.filter((v: { country: string, nationality: string }) => {
            return v.nationality.toLowerCase().startsWith(searchValue.toLowerCase());
        })
    }

    clear() {
        this.z.run(() => {
            this.fc.setValue('');
            this.fci.nativeElement.focus();
            setTimeout(() => {
                this.trigger.openPanel();
            }, 100);
        });
    }


    selected($event: MatAutocompleteSelectedEvent) {
        if (typeof this.changeCallbackFn === 'function') {
            this.changeCallbackFn(this.field_name, $event.option.value);
        }
    }

    ngOnDestroy() { }
}
