/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormControl } from '@angular/forms';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dynnumber',
    templateUrl: './dynnumber.template.html'
})

export class dynnumberComponent extends NBaseComponent implements OnInit {
    @Input('placeholder') placeholder = '__no_placeholder__';
    @Input('fc') fc = new FormControl();
    @Input('required') required = false;
    @Input('field_name') field_name = '';
    @Input('changeCallbackFn') changeCallbackFn;
    constructor() {
        super();
    }

    ngOnInit() {
    }

    _keyUp(event: any) {
        // console.log(event);
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = event.key;
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        } else {
        }
    }

    ngOnDestroy() {
    }
}
