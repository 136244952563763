/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { tenantservice } from 'app/sd-services/tenantservice';
import { FormControl, Validators } from '@angular/forms';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-module_rename',
    templateUrl: './module_rename.template.html'
})

export class module_renameComponent extends NBaseComponent implements OnInit {
    disableAddButton = false;
    singularName = new FormControl(null, Validators.required);
    plularName = new FormControl(null, Validators.required);
    constructor(
        private pubsub: NPubSubService,
        private tenantService: tenantservice
    ) {
        super();
    }

    ngOnInit() {
        this.tenantService.getTenantSettings().then(data=>{
            this.singularName.setValue(data.local.tenantSettings.DEAL_MODULE_NAME_SINGULAR);
            this.plularName.setValue(data.local.tenantSettings.DEAL_MODULE_NAME_PLURAL);
        });
    }
    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }

    async saveModuleName() {

        if (this.singularName.valid && this.plularName.valid) {
            this.disableAddButton = true;
            await this.tenantService.renameDealModule( this.singularName.value, this.plularName.value);
            this.pubsub.$pub('refresh-settings');
            this.close();
            // Promise.all([
            //     this.tenantService.renameDealModule('DEAL_MODULE_NAME_SINGULAR', this.singularName.value),
            //     this.tenantService.setTenantSettingValue('DEAL_MODULE_NAME_PLURAL', this.plularName.value),
            // ]
            // ).then((data) => {
            //     this.pubsub.$pub('refresh-settings');
            //     this.close();
            // }).catch(err => {
            //     this.disableAddButton = false;
            // });
        }

    }

}
