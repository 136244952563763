/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class tenant_logo_serviceService {
    ssdURL = environment.properties.ssdURL;

    constructor(private http: HttpClient){}
    checkTenantLogoExist(){
        return this.http.get(`${this.ssdURL}tenant-icon`);
    }
}
