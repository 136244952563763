/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'app/functions/Validators';
import { cacheservice } from 'app/sd-services/cacheservice';
import { roleservice } from 'app/sd-services/roleservice';
import { usernotificationservice } from 'app/sd-services/usernotificationservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-form_add_role',
	templateUrl: './form_add_role.template.html'
})

export class form_add_roleComponent extends NBaseComponent implements OnInit {
	deal;
	dealTitleCase;
	pPerms = [
		{
			label: 'My Access',
			value: 'MINE',
			description: 'Can only access and edit assigned records'
		},
		{
			label: 'Global Access',
			value: 'ALL',
			description: 'Can access and view all records in the system'
		}
	]
	sPerms = [];

	_roleObject: any = {};
	addDisabled = false;
	permissionGroupMap: Record<string, string> = {};
	roleFormGroup = new FormGroup({
		rolename: new FormControl('', { validators: Validators.compose([Validators.required, Validators.maxLength(25)]) }),
	});
	mode: 'ADD' | 'EDIT' = 'ADD';
	private formKey = 'FORM_ADD_ROLE';

	constructor(private _caches: cacheservice,
		private _pubsub: NPubSubService,
		private _notify: usernotificationservice,
		private _roles: roleservice, private labelService: labelService) {
		super();
	}

	ngOnInit() {
		this._getCachedData().then(roleObj => {
			if (roleObj) {
				this.mode = 'EDIT';
				if (Array.isArray(roleObj.permission_groups)) {
					this.permissionGroupMap = roleObj.permission_groups.reduce((a, c: string) => {
						a[c] = c;
						return a;
					}, {});
				}
				this._roleObject = roleObj;
			}
			this.roleFormGroup.patchValue({ rolename: this._roleObject.role_name });
			this.roleFormGroup.addControl('primaryPermission', this._createPrimaryPermControls());
			this.roleFormGroup.addControl('secondaryPermission', this._createSecondaryPermControls());
		})
	}

	ngAfterViewInit() {
		this.deal = this.labelService.deal;
		this.dealTitleCase = this.deal.charAt(0).toUpperCase() + this.deal.slice(1);
		this.sPerms =  [
			{
				label: 'Contact Re-Assign',
				value: 'CONTACT_REASSIGN',
				description: 'Can re-assign the contacts owned, if team access then re-assign team\'s owned contact as well'
			},
			{
				label: 'Delete Contact',
				value: 'CONTACT_DELETE',
				description: 'Can delete the contacts basis the primary permission access level'
			},
			{
				label: `Delete ${this.dealTitleCase}`,
				value: 'DEAL_DELETE',
				description: `Can delete the ${this.deal} basis the primary permission access level`
			},
			{
				label: 'Admin',
				value: 'ADMIN',
				description: 'Can access admin level permissions'
			}
		]
	}

	private _createPrimaryPermControls() {
		const arr = this.pPerms.map(o => new FormControl(!!this.permissionGroupMap[o.value]));
		return new FormArray(arr, {
			validators: CustomValidators.atleastNTruthy(1, arr)
		});
	}

	private _createSecondaryPermControls() {
		const arr = this.sPerms.map(o => new FormControl(!!this.permissionGroupMap[o.value]));
		return new FormArray(arr);
	}

	async _getCachedData() {
		const cache = this._caches.getc(this.formKey)
		return (await cache).local.data;
	}

	close() {
		this._pubsub.$pub('sidenavformevents', { ACTION: 'close' });
	}

	cancel() {
		this.close();
	}

	async update() {
		this.addDisabled = true;
		const dynMsgPrt = this.mode === 'ADD' ? 'added' : 'updated';
		if (this.roleFormGroup.invalid) {
			this._notify.openSnackbar(`Role cannot be ${dynMsgPrt}. Form is invalid.`);
		} else {
			try {
				const postableRoleObject = {
					role_name: this.roleFormGroup.get('rolename').value,
					permission_groups: this._getPermissionGroupsFromControls(),
					...(this._roleObject?.roleid && { roleid: this._roleObject?.roleid })
				}
				if (this.mode === 'ADD') {
					await this.addRole(postableRoleObject)
				} else {
					await this.updateRole(postableRoleObject);
				}
				this._notify.openSnackbar(`"${postableRoleObject.role_name}" role ${dynMsgPrt} succesfully.`);
				this._pubsub.$pub('PAGE_EVENT_SETTING_ROLES', {
					evt: 'refresh-list',
				});
				this.close();
			} catch (e) {
				this._notify.openSnackbar(`Role could not be ${dynMsgPrt}.`);
			}
		}
		this.addDisabled = false;
	}

	async addRole(roleObj) {
		const roleid = (await this._roles.addRole(roleObj)).local.res.roleid;
	}

	async updateRole(roleObj) {
		await this._roles.updateRole(roleObj);
		this._caches.updatec(roleObj, this.formKey)
	}

	_getPermissionGroupsFromControls() {
		const pPerms = this.roleFormGroup.get('primaryPermission').value.map((c, i) => c && this.pPerms[i].value).filter(Boolean);
		const sPerms = this.roleFormGroup.get('secondaryPermission').value.map((c, i) => c && this.sPerms[i].value).filter(Boolean);
		return [...pPerms, ...sPerms];
	}

	private async _clearCache() {
		await this._caches.deletec(this.formKey)
	}

	async ngOnDestroy() {
		await this._clearCache();
	}
}
