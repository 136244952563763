<div style="position:relative;" class="width-100">
  <label class="phone-number-label">{{placeholder}}{{required ? &apos; *&apos; : &apos;&apos;}}</label>
  <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" class="width-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div class="width-100">
        <mat-form-field class="phone-country-code-autocomplete search-field width-100" floatLabel="always">
          <input type="text" aria-label="Number" matInput [formControl]="fcDisplay" [matAutocomplete]="auto">
          <img matSuffix class="autocomplete-icon" src="assets/Web/Icons/autocomplete.svg">
          <mat-autocomplete (optionSelected)="selectedOption($event.option.value)" [displayWith]="displayFn" autoActiveFirstOption #auto="matAutocomplete" panelWidth="300">
            <mat-option [ngStyle.lg]="&apos;width: 465px&apos;" *ngFor="let option of currenciesFiltered | async" [value]="option">
              {{option.currency_symbol}} {{option.currency_name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>