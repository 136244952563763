/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class srmappservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_srmappservice

  async initPhoneCountryCodes(...others) {
    try {
      var bh = {
        input: {},
        local: {
          phonecountrycodes: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_hDqR0p3Nc4vQezI3(bh);
      //appendnew_next_initPhoneCountryCodes
      return (
        // formatting output variables
        {
          input: {},
          local: {
            phonecountrycodes: bh.local.phonecountrycodes
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EZH8rfe6PHEDB9vc');
    }
  }

  async getPhoneCountryCodes(...others) {
    try {
      var bh = {
        input: {},
        local: {
          phonecountrycodes: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_W5yJoOva0pPUB1Bi(bh);
      //appendnew_next_getPhoneCountryCodes
      return (
        // formatting output variables
        {
          input: {},
          local: {
            phonecountrycodes: bh.local.phonecountrycodes
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_THC5FhwC5syFeiAM');
    }
  }

  async initCurrencyCodes(...others) {
    try {
      var bh = {
        input: {},
        local: {
          currencycodes: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_em2qRkSu63lAz6sN(bh);
      //appendnew_next_initCurrencyCodes
      return (
        // formatting output variables
        {
          input: {},
          local: {
            currencycodes: bh.local.currencycodes
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mbJdZ5mUpgKVYYsO');
    }
  }

  async getCurrencyCodes(...others) {
    try {
      var bh = {
        input: {},
        local: {
          currencycodes: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_h7ksa4WfLSsxOI0B(bh);
      //appendnew_next_getCurrencyCodes
      return (
        // formatting output variables
        {
          input: {},
          local: {
            currencycodes: bh.local.currencycodes
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_vEGNQcKBhzfAEDjM');
    }
  }

  async getCurrencySymbol(currencyId: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          currencyId: currencyId
        },
        local: {
          currencySymbol: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_opiiV1SBtSaSoiog(bh);
      //appendnew_next_getCurrencySymbol
      return (
        // formatting output variables
        {
          input: {},
          local: {
            currencySymbol: bh.local.currencySymbol
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ZIyD1lQicijYAzsw');
    }
  }

  //appendnew_flow_srmappservice_Start

  async sd_hDqR0p3Nc4vQezI3(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'phone-country-codes';
      bh = await this.sd_cGa14PENNRuRr4mx(bh);
      //appendnew_next_sd_hDqR0p3Nc4vQezI3
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_hDqR0p3Nc4vQezI3');
    }
  }

  async sd_cGa14PENNRuRr4mx(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.phonecountrycodes = await this.sdService.nHttpRequest(
        requestOptions
      );
      bh = await this.sd_TlFMI3fgJYzEikRg(bh);
      //appendnew_next_sd_cGa14PENNRuRr4mx
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_cGa14PENNRuRr4mx');
    }
  }

  //__client_service_designer_class_variable_declaration__phonecountrycodes
  phonecountrycodes: any;
  async sd_TlFMI3fgJYzEikRg(bh) {
    try {
      this.phonecountrycodes = bh.local.phonecountrycodes.data;
      //appendnew_next_sd_TlFMI3fgJYzEikRg
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TlFMI3fgJYzEikRg');
    }
  }

  async sd_W5yJoOva0pPUB1Bi(bh) {
    try {
      bh.local.phonecountrycodes = this.phonecountrycodes;
      //appendnew_next_sd_W5yJoOva0pPUB1Bi
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_W5yJoOva0pPUB1Bi');
    }
  }

  async sd_em2qRkSu63lAz6sN(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'currency-codes';
      bh = await this.sd_TvA6y00uP6o2gEA9(bh);
      //appendnew_next_sd_em2qRkSu63lAz6sN
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_em2qRkSu63lAz6sN');
    }
  }

  async sd_TvA6y00uP6o2gEA9(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.currencycodes = await this.sdService.nHttpRequest(
        requestOptions
      );
      bh = await this.sd_O176n288JVn916ul(bh);
      //appendnew_next_sd_TvA6y00uP6o2gEA9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TvA6y00uP6o2gEA9');
    }
  }

  //__client_service_designer_class_variable_declaration__currencycodes
  currencycodes: any;
  async sd_O176n288JVn916ul(bh) {
    try {
      this.currencycodes = bh.local.currencycodes.data;
      //appendnew_next_sd_O176n288JVn916ul
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_O176n288JVn916ul');
    }
  }

  async sd_h7ksa4WfLSsxOI0B(bh) {
    try {
      bh.local.currencycodes = this.currencycodes;
      //appendnew_next_sd_h7ksa4WfLSsxOI0B
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_h7ksa4WfLSsxOI0B');
    }
  }

  async sd_opiiV1SBtSaSoiog(bh) {
    try {
      if (this.currencycodes.length > 0) {
        for (let i = 0; i < this.currencycodes.length; i++) {
          if (this.currencycodes[i].currencyid == bh.input.currencyId) {
            bh.local.currencySymbol = this.currencycodes[i].currency_symbol;
          }
        }
      }
      //appendnew_next_sd_opiiV1SBtSaSoiog
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_opiiV1SBtSaSoiog');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_srmappservice_Catch
}
