/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, OnInit, ViewChild, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Page } from 'app/constants';
import { srmappservice } from 'app/sd-services/srmappservice';
import { tenantservice } from 'app/sd-services/tenantservice';
import { userservice } from 'app/sd-services/userservice';
import { NeutrinosOAuthClientService } from 'neutrinos-oauth-client';
import { NHTTPLoaderService, NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { blmenuComponent } from '../../components/blmenuComponent/blmenu.component';
import { sort_by_listComponent } from '../../components/sort_by_listComponent/sort_by_list.component';
import { dialogService } from '../../services/dialog/dialog.service';
import { settingsService } from '../../services/settings/settings.service';
import { titleService } from 'app/services/title/title.service';
// import "amazon-connect-streams";
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/
declare var connect: any;

@Component({
    selector: 'bh-baselayout',
    templateUrl: './baselayout.template.html'
})

export class baselayoutComponent extends NBaseComponent implements OnInit, AfterViewInit {
    @ViewChild('menu', { static: true }) menu: blmenuComponent;
    @ViewChild('sidenav', { static: true }) sidenav: any;
    @ViewChild('sidenavform', { static: true }) sidenavform: MatSidenav;
    @ViewChild('sidenavMenu', { static: true }) sidenavMenu: any;
    ccpHide = true;
    selectedMenu;
    watcher: Subscription;
    activeMediaQuery = '';
    pubsubSubscription: Subscription;
    settingsSubscription: Subscription;
    loadInSidenav: string;
    hasBackDrop = false;
    httpSubscribe;
    showLoader = false;
    Page = Page;
    opened = true;
    emailData: any;

    constructor(private mediaObserver: MediaObserver,
        public router: Router,
        private nauth: NeutrinosOAuthClientService,
        private userS: userservice,
        private pubsub: NPubSubService,
        private httpLoaderService: NHTTPLoaderService,
        private tenantService: tenantservice,
        private cdr: ChangeDetectorRef,
        private settings: settingsService,
        public matdialog: MatDialog,
        private dialogService: dialogService,
        private titleservice: titleService
    ) {
        super();
        this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;
            if (change.mqAlias == 'xs') {
                this.opened = false;
            } else {
                this.opened = true;
                this.hasBackDrop = false;
            }
            this.viewChange();
        });
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        if (this.activeMediaQuery == 'xs' && this.sidenavMenu._opened) {
            this.sidenavMenu.toggle();
        }
        return;
    }

    @ViewChild('ccpDiv')
    public ccpDiv: ElementRef;

    public ngAfterViewInit(): void {
        let outer_this = this;
        const ccpUrl = 'https://neutrinos.awsapps.com/connect/ccp-v2/';
        connect.core.initCCP(this.ccpDiv.nativeElement, {
            ccpUrl,
            loginPopup: true,
            softphone: {
                allowFramedSoftphone: true
            }
        });
        connect.contact(function (contact) {
            contact.onIncoming(function (contact) {
            });

            contact.onRefresh(function (contact) {
            });

            contact.onAccepted(function (contact) {
            });

            contact.onEnded(function () {
            });

            contact.onConnected(function () {
                console.log(`connected(${contact.getContactId()})`);
                var attributeMap = contact.getAttributes();
                console.log(JSON.stringify(attributeMap));
                if(attributeMap?.url?.value)
                    outer_this.router.navigate([attributeMap['url'].value]);
                //outer_this.router.navigate(['/home/summary']);
            });
        });
    }

    ngOnInit() {
        this.userS.user = this.nauth['currentUserInfo'];
        this.navigateToDefaultRouteByRole();
        this.fixRequired();
        this.viewChange();
        this.initPointerFaces();
        this.pubsubSubscription = this.pubsub.$sub('sidenavformevents', (sidenavformvalue: SideNavFormEventData) => {
            if (sidenavformvalue.ACTION === 'open') {
                this.hasBackDrop = true;
                if (sidenavformvalue && sidenavformvalue['DATA']) {
                    this.emailData = sidenavformvalue['DATA']
                }
                this.loadInSidenav = sidenavformvalue.PAGE;
                if (this.opened == true) {
                    this.sidenavform.open();
                }
                if (this.opened == false) {
                    this.dialogService.openDialog({
                        loadInSidenav: this.loadInSidenav
                    });
                }
            }

            if (sidenavformvalue.ACTION === 'close') {
                if (this.opened == true) {
                    this.sidenavform.close();
                }
                if (this.opened == false) {
                    this.pubsub.$pub('closedialog');
                }
                this.loadInSidenav = null;
                this.hasBackDrop = false;
            }
        })

        this.settingsSubscription = this.pubsub.$sub('refresh-settings', () => this.settings.refreshSettings());
        this.initPointerFaces();
    }

    viewChange() {
        if (this.loadInSidenav) {
            this.pubsub.$pub('closedialog');
            this.sidenavform.close();
        }
    }

    initPointerFaces() {
        this.httpSubscribe = this.httpLoaderService._isHTTPRequestInProgress$.subscribe(inProgress => {
            if (inProgress) {
                this.showLoader = true;
                this.cdr.detectChanges();
                document.getElementsByTagName('body').item(0).classList.add('cursor-progress');
            } else {
                this.showLoader = false;
                this.cdr.detectChanges();
                document.getElementsByTagName('body').item(0).classList.remove('cursor-progress');
            }
        });
    }

    fixRequired() {
        /**
    * Fix for the MatInput required asterisk. (see https://github.com/angular/components/issues/2574#issuecomment-486656158)
    */
        const requiredImplementation = {
            get: function (): boolean {
                if (this._required) {
                    return this._required;
                }

                // The required attribute is set
                // when the control return an error from validation with an empty value
                if (
                    this.ngControl &&
                    this.ngControl.control &&
                    this.ngControl.control.validator
                ) {
                    const emptyValueControl = Object.assign({}, this.ngControl.control);
                    (emptyValueControl as any).value = null;
                    return (
                        "required" in
                        (this.ngControl.control.validator(emptyValueControl) || {})
                    );
                }
                return false;
            },
            set: function (value: boolean) {
                this._required = coerceBooleanProperty(value);
            }
        };
        Object.defineProperty(MatInput.prototype, "required", requiredImplementation);
        Object.defineProperty(MatSelect.prototype, "required", requiredImplementation);
    }

    updatelog(selecteditem) {
        this.selectedMenu == selecteditem;
        if (this.activeMediaQuery == 'xs') {
            this.sidenavMenu.close();
        }
    }

    navigateToDefaultRouteByRole() {
        if (this.router.url == '/home'
            && this.userS.user['srmPermissions'] instanceof Array
            && this.userS.user.srmPermissions.length > 0) {
            if (this.userS.user.srmPermissions.includes('SETTINGS_ADMIN')) {
                this.router.navigate(['/home/settings']);
                this.titleservice.activeLabel = 'Settings';
            } else {
                this.router.navigate(['/home/summary']);
                this.titleservice.activeLabel = 'DailySummary';
            }
        }
    }

    sidenavToggle() {
        this.sidenavMenu.toggle();
        if (this.activeMediaQuery == 'xs') {
            if (this.sidenavMenu.opened) {
                this.hasBackDrop = true;
            } else {
                this.hasBackDrop = false;
            }
        }
    }

    ngOnDestroy() {
        this.watcher.unsubscribe();
        this.httpSubscribe.unsubscribe();
    }
}

export interface SideNavFormEventData {
    ACTION: 'open' | 'close',
    PAGE?: string
}
