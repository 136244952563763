export const environment = {
    "name": "prod",
    "properties": {
        "production": true,
        "ssdURL": "/api/",
        "tenantName": "neutrinos",
        "appName": "neutrinos-srm-ui",
        "namespace": "com.neutrinos.neutrinos-srm-ui",
        "googleMapKey": "AIzaSyCSTnVwijjv0CFRA4MEeS-H6PAQc87LEoU",
        "useDefaultExceptionUI": false,
        "isIDSEnabled": "true",
        "webAppMountpoint": "web"
    }
}