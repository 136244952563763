<div>
  <mat-form-field class="width-100" floatLabel="always">

    <mat-label>{{label || &apos;Users&apos;}}</mat-label>
    <input type="text" #userSearchInput [formControl]="fc" [placeholder]="placeholder" aria-label="Number" name="{{name}}" matInput [matAutocomplete]="autoUsers">
    <img matSuffix src="assets/Web/Icons/ac-clear-icon.svg" class="autocomplete-clear" *ngIf="fc.value &amp;&amp; !searchInProgress" (click)="clear()">
    <mat-spinner matSuffix *ngIf="searchInProgress"></mat-spinner>
    <mat-autocomplete class="search-autocomplete" autoActiveFirstOption #autoUsers="matAutocomplete" [displayWith]="displayWithDisplayName" (optionSelected)="selected($event)">
      <mat-option class="user-search-autocomplete-option" *ngFor="let option of filteredUsers | async" [value]="option">
        <div class="name">{{option.display_name + &apos; (&apos; + option.role_name + &apos;)&apos;}}</div>
        <div class="label">Email: {{option.email_id}}</div>
        <div class="label">Phone no: {{option.phone_country_code + &apos; &apos; + option.phone_no}}</div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>