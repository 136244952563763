/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, Inject, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { contactservice } from 'app/sd-services/contactservice';
import { Router } from '@angular/router';
import { NPubSubService } from 'neutrinos-seed-services';
import { dialogService } from '../../services/dialog/dialog.service';
import { cacheservice } from 'app/sd-services/cacheservice';
import { Header } from '../usersComponent/users.component';
import { SortOrder, SortChangeEvent } from '../sortComponent/sort.component';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';


export interface Contact {
    contact_name: string;
    contact_owner: number;
    contactid: number;
    created_by: number;
    first_name: string;
    last_name: string;
    phone_country_code: string;
    phone_no: string;
    tenantid: number;
    updated_at: string;
}

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-mycontacts',
    templateUrl: './mycontacts.template.html'
})

export class mycontactsComponent extends NBaseComponent implements OnInit, OnChanges {
    @Output('listRefreshed') listRefreshed = new EventEmitter();
    @Output('selectedContacts') selectContacts = new EventEmitter();
    @Output('displayedContactsCount') displayedContactsCount = new EventEmitter();
    @Output() reassignContact = new EventEmitter();
    @Input('media_view') media_view;
   
    @Input('totalCountP') totalCountP;

    @Input('pageSizeP') pageSizeP;

    @Input('pageOffsetP') pageOffsetP;

    @Input('readyP') readyP;

    @Output() paginationEvent = new EventEmitter<string>();

    mycontacts: Contact[] = [];
    totalContacts = 0;
    ready=true
    nameSorting = 'ASC';
    lastUpdateSorting = 'DESC';
    headers: Header[] = [
        { displayText: 'Name', sort: true, sortKey: 'contact_name', sortValue: 'ASC' },
        { displayText: 'Mobile' },
        { displayText: 'Email' },
        { displayText: 'Last Updated Date', sort: true, sortKey: 'updated_at', sortValue: 'DESC' },
        { displayText: 'Action' }
    ]
    currentSortKey = 'updated_at';
    private sortOrder: { key: string, order: SortOrder }[] = [
        {
            key: 'updated_at',
            order: 'DESC'
        },
        {
            key: 'contact_name',
            order: 'ASC'
        }
    ];

    watcher: Subscription;
    activeMediaQuery = '';

    contactRefreshSub;
    sortSub;
    pageSize = 50;
    pageOffset = 0;
    scrollPageOffset = 0;
    selectedContactsArr = [];
    selectedContactsCount = 0;
    selectAllCheck;
    disableCheck;
    sum = 50;
    filters = [];
    labelsCount = 0;
    checkAppendStatus = false;
    permissions = false;

    constructor(private contactService: contactservice, private router: Router,
        private pubsub: NPubSubService,
        private dialog: dialogService,
        private cacheService: cacheservice,
        private mediaObserver: MediaObserver,
        public matdialog: MatDialog,
        private ngxPermissionsService: NgxPermissionsService) {
        super();

        this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;
        });

    }

    ngOnInit() {

        this.pubsub.$sub('resetSelectedContacts', (res) => {
            this.resetSelectedContacts();
        })

        // this.getMyContacts(this.pageSize, this.pageOffset);
        this.contactRefreshSub = this.pubsub.$sub('refreshcontactlist', () => {
            this.cacheService.getc('MY_CONTACT_FILTER').then((result) => {
                let d = result.local.data;
                if (d) {
                    this.filters = d.filters;
                    this.labelsCount = d.filters.length;
                } else {
                    this.filters = [];
                    this.labelsCount = 0
                }
                this.getMyContacts(this.pageSize, this.pageOffset, this.filters);
                // this.cacheService.setc(null, 'APPLY_CONTACT_FILTER');
            }).catch(e => console.log(e));
        });
        this.sortSub = this.pubsub.$sub('mysortlist', (result) => {
            this.findNReplaceSortOrder(result.res.data.key, result.res.data.sort_type);
            // this.getMyContacts(this.mycontacts.length, 0, this.filters);
            this.getMyContacts(this.pageSize, this.pageOffset, this.filters);
        })
        this.sortSub = this.pubsub.$sub('my_contacts_sort', (result) => {
            this.checkAppendStatus = true;
            let contactsortCount = this.mycontacts.length
            this.mycontacts = [];
            this.findNReplaceSortOrder(result.res.data.key, result.res.data.sort_type);
            this.getMyContacts(contactsortCount, 0, this.filters);
        })
        // this.selectedContactsCount = 0;
        this.ngxPermissionsService.permissions$.subscribe((permissions) => {
            if(permissions && permissions['CONTACT_REASSIGN']){
                this.permissions = true;
            } else {
                this.permissions = false;
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {  
        if (changes.media_view) {
            this.pageSize = 50;
            this.pageOffset = 0;
            this.getMyContacts(this.pageSize, this.pageOffset, this.filters);
        }
        else if(Object.keys(changes).includes("totalCountP") || Object.keys(changes).includes("pageSizeP") || Object.keys(changes).includes("pageOffsetP")||Object.keys(changes).includes("readyP") ){

        }
    }

    onScroll() {
        this.checkAppendStatus = true;
        this.scrollPageOffset += 1;
        this.getMyContacts(this.sum, this.scrollPageOffset, this.filters);
    }

    pageEvents($event) {
         this.paginationEvent.next($event);;
    }

    getMyContacts(pageSize: number, pageOffset: number, filters: any[]) {
        this.pubsub.$pub('allContactsSelected', {
            'allContactsSelected': 'false'
        });
        this.selectedContactsArr = [];
        this.selectedContactsCount = 0;
        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
        this.selectAllCheck = false;
        this.disableCheck = true;
        if (typeof pageSize == 'undefined') {
            pageSize = 50;
        }

        if (typeof pageOffset == 'undefined') {
            pageOffset = 0;
        }

        this.pageSize = pageSize;
        this.pageOffset = pageOffset;

        const sortBy = this.sortOrder.map(v => v.key + ' ' + v.order).join(',');
        this.contactService.getMyContacts(pageSize, pageOffset, sortBy, filters)
            .then((bh: {
                input: any,
                local: {
                    result: {
                        data: {
                            count: number,
                            result: Contact[]
                        }
                    }
                }
            }) => {
                if (bh && bh.local && bh.local.result && bh.local.result.data && bh.local.result.data.count) {
                    if (this.checkAppendStatus) {
                        let temp = this.mycontacts;
                        this.mycontacts = bh.local.result.data.result;
                        this.mycontacts = temp.concat(this.mycontacts);
                        this.totalContacts = Number(bh.local.result.data.count);
                        this.checkAppendStatus = false;
                    } else {
                        this.mycontacts = bh.local.result.data.result;
                    }
                    for (let i in this.mycontacts) {
                        this.mycontacts[i]['isSelected'] = false;
                    }
                    this.totalContacts = Number(bh.local.result.data.count);
                    this.displayedContactsCount.emit({value:this.mycontacts.length});
                } else {
                    if( this.activeMediaQuery =='lg'){
                        this.mycontacts = [];
                        this.totalContacts = 0;
                        this.displayedContactsCount.emit({value:this.mycontacts.length});
                    }
                }
                this.listRefreshed.emit({ value: false });
                this.disableCheck = false;
            })
            .catch(error => {
                console.log(error);
            })
    }

    navigateToContact(id) {
        this.router.navigate(['/home/contacts/' + id])
    }

    initialCall(contactid) {
        if (contactid) {
            this.dialog.makeCallDialog({
                modulename: 'contacts',
                moduleid: contactid
            });
        }
    }

    async scheduleAppointment(contactid, contact_name) {
        await this.cacheService.setc({ 'contactid': contactid, 'contact_name': contact_name }, 'CREATE_APPOINTMENT_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'SCHEDULE_APPOINTMENT'
        });
    }
    async createTask(contact) {
        await this.cacheService.setc({ 'contactid': contact.contactid, 'first_name': contact.first_name, 'last_name': contact.last_name }, 'CREATE_TASK_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_TASK'
        });
    }

    sortChange($event: SortChangeEvent) {
        this.currentSortKey = $event.key;
        this.findNReplaceSortOrder($event.key, $event.sort);
        this.getMyContacts(this.pageSize, this.pageOffset, this.filters);
    }

    findNReplaceSortOrder(key, sort: 'ASC' | 'DESC') {
        let i = this.sortOrder.findIndex(v => {
            return v.key === key;
        });

        this.sortOrder.splice(i, 1);
        this.sortOrder.unshift({
            key,
            order: sort
        })
    }
    onRowClick(event, contactid, contactowner) {
        this.selectAllCheck = false;
        if (event.checked) {
            this.selectedContactsArr.push({ 'contactid': contactid, 'contact_owner': contactowner });
            this.selectedContactsCount += 1;
            if (this.selectedContactsCount == this.mycontacts.length) {
                this.selectAllCheck = true;
                if(localStorage.getItem('usersChoiceSelectAll')=='true')
                this.pubsub.$pub('allContactsSelected', {
                    'allContactsSelected': 'true'
                });
            }
        } else {
            for (let i = 0; i < this.selectedContactsArr.length; i++) {
                if (this.selectedContactsArr[i]['contactid'] == contactid) {
                    this.selectedContactsArr.splice(i, 1);
                    this.selectedContactsCount -= 1;
                }
            }
        }
        if (this.selectedContactsCount != this.mycontacts.length) {
            this.pubsub.$pub('allContactsSelected', {
                'allContactsSelected': 'false'
            });
        }
        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
    }
    selectAll(event) {
        if (event.checked) {
            this.selectedContactsArr = [];
            for (let i in this.mycontacts) {
                this.mycontacts[i]['isSelected'] = true;
                this.selectedContactsArr.push({ 'contactid': this.mycontacts[i].contactid, 'contact_owner': this.mycontacts[i].contact_owner });
            }
            this.selectedContactsCount = this.mycontacts.length;
        } else {
            for (let i in this.mycontacts) {
                this.mycontacts[i]['isSelected'] = false;
                this.selectedContactsArr = [];
            }
            localStorage.setItem('allContactsSelected','false');
            localStorage.setItem('usersChoiceSelectAll','false')
            this.selectedContactsCount = 0;
        }
        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
    }

    resetSelectedContacts() {
        this.selectAllCheck=false
        for (let i in this.mycontacts) {
            this.mycontacts[i]['isSelected'] = false;
            this.selectedContactsArr = [];
        }
        this.selectedContactsCount = 0;
        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
    }
    

    reassign(contact) {
        this.reassignContact.emit({ count: 1, obj: [{ contactid: contact.contactid, contact_owner: contact.contact_owner }] });
    }

    ngOnDestroy() {
        if (this.contactRefreshSub) {
            this.contactRefreshSub.unsubscribe();
        }
        if (this.sortSub) {
            this.sortSub.unsubscribe();
        }
    }
}
