<div *ngIf="stats" class="height-100 summary-info-card">
  <h3 class="summary-info-card-header summary-info-card-tile-text" [ngStyle]="{&apos;min-height&apos;: (stats.title) ? &apos;&apos; : &apos;5%&apos;}">
    {{stats.title}}</h3>
  <div class="summary-info-card-content-section">
    <h3 *ngFor="let row of stats.list;" class="summary-info-card-row">
      <span class="summary-info-card-row-col summary-info-card-title stats-row-width dashboard-stats-label">
	<div *ngIf="stats.title" fxLayout="row" fxLayoutAlign="space-between center">

          <span>{{row.key}}</span> <span>{{row.value}}</span>
  </div>
  </span>

  </h3>
  <div *ngIf="!stats.title" style="height: calc(100% - 35px)" fxLayout="row" fxLayoutAlign="center center">
    <h2 class="summary-info-card-row light-text-color">
      No User Selected
    </h2>
  </div>
</div>
</div>