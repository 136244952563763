/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-emailtrail',
    templateUrl: './emailtrail.template.html',
    styleUrls: ['emailtrail.style.scss']
})

export class emailtrailComponent extends NBaseComponent implements OnInit {
    @Input('email') email: any;
    replyData: any;
    isRelpy = false;
    isHide = false;
    
    constructor(private pubsub: NPubSubService, ) {
        super();
    }
    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }
    ngOnInit() {
    }
    replyMail() {
        console.log(this.email,'reply')
        this.isRelpy = true;
        this.replyData = {
            emailType:'reply',
            from_email:'',
            to_Email:this.email['emailData']['from'],
            topic:this.email['emailData']['topic'],
            dealId : this.email['caseID']
        }
    }
    cancel() {
        this.isRelpy = false;
        // this.close();
    }

}
