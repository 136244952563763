/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

import { contactservice } from './contactservice'; //_splitter_
import { srmappservice } from './srmappservice'; //_splitter_
import { utilservice } from './utilservice'; //_splitter_
//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class udfservice {
  constructor(
    private utilservice: utilservice,
    private srmappservice: srmappservice,
    private contactservice: contactservice,
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_udfservice

  async refreshUDFListByDependency(udf_data: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          udf_data: udf_data
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.callCreateDependencyList(bh);
      //appendnew_next_refreshUDFListByDependency
      return (
        // formatting output variables
        {
          input: {
            udf_data: bh.input.udf_data
          },
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_clwA70N9txZ1BiEm');
    }
  }

  async createDependencyList(udf_data: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          udf_data: udf_data
        },
        local: {
          depsList: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_EjbTtfexvcgCjxku(bh);
      //appendnew_next_createDependencyList
      return (
        // formatting output variables
        {
          input: {},
          local: {
            depsList: bh.local.depsList
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EovHJR9ZS72HV1fR');
    }
  }

  async createSummarySections(
    udf_data: any = undefined,
    showEditButton: any = undefined,
    title: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          udf_data: udf_data,
          showEditButton: showEditButton,
          title: title
        },
        local: {
          summaryConfig: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_hcoa2NrTkItQ2X1y(bh);
      //appendnew_next_createSummarySections
      return (
        // formatting output variables
        {
          input: {},
          local: {
            summaryConfig: bh.local.summaryConfig
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_8aYq9xjA5NeU7kJk');
    }
  }

  async createDynFormSections(udf_data: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          udf_data: udf_data
        },
        local: {
          dynFormSections: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_9PJPEijEFqe9SrJw(bh);
      //appendnew_next_createDynFormSections
      return (
        // formatting output variables
        {
          input: {},
          local: {
            dynFormSections: bh.local.dynFormSections
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_kjLZBPVfAZFJ9Vob');
    }
  }

  async changeValueBasedOnType(
    ui_type: any = undefined,
    value: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          ui_type: ui_type,
          value: value
        },
        local: {
          value: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_3gZ197O0PdNkDhXX(bh);
      //appendnew_next_changeValueBasedOnType
      return (
        // formatting output variables
        {
          input: {},
          local: {
            value: bh.local.value
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Vo41RYiWzDJyB9kp');
    }
  }

  async getTenantModuleUdfs(modulename: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          modulename: modulename
        },
        local: {
          res: undefined,
          udfs: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.contructGetUdfsUrl(bh);
      //appendnew_next_getTenantModuleUdfs
      return (
        // formatting output variables
        {
          input: {},
          local: {
            udfs: bh.local.udfs
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_E2yTQVCkOVUKxlRR');
    }
  }

  async putTenantModuleUdfs(
    udf_metadata: any = undefined,
    modulename: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          udf_metadata: udf_metadata,
          modulename: modulename
        },
        local: {
          res: undefined,
          url: '',
          body: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.contructPostUdfsUrl(bh);
      //appendnew_next_putTenantModuleUdfs
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_4BXQHOhFnH0wZI8p');
    }
  }

  //appendnew_flow_udfservice_Start

  async callCreateDependencyList(bh) {
    try {
      let outputVariables = await this.createDependencyList(bh.input.udf_data);
      bh.local.deps = outputVariables.local.depsList;

      bh = await this.sd_yWvcL35oN37ptyvu(bh);
      //appendnew_next_callCreateDependencyList
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_odTEC2rt4F9yXUyY');
    }
  }

  async sd_yWvcL35oN37ptyvu(bh) {
    try {
      let dependencies = bh.local.deps;
      let udf_data = JSON.parse(JSON.stringify(bh.input.udf_data));
      let depsList = Object.keys(dependencies);
      for (let i = 0; i < depsList.length; i++) {
        const df = udf_data.find(v => {
          return v.field_name === depsList[i];
        });
        // for eq operation
        if (dependencies[depsList[i]] && dependencies[depsList[i]]['eq']) {
          let fields = dependencies[depsList[i]]['eq'];
          if (fields instanceof Array) {
            fields.forEach(
              (v: {
                dependency_operation: string;
                dependency_value: string;
                field: string;
                index: number;
              }) => {
                if (df && df.field_value === v.dependency_value) {
                  if (v.dependency_operation == 'hidden') {
                    udf_data[v.index].___markedForHidden__ = true;
                  }

                  if (v.dependency_operation == 'required') {
                    udf_data[v.index].field_is_required = true;
                  }
                }
              }
            );
          }
        }

        if (dependencies[depsList[i]] && dependencies[depsList[i]]['neq']) {
          let fields = dependencies[depsList[i]]['neq'];
          if (fields instanceof Array) {
            fields.forEach(
              (v: {
                dependency_operation: string;
                dependency_value: string;
                field: string;
                index: number;
              }) => {
                if (df && df.field_value != v.dependency_value) {
                  if (v.dependency_operation == 'hidden') {
                    udf_data[v.index].___markedForHidden__ = true;
                  }

                  if (v.dependency_operation == 'required') {
                    udf_data[v.index].field_is_required = true;
                  }
                }
              }
            );
          }
        }
      }
      bh.input.udf_data = JSON.parse(
        JSON.stringify(
          udf_data.filter(v => {
            return !v.___markedForHidden__;
          })
        )
      );
      //appendnew_next_sd_yWvcL35oN37ptyvu
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_yWvcL35oN37ptyvu');
    }
  }

  async sd_EjbTtfexvcgCjxku(bh) {
    try {
      const udf_data = bh.input.udf_data;
      const deps = {};
      for (let i = 0; i < udf_data.length; i++) {
        if (udf_data[i].dependency_field_name) {
          const dfn = udf_data[i].dependency_field_name;
          const dfnc = udf_data[i].dependency_comparator;
          const dfnv = udf_data[i].dependency_field_value;
          const dfno = udf_data[i].update_by_dependency_as; // hidden
          if (!deps[dfn]) {
            deps[dfn] = {};
          }
          if (!(deps[dfn][dfnc] instanceof Array)) {
            deps[dfn][dfnc] = [];
          }

          deps[dfn][dfnc].push({
            field: udf_data[i].field_name,
            dependency_value: dfnv,
            dependency_operation: dfno,
            index: i
          });
        }
      }

      bh.local.depsList = deps;
      //appendnew_next_sd_EjbTtfexvcgCjxku
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EjbTtfexvcgCjxku');
    }
  }

  async sd_hcoa2NrTkItQ2X1y(bh) {
    try {
      const udf_data = bh.input.udf_data;
      const sections = [];
      if (udf_data instanceof Array) {
        for (let i = 0; i < udf_data.length; i++) {
          const udf = udf_data[i];
          const sectionIndex = sections.findIndex(section => {
            return section.title === udf.field_section;
          });

          if (sectionIndex == -1) {
            if (udf.field_section) {
              if (udf.field_ui_type == 'contactsearch' && udf.field_value) {
                udf.display_text = (await this.changeValueBasedOnType(
                  udf.field_ui_type,
                  udf.field_value
                )).local.value.data.display_name;
              }
              if (udf.field_ui_type == 'money' && udf.field_value) {
                udf.display_text =
                  (await this.changeValueBasedOnType(
                    udf.field_ui_type,
                    udf.field_currency_code_value
                  )).local.value +
                  ' ' +
                  udf.field_value;
              }
              if (udf.field_ui_type == 'phonenumber' && udf.field_value) {
                udf.display_text =
                  udf.field_country_code_value + ' ' + udf.field_value;
              }
              sections.push({
                title: udf.field_section,
                rows: [
                  {
                    title: udf.field_placeholder,
                    value: udf.field_value,
                    type: udf.field_ui_type,
                    display_text: udf.display_text
                  }
                ]
              });
            }
          } else {
            if (udf.field_ui_type == 'contactsearch' && udf.field_value) {
              udf.display_text = (await this.changeValueBasedOnType(
                udf.field_ui_type,
                udf.field_value
              )).local.value.data.display_name;
            }
            if (udf.field_ui_type == 'money' && udf.field_value) {
              udf.display_text =
                (await this.changeValueBasedOnType(
                  udf.field_ui_type,
                  udf.field_currency_code_value
                )).local.value +
                ' ' +
                udf.field_value;
            }
            if (udf.field_ui_type == 'phonenumber' && udf.field_value) {
              udf.display_text =
                udf.field_country_code_value + ' ' + udf.field_value;
            }
            sections[sectionIndex].rows.push({
              title: udf.field_placeholder,
              value: udf.field_value,
              type: udf.field_ui_type,
              display_text: udf.display_text
            });
          }
        }
      }

      bh.local.summaryConfig = {
        title: bh.input.title,
        sections: sections,
        showEditButton: bh.input.showEditButton
      };
      //appendnew_next_sd_hcoa2NrTkItQ2X1y
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_hcoa2NrTkItQ2X1y');
    }
  }

  async sd_9PJPEijEFqe9SrJw(bh) {
    try {
      const udf_data = bh.input.udf_data;
      const defaultSection = { 'Basic Details': true };
      let sections = {};
      if (udf_data instanceof Array) {
        for (let i = 0; i < udf_data.length; i++) {
          const udf = udf_data[i];
          if (udf.field_section) {
            if (!sections[udf.field_section]) {
              sections[udf.field_section] = {
                field_section: udf.field_section,
                field_section_name: udf.field_section_name,
                fields: [udf]
              };
            } else {
              sections[udf.field_section].fields.push(udf);
            }
            if (defaultSection[udf.field_section]) {
              sections[udf.field_section].default = true;
            }
          }
        }
      }

      bh.local.dynFormSections = Object.values(sections);
      //appendnew_next_sd_9PJPEijEFqe9SrJw
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_9PJPEijEFqe9SrJw');
    }
  }

  async sd_3gZ197O0PdNkDhXX(bh) {
    try {
      if (
        this.sdService.operators['eq'](
          bh.input.ui_type,
          'contactsearch',
          undefined,
          undefined
        )
      ) {
        bh = await this.sd_x2KKz5cZbpXiHoCY(bh);
      } else if (
        this.sdService.operators['eq'](
          bh.input.ui_type,
          'money',
          undefined,
          undefined
        )
      ) {
        bh = await this.getCurrencySymbol(bh);
      }

      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3gZ197O0PdNkDhXX');
    }
  }

  async sd_x2KKz5cZbpXiHoCY(bh) {
    try {
      let outputVariables = await this.contactservice.getContactById(
        bh.input.value
      );
      bh.local.value = outputVariables.local.result;

      //appendnew_next_sd_x2KKz5cZbpXiHoCY
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_x2KKz5cZbpXiHoCY');
    }
  }

  async getCurrencySymbol(bh) {
    try {
      let outputVariables = await this.srmappservice.getCurrencySymbol(
        bh.input.value
      );
      bh.local.value = outputVariables.local.currencySymbol;

      //appendnew_next_getCurrencySymbol
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_9xJDfnqNKMfQbCLS');
    }
  }

  async contructGetUdfsUrl(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `user-defined-fields/all/${bh.input.modulename}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_WLLlmPV8ouxmIGOg(bh);
      //appendnew_next_contructGetUdfsUrl
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_vGdb7DW72luYIgT9');
    }
  }

  async sd_WLLlmPV8ouxmIGOg(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      bh = await this.callCreateUdfSections(bh);
      //appendnew_next_sd_WLLlmPV8ouxmIGOg
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_WLLlmPV8ouxmIGOg');
    }
  }

  async callCreateUdfSections(bh) {
    try {
      let outputVariables = await this.createDynFormSections(bh.local.res.data);
      bh.local.udfs = outputVariables.local.dynFormSections;

      //appendnew_next_callCreateUdfSections
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_w3TSFivfIWWzNHSR');
    }
  }

  async contructPostUdfsUrl(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `user-defined-fields/${bh.input.modulename}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_dsnr4qfzYdyvbe0v(bh);
      //appendnew_next_contructPostUdfsUrl
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_4VhjH3d66vDrfafE');
    }
  }

  async sd_dsnr4qfzYdyvbe0v(bh) {
    try {
      bh.local.body = {
        udf_metadata: bh.input.udf_metadata
      };
      bh = await this.sd_ViOL09V78zORQdsh(bh);
      //appendnew_next_sd_dsnr4qfzYdyvbe0v
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_dsnr4qfzYdyvbe0v');
    }
  }

  async sd_ViOL09V78zORQdsh(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.local.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_ViOL09V78zORQdsh
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ViOL09V78zORQdsh');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_udfservice_Catch
}
