/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cacheservice } from 'app/sd-services/cacheservice';
import { feedbackservice } from 'app/sd-services/feedbackservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { CallFeedbackOutcome } from '../activities_callsComponent/activities_calls.component';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-feedback_form_call',
	templateUrl: './feedback_form_call.template.html'
})

export class feedback_form_callComponent extends NBaseComponent implements OnInit, OnDestroy {

    private _baseUrl = '/home/';

	feedbackObject: any;
	outcomes: CallFeedbackOutcome[] = [];
    relatedDealLabel = '';
    contactLable = 'Related Contact';
    conUrl = '';
    dealUrl = '';

	feedbackFormGroup = new FormGroup({
		callfeedbackid: new FormControl('', Validators.required),
		feedback_notes: new FormControl(''),
	});

	formKey = 'FEEDBACK_FORM_CALL_ACTIVITY';

	constructor(
		private pubsub: NPubSubService,
		private snackBar: MatSnackBar,
        private label: labelService,
		private feedbackService: feedbackservice,
		private _caches: cacheservice
	) {
		super();
	}

	ngOnInit() {
        this.relatedDealLabel = `Related ${this.label.deal}`;
		this._getCachedData().then(([{ local: { data: outcomes } }, { local: { data: feedback } } ]) => {
			this.feedbackObject = feedback;
			this.outcomes = outcomes;
			if (this.feedbackObject && Object.keys(this.feedbackObject).length) {
                this.conUrl = this._baseUrl + 'contacts/' + this.feedbackObject.contactid;
                this.dealUrl = this._baseUrl + 'deals/' + this.feedbackObject.dealid + '/contact/' + this.feedbackObject.contactid;
                this.feedbackFormGroup.patchValue(this.feedbackObject);
			}
		})
	}

	private _getCachedData() {
		return Promise.all([
			this._caches.getc('CALL_FEEDBACK_OUTCOMES'),
			this._caches.getc(this.formKey),
		])
	}

	close() {
		this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
	}

	cancel() {
		this.close();
	}

	async update() {
		if (!this.feedbackFormGroup.invalid) {
			const body = {
				feedback_notes: this.feedbackFormGroup.get('feedback_notes').value || '',
				feedbackid: this.feedbackFormGroup.get('callfeedbackid').value
			}
			try {
				const result = await this.feedbackService.upateFeedback(body, 'call', this.feedbackObject.activitycallid, this.feedbackObject.contactid);
				this.notify({ data: body }, 'Call log was succesfully updated.');
			} catch (e) {
				this.notify({ err: e }, 'Error occured when trying to update call log.');
			}
		} else {
			this.notify({ err: true }, 'Outcome is required.');
		}

	}

	notify({ data, err }: { data?: any, err?: any }, message: string) {
		const sbOpts = { duration: 3000 };
		if (data) {
			this._caches.updatec({
				callfeedbackid: data.feedbackid,
				feedback_notes: data.feedback_notes
			}, this.formKey)
		this.pubsub.$pub('getFeedbackevents');
			this.snackBar.open(message, 'OK', sbOpts);
			this.close();
		} else if (err) {
			this.snackBar.open(message, 'OK', sbOpts);
		}
	}

	private async _clearCache() {
		await this._caches.deletec('FEEDBACK_FORM_CALL_ACTIVITY')
	}

	async ngOnDestroy() {
		this.feedbackObject = null;
		this.outcomes = null;
		await this._clearCache();
	}
}
