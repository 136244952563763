<div fxLayout="column " class="height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="sample-csv" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div>
        Import your contacts using <a class="no-focus-box" [href]="sampleDownloadLink" download="sample.csv"><span class="text-style-1">sample CSV.</span></a></div>
    </div>
    <div fxLayout="column " class="upload-your-file-here" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" *ngIf="showPreFileImport" appFileDragDrop (fileDropped)="onFileDropped($event)"><img imgSrc="/Web/Icons/import_contact_file.svg">
      <p class="file-upload-button top-pad-20 text-center">
        <label for="fileDropRef">Upload your file here</label><input type="file" #fileDropRef id="fileDropRef" accept=".csv" (change)="onFileDropped($event.target.files)"></p>
      <div class="text-center">
        <p class="top-pad-20 or-text"><span>or</span></p>
      </div>
      <p class="text-center">
        Drag your file</p>
      <p class="formats-supported top-pad-20 text-center">
        (.csv formats supported)</p>
    </div>
    <div fxLayout="column " class="upload-your-file-here" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="showPostFileImport"><img imgSrc="/Web/Icons/import_contact_file.svg">
      <p class="text-center">
        {{filename}}</p>
      <p class="file-upload-button top-pad-20 text-center">
        <span (click)="removeFile()">Remove</span></p>
    </div>
    <div>
      <mat-error class="error left-pad-20" align>{{formErrorMessage}}</mat-error>
    </div>
    <div fxLayout="row " class="notice" fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false"><img imgSrc="/Web/Icons/exclamation.svg" class="exclamation-icon">
      <div class="text-content">
        <p>On click of import button, selected contact CSV file will be validated by the system.
          The status of the transaction will be updated from Validation In-progress to Pending
          assignment / Invalid file / Import failed in the import contact history page.</p>
      </div>
    </div>
    <div fxLayout="column wrap" style="order: -1;" class="lr-padding add-contact-title sticky white-back add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="70" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/add_import_contacts_icon.svg">
            <h2>
              IMPORT CONTACTS</h2>
          </div>
        </div>
        <div fxLayout="row wrap" fxFlex="30" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
            <mat-icon (click)="close()" tabindex="0">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row " class="lr-padding pad-bottom" fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="uploadFile()" [disabled]="disableImportButton">Import</button></div>
    <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-action-button" (click)="cancel()" type="button">Cancel</button></div>
  </div>
</div>