/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, Validators as V } from '@angular/forms';
import { CacheKey, Page, PubSubChannel as PSChnnl } from 'app/constants';
import { udfsyncService } from 'app/services/udfsync/udfsync.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { UDFConfig, UDFSectionFieldChangedEventData } from '../udf_fieldComponent/udf_field.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-udf_section',
	templateUrl: './udf_section.template.html'
})

export class udf_sectionComponent extends NBaseComponent implements OnInit, OnDestroy, AfterViewInit {
	sortHandle = '/assets/Web/Icons/drag-handle.svg';

	@Input('udfSection') udfSectionConfig: UDFSectionConfig;
	@Input('movesUp') upIcn = true;
	@Input('movesDown') downIcn = true;
	@Input('deletable') delIcn = true;
	@Input('editable') editIcn = true;
	@Input('position') i: number;
	@Input('disableActions') disableActions = false;

	@Output('moveUp') moveUp = new EventEmitter<UDFSectionEmitData>();
	@Output('moveDown') moveDown = new EventEmitter<UDFSectionEmitData>();
	@Output('sectionDelete') sectionDelete = new EventEmitter<UDFSectionEmitData>();
	@Output('nameChange') nameChange = new EventEmitter<UDFSectionEmitData>();
	@Output('sectionCompViewInit') sectionAdded = new EventEmitter<UDFSectionEmitData>();

	@Output('fieldChanged') fieldChanged = new EventEmitter<UDFSectionFieldChangedEventData>();

	duplicate = (control: AbstractControl): { duplicate: boolean } | null => {
		const farray = this._udfSync.getc(CacheKey.udfSectionNames);
		for (let i = 0; i < farray.length; i++) {
			if (farray[i] === control.value && i !== this.i) {
				return { duplicate: true }
			}
		}
		return null;
	}

	editNameFc = new FormControl('', V.compose([V.required, this.duplicate]));
	editing = false;
	adding: boolean = false;
	private _validateSub: Subscription;

	constructor(private _ps: NPubSubService,
		private _udfSync: udfsyncService,
		private host: ElementRef) {
		super();
	}

	ngOnInit() {
		this._validateSub = this._ps.$sub(PSChnnl.udfSectionValidateName, (validate = true) => {
			if (validate) {
				this.editNameFc.setValue(this.editNameFc.value);
			}
		})
		if (!this.udfSectionConfig.field_section) {
			this.adding = true;
		}
	}

	ngAfterViewInit() {
		if (this.adding) {
			this.emitSectionAdded();
		}
	}

	get emitData(): UDFSectionEmitData {
		return ({
			data: {
				adding: this.adding,
				editing: this.editing,
				hostDOM: this.host.nativeElement
			},
			section: this.udfSectionConfig
		});
	}

	emitMoveUp() {
		if (!this.disableActions)
			this.moveUp.next(this.emitData);
	}

	emitMoveDown() {
		if (!this.disableActions)
			this.moveDown.next(this.emitData);
	}

	emitNameChange() {
		if (!this.disableActions)
			this.nameChange.next(this.emitData);
	}

	emitDeleteSection() {
		if (!this.disableActions)
			this.sectionDelete.next(this.emitData)
	}

	emitFieldChanged(ufsce: UDFSectionFieldChangedEventData) {
		if (!this.disableActions)
			this.fieldChanged.next(ufsce);
	}

	emitSectionAdded() {
		if (!this.disableActions)
			this.sectionAdded.next(this.emitData)
	}

	updateNameChange() {
		if (!this.disableActions) {
			this.udfSectionConfig.field_section = this.editNameFc.value;
			this.udfSectionConfig.fields?.forEach(u => {
				u.field_section = this.editNameFc.value;
			});
			this.exitEditingMode();
			this.emitNameChange();
		}
	}

	enterEditMode() {
		if (!this.disableActions) {
			this.editing = true;
			this.editNameFc.setValue(this.udfSectionConfig.field_section);
		}
	}

	exitEditingMode(cancelling = false) {
		this.editing = false;
		if (this.adding && cancelling) {
			this.emitDeleteSection();
		} else {
			this.adding = false;
		}
	}

	openAddFieldForm() {
		this._udfSync.setc(this.udfSectionConfig, CacheKey.udfAddFieldFormSectionInfo);
		this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
			ACTION: 'open',
			PAGE: Page.UDFForm
		})
	}

	updateFields(usfce: UDFSectionFieldChangedEventData) {
		this.emitFieldChanged(usfce);
	}

	get disableLooking() {
		return {
			'disabled-appearance': this.disableActions,
			'cursor-pointer': !this.disableActions
		}
	}

	ngOnDestroy() {
		this._validateSub.unsubscribe();
		this._udfSync.deletec(CacheKey.udfAddFieldFormSectionInfo);
	}

}

export interface UDFSectionEmitData {
	data: {
		editing: boolean,
		adding: boolean,
		hostDOM: HTMLElement
	},
	section: UDFSectionConfig
}

export interface UDFSectionConfig {
	field_section: string,
	fields: UDFConfig[]
}

