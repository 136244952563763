/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

import { utilservice } from './utilservice'; //_splitter_
//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class permissionservice {
  constructor(
    private utilservice: utilservice,
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_permissionservice

  async getPermissionGroups(...others) {
    try {
      var bh = {
        input: {},
        local: {
          res: undefined,
          url: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.url(bh);
      //appendnew_next_getPermissionGroups
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_z7I44ANB4CXdMn9l');
    }
  }

  //appendnew_flow_permissionservice_Start

  async url(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `permissions/group`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.callApi(bh);
      //appendnew_next_url
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1ilRJDLro3wRjc7k');
    }
  }

  async callApi(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_callApi
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_QawWfYu8SLDv8Hzi');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_permissionservice_Catch
}
