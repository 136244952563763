<div fxLayout="column " class="padding-bottom-1 height-100 overflow-y" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row wrap" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true" [ngStyle.xs]="&apos;height: 50px&apos;">
    <div fxLayout="row wrap" fxFlex="15" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button style="  margin-top: 0.4em; margin-right: 1em;" class="action-button" matTooltip="Add Document" *ngIf="!this.accessDenied" (click)="add_document()">
        <div class="add-notes-image"></div>
      </button></div>
  </div>
  <div fxLayout="column " class="padding-1-0" fxLayoutGap="8px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="!documentsArr.length" [ngClass.gt-xs]="&apos;height-100&apos;">
    <div class="no-documents-image">
    </div>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <h3 [ngClass]="(!progressCheck) ? &apos;icon-disable&apos; : &apos;&apos; " class="light-gray-color">
        No Documents Available</h3>
    </div>
  </div>
  <div fxLayout="row wrap" class="padding-0-1" fxFlex="100" fxLayoutAlign="space-between start" [fxShow]="true" [fxHide]="false" *ngIf="documentsArr.length" [ngStyle.xs]="&apos;height: calc(100% - 60px)&apos;" [ngClass.xs]="&apos;width-100 overflow-y&apos;">
    <div fxLayout="row " class="cursor-pointer margin-top-1 notes-list-gt-xs" fxLayoutGap="8px" fxLayoutAlign="start space-between" [fxShow]="true" [fxHide]="false" *ngFor="let file of documentsArr;let i = index" (click)="editDocument(file)" [ngStyle.gt-xs]="&apos;width: 49%&apos;" [ngStyle.xs]="&apos;width: 100%&apos;">
      <div fxLayout="row wrap" class="touch-scroll display-grid primary-font-color" fxFlex="70" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" matTooltip="{{file.file_name}}" [fxFlex.sm]="60">
        <div class="text-ellipsis padding-0-1">
          {{file.file_name}}</div>
      </div>
      <div fxLayout="row " class="lr-padding leads-height-gt-xs" fxFlex="30" fxLayoutGap="20px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="40">
        <div fxLayout="column wrap" class="touch-scroll height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation matTooltip="{{getMoreInformation(file)}} {{file?.created_at | date: &apos;dd&apos;}}-{{file?.created_at | date:&apos;MM&apos;}}-{{file?.created_at | date: &apos;yyyy&apos;}}, {{file?.created_at | date: &apos;HH:mm&apos;}}" matTooltipClass="document-tooltip" matTooltipPosition="above" (click)="tooltipToggle(i)" #tooltip="matTooltip"><img imgSrc="Web/Icons/document_info.svg"></div>
        <div fxLayout="column wrap" class="touch-scroll height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation matTooltip="Download Document" (click)="downloadDocument(file.activityfileid)" *ngIf="!accessDenied"><img imgSrc="Web/Icons/document_download.svg"></div>
        <div fxLayout="column wrap" class="touch-scroll height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation matTooltip="Delete Document" (click)="deleteDocument(file)" *ngIf="!accessDenied"><img imgSrc="Web/Icons/document_delete.svg"></div>
      </div>
    </div>
  </div>
</div>