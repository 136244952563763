<form autocomplete="off" class="height-100">
  <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill>
    <div fxLayout="column wrap" class="white-back add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="border-bottom lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/editevent.svg">
            <h2>
              EDIT {{label.deal | uppercase}}</h2>
          </div>
        </div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
          <mat-icon class="close-button" (click)="close()">close</mat-icon>
        </div>
      </div>
    </div>
    <div fxLayout="column wrap" class="overflow-y" fxFlex="calc( 100% - 128px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="dealform">
      <bh-dynform [udf_for_view]="dealform" (changeEvent)="refreshForm($event)"></bh-dynform>
    </div>
    <div fxLayout="column wrap" class="add-lead-content" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="update()">Update</button><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
    </div>
  </div>
</form>