<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" style="order: -1;" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/dollar_black.svg" class="add-contact-icon">
          <h2>
            CONFIGURE DEFAULT CURRENCY</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="grey-title" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          Choose the default currency for your organisation</div>
      </div>
      <form NgForm fxLayout="column" fxFlex="calc(100% - 64px)" class="add-lead-content">
        <div>
          <label class="phone-number-label">
            <div class="label-phone-code"><span class="place-holder-paddingr">
        Currency Type
    </span>

              <span class="info-icon" matTooltip="Search example: Singapore Dollar (S$)" matTooltipPosition="right"><img imgSrc="Web/Icons/exclamationgrey.svg">
    <!-- <span class="tooltip">Search example: Singapore (+65)</span> -->
              </span>


            </div>

          </label>

        </div>
        <bh-dyncurrencytypeauto [currencyCodeformControl]="currencyCode"></bh-dyncurrencytypeauto>
      </form>
    </div>
    <div fxLayout="column wrap" class="add-lead-content lr-padding buttons-pad" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="saveCurrencyValue()" [disabled]="disableAddButton">Save</button></div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </div>
</div>