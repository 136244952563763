import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { appDeclarations, appBootstrap, appProviders, appEntryComponents } from './config/declarations';
import { appImportModules } from './config/import-modules';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
@NgModule({
  declarations: [...appDeclarations],
  imports: [...appImportModules,
    CKEditorModule,    
    CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  })],
  providers: [...appProviders],
  entryComponents: [...appEntryComponents],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [...appBootstrap]
})
export class AppModule { }
