/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { taskservice } from 'app/sd-services/taskservice';
import { cacheservice } from 'app/sd-services/cacheservice';
import { labelService } from 'app/services/label/label.service';
import { userservice } from 'app/sd-services/userservice';
import { Moment } from 'moment';
import * as _moment from 'moment';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-activities_tasks',
    templateUrl: './activities_tasks.template.html'
})

export class activities_tasksComponent extends NBaseComponent implements OnInit {
    @Input('activity') activity;
    dealNameLabel = '';

    constructor(private pubsub: NPubSubService,
        private taskS: taskservice,
        private cacheService: cacheservice,
        private label: labelService,
        public userservice: userservice) {
        super();
    }

    ngOnInit() {
        this.dealNameLabel = `${this.label.deal} Name`;
    }

    editTask(value) {
		// TODO: No need to construct this object. Directly send the `deal` for the form
        if (value['activitytaskid']) {
            var tempObj = {
                title: value['task_title'],
                description: value['task_description'],
                duedate: new Date(value['due_date']),
                duetime: this.getTime(new Date(value['due_date'])),
                completed: (value['task_status'] == true) ? 'Yes' : 'No',
            }
            let taskIdObj = {};
            taskIdObj['contactid'] = value['contactid'];
            taskIdObj['dealid'] = value['dealid'];
            taskIdObj['activitytaskid'] = value['activitytaskid'];
            let taskObj = {
                taskEventObj: tempObj,
                taskIdObj: value
            };
            this.cacheService.setc(taskObj, 'EDIT_TASK_FORM');
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'ADD_TASK'
            })
        }
    }
    hourwithAMPM(inputDate: Moment) {
        let d = _moment();
        if (inputDate) {
            d = _moment(inputDate);
        }
        let ampm = (d.hour() >= 12) ? "pm" : "am";
        let hours = (d.hour() > 12) ? d.hour() - 12 : d.hour();
        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.minute() < 10 ? '0' + d.minute() : d.minute()) + ampm;
    }
    getTime(date: Date) {
        return this.hourwithAMPM(_moment(date));
    }

}
