/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Router } from '@angular/router';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-linkfield',
    templateUrl: './linkfield.template.html'
})

export class linkfieldComponent extends NBaseComponent implements OnInit {

    @Input('text') text = '';
    @Input('label') label = '';
    @Input('url') url = '';

    @Output('navigationInit') navigationInit = new EventEmitter<{url: string}>();
	
    constructor(private router: Router) {
        super();
    }

    ngOnInit() {

    }

    navigateToContactDetails($event) {
        this.navigationInit.next({
            url: this.url
        })
        this.router.navigate([this.url]);
    }
}
