/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { emailService } from 'app/services/email/email.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-emailist',
    templateUrl: './emailist.template.html'
})

export class emailistComponent extends NBaseComponent implements OnInit {
    @Input('emails') emails: any;
    constructor(
        private pubsub: NPubSubService,
        private emailservice: emailService
    ) {
        super();
    }

    ngOnInit() {
    }
    downLoadFile(item) {
    }
    openEmail(item) {
        this.pubsub.$pub('sidenavformevents', {
            'ACTION': 'open',
            PAGE: 'EMAIL_TRAIL',
            DATA: item
        })
    }

    changeTime(date) {
        let currentDate = new Date();
        let emailDate = new Date(date)
        if ((currentDate.getDate() - emailDate.getDate()) < 1) {
            return true
        } else {
            // return months[currentDate.getMonth()] + " " + currentDate.getDate()
            return false;
        }

    }

}
