<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <bh-sidenavformheader [title]="title" [titleIconUrl]="&apos;assets/Web/Icons/udf_add_field_form_title.svg&apos;" (closeClicked)="close()"></bh-sidenavformheader>
  <form style="height: calc(100% - 70px);" (ngSubmit)="add()">
    <div fxLayout="column " class="height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" fxFill="fxFill">
      <bh-formsectiontitle [sectionName]="&apos;Configure field validations based on selected field type&apos;"></bh-formsectiontitle>
      <div fxLayout="column " class="height-100 overflow-y-only lr-padding" fxLayoutGap="0.25rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " class="form-mat-form-field-cont" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" id="form-add-user_select-role">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
            <mat-select placeholder="Section" [required]="true" [formControl]="formGroup.controls.field_section" [readonly]="mode === &apos;EDIT&apos;">
              <ng-template ngFor let-option [ngForOf]="field_sections" let-i="index">
                <mat-option [value]="option">{{option }}</mat-option>
              </ng-template>
            </mat-select>

          </mat-form-field>
        </div>
        <div fxLayout="row " class="form-mat-form-field-cont" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" id="form-add-user_select-role">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
            <mat-select placeholder="Field type" [required]="true" [formControl]="formGroup.controls.field_ui_type">
              <ng-template ngFor let-option [ngForOf]="field_ui_types" let-i="index">
                <mat-option [value]="option">{{option | titlecase}}</mat-option>
              </ng-template>
            </mat-select>

          </mat-form-field>
        </div>
        <div fxLayout="row " class="form-mat-form-field-cont" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" id="form-add-user_select-role">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="Enter Field Label" [required]="true" [formControl]="formGroup.controls.field_placeholder">
            <mat-error align="start" *ngIf="formGroup.controls.field_placeholder?.errors?.duplicate">{{&apos;Field with this label already exists in &apos; + (formGroup.controls.field_section?.value || &apos;the selected&apos;) + &apos; section&apos;}}</mat-error>
            <mat-label>Field Label</mat-label>
          </mat-form-field>
        </div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <bh-phonecountrycodeselect [fxShow]="selectedType === &apos;phonenumber&apos;" [phoneCountryCodeFormControl]="formGroup.controls.field_country_code_value" [placeholder]="&apos;Default Dialing Code&apos;"></bh-phonecountrycodeselect>
          <bh-countryselect [fxShow]="selectedType === &apos;country&apos;" [countryFormControl]="formGroup.controls.field_country_value" [placeholder]="&apos;Default Country&apos;"></bh-countryselect>
          <bh-nationalityselect [fxShow]="selectedType === &apos;nationality&apos;" [nationalityFormControl]="formGroup.controls.field_nationality_value" [placeholder]="&apos;Default Nationality&apos;"></bh-nationalityselect>
          <bh-currencycodeselect [fxShow]="selectedType === &apos;money&apos;" [currencyCodeformControl]="formGroup.controls.field_currency_code_value" [placeholder]="&apos;Default Currency&apos;"></bh-currencycodeselect>
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="selectedType === &apos;select&apos;">
            <bh-optionsconfigurator class="margin-bottom-1" [optionsFormControl]="formGroup.controls.field_options" [header]="&apos;Dropdown Options&apos;" [optionPlaceholder]="&apos;Enter dropdown value&apos;" [initialOptions]="initialOpts" [setFCErrors]="setOptionsFCErrors"></bh-optionsconfigurator>
            <div fxLayout="row wrap" class="form-mat-form-field-cont" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
              <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
                <mat-select placeholder="Default Option" [required]="false" [formControl]="formGroup.controls.field_options_value">
                  <ng-template ngFor let-option [ngForOf]="optValues" let-i="index">
                    <mat-option [value]="option.hasOwnProperty(&apos;value&apos;)?option.value:option">{{option.display || option}}</mat-option>
                  </ng-template>
                </mat-select>

              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row " class="form-mat-form-field-cont" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" id="form-add-user_select-role">
          <mat-checkbox labelPosition="after" [required]="false" [formControl]="formGroup.controls.field_is_required">Make this a mandatory field</mat-checkbox>
        </div>
      </div>
      <div fxLayout="row wrap" class="tb-padding lr-padding" fxLayoutGap="1rem" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="primary-form-action-button" *ngIf=" mode === &apos;EDIT&apos;" [disabled]="disableSubmit" type="submit">Update</button><button mat-button class="primary-form-action-button" *ngIf=" mode === &apos;ADD&apos;" [disabled]="disableSubmit" type="submit">Add</button></div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-form-action-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>