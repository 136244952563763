/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { MatDialogRef } from '@angular/material/dialog';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-importcanceldialog',
    templateUrl: './importcanceldialog.template.html'
})

export class importcanceldialogComponent extends NBaseComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<any>) {
        super();
    }

    ngOnInit() {

    }

    cancel() {
        this.dialogRef.close(false);
    }

    yes() {
        this.dialogRef.close(true);
    }
}
