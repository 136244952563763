<div fxLayout="column " class="border-radius summary card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill *ngIf="config &amp;&amp; config.title &amp;&amp; config.sections">
  <mat-expansion-panel #panel class="mobile-tap-view height-100 border-top-align" (opened)="toggleChange(&apos;open&apos;)" (closed)="toggleChange(&apos;close&apos;)" [expanded]="true">
    <mat-expansion-panel-header>

      <mat-panel-title>

        <div fxLayout="row wrap" style="padding-left: 0px !important;" class="lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" fxFlex="55" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <h3 class="font-weight-size">
              {{config.title | uppercase}}</h3>
          </div>
          <div fxLayout="column wrap" fxFlex="45" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="panel?.expanded ? true : false">
            <div fxLayout="row " fxLayoutGap="18px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
              <div fxLayout="row wrap" fxFlex="57px" fxLayoutGap="1em" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
                <div (click)="previousPage()" *ngIf="showActionButtons" click-stop-propagation class="left-arrow-image cursor-pointer">
                </div>
                <div (click)="nextPage()" *ngIf="showActionButtons" click-stop-propagation class="right-arrow-image cursor-pointer">
                </div>
              </div><button mat-button class="action-button" matTooltip="Edit" *ngIf="config.showEditButton" click-stop-propagation [ngClass.gt-xs]="&apos;display-none&apos;" (click)="editView()">
                <div class="edit-image"></div>
              </button><button mat-button class="action-button" matTooltip="Edit" *ngIf="config.showEditButton" click-stop-propagation [ngClass.xs]="&apos;display-none&apos;" (click)="edit()">
                <div class="edit-image"></div>
              </button>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="row wrap" class="circle-padding lr-padding tb-padding" fxFlex="calc(100% - 57px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" class="summary-item height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let section of displaySections" fxFlex.lg="33.3" fxFlex.md="49.5" fxFlex.sm="49.5" fxFlex.xs="100">
        <bh-summaryinfocard class="width-100 height-100" [config]="section"></bh-summaryinfocard>
      </div>
    </div>
  </mat-expansion-panel>
</div>