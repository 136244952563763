/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
interface Row {
    title: string;
    value: string;
}

interface Section {
    title: string;
    rows: Row[];
}
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-summaryinfocard',
    templateUrl: './summaryinfocard.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./summaryinfocard.style.scss']
})

export class summaryinfocardComponent extends NBaseComponent implements OnInit {
    @Input('config') config = {}; // interface Section
    constructor() {
        super();
    }

    ngOnInit() {

    }
}
