<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill id="reassign-sidenav-form">
  <div fxLayout="column wrap" class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div class="reassign-form-icon">
          </div>
          <h2>
            RE-ASSIGN CONTACT</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="form-sections-title" fxFlex="44px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <h3>
      Select user to re-assign contacts</h3>
  </div>
  <form NgForm [formGroup]="reassignFormGroup" fxLayout="column" fxFlex="calc(100% - 108px)" class="add-lead-content" (ngSubmit)="add()">
    <div fxLayout="column wrap" fxFlex="calc(100%-px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
        <input matInput placeholder="Total contacts selected for re-assignment" [required]="true" trim="blur" [formControl]="reassignFormGroup.controls?.contactcount">

      </mat-form-field>
      <bh-dynusersearch [placeholder]="&apos;Search and select from available users&apos;" (resultSelected)="selected($event)" [inputFormControl]="reassignFormGroup.controls?.ownerid" [name]="&apos;usersearch&apos;" [filterWith]="filter" #usersearch="dynUserSearch" [label]="&apos;User&apos;"></bh-dynusersearch>
      <mat-error align *ngIf="isReassignFormInvalid">Select valid user</mat-error>
      <div fxLayout="row " fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false" id="form-add-user_select-role"><img imgSrc="Web/Icons/reassign_note.svg" class="add-contact-icon"><span class="reassign-form-note">
                Note: For all re-assigned contacts,<br> 
1. New user will be the owner of the contact and associated transactions.<br> 
2. Any active tasks will be deleted.<br> 
3. Any scheduled appointments will be cancelled.<br> </span></div>
    </div>
    <div fxLayout="column wrap" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" ngClass.xs="button-alignment">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" [disabled]="disableAddButton">Re-Assign</button></div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-action-button color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>