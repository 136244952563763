<div fxLayout="row wrap" style="height:60px" class="lr-padding border-radius card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
  <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="width-100" fxLayoutGap="10px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/lead_icon.svg">
      <h2 class="text-ellipsis">
        {{contact_name}}
        <mat-divider vertical="true"></mat-divider>
      </h2>
      <div fxLayout="row wrap" fxLayoutGap="0.1em" fxLayoutAlign="start space-between" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="0.1em" fxLayoutAlign="start space-between" [fxShow]="true" [fxHide]="false" *ngFor="let label of labels; let i = index">
          <div *ngIf="i &lt;= 2" class="contact-labels">
            {{label.label}}</div>
        </div>
        <div fxLayout="row wrap" class="cursor-pointer" fxLayoutAlign="start space-between" [fxShow]="true" [fxHide]="false" *ngIf="labels &amp;&amp; labels.length &gt; 3" matTooltip="{{labelsTooltip.join(&apos;, &apos;)}}" matTooltipClass="document-tooltip" matTooltipPosition="below" #tooltip="matTooltip"><span class="contacts-labels-more">
                + {{labels.length - 3}} more</span></div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxFlex="50" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <h3>
        Contact Owner: {{contact_owner}}</h3>
      <h3>
        Contact ID: {{contactid}}</h3>
    </div><button mat-button class="margin-left-1 action-button" matTooltip="Call" *ngIf="!accessDenied" (click)="initialCall()">
      <div class="call-image"></div>
    </button><button mat-button class="margin-left-1 action-button" matTooltip="Add Label" *ngIf="!accessDenied" (click)="addLabel()">
      <div class="label-image"></div>
    </button><button mat-button class="margin-left-1 action-button" matTooltip="Delete" *ngIf="(userservice?.user?.srmPermissions?.includes(&apos;CONTACT_DELETE&apos;) &amp;&amp; (userservice?.user?.srmUserid == contact_owner_id ||  (userservice?.user?.srmPermissions?.includes(&apos;CONTACT_ALL&apos;) || userservice?.user?.srmPermissions?.includes(&apos;GENERAL_ALL&apos;))))" (click)="deleteContact()">
      <div class="document_delete"></div>
    </button>
  </div>
</div>
<mat-menu #notesList class="mobile-notes" xPosition="after" #menu="matMenu"><button mat-menu-item (click)="notes()" class="color-black common-menu-item-align font-Weight-size font-Family"> <span><img class="common-add-list-images" imgSrc="/Web/Icons/add_deal_icon.svg">View Notes</span> </button></mat-menu>
<div fxLayout="row wrap" style="height:60px" class="lr-padding border-radius card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true" [ngStyle.xs]="&apos;min-height: 80px;&apos;">
  <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxLayoutGap="6px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/owner_icon.svg" style="height: 20px;" *ngIf="contact_name">
        <div fxLayout="column wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <h2 ngClass.xs="width200px" class="text-ellipsis">
              {{contact_name | uppercase}}</h2>
            <div fxLayout="row wrap" class="l-padding" fxLayoutGap="0.1em" fxLayoutAlign="start space-between" [fxShow]="true" [fxHide]="false">
              <div fxLayout="row wrap" fxLayoutAlign="start space-between" [fxShow]="true" [fxHide]="false" *ngFor="let label of labels; let i = index">
                <div *ngIf="i == 0" class="contact-labels">
                  {{label.label}}</div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start space-between" [fxShow]="true" [fxHide]="false" *ngIf="labels &amp;&amp; labels.length &gt; 1" matTooltip="{{labelsMobileTooltip.join(&apos;, &apos;)}}" matTooltipClass="document-tooltip" matTooltipPosition="below" (click)="tooltipToggle()" #tooltipMobile="matTooltip"><span class="contacts-labels-more">
                + {{labels.length - 1}} more</span></div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row " fxLayoutGap="10px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;border-right: 1px solid #dedede;padding-right: 10px;&apos;"><img imgSrc="Web/Icons/contact_icon.svg" *ngIf="contact_owner">
              <h5>
                {{contact_owner}}</h5>
            </div>
            <div fxLayout="row " fxLayoutGap="10px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/id_icon.svg" *ngIf="contactid">
              <h5>
                {{contactid}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="padding-1-0" fxFlex="20" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
    <mat-icon class="font-primary-color" [matMenuTriggerFor]="notesList">more_vert</mat-icon>
  </div>
</div>