/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { srmappservice } from 'app/sd-services/srmappservice';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { tenantservice } from 'app/sd-services/tenantservice';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dynphonecountrycode',
    templateUrl: './dynphonecountrycode.template.html'
})

export class dynphonecountrycodeComponent extends NBaseComponent implements OnInit {
    @Input('phoneCountryCodeControl') phoneCountryCodeControl = new FormControl();
    phoneCountryCodeDisplayControl: FormControl = new FormControl();
    phoneCountryCodes: Array<any> = [];
    focusedLabel = false;
    filteredPhoneCountryCodes: Observable<Array<{ country: string, phone_country_code: string }>>;
    displayAutoComplete = false;
    fc: any;
    placeholder = "";
    required = false;
    constructor(
        public srmappS: srmappservice,
        private tenantService: tenantservice
    ) {
        super();
    }

    ngOnInit() {
        this.srmappS.getPhoneCountryCodes()
            .then(result => {
                this.phoneCountryCodes = result.local.phonecountrycodes;
                this.filteredPhoneCountryCodes = of(result.local.phonecountrycodes);
                this.tenantService.getTenantSettings()
                    .then(result => {
                        if (!this.phoneCountryCodeControl.value && result.local.tenantSettings && result.local.tenantSettings.PHONE_COUNTRY_CODE) {
                            this.phoneCountryCodeControl.setValue(result.local.tenantSettings.PHONE_COUNTRY_CODE);
                            let cCodeObject = this.phoneCountryCodes.find((item) => item.phone_country_code === result.local.tenantSettings.PHONE_COUNTRY_CODE)
                            if (cCodeObject) {
                                this.phoneCountryCodeDisplayControl.setValue(
                                    this.displayFn(cCodeObject)
                                );
                            }

                        } else {
                            console.log('No default country code set for the tenant');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(e => {
            });
        this.phoneCountryCodeDisplayControl.valueChanges.subscribe((searchInput: string) => {
            if (searchInput.length > 0) {
                this.filteredPhoneCountryCodes = of(this.phoneCountryCodes.filter(
                    (item) => item.phone_country_code.startsWith(searchInput) ||
                        item.country.toLowerCase().startsWith(searchInput.toLowerCase())
                ));
            } else {
                this.filteredPhoneCountryCodes = of(this.phoneCountryCodes);
            }
        });

    }
    selectedOption(selectedValue) {
        this.phoneCountryCodeControl.setValue(selectedValue);
    }
    displayFn(selectedValue): string {
        if (!selectedValue
            ) {
            return '';
        }
        if(typeof(selectedValue) === 'string'){
            return selectedValue as string ;
        }
        return selectedValue.country + ' ' + selectedValue.phone_country_code;
    }
    filter(v: unknown): any {
        throw new Error("Method not implemented.");
    }
}
