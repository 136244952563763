<div fxLayout="row wrap" style="height: 10rem;" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="!config?.length; else fields;">
  <div style="margin: auto;" class="label-font-color">
    Configure new section by adding a unique section name</div>
</div>
<ng-template #fields>
  <div fxLayout="column " class="border-radius card-like udf-fields-container drag-list" fxLayoutGap="0.3rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" cdkDropList (cdkDropListDropped)="udfDrop($event)" [cdkDropListData]="config">
    <div fxLayout="row " class="drag-box udf-field-item" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false" *ngFor="let udf of config; let i =index;" cdkDrag [cdkDragData]="udf" cdkDragBoundary=".udf-fields-container" [cdkDragDisabled]="udf.default || disableActions" (click)="openUDFEditForm(udf)" [ngClass]="disableLooking">
      <div fxLayout="row " class="drag-box-content-container" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " class="white-back drag-box-content" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" class="udf-drag-handle" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" cdkDragHandle *ngIf="!udf.default" [ngClass]="dragHandleDisableLooking"><img imgSrc="/Web/Icons/drag-handle.svg"></div>
          <div class="font-primary-color">
            {{udf.field_placeholder}}</div>
        </div>
        <div fxLayout="row wrap" class="udf-field-item-delete white-back" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" (click)="deleteField(udf, i, $event)" *ngIf="!udf.default" [ngClass]="disableLooking" matTooltip="Delete Field"><img imgSrc="/Web/Icons/udf-field-delete.svg"></div>
      </div>
    </div>
  </div>
</ng-template>