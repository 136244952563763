/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, ViewChild } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { dynformComponent } from '../dynformComponent/dynform.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { contactservice } from 'app/sd-services/contactservice';
import { udfservice } from 'app/sd-services/udfservice';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-editcontact',
    templateUrl: './editcontact.template.html'
})

export class editcontactComponent extends NBaseComponent implements OnInit {
    contactform: any;
    contactid;
    all_udfs: any[] = [];
    @ViewChild(dynformComponent) dnfc: dynformComponent;
    constructor(private cacheService: cacheservice,
        private pubsub: NPubSubService,
        private snackbar: MatSnackBar,
        private contactService: contactservice,
        private udfService: udfservice) {
        super();
    }

    ngOnInit() {
        this.cacheService.getc('EDIT_CONTACT_FORM')
            .then((result: {
                local: {
                    data: {
                        contactid: any,
                        sections: any[],
                        all_udfs: any[]
                    }
                }
            }) => {
                this.contactform = result.local.data.sections;
                this.contactid = result.local.data.contactid;
                this.all_udfs = result.local.data.all_udfs;
            })
            .catch(e => {
                console.log(e);
            })
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' })
    }

	refreshForm($event: {
		field_name: string,
		value: any
	}) {
		// if (this.dnfc.dynamicFormGroup.valid) {

		// change detection gets fired before the form value changes
		const udfkv = this.dnfc.dynamicFormGroup.value;
		udfkv[$event.field_name] = $event.value;

		this.mapFieldValuesToUDF(udfkv);

		this.udfService.refreshUDFListByDependency(this.all_udfs)
			.then((result: {
				input: {
					udf_data: any[]
				},
				local: {}
			}) => {
				this.udfService.createDynFormSections(result.input.udf_data)
					.then((result: {
						input: {},
						local: {
							dynFormSections: any[]
						}
					}) => {
						this.contactform = undefined;
						this.contactform = result.local.dynFormSections;
					}).catch(e => { })
			}).catch(e => { })
        // }
    }

    mapFieldValuesToUDF(udfKV: any) {
        let udfKeys = Object.keys(udfKV);
        for (let i = 0; i < this.all_udfs.length; i++) {
            const udf = this.all_udfs[i];
            for (let j = 0; j < udfKeys.length; j++) {
                const udf_field = udfKeys[j];
                const udf_value = udfKV[udf_field];
                if (udf && udf.field_name && udf.field_name == udf_field) {
                    this.all_udfs[i].field_value = udf_value;
                    if (udf.field_ui_type == 'phonenumber') {
                        // TODO: @paulthomas0 to be fixed for country code
                        // this.all_udfs[i].field_country_code = udfKV[udf_field + '__country_code']
                    }
                    break;
                }
            }
        }
    }

    update() {
        if (this.dnfc.dynamicFormGroup.valid) {
            this.contactService.updateContact(this.contactid, this.dnfc.dynamicFormGroup.value)
                .then(result => {
                    this.pubsub.$pub('refreshcontactsummary')
                    this.snackbar.open('Contact details updated successfully.', 'OK', {
                        duration: 3000
                    })
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                    this.snackbar.open(e.error.error, 'OK', {
                        duration: 3000
                    })
                })
        } else {
            this.snackbar.open('Some fields are invalid for contact.', 'OK', {
                duration: 3000
            })
        }
    }

}
