/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { formatDate } from '@angular/common';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { dashboardservice } from 'app/sd-services/dashboardservice';
import { labelService } from 'app/services/label/label.service';
import { NPubSubService } from 'neutrinos-seed-services';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/


export const PICK_FORMATS = {
    parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

class PickDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            return formatDate(date, 'dd-MM-yyyy', this.locale);;
        } else {
            return date.toDateString();
        }
    }
}


@Component({
    selector: 'bh-dashboard',
    templateUrl: './dashboard.template.html',
    providers: [
        { provide: DateAdapter, useClass: PickDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
    ]
})

export class dashboardComponent extends NBaseComponent implements OnInit {
    first_date;
    last_date;
    selectedView: any;
    statsValue;
    statsArr = [];
    averageArr = [];
    usersList = [];
    teamObj = {};
    userObj = {};
    statsCheck: boolean = false;
    private statsSub;

    constructor(private dashboardS: dashboardservice,
    public label: labelService,
    private pubsub: NPubSubService) {
        super();
        this.statsValue = 'my';
        this.selectedView = 'Weekly'
        this.getView('Weekly');
        this.getUsers();
    }

    ngOnInit() {
        this.statsSub = this.pubsub.$sub('refreshstatslist', () => {
        this.getView('Weekly');
        })

    }

    getStats() {
        this.dashboardS.getDashboardStats(this.statsValue, this.getDateString(this.first_date), this.getDateString(this.last_date))
            .then(res => {
                if (res && res.local.result)
                    this.statsArr = [];
                res.local.result.filter((ele) => {
                    if (ele.local.overViewData) {
                        let overViewObj = {}
                        overViewObj['title'] = 'OVERVIEW';
                        overViewObj['list'] = ele.local.overViewData.data;
                        this.statsArr.push(overViewObj);
                    }
                    else if (ele.local.dropoutData) {
                        let dropoutObj = {}
                        dropoutObj['title'] = this.label.capitalize(this.label.deal) + ' DROPOUT SUMMARY';
                        dropoutObj['list'] = ele.local.dropoutData.data;
                        this.statsArr.push(dropoutObj);
                    }
                    else if (ele.local.appointmentData) {
                        let appointmentObj = {}
                        appointmentObj['title'] = 'APPOINTMENT OUTCOME';
                        appointmentObj['list'] = ele.local.appointmentData.data;
                        this.statsArr.push(appointmentObj);
                    }
                    else if (ele.local.callData) {
                        let callObj = {}
                        callObj['title'] = 'CALL OUTCOME';
                        callObj['list'] = ele.local.callData.data;
                        this.statsArr.push(callObj);
                    }
                })
            });
        this.statsCheck = false;
        if (this.statsValue == 'team') {
            this.statsCheck = true;
            this.getTeamAverage();
        }
    }

    getUser(user_date) {
        this.dashboardS.getUserById(this.getDateString(this.first_date), this.getDateString(this.last_date), user_date['userid'])
            .then(res => {
                if (res && res.local.userAverageData) {
                    this.userObj['title'] = user_date['first_name'] + ' ' + user_date['last_name'];
                    this.userObj['list'] = res.local.userAverageData.data;
                    this.averageArr = [this.teamObj, this.userObj];
                }
            })
    }

    getCustomDate() {
        if (new Date(this.first_date) <= new Date(this.last_date)) {
            this.getStats();
        }
    }

    getDateString(date_value) {
        var dt = date_value;
        var year = dt.getFullYear();
        var month = (dt.getMonth() + 1).toString().padStart(2, "0");
        var day = dt.getDate().toString().padStart(2, "0");
        return year + '-' + month + '-' + day;
    }

    getTeamAverage() {
        this.dashboardS.getTeamAverage(this.getDateString(this.first_date), this.getDateString(this.last_date))
            .then(res => {
                if (res && res.local.teamData) {
                    this.teamObj = {}
                    this.teamObj['title'] = 'TEAM AVERAGE';
                    this.teamObj['list'] = res.local.teamData.data;
                }
                this.userObj = {}
                this.userObj['title'] = '';
                this.userObj['list'] = [];
                this.averageArr = [this.teamObj, this.userObj];
            })
    }

    getUsers() {
        this.dashboardS.getUsers(this.getDateString(this.first_date), this.getDateString(this.last_date))
            .then(res => {
                if (res && res.local.usersData) {
                    this.usersList = res.local.usersData.data;
                }
            })
    }

    getView(view) {
        this.selectedView = view;
        if (view == 'Weekly') {
            this.previousWeek(new Date());
        } else if (view == 'Monthly') {
            this.getCurrentMonth(new Date())
            this.getStats();
        } else if (view == 'Custom') {
            this.getQuarterlyDate(new Date());
        }
    }

    getCurrentMonth(value) {
        var my_date = new Date(value);
        this.first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
        this.last_date = new Date();
    }

    getWeekdates(date) {
        this.first_date = new Date(date);
        this.last_date = new Date(this.first_date.getTime() + 6 * 24 * 60 * 60 * 1000);
    }

    nextWeek(date) {
        this.first_date = new Date(date);
        this.last_date = new Date(this.first_date.getTime() + 6 * 24 * 60 * 60 * 1000);
        this.getStats();
    }

    previousWeek(date) {
        this.last_date = new Date(date);
        this.first_date = new Date(this.last_date.getFullYear(), this.last_date.getMonth(), this.last_date.getDate() - 6);
        this.getStats();
    }

    getmonthDates(value) {
        var my_date = new Date(value);
        this.first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
        this.last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
        this.getStats();
    }

    getQuarterlyDate(date) {
        var dated = new Date();
        var quarter = Math.floor((dated.getMonth() / 3));
        this.first_date = new Date(dated.getFullYear(), quarter * 3, 1);
        this.last_date = new Date(this.first_date.getFullYear(), this.first_date.getMonth() + 3, 0);
        this.getStats();
    }

    previousMonth(date) {
        var d = new Date(date);
        d.setMonth(d.getMonth() - 1);
        this.getmonthDates(d);
    }

    nextMonth(date) {
        var d = new Date(date);
        d.setMonth(d.getMonth() + 1);
        this.getmonthDates(d);
    }

    ngOnDestroy() {

        if (this.statsSub) {
            this.statsSub.unsubscribe();
        }
    }

}
