<div style="position:relative;" class="width-100">
  <label class="phone-number-label width-100 text-ellipsis" [ngClass]="{&apos;focus&apos;: focusedLabel}">{{placeholder}}{{required ? &apos; *&apos; : &apos;&apos;}}</label>
  <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxFlex="30" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div *ngIf="displayAutoComplete" class="width-100">
        <mat-form-field class="phone-country-code-autocomplete search-field width-100" [floatLabel]="always">
          <input type="text" aria-label="Number" matInput [formControl]="phoneCountryCodeControl" [matAutocomplete]="auto" (focus)="autoCompleteFocused()">
          <img matSuffix class="autocomplete-icon" src="assets/Web/Icons/autocomplete.svg">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" panelWidth="300">
            <mat-option *ngFor="let option of filteredPhoneCountryCodes | async;" [value]="option.phone_country_code">
              {{option.country}} {{option.phone_country_code}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column wrap" class="phone-number-input" fxFlex="70" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="displayAutoComplete">
      <mat-form-field style="margin-left: 5px; width:auto !important;" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
        <input matInput type="number" [required]="required" numbersOnly [formControl]="fc">

      </mat-form-field>
    </div>
  </div>
</div>