<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-around stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
    <div fxLayout="row wrap" fxFlex="15" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [matMenuTriggerFor]="action">
      <div fxLayout="column wrap" fxLayoutGap="3px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div class="action-list-image">
        </div>
        <h4 class="primary-font-color font-normal-size">
          Action</h4>
      </div>
    </div>
    <div fxLayout="row wrap" fxFlex="52" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxLayoutGap="3px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div class="calendar-view-image">
        </div>
        <h4 class="primary-font-color font-normal-size">
          Calendar</h4>
      </div>
    </div>
    <div fxLayout="row wrap" fxFlex="15" fxLayoutAlign="space-evenly center" [fxShow]="true" [fxHide]="false" [matMenuTriggerFor]="addtask">
      <div fxLayout="column wrap" fxLayoutGap="3px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div class="add-list-image">
        </div>
        <h4 class="primary-font-color font-normal-size">
          Add</h4>
      </div>
    </div>
  </div>
</div>
<mat-menu #action [hasBackdrop]="true" backdropClass="menu-backdrop" class="action-menu-sort" #menu="matMenu" hasBackdrop="true" overlapTrigger="false"><button mat-menu-item (click)="addLabel()" [disableRipple]="true" *ngIf="action_view == &apos;contactDetails&apos;" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" style="height: 38px;margin-top: 4px;" class="background-secondary circle" fxFlex="34" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div style="margin-top: 8px;margin-left: 0px;">
          <img imgSrc="/Web/Icons/contact_label.svg"></div>
      </div>
      <div fxLayout="row wrap" fxFlex="56" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Add label</h3>
      </div>
    </div>
  </button><button mat-menu-item (click)="filter()" [disableRipple]="true" *ngIf="action_view == &apos;contacts&apos;" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" style="height: 38px;margin-top: 4px;" class="background-secondary circle" fxFlex="34" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div style="margin-top: 12px;margin-left: 0px;">
          <img imgSrc="/Web/Icons/filterkey.svg"></div>
      </div>
      <div fxLayout="row wrap" fxFlex="56" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Filter</h3>
      </div>
    </div>
  </button><button mat-menu-item (click)="deleteContact()" [disableRipple]="true" *ngIf="(action_view == &apos;contactDetails&apos;) &amp;&amp; (user?.user?.srmPermissions?.includes(&apos;CONTACT_DELETE&apos;) &amp;&amp; (user?.user?.srmUserid == deleteService.contact_ownerid ||  (user?.user?.srmPermissions?.includes(&apos;CONTACT_ALL&apos;) || user?.user?.srmPermissions?.includes(&apos;GENERAL_ALL&apos;))))" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" style="height: 38px;margin-top: 4px;" class="background-secondary circle" fxFlex="34" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div style="margin-top: 8px;margin-left: 2px;">
          <img imgSrc="/Web/Icons/delete_new.svg"></div>
      </div>
      <div fxLayout="row wrap" fxFlex="56" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Delete</h3>
      </div>
    </div>
  </button><button mat-menu-item (click)="deleteDeal()" [disableRipple]="true" *ngIf="(action_view == &apos;dealDetails&apos;) &amp;&amp; (user.user.srmPermissions.includes(&apos;DEAL_DELETE&apos;) &amp;&amp; (user.user.srmUserid == deleteService.deal_ownerid || (user.user.srmPermissions.includes(&apos;DEAL_ALL&apos;) || user.user.srmPermissions.includes(&apos;GENERAL_ALL&apos;))))" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" style="height: 38px;margin-top: 4px;" class="background-secondary circle" fxFlex="34" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div style="margin-top: 8px;margin-left: 2px;">
          <img imgSrc="/Web/Icons/delete_new.svg"></div>
      </div>
      <div fxLayout="row wrap" fxFlex="56" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Delete</h3>
      </div>
    </div>
  </button><button mat-menu-item (click)="initialCall()" [disableRipple]="true" *ngIf="(action_view == &apos;contactDetails&apos; &amp;&amp; !deleteService.accessDenied) || (action_view == &apos;dealDetails&apos; &amp;&amp; !deleteService.accessDenied)" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" style="height: 38px;margin-top: 4px;" class="background-secondary circle" fxFlex="34" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div style="margin-top: 8px;margin-left: 2px;">
          <img imgSrc="/Web/Icons/call_icon.svg"></div>
      </div>
      <div fxLayout="row wrap" fxFlex="56" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Call</h3>
      </div>
    </div>
  </button><button mat-menu-item (click)="downloadContactasCSV()" [disableRipple]="true" *ngIf="(action_view == &apos;contacts&apos;) || (action_view == &apos;deals&apos;)" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" style="height: 38px;margin-top: 4px;" class="background-secondary circle" fxFlex="34" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div style="margin-top: 8px;margin-left: 4px;">
          <img style="margin-left: -4px" imgSrc="/Web/Icons/download_icon.svg"></div>
      </div>
      <div fxLayout="row wrap" fxFlex="56" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Download</h3>
      </div>
    </div>
  </button><button mat-menu-item (click)="navigateToSort()" [disableRipple]="true" *ngIf="(action_view == &apos;contacts&apos;) || (action_view == &apos;deals&apos;)" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" style="height: 38px;margin-top: 4px;" class="background-secondary circle" fxFlex="34" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div style="margin-top: 8px;margin-left: 4px;">
          <img imgSrc="/Web/Icons/action_sort.svg"></div>
      </div>
      <div fxLayout="row wrap" fxFlex="56" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Sort</h3>
      </div>
    </div>
  </button></mat-menu>
<mat-menu #addtask [hasBackdrop]="true" backdropClass="menu-backdrop" class="mobile-add-menu" #menu="matMenu" hasBackdrop="true" overlapTrigger="false"><button mat-menu-item [disableRipple]="true" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="70" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Import Contact</h3>
      </div>
      <div fxLayout="row wrap" style="height: 40px;" class="circle background-secondary" fxFlex="21" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div>
          <img class="mobile-menu-list" imgSrc="/Web/Icons/contact_mobile.svg"></div>
      </div>
    </div>
  </button><button mat-menu-item [disableRipple]="true" (click)="scheduleAppointment()" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="70" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Add Appointment</h3>
      </div>
      <div fxLayout="row wrap" style="height: 40px;" class="circle background-secondary" fxFlex="21" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div>
          <img class="mobile-menu-list" imgSrc="/Web/Icons/appointment_mobile.svg"></div>
      </div>
    </div>
  </button><button mat-menu-item [disableRipple]="true" (click)="addTask()" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="70" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Add Task</h3>
      </div>
      <div fxLayout="row wrap" style="height: 40px;" class="circle background-secondary" fxFlex="21" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div>
          <img class="mobile-menu-list" imgSrc="/Web/Icons/task_mobile.svg"></div>
      </div>
    </div>
  </button><button mat-menu-item [disableRipple]="true" (click)="addDeal()" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="70" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Add {{label.deal | titlecase}}</h3>
      </div>
      <div fxLayout="row wrap" style="height: 40px;" class="circle background-secondary" fxFlex="21" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div>
          <img class="mobile-menu-list" imgSrc="/Web/Icons/dollar_mobile.svg"></div>
      </div>
    </div>
  </button><button mat-menu-item [disableRipple]="true" (click)="addContact()" class="common-menu-item-align font-Weight-size font-Family generic-text-color">
    <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="70" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <h3 class="white-color">
          Add Contact</h3>
      </div>
      <div fxLayout="row wrap" style="height: 40px;" class="circle background-secondary" fxFlex="21" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div>
          <img class="mobile-menu-list" imgSrc="/Web/Icons/add_mobile.svg"></div>
      </div>
    </div>
  </button></mat-menu>