<div fxLayout="column " class="overflow-y-only" fxLayoutGap="5px" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="row " class="settings-setting-header" fxFlex="60px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <h2>
      SETTINGS</h2>
  </div>
  <div fxLayout="column " class="overflow-y-only height-100" fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" class="settings-setting-section-container" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let section of settings">
      <h2 class="settings-setting-section-header">
        {{section.section}}</h2>
      <div fxLayout="row wrap" class="settings-settings-list-wrp" fxLayoutGap="1.625rem" fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " class="cursor-pointer settings-setting-item" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" *ngFor="let setting of section.setting" (click)="openSetting(setting, $event)" tabindex="0" (keyup.enter)="openSetting(setting, $event)">
          <div class="settings-setting-item-img-wrp">
            <div [style.mask]="&apos;url(assets/Web/Icons/&apos; + setting.icon + &apos;) no-repeat center / contain&apos;" [style.-webkit-mask]="&apos;url(assets/Web/Icons/&apos; + setting.icon + &apos;) no-repeat center / contain&apos;" class="settings-setting-item-img">
            </div>
          </div>
          <div>
            {{setting.label}}</div>
        </div>
      </div>
    </div>
  </div>
</div>