import { NeutrinosAuthGuardService } from 'neutrinos-oauth-client';
import { PageNotFoundComponent } from '../not-found.component';
import { LayoutComponent } from '../layout/layout.component';
import { ImgSrcDirective } from '../directives/imgSrc.directive';
import { APP_INITIALIZER } from '@angular/core';
import { NDataSourceService } from '../n-services/n-dataSorce.service';
import { environment } from '../../environments/environment';
import { NMapComponent } from '../n-components/nMapComponent/n-map.component';
import { NLocaleResource } from '../n-services/n-localeResources.service';
import { NAuthGuardService } from 'neutrinos-seed-services';
import { ArtImgSrcDirective } from '../directives/artImgSrc.directive';
import { NumbersOnlyDirective } from '../directives/numbers-only-directive';
import { currencySymboleByIdPipe } from '../pipes/currencyCodeByIdPipe';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

window['neutrinos'] = {
  environments: environment
}

import { genericService } from '../services/generic/generic.service';

//CORE_REFERENCE_IMPORTS
//CORE_REFERENCE_IMPORT-emailService
import { emailService } from '../services/email/email.service';
//CORE_REFERENCE_IMPORT-emaildisplayitemComponent
import { emaildisplayitemComponent } from '../components/emaildisplayitemComponent/emaildisplayitem.component';
//CORE_REFERENCE_IMPORT-emailtrailComponent
import { emailtrailComponent } from '../components/emailtrailComponent/emailtrail.component';
//CORE_REFERENCE_IMPORT-newemailComponent
import { newemailComponent } from '../components/newemailComponent/newemail.component';
//CORE_REFERENCE_IMPORT-emailistComponent
import { emailistComponent } from '../components/emailistComponent/emailist.component';
//CORE_REFERENCE_IMPORT-teamfiltercountComponent
import { teamfiltercountComponent } from '../components/teamfiltercountComponent/teamfiltercount.component';
//CORE_REFERENCE_IMPORT-form_team_contact_filterComponent
import { form_team_contact_filterComponent } from '../components/form_team_contact_filterComponent/form_team_contact_filter.component';
//CORE_REFERENCE_IMPORT-deleteService
import { deleteService } from '../services/delete/delete.service';
//CORE_REFERENCE_IMPORT-filtercountComponent
import { filtercountComponent } from '../components/filtercountComponent/filtercount.component';
//CORE_REFERENCE_IMPORT-filteroptionsconfigComponent
import { filteroptionsconfigComponent } from '../components/filteroptionsconfigComponent/filteroptionsconfig.component';
//CORE_REFERENCE_IMPORT-form_contact_filterComponent
import { form_contact_filterComponent } from '../components/form_contact_filterComponent/form_contact_filter.component';
//CORE_REFERENCE_IMPORT-form_contact_labelComponent
import { form_contact_labelComponent } from '../components/form_contact_labelComponent/form_contact_label.component';
//CORE_REFERENCE_IMPORT-setting_vcall_msgComponent
import { setting_vcall_msgComponent } from '../components/setting_vcall_msgComponent/setting_vcall_msg.component';
//CORE_REFERENCE_IMPORT-titleService
import { titleService } from '../services/title/title.service';
//CORE_REFERENCE_IMPORT-sortdailogService
import { sortdailogService } from '../services/sortdailog/sortdailog.service';
//CORE_REFERENCE_IMPORT-header_interceptorService
import { header_interceptorService } from '../services/header_interceptor/header_interceptor.service';
//CORE_REFERENCE_IMPORT-settingsService
import { settingsService } from '../services/settings/settings.service';
//CORE_REFERENCE_IMPORT-delete_dialogComponent
import { delete_dialogComponent } from '../components/delete_dialogComponent/delete_dialog.component';
//CORE_REFERENCE_IMPORT-genericdialogComponent
import { genericdialogComponent } from '../components/genericdialogComponent/genericdialog.component';
//CORE_REFERENCE_IMPORT-sort_by_listComponent
import { sort_by_listComponent } from '../components/sort_by_listComponent/sort_by_list.component';
//CORE_REFERENCE_IMPORT-setting_dealpipelineComponent
import { setting_dealpipelineComponent } from '../components/setting_dealpipelineComponent/setting_dealpipeline.component';
//CORE_REFERENCE_IMPORT-feedbackoutcomessync
import { feedbackoutcomessyncService } from '../services/feedbackoutcomessync/feedbackoutcomessync.service';
//CORE_REFERENCE_IMPORT-module_renameComponent
import { module_renameComponent } from '../components/module_renameComponent/module_rename.component';
//CORE_REFERENCE_IMPORT-importcanceldialogComponent
import { importcanceldialogComponent } from '../components/importcanceldialogComponent/importcanceldialog.component';
//CORE_REFERENCE_IMPORT-dyncurrencytypeautoComponent
import { dyncurrencytypeautoComponent } from '../components/dyncurrencytypeautoComponent/dyncurrencytypeauto.component';
//CORE_REFERENCE_IMPORT-dynphonecountrycodeComponent
import { dynphonecountrycodeComponent } from '../components/dynphonecountrycodeComponent/dynphonecountrycode.component';
//CORE_REFERENCE_IMPORT-setting_phone_country_codeComponent
import { setting_phone_country_codeComponent } from '../components/setting_phone_country_codeComponent/setting_phone_country_code.component';
//CORE_REFERENCE_IMPORT-setting_currComponent
import { setting_currComponent } from '../components/setting_currComponent/setting_curr.component';
//CORE_REFERENCE_IMPORT-setting_itemComponent
import { setting_itemComponent } from '../components/setting_itemComponent/setting_item.component';
//CORE_REFERENCE_IMPORT-form_theme_logoComponent
import { form_theme_logoComponent } from '../components/form_theme_logoComponent/form_theme_logo.component';
//CORE_REFERENCE_IMPORT-assigncontactsComponent
import { assigncontactsComponent } from '../components/assigncontactsComponent/assigncontacts.component';
//CORE_REFERENCE_IMPORT-importcontacthistoryComponent
import { importcontacthistoryComponent } from '../components/importcontacthistoryComponent/importcontacthistory.component';
//CORE_REFERENCE_IMPORT-importcontactsComponent
import { importcontactsComponent } from '../components/importcontactsComponent/importcontacts.component';
//CORE_REFERENCE_IMPORT-form_udf_dependencyComponent
import { form_udf_dependencyComponent } from '../components/form_udf_dependencyComponent/form_udf_dependency.component';
//CORE_REFERENCE_IMPORT-optionsconfiguratorComponent
import { optionsconfiguratorComponent } from '../components/optionsconfiguratorComponent/optionsconfigurator.component';
//CORE_REFERENCE_IMPORT-currencycodeselectComponent
import { currencycodeselectComponent } from '../components/currencycodeselectComponent/currencycodeselect.component';
//CORE_REFERENCE_IMPORT-nationalityselectComponent
import { nationalityselectComponent } from '../components/nationalityselectComponent/nationalityselect.component';
//CORE_REFERENCE_IMPORT-countryselectComponent
import { countryselectComponent } from '../components/countryselectComponent/countryselect.component';
//CORE_REFERENCE_IMPORT-phonecountrycodeselectComponent
import { phonecountrycodeselectComponent } from '../components/phonecountrycodeselectComponent/phonecountrycodeselect.component';
//CORE_REFERENCE_IMPORT-formsectiontitleComponent
import { formsectiontitleComponent } from '../components/formsectiontitleComponent/formsectiontitle.component';
//CORE_REFERENCE_IMPORT-udfsyncService
import { udfsyncService } from '../services/udfsync/udfsync.service';
//CORE_REFERENCE_IMPORT-form_udf_fieldComponent
import { form_udf_fieldComponent } from '../components/form_udf_fieldComponent/form_udf_field.component';
//CORE_REFERENCE_IMPORT-udfdependenciesComponent
import { udfdependenciesComponent } from '../components/udfdependenciesComponent/udfdependencies.component';
//CORE_REFERENCE_IMPORT-udfsectionandfieldsComponent
import { udfsectionandfieldsComponent } from '../components/udfsectionandfieldsComponent/udfsectionandfields.component';
//CORE_REFERENCE_IMPORT-udf_fieldComponent
import { udf_fieldComponent } from '../components/udf_fieldComponent/udf_field.component';
//CORE_REFERENCE_IMPORT-udf_sectionComponent
import { udf_sectionComponent } from '../components/udf_sectionComponent/udf_section.component';
//CORE_REFERENCE_IMPORT-sidenavformheaderComponent
import { sidenavformheaderComponent } from '../components/sidenavformheaderComponent/sidenavformheader.component';
//CORE_REFERENCE_IMPORT-setting_feedbackoutcomesComponent
import { setting_feedbackoutcomesComponent } from '../components/setting_feedbackoutcomesComponent/setting_feedbackoutcomes.component';
//CORE_REFERENCE_IMPORT-setting_dealmoduleComponent
import { setting_dealmoduleComponent } from '../components/setting_dealmoduleComponent/setting_dealmodule.component';
//CORE_REFERENCE_IMPORT-setting_contactmoduleComponent
import { setting_contactmoduleComponent } from '../components/setting_contactmoduleComponent/setting_contactmodule.component';
//CORE_REFERENCE_IMPORT-form_contact_reassignComponent
import { form_contact_reassignComponent } from '../components/form_contact_reassignComponent/form_contact_reassign.component';
//CORE_REFERENCE_IMPORT-documentsComponent
import { documentsComponent } from '../components/documentsComponent/documents.component';
//CORE_REFERENCE_IMPORT-adddocumentComponent
import { adddocumentComponent } from '../components/adddocumentComponent/adddocument.component';
//CORE_REFERENCE_IMPORT-linkfieldComponent
import { linkfieldComponent } from '../components/linkfieldComponent/linkfield.component';
//CORE_REFERENCE_IMPORT-tenant_logo_serviceService
import { tenant_logo_serviceService } from '../services/tenant_logo_service/tenant_logo_service.service';
//CORE_REFERENCE_IMPORT-labelService
import { labelService } from '../services/label/label.service';
//CORE_REFERENCE_IMPORT-dyndealssearchComponent
import { dyndealssearchComponent } from '../components/dyndealssearchComponent/dyndealssearch.component';
//CORE_REFERENCE_IMPORT-loaderComponent
import { loaderComponent } from '../components/loaderComponent/loader.component';
//CORE_REFERENCE_IMPORT-thankyouComponent
import { thankyouComponent } from '../components/thankyouComponent/thankyou.component';
//CORE_REFERENCE_IMPORT-form_add_userComponent
import { form_add_userComponent } from '../components/form_add_userComponent/form_add_user.component';
//CORE_REFERENCE_IMPORT-dynnationalityComponent
import { dynnationalityComponent } from '../components/dynnationalityComponent/dynnationality.component';
//CORE_REFERENCE_IMPORT-userrolesresolverService
import { userrolesresolverService } from '../services/userrolesresolver/userrolesresolver.service';
//CORE_REFERENCE_IMPORT-form_add_roleComponent
import { form_add_roleComponent } from '../components/form_add_roleComponent/form_add_role.component';
//CORE_REFERENCE_IMPORT-rolesComponent
import { rolesComponent } from '../components/rolesComponent/roles.component';
//CORE_REFERENCE_IMPORT-usersComponent
import { usersComponent } from '../components/usersComponent/users.component';
//CORE_REFERENCE_IMPORT-settinguserandrolesComponent
import { settinguserandrolesComponent } from '../components/settinguserandrolesComponent/settinguserandroles.component';
//CORE_REFERENCE_IMPORT-settingsComponent
import { settingsComponent } from '../components/settingsComponent/settings.component';
//CORE_REFERENCE_IMPORT-externallinksComponent
import { externallinksComponent } from '../components/externallinksComponent/externallinks.component';
//CORE_REFERENCE_IMPORT-summarystageinfolistComponent
import { summarystageinfolistComponent } from '../components/summarystageinfolistComponent/summarystageinfolist.component';
//CORE_REFERENCE_IMPORT-summaryinfolistComponent
import { summaryinfolistComponent } from '../components/summaryinfolistComponent/summaryinfolist.component';
//CORE_REFERENCE_IMPORT-dashboardstatsComponent
import { dashboardstatsComponent } from '../components/dashboardstatsComponent/dashboardstats.component';
//CORE_REFERENCE_IMPORT-dailysummaryComponent
import { dailysummaryComponent } from '../components/dailysummaryComponent/dailysummary.component';
//CORE_REFERENCE_IMPORT-dashboardComponent
import { dashboardComponent } from '../components/dashboardComponent/dashboard.component';
//CORE_REFERENCE_IMPORT-dyncontactsearchComponent
import { dyncontactsearchComponent } from '../components/dyncontactsearchComponent/dyncontactsearch.component';
//CORE_REFERENCE_IMPORT-editdealComponent
import { editdealComponent } from '../components/editdealComponent/editdeal.component';
//CORE_REFERENCE_IMPORT-dynusersearchComponent
import { dynusersearchComponent } from '../components/dynusersearchComponent/dynusersearch.component';
//CORE_REFERENCE_IMPORT-dynmoneyComponent
import { dynmoneyComponent } from '../components/dynmoneyComponent/dynmoney.component';
//CORE_REFERENCE_IMPORT-sortComponent
import { sortComponent } from '../components/sortComponent/sort.component';
//CORE_REFERENCE_IMPORT-feedback_form_appointmentComponent
import { feedback_form_appointmentComponent } from '../components/feedback_form_appointmentComponent/feedback_form_appointment.component';
//CORE_REFERENCE_IMPORT-feedback_form_callComponent
import { feedback_form_callComponent } from '../components/feedback_form_callComponent/feedback_form_call.component';
//CORE_REFERENCE_IMPORT-teamdealsComponent
import { teamdealsComponent } from '../components/teamdealsComponent/teamdeals.component';
//CORE_REFERENCE_IMPORT-dealdetailsComponent
import { dealdetailsComponent } from '../components/dealdetailsComponent/dealdetails.component';
//CORE_REFERENCE_IMPORT-mydealsComponent
import { mydealsComponent } from '../components/mydealsComponent/mydeals.component';
//CORE_REFERENCE_IMPORT-dealsComponent
import { dealsComponent } from '../components/dealsComponent/deals.component';
//CORE_REFERENCE_IMPORT-confirmpopupComponent
import { confirmpopupComponent } from '../components/confirmpopupComponent/confirmpopup.component';
//CORE_REFERENCE_IMPORT-addtaskComponent
import { addtaskComponent } from '../components/addtaskComponent/addtask.component';
//CORE_REFERENCE_IMPORT-contactdeallistComponent
import { contactdeallistComponent } from '../components/contactdeallistComponent/contactdeallist.component';
//CORE_REFERENCE_IMPORT-adddealComponent
import { adddealComponent } from '../components/adddealComponent/adddeal.component';
//CORE_REFERENCE_IMPORT-summaryinfocardComponent
import { summaryinfocardComponent } from '../components/summaryinfocardComponent/summaryinfocard.component';
//CORE_REFERENCE_IMPORT-timelineComponent
import { timelineComponent } from '../components/timelineComponent/timeline.component';
//CORE_REFERENCE_IMPORT-pipelineComponent
import { pipelineComponent } from '../components/pipelineComponent/pipeline.component';
//CORE_REFERENCE_IMPORT-editcontactComponent
import { editcontactComponent } from '../components/editcontactComponent/editcontact.component';
//CORE_REFERENCE_IMPORT-addcontactComponent
import { addcontactComponent } from '../components/addcontactComponent/addcontact.component';
//CORE_REFERENCE_IMPORT-contactdealsComponent
import { contactdealsComponent } from '../components/contactdealsComponent/contactdeals.component';
//CORE_REFERENCE_IMPORT-contactactivitiesComponent
import { contactactivitiesComponent } from '../components/contactactivitiesComponent/contactactivities.component';
//CORE_REFERENCE_IMPORT-contactinfoComponent
import { contactinfoComponent } from '../components/contactinfoComponent/contactinfo.component';
//CORE_REFERENCE_IMPORT-notesComponent
import { notesComponent } from '../components/notesComponent/notes.component';
//CORE_REFERENCE_IMPORT-summaryComponent
import { summaryComponent } from '../components/summaryComponent/summary.component';
//CORE_REFERENCE_IMPORT-contactdetailsComponent
import { contactdetailsComponent } from '../components/contactdetailsComponent/contactdetails.component';
//CORE_REFERENCE_IMPORT-teamcontactsComponent
import { teamcontactsComponent } from '../components/teamcontactsComponent/teamcontacts.component';
//CORE_REFERENCE_IMPORT-mycontactsComponent
import { mycontactsComponent } from '../components/mycontactsComponent/mycontacts.component';
//CORE_REFERENCE_IMPORT-contactsComponent
import { contactsComponent } from '../components/contactsComponent/contacts.component';
//CORE_REFERENCE_IMPORT-calendarComponent
import { calendarComponent } from '../components/calendarComponent/calendar.component';
//CORE_REFERENCE_IMPORT-scheduleappointmentComponent
import { scheduleappointmentComponent } from '../components/scheduleappointmentComponent/scheduleappointment.component';
//CORE_REFERENCE_IMPORT-srmauthguardService
import { srmauthguardService } from '../services/srmauthguard/srmauthguard.service';
//CORE_REFERENCE_IMPORT-unauthorizedComponent
import { unauthorizedComponent } from '../components/unauthorizedComponent/unauthorized.component';
//CORE_REFERENCE_IMPORT-dynnumberComponent
import { dynnumberComponent } from '../components/dynnumberComponent/dynnumber.component';
//CORE_REFERENCE_IMPORT-dyncountryComponent
import { dyncountryComponent } from '../components/dyncountryComponent/dyncountry.component';
//CORE_REFERENCE_IMPORT-dyndatepickerComponent
import { dyndatepickerComponent } from '../components/dyndatepickerComponent/dyndatepicker.component';
//CORE_REFERENCE_IMPORT-dialogService
import { dialogService } from '../services/dialog/dialog.service';
//CORE_REFERENCE_IMPORT-ldcallComponent
import { ldcallComponent } from '../components/ldcallComponent/ldcall.component';
//CORE_REFERENCE_IMPORT-activities_tasksComponent
import { activities_tasksComponent } from '../components/activities_tasksComponent/activities_tasks.component';
//CORE_REFERENCE_IMPORT-activities_callsComponent
import { activities_callsComponent } from '../components/activities_callsComponent/activities_calls.component';
//CORE_REFERENCE_IMPORT-activities_appointmentsComponent
import { activities_appointmentsComponent } from '../components/activities_appointmentsComponent/activities_appointments.component';
//CORE_REFERENCE_IMPORT-activities_allComponent
import { activities_allComponent } from '../components/activities_allComponent/activities_all.component';
//CORE_REFERENCE_IMPORT-tenantresolverService
import { tenantresolverService } from '../services/tenantresolver/tenantresolver.service';
//CORE_REFERENCE_IMPORT-dynformComponent
import { dynformComponent } from '../components/dynformComponent/dynform.component';
//CORE_REFERENCE_IMPORT-dynemailComponent
import { dynemailComponent } from '../components/dynemailComponent/dynemail.component';
//CORE_REFERENCE_IMPORT-dynselectComponent
import { dynselectComponent } from '../components/dynselectComponent/dynselect.component';
//CORE_REFERENCE_IMPORT-dynphonenoComponent
import { dynphonenoComponent } from '../components/dynphonenoComponent/dynphoneno.component';
//CORE_REFERENCE_IMPORT-dyninputComponent
import { dyninputComponent } from '../components/dyninputComponent/dyninput.component';
//CORE_REFERENCE_IMPORT-searchdropdownComponent
import { searchdropdownComponent } from '../components/searchdropdownComponent/searchdropdown.component';
//CORE_REFERENCE_IMPORT-bltoolbarComponent
import { bltoolbarComponent } from '../components/bltoolbarComponent/bltoolbar.component';
//CORE_REFERENCE_IMPORT-baselayoutaddComponent
import { baselayoutaddComponent } from '../components/baselayoutaddComponent/baselayoutadd.component';
//CORE_REFERENCE_IMPORT-blprofileviewComponent
import { blprofileviewComponent } from '../components/blprofileviewComponent/blprofileview.component';
//CORE_REFERENCE_IMPORT-blfooterComponent
import { blfooterComponent } from '../components/blfooterComponent/blfooter.component';
//CORE_REFERENCE_IMPORT-blsearchbarComponent
import { blsearchbarComponent } from '../components/blsearchbarComponent/blsearchbar.component';
//CORE_REFERENCE_IMPORT-blmenutreeComponent
import { blmenutreeComponent } from '../components/blmenutreeComponent/blmenutree.component';
//CORE_REFERENCE_IMPORT-blmenuComponent
import { blmenuComponent } from '../components/blmenuComponent/blmenu.component';
//CORE_REFERENCE_IMPORT-baselayoutComponent
import { baselayoutComponent } from '../components/baselayoutComponent/baselayout.component';
import { StopEventPropagation } from '../directives/StopEventPropagation';
import { HighlightSearch } from '../highlightPipe/HighlightSearch';
import { FileDragDropDirective } from 'app/file-drag-drop.directive';
import { DragDirective } from '../directives/dragDrop.directive';
/**
 * Reads datasource object and injects the datasource object into window object
 * Injects the imported environment object into the window object
 *
 */
export function startupServiceFactory(startupService: NDataSourceService) {
  return () => startupService.getDataSource();
}

/**
*bootstrap for @NgModule
*/
export const appBootstrap: any = [
  LayoutComponent,
];


/**
*Entry Components for @NgModule
*/
export const appEntryComponents: any = [
  //CORE_REFERENCE_PUSH_TO_ENTRY_ARRAY
];

/**
*declarations for @NgModule
*/
export const appDeclarations = [
  currencySymboleByIdPipe,
  NumbersOnlyDirective,
  ImgSrcDirective,
  LayoutComponent,
  PageNotFoundComponent,
  NMapComponent,
  ArtImgSrcDirective,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-emaildisplayitemComponent
emaildisplayitemComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-emailtrailComponent
emailtrailComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-newemailComponent
newemailComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-emailistComponent
emailistComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-teamfiltercountComponent
teamfiltercountComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_team_contact_filterComponent
form_team_contact_filterComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-filtercountComponent
filtercountComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-filteroptionsconfigComponent
filteroptionsconfigComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_contact_filterComponent
form_contact_filterComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_contact_labelComponent
form_contact_labelComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_vcall_msgComponent
setting_vcall_msgComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-delete_dialogComponent
delete_dialogComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-genericdialogComponent
genericdialogComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-sort_by_listComponent
sort_by_listComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_dealpipelineComponent
setting_dealpipelineComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-module_renameComponent
module_renameComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-importcanceldialogComponent
importcanceldialogComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dyncurrencytypeautoComponent
dyncurrencytypeautoComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynphonecountrycodeComponent
dynphonecountrycodeComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_phone_country_codeComponent
setting_phone_country_codeComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_currComponent
setting_currComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_itemComponent
setting_itemComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_theme_logoComponent
form_theme_logoComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-assigncontactsComponent
assigncontactsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-importcontacthistoryComponent
importcontacthistoryComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-importcontactsComponent
importcontactsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_udf_dependencyComponent
form_udf_dependencyComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-optionsconfiguratorComponent
optionsconfiguratorComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-currencycodeselectComponent
currencycodeselectComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-nationalityselectComponent
nationalityselectComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-countryselectComponent
countryselectComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-phonecountrycodeselectComponent
phonecountrycodeselectComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-formsectiontitleComponent
formsectiontitleComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_udf_fieldComponent
form_udf_fieldComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-udfdependenciesComponent
udfdependenciesComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-udfsectionandfieldsComponent
udfsectionandfieldsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-udf_fieldComponent
udf_fieldComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-udf_sectionComponent
udf_sectionComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-sidenavformheaderComponent
sidenavformheaderComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_feedbackoutcomesComponent
setting_feedbackoutcomesComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_dealmoduleComponent
setting_dealmoduleComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-setting_contactmoduleComponent
setting_contactmoduleComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_contact_reassignComponent
form_contact_reassignComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-documentsComponent
documentsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-adddocumentComponent
adddocumentComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-linkfieldComponent
linkfieldComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dyndealssearchComponent
dyndealssearchComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-loaderComponent
loaderComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-thankyouComponent
thankyouComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_add_userComponent
form_add_userComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynnationalityComponent
dynnationalityComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-form_add_roleComponent
form_add_roleComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-rolesComponent
rolesComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-usersComponent
usersComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-settinguserandrolesComponent
settinguserandrolesComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-settingsComponent
settingsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-externallinksComponent
externallinksComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-summarystageinfolistComponent
summarystageinfolistComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-summaryinfolistComponent
summaryinfolistComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dashboardstatsComponent
dashboardstatsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dailysummaryComponent
dailysummaryComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dashboardComponent
dashboardComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dyncontactsearchComponent
dyncontactsearchComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-editdealComponent
editdealComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynusersearchComponent
dynusersearchComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynmoneyComponent
dynmoneyComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-sortComponent
sortComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-feedback_form_appointmentComponent
feedback_form_appointmentComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-feedback_form_callComponent
feedback_form_callComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-teamdealsComponent
teamdealsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dealdetailsComponent
dealdetailsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-mydealsComponent
mydealsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dealsComponent
dealsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-confirmpopupComponent
confirmpopupComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-addtaskComponent
addtaskComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-contactdeallistComponent
contactdeallistComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-adddealComponent
adddealComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-summaryinfocardComponent
summaryinfocardComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-timelineComponent
timelineComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-pipelineComponent
pipelineComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-editcontactComponent
editcontactComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-addcontactComponent
addcontactComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-contactdealsComponent
contactdealsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-contactactivitiesComponent
contactactivitiesComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-contactinfoComponent
contactinfoComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-notesComponent
notesComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-summaryComponent
summaryComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-contactdetailsComponent
contactdetailsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-teamcontactsComponent
teamcontactsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-mycontactsComponent
mycontactsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-contactsComponent
contactsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-calendarComponent
calendarComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-scheduleappointmentComponent
scheduleappointmentComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-unauthorizedComponent
unauthorizedComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynnumberComponent
dynnumberComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dyncountryComponent
dyncountryComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dyndatepickerComponent
dyndatepickerComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-ldcallComponent
ldcallComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-activities_tasksComponent
activities_tasksComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-activities_callsComponent
activities_callsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-activities_appointmentsComponent
activities_appointmentsComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-activities_allComponent
activities_allComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynformComponent
dynformComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynemailComponent
dynemailComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynselectComponent
dynselectComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dynphonenoComponent
dynphonenoComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-dyninputComponent
dyninputComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-searchdropdownComponent
searchdropdownComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-bltoolbarComponent
bltoolbarComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-baselayoutaddComponent
baselayoutaddComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-blprofileviewComponent
blprofileviewComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-blfooterComponent
blfooterComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-blsearchbarComponent
blsearchbarComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-blmenutreeComponent
blmenutreeComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-blmenuComponent
blmenuComponent,
//CORE_REFERENCE_PUSH_TO_DEC_ARRAY-baselayoutComponent
baselayoutComponent,
StopEventPropagation,
HighlightSearch,
FileDragDropDirective,
DragDirective
];

/**
* provider for @NgModuke
*/
export const appProviders = [
  NDataSourceService,
  NLocaleResource,
  {
    // Provider for APP_INITIALIZER
    provide: APP_INITIALIZER,
    useFactory: startupServiceFactory,
    deps: [NDataSourceService],
    multi: true
  },
  NAuthGuardService,
  genericService,
  //CORE_REFERENCE_PUSH_TO_PRO_ARRAY
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-emailService
emailService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-deleteService
deleteService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-titleService
titleService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-sortdailogService
sortdailogService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-header_interceptorService
{
  // header_interceptorService,
  provide: HTTP_INTERCEPTORS,
  useClass: header_interceptorService,
  multi: true
},
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-settingsService
settingsService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-feedbackoutcomessync
feedbackoutcomessyncService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-udfsyncService
udfsyncService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-tenant_logo_serviceService
tenant_logo_serviceService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-labelService
labelService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-userrolesresolverService
userrolesresolverService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-srmauthguardService
srmauthguardService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-dialogService
dialogService,
//CORE_REFERENCE_PUSH_TO_PRO_ARRAY-tenantresolverService
tenantresolverService,

];

/**
* Routes available for bApp
*/

// CORE_REFERENCE_PUSH_TO_ROUTE_ARRAY_START
export const appRoutes = [{path: 'home', component: baselayoutComponent, resolve: { data: tenantresolverService }, canActivate: [srmauthguardService],
children: [{path: 'calendar', component: calendarComponent},{path: 'contactdeals', component: contactdeallistComponent},{path: 'contacts', component: contactsComponent},{path: 'contacts/:contactid', component: contactdetailsComponent},{path: 'deals', component: dealsComponent},{path: 'deals/:dealid/contact/:contactid', component: dealdetailsComponent},{path: 'dashboard', component: dashboardComponent},{path: 'settings', component: settingsComponent},{path: 'settings/user-and-roles', component: settinguserandrolesComponent},{path: 'summary', component: dailysummaryComponent},{path: 'import-contact-status', component: importcontacthistoryComponent},{path: 'settings/contact-module', component: setting_contactmoduleComponent},{path: 'settings/deal-module', component: setting_dealmoduleComponent}]},{path: 'unauthorized', component: unauthorizedComponent},{path: 'thankyou', component: thankyouComponent},{path: '', redirectTo: 'home', pathMatch: 'full'},{path: '**', component: PageNotFoundComponent}]
// CORE_REFERENCE_PUSH_TO_ROUTE_ARRAY_END
