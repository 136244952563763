/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NPubSubService } from 'neutrinos-seed-services';
import { dialogService } from '../../services/dialog/dialog.service';
import { environment } from 'environments/environment';
import { cacheservice } from 'app/sd-services/cacheservice';
import { sortdailogService } from 'app/services/sortdailog/sortdailog.service';
import { userservice } from 'app/sd-services/userservice';
import { labelService } from 'app/services/label/label.service';
import { dealservice } from 'app/sd-services/dealservice';
import { deleteService } from 'app/services/delete/delete.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-blfooter',
    templateUrl: './blfooter.template.html'
})

export class blfooterComponent extends NBaseComponent implements OnInit {
    hasBackdrop = true;
    private actionSub;
    contactid;
    dealid;
    action_view;

    constructor(private router: Router,
        public dialog: MatDialog,
        private pubsub: NPubSubService,
        private dialogService: dialogService,
        private cacheService: cacheservice,
        private sortdialogservice: sortdailogService,
        public label: labelService,
        public user: userservice,
        private dealService: dealservice,
        public deleteService: deleteService) {
        super();
    }

    ngOnInit() {
        this.actionSub = this.pubsub.$sub('actionlist', (res) => {
            if (res) {
                this.action_view = res.list;
            }
            if (res && res.contactid) {
                this.contactid = res.contactid;
            }
            if (res && res.dealid) {
                this.dealid = res.dealid;
            }
        })

    }

    initialCall() {
        if(this.action_view == 'contactDetails'){   
        if (this.contactid) {
            this.dialogService.makeCallDialog({
                modulename: 'contacts',
                moduleid: this.contactid
            });
        }
        }
        if(this.action_view == 'dealDetails'){   
            if (this.contactid) {
            this.dialogService.makeCallDialog({
                modulename: 'deals',
                moduleid: this.dealid,
                contactid: this.contactid
            });
        }
        }

    }

    navigateToSort() {
        this.sortdialogservice.sortList();
    }

    addTask() {
        this.cacheService.setc({}, 'EDIT_TASK_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_TASK'
        })
    }

    scheduleAppointment() {
        this.cacheService.setc({}, 'EDIT_APPOINTMENT_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'SCHEDULE_APPOINTMENT'
        })
    }

    addContact() {
        this.pubsub.$pub('sidenavformevents', {
            'ACTION': 'open',
            PAGE: 'ADD_CONTACT'
        })
    }
    filter(){
        this.pubsub.$pub('sidenavformevents', {
            'ACTION': 'open',
            PAGE: 'CONTACT_FILTER'
        })
    }
    addLabel(){
        this.pubsub.$pub('sidenavformevents', {
            'ACTION': 'open',
            PAGE: 'CONTACT_LABEL'
        })
    }

    addDeal() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_DEAL'
        })
    }

    downloadContactasCSV() {
        if (this.action_view == 'contacts') {
            window.location.assign(`${environment.properties.ssdURL}export/contacts`);
        }
        if (this.action_view == 'deals') {
            window.location.assign(`${environment.properties.ssdURL}export/deals`);
        }
    }

    deleteContact() {
        this.deleteService.deleteContact(this.contactid);
    }

    deleteDeal() {
        this.deleteService.deleteDeal(this.dealid);
    }

    ngOnDestroy() {
        if (this.actionSub) {
            this.actionSub.unsubscribe();
        }
    }
}
