<mat-toolbar [ngClass.gt-xs]="&apos;toolbar-boxShadhow common-toolbar-gt-xs common-box-shoadow&apos;" [ngClass.xs]="&apos;background-actual-primary&apos;" [ngStyle.xs]="&apos;padding: 0px 12px !important&apos;" class="common-toolbar font-Family">
  <div fxLayout="row " fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="space-between">
    <div fxLayout="row wrap" fxFlex="20" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
      <mat-icon class="color-primary" (click)="menuClicked()">menu</mat-icon>
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"></div>
    </div>
    <div fxLayout="row " fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" fxLayout.xs="column wrap" fxLayoutAlign.xs="center stretch" fxHide.gt-xs="true">
      <div fxLayout="column wrap" class="white-color" fxFlex="100" fxLayoutAlign="center none" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="72">
        <div *ngIf="titleservice.activeLabel == &apos;Contacts&apos;">
          {{titleservice.activeLabel | uppercase}}</div>
        <div *ngIf="titleservice.activeLabel == &apos;Deals&apos;">
          {{label.deals | uppercase}}</div>
      </div>
    </div>
    <div fxLayout="row " fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" fxLayout.xs="column wrap" fxLayoutAlign.xs="center stretch" fxFlex.gt-xs="50" fxHide.xs="true">
      <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true"><img [src]="tenantIcon" alt="Logo" *ngIf="showMainLogo" class="tenant-logo common-margin-buttom"><img imgSrc="Web/Icons/favicon.ico" *ngIf="showneutrinosLogo" class="tenant-logo common-margin-buttom"></div>
      <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="center none" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="72">
        <bh-blsearchbar></bh-blsearchbar>
      </div>
    </div>
    <div fxLayout="column " fxLayoutAlign="  " [fxShow]="true" [fxHide]="false" fxFlex.gt-xs="50">
      <div fxLayout="row " fxLayoutAlign="end none" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="stretch">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;toolbar-border-gt-xs&apos;">
          <div fxLayout="column wrap" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false">
            <bh-baselayoutadd></bh-baselayoutadd>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true" *ngxPermissionsOnly="[&apos;GENERAL_MINE&apos;]"><button mat-button class="action-button common-filter-image cursor-pointer" matTooltip="Calendar" (click)="navigateCalendar()">
              <div class="calendar-image"></div>
            </button></div>
          <div fxLayout="row wrap" style="height: 75%;" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true"><img imgSrc="/Web/Icons/search-icon.svg" class="label-padding-right common-add-list-images"></div>
        </div>
        <div fxLayout="column wrap" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="42">
          <bh-blprofileview></bh-blprofileview>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>