/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { dealservice } from 'app/sd-services/dealservice';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { NPubSubService } from 'neutrinos-seed-services';
import { Router } from '@angular/router';
import { userservice } from 'app/sd-services/userservice';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-contactdeallist',
    templateUrl: './contactdeallist.template.html'
})

export class contactdeallistComponent extends NBaseComponent implements OnInit, OnChanges, OnDestroy {
    dealHeader = ['Name', 'Value', 'Stage', 'Last Updated Date'];;
    dealValue = [];
    pageSize = 15;
    pageOffset = 0;
    scrollPageOffset = 0;
    dealCount;
    sum = 15;
    dealsArr: Observable<any>;
    dataSource: MatTableDataSource<any>;
    progressCheck: boolean;
    dealSub: Subscription;
    checkAppendStatus = false;
    currentUserId;
    @Input('contactid') contactid;
    @Input('contact_owner_id') contact_owner_id;

    constructor(private dealS: dealservice,
        private pubsub: NPubSubService,
        private router: Router,
        private userS: userservice,
        private label: labelService) {
        super();
    }

    ngOnInit() {
        this.currentUserId = this.userS.user.srmUserid;
        this.dealSub = this.pubsub.$sub('deal-added-event')
            .subscribe(() => {
                this.getDeals(this.pageSize, this.pageOffset);
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.contact_owner_id || this.currentUserId) {
            this.pageSize = 15;
            this.pageOffset = 0;
            this.getDeals(this.pageSize, this.pageOffset);
        }
    }

    pageEvents(event) {
        this.pageSize = event.pageSize;
        this.pageOffset = event.pageIndex;
        if (event.pageSize) {
            this.getDeals(this.pageSize, this.pageOffset)
        }
    }

    onScroll() {
        this.checkAppendStatus = true;
        this.scrollPageOffset += 1;
        this.getDeals(this.sum, this.scrollPageOffset);
    }

    getDeals(size, offset) {
        this.progressCheck = false;
        if (this.currentUserId == this.contact_owner_id) {
            this.dealS.getMyDeals(size, offset, null, this.contactid)
                .then(result => {
                    if (result && result.local.dealsValue &&
                        result.local.dealsValue.data &&
                        result.local.dealsValue.data.deals instanceof Array &&
                        result.local.dealsValue.data.deals.length) {
                        this.contactDealList(result);
                    }
                    this.progressCheck = true;
                });
        } else {
            this.dealS.getTeamContactDeals(size, offset, null, this.contactid, this.contact_owner_id)
                .then(result => {
                    if (result && result.local.dealsValue &&
                        result.local.dealsValue.data &&
                        result.local.dealsValue.data.deals instanceof Array &&
                        result.local.dealsValue.data.deals.length) {
                        this.contactDealList(result);
                    }
                    this.progressCheck = true;
                });
        }
    }

    contactDealList(result) {
        if (this.checkAppendStatus) {
            let temp = this.dealsArr['_value'];
            this.dataSource = new MatTableDataSource<any>(result.local.dealsValue.data.deals);
            this.dealValue = result.local.dealsValue.data.deals;
            this.dealCount = result.local.dealsValue.data.count;
            this.dealsArr = this.dataSource.connect();
            this.dealsArr['_value'] = temp.concat(this.dealsArr['_value']);
            this.checkAppendStatus = false;
        } else {
            this.dataSource = new MatTableDataSource<any>(result.local.dealsValue.data.deals);
            this.dealValue = result.local.dealsValue.data.deals;
            this.dealCount = result.local.dealsValue.data.count;
            this.dealsArr = this.dataSource.connect();
        }
    }

    navigateToDeal(id) {
        this.router.navigate(['/home/deals/' + id + '/contact/' + this.contactid])
    }

    ngOnDestroy() {
        if (this.dealSub) {
            this.dealSub.unsubscribe()
        }
    }
}
