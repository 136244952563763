<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" class="add-contact-title lr-padding add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/adddeal.svg">
          <h2>
            ADD {{label.deal | uppercase}}</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon (click)="close()" [ngClass.gt-xs]="&apos;close-button&apos;">close</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="addDealFormGroup" fxLayout="column" fxFlex="calc(100% - 64px)" NgForm class="add-lead-content" (ngSubmit)="add()">
    <div fxLayout="column " fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" fxFill>
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
          <input matInput placeholder="Name" [required]="true" trim="blur" [formControl]="addDealFormGroup.controls.deal_name">

        </mat-form-field>
        <bh-dynmoney [fc]="addDealFormGroup.controls.deal_value" [currencyCodeControl]="addDealFormGroup.controls.deal_currency" [placeholder]="&apos;Value&apos;" [field_name]="&apos;deal_value&apos;"></bh-dynmoney>
        <bh-dyncontactsearch [fc]="addDealFormGroup.controls.related_contact" [placeholder]="&apos;Related Contact&apos;" [defaultcontacts]="contactArr" [value]="contactId" [required]="contactRequired"></bh-dyncontactsearch>
      </div>
      <div fxLayout="column wrap" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view add-button" [disabled]="disableAdd">Add</button></div>
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
        </div>
      </div>
    </div>
  </form>
</div>