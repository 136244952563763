/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, ViewChild, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormControl, Validators } from '@angular/forms';
import { srmappservice } from 'app/sd-services/srmappservice';
import { tenantservice } from 'app/sd-services/tenantservice';
import { startWith, map } from 'rxjs/operators';
import * as fuzzysort from 'fuzzysort';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

let currencyCodes: Array<any> = [];
@Component({
    selector: 'bh-dynmoney',
    templateUrl: './dynmoney.template.html'
})

export class dynmoneyComponent extends NBaseComponent implements OnInit {
    @Input('fc') fc = new FormControl();
    @Input('currencyCodeControl') currencyCodeControl = new FormControl();
    @Input('placeholder') placeholder = 'Mobile Number';
    @Input('field_name') field_name = '';
    @Input('required') required = false;
    @ViewChild(MatAutocompleteTrigger) trigger;
    // example: {"currencyid":1,"country":"Afghanistan","currency_name":"Afghanistani Afghani","currency_code":"AFN","currency_symbol":"؋"}
    focusedLabel = false;
    filteredCurrencyCodes: Observable<Array<{ country: string, phone_country_code: string }>>;
    displayAutoComplete = false;

    constructor(public srmappS: srmappservice,
        private tenantService: tenantservice,
        private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (this.required) {
            this.fc.setValidators(Validators.required);
        }
        this.srmappS.getCurrencyCodes()
            .then((result: {
                input: {},
                local: {
                    currencycodes: any[]
                }
            }) => {
                currencyCodes = result.local.currencycodes;
                this.tenantService.getTenantSettings()
                    .then(result => {

                        if (result.local.tenantSettings &&
                            result.local.tenantSettings.CURRENCY_CODE &&
                            !this.currencyCodeControl.value) {
                            this.currencyCodeControl.setValue(Number(result.local.tenantSettings.CURRENCY_CODE));
                        } else {
                            console.log('No default country code set for the tenant');
                            this.currencyCodeControl.setValue(this.currencyCodeControl.value);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(e => {
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currencyCodeControl) {
            this.filteredCurrencyCodes = changes.currencyCodeControl.currentValue.valueChanges.pipe(
                startWith(''),
                map(v => {
                    if (!currencyCodes.find((item) => item.currencyid === v)) {
                        this.currencyCodeControl.setErrors({ 'incorrect': true });
                    }
                    if(!this.currencyCodeControl.value){
                        this.currencyCodeControl.setErrors(null);
                    }

                    return this.filter(v)
                })
            );
            this.displayAutoComplete = true;
        }
    }

    focused() {
        this.focusedLabel = true;
    }

    autoCompleteFocused() {
        if (this.currencyCodeControl.value == '') {
            this.trigger._onChange("");
            this.trigger.openPanel();
        } else if (this.currencyCodeControl.value) {
            this.trigger._onChange(this.currencyCodeControl.value);
            this.trigger.openPanel();
        }
    }

    blurred() {
        this.focusedLabel = false;
    }

    displayWith(v) {
        if (v) {
            const selectedCurrency = currencyCodes.filter(currency => {
                return currency.currencyid == v;
            })
            return selectedCurrency[0] ? selectedCurrency[0]['currency_symbol'] : null;
        }
        return null;
    }

    private filter(searchValue) {
        if (searchValue == '') {
            return currencyCodes;
        }

        // auto-complete is fired with search for id
        if (typeof searchValue === 'number') {
            return currencyCodes.filter(v => {
                return v.currencyid === searchValue;
            })
        }

        if (typeof searchValue != 'string') {
            return [];
        }

        return fuzzysort.go(searchValue, currencyCodes, {
            key: 'currency_name',
            threshold: -10000
        })
            .map(v => {
                return v.obj;
            })
    }
}
