<div fxLayout="column wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center center" [ngClass.xs]="&apos;background-secondary&apos;" [ngStyle.xs]="&apos;height: 54px;border-radius: 0px 0px 24px 24px;&apos;" [ngStyle.gt-xs]="&apos;height: 65px&apos;">
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center center" [ngClass.xs]="&apos;width-100 detail-header-xs&apos;" [ngStyle.xs]="{&apos;display&apos;: (!shownotes) ? &apos;flex&apos; : &apos;none&apos;}">
      <bh-contactinfo [contact_name]="contact.display_name" [contact_owner]="contact.contact_owner_name" [contactid]="contact.contactid" [accessDenied]="accessDenied" [ngStyle.xs]="&apos;width: 94%;&apos;" [ngClass.xs]="&apos;mobile-detail-toolbar&apos;" (notesTrigger)="notesView()" [contact_owner_id]="contact.contact_owner_id"></bh-contactinfo>
    </div>
  </div>
  <div fxLayout="column wrap" fxFlex="calc(100% - 200px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;padding-top: 12px;margin-top: 24px;&apos;" [ngClass.xs]="(!shownotes) ? &apos;&apos; : &apos;notes-position-xs&apos;">
    <div fxLayout="row wrap" class="relative-position height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center center">
      <div fxLayout="column " class="overflow-y height-100" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="69" [fxFlex.xs]="94" [fxHide.xs]="shownotes" [ngClass]="(!shownotes) ? &apos;display-block&apos; : &apos;&apos;">
        <div fxLayout="column wrap" class="summary-element" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="(panelView == &apos;open&apos;) ? &apos;height-100&apos; : &apos;&apos;">
          <bh-summary class="height-100" [config]="summaryConfig" (editButtonClicked)="editContact()" [media_flex]="activeMediaQuery" (panelChange)="panelChange($event)"></bh-summary>
        </div>
        <div fxLayout="column wrap" style="flex-grow: 1;" class="activity-min-height border-radius card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="{&apos;height&apos;: (selectedIndex == 1) ? &apos;100%&apos; : &apos;&apos; }" [ngClass.sm]="&apos;activity-tab-sm&apos;">
          <div fxLayout="row wrap" fxLayoutAlign="end end" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 100%&apos;" fxLayoutAlign.xs="center center" [ngClass.gt-xs]="&apos;pagination-align-gt-xs&apos;" [fxHide.xs]="true"><button mat-button style="  margin-top: 1.2em; margin-right: 1em;" class="action-button" matTooltip="Refresh" *ngIf="selectedIndex == 0" (click)="refreshActivities()">
              <div class="refresh-image"></div>
            </button><button mat-button style="  margin-top: 1.2em; margin-right: 1em;" class="action-button" matTooltip="Add Document" *ngIf="selectedIndex == 2 &amp;&amp; !accessDenied" (click)="add_document()">
              <div class="add-notes-image"></div>
            </button></div>
          <mat-tab-group mat-align-tabs="start" class="width-100 document-tab h3-tab-label height-100 lead-details-tabs" [(selectedIndex)]="selectedIndex" [ngClass.xs]="&apos;mobile-tab-label mat-geneic-tab details-mobile-tab&apos;" animationDuration="0">
            <mat-tab label="ALL ACTIVITIES">
              <bh-contactactivities class="height-100" [contactid]="contactid"></bh-contactactivities>
            </mat-tab>
            <mat-tab label="{{label.deals | uppercase }}">
              <bh-contactdeallist class="height-100" [contactid]="contactid" [contact_owner_id]="contact.contact_owner_id"></bh-contactdeallist>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span [ngClass]="(documentArr.length) ? &apos;mat-tab-badge mobile-mat-madge&apos; : &apos;mat-badge-none&apos;" matBadge="{{documentArr.length}}" matBadgeOverlap="false">DOCUMENTS</span>
                <mat-icon class="display-none">home</mat-icon>
              </ng-template>
              <bh-documents [documentsArr]="documentArr" (download_file_id)="downloadDocument($event.value)" [accessDenied]="accessDenied"></bh-documents>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="!shownotes" [ngClass.gt-xs]="&apos;contact-details-notes-wrp&apos;" [fxFlex.xs]="94" [ngClass.xs]="&apos;height-100&apos;">
        <bh-notes class="width-100" [notes]="notes" (newNote)="noteAdded($event)" (deletedNote)="noteDeleted($event)" (editedNote)="noteUpdated($event)" (exitNotes)="backtosummary()" (editedPinned)="pinnednoteUpdated($event)" (editedUnpinned)="unpinnednoteUpdated($event)"></bh-notes>
      </div>
    </div>
  </div>
</div>