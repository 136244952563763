/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

import { utilservice } from './utilservice'; //_splitter_
//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class userservice {
  constructor(
    private utilservice: utilservice,
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_userservice

  async setcu(user: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          user: user
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_HdfTozx84Qn3NmtC(bh);
      //appendnew_next_setcu
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_S3qTNwv9L5Sx0qYZ');
    }
  }

  async getcu(...others) {
    try {
      var bh = {
        input: {},
        local: {
          user: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_54NXSwKPoTSnLvzw(bh);
      //appendnew_next_getcu
      return (
        // formatting output variables
        {
          input: {},
          local: {
            user: bh.local.user
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_xBZa7qwCHvCUayLw');
    }
  }

  async getUsersFromDb(
    pageSize = 10,
    pageOffset = 0,
    sortBy: any = [],
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          sortBy: sortBy
        },
        local: {
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_Viylh6ey75FSdeEj(bh);
      //appendnew_next_getUsersFromDb
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Ff1lv67Hs5cbKCwT');
    }
  }

  async addUser(body: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          body: body
        },
        local: {
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_O5PouCZU1sduiUDj(bh);
      //appendnew_next_addUser
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_yM1l51J2DR8RMEzu');
    }
  }

  async updateUser(body: any = undefined, userid = 0, ...others) {
    try {
      var bh = {
        input: {
          body: body,
          userid: userid
        },
        local: {
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_jCelt94DI8e3eUPQ(bh);
      //appendnew_next_updateUser
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_rxJfD3H2YmYSItbV');
    }
  }

  async getUserReportingTo(userid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          userid: userid
        },
        local: {
          res: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_mMIlMDIGADv18bH4(bh);
      //appendnew_next_getUserReportingTo
      return (
        // formatting output variables
        {
          input: {},
          local: {
            res: bh.local.res
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BZOTfNdePzuo6EKE');
    }
  }

  async getcupermission(...others) {
    try {
      var bh = {
        input: {},
        local: {
          permissions: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_1psXbOzkNOtQDZfV(bh);
      //appendnew_next_getcupermission
      return (
        // formatting output variables
        {
          input: {},
          local: {
            permissions: bh.local.permissions
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_cEz8hSoPUNttfLPG');
    }
  }

  async getReportingusers(...others) {
    try {
      var bh = {
        input: {},
        local: {
          reportingUsers: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_Sdu6Y2glQIgTKAyt(bh);
      //appendnew_next_getReportingusers
      return (
        // formatting output variables
        {
          input: {},
          local: {
            reportingUsers: bh.local.reportingUsers
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bxGMiR2d4Vvb04Fl');
    }
  }

  //appendnew_flow_userservice_Start

  //__client_service_designer_class_variable_declaration__user
  user: any;
  async sd_HdfTozx84Qn3NmtC(bh) {
    try {
      this.user = bh.input.user;
      //appendnew_next_sd_HdfTozx84Qn3NmtC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HdfTozx84Qn3NmtC');
    }
  }

  async sd_54NXSwKPoTSnLvzw(bh) {
    try {
      bh.local.user = this.user;
      //appendnew_next_sd_54NXSwKPoTSnLvzw
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_54NXSwKPoTSnLvzw');
    }
  }

  async sd_Viylh6ey75FSdeEj(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(`users`);
      bh.local.url = outputVariables.local.url;

      bh = await this.setPageQueryData(bh);
      //appendnew_next_sd_Viylh6ey75FSdeEj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Viylh6ey75FSdeEj');
    }
  }

  async setPageQueryData(bh) {
    try {
      bh.local.q = {
        pageSize: bh.input.pageSize,
        pageOffset: bh.input.pageOffset,
        ...(Array.isArray(bh.input.sortBy)
          ? {
              sortBy: bh.input.sortBy
                .map(c => `${c.sortKey} ${c.sortValue}`)
                .toString()
            }
          : undefined)
      };
      bh = await this.sd_njANizHBGThHM2Jw(bh);
      //appendnew_next_setPageQueryData
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MTV6bmA3aotrPb7F');
    }
  }

  async sd_njANizHBGThHM2Jw(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: bh.local.q,
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_njANizHBGThHM2Jw
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_njANizHBGThHM2Jw');
    }
  }

  async sd_O5PouCZU1sduiUDj(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(`user`);
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_Vsi4vmFz2OBIurCC(bh);
      //appendnew_next_sd_O5PouCZU1sduiUDj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_O5PouCZU1sduiUDj');
    }
  }

  async sd_Vsi4vmFz2OBIurCC(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_Vsi4vmFz2OBIurCC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Vsi4vmFz2OBIurCC');
    }
  }

  async sd_jCelt94DI8e3eUPQ(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `user/${bh.input.userid}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_xukk9UPLXNGVUwEL(bh);
      //appendnew_next_sd_jCelt94DI8e3eUPQ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jCelt94DI8e3eUPQ');
    }
  }

  async sd_xukk9UPLXNGVUwEL(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.body
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_xukk9UPLXNGVUwEL
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_xukk9UPLXNGVUwEL');
    }
  }

  async sd_mMIlMDIGADv18bH4(bh) {
    try {
      let outputVariables = await this.utilservice.constructBaseUrl(
        `user/reportingto/${bh.input.userid}`
      );
      bh.local.url = outputVariables.local.url;

      bh = await this.sd_6SH27CpuB9AlCRCa(bh);
      //appendnew_next_sd_mMIlMDIGADv18bH4
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mMIlMDIGADv18bH4');
    }
  }

  async sd_6SH27CpuB9AlCRCa(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.res = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_6SH27CpuB9AlCRCa
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_6SH27CpuB9AlCRCa');
    }
  }

  async sd_1psXbOzkNOtQDZfV(bh) {
    try {
      bh.local.permissions = this.user.srmPermissions;
      //appendnew_next_sd_1psXbOzkNOtQDZfV
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1psXbOzkNOtQDZfV');
    }
  }

  async sd_Sdu6Y2glQIgTKAyt(bh) {
    try {
      bh.local.reportingUsers = this.user.srmReportingUsers;
      //appendnew_next_sd_Sdu6Y2glQIgTKAyt
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Sdu6Y2glQIgTKAyt');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_userservice_Catch
}
