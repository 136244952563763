/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { CurrencyCode } from 'app/components/currencycodeselectComponent/currencycodeselect.component';
import { PhoneCountryCode } from 'app/components/phonecountrycodeselectComponent/phonecountrycodeselect.component';
import { DependencyConfig, UDFConfig } from 'app/components/udf_fieldComponent/udf_field.component';
import { UDFSectionConfig } from 'app/components/udf_sectionComponent/udf_section.component';
import { CacheKey } from 'app/constants';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';

@Injectable()
export class udfsyncService {

	cache: any = {};

	private _depFields = ['dependency_field_name', 'dependency_field_value', 'dependency_comparator', 'update_by_dependency_as'];
	fieldNameToFieldMap: { [key: string]: UDFConfig; };
	phoneCountryCodes: PhoneCountryCode[] = [];
	currencyCodes: CurrencyCode[] = [];

	setc(d: any, key: string) {
		this.cache[key] = d;
		return this.cache[key];
	}

	getc(key: string) {
		return this.cache[key];
	}

	updatec(d: any, key: string) {
		const o = this.cache[key] || {};
		this.cache[key] = Object.assign(o, d);
		return this.cache[key];
	}

	deletec(key) {
		this.cache[key] = null;
		delete this.cache[key];
	}

	refreshUDFDependencyConfig(d: UDFSectionConfig[]): { depFields: UDFConfig[], fieldNameToFieldMap: { [key: string]: UDFConfig; } } {
		const fields = d.flatMap(u => u.fields);
		this.fieldNameToFieldMap = U.mapFromArr(fields, 'field_name');
		this.setc(fields, CacheKey.depAllUdfConfigs);
		const depFields = this._rectifyDepFieldsConfig(fields).sort((f1, f2) => this.getDepMappingText(f1) < this.getDepMappingText(f2) ? -1 : 1);
		return ({
			depFields: depFields,
			fieldNameToFieldMap: this.fieldNameToFieldMap
		});
	}

	private _rectifyDepFieldsConfig(fields: UDFConfig[]) {
		const depFields = []
		fields.forEach((f) => {
			const validDep = this._validateDepConfig(f);
			if (!validDep) {
				this.removeUDFDepConfig(f);
			} else {
				depFields.push(f);
			}
		});
		return depFields;
	}

	removeUDFDepConfig(f: UDFConfig) {
		this._depFields.forEach(depField => {
			// TODO: @sankarshanaj - should it be `delete`d or be assigned `null` value?
			f[depField] = null;
		})
	}

	private _validateDepConfig(f: UDFConfig) {
		return this.fieldNameToFieldMap[f.dependency_field_name] &&
			f.dependency_field_name &&
			f.dependency_field_value &&
			f.dependency_comparator &&
			f.update_by_dependency_as;
	}

	private _extractDepConfigFromUDF(f: UDFConfig): DependencyConfig {
		return ({
			'dependency_field_name': f.dependency_field_name,
			'dependency_field_value': f.dependency_field_value,
			'dependency_comparator': f.dependency_comparator,
			'update_by_dependency_as': f.update_by_dependency_as,
		});
	}

	getDepMappingText(field: UDFConfig) {
		let depMapText = '';
		if (this.fieldNameToFieldMap?.[field?.dependency_field_name]) {
			depMapText = `${this.fieldNameToFieldMap[field.dependency_field_name].field_placeholder} -> ${field.field_placeholder}`;
		}
		return depMapText;
	}
}
