/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'app/functions/Validators';
import { contactservice } from 'app/sd-services/contactservice';
import { userservice } from 'app/sd-services/userservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Router } from '@angular/router';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-addcontact',
    templateUrl: './addcontact.template.html'
})

export class addcontactComponent extends NBaseComponent implements OnInit {

    addLeadFormGroup = this.fb.group({
        first_name: ['', Validators.required],
        last_name: [''],
        phone_no: [''],
        phone_country_code: [''],
        email_id: ['']
    });
    private currentUserId;
    isLeadFormInvalid = false;
    disableAddButton = false;
    constructor(private fb: FormBuilder,
        private pubsub: NPubSubService,
        private userS: userservice,
        private snackBar: MatSnackBar,
        private contactService: contactservice,
        private router: Router) {
        super();
    }

    ngOnInit() {
        this.addLeadFormGroup.controls.email_id.validator = Validators.email;
        this.addLeadFormGroup.setValidators([
            CustomValidators.oneOfControlRequired(
                this.addLeadFormGroup.get('phone_no'),
                this.addLeadFormGroup.get('email_id')
            )
            ,CustomValidators.phoneNumberAndCountryCode(
                'phone_country_code',
                'phone_no'
            )
        ]);
        
        this.currentUserId = this.userS.user.srmUserid;
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' })
    }

    add() {

        if (this.addLeadFormGroup.valid) {
            this.disableAddButton = true;
            this.contactService.createContact(this.addLeadFormGroup.value.first_name,
                this.addLeadFormGroup.value.last_name,
                this.addLeadFormGroup.value.phone_no,
                this.addLeadFormGroup.value.phone_country_code,
                this.addLeadFormGroup.value.email_id,
                this.currentUserId)
                .then((result: {
                    input: {}
                    local: {
                        result: {
                            data: any[],
                            message: string
                        }
                    }
                }) => {
                    this.snackBar.open(result.local.result.message, 'OK', {
                        duration: 3000
                    });
                    this.close();
                    this.pubsub.$pub('refreshstatslist');
                    if (this.router.url.includes('/home/contacts')) {
                        this.pubsub.$pub('refreshcontactlist');
                    }
                    this.disableAddButton = false;
                })
                .catch(error => {
                    this.snackBar.open(error.message, 'OK', {
                        duration: 3000
                    })
                    this.disableAddButton = false;
                });
        } else {
            this.isLeadFormInvalid = true;
        }
    }
}
