<div fxLayout="column " style="min-height: 100%;" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row wrap" fxFlex="calc(100% - 65px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column " class="width-100" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column " class="lr-padding add-contact-title sticky white-back add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" fxFlex="60" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/appointment_create.svg">
              <h2>
                EMAIL</h2>
            </div>
          </div>
          <div fxLayout="column wrap" fxFlex="40" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row wrap" style="width:100%" fxLayoutGap="1em" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false">
              <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
                <mat-icon (click)="close()">close</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" class="sample-csv" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          {{email[&apos;emailData&apos;][&apos;topic&apos;]}}</div>
      </div>
      <div fxLayout="column " class="email-border padding-top-1 lr-padding" fxFlex="100" fxLayoutGap="20px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="isRelpy? (isHide = true) : &apos;&apos;">
          <div fxLayout="column wrap" fxFlex="10" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row wrap" class="circle-dp" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
              <div>
                {{email[&apos;emailData&apos;][&apos;from&apos;].slice(0,1) || &apos;&apos;}}</div>
            </div>
          </div>
          <div fxLayout="column " fxFlex="90" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row wrap" fxLayoutAlign="center start" [fxShow]="true" [fxHide]="false">
              <div fxLayout="column wrap" fxFlex="60" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <div class="font-normal-size font-color-emial-item common-font-small">
                  {{email[&apos;emailData&apos;][&apos;from&apos;] || &apos;&apos;}}</div>
              </div>
              <div fxLayout="column wrap" fxFlex="40" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false">
                  <div class="font-normal-size font-color-emial-item small-font">
                    {{email[&apos;emailData&apos;][&apos;date&apos;] | date:&quot;HH:mm a&quot; }}</div><img imgSrc="Web/Icons/reply.svg" style="align-self: start" (click)="replyMail()" class="padding-top-1px">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [innerHtml]="email[&apos;emailData&apos;][&apos;html&apos;]" class="font-normal-size small-font font-color-emial-item">
          <!-- {{email_content}} -->
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row " class="width-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="isRelpy">
    <bh-newemail class="width-100" *ngIf="!false" [emailData]="replyData"></bh-newemail>
  </div>
  <div fxLayout="row " class="margin-top-1 lr-padding pad-bottom" fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" *ngIf="!isRelpy">
    <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="replyMail()" [disabled]="disableImportButton">Reply</button></div>
    <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-action-button" (click)="close()" type="button">Cancel</button></div>
  </div>
</div>