/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { srmappservice } from 'app/sd-services/srmappservice';
import { FormControl } from '@angular/forms';
import { tenantservice } from 'app/sd-services/tenantservice';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-phonecountrycodeselect',
	templateUrl: './phonecountrycodeselect.template.html'
})

export class phonecountrycodeselectComponent extends NBaseComponent implements OnInit {


	@Input('phoneCountryCodeFormControl') fc = new FormControl('');
	@Input('placeholder') placeholder = '';
	@Input('options') phoneCountryCodes: PhoneCountryCode[] = [];
	@Input('valueMapsTo') vMap = '';
	@Input('viewMapsTo') vwMap = '';

	constructor(private srmappS: srmappservice,
		private tenantS: tenantservice) {
		super();
	}

	async ngOnInit() {
		await this.getPhoneCountryCodes();
		this.phoneCountryCodes.unshift({
			country: '',
			nationality: '',
			phone_country_code: ''
		})
	}

	async getPhoneCountryCodes() {
		try {
			this.phoneCountryCodes = (await this.srmappS.getPhoneCountryCodes()).local.phonecountrycodes.filter((p: PhoneCountryCode) => p.phone_country_code);
			const tenantSettings = (await this.tenantS.getTenantSettings()).local.tenantSettings;
			// if (!this.fc.value && tenantSettings && tenantSettings.PHONE_COUNTRY_CODE) {
			// 	this.fc.setValue(tenantSettings.PHONE_COUNTRY_CODE);
			// } else {
			// 	console.log('No default country code set for the tenant');
			// }
		} catch (e) {
			console.error(e);
		}
	}
}

export interface PhoneCountryCode {
	country: string,
	nationality: string,
	phone_country_code: string
}
