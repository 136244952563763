/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

import { FormControl } from '@angular/forms';
import { srmappservice } from 'app/sd-services/srmappservice';
import { tenantservice } from 'app/sd-services/tenantservice';
import { CurrencyCode } from '../currencycodeselectComponent/currencycodeselect.component';
import { of, Observable } from 'rxjs';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dyncurrencytypeauto',
    templateUrl: './dyncurrencytypeauto.template.html'
})

export class dyncurrencytypeautoComponent extends NBaseComponent implements OnInit {
    @Input('currencyCodeformControl') fc = new FormControl('');
    @Input('placeholder') placeholder = '';
    required = false;
    currencies: CurrencyCode[] = [];
    currenciesFiltered: Observable<Array<
        {
            country: string
            currency_code: string
            currency_name: string
            currency_symbol: string
            currencyid: number
        }>>;
    fcDisplay: FormControl = new FormControl();
    constructor(
        private srmappS: srmappservice,
        private tenantS: tenantservice
    ) {
        super();
    }

    ngOnInit() {
        this.fcDisplay.valueChanges.subscribe((searchInput: string) => {
            if (searchInput.length > 0) {
                this.currenciesFiltered = of(this.currencies.filter(
                    (item) => item && (
                        (item.currency_name && item.currency_name.toLowerCase().startsWith(searchInput.toLowerCase())) ||
                        (item.currency_code && item.currency_code.toLowerCase().startsWith(searchInput.toLowerCase())) ||
                        (item.country && item.country.toLowerCase().startsWith(searchInput.toLowerCase())))

                ));
            } else {
                this.currenciesFiltered = of(this.currencies);
            }
        });
        this.getPhoneCountryCodes().then(() => {
            this.currenciesFiltered = of(this.currencies);
        })

    }
    async getPhoneCountryCodes() {
        try {
            this.currencies = (await this.srmappS.getCurrencyCodes()).local.currencycodes.filter((p: CurrencyCode) => p.currency_code);

            const tenantSettings = (await this.tenantS.getTenantSettings()).local.tenantSettings;
            if (!this.fc.value && tenantSettings && tenantSettings.CURRENCY_CODE) {
                this.fc.setValue(parseInt(tenantSettings.CURRENCY_CODE));
                let record = this.currencies.find((item) => {
                    return item.currencyid === parseInt(tenantSettings.CURRENCY_CODE)
                });
                this.fcDisplay.setValue(this.displayFn(record));
            } else {
                console.log('No default currency code set for the tenant');
            }
        } catch (e) {
            console.error(e);
        }
    }

    selectedOption(selectedValue) {
        this.fc.setValue(selectedValue);
    }
    displayFn(selectedValue): string {

        if (!selectedValue
        ) {
            return '';
        }
        if (typeof (selectedValue) === 'string') {
            return selectedValue as string;
        }
        return selectedValue.currency_code + ' ' + selectedValue.currency_name;
    }
}
