/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import * as _moment from 'moment';
import { appointmentservice } from 'app/sd-services/appointmentservice';
import { taskservice } from 'app/sd-services/taskservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { cacheservice } from 'app/sd-services/cacheservice';
import { summaryservice } from 'app/sd-services/summaryservice';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dailysummary',
    templateUrl: './dailysummary.template.html'
})

export class dailysummaryComponent extends NBaseComponent implements OnInit {
    summaryListArr = [];
    currentDate: Date = new Date();
    startDate;
    endDate;
    date_value;
    appointmentArr = [];
    taskArr = [];
    dealStageArr = [];
    dueTask = [];
    tempArr = [];
    appointmentObj = {};
    taskObj = {};
    dueTaskObj = {};
    pendingObj = {};
    feedbackArr = [];
    pendingArr = [];
    activeObj = {};
    amountObj = {};
    private taskSub;
    private appointmentSub;
    private feedbackSub;
    constructor(
        private appointmentS: appointmentservice,
        private taskS: taskservice,
        private pubsub: NPubSubService,
        private cacheService: cacheservice,
        private summaryS: summaryservice,
        public label: labelService,
        public taskService: taskservice) {
        super();
        this.getSummaryTitles()
        this.getDailySummary();
        this.summaryListArr = [this.appointmentObj, this.taskObj, this.dueTaskObj];
        this.feedbackArr = [this.pendingObj];
        this.dealStageArr = [this.activeObj, this.amountObj];
    }

    ngOnInit() {
        this.appointmentSub = this.pubsub.$sub('refreshappointmentslist', () => {
            this.getPendingFeedback();
            this.getAppointment();
        })

        this.taskSub = this.pubsub.$sub('refreshtasklist', () => {
            return Promise.all([
                this.getTask(),
                this.getOverdueTask()
            ])
        })
        this.feedbackSub = this.pubsub.$sub('getFeedbackevents', () => {
            this.getPendingFeedback();
            this.getAppointment();
        })
        this.feedbackSub = this.pubsub.$sub('deal-added-event', () => {
            this.getDealStages();
        })
    }

    getDailySummary() {
        this.getAppointment();
        this.getPendingFeedback();
        this.getDealStages();
        this.getTask();
        this.getOverdueTask();
    }

    getSummaryTitles() {
        this.appointmentObj['title'] = "TODAY'S APPOINTMENTS";
        this.appointmentObj['image_name'] = "no_appointment";
        this.taskObj['title'] = "TODAY'S TASKS";
        this.taskObj['image_name'] = "no_task";
        this.dueTaskObj['title'] = "OVERDUE TASKS";
        this.dueTaskObj['image_name'] = "no_overdue";
        this.pendingObj['title'] = "PENDING FEEDBACK";
        this.pendingObj['image_name'] = "no_feedback";
        this.activeObj['title'] = 'ACTIVE ' + this.label.capitalize(this.label.deal) + ' STAGE SUMMARY';
        this.amountObj['title'] = this.label.capitalize(this.label.deal) + ' AMOUNT BY STAGES';
    }

    private async getAppointment() {
        this.startDate = new Date();
        this.startDate.setHours(0, 0, 0, 0);
        this.endDate = new Date();
        this.endDate.setHours(23, 59, 59, 999);
        let result = await this.appointmentS.getAppointments(_moment(this.startDate).format('YYYY-MM-DD HH:mm:00Z'),
            _moment(this.endDate).format('YYYY-MM-DD HH:mm:00Z'))
        if (result && result.local.appointmentData) {
            this.appointmentArr = [];
            result.local.appointmentData.filter((ele) => {
                let appointmentTempObj = {};
                appointmentTempObj = Object.assign({}, ele);
                appointmentTempObj['event_title'] = ele['event_title'];
                appointmentTempObj['contact_name'] = ele['contact_first_name'] + ' ' + ele['contact_last_name'];
                appointmentTempObj['deal_name'] = ele['deal_name'];
                appointmentTempObj['start_time'] = ele['start_time'];
                appointmentTempObj['end_time'] = ele['end_time'];
                this.appointmentArr.push(appointmentTempObj);
            })

            this.appointmentObj['type_name'] = 'appointment';
            this.appointmentObj['image_name'] = "no_appointment";
            this.appointmentObj['title'] = "TODAY'S APPOINTMENTS";
            this.appointmentObj['list'] = this.appointmentArr;
            this.summaryListArr = [this.appointmentObj, this.taskObj, this.dueTaskObj];
            return this.appointmentArr;
        }

    }
    private async getTask() {
        this.startDate = new Date();
        this.startDate.setHours(0, 0, 0, 0);
        this.endDate = new Date();
        this.endDate.setHours(23, 59, 59, 999);
        let result = await this.taskS.getTask(_moment(this.startDate).format('YYYY-MM-DD HH:mm:00Z'),
            _moment(this.endDate).format('YYYY-MM-DD HH:mm:00Z'))
        if (result && result.local.taskData.data) {
            this.taskArr = [];
            result.local.taskData.data.filter((ele) => {
                let taskObj = {};
                taskObj = Object.assign({}, ele);
                taskObj['event_title'] = ele['task_title'];
                taskObj['contact_name'] = ele['contact_name'];
                taskObj['deal_name'] = ele['deal_name'];
                taskObj['start_time'] = ele['due_date'];
                taskObj['start_time'] = ele['due_date'];
                this.taskArr.push(taskObj);
            });
            this.taskObj['type_name'] = 'task';
            this.taskObj['image_name'] = "no_task";
            this.taskObj['title'] = "TODAY'S TASKS";
            this.taskObj['list'] = this.taskArr;
            this.tempArr = [];
            this.summaryListArr = [this.appointmentObj, this.taskObj, this.dueTaskObj];
        }
    }

    private async getOverdueTask() {
        let result = await this.summaryS.getOverdueTask(_moment.utc(new Date()).format());
        if (result && result.local.overdueTaskData) {
            this.dueTask = [];
            result.local.overdueTaskData.data.filter((ele) => {
                let taskObj = {};
                taskObj = Object.assign({}, ele);
                taskObj['event_title'] = ele['task_title'];
                taskObj['contact_name'] = ele['contact_name'];
                taskObj['deal_name'] = ele['deal_name'];
                taskObj['start_time'] = ele['due_date'];
                this.dueTask.push(taskObj);
            })
            this.dueTaskObj['type_name'] = 'due_task';
            this.dueTaskObj['image_name'] = "no_overdue";
            this.dueTaskObj['title'] = "OVERDUE TASKS";
            this.dueTaskObj['list'] = this.dueTask;
            this.tempArr = [];
            this.summaryListArr = [this.appointmentObj, this.taskObj, this.dueTaskObj];
        }
    }

    async setArrayObj(title, list, type_name) {
        let temp = {};
        temp['type_name'] = type_name;
        temp['title'] = title;
        temp['list'] = list;
        this.summaryListArr.push(temp);
    }

    private async getPendingFeedback() {
        let result = await this.summaryS.getPendingEvents(_moment.utc(new Date()).format());
        this.pendingObj['type_name'] = 'event';
        this.pendingObj['image_name'] = "no_feedback";
        this.pendingObj['title'] = "PENDING FEEDBACK";
        this.pendingObj['list'] = [];
        this.feedbackArr = [this.pendingObj];
        if (result && result.local.pendingEventsData && result.local.pendingEventsData.data) {
            this.pendingArr = [];
            result.local.pendingEventsData.data.filter((ele) => {
                let feedbackTempObj = {};
                if (ele.hasOwnProperty('activitycallid')) {
                    feedbackTempObj = Object.assign({}, ele);
                    feedbackTempObj['event_title'] = (() => {
                        if (ele['to_phone_country_code'] &&
                            ele['to_phone_country_code'].length > 0 &&
                            ele['to_phone_number'] &&
                            ele['to_phone_number'].length > 0
                        ) {
                            let country_code_size = ele['to_phone_country_code'].length;
                            let phone_number = ele['to_phone_number'].substring(
                                country_code_size - 1,
                                ele['to_phone_number'].length);
                            return 'Dialed Number: ' + ele['to_phone_country_code'] + ' ' + phone_number;
                        }
                        return "";
                    })();
                    feedbackTempObj['contact_name'] = ele['contact_first_name'] + ' ' + ele['contact_last_name'];
                    feedbackTempObj['deal_name'] = ele['deal_name'];
                    feedbackTempObj['start_time'] = ele['created_at'];
                    this.pendingArr.push(feedbackTempObj);
                }
                else if (ele.hasOwnProperty('activityeventid')) {
                    feedbackTempObj = Object.assign({}, ele);
                    feedbackTempObj['event_title'] = ele['event_title'];
                    feedbackTempObj['contact_name'] = ele['contact_first_name'] + ' ' + ele['contact_last_name'];
                    feedbackTempObj['deal_name'] = ele['deal_name'];
                    feedbackTempObj['start_time'] = ele['start_time'];
                    feedbackTempObj['end_time'] = ele['end_time'];
                    this.pendingArr.push(feedbackTempObj);
                }
                this.pendingObj['type_name'] = 'event';
                this.pendingObj['image_name'] = "no_feedback";
                this.pendingObj['title'] = "PENDING FEEDBACK";
                this.pendingObj['list'] = this.pendingArr;
                this.feedbackArr = [this.pendingObj];
            })
        }
        return this.feedbackArr;
    }

    private async getDealStages() {
        let result = await this.summaryS.getActiveDeal();
        if (result && result.local.activeData) {
            let tempObj = {};
            tempObj['title'] = 'ACTIVE ' + this.label.capitalize(this.label.deal) + ' STAGE SUMMARY';
            tempObj['name'] = 'Total Count';
            tempObj['count'] = result.local.activeData['count'];
            tempObj['list'] = result.local.activeData['result'];
            this.activeObj = tempObj;
        }
        let tempArr = await this.summaryS.getDealAmount();
        if (tempArr && tempArr.local.amountData) {
            let tempObj = {};
            tempObj['title'] = this.label.capitalize(this.label.deal) + ' AMOUNT BY STAGES';
            tempObj['name'] = 'Total Amount';
            tempObj['count'] = tempArr.local.amountData['totalAmount'];
            tempObj['list'] = tempArr.local.amountData['result'];
            this.amountObj = tempObj;
        };
        this.dealStageArr = [this.activeObj, this.amountObj];

    }

    convertDate(value) {
        this.date_value = new Date(value);
        var dd = String(this.date_value.getDate()).padStart(2, '0');
        var mm = String(this.date_value.getMonth() + 1).padStart(2, '0');
        var yyyy = this.date_value.getFullYear();

        this.date_value = yyyy + '-' + mm + '-' + dd;
        return this.date_value;
    }

    async viewSummaryDetails(value: any) {
        if (value['activitytaskid']) {
            // TODO: No need to construct this object. Directly send the `value` for the form
            var tempObj = {
                title: value['task_title'],
                description: value['task_description'],
                duedate: new Date(value['due_date']),
                duetime: this.hourwithAMPM(_moment(value['due_date'])),
                completed: (value['task_status'] == true) ? 'Yes' : 'No',
            }
            let taskObj = {
                taskEventObj: tempObj,
                taskIdObj: value
            };
            await this.cacheService.setc(taskObj, 'EDIT_TASK_FORM');
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'ADD_TASK'
            })
        }

        if (value['activityeventid']) {
            // TODO: No need to construct this object. Directly send the `value` for the form
            var appointmentEventObj = {
                appointment_feedback_id: value['appointment_feedback_id'],
                contactText: "",
                dealText: "",
                end_date: value['end_time'],
                end_time: this.hourwithAMPM(_moment(value['end_time'])),
                event_description: value['event_description'],
                event_location: value['event_location'],
                event_title: value['event_title'],
                feedback_notes: value['feedback_notes'],
                location: "",
                meeting_url: "",
                room_id: value['room_id'],
                start_date: value['start_time'],
                start_time: this.hourwithAMPM(_moment(value['start_time']))
            }
            let appointmentObj = {
                appointmentEventObj: appointmentEventObj,
                appointmentIdObj: value
            };
            await this.cacheService.setc(appointmentObj, 'EDIT_APPOINTMENT_FORM');

            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'SCHEDULE_APPOINTMENT'
            })
        }
    }

    async viewFeedbackDetails(value) {
        if (value.hasOwnProperty('activityeventid')) {
            const formKey = 'FEEDBACK_FORM_APPOINTMENTS_ACTIVITY';
            await this.cacheService.setc(value, formKey)
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: formKey
            });
        } else if (value.hasOwnProperty('activitycallid')) {
            const formKey = 'FEEDBACK_FORM_CALL_ACTIVITY';
            await this.cacheService.setc(value, formKey)
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: formKey
            });
        }
    }

    hourwithAMPM(inputDate: _moment.Moment) {
        let d = _moment();
        if (inputDate) {
            d = _moment(inputDate);
        }
        let ampm = (d.hour() >= 12) ? "pm" : "am";
        let hours = (d.hour() > 12) ? d.hour() - 12 : d.hour();
        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.minute() < 10 ? '0' + d.minute() : d.minute()) + ampm;
    }

    ngOnDestroy() {

        if (this.taskSub) {
            this.taskSub.unsubscribe();
        }
        if (this.appointmentSub) {
            this.appointmentSub.unsubscribe();
        }
        if (this.feedbackSub) {
            this.feedbackSub.unsubscribe();
        }
        if (this.summaryListArr) {
            this.summaryListArr = [];
        }
        if (this.dealStageArr) {
            this.dealStageArr = [];
        }
        if (this.feedbackArr) {
            this.feedbackArr = [];
        }
    }
    setTaskDone(eventObj) {
        let { taskid, task_status } = eventObj;
        let foundTaskObj;
        if (this.taskObj && this.taskObj['list']) {
            foundTaskObj = this.taskObj['list'].find((item) => item.activitytaskid === taskid);
        }
        let dueTaskObj;
        if (this.dueTaskObj && this.dueTaskObj['list']) {
            dueTaskObj = this.dueTaskObj['list'].find((item) => item.activitytaskid === taskid);
        }
        this.taskService.updateTaskstatus(taskid, { task_status }).then(res => {
            this.getTask().then(()=>{});
            this.getOverdueTask().then(()=>{});
        }).catch(err => {
            // rollback changes
            if (foundTaskObj) {
                foundTaskObj.task_status = !foundTaskObj.task_status;
            }
            if (dueTaskObj) {
                dueTaskObj.task_status = !dueTaskObj.task_status;
            }
        });
    }
}
