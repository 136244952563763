<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <bh-sidenavformheader [title]="title" [titleIconUrl]="&apos;assets/Web/Icons/contact_label.svg&apos;" (closeClicked)="close()"></bh-sidenavformheader>
  <form style="height: calc(100% - 115px);">
    <bh-formsectiontitle [sectionName]="sectionName"></bh-formsectiontitle>
    <div fxLayout="column " style="height: calc(100% - 50px);" class="overflow-y-only height-100 lr-padding" fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column " class="padding-right-1 overflow-y-only height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <bh-optionsconfigurator class="margin-bottom-1" [optionsFormControl]="formGroup.controls.contact_label" [optionPlaceholder]="&apos;Enter label&apos;" [initialOptions]="contactLabels" [addBtnLabel]="optionsAddBtnLabel" [iem]="true" [labelPath]="&apos;label&apos;" (optionSaved)="updateOption($event)" (optionDeleted)="deleteOption($event)" (optionsOrdered)="updateOptionOrder($event)" [disableActions]="disableActions"></bh-optionsconfigurator>
      </div>
    </div>
  </form>
  <div fxLayout="row wrap" class="tb-padding lr-padding" fxLayoutGap="1rem" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-action-button" (click)="close()" [disabled]="disableActions">Close</button></div>
</div>