/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ElementRef, ViewChild } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Subject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import { leadservice } from 'app/sd-services/leadservice';
import { appointmentservice } from 'app/sd-services/appointmentservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { taskservice } from 'app/sd-services/taskservice';
import * as _moment from 'moment';
import { cacheservice } from 'app/sd-services/cacheservice';
import { Moment } from 'moment';
import { differenceInMinutes, startOfDay, startOfHour } from 'date-fns';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './calendar.template.html',
})
/* TODO
** Change time 00:00 am to 12:00 am 
*/
export class calendarComponent extends NBaseComponent implements OnInit {
    @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLElement>;
    viewDate: Date = new Date();
    calendarView = 'month';
    calenderWHeader = new Date().getDate();
    calenderMonth = new Date().getMonth();
    startDate;
    endDate;
    appointmentData = [];
    appointmentSub;
    taskData = [];
    date_value;
    calendarEvents: CalendarEvent[] = [];

    // for refreshing calendar model/view
    refresh: Subject<any> = new Subject();

    constructor(private leadS: leadservice,
        private appointmentS: appointmentservice,
        private pubsub: NPubSubService,
        private taskS: taskservice,
        private cdr: ChangeDetectorRef,
        private cacheService: cacheservice) {
        super();
    }

    ngOnInit() {
        this.appointmentSub = this.pubsub.$sub('refreshevents')
            .subscribe(() => {
                this.getMonthValue();
            })
        this.refresh.next();
        this.getMonthValue();
        this.scrollToCurrentView();
    }

    viewChanged() {
        this.cdr.detectChanges();
        this.scrollToCurrentView();
      }

    private scrollToCurrentView() {
        if (this.calendarView ==  'week' || 'day') {
          const minutesSinceStartOfDay = differenceInMinutes(
            startOfHour(new Date()),
            startOfDay(new Date())
          );
          const headerHeight = this.calendarView === 'week' ? 60 : 0;
          this.scrollContainer.nativeElement.scrollTop =
            minutesSinceStartOfDay + headerHeight;
        }
      }

    resetCalendarDate() {
        if (this.calendarView == 'week') {
            this.weekChange(this.viewDate);
            this.scrollToCurrentView()
        } else if (this.calendarView == 'day') {
            this.weekHeader(this.viewDate);
            this.scrollToCurrentView()
        } else {
            this.getMonthValue();
        }
    }
    getCurrentDate() {
        this.viewDate = new Date();
        if (this.calendarView == 'day') {
            this.weekHeader(this.viewDate);
        }
    }

    getNextValue(value) {
        if (value == 'Day' || value == 'Week') {
            if (Date.parse(this.viewDate.toJSON()) > Date.parse(this.endDate.toJSON())) {
                this.getMonthValue();
            }
        } else {
            if (this.viewDate.getMonth() >= this.endDate.getMonth()) {
                this.getMonthValue();
            }
        }
    }

    getPreviousValue(value) {
        if (value == 'Day' || value == 'Week') {
            if (Date.parse(this.viewDate.toJSON()) < Date.parse(this.startDate.toJSON())) {
                this.getMonthValue();
            }
        } else {
            if (this.viewDate.getMonth() <= this.startDate.getMonth()) {
                this.getMonthValue();
            }
        }
    }

    getMonthValue() {
        var startValue = new Date(`${this.viewDate.getFullYear()}-${this.getCurrentMonth(this.viewDate)}-01`);
        startValue.setDate(startValue.getDate() - startValue.getDay());
        this.startDate = new Date(startValue.setDate(startValue.getDate() - startValue.getDay()));
        this.endDate = new Date(`${this.viewDate.getFullYear()}-${this.getCurrentMonth(this.viewDate)}-01`);
        this.endDate.setDate(1)
        this.endDate.setHours(23, 59, 59, 999)
        this.endDate.setMonth(this.endDate.getMonth() + 1)
        this.endDate.setDate(this.endDate.getDate() - 1)
        this.endDate.setDate(this.endDate.getDate() - (this.endDate.getDay() - 6))
        this.getTask();
        this.getAppointment();

    }

    getCurrentMonth(date) {
        var month = date.getMonth() + 1;
        return month < 10 ? '0' + month : '' + month;
    }

    getAppointment() {
        this.appointmentS.getAppointments(_moment(this.startDate).format('YYYY-MM-DD HH:mm:00Z'),
            _moment(this.endDate).format('YYYY-MM-DD HH:mm:00Z'))
            .then(result => {
                if (result && result.local.appointmentData) {
                    this.appointmentData = result.local.appointmentData;

                    this.appointmentData.filter((ele) => {
                        ele['title'] = ele['event_title'];
                        ele['date'] = new Date();
                        ele['start'] = new Date(ele['start_time']);
                        ele['end'] = new Date(ele['end_time']);
                        ele['color'] = {
                            secondary: "#8d8ded"
                        }
                    })
                } else {
                    this.appointmentData = [];
                }
                this.updateCalendar();
            });
    }

    getTask() {
        this.taskS.getTask(_moment(this.startDate).format('YYYY-MM-DD HH:mm:00Z'),
            _moment(this.endDate).format('YYYY-MM-DD HH:mm:00Z'))
            .then(result => {
                if (result && result.local.taskData.data) {
                    this.taskData = result.local.taskData.data;
                    this.taskData.filter((ele) => {
                        ele['title'] = ele['task_title'];
                        ele['date'] = new Date();
                        ele['start'] = this.getDateTime(ele['due_date']);
                        ele['end'] = new Date(ele['due_date']);
                        ele['color'] = {
                            secondary: "#0f9d7c"
                        }
                    })
                } else {
                    this.taskData = [];
                }
                this.updateCalendar();
            });
    }

    getDateTime(date) {
        let val = new Date(date);
        return new Date(val.getTime() - 30 * 60000);
    }

    updateCalendar() {
        this.calendarEvents = [...this.taskData, ...this.appointmentData];
    }


    convertDate(value) {
        this.date_value = new Date(value);
        var dd = String(this.date_value.getDate()).padStart(2, '0');
        var mm = String(this.date_value.getMonth() + 1).padStart(2, '0');
        var yyyy = this.date_value.getFullYear();

        this.date_value = yyyy + '-' + mm + '-' + dd;
        return this.date_value;
    }

    weekHeader(data) {
        this.calenderWHeader = data.getDate();
        this.calendarView = 'day';
        this.viewDate = data;
    }
    hourwithAMPM(inputDate: Moment) {
        let d = _moment();
        if (inputDate) {
            d = _moment(inputDate);
        }
        let ampm = (d.hour() >= 12) ? "pm" : "am";
        let hours = (d.hour() > 12) ? d.hour() - 12 : d.hour();
        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.minute() < 10 ? '0' + d.minute() : d.minute()) + ampm;
    }
    async handleEvent(value: any) {
        if (value['activitytaskid']) {
            var tempObj = {
                title: value['task_title'],
                description: value['task_description'],
                duedate: new Date(value['due_date']),
                duetime: this.getTime(new Date(value['due_date'])),
                completed: (value['task_status'] == true) ? 'Yes' : 'No',
            }
            let taskObj = {
                taskEventObj: tempObj,
                taskIdObj: value
            };
            await this.cacheService.setc(taskObj, 'EDIT_TASK_FORM');
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'ADD_TASK'
            })
        }

        if (value['activityeventid']) {
            var appointmentEventObj = {
                appointment_feedback_id: value['appointment_feedback_id'],
                contactText: "",
                dealText: "",
                end_date: value['end'],
                end_time: this.getTime(new Date(value['end'])),
                event_description: value['event_description'],
                event_location: value['event_location'],
                event_title: value['event_title'],
                feedback_notes: value['feedback_notes'],
                location: "",
                meeting_url: "",
                room_id: value['room_id'],
                start_date: value['start'],
                start_time: this.getTime(new Date(value['start']))
            }
            let appointmentObj = {
                appointmentEventObj: appointmentEventObj,
                appointmentIdObj: value
            };
            await this.cacheService.setc(appointmentObj, 'EDIT_APPOINTMENT_FORM');

            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'SCHEDULE_APPOINTMENT'
            })
        }
    }

    getTime(date) {
        return this.hourwithAMPM(_moment(date));
    }

    weekChange(data) {
        this.calenderWHeader = data.getDate();
        this.viewDate = data;
        this.getMonthValue();
    }
}
