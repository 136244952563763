/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CacheKey, PubSubChannel } from 'app/constants';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { CustomValidators } from 'app/functions/Validators';
import { srmappservice } from 'app/sd-services/srmappservice';
import { labelService } from 'app/services/label/label.service';
import { udfsyncService } from 'app/services/udfsync/udfsync.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { PhoneCountryCode } from '../phonecountrycodeselectComponent/phonecountrycodeselect.component';
import { UDFConfig, UDFSectionFieldChangedEventData } from '../udf_fieldComponent/udf_field.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-form_udf_dependency',
	templateUrl: './form_udf_dependency.template.html'
})

export class form_udf_dependencyComponent extends NBaseComponent implements OnInit {

	title = '';
	editData: UDFConfig = null;
	udfFields: UDFConfig[] = null
	mode: 'ADD' | 'EDIT';
	dependencyOperations = [
		{ value: 'hidden', display: 'Hide Field', hide: false },
		{ value: 'required', display: 'Make this a mandatory field', hide: false }
	]
	operators = [
		{ value: 'eq', display: 'Equal to' },
		{ value: 'neq', display: 'Not Equal To' },
	]
	fg: FormGroup;
	controllingFields: UDFConfig[] = [];
	dependentFields: UDFConfig[] = [];
	filteredControllingFieldValues: Observable<string[]>;
	selectedControllingValue = null;
	searchInputFC = new FormControl('');
	addLabel = '';

	nationalities: string[] = [];
	countries: string[] = [];
	fieldNameToFieldMap: { [key: string]: UDFConfig; };

	private _subs = new Subscription();
	private _controllingFieldTypes = { 'select': true, 'nationality': true, 'country': true };
	private _diableAddBtn: boolean;

	constructor(private _ps: NPubSubService,
		private _udfSync: udfsyncService,
		private _label: labelService,
		private srmappservice: srmappservice) {
		super();
		this._cacheData();
		this._createFg();
		this.filteredControllingFieldValues = this.searchInputFC.valueChanges
			.pipe(
				startWith(''),
				map(v => this.filterControllingValues(v))
			)
	}

	_cacheData() {
		this.editData = this._udfSync.getc(CacheKey.depUdfEditForm);
		this._udfSync.deletec(CacheKey.depUdfEditForm);
		this.udfFields = (<UDFConfig[]>this._udfSync.getc(CacheKey.depAllUdfConfigs)).filter(f => !f.default);
		this.fieldNameToFieldMap = this._udfSync.fieldNameToFieldMap;
		if (this.editData) {
			this.mode = 'EDIT';
			this.addLabel = 'Update';
		} else {
			this.mode = 'ADD';
			this.addLabel = 'Add';
		}
		this.nationalities = this._udfSync.phoneCountryCodes.filter(v => v.nationality).map(v => v.nationality);
		this.countries = this._udfSync.phoneCountryCodes.filter(v => v.country).map(v => v.country);
	}

	private _createFg() {
		const listDisabled = this.mode === 'EDIT';
		this.fg = new FormGroup(<UDFDepFormGroup>{
			field_name: new FormControl({ value: '', disabled: listDisabled }, Validators.required),
			dependency_field_name: new FormControl({ value: '', disabled: listDisabled }, Validators.required),
			dependency_field_value: new FormControl(''),
			dependency_comparator: new FormControl('eq', Validators.required),
			update_by_dependency_as: this._createDepOpControl(),
		});
		this.fg.get('dependency_field_value').setValidators(Validators.compose([Validators.required, this.isExistingDepVal.bind(this)]));
		// this.fg.get('dependency_field_value').updateValueAndValidity();
	}

	ngOnInit() {
		this._setListData();
		this._subsToDepOpChanges();
		this._subsToControllingFieldChange();
		this._subsForDependentFieldValChanges();
		this._remapToFCs();
	}

	private _setListData() {
		this.controllingFields = this.udfFields.filter(f => this._controllingFieldTypes[f.field_ui_type]);
		this.dependentFields = this.udfFields;
	}

	private _subsToControllingFieldChange() {
		this._subs.add(this.fg.get('dependency_field_name').valueChanges.subscribe(v => {
			this.dependentFields = this.udfFields.filter(f => f.field_name !== v);
			this.searchInputFC.setValue(this.searchInputFC.value); // ; this.fieldNameToFieldMap[v]?.field_options || [];
			this.fg.get('dependency_field_value').setValue(this.fg.get('dependency_field_value').value);
		}))
	}

	private _subsForDependentFieldValChanges() {
		this._subs.add(this.fg.get('field_name').valueChanges.subscribe(v => {
			this.controllingFields = this.udfFields.filter(f => f.field_name !== v && this._controllingFieldTypes[f.field_ui_type]);
		}))
	}

	private _subsToDepOpChanges() {
		const fa = <FormArray>this.fg.get('update_by_dependency_as');
		this._subs.add(fa.controls[0].valueChanges.subscribe((v: boolean) => {
			if (v === true) {
				fa.controls[1].setValue(false);
				this.dependencyOperations[1].hide = true;
			} else {
				this.dependencyOperations[1].hide = false;
			}
		}));
	}

	private _remapToFCs() {
		if (this.mode === 'EDIT') {
			this.fg.patchValue(this.editData)
			this.title = 'EDIT DEPENDENCY';
		} else {
			this.title = 'ADD DEPENDENCY';
		}
	}

	private _createDepOpControl(): FormArray {
		const ctrls = this.dependencyOperations.map(o => new FormControl(this.editData?.update_by_dependency_as === o.value));
		if (this.mode === 'EDIT') {
			this.editData.update_by_dependency_as = <any>ctrls.map(c => c.value);
		}
		return new FormArray(ctrls, CustomValidators.atleastNTruthy(1, ctrls));
	}

	get disableAddButton() {
		return this.fg.invalid || this._diableAddBtn;
	}

	get showValueSelector() {
		return this.fg.controls.dependency_field_name?.value;
	}

	get showOpSelector() {
		return this.fg.controls.dependency_field_name?.value && this.fg.controls.field_name?.value;
	}

	get searchInputPlaceholder() {
		return `Search ${this.fieldNameToFieldMap[this.fg.controls.dependency_field_name?.value]?.field_placeholder || ''}`;
	}

	get depPartHeaderLabel() {
		const field = this.fieldNameToFieldMap[this.fg.controls.field_name?.value];
		return (field && field.field_placeholder) ? `${field.field_placeholder}(${this._label.toTitleCase(field.field_ui_type || '')})` : '';
	}

	get controllingPartHeaderLabel() {
		return this.fieldNameToFieldMap[this.fg.controls.dependency_field_name?.value]?.field_placeholder || '';
	}

	toggleSelection(v: string, i: number) {
		const selectedControllingValue = v === this.fg.get('dependency_field_value').value ? null : v;
		this.fg.get('dependency_field_value').setValue(selectedControllingValue);
	}

	filterControllingValues(v?: string) {
		const options = this._getControllingFieldOptions();
		return !v ? options.filter(s => s.toLowerCase().includes(this.searchInputFC.value.toLowerCase?.())) :
			options.filter(s => s.toLowerCase().includes(v.toLowerCase?.()));
	}

	private _getControllingFieldOptions() {
		let options = [];
		let controllingField = this.fieldNameToFieldMap[this.fg.get('dependency_field_name').value];
		if (controllingField) {
			let controllingFieldUItype = controllingField.field_ui_type;
			switch (controllingFieldUItype) {
				case 'select':
					options = controllingField.field_options || [];
					break;
				case 'country':
					options = this.countries;
					break;
				case 'nationality':
					options = this.nationalities;
					break;
			}
		}
		return options;
	}

	add() {
		this._diableAddBtn = true;
		const v = U.JSONClone(this.fg.value);
		if (this.mode === 'EDIT') {
			v.dependency_field_name = this.editData.dependency_field_name;
			v.field_name = this.editData.field_name;
		}
		v.update_by_dependency_as = this.dependencyOperations[(<boolean[]>v.update_by_dependency_as).indexOf(true)]?.value;
		// May be use a different channel for updating dependencies instead, when there is time.
		this._ps.$pub(PubSubChannel.udfSectionFieldChanged, <UDFSectionFieldChangedEventData>{
			action: 'update',
			ufc: Object.assign(this.fieldNameToFieldMap[v.field_name], v)
		})
		this.close();
		this._diableAddBtn = false;
	}

	clear() {
		this.fg.reset(this.editData);
	}

	close() {
		this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
			ACTION: 'close'
		})
	}

	isExistingDepVal(control: AbstractControl): { nonExisting: boolean } | null {
		const options = this._getControllingFieldOptions();
		return options.includes?.(control.value) ? null : { nonExisting: true };
	}
}

type UDFDepFormGroup = { [key in keyof Partial<UDFConfig>]: FormControl | FormArray };