/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CacheKey, PubSubChannel } from 'app/constants';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { udfsyncService } from 'app/services/udfsync/udfsync.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { UDFConfig, UDFSectionFieldChangedEventData } from '../udf_fieldComponent/udf_field.component';
import { UDFSectionConfig } from '../udf_sectionComponent/udf_section.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-form_udf_field',
	templateUrl: './form_udf_field.template.html'
})

export class form_udf_fieldComponent extends NBaseComponent implements OnInit, OnDestroy {

	editData: UDFConfig;
	mode: 'ADD' | 'EDIT';
	title = '';
	fieldTypes = [];
	sectionData: UDFSectionConfig;
	field_sections: string[] = [];
	formGroup = new FormGroup({
		'field_section': new FormControl('', Validators.required),
		'field_ui_type': new FormControl(''),
		'field_placeholder': new FormControl(''),
		'field_is_required': new FormControl(false),
		'field_country_code_value': new FormControl(''),
		'field_country_value': new FormControl(''),
		'field_nationality_value': new FormControl(''),
		'field_currency_code_value': new FormControl(''),
		'field_options_value': new FormControl(''),
		'field_options': new FormControl('')
	});
	typeFieldMap: { [key: string]: FieldInfo } = {
		text: {},
		number: {},
		email: {},
		date: {},
		contactsearch: {},
		phonenumber: {
			fields: [
				{
					field: 'field_country_code_field',
					value: 'field_country_code_value',
					value_fc: 'field_country_code_value',
				}
			]
		},
		country: {
			fields: [
				{
					value: 'field_value',
					value_fc: 'field_country_value',
				}
			]
		},
		nationality: {
			fields: [
				{
					value: 'field_value',
					value_fc: 'field_nationality_value',
				}
			]
		},
		money: {
			fields: [
				{
					field: 'field_currency_code_field',
					value: 'field_currency_code_value',
					value_fc: 'field_currency_code_value'
				}
			]
		},
		select: {
			fields: [{
				options: 'field_options',
				options_fc: 'field_options',
				value: 'field_value',
				value_fc: 'field_options_value',
			}]
		},
	}
	field_ui_types: FieldUIType[] = <FieldUIType[]>Object.keys(this.typeFieldMap).sort();
	selectedType: FieldUIType;
	initialOpts = ['', ''];
	setOptionsFCErrors = false;
	everyConfig: UDFSectionConfig[];
	nullOpt = { value: '', display: 'None' };
	optValues: any[] = [];
	private _subs = new Subscription();
	private _disableAddBtn: boolean;

	constructor(private _ps: NPubSubService,
		private _udfSync: udfsyncService) {
		super();
		this._cacheData();
	}

	ngOnInit() {
		this._subscribeToUITypeChanges();
		this._remapToFormFields();
		this._subscribeToSectionChanges();
		this._subscribeToFieldOptionChanges();
	}

	private _subscribeToUITypeChanges() {
		this._subs.add(this.formGroup.get('field_ui_type').valueChanges.subscribe((v: FieldUIType) => {
			this.selectedType = v;
			if (v === 'select') {
				this.setOptionsFCErrors = true;
				this.formGroup.get('field_options').setValidators(Validators.required);
			} else {
				this.setOptionsFCErrors = false;
				this.formGroup.get('field_options').clearValidators();
			}
			this.formGroup.get('field_options').updateValueAndValidity();
		}));
	}

	private _subscribeToSectionChanges() {
		const sectionFC = this.formGroup.get('field_section');
		const uiType = this.formGroup.get('field_ui_type');
		if (this.mode === 'ADD') {
			this._subs.add(sectionFC.valueChanges.subscribe((v: FieldUIType) => {
				// trigger validation
				this.formGroup.get('field_placeholder').setValue(this.formGroup.get('field_placeholder').value);
			}));
		} else {
				 sectionFC.disable();
				// uiType.disable();
		}
	}

	private _subscribeToFieldOptionChanges() {
		this._subs.add(this.formGroup.get('field_options').valueChanges.subscribe(v => {
			this.optValues = U.JSONClone(v || []);
			this.optValues.push(this.nullOpt);
		}));
	}

	private _cacheData() {
		this.sectionData = this._udfSync.getc(CacheKey.udfAddFieldFormSectionInfo);
		this.everyConfig = this._udfSync.getc(CacheKey.entireUDFsConfig);
		this.editData = this._udfSync.getc(CacheKey.udfEditForm);
		this._udfSync.deletec(CacheKey.udfEditForm);
		this.field_sections = this._udfSync.getc(CacheKey.udfSectionNames).filter(Boolean);
	}

	private _remapToFormFields() {
		if (this.editData) {
			this.mode = 'EDIT';
			this.title = 'EDIT FIELD'
			this.formGroup.patchValue(this.editData);
			const f = this.typeFieldMap[this.editData.field_ui_type];
			if (f.fields) {
				f.fields.forEach(f => {
					if (f.value) {
						this.formGroup.controls[f.value_fc].setValue(this.editData[f.value]);
					}
					if (f.options) {
						this.initialOpts = this.editData[f.options];
					}
				})
			}
		} else {
			this.mode = 'ADD';
			this.title = 'ADD FIELD';
			this.formGroup.patchValue(this.sectionData);
		}
		this.formGroup.controls.field_placeholder.setValidators(Validators.compose([Validators.required, this.duplicateFieldPlaceholder.bind(this)]));
	}

	get disableSubmit() {
		return this.formGroup.invalid || this._disableAddBtn;
	}

	/*
	field_name: string,

	'field_placeholder': string,
	'field_section': string,
	'field_ui_type': string,
	'field_is_required': boolean,
	*/
	getFieldConfigForUIType(): UDFConfig {
		const fieldConfig = <UDFConfig>{};
		this._nullifyConditionalFields(fieldConfig);
		// Mandatory fields
		if (this.mode === 'ADD') {
			fieldConfig.field_name = U.generateUUID();
			fieldConfig.field_section = this.formGroup.get('field_section').value;
			fieldConfig.field_ui_type = this.formGroup.get('field_ui_type').value;
		} else {
			fieldConfig.field_name = this.editData.field_name;
			fieldConfig.field_section = this.editData.field_section;
			fieldConfig.field_ui_type = this.editData.field_ui_type;
		}
		const commonFields = ['field_placeholder', 'field_is_required'];
		commonFields.forEach(fn => fieldConfig[fn] = this.formGroup.value[fn]);
		// Conditional fields
		const conditionalFieldInfo = this.typeFieldMap[fieldConfig.field_ui_type];
		conditionalFieldInfo.fields?.forEach((conditionalField) => {
			if (conditionalField.field) {
				fieldConfig[conditionalField.field] = `${fieldConfig.field_name}_${conditionalField.field}`;
			}
			if (conditionalField.options) {
				fieldConfig[conditionalField.options] = this.formGroup.get(conditionalField.options_fc)?.value?.map?.(v => v.display);
			}
			if (conditionalField.value) {
				fieldConfig[conditionalField.value] = this.formGroup.get(conditionalField.value_fc)?.value;
			}
		});
		return fieldConfig;
	}

	private _nullifyConditionalFields(fieldConfig: any) {
		// nullify all field and values
		this.field_ui_types.forEach(t => {
			this.typeFieldMap[t].fields?.forEach(f => {
				if (f.field) {
					fieldConfig[f.field] = null;
				}
				if (f.options) {
					fieldConfig[f.options] = null;
				}
				if (f.value) {
					fieldConfig[f.value] = null;
				}
			})
		});
	}

	add() {
		this._disableAddBtn = true;
		if (this.formGroup.valid) {
			const fieldConfig = this.getFieldConfigForUIType();
			this._ps.$pub(PubSubChannel.udfSectionFieldChanged, <UDFSectionFieldChangedEventData>{
				action: this.mode === 'ADD' ? 'add' : 'update',
				ufc: fieldConfig,
			})
			this.close();
		}
		this._disableAddBtn = false;
	}

	close() {
		this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
			ACTION: 'close',
		})
	}

	duplicateFieldPlaceholder(control: AbstractControl): { duplicate: boolean } | null {
		const fields = this.everyConfig.find(v => v.field_section === this.formGroup.get('field_section').value).fields;
		for (let i = 0; i < fields.length; i++) {
			if (fields[i].field_placeholder === control.value && fields[i].field_name !== this.editData?.field_name) {
				return { duplicate: true }
			}
		}
		return null;
	}

	ngOnDestroy() {
		this._subs.unsubscribe();
	}
}

type FieldUIType = 'select' | 'text' | 'contactsearch' | 'date' | 'nationality' | 'email' | 'phonenumber' | 'country' | 'number' | 'money';
interface FieldInfo {
	fields?: FieldConfig[]
}

interface FieldConfig {
	field?: string,
	options?: string,
	options_fc?: string,
	value?: string,
	value_fc?: string,
}
