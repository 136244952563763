<div fxLayout="column " class="padding-0-1-1-1 background-primary border-radius" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="dealValue &amp;&amp; dealValue.length != 0">
    <div fxLayout="column wrap" fxFlex="48px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div [ngClass]="(!progressCheck) ? &apos;icon-disable&apos; : &apos;&apos; " [ngClass.xs]="&apos;deal-page-label&apos;" class="pagination-gt-xs common-pagination">
        <mat-paginator [length]="dealCount" [pageSize]="15" [pageSizeOptions]="[15, 25, 50, 100]" (page)="pageEvents($event)"></mat-paginator>
      </div>
    </div>
    <div fxLayout="column " class="common-lead-list-padding" fxFlex="calc(100% - 48px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="&apos;stage-list-card&apos;">
      <div fxLayout="row wrap" class="lead-list-header" fxFlex="48px" fxLayoutGap="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true" [ngClass]="(tabView == 1) ? &apos;team-list-header-gt-xs&apos; : &apos;&apos;">
          <div fxLayout="row wrap" fxFlex="20" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;lead-header-field-gt-xs&apos;" *ngFor="let val of dealHeader">
            <h3 class="color-view">
              {{val}}</h3>
          </div>
        </div>
      </div>
      <div fxLayout="column " class="overflow-y" fxFlex="calc(100% - 48px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
        <div fxLayout="column wrap" class="display-block leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let val of dealsArr | async" (click)="navigateToDeal(val.dealid)" [ngClass.xs]="&apos;menu-margin-xs&apos;">
          <div fxLayout="row wrap" class="cursor-pointer color-black" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="val" [ngClass.xs]="&apos;border-lightgray-1px border-radius-4&apos;">
            <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;" [ngClass.xs]="&apos;display-grid&apos;">
              <h3 class="text-ellipsis font-normal-size">
                <span *ngIf="val.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Name : </span>{{val.deal_name}}</span>
                <span *ngIf="!val.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Name : </span><span class="label-font-color">-------</span></span></h3>
            </div>
            <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
              <h3 class="text-ellipsis font-normal-size">
                <span *ngIf="val.currencyid || val.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Value : </span>{{val.currencyid | currencySymbol | async}} {{val.deal_value}}</span>
                <span *ngIf="!val.currencyid &amp;&amp; !val.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Value : </span><span class="label-font-color">-------</span></span></h3>
            </div>
            <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
              <h3 class="text-ellipsis font-normal-size">
                <span *ngIf="val.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Stage : </span>{{val.deal_stage_name}}</span>
                <span *ngIf="!val.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Stage : </span><span class="label-font-color">-------</span></span>
              </h3>
            </div>
            <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
              <h3 class="text-ellipsis font-normal-size">
                <!-- {{val.lead_last_updated_at}} -->
                <span *ngIf="val.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span>{{val.deal_updated_at | date: &apos;dd&apos;}} - {{val.deal_updated_at | date:&apos;MM&apos;}} - {{val.deal_updated_at | date: &apos;yyyy&apos;}}, {{val.deal_updated_at | date: &apos;HH:mm&apos;}}</span>
                <span *ngIf="!val.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span><span class="label-font-color">-------</span></span>

              </h3>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
        <div class="overlay-scroll height-100 search-results">
          <cdk-virtual-scroll-viewport infinite-scroll style="height: 100%" [itemSize]="112" minBufferPx="200" maxBufferPx="400" [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="200" [scrollWindow]="false" (scrolled)="onScroll()">
            <div fxLayout="column wrap" class="display-block leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *cdkVirtualFor="let val of dealsArr | async" (click)="navigateToDeal(val.dealid)" [ngClass.xs]="&apos;menu-margin-xs&apos;">
              <div fxLayout="row wrap" class="cursor-pointer color-black" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="val" [ngClass.xs]="&apos;border-lightgray-1px border-radius-4&apos;">
                <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;" [ngClass.xs]="&apos;display-grid&apos;">
                  <h3 class="text-ellipsis font-normal-size">
                    <span *ngIf="val.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Name : </span>{{val.deal_name}}</span>
                    <span *ngIf="!val.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Name : </span><span class="label-font-color">-------</span></span></h3>
                </div>
                <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                  <h3 class="text-ellipsis font-normal-size">
                    <span *ngIf="val.currencyid || val.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Value : </span>{{val.currencyid | currencySymbol | async}} {{val.deal_value}}</span>
                    <span *ngIf="!val.currencyid &amp;&amp; !val.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Value : </span><span class="label-font-color">-------</span></span></h3>
                </div>
                <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                  <h3 class="text-ellipsis font-normal-size">
                    <span *ngIf="val.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Stage : </span>{{val.deal_stage_name}}</span>
                    <span *ngIf="!val.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Deal Stage : </span><span class="label-font-color">-------</span></span>
                  </h3>
                </div>
                <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="100" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                  <h3 class="text-ellipsis font-normal-size">
                    <!-- {{val.lead_last_updated_at}} -->
                    <span *ngIf="val.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span>{{val.deal_updated_at | date: &apos;dd&apos;}} - {{val.deal_updated_at | date:&apos;MM&apos;}} - {{val.deal_updated_at | date: &apos;yyyy&apos;}}, {{val.deal_updated_at | date: &apos;HH:mm&apos;}}</span>
                    <span *ngIf="!val.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span><span class="label-font-color">-------</span></span>

                  </h3>
                </div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column wrap" class="padding-1-0" fxLayoutGap="8px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" fxFill *ngIf="dealValue &amp;&amp; dealValue.length == 0" [ngStyle.xs]="&apos;flex-wrap: nowrap;&apos;">
    <div class="no-deal-image">
    </div>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <h3 [ngClass]="(!progressCheck) ? &apos;icon-disable&apos; : &apos;&apos; " class="light-gray-color">
        No {{label.deals | titlecase}} Available</h3>
    </div>
  </div>
</div>