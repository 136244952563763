/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { dealservice } from 'app/sd-services/dealservice';
import { Router } from '@angular/router';
import { NPubSubService } from 'neutrinos-seed-services';
import { Deal } from 'app/components/dealsComponent/deals.component';
import { dialogService } from 'app/services/dialog/dialog.service';
import { cacheservice } from 'app/sd-services/cacheservice';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-mydeals',
    templateUrl: './mydeals.template.html',
    styleUrls: ['./mydeals.style.scss']
})

export class mydealsComponent extends NBaseComponent implements OnInit, OnChanges {
    @Output('totalCount') totalCount = new EventEmitter();
    @Input('media_view') media_view;

    @Output('selectedDeals') selectDeals = new EventEmitter();
    @Output('displayedDealsCount') displayedDealsCount = new EventEmitter();

    @Input('totalCountP') totalCountP;

    @Input('pageSizeP') pageSizeP;

    @Input('pageOffsetP') pageOffsetP;

    @Input('readyP') readyP;

    @Output() paginationEvent = new EventEmitter<string>();
    selectedDealsArr = [];
    selectedDealsCount = 0;
    pageSize = 50;
    pageOffset = 0;
    scrollPageOffset = 0;
    mydeals = [];
    totalDeals = 0;
    sum = 15;
    checkAppendStatus = false;
    selectAllCheck
    disableCheck
    headers = [
        {
            displayText: 'Name', // deal_name,
            sortKey: 'deal_name',
            sort: true,
            sortValue: 'ASC'
        },
        {
            displayText: 'Contact Name' // contact_owner_name
        },
        {
            displayText: 'Value' // 
        },
        {
            displayText: 'Stage',
            sortKey: 'deal_stage_name',
            sort: true,
            sortValue: 'ASC'
        },
        {
            displayText: 'Last updated date',
            sortKey: 'deal_updated_at',
            sort: true,
            sortValue: 'DESC'
        },
        {
            displayText: 'Action'
        }]
    currentSortKey = 'deal_updated_at';
    dealRefreshSub;
    sortSub;
    sortOrder: Array<{ key: string, order: 'ASC' | 'DESC' }> = [
        {
            key: 'deal_updated_at',
            order: 'DESC'
        },
        {
            key: 'deal_stage_name',
            order: 'ASC'
        },
        {
            key: 'deal_name',
            order: 'ASC'
        }
    ];
    
    constructor(private dealsService: dealservice,
        private router: Router,
        private pubsub: NPubSubService,
        private dialog: dialogService,
        private cacheService: cacheservice,
        public label: labelService) {
        super();
    }

    ngOnInit() {
        this.dealRefreshSub = this.pubsub.$sub('deal-added-event', () => {
            this.getMyDeals(this.pageSize, this.pageOffset);
        })

        this.sortSub = this.pubsub.$sub('my_deals_sort', (result) => {
            this.checkAppendStatus = true;
            let dealsortCount = this.mydeals.length
            this.mydeals = [];
            this.findNReplaceSortOrder(result.res.data.key, result.res.data.sort_type);
            this.getMyDeals(dealsortCount, 0);
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.media_view) {
            this.pageSize = 50;
            this.pageOffset = 0;
            this.getMyDeals(this.pageSize, this.pageOffset);
        }
        else if (Object.keys(changes).includes("totalCountP") || Object.keys(changes).includes("pageSizeP") || Object.keys(changes).includes("pageOffsetP") || Object.keys(changes).includes("readyP")) {

        }
    }

    onScroll() {
        this.checkAppendStatus = true;
        this.scrollPageOffset += 1;
        this.getMyDeals(this.sum, this.scrollPageOffset);
    }

    pageEvents($event) {
         this.paginationEvent.next($event);;
    }

    getMyDeals(pageSize: number, pageOffset: number) {
        this.pubsub.$pub('allDealsSelected', {
            'allDealsSelected': 'false'
        });
        this.selectedDealsArr = [];
        this.selectedDealsCount = 0;
        this.selectDeals.emit({ count: this.selectedDealsCount, obj: this.selectedDealsArr });
        this.selectAllCheck = false;
        this.disableCheck=true
        if (typeof pageSize == 'undefined') {
            pageSize = 50;
        }

        if (typeof pageOffset == 'undefined') {
            pageOffset = 0;
        }

        this.pageSize = pageSize;
        this.pageOffset = pageOffset;

        this.dealsService.getMyDeals(pageSize, pageOffset, this.sortOrder.map(v => {
            return v.key + ' ' + v.order;
        }).filter(v => v).join(','))
            .then((result: {
                input: {},
                local: {
                    dealsValue: {
                        data: {
                            count: number,
                            deals: Deal[]
                        }
                    }
                }
            }) => {
                if (result.local.dealsValue && result.local.dealsValue.data) {
                    if (this.checkAppendStatus) {
                        let temp = this.mydeals;
                        this.mydeals = result.local.dealsValue.data.deals;
                        this.mydeals = temp.concat(this.mydeals);
                        this.totalDeals = result.local.dealsValue.data.count;
                        this.totalCount.emit({ value: false });
                        this.checkAppendStatus = false;
                    } else {
                        this.mydeals = result.local.dealsValue.data.deals;
                        this.totalDeals = result.local.dealsValue.data.count;
                        this.totalCount.emit({ value: false });
                    }
                    for (let i in this.mydeals) {
                        this.mydeals[i]['isSelected'] = false;
                    }
                    this.disableCheck=false
                }
                this.displayedDealsCount.emit({value:this.mydeals.length});
            })
            .catch(e => {
                console.log(e);
            })
    }

    navigateToDeal(dealid, contactid) {
        this.router.navigate(['/home/deals/' + dealid + '/contact/' + contactid])
    }


    sortChange($event: {
        key: string,
        sort: 'ASC' | 'DESC'
    }) {
        this.currentSortKey = $event.key;
        this.findNReplaceSortOrder($event.key, $event.sort);
        this.getMyDeals(this.pageSize, this.pageOffset);
    }

    findNReplaceSortOrder(key, sort: 'ASC' | 'DESC') {
        let i = this.sortOrder.findIndex(v => {
            return v.key === key;
        });

        this.sortOrder.splice(i, 1);
        this.sortOrder.unshift({
            key,
            order: sort
        })
    }

    onRowClick(event, dealid, dealowner) {
        this.selectAllCheck = false;
        if (event.checked) {
            this.selectedDealsArr.push({ 'dealid': dealid, 'deal_created_by': dealowner });
            this.selectedDealsCount += 1;
            if (this.selectedDealsCount == this.mydeals.length) {
                this.selectAllCheck = true;
                if (localStorage.getItem('usersDealChoiceSelectAll') == 'true')
                    this.pubsub.$pub('allDealsSelected', {
                        'allDealsSelected': 'true'
                    });
            }
        } else {
            for (let i = 0; i < this.selectedDealsArr.length; i++) {
                if (this.selectedDealsArr[i]['dealid'] == dealid) {
                    this.selectedDealsArr.splice(i, 1);
                    this.selectedDealsCount -= 1;
                }
            }
        }
        if (this.selectedDealsCount != this.mydeals.length) {
            this.pubsub.$pub('allDealsSelected', {
                'allDealsSelected': 'false'
            });
        }
        this.selectDeals.emit({ count: this.selectedDealsCount, obj: this.selectedDealsArr });
    }

    selectAll(event) {
        console.log("selectAll called",event)
        if (event.checked) {
            this.selectedDealsArr = [];
            for (let i in this.mydeals) {
                this.mydeals[i]['isSelected'] = true;
                this.selectedDealsArr.push({ 'dealid': this.mydeals[i].dealid, 'deal_created_by': this.mydeals[i].deal_created_by });
            }
            this.selectedDealsCount = this.mydeals.length;
        } else {
            for (let i in this.mydeals) {
                this.mydeals[i]['isSelected'] = false;
                this.selectedDealsArr = [];
            }
            localStorage.setItem('allDealsSelected','false');
            localStorage.setItem('usersDealChoiceSelectAll','false')
            this.selectedDealsCount = 0;
        }
        this.selectDeals.emit({ count: this.selectedDealsCount, obj: this.selectedDealsArr });
    }

    resetSelectedDeals() {
        this.selectAllCheck=false
        for (let i in this.mydeals) {
            this.mydeals[i]['isSelected'] = false;
            this.selectedDealsArr = [];
        }
        this.selectedDealsCount = 0;
        this.selectDeals.emit({ count: this.selectedDealsCount, obj: this.selectedDealsArr });
    }


    initialCall(deal: Deal) {
        if (deal.contactid && deal.dealid) {
            this.dialog.makeCallDialog({
                modulename: 'deals',
                moduleid: deal.dealid,
                contactid: deal.contactid
            })
        }
    }

    async scheduleAppointment(deal: Deal) {
        await this.cacheService.setc({
            'contactid': deal.contactid,
            'contact_name': deal.contact_display_name,
            'dealid': deal.dealid,
            'deal_name': deal.deal_name
        }, 'CREATE_APPOINTMENT_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'SCHEDULE_APPOINTMENT'
        });
    }

    async createTask(deal: Deal) {
        // TODO: No need to construct this object. Directly send the `deal` for the form
        await this.cacheService.setc({
            'contactid': deal.contactid,
            'contact_name': deal.contact_display_name,
            'dealid': deal.dealid,
            'deal_name': deal.deal_name,
            contact_first_name: deal.contact_first_name,
            contact_last_name: deal.contact_last_name
        }, 'CREATE_TASK_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_TASK'
        });
    }
    ngOnDestroy() {

        if (this.dealRefreshSub) {
            this.dealRefreshSub.unsubscribe();
        }
    }
}
