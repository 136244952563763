/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dynselect',
    templateUrl: './dynselect.template.html'
})

export class dynselectComponent extends NBaseComponent implements OnInit, OnDestroy {
    @Input('placeholder') placeholder = '__no_placeholder__';
    @Input('fc') fc = new FormControl();
    @Input('selectOptions') selectOptions = [];
    @Input('required') required = false;
    @Input('field_name') field_name = '';
    @Input('changeCallbackFn') changeCallbackFn;
    private changeSub: Subscription;
    constructor() {
        super();
    }

    ngOnInit() {
        this.changeSub = this.fc.valueChanges.subscribe(v => {
            if (typeof this.changeCallbackFn === 'function') {
                this.changeCallbackFn(this.field_name, v);
            }
        })
    }

    ngOnDestroy() {
        if (this.changeSub) {
            this.changeSub.unsubscribe();
        }
    }
}
