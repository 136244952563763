/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class fileservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_fileservice

  async addDocument(payload: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          payload: payload
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_7O3o75ej1cR6W1U8(bh);
      //appendnew_next_addDocument
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1MPNQyPMffAC8bl8');
    }
  }

  async addFileTags(
    fileid: any = undefined,
    tags: any = undefined,
    contactid: any = undefined,
    dealid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          fileid: fileid,
          tags: tags,
          contactid: contactid,
          dealid: dealid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_dviYONgcYwYHQpt0(bh);
      //appendnew_next_addFileTags
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TX2qs2Xwr95SQitw');
    }
  }

  async getFileByContactid(contactid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          contactid: contactid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_MrZNSEFn4trKgUFI(bh);
      //appendnew_next_getFileByContactid
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Q4Sat8JODGWFODsO');
    }
  }

  async downloadDocument(fileid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          fileid: fileid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_ivmZICUAtUKqcVuG(bh);
      //appendnew_next_downloadDocument
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_VDHVdc6xtMURRAhf');
    }
  }

  async getFileByDealid(
    dealid: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          dealid: dealid,
          contactid: contactid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_mhsStFTGeYxFMe2S(bh);
      //appendnew_next_getFileByDealid
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_by0aFSfgPdR0vf7l');
    }
  }

  async updateFileTags(
    fileid: any = undefined,
    payload: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          fileid: fileid,
          payload: payload
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_sOoRU9dMBtXMkwDl(bh);
      //appendnew_next_updateFileTags
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_R55qBjyxOo70aQJK');
    }
  }

  async deleteDocument(
    fileid: any = undefined,
    payload: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          fileid: fileid,
          payload: payload
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_uB5QrXH8TrRViOux(bh);
      //appendnew_next_deleteDocument
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Xl6ISihUYNEBN3Se');
    }
  }

  //appendnew_flow_fileservice_Start

  async sd_7O3o75ej1cR6W1U8(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'file';
      bh = await this.sd_jtP3qq8nq54qcS1q(bh);
      //appendnew_next_sd_7O3o75ej1cR6W1U8
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_7O3o75ej1cR6W1U8');
    }
  }

  async sd_jtP3qq8nq54qcS1q(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.payload
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_jtP3qq8nq54qcS1q
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jtP3qq8nq54qcS1q');
    }
  }

  async sd_dviYONgcYwYHQpt0(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'file/tags/activityfileid/' +
        bh.input.fileid;
      bh.body = {
        tags: bh.input.tags,
        contactid: bh.input.contactid,
        dealid: bh.input.dealid
      };
      bh = await this.sd_GKAwg1JyiXKVjMFK(bh);
      //appendnew_next_sd_dviYONgcYwYHQpt0
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_dviYONgcYwYHQpt0');
    }
  }

  async sd_GKAwg1JyiXKVjMFK(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_GKAwg1JyiXKVjMFK
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_GKAwg1JyiXKVjMFK');
    }
  }

  async sd_MrZNSEFn4trKgUFI(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'files/contact/' +
        bh.input.contactid;
      bh = await this.sd_ujSXkO9rdh5U1w15(bh);
      //appendnew_next_sd_MrZNSEFn4trKgUFI
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MrZNSEFn4trKgUFI');
    }
  }

  async sd_ujSXkO9rdh5U1w15(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_ujSXkO9rdh5U1w15
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ujSXkO9rdh5U1w15');
    }
  }

  async sd_ivmZICUAtUKqcVuG(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'file/activityfileid/' +
        bh.input.fileid;
      bh = await this.sd_0nDv1mqiD1QsV6tA(bh);
      //appendnew_next_sd_ivmZICUAtUKqcVuG
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ivmZICUAtUKqcVuG');
    }
  }

  async sd_0nDv1mqiD1QsV6tA(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'arraybuffer',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_0nDv1mqiD1QsV6tA
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0nDv1mqiD1QsV6tA');
    }
  }

  async sd_mhsStFTGeYxFMe2S(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'files/deal/' +
        bh.input.dealid +
        '?';

      if (bh.input.contactid) {
        bh.url += 'contactid=' + bh.input.contactid;
      }
      bh = await this.sd_4I8hnBUuYRkyJ0DZ(bh);
      //appendnew_next_sd_mhsStFTGeYxFMe2S
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mhsStFTGeYxFMe2S');
    }
  }

  async sd_4I8hnBUuYRkyJ0DZ(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_4I8hnBUuYRkyJ0DZ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_4I8hnBUuYRkyJ0DZ');
    }
  }

  async sd_sOoRU9dMBtXMkwDl(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'file/tags/activityfileid/' +
        bh.input.fileid;
      bh = await this.sd_uyl3ddModHrfEV5y(bh);
      //appendnew_next_sd_sOoRU9dMBtXMkwDl
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_sOoRU9dMBtXMkwDl');
    }
  }

  async sd_uyl3ddModHrfEV5y(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.payload
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_uyl3ddModHrfEV5y
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_uyl3ddModHrfEV5y');
    }
  }

  async sd_uB5QrXH8TrRViOux(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'file/activityfileid/' +
        bh.input.fileid;
      bh = await this.sd_ApozTmfsZAcZm6kZ(bh);
      //appendnew_next_sd_uB5QrXH8TrRViOux
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_uB5QrXH8TrRViOux');
    }
  }

  async sd_ApozTmfsZAcZm6kZ(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.payload
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_ApozTmfsZAcZm6kZ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ApozTmfsZAcZm6kZ');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_fileservice_Catch
}
