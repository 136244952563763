<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <bh-sidenavformheader [title]="title" [titleIconUrl]="&apos;assets/Web/Icons/settings-feedback_outcome.svg&apos;" (closeClicked)="close()"></bh-sidenavformheader>
  <form style="height: calc(100% - 115px);">
    <bh-formsectiontitle [sectionName]="sectionName"></bh-formsectiontitle>
    <div fxLayout="column " style="height: calc(100% - 50px);" class="overflow-y-only height-100 lr-padding" fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <n-toggleoptions [value]="&apos;Appointment&apos;" [toggleOptions]="outcomeTypes" (valueChange)="switchOutComesConfig($event)"></n-toggleoptions>
      <div fxLayout="column " class="padding-right-1 overflow-y-only height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <bh-optionsconfigurator class="margin-bottom-1" [optionsFormControl]="formGroup.controls.appointment_feedback_outcomes" [optionPlaceholder]="&apos;Enter outcome&apos;" [initialOptions]="apptfbocs" [addBtnLabel]="optionsAddBtnLabel" [fxShow]="showApptConfig" [iem]="true" [labelPath]="&apos;feedback_label&apos;" (optionSaved)="updateOption($event, &apos;appointment&apos;)" (optionDeleted)="deleteOption($event, &apos;appointment&apos;)" (optionsOrdered)="updateOptionOrder($event, &apos;appointment&apos;)" [disableActions]="disableActions"></bh-optionsconfigurator>
        <bh-optionsconfigurator class="margin-bottom-1" [optionsFormControl]="formGroup.controls.call_feedback_outcomes" [optionPlaceholder]="&apos;Enter outcome&apos;" [initialOptions]="callfbocs" [addBtnLabel]="optionsAddBtnLabel" [fxShow]="!showApptConfig" [iem]="true" [labelPath]="&apos;feedback_label&apos;" (optionSaved)="updateOption($event, &apos;call&apos;)" (optionDeleted)="deleteOption($event, &apos;call&apos;)" (optionsOrdered)="updateOptionOrder($event, &apos;call&apos;)" [disableActions]="disableActions"></bh-optionsconfigurator>
      </div>
    </div>
  </form>
  <div fxLayout="row wrap" class="tb-padding lr-padding" fxLayoutGap="1rem" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-action-button" (click)="close()" [disabled]="disableActions">Close</button></div>
</div>