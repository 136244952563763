/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class dashboardservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_dashboardservice

  async getOverview(
    stats_type: any = undefined,
    fromDate: any = undefined,
    toDate: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          stats_type: stats_type,
          fromDate: fromDate,
          toDate: toDate
        },
        local: {
          overViewData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_7qKTMTGv2Iwpj60D(bh);
      //appendnew_next_getOverview
      return (
        // formatting output variables
        {
          input: {},
          local: {
            overViewData: bh.local.overViewData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_5pfcO02FbTqStzp7');
    }
  }

  async getdropout(
    stats_type: any = undefined,
    fromDate: any = undefined,
    toDate: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          stats_type: stats_type,
          fromDate: fromDate,
          toDate: toDate
        },
        local: {
          dropoutData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_1IdKsIosiVaULL6X(bh);
      //appendnew_next_getdropout
      return (
        // formatting output variables
        {
          input: {},
          local: {
            dropoutData: bh.local.dropoutData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_nCwAW1o4Jj2X3LYe');
    }
  }

  async getDashboardStats(
    stats_type: any = undefined,
    fromDate: any = undefined,
    toDate: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          stats_type: stats_type,
          fromDate: fromDate,
          toDate: toDate
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_GGUXZAHCfjATtnmn(bh);
      //appendnew_next_getDashboardStats
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_QAyQXcdGdlhAxJLb');
    }
  }

  async getAppointmentOutcome(
    stats_type: any = undefined,
    fromDate: any = undefined,
    toDate: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          stats_type: stats_type,
          fromDate: fromDate,
          toDate: toDate
        },
        local: {
          appointmentData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_lPR8mCPSYdmGYpmV(bh);
      //appendnew_next_getAppointmentOutcome
      return (
        // formatting output variables
        {
          input: {},
          local: {
            appointmentData: bh.local.appointmentData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_QJXTjvuFvSpxYY5f');
    }
  }

  async getCallOutcome(
    stats_type: any = undefined,
    fromDate: any = undefined,
    toDate: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          stats_type: stats_type,
          fromDate: fromDate,
          toDate: toDate
        },
        local: {
          callData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_tlWcJUWGQdi32fAB(bh);
      //appendnew_next_getCallOutcome
      return (
        // formatting output variables
        {
          input: {},
          local: {
            callData: bh.local.callData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_KkozGrJ2pHq6WtFN');
    }
  }

  async getTeamAverage(
    fromDate: any = undefined,
    toDate: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          fromDate: fromDate,
          toDate: toDate
        },
        local: {
          teamData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_mtQPyW7fcAez5pPk(bh);
      //appendnew_next_getTeamAverage
      return (
        // formatting output variables
        {
          input: {},
          local: {
            teamData: bh.local.teamData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BDNFBUzWOMqOr4Nm');
    }
  }

  async getUsers(
    fromDate: any = undefined,
    toDate: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          fromDate: fromDate,
          toDate: toDate
        },
        local: {
          usersData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_njkPDEmaPM3Vjbik(bh);
      //appendnew_next_getUsers
      return (
        // formatting output variables
        {
          input: {},
          local: {
            usersData: bh.local.usersData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_A0BRMQkwquMXydMG');
    }
  }

  async getUserById(
    fromDate: any = undefined,
    toDate: any = undefined,
    userid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          fromDate: fromDate,
          toDate: toDate,
          userid: userid
        },
        local: {
          userAverageData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_Bb8W2dO3uD7RNriX(bh);
      //appendnew_next_getUserById
      return (
        // formatting output variables
        {
          input: {},
          local: {
            userAverageData: bh.local.userAverageData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_2iSqWja3ACMJCZJg');
    }
  }

  //appendnew_flow_dashboardservice_Start

  async sd_7qKTMTGv2Iwpj60D(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'dashboard/overview/' +
        bh.input.stats_type +
        '?';

      if (bh.input.fromDate) {
        bh.url += 'fromDate=' + bh.input.fromDate;
      }

      if (bh.input.toDate) {
        bh.url += '&toDate=' + bh.input.toDate;
      }

      bh = await this.sd_dWqRvFY4y0nfTMN5(bh);
      //appendnew_next_sd_7qKTMTGv2Iwpj60D
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_7qKTMTGv2Iwpj60D');
    }
  }

  async sd_dWqRvFY4y0nfTMN5(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.overViewData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_dWqRvFY4y0nfTMN5
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_dWqRvFY4y0nfTMN5');
    }
  }

  async sd_1IdKsIosiVaULL6X(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'dashboard/dropout/summary/' +
        bh.input.stats_type +
        '?';

      if (bh.input.fromDate) {
        bh.url += 'fromDate=' + bh.input.fromDate;
      }

      if (bh.input.toDate) {
        bh.url += '&toDate=' + bh.input.toDate;
      }

      bh = await this.sd_HtpS2cuX2QOYmdLD(bh);
      //appendnew_next_sd_1IdKsIosiVaULL6X
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1IdKsIosiVaULL6X');
    }
  }

  async sd_HtpS2cuX2QOYmdLD(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.dropoutData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_HtpS2cuX2QOYmdLD
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HtpS2cuX2QOYmdLD');
    }
  }

  async sd_GGUXZAHCfjATtnmn(bh) {
    try {
      let promiseArray = [];
      promiseArray.push(
        this.getOverview(
          bh.input.stats_type,
          bh.input.fromDate,
          bh.input.toDate
        )
      );
      promiseArray.push(
        this.getdropout(bh.input.stats_type, bh.input.fromDate, bh.input.toDate)
      );
      promiseArray.push(
        this.getAppointmentOutcome(
          bh.input.stats_type,
          bh.input.fromDate,
          bh.input.toDate
        )
      );
      promiseArray.push(
        this.getCallOutcome(
          bh.input.stats_type,
          bh.input.fromDate,
          bh.input.toDate
        )
      );

      bh.local.result = await Promise.all(promiseArray);
      //appendnew_next_sd_GGUXZAHCfjATtnmn
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_GGUXZAHCfjATtnmn');
    }
  }

  async sd_lPR8mCPSYdmGYpmV(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'dashboard/outcome/appointment/' +
        bh.input.stats_type +
        '?';

      if (bh.input.fromDate) {
        bh.url += 'fromDate=' + bh.input.fromDate;
      }

      if (bh.input.toDate) {
        bh.url += '&toDate=' + bh.input.toDate;
      }

      bh = await this.sd_pTESTNUxiMHRYjis(bh);
      //appendnew_next_sd_lPR8mCPSYdmGYpmV
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lPR8mCPSYdmGYpmV');
    }
  }

  async sd_pTESTNUxiMHRYjis(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.appointmentData = await this.sdService.nHttpRequest(
        requestOptions
      );
      //appendnew_next_sd_pTESTNUxiMHRYjis
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_pTESTNUxiMHRYjis');
    }
  }

  async sd_tlWcJUWGQdi32fAB(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'dashboard/outcome/call/' +
        bh.input.stats_type +
        '?';

      if (bh.input.fromDate) {
        bh.url += 'fromDate=' + bh.input.fromDate;
      }

      if (bh.input.toDate) {
        bh.url += '&toDate=' + bh.input.toDate;
      }

      bh = await this.sd_pxtjJzF85WTvRi8A(bh);
      //appendnew_next_sd_tlWcJUWGQdi32fAB
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_tlWcJUWGQdi32fAB');
    }
  }

  async sd_pxtjJzF85WTvRi8A(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.callData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_pxtjJzF85WTvRi8A
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_pxtjJzF85WTvRi8A');
    }
  }

  async sd_mtQPyW7fcAez5pPk(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'dashboard/team/average?';

      if (bh.input.fromDate) {
        bh.url += 'fromDate=' + bh.input.fromDate;
      }

      if (bh.input.toDate) {
        bh.url += '&toDate=' + bh.input.toDate;
      }
      bh = await this.sd_4ErxRaeyoodzrAVF(bh);
      //appendnew_next_sd_mtQPyW7fcAez5pPk
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mtQPyW7fcAez5pPk');
    }
  }

  async sd_4ErxRaeyoodzrAVF(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.teamData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_4ErxRaeyoodzrAVF
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_4ErxRaeyoodzrAVF');
    }
  }

  async sd_njkPDEmaPM3Vjbik(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'reporting/users';

      bh = await this.sd_5bELvVAzIUORfsEz(bh);
      //appendnew_next_sd_njkPDEmaPM3Vjbik
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_njkPDEmaPM3Vjbik');
    }
  }

  async sd_5bELvVAzIUORfsEz(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.usersData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_5bELvVAzIUORfsEz
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_5bELvVAzIUORfsEz');
    }
  }

  async sd_Bb8W2dO3uD7RNriX(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'dashboard/user?';

      if (bh.input.fromDate) {
        bh.url += 'fromDate=' + bh.input.fromDate;
      }

      if (bh.input.toDate) {
        bh.url += '&toDate=' + bh.input.toDate;
      }

      if (bh.input.userid) {
        bh.url += '&userid=' + bh.input.userid;
      }

      bh = await this.sd_K7k7XlQENdnT7vx9(bh);
      //appendnew_next_sd_Bb8W2dO3uD7RNriX
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Bb8W2dO3uD7RNriX');
    }
  }

  async sd_K7k7XlQENdnT7vx9(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.userAverageData = await this.sdService.nHttpRequest(
        requestOptions
      );
      //appendnew_next_sd_K7k7XlQENdnT7vx9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_K7k7XlQENdnT7vx9');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false ||
      (await this.sd_cjvp2fPjiAvvDjHm(bh))
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  async sd_cjvp2fPjiAvvDjHm(bh) {
    const nodes = [
      'sd_5pfcO02FbTqStzp7',
      'sd_nCwAW1o4Jj2X3LYe',
      'sd_QAyQXcdGdlhAxJLb',
      'sd_QJXTjvuFvSpxYY5f',
      'sd_KkozGrJ2pHq6WtFN'
    ];
    if (nodes.includes(bh.errorSource)) {
      //appendnew_next_sd_cjvp2fPjiAvvDjHm
      return true;
    }
    return false;
  }
  //appendnew_flow_dashboardservice_Catch
}
