/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { contactservice } from 'app/sd-services/contactservice';
import { searchservice } from 'app/sd-services/searchservice';
import { userservice } from 'app/sd-services/userservice';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

interface ContactSearchResult {
	contactid: number;
	display_name: string;
}

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-dyncontactsearch',
	templateUrl: './dyncontactsearch.template.html',
	exportAs: 'contactSearch'
})
/**********************************
 * !IMPORTANT: 
 **********************************/
export class dyncontactsearchComponent extends NBaseComponent implements OnInit, OnChanges, OnDestroy {

	@Input('fc') fc = new FormControl();
	@Input('placeholder') placeholder = '';
	@Input('defaultcontacts') defaultcontacts = [];
	@Input('mode') mode: DYN_SEARCH_INPUT_MODE = 'EDIT';
	@Input() value: number;
	@Input() diabled = false;
	@Input() required = false;

	@Output() resultSelected = new EventEmitter();
	@Output('navigationInit') navigationInitiated = new EventEmitter();

	@ViewChild(MatAutocompleteTrigger) trigger;
	@ViewChild('fci') fci;

	contacts = [];
	filteredcontacts = [];
	public dwbind = null;
	intializationComplete = false;
	selectedid: number;

	private changesSub;
	private userId;
	private pageSize = 10;
	private pageOffset = 0;
	private searchInProgress = false;
	private _baseUrl = '/home/contacts/';
	private _setErrSub: Subscription;
	constructor(
		private userService: userservice,
		private searchService: searchservice,
		private contactService: contactservice,
		private router: Router,
		private z: NgZone) {
		super();
		this.userService.getcu().then((result: bh) => this.userId = result.local.user.srmUserid).catch(e => { })
	}

	ngOnInit() {
		this.changesSub = this.fc.valueChanges.pipe(
			debounceTime(300),
			tap(v => {
				if (v && !this.searchInProgress && this.intializationComplete) {
					this.search(v);
				}
			})
		).subscribe();
		this.dwbind = this.displayWith.bind(this);
		this.fc.setValue(this.fc.value);
		this.fetchAndSetContactByID(this.fc.value);
		this._setErrSub = this.fc.valueChanges.subscribe((v) => this._setFCErrs(v));
	}

	private _setFCErrs(v: string | number) {
		const cnsErr = (v && typeof v !== 'number') ? { invalidContact: 'Contact not selected' } : null;
		const er = { ...this.fc.errors, ...cnsErr };
		this.fc.setErrors(Object.keys(er).length ? er : null);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.fc && this.fc.value &&
			!(changes.defaultcontacts && changes.defaultcontacts.currentValue)) {
			this.fetchAndSetContactByID(this.fc.value)
		} else {
			this.intializationComplete = true;
		}

		if (changes.defaultcontacts &&
			changes.defaultcontacts.currentValue instanceof Array &&
			changes.defaultcontacts.currentValue.length) {
			this.filteredcontacts = changes.defaultcontacts.currentValue;
			this.intializationComplete = true;
			this.fc.setValue(this.fc.value);
		}
		if (changes.disabled) {
			this._setDisabled(changes.disabled.currentValue);
		}
		if (changes.value) {
			this._setValue(changes.value.currentValue);
		}
	}

	private _setDisabled(d: boolean) {
		if (this.fc) {
			!!d ? this.fc.disable() : this.fc.enable();
		}
	}

	private _setValue(v: number) {
		if (this.fc) {
			if (!v) {
				this.fc.setValue('');
			} else {
				this.setValueBasedOnId(v);
			}
		}
	}

	search(searchValue, searchForUserid?: number) {
		if (!searchValue) {
			this.filteredcontacts = [];
		}
		if (this.mode === 'EDIT' && typeof searchValue !== 'number') {
			this.searchInProgress = true;
			this.searchService.contactDealSearch(searchForUserid || this.userId, searchValue, this.pageSize, this.pageOffset, true, null, null, true)
				.then((result: bh) => {
					if (result.local.searchList
						&& result.local.searchList.data
						&& result.local.searchList.data.contacts) {
						this.filteredcontacts = result.local.searchList.data.contacts;
					} else {
						this.filteredcontacts = [];
					}
					this.searchInProgress = false;
				}).catch(e => this.searchInProgress = false)
		}
	}

	setValueBasedOnId(id: number) {
		if (this.fc && typeof id === 'number') {
			const found = this.filteredcontacts.find(c => c.contactid === id);
			if (Array.isArray(this.filteredcontacts) && found) {
				this.fc.setValue(id);
				this.selectedid = id;
			} else {
				this.fetchAndSetContactByID(id);
			}
		}
	}

	displayWith(contactid) {
		if (contactid) {
			const k = this.filteredcontacts.find(v => {
				return v.contactid == contactid;
			})
			if (k) {
				return k.display_name;
			}
		}
		return null;
	}

	navigateToContactDetails(e) {
		e.preventDefault();
		e.stopPropagation();
		if (this.selectedid) {
			const url = this._baseUrl + this.selectedid;
			this.navigationInitiated.next({
				url: url,
				contactid: this.selectedid
			});
			this.router.navigate([url]);
		}
	}

	autoCompleteFocused() {
		if (this.fc.value === '' || this.fc.value === undefined || this.fc.value === null) {
			this.trigger._onChange("");
			this.trigger.openPanel();
		} else if (this.fc.value) {
			this.trigger._onChange(this.fc.value);
			this.trigger.openPanel();
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.selectedid = event.option.value;
		this.resultSelected.next(event.option.value)
	}

	fetchAndSetContactByID(v: number) {
		if (typeof v === 'number' && v) {
			this.contactService.getContactById(v)
				.then((result: bh) => {
					if (result.local.result &&
						result.local.result.data) {
						const d: ContactSearchResult = result.local.result.data;
						this.filteredcontacts.push(d);
						this.selectedid = this.fc?.value || d.contactid;
						this.fc.setValue(this.selectedid);
					}
					this.intializationComplete = true;
				})
				.catch(e => {
					console.log(e);
				})
		}
	}

	clear() {
        this.z.run(() => {
            this.fc.setValue('');
            this.fci.nativeElement.focus();
            setTimeout(() => {
                this.trigger.openPanel();
            }, 100);
        });
    }


	ngOnDestroy() {
		if (this.changesSub) {
			this.changesSub.unsubscribe();
		}
		if (this._setErrSub) {
			this._setErrSub.unsubscribe();
		}
	}
}

export type DYN_SEARCH_INPUT_MODE = 'VIEW' | 'EDIT'

interface bh {
	input: {},
	local: {
		searchList?: {
			data: {
				contacts: any[]
			}
		},
		result?: {
			data: any
		},
		user?: {
			srmUserid: number
		}
	}
}
