<div fxLayout="column wrap" fxLayoutGap="5px" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" class="lr-padding border-radius card-like" fxFlex="60px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
    <h2>
      {{label.deals | uppercase}}</h2>
  </div>
  <div fxLayout="column wrap" class="border-radius card-like" fxFlex="calc(100%-66px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="&apos;light-white-bg&apos;" fxLayoutAlign.xs="center start" [ngClass.sm]="&apos;list-tab-sm&apos;">
    <div fxLayout="row wrap" fxLayoutAlign="end end" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 100%&apos;" fxLayoutAlign.xs="center center" [ngClass.gt-xs]="&apos;pagination-align-gt-xs&apos;" [fxHide.xs]="true">
      <h3 *ngIf="selectedDealsCount &gt; 0" style="margin-bottom:15px;" class="lr-padding">
        <div *ngIf="selectedDealsCount==displayedDealsCountValue &amp;&amp; allDealsSelected==false &amp;&amp; totalCount&gt;pageSize  ">{{selectedDealsCount}} {{label.deals | titlecase}} Selected.&#xA0;<a role="button" (click)="selectAllDeals()" style="color:&apos;var(--primary-color)&apos;;cursor:&apos;pointer&apos;">Select All {{totalCount}} {{label.deals | titlecase}}.</a></div>
        <div *ngIf="allDealsSelected">All {{totalCount}} {{label.deals | titlecase}} Selected. <a role="button" (click)="deselectAllSelectedDeals()" style="color:&apos;var(--primary-color)&apos;;cursor:&apos;pointer&apos;">Clear Selection </a></div>
      </h3>
      <div fxLayout="row wrap" style="margin-top:15px;margin-right:15px;padding: 0.6rem;margin-bottom: 13px; " class="border-radius-4 document-margin cursor-pointer background-actual-primary" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" (click)="downloadDealasCSV()" *ngIf="selectedIndex == 0 &amp;&amp; selectedDealsCount" matTooltip="Export as CSV">
        <div class="document_info-image">
        </div>
      </div>
    </div>
    <mat-tab-group mat-align-tabs="start" class="width-100 h3-tab-label contact-tabs height-100" [(selectedIndex)]="selectedIndex" [ngClass.xs]="permissionsList &amp;&amp; permissionsList[&apos;HAS_TEAM&apos;] ? &apos;mobile-tab mobile-tab-label&apos; : &apos;mobile-tab&apos;" animationDuration="0" (selectedIndexChange)="tabChange($event)">
      <mat-tab label="MY {{label.deals | uppercase}}" class="height-100" [disabled]="!ready">
        <bh-mydeals class="height-100" [pageSize]="pageSize" [pageOffset]="pageOffset" (totalCount)="totalCountEvent(mydeals, $event.value)" #mydeals [media_view]="activeMediaQuery" (paginationEvent)="paginate($event)" [totalCountP]="totalCount" [pageSizeP]="pageSize" [pageOffsetP]="pageOffset" [readyP]="ready" (selectedDeals)="selectedDeals($event)" (displayedDealsCount)="displayedDealsCount($event)"></bh-mydeals>
      </mat-tab>
      <mat-tab label="TEAM {{label.deals | uppercase}}" *ngxPermissionsOnly="[&apos;HAS_TEAM&apos;]" [disabled]="!ready">
        <bh-teamdeals class="height-100" [pageSize]="pageSize" [pageOffset]="pageOffset" (totalCount)="totalCountEvent(teamdeals, $event.value)" #teamdeals [media_view]="activeMediaQuery" [totalCountP]="totalCount" [pageSizeP]="pageSize" [pageOffsetP]="pageOffset" [readyP]="ready" (paginationEvent)="paginate($event)"></bh-teamdeals>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>