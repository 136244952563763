/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-filtercount',
    templateUrl: './filtercount.template.html'
})

export class filtercountComponent extends NBaseComponent implements OnInit {
    @Input('labelsCount') labelsCount = 0;
    @Input('labels') labels = [];
    @ViewChildren('tooltip') tooltip;

    constructor(private cacheService: cacheservice, private _ps: NPubSubService) {
        super();
    }

    ngOnInit() {
        
    }
    clearFilters() {
        this.cacheService.setc(null, 'MY_CONTACT_FILTER');
        this._ps.$pub('refreshcontactlist');
        this._ps.$pub('refreshfilter');
    }
    tooltipToggle() {
        this.tooltip._results['0'].show()
    }
}
