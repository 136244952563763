/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { activities_allComponent } from 'app/components/activities_allComponent/activities_all.component';
import { activities_appointmentsComponent } from 'app/components/activities_appointmentsComponent/activities_appointments.component';
import { activities_callsComponent } from 'app/components/activities_callsComponent/activities_calls.component';
import { activities_tasksComponent } from 'app/components/activities_tasksComponent/activities_tasks.component';
import { activityservice } from 'app/sd-services/activityservice';
import { environment } from 'environments/environment';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services'
interface Activity {
    component: string; // paratmeter for UI specifying which component will be loaded
    activityArr: any; // Parameter for UI specifying the Input values for the component
    audit_desc: string; //audit log status title
    activityTitle: any; // Stepper title
    user_name: any; // Activity username label
    created_at; //Activity created date
    event_title: string; //Appointment event title
    start_time; //Appointment start time
    end_time; //Appointment end time
    event_desc: string; //event description for the appointemt
    to_phone_number: any; //call log cell number
    audioFilepath; //call recording file variable
    videoFilepath; //video recording file variable
    task_title: string; //created task title
    task_status: any; //created task status
    due_date; //task due date
    task_description: string; //created task description
    showViewOlderBtn: boolean; //show and hide view older button based on condition
}
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-contactactivities',
    templateUrl: './contactactivities.template.html'
})

export class contactactivitiesComponent extends NBaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input('contactid') contactid;

    activities = [
        { key: 'getAllActivities', value: 'Activities' },
        { key: 'getAppointments', value: 'Appointments' },
        { key: 'getCalls', value: 'Calls' },
        { key: 'getTasks', value: 'Tasks' }
    ]
    selectedActivity = 'getAllActivities';
    selectedComponent;
    activityArr = [];
    offset = 0;
    showViewOlderBtn = false;
    audioFilepath;
    activityCount;
    videoFilepath;
    refreshActSub;
    disableCheck = false;

    constructor(private activityService: activityservice, private pubsub: NPubSubService) {
        super();
        let currentDomain = `${window.location.protocol}//${window.location.hostname}`;
        if (window.location.port && window.location.port.length > 0) {
            currentDomain += `:${window.location.port}`;
        }
        this.audioFilepath = currentDomain + environment.properties.ssdURL + 'calls/recording';
        this.videoFilepath = currentDomain + environment.properties.ssdURL + 'appointment/recording/';
        this.subscribeRefreshActivities();
    }

    ngOnInit() {
        this.refreshActSub = this.pubsub.$sub('refreshevents', () => {
            this.fetchActivities('getTasks');
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.contactid) {
            this.fetchActivities(this.selectedActivity);
        }
    }

    subscribeRefreshActivities() {
        this.refreshActSub = this.pubsub.$sub('refresh-activities', () => {
            this.fetchActivities(this.selectedActivity);
        })
    }

    async fetchActivities(activity_name) {
        this.activityArr = [];
        this.offset = 0;
        this.showViewOlderBtn = false;
        this.disableCheck = true;
        switch (activity_name) {
            case 'getAllActivities':
                this.selectedComponent = activities_allComponent;
                this.fetch('getAllActivities');
                break;
            case 'getCalls':
                this.fetch('getCalls');
                this.selectedComponent = activities_callsComponent;
                break;
            case 'getAppointments':
                this.fetch('getAppointments');
                this.selectedComponent = activities_appointmentsComponent;
                break;
            case 'getTasks':
                this.selectedComponent = activities_tasksComponent;
                this.fetch('getTasks');
                break;
        }
        this.selectedActivity = activity_name;
    }

    fetch(fn): Promise<any> {
        return this.activityService[fn]('contacts', this.contactid, this.offset)
            .then((result: {
                input: {}
                local: {
                    activityData: {
                        data: any
                    }
                }
            }) => {
                if (result.local.activityData && result.local.activityData.data instanceof Array) {
                    if (result.local.activityData.data.length > 0) {
                        if (this.offset > 0) {
                            this.activityArr = this.activityArr.concat(result.local.activityData.data);
                        } else {
                            this.activityArr = result.local.activityData.data;
                        }
                        this.remapTitle();
                        result.local.activityData.data.filter((ele, i) => {
                            this.activityCount = result.local.activityData.data[i].count;
                        })
                        if (this.activityCount == this.activityArr.length) {
                            this.showViewOlderBtn = false;
                        } else {
                            this.showViewOlderBtn = true;
                        }
                    }
                    this.disableCheck = false;
                }
            })
            .catch(e => { });
    }

    refreshActivity(){
        this.fetchActivities(this.selectedActivity);
    }

    remapTitle() {
        this.activityArr = this.activityArr.map(v => {
            v.activityTitle = v.activity_title;
            return v;
        })
    }

    viewOlder() {
        this.offset += 5;
        this.fetch(this.selectedActivity);
    }

    ngOnDestroy() {
        if (this.refreshActSub) {
            this.refreshActSub.unsubscribe();
        }
    }
}
