/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NPubSubService } from 'neutrinos-seed-services';
import { cacheservice } from 'app/sd-services/cacheservice';
import { importcontactservice } from 'app/sd-services/importcontactservice';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-assigncontacts',
    templateUrl: './assigncontacts.template.html',
    styleUrls: ['./assigncontacts.style.scss']
})

export class assigncontactsComponent extends NBaseComponent implements OnInit {
    contactShare: FormArray;
    _tmp = new FormControl();
    fileItem;
    totalAvailableContacts: number = 0;
    totalContactsSelected: number = 0;
    formErrorMessage = "";
    recordid;
    disableAddButton = false;
    constructor(
        private pubsub: NPubSubService,
        private _caches: cacheservice,
        private importContactService:importcontactservice
    ) {
        super();
    }

    ngOnInit() {
        this.contactShare = new FormArray([
            new FormGroup(
                {
                    userid: new FormControl(null,Validators.required),
                    totalContacts: new FormControl(0, [Validators.required, Validators.max(5000), Validators.min(1)])
                }
            )
        ]);
        this.contactShare.valueChanges.subscribe(()=>{
            this.totalContactsSelected = 0;
            
            for(let record of this.contactShare.controls){
                this.totalContactsSelected += (parseInt(record.get('totalContacts').value) || 0 );
            }
            if(this.totalContactsSelected > this.totalAvailableContacts){
                this.formErrorMessage = 'Number of contacts to be assigned cannot be more than available contacts';
            }else{
                this.formErrorMessage = '';
            }
            
        })
        // get cached info for component 
        this._caches.getc('ASSIGN_CONTACT').then((fileItem) => {
            if (!fileItem) {
                return;
            }
            this.fileItem = fileItem.local.data;
            this.totalAvailableContacts = this.fileItem.valid_contacts;
            this.recordid = this.fileItem.importsstagingid;
            this._caches.setc(null, 'ASSIGN_CONTACT')
        });
    }
    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }
    selected(selectedUser) {
    }
    addRecord() {
        this.contactShare.push(
            new FormGroup(
                {
                    userid: new FormControl(null, Validators.required),
                    totalContacts: new FormControl(1, [Validators.required, Validators.max(5000), Validators.min(1)])
                }
            ));
    }
    removeRecord(indx) {
        this.contactShare.removeAt(indx);
    }
    assignTOContacts() {
        if(this.formErrorMessage.length > 0){
            return;
        }
        this.disableAddButton = true;
        this.importContactService.assignContactsToUsers(this.contactShare.value, this.recordid).then((res)=>{
            this.disableAddButton = false;
            this.pubsub.$pub('refreshimporthistory');
            this.close();
        })
        .catch(e => {
            this.disableAddButton = false;
        })
    }
}
