/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { AppointmentFeedbackOutcome } from 'app/components/activities_appointmentsComponent/activities_appointments.component';
import { CallFeedbackOutcome } from 'app/components/activities_callsComponent/activities_calls.component';
import { cacheservice } from 'app/sd-services/cacheservice';
import { feedbackservice } from 'app/sd-services/feedbackservice';
import { tenantservice } from 'app/sd-services/tenantservice';
import { NgxPermissionsService } from 'ngx-permissions';
import { feedbackoutcomessyncService } from '../feedbackoutcomessync/feedbackoutcomessync.service';
import { NeutrinosOAuthClientService } from 'neutrinos-oauth-client';
import { labelService } from '../label/label.service';

@Injectable()
export class settingsService {

    constructor(
        private authService: NeutrinosOAuthClientService,
        private tenantService: tenantservice,
		private _caches: cacheservice,
        private _feedbackService: feedbackservice,
		private permissionService: NgxPermissionsService,
		private _feedbackOutcomesSerivce: feedbackoutcomessyncService,
		private labelService: labelService
    ) {
        
    }
    cachePermissions() {
        this.permissionService.addPermission(this.authService['currentUserInfo']['srmPermissions']);
        if (this.authService['currentUserInfo']['srmReportingUsers'] instanceof Array &&
            this.authService['currentUserInfo']['srmReportingUsers'].length) {
                this.permissionService.addPermission('HAS_TEAM');
            }
    }

    async applyColors() {
        const r = await this.tenantService.getTenantSettings();
        const data = r.local.tenantSettings;
        if (data.COLOR_PRIMARY) {
            this.setPrimaryColor(data.COLOR_PRIMARY);
        }
        if (data.COLOR_SECONDARY) {
            this.setSecondayColor(data.COLOR_SECONDARY);
        }
    }

    private setPrimaryColor(primaryColor) {
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--checked-pipe-color', primaryColor);
        if (this.lightOrDark(primaryColor) == 'dark') {
            document.documentElement.style.setProperty('--text-image-color', '#ffffff');
        } else {
            document.documentElement.style.setProperty('--text-image-color', '#000000');
        }
    }

    private setSecondayColor(secondayColor) {
        document.documentElement.style.setProperty('--secondary-color', secondayColor);
        document.documentElement.style.setProperty('--unchecked-pipe-color', secondayColor);
    }

    private lightOrDark(color): 'light' | 'dark' {
        var r, g, b, hsp;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {

            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = color[1];
            g = color[2];
            b = color[3];
        }
        else {

            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'
            )
            );

            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {

            return 'light';
        }
        else {

            return 'dark';
        }
	}
	
	public async _cacheCallActivitiesData() {
		const outcomes: CallFeedbackOutcome[] = (await this._feedbackService.getFeedbackOutcomes('call')).local.res.data;
		this._feedbackOutcomesSerivce.call_feedback_outcomes = outcomes;
	}

	public async _cacheAppointmentsActivitiesData() {
		const outcomes: AppointmentFeedbackOutcome[] = (await this._feedbackService.getFeedbackOutcomes('appointment')).local.res.data;
		this._feedbackOutcomesSerivce.appointment_feedback_outcomes = outcomes;
	}

	async refreshSettings() {
		await this.authService.getUserInfo();
		await this.tenantService.initTenantSettings(this.authService['currentUserInfo'].tenantSettings);
		this.setDealModuleName();
		await this.applyColors();
		await this._cacheCallActivitiesData();
		await this._cacheAppointmentsActivitiesData();
		this.cachePermissions();
	}

	setDealModuleName() {
		if (this.tenantService.tenantSettings && this.tenantService.tenantSettings.DEAL_MODULE_NAME_SINGULAR &&
			this.tenantService.tenantSettings.DEAL_MODULE_NAME_PLURAL) {
			this.labelService.deal = this.tenantService.tenantSettings.DEAL_MODULE_NAME_SINGULAR;
			this.labelService.deals = this.tenantService.tenantSettings.DEAL_MODULE_NAME_PLURAL;
		}
	}
}
