/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { labelService } from 'app/services/label/label.service';
import { environment } from 'environments/environment';
import { tenant_logo_serviceService } from 'app/services/tenant_logo_service/tenant_logo_service.service';
import { titleService } from 'app/services/title/title.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-blmenu',
    templateUrl: './blmenu.template.html'
})

export class blmenuComponent extends NBaseComponent implements OnInit {
    @Input() MenuList;
    @Output() out = new EventEmitter();
    @Output() menuTrigger = new EventEmitter();
    selectedMenu = 'Summary';
    @Input() matMenu: any;
    leadsList = [];
    ssdURL = environment.properties.ssdURL;
    tenantIcon = `${this.ssdURL}tenant-icon`;
    showMainLogo = true;
    showneutrinosLogo = false;
    @Input() sideNavOpened = false;

    menuMap = {
        Deals: '/home/deals',
        Contacts: '/home/contacts',
        DailySummary: '/home/summary',
        Dashboard: 'home/dashboard',
        Settings: '/home/settings',
        Calendar: '/home/calendar',
        importContactStatus: '/home/import-contact-status'
    }

    constructor(private router: Router,
        public label: labelService,
        private tenantLogoService: tenant_logo_serviceService,
        private titleservice: titleService) {
        super();
        const values = Object.entries(this.menuMap);
        this.titleservice.getActiveMenu(values);
    }

    ngOnInit() {
        this.checkTenantLogoExist();
        if (this.titleservice.activeLabel) {
            this._setActiveMenu(this.titleservice.activeLabel);
        }
    }

    private checkTenantLogoExist() {
        this.tenantLogoService.checkTenantLogoExist()
            .subscribe(() => {
            }, resObj => {
                if (resObj.status !== 200) {
                    this.showneutrinosLogo = true;
                    this.showMainLogo = false;
                }
            });
    }

    private _setActiveMenu(menu) {
        this.selectedMenu = menu || this.selectedMenu;
    }

    navigateRoute(val) {
        if (!this.sideNavOpened) {
            this.selectedMenu = val;
            this.out.emit({ val });
            if (this.menuMap[val]) {
                this.router.navigate([this.menuMap[val]]);
            }
        }
    }

    menuToggle() {
        this.menuTrigger.emit();
    }
}
