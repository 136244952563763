<div fxLayout="column wrap" fxLayoutGap="1em" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" fxFill *ngIf="mydeals.length === 0">
  <div class="no-deal-image">
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <h2 class="light-gray-color">
      No Team {{label.deals | titlecase}} Available</h2>
  </div>
</div>
<div fxLayout="column " class="height-100 common-lead-list-padding" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="mydeals.length &gt; 0" [ngClass.xs]="&apos;circle-padding absolute-position width-100&apos;">
  <div fxLayout="row wrap" class="lead-list-header" fxFlex="48px" fxLayoutGap="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div fxLayout="row " class="lr-padding" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;lead-header-field-gt-xs&apos;" *ngFor="let header of headers" [fxFlex.sm]="header.displayText != &apos;Last Updated On&apos; ? &apos;15&apos; : &apos;21&apos;">
        <h3 class="color-view">
          {{header.displayText}}</h3>
        <bh-sort [sortKey]="header.sortKey" [sort]="header.sortValue" (changeEvent)="sortChange($event)" *ngIf="header.sort" [ngClass]="(header.sortKey != currentSortKey) ? &apos;not-sorting-icon&apos; : &apos;&apos;"></bh-sort>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y" fxFlex="calc(100% - 48px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="&apos;border-radius-4 circle-padding background-primary&apos;">
    <div fxLayout="column " class="overflow-y" fxFlex="calc(100% - 48px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
        <div fxLayout="column wrap" class="leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let deal of mydeals" (click)="navigateToDeal(deal.dealid, deal.contactid)" [fxHide.xs]="true">
          <div fxLayout="row wrap" class="cursor-pointer color-black" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="deal">
            <div fxLayout="row wrap" class="display-grid lr-padding leads-height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="15">
              <h3 class="font-normal-size text-ellipsis">
                <div *ngIf="deal.deal_name">{{deal.deal_name}}</div>
                <div *ngIf="!deal.deal_name" class="label-font-color">-------</div>
              </h3>
            </div>
            <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="15">
              <h3 class="font-normal-size text-ellipsis">
                <div *ngIf="deal.contact_display_name">{{deal.contact_display_name}}</div>
                <div *ngIf="!deal.contact_display_name" class="label-font-color">-------</div>
              </h3>
            </div>
            <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="15">
              <h3 class="font-normal-size text-ellipsis">
                <div *ngIf="deal.currencyid || deal.deal_value">{{deal.currencyid | currencySymbol | async}} {{deal.deal_value}}</div>
                <div *ngIf="!deal.currencyid &amp;&amp; !deal.deal_value" class="label-font-color">-------</div>
              </h3>
            </div>
            <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="15">
              <h3 class="font-normal-size">
                <div *ngIf="deal.deal_pipe_name">{{deal.deal_stage_name}}</div>
                <div *ngIf="!deal.deal_pipe_name" class="label-font-color">-------</div>
              </h3>
            </div>
            <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="15">
              <h3 class="font-normal-size">
                <div *ngIf="deal.contact_owner_name">{{deal.contact_owner_name}}</div>
                <div *ngIf="!deal.contact_owner_name" class="label-font-color">-------</div>
              </h3>
            </div>
            <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="21">
              <h3 class="font-normal-size">
                <!-- {{val.lead_last_updated_at}} -->
                <div *ngIf="deal.deal_updated_at">{{deal.deal_updated_at | date: &apos;dd&apos;}} - {{deal.deal_updated_at | date:&apos;MM&apos;}} - {{deal.deal_updated_at | date: &apos;yyyy&apos;}}, {{deal.deal_updated_at | date: &apos;HH:mm&apos;}}</div>
                <div *ngIf="!deal.deal_updated_at" class="label-font-color">-------</div>
              </h3>
            </div>
          </div>
        </div>
        <div [ngClass.sm]="&apos;deal-page-label&apos;" id="&apos;common-pagination-teamdeals&apos;" class="pagination-gt-xs common-pagination">
          <mat-paginator [length]="totalCountP" [pageSize]="pageSizeP" [pageIndex]="pageOffsetP" [pageSizeOptions]="[50, 200, 500]" (page)="pageEvents($event)" [disabled]="!readyP"></mat-paginator>
        </div>
      </div>
    </div>
    <div fxLayout="column wrap" class="height-100" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
      <div [fxHide.gt-xs]="true" class="overlay-scroll search-results height-100">
        <cdk-virtual-scroll-viewport infinite-scroll style="height: 100%" [itemSize]="166" [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="200" [scrollWindow]="false" (scrolled)="onScroll()">
          <div fxLayout="column wrap" class="display-block leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *cdkVirtualFor="let deal of mydeals;trackBy: trackByFn" (click)="navigateToDeal(deal.dealid, deal.contactid)" [ngClass.xs]="&apos;menu-margin-xs&apos;">
            <div fxLayout="row wrap" class="cursor-pointer color-black" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="deal" [ngClass.xs]="&apos;border-lightgray-1px border-radius-4 mobile-tap-view&apos;" [fxFlex.gt-xs]="100">
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Name : </span>{{deal.deal_name}}</span>
                  <span *ngIf="!deal.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Name : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.contact_display_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Contact Name : </span>{{deal.contact_display_name}}</span>
                  <span *ngIf="!deal.contact_display_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Contact Name : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.currencyid || deal.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Value : </span>{{deal.currencyid | currencySymbol | async}}
                  {{deal.deal_value}}</span>
                  <span *ngIf="!deal.currencyid &amp;&amp; !deal.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Value : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="text-ellipsis lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Stage Name : </span>{{deal.deal_stage_name}}</span>
                  <span *ngIf="!deal.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Stage Name : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="text-ellipsis lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.contact_owner_name"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Owner : </span>{{deal.contact_owner_name}}</span>
                  <span *ngIf="!deal.contact_owner_name"><span class="font-weight-size" [fxHide.gt-xs]="true"> {{label.deal | titlecase}} Owner : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="text-ellipsis lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size">
                  <!-- {{val.lead_last_updated_at}} -->
                  <span *ngIf="deal.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span>{{deal.deal_updated_at | date: &apos;dd&apos;}}
                  - {{deal.deal_updated_at | date:&apos;MM&apos;}} -
                  {{deal.deal_updated_at | date: &apos;yyyy&apos;}},
                  {{deal.deal_updated_at | date: &apos;HH:mm&apos;}}</span>
                  <span *ngIf="!deal.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date: </span><span class="label-font-color">------</span>
                  </span></h3>
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</div>