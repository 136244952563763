<div class="width-100">
  <mat-form-field class="phone-country-code-autocomplete width-100 search-field" [floatLabel]="&apos;always&apos;">
    <input type="text" aria-label="Number" matInput placeholder="Type and Select" [formControl]="fc" [matAutocomplete]="auto" (focus)="autoCompleteFocused()" #fci>
    <img matSuffix src="assets/Web/Icons/ac-clear-icon.svg" class="autocomplete-clear" *ngIf="fc.value" (click)="clear()">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of filteredCountries | async;" [value]="option.nationality">
        {{option.nationality}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>