
import {Component, NgModule, VERSION, Pipe, PipeTransform} from '@angular/core'
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'
import { srmappservice } from 'app/sd-services/srmappservice';
const currencyObj = {};

@Pipe({
    name: 'currencySymbol'
})
export class currencySymboleByIdPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, public srmappS: srmappservice){}
   async transform(currencyId: any) {
    let currencyCodes;

    if (Object.keys(currencyObj).length === 0) {
      const result: {
        input: {},
        local: {
            currencycodes: any[]
        }
      } = await this.srmappS.getCurrencyCodes();
      currencyCodes = result.local.currencycodes;
      for (let i = 0; i < currencyCodes.length; i++) {
        currencyObj[currencyCodes[i].currencyid] = currencyCodes[i].currency_symbol;
      }
    }
    return currencyObj[currencyId] ? currencyObj[currencyId] : '';
  }
}
