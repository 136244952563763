<ul fxLayout="row" [fxHide.xs]="true" [ngClass.sm]="&apos;pipeline-sm&apos;" class="width-100 pipeline">
  <div class="width-100">
    <li *ngFor="let pipe of pipeline;" (click)="updatePipe(pipe)" fxFlex [ngClass]="(pipe.checked === true) ? &apos;checked&apos;: &apos;unchecked&apos;">
      <h4 class="pipe-right-margin text-ellipsis" [ngStyle.md]="&apos;width:50px&apos;" [ngClass.md]="&apos;pipe-margin-md&apos;" [ngClass.sm]="&apos;pipe-margin-md&apos;">
        {{pipe?.pipe_name}}
      </h4>
    </li>
    <li [matMenuTriggerFor]="pipetask" fxFlex [ngClass]="{&apos;checked&apos;: statusCssFlag, &apos;unchecked&apos;: !statusCssFlag, &apos;disable-element&apos;: accessDenied}" *ngIf="pipeline &amp;&amp; pipeline.length">
      <h4 class="pipe-right-margin text-ellipsis" [ngStyle.md]="&apos;width:50px&apos;">
        {{status_label}}
      </h4>
      <img class="pipe-down-image" [ngClass.lg]="&apos;pipe-left-margin&apos;" *ngIf="pipe?.pipeid == null" src="assets/Web/Icons/down_arrow.svg">
    </li>
  </div>
</ul>
<mat-menu #pipetask class="pipe-menu-item" xPosition="before" #menu="matMenu"><button mat-menu-item *ngFor="let status of statusesFiltered" (click)="statusChange(status)" class="border-light-gray common-menu-item-align font-Weight-size font-Family generic-text-color"> {{status.label}} </button></mat-menu>
<div fxLayout="row wrap" style="height: 40px;" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;min-height: 40px;width: 94%;border-radius: 0px 0px 6px 6px;&apos;" [fxHide.gt-xs]="true">
  <div fxLayout="row wrap" class="height-100" fxFlex="28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <h3>
      {{label.deal | titlecase}} Stage: </h3>
  </div>
  <div fxLayout="row wrap" class="height-100" fxFlex="68" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <div>
      <h3 [matMenuTriggerFor]="dealpipetask">{{(selected_status == &apos;Ongoing&apos;) ? selected_pipe_name : selected_status}}</h3>
    </div><img imgSrc="/Web/Icons/down.svg" [matMenuTriggerFor]="dealpipetask">
  </div>
</div>
<mat-menu #dealpipetask class="pipe-stage-menu" xPosition="before" #menu="matMenu">
  <div>
    <button *ngFor="let pipe of pipeline" (click)="updatePipe(pipe)" mat-menu-item>{{pipe.pipe_name}}</button>
    <button [matMenuTriggerFor]="stagestatus" mat-menu-item>{{status_label}}</button></div>
</mat-menu>
<mat-menu #stagestatus class="pipe-stage-menu" xPosition="before" #menu="matMenu">
  <div>
    <button *ngFor="let pipe_status of statusesFiltered" (click)="statusChange(pipe_status)" mat-menu-item>{{pipe_status.label}}</button></div>
</mat-menu>