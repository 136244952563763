//__IMPORT_MODULES_HERE__
//__LIBRARY__ngx\-color\-picker
import {ColorPickerModule} from 'ngx-color-picker';
//__END__LIBRARY__ngx\-color\-picker
//__LIBRARY__@angular/cdk/drag\-drop
import {DragDropModule} from '@angular/cdk/drag-drop';
//__END__LIBRARY__@angular/cdk/drag\-drop

//__LIBRARY__ngx\-permissions
import {NgxPermissionsModule} from 'ngx-permissions';
//__END__LIBRARY__ngx\-permissions


//__LIBRARY__ngx\-trim\-directive
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
//__END__LIBRARY__ngx\-trim\-directive

//__LIBRARY__ng\-dynamic\-component
import {DynamicComponentModule} from 'ng-dynamic-component';
//__END__LIBRARY__ng\-dynamic\-component
import {NgModule } from '@angular/core';
import { sdProviders} from './sd-providers';
import { environment } from 'environments/environment';
@NgModule({
  imports: [
    //__CUSTOM_IMPORTS_MODULES__
    //__CUSTOM_IMPORTS_MODULES_END__
    //__IMPORTS_MODULES__
    
//__IMPORTED_MODULE__DynamicComponentModule
DynamicComponentModule,
//__IMPORTED_MODULE__DynamicComponentModule__END
//__IMPORTED_MODULE__NgxTrimDirectiveModule
NgxTrimDirectiveModule,
//__IMPORTED_MODULE__NgxTrimDirectiveModule__END

//__IMPORTED_MODULE__NgxPermissionsModule
NgxPermissionsModule.forRoot(),
//__IMPORTED_MODULE__NgxPermissionsModule__END
//__IMPORTED_MODULE__DragDropModule
DragDropModule,
//__IMPORTED_MODULE__DragDropModule__END
//__IMPORTED_MODULE__ColorPickerModule
ColorPickerModule,
//__IMPORTED_MODULE__ColorPickerModule__END
//__IMPORTS_MODULES_END__
  ],
  providers: [...sdProviders],
  exports: [
    //__EXPORTS_MODULES__
    
//__EXPORTED_MODULE__DynamicComponentModule
DynamicComponentModule,
//__EXPORTED_MODULE__DynamicComponentModule__END
//__EXPORTED_MODULE__NgxTrimDirectiveModule
NgxTrimDirectiveModule,
//__EXPORTED_MODULE__NgxTrimDirectiveModule__END

//__EXPORTED_MODULE__NgxPermissionsModule
NgxPermissionsModule,
//__EXPORTED_MODULE__NgxPermissionsModule__END
//__EXPORTED_MODULE__DragDropModule
DragDropModule,
//__EXPORTED_MODULE__DragDropModule__END
//__EXPORTED_MODULE__ColorPickerModule
ColorPickerModule,
//__EXPORTED_MODULE__ColorPickerModule__END
//__EXPORTS_MODULES_END__
  ]
})
export class DependenciesModule { }