<div fxLayout="column wrap" class="line-height-view" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="activity">
  <div class="all-activities-details">
    <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/user_icon.svg" class="circle-icon"></div>
      <div>
        {{activity?.user_name}}</div>
      <div fxLayout="row wrap" class="circle-icon" fxLayoutGap="5px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/clock_icon.svg" class="circle-icon"></div>
      <div>
        {{activity?.created_at | date: &apos;dd&apos;}} - {{activity?.created_at | date:&apos;MM&apos;}} - {{activity?.created_at | date: &apos;yyyy&apos;}}, {{activity?.created_at | date: &apos;HH:mm&apos;}}</div>
    </div><span class="appointment-details-label" *ngIf="activity?.details?.deal_name"><span>{{dealNameLabel | titlecase}}: </span><span class="font-normal-size">{{activity?.details?.deal_name}}</span><br></span>
    <span class="appointment-details-label">Status: </span>{{activity?.status ? &apos;Scheduled&apos; : &apos;Cancelled&apos;}}<br>
    <span class="appointment-details-label">Appointment Date: </span>{{activity?.start_time | date: &apos;dd&apos;}} - {{activity?.start_time | date:&apos;MM&apos;}} - {{activity?.start_time | date: &apos;yyyy&apos;}}, {{activity?.start_time | date: &apos;HH:mm&apos;}} to {{activity?.end_time | date: &apos;dd&apos;}} - {{activity?.end_time | date:&apos;MM&apos;}} - {{activity?.end_time | date: &apos;yyyy&apos;}}, {{activity?.end_time | date: &apos;HH:mm&apos;}}<br>
    <span class="appointment-details-label">Description: </span><span class="common-word-wrap">{{activity?.event_description}}</span><br>
    <span *ngFor="let recording of activity.recordingArr">
<span *ngIf="activity.recordingArr"><img class="video-icon" src="assets/Web/Icons/video-camera.svg"><span class="padding-0-1 cursor-pointer" style="text-decoration: underline" (click)="showRecording(recording)">View Video Recording</span><br></span>
    </span>
  </div>
  <div fxLayout="row " fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="activity?.appointment_feedback_id">
    <div *ngIf="activity?.appointment_feedback_id" class="all-activities-details">
      <span class="font-weight-size">Outcome : </span> <span class="common-word-wrap">{{getOutcomeLabel()}}</span></div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="(activity?.contact_owner == userservice?.user?.srmUserid)">
      <div (click)="openForm()" class="edit-feedback-image cursor-pointer">
      </div>
    </div>
  </div>
  <div fxLayout="row " fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="activity?.feedback_notes">
    <div fxFlex="90% 0 0" class="all-activities-details">
      <span class="font-weight-size">Feedback : </span> <span class="common-word-wrap">{{activity.feedback_notes}}</span></div>
  </div><button mat-raised-button class="width-fit-content add-feedback-btn" *ngIf="!activity?.appointment_feedback_id &amp;&amp; (activity?.contact_owner == userservice?.user?.srmUserid)" color="var(--primary-color)" (click)="openForm()">Add Feedback</button>
</div>