/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class dealservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_dealservice

  async createDeal(
    deal_name: any = undefined,
    deal_currency: any = undefined,
    deal_value: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          deal_name: deal_name,
          deal_currency: deal_currency,
          deal_value: deal_value,
          contactid: contactid
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_bb4vSdsqrHIw3iBL(bh);
      //appendnew_next_createDeal
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_yAaBacWDT9Cp4DDy');
    }
  }

  async getDealById(
    dealid: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          dealid: dealid,
          contactid: contactid
        },
        local: {
          dealsValue: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_iLIoCizVyaR0A1cO(bh);
      //appendnew_next_getDealById
      return (
        // formatting output variables
        {
          input: {},
          local: {
            dealsValue: bh.local.dealsValue
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_GeiIvTkRXY3KBNzw');
    }
  }

  async getMyDeals(
    pageSize: any = undefined,
    pageOffset: any = undefined,
    sortBy: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          sortBy: sortBy,
          contactid: contactid
        },
        local: {
          dealsValue: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_jS4J7t0WbHymnhjm(bh);
      //appendnew_next_getMyDeals
      return (
        // formatting output variables
        {
          input: {},
          local: {
            dealsValue: bh.local.dealsValue
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HN70id4gWjtGurlh');
    }
  }

  async updateDeal(
    body: any = undefined,
    dealid: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          body: body,
          dealid: dealid,
          contactid: contactid
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_9TSkqombEe9InWuV(bh);
      //appendnew_next_updateDeal
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_aLmmZdysiHqFrixa');
    }
  }

  async getTeamDeals(
    pageSize: any = undefined,
    pageOffset: any = undefined,
    sortBy: any = undefined,
    managerid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          sortBy: sortBy,
          managerid: managerid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_TEZg55F2wwGiu5C3(bh);
      //appendnew_next_getTeamDeals
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_xxdnrKeJpEnSSSGN');
    }
  }

  async getTeamContactDeals(
    pageSize: any = undefined,
    pageOffset: any = undefined,
    sortBy: any = undefined,
    contactid: any = undefined,
    userid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          sortBy: sortBy,
          contactid: contactid,
          userid: userid
        },
        local: {
          dealsValue: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_RASY65jT9mXbRl7M(bh);
      //appendnew_next_getTeamContactDeals
      return (
        // formatting output variables
        {
          input: {},
          local: {
            dealsValue: bh.local.dealsValue
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_uxjISZvHM4HhEOy0');
    }
  }

  async deleteDeal(dealid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          dealid: dealid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_0D4NRkghEbk0EBNH(bh);
      //appendnew_next_deleteDeal
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_uQM74NTYkQUnmG4f');
    }
  }

  async exportDeals(dealsIds: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          dealsIds: dealsIds
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_4gbW3vPy693JZLaW(bh);
      //appendnew_next_exportDeals
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_UxZhoB5pD3oAkQY0');
    }
  }

  //appendnew_flow_dealservice_Start

  async sd_bb4vSdsqrHIw3iBL(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'deal';

      bh.body = {
        deal_name: bh.input.deal_name,
        deal_value: bh.input.deal_value,
        contactid: bh.input.contactid,
        currencyid: bh.input.deal_currency
      };
      bh = await this.sd_jiZ7loTHofaqIoa1(bh);
      //appendnew_next_sd_bb4vSdsqrHIw3iBL
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bb4vSdsqrHIw3iBL');
    }
  }

  async sd_jiZ7loTHofaqIoa1(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_jiZ7loTHofaqIoa1
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jiZ7loTHofaqIoa1');
    }
  }

  async sd_iLIoCizVyaR0A1cO(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'deals/contact/' +
        bh.input.contactid +
        '/deal/' +
        bh.input.dealid;

      bh = await this.sd_zSXu5IPYPtiy5WXw(bh);
      //appendnew_next_sd_iLIoCizVyaR0A1cO
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_iLIoCizVyaR0A1cO');
    }
  }

  async sd_zSXu5IPYPtiy5WXw(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.dealsValue = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_zSXu5IPYPtiy5WXw
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_zSXu5IPYPtiy5WXw');
    }
  }

  async sd_jS4J7t0WbHymnhjm(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'deals/my?';

      if (bh.input.contactid) {
        bh.url += 'contactid=' + bh.input.contactid;
      }

      if (bh.input.pageSize) {
        bh.url += '&pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }

      if (bh.input.sortBy) {
        bh.url += '&sortBy=' + bh.input.sortBy;
      }

      bh = await this.sd_E9DP01YIZF2DxoYv(bh);
      //appendnew_next_sd_jS4J7t0WbHymnhjm
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jS4J7t0WbHymnhjm');
    }
  }

  async sd_E9DP01YIZF2DxoYv(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.dealsValue = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_E9DP01YIZF2DxoYv
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_E9DP01YIZF2DxoYv');
    }
  }

  async sd_9TSkqombEe9InWuV(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'deal/' +
        bh.input.dealid +
        '/contact/' +
        bh.input.contactid;

      bh = await this.sd_bGq6npx5JxW0w1pX(bh);
      //appendnew_next_sd_9TSkqombEe9InWuV
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_9TSkqombEe9InWuV');
    }
  }

  async sd_bGq6npx5JxW0w1pX(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.body
      };
      bh.local.dealsValue = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_bGq6npx5JxW0w1pX
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bGq6npx5JxW0w1pX');
    }
  }

  async sd_TEZg55F2wwGiu5C3(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'deals/teams?';

      if (bh.input.managerid) {
        bh.url += 'managerid=' + bh.input.managerid;
      }

      if (bh.input.pageSize) {
        bh.url += '&pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }

      if (bh.input.sortBy) {
        bh.url += '&sortBy=' + bh.input.sortBy;
      }

      bh = await this.sd_XWj52ScpgO8Qu45K(bh);
      //appendnew_next_sd_TEZg55F2wwGiu5C3
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TEZg55F2wwGiu5C3');
    }
  }

  async sd_XWj52ScpgO8Qu45K(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_XWj52ScpgO8Qu45K
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_XWj52ScpgO8Qu45K');
    }
  }

  async sd_RASY65jT9mXbRl7M(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'deals/contact/' +
        bh.input.contactid +
        '?';

      if (bh.input.userid) {
        bh.url += 'userid=' + bh.input.userid;
      }

      if (bh.input.pageSize) {
        bh.url += '&pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }

      if (bh.input.sortBy) {
        bh.url += '&sortBy=' + bh.input.sortBy;
      }

      bh = await this.sd_yAJebbY3axaQ0zlq(bh);
      //appendnew_next_sd_RASY65jT9mXbRl7M
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_RASY65jT9mXbRl7M');
    }
  }

  async sd_yAJebbY3axaQ0zlq(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.dealsValue = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_yAJebbY3axaQ0zlq
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_yAJebbY3axaQ0zlq');
    }
  }

  async sd_0D4NRkghEbk0EBNH(bh) {
    try {
      bh.local.url = `${bh.system.environment.properties.ssdURL}deal/${bh.input.dealid}`;

      bh = await this.sd_HnwF8vyy7vTmQEVA(bh);
      //appendnew_next_sd_0D4NRkghEbk0EBNH
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0D4NRkghEbk0EBNH');
    }
  }

  async sd_HnwF8vyy7vTmQEVA(bh) {
    try {
      let requestOptions = {
        url: bh.local.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_HnwF8vyy7vTmQEVA
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HnwF8vyy7vTmQEVA');
    }
  }

  async sd_4gbW3vPy693JZLaW(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'export/deals';
      bh.body = {
        deal_ids: bh.input.dealsIds
      };
      bh = await this.sd_LVUKHMzIjmxguP2z(bh);
      //appendnew_next_sd_4gbW3vPy693JZLaW
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_4gbW3vPy693JZLaW');
    }
  }

  async sd_LVUKHMzIjmxguP2z(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'text',
        reportProgress: undefined,
        headers: { 'Content-Type': 'application/json' },
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_LVUKHMzIjmxguP2z
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_LVUKHMzIjmxguP2z');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_dealservice_Catch
}
