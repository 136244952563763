<div fxLayout="column " class="height-100" fxFlex="65" fxLayoutGap="8px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="activityArr.length == 0" [fxFlex.xs]="100"><img imgSrc="Web/Icons/no_activities.svg" style="width: 26%;">
  <h3 class="light-gray-color">
    No Record Available</h3>
</div>
<div fxLayout="column wrap" class="overflow-hidden display-block height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="activityArr.length &gt; 0">
  <mat-vertical-stepper [linear]="true" class="white-space-label">
    <mat-step *ngFor="let activity of activityArr" fxFlex="100">
      <ng-template matStepLabel>
        <div class="flex-row-nowrap color-black step-title-label" style="align-items: center;">
          <div *ngIf="activity.activity_title_prefix_icon" style="margin-right: 0.5rem" [attr.class]="activity.activity_title_prefix_icon"></div>
          <span class="font-weight-size" (click)="viewTaskandAppointmentDetails(activity)">{{activity.activityTitle}}</span>
          <div *ngIf="activity.activity_title_postfix_icon" style="margin-right: 0.5rem" [attr.class]="activity.activity_title_postfix_icon"></div>
        </div>
      </ng-template>
      <div>
        <ndc-dynamic [ndcDynamicComponent]="component" [ndcDynamicInputs]=" { &apos;activity&apos;: activity, &apos;audioFilepath&apos;: audioFilepath, &apos;videoFilepath&apos;: videoFilepath }">
        </ndc-dynamic>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <div fxLayout="row wrap" class="view-older-div" fxLayoutAlign="  stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="view-older" *ngIf="showViewOlderBtn" (click)="viewOlder()" [disabled]="false" [disableRipple]="true">View Older</button></div>
</div>