<div fxLayout="column " class="height-100" fxLayoutGap="20px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="emailData[&apos;emailType&apos;] == &apos;new&apos;">
    <div fxLayout="row wrap" style="order: -1;" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="90" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <mat-icon>email</mat-icon><img imgSrc="Web/Icons/microphone.svg" *ngIf="false" class="add-contact-icon">
          <h2>
            NEW EMAIL</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="10" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" style="padding: 0px 10px;" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <form [formGroup]="newEmailForm" class="width-100">
        <div fxLayout="row wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="emailData[&apos;emailType&apos;] == &apos;new&apos;">
          <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <span matPrefix>From: &#xA0;</span>
              <span matPrefix>{{emailFrom}}</span></div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <mat-form-field class="email-to">
                <input matInput placeholder formControlName="to_email" name="to_email" type class>
                <span matPrefix>To: &#xA0;</span>
                <span matSuffix *ngIf="!isCc &amp;&amp; !isBcc" (click)="isBcc = true"> Bcc</span>
                <span matSuffix *ngIf="!isCc &amp;&amp; !isBcc" (click)="isCc = true"> Cc</span>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="isCc">
          <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <mat-form-field class="email-to">
                <input matInput placeholder type formControlName="cc_email" name="cc_email" class>
                <span matPrefix>Cc: &#xA0;</span>
                <span matSuffix *ngIf="!isBcc" (click)="isBcc = true"> Bcc</span>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="isBcc">
          <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <mat-form-field class="email-to">
                <input matInput placeholder type formControlName="bcc_email" name="bcc_email" class>
                <span matPrefix>Bcc: &#xA0;</span>
                <span matSuffix *ngIf="!isCc" (click)="isCc = true"> Cc</span>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="emailData[&apos;emailType&apos;] == &apos;new&apos;">
          <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <mat-form-field class="email-to">
                <input matInput placeholder formControlName="emailTopicLine" name="emailTopicLine" type class>
                <span matPrefix>Subject: &#xA0;</span>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" style="width:100%;padding-top:1em;" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div class="width-100 email-editor">
            <ckeditor [editor]="Editor" id="text-editor" [config]="config" formControlName="emailContent">
            </ckeditor>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="imgArr &amp;&amp; imgArr.length&gt;0">
    <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let item of imgArr; let i = index;">
      <div fxLayout="row wrap" style="padding: 0px 10px;" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div *ngIf="fileObj" style="     color: #2e2ebf;">
            {{item[&apos;fileName&apos;]}} &#xA0; &#xA0;{{item[&apos;fileSize&apos;]}} MB</div>
        </div>
        <div fxLayout="column wrap" style="     font-weight: bold;" fxFlex="10" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
          <mat-icon style="font-weight: bold;cursor: pointer;" (click)="removeUpload(item,i)">close</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column " fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" *ngIf="!false">
    <div fxLayout="column wrap" class="add-lead-content lr-padding buttons-pad" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="center space-between" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="30" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row " fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="saveSetting(newEmailForm)" [disabled]="disableAddButton">{{emailData[&apos;emailType&apos;] == &apos;reply&apos; ?&apos;Reply&apos;: &apos;Save&apos;}}</button>
            <div *ngIf="!false">
              <div class="dropzone" fxLayout="column nowrap" fxLayoutAlign="center center">
                <input [fxShow]="true" type="file" style="display: none;" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)">
                <mat-icon [fxShow]="true" (click)="fileDropRef.click()">attach_file</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column wrap" fxFlex="70" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-action-button" *ngIf="false" (click)="cancel()" type="button">Cancel</button></div>
      </div>
    </div>
  </div>
</div>