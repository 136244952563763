/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
// import { NLoginService } from 'neutrinos-seed-services';
import { NeutrinosOAuthClientService } from 'neutrinos-oauth-client';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-unauthorized',
    templateUrl: './unauthorized.template.html'
})

export class unauthorizedComponent extends NBaseComponent implements OnInit {

    constructor(private loginService: NeutrinosOAuthClientService) {
        super();
    }

    ngOnInit() {

    }

    login() {
        this.loginService.login('/')
    }

    logout() {
        this.loginService.logout('/');
    }
}
