/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Inject } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-genericdialog',
    templateUrl: './genericdialog.template.html'
})

export class genericdialogComponent extends NBaseComponent implements OnInit {
    private pubsubSubscription;

    constructor(private dialogRef: MatDialogRef<genericdialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private pubsub: NPubSubService) {
        super();
        this.pubsubSubscription = this.pubsub.$sub('closedialog', () => {
            this.closeDialog();
        })
    }

    ngOnInit() {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        if (this.pubsubSubscription) {
            this.pubsubSubscription.unsubscribe();
        }
    }
}
