<div fxLayout="column wrap" class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true" [ngStyle.xs]="&apos;height: 50px&apos;">
    <div fxLayout="row wrap" class="height-100" fxFlex="85" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div class="padding-0-1 mat-select-box">
        <mat-form-field appearance="none">
          <mat-select (selectionChange)="fetchActivities($event.value)" panelClass="panel-view" [(ngModel)]="selectedActivity" [(value)]="selectedActivity" disableOptionCentering>
            <mat-option class="mat-select-option" *ngFor="let activity of activities" value="{{activity.key}}">{{activity.value}}

            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row wrap" fxFlex="15" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button style="  margin-top: 0.4em; margin-right: 1em;" class="action-button" (click)="refreshActivity()">
        <div class="refresh-image"></div>
      </button></div>
  </div>
  <div fxLayout="row " class="width-100 height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;height: calc(100% - 50px)&apos;" [ngClass.xs]="&apos;width-100&apos;">
    <div fxLayout="column " class="overflow-y-only height-100" fxFlex="25" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true" [fxFlex.md]="30" [fxFlex.sm]="30">
      <mat-list>
        <mat-list-item *ngFor="let act of activities" class="activities-list-item"><button mat-button class="h2 activity-list" [ngClass]="{&apos;selectedActivity&apos;: act.key === selectedActivity}" (click)="fetchActivities(act.key)" [disabled]="disableCheck" [disableRipple]="true">{{act.value}}</button></mat-list-item>
      </mat-list>
    </div>
    <div fxLayout="column " class="height-100" fxFlex="75" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.md]="70" [fxFlex.sm]="70">
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngSwitch]="selectedActivity">
        <div fxLayout="column " fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="selectedComponent">
          <bh-timeline class="height-100" [activityArr]="activityArr" [showViewOlderBtn]="showViewOlderBtn" [component]="selectedComponent" [audioFilepath]="audioFilepath" (viewOldData)="viewOlder()" [videoFilepath]="videoFilepath"></bh-timeline>
        </div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="!selectedComponent">
          <h4 class="padding-1">
            Component is not fetched</h4>
        </div>
      </div>
    </div>
  </div>
</div>