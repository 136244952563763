<div fxLayout="column " style="height:70px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " fxLayoutGap="0.5rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="mode === &apos;VIEW&apos;" fxFill="fxFill">
    <div class="label">
      {{placeholder || &apos;Related Contact&apos;}}</div>
    <div (click)="navigateToContactDetails($event)" (keyup.enter)="navigateToContactDetails($event)" tabindex="0" class="width-fit-content nav-link">
      {{displayWith(selectedid)}}</div>
  </div>
  <div *ngIf="intializationComplete &amp;&amp; mode === &apos;EDIT&apos;">
    <mat-form-field class="width-100 search-field" floatLabel="always">

      <mat-label>{{placeholder || &apos;Related Contact&apos;}}</mat-label>
      <input type="text" [formControl]="fc" placeholder="Type and Select" aria-label="Number" matInput [matAutocomplete]="autoContact" (focus)="autoCompleteFocused()" [required]="required" #fci>
      <img matSuffix src="assets/Web/Icons/ac-clear-icon.svg" class="autocomplete-clear" *ngIf="fc.value &amp;&amp; !searchInProgress" (click)="clear()">
      <mat-spinner matSuffix *ngIf="searchInProgress"></mat-spinner>
      <mat-autocomplete autoActiveFirstOption #autoContact="matAutocomplete" [displayWith]="dwbind" class="search-autocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let option of filteredcontacts" [value]="option.contactid">
          <div>{{option.display_name}}</div>
          <div class="label" *ngIf="option.contact_email_id">Email: {{option.contact_email_id}}</div>
          <div class="label" *ngIf="option.contact_phone_no">Phone no: {{option.contact_phone_country_code || &apos;&apos;}} {{option.contact_phone_no}}</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>