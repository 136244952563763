/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class importcontactservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_importcontactservice

  async uploadFile(localFile: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          localFile: localFile
        },
        local: {
          response: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_jonzphGXfSSM4WNo(bh);
      //appendnew_next_uploadFile
      return (
        // formatting output variables
        {
          input: {},
          local: {
            response: bh.local.response
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_gWfyKfy2hoInmJ5g');
    }
  }

  async getSample(...others) {
    try {
      var bh = {
        input: {},
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_kNNrrX83uo3gpDBr(bh);
      //appendnew_next_getSample
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ftZeQHtEtFqQjJBF');
    }
  }

  async getContactUploadStatus(
    pageSize: any = undefined,
    pageOffset: any = undefined,
    sortBy: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          sortBy: sortBy
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_9CtLqSuS2D93fmtX(bh);
      //appendnew_next_getContactUploadStatus
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_6thQJaYKIvRQQy2J');
    }
  }

  async assignContactsToUsers(
    usersContactsData: any = undefined,
    recordid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          usersContactsData: usersContactsData,
          recordid: recordid
        },
        local: {
          response: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_ikF7AWZYqPKRe79O(bh);
      //appendnew_next_assignContactsToUsers
      return (
        // formatting output variables
        {
          input: {},
          local: {
            response: bh.local.response
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Ytx6iIYYFLHM2HyX');
    }
  }

  async downloadFile(importsstagingid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          importsstagingid: importsstagingid
        },
        local: {
          response: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_Rc7SUZOMz3zwLxC8(bh);
      //appendnew_next_downloadFile
      return (
        // formatting output variables
        {
          input: {},
          local: {
            response: bh.local.response
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_gJeS5E390ZhxzaIF');
    }
  }

  async cancelFile(importsstagingid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          importsstagingid: importsstagingid
        },
        local: {
          response: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_qNACcCjeUCMzXj2S(bh);
      //appendnew_next_cancelFile
      return (
        // formatting output variables
        {
          input: {},
          local: {
            response: bh.local.response
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wmOZiMdNjcfaecOk');
    }
  }

  //appendnew_flow_importcontactservice_Start

  async sd_jonzphGXfSSM4WNo(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'imports/contact';
      console.log('debug: ', bh.input.localFile, bh.input.localFile.name);
      let formData: FormData = new FormData();
      formData.append(
        'importfile',
        bh.input.localFile,
        bh.input.localFile.name
      );
      bh.local.formData = formData;
      bh = await this.sd_IO4Qav6oKvY5AY7n(bh);
      //appendnew_next_sd_jonzphGXfSSM4WNo
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jonzphGXfSSM4WNo');
    }
  }

  async sd_IO4Qav6oKvY5AY7n(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: { Accept: 'application/json' },
        params: {},
        body: bh.local.formData
      };
      bh.local.response = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_IO4Qav6oKvY5AY7n
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_IO4Qav6oKvY5AY7n');
    }
  }

  async sd_kNNrrX83uo3gpDBr(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'overdue/tasks?';

      bh = await this.sd_bDQ4uOn2XoYEwtpj(bh);
      //appendnew_next_sd_kNNrrX83uo3gpDBr
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_kNNrrX83uo3gpDBr');
    }
  }

  async sd_bDQ4uOn2XoYEwtpj(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json'
        },
        params: {},
        body: bh.local.formData
      };
      bh.response = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_bDQ4uOn2XoYEwtpj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bDQ4uOn2XoYEwtpj');
    }
  }

  async sd_9CtLqSuS2D93fmtX(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'imports/status/contacts?';

      if (bh.input.pageSize) {
        bh.url += 'pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }

      if (bh.input.sortBy) {
        bh.url += '&sortBy=' + bh.input.sortBy;
      }
      bh = await this.sd_DoHYpH4NSoRH762S(bh);
      //appendnew_next_sd_9CtLqSuS2D93fmtX
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_9CtLqSuS2D93fmtX');
    }
  }

  async sd_DoHYpH4NSoRH762S(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_DoHYpH4NSoRH762S
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_DoHYpH4NSoRH762S');
    }
  }

  async sd_ikF7AWZYqPKRe79O(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'imports/assign/' +
        bh.input.recordid;

      bh.local.body = bh.input.usersContactsData;
      bh = await this.sd_1iqbjiO91ewZKMr3(bh);
      //appendnew_next_sd_ikF7AWZYqPKRe79O
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ikF7AWZYqPKRe79O');
    }
  }

  async sd_1iqbjiO91ewZKMr3(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.local.body
      };
      bh.local.response = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_1iqbjiO91ewZKMr3
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1iqbjiO91ewZKMr3');
    }
  }

  async sd_Rc7SUZOMz3zwLxC8(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'imports/download/' +
        bh.input.importsstagingid;

      bh = await this.sd_5PakYmnXn1XA2PpR(bh);
      //appendnew_next_sd_Rc7SUZOMz3zwLxC8
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Rc7SUZOMz3zwLxC8');
    }
  }

  async sd_5PakYmnXn1XA2PpR(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: { Accept: 'application/json' },
        params: {},
        body: undefined
      };
      bh.local.response = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_5PakYmnXn1XA2PpR
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_5PakYmnXn1XA2PpR');
    }
  }

  async sd_qNACcCjeUCMzXj2S(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'imports/cancel/' +
        bh.input.importsstagingid;

      bh = await this.sd_L6RK1XU47d1ks9Xj(bh);
      //appendnew_next_sd_qNACcCjeUCMzXj2S
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_qNACcCjeUCMzXj2S');
    }
  }

  async sd_L6RK1XU47d1ks9Xj(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: { Accept: 'application/json' },
        params: {},
        body: undefined
      };
      bh.local.response = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_L6RK1XU47d1ks9Xj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_L6RK1XU47d1ks9Xj');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_importcontactservice_Catch
}
