/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { searchservice } from 'app/sd-services/searchservice';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dynusersearch',
    templateUrl: './dynusersearch.template.html',
    exportAs: 'dynUserSearch'
})
export class dynusersearchComponent extends NBaseComponent implements OnInit {

    @Input('inputFormControl') fc;
    @Input() placeholder = '';
    @Input() label = '';
    @Input() name = '';
    @Input('filterWith') userFilter = null;
    @Input('onlyHeirarchy') onlyHeirarchy = false;
    // TODO: Incomplete implementation. User `GET /user/:userid` end point.
    @Input('') set value(v: number) {
        if (!v && this.fc) {
            this.fc.setValue('');
        }
    }

    @Output() resultSelected = new EventEmitter<SearchedUser>();

    @ViewChild('userSearchInput') userSearchInput: ElementRef<HTMLInputElement>
    @ViewChild('autoUsers') matAutocomplete: MatAutocomplete;
    @ViewChild('fci') fci;

    private pageSize = 10;
    private pageOffset = 0;
    filteredUsers: Observable<Promise<any[]>>;
    currentSearchResults = [];
    latestSearchTimestamp = { timestamp: Date.now() };
    displayName;
    searchInProgress = false;
    constructor(private searchService: searchservice, private z: NgZone) {
        super();
    }

    ngOnInit() {
        this.filteredUsers = this.fc.valueChanges.pipe(
            debounceTime(300),
            switchMap((value: string) => this.search(value)),
        );
    }

    async search(searchValue: string) {
        let usersSearchResults = [];
        this.pageOffset = 0;
        if (!searchValue) {
            return [];
        }
        this.searchInProgress = true;
        const searchRes = (await this.searchService.getSearchedUsers(searchValue, this.pageSize, this.pageOffset++, this.onlyHeirarchy)).local.users;
        if (searchRes && searchRes.data && Array.isArray(searchRes.data)) {
            usersSearchResults.push(...searchRes.data);
        }
        if (typeof this.userFilter === 'function') {
            this.searchInProgress = false;
            return usersSearchResults.filter(this.userFilter);
        }
        if(usersSearchResults){
            this.currentSearchResults = usersSearchResults;
        }
        this.searchInProgress = false;
        return usersSearchResults;
    }

    displayWithDisplayName(v: SearchedUser) {
        if (!v) {
            return '';
        }
        let tmpdisplayName = v.display_name || v.user_name;
        if (tmpdisplayName === undefined && this.currentSearchResults) {
            const userObj = this.currentSearchResults.find((item) => item.userid === v);
            tmpdisplayName = userObj.display_name || userObj.user_name;
        }
        this.displayName = tmpdisplayName || this.displayName;
        return tmpdisplayName || this.displayName;
    }

    clear() {
        this.z.run(() => {
            this.fc.setValue('');
            this.fci.nativeElement.focus();
        });
    }


    selected(event: MatAutocompleteSelectedEvent): void {
        this.fc.setValue(event.option?.value?.userid);
        this.resultSelected.next(event.option?.value)
        this.searchInProgress = false;
    }
}

export interface SearchedUser {
    userid: number,
    user_name: string,
    display_name: string,
    email_id: string,
    phone_no: string,
    phone_country_code: number,
}