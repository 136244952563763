/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-summaryinfolist',
    templateUrl: './summaryinfolist.template.html'
})

export class summaryinfolistComponent extends NBaseComponent implements OnInit {
    @Input('summary') summary = {};
    @Output() summaryDetail = new EventEmitter();
    @Output() feedbackEvent = new EventEmitter();
    @Output() updateTaskStatus = new EventEmitter();

    constructor() {
        super();
    }

    ngOnInit() {

    }

    getsummary(value, summary) {
        if (summary['type_name'] != 'event') {
            this.summaryDetail.emit({ value });
        }
    }

    addFeedback(value){
        this.feedbackEvent.emit({value});
    }
    updateTaskStatusToDone(taskObj){
        this.updateTaskStatus.emit({taskid: taskObj.activitytaskid,task_status: taskObj.task_status});
    }
}
