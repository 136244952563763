/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { pipelineservice } from 'app/sd-services/pipelineservice';
import { usernotificationservice } from 'app/sd-services/usernotificationservice';
import { labelService } from 'app/services/label/label.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { OptionDroppedEvtData, OptionEvtData, OptionItem } from '../optionsconfiguratorComponent/optionsconfigurator.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-setting_dealpipeline',
	templateUrl: './setting_dealpipeline.template.html'
})

export class setting_dealpipelineComponent extends NBaseComponent implements OnInit {
	title = '';
	optionsAddBtnLabel = 'Add Stage';
	sectionName = 'Configure various pipeline stages';
	disableActions = false;
	formGroup = new FormGroup({
		pipeline: new FormControl('', Validators.required),
	});
	pipeOptions: PipeOption[] = [];
	insertionIndex: number;
	optionPlaceholder = 'Enter stage name';

	private _module = 'deal';

	constructor(public pls: pipelineservice,
		private _ps: NPubSubService,
		private _uns: usernotificationservice,
		private _label: labelService) {
		super();
		this.title = `Configure Deal (${this._label.toTitleCase(this._label.deal)}) pipeline`;
		this._constructPipeOptions(true);
	}

	ngOnInit() { }

	private async _constructPipeOptions(initing = false) {
		if (!initing) await this.pls.getActivePipeline(this._module);
		const pipes = <Pipe[]>this.pls.pipeData[this._module].pipes;
		this.insertionIndex = pipes.length;
		this.pipeOptions = [...pipes, { pipe_name: 'Won', notAPipe: true }, { pipe_name: 'Lost', notAPipe: true }].map((p, i, a) => this._attachOptionConf<any>(p, i, a));
	}

	private _attachOptionConf<T extends PipeOption>(p: T, i: number, a: T[]) {
		const defs = i !== 0 && i !== a.length - 1 && i !== a.length - 2;
		p.__optionConfig__ = {
			editable: i !== a.length - 1 && i !== a.length - 2,
			deletable: defs,
			sortable: defs
		};
		return p;
	}

	async updateOption(e: OptionEvtData<PipeOption>) {
		this.disableActions = true;
		const oc = e.value;
		if (!oc.notAPipe) {
			oc.pipe_order = e.index + 1;
			let msg = `"${oc.pipe_name}" added to pipeline stages.`;
			try {
				if (e.isNew) {
					await this.pls.createPipe(this.pls.pipeData.deal.pipelineid, this._module, oc.pipe_name, oc.pipe_order);
				} else {
					await this.pls.updatePipe(oc.pipelineid, this._module, oc.pipe_name, oc.pipe_order, oc.pipeid);
					msg = `"${oc.pipe_name}" pipeline stage updated.`;
				}
                this._ps.$pub('refresh-settings');
			} catch (e) {
				msg = e.error?.message || e.error || e.message;
			}
			await this._updatePipeOptions(msg);
		} else {

		}
		this.disableActions = false;
	}

	async updateOptionOrder(es: OptionDroppedEvtData) {
		this.disableActions = true;
		const ems = es.filter(e => !e.value.notAPipe).map(e => { e.value.pipe_order = e.index + 1; return e.value });
		let msg = `Order of pipeline stages updated.`;
		try {
			await this.pls.bulkUpdatePipes(ems);
		} catch (e) {
			msg = e.error?.message || e.error || e.message;
		}
		await this._updatePipeOptions(msg);
		this.disableActions = false;
	}

	async deleteOption(e: OptionEvtData<PipeOption>) {
		const oc = e.value;
		if (!e.isNew && !oc.notAPipe) {
			this.disableActions = true;
			oc.pipe_order = e.index + 1;
			let msg = `"${oc.pipe_name}" deleted from pipeline stages.`;
			try {
				await this.pls.deletePipe(oc.pipeid, this._module)
			} catch (e) {
				if (e.error?.code === 'IN_USE') {
					msg = `Cannot delete the stage since there are some deals (${this._label.deals}) associated with it.`
				} else {
					msg = e.error?.message || e.error || e.message
				}
			}
			await this._updatePipeOptions(msg);
			this.disableActions = false;
		}
	}

	private async _updatePipeOptions(msg = '') {
		try {
			await this._constructPipeOptions();
		} catch (e) {
			msg = e.error?.message || e.error || e.message;
		}
		this._uns.openSnackbar(msg);
	}

	close() {
		if (!this.disableActions) {
			this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
				ACTION: 'close'
			})
		}
	}
}
export interface Pipe {
	pipeid: number,
	pipelineid: number,
	pipe_name: string,
	pipe_order: number,
	probabilty: number
}

interface PipeOption extends Pipe, OptionItem {
	notAPipe?: boolean
}