<div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
  <div fxLayout="row " class="background-primary sidenav-header" fxFlex="100" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
      <mat-icon class="icon-color" (click)="menuToggle()">menu</mat-icon>
    </div>
    <div fxLayout="row " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="80" fxLayoutAlign.xs="center center">
      <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false"><img [src]="tenantIcon" alt="Logo" style="height: 46px;" *ngIf="showMainLogo" class="tenant-logo common-margin-buttom"><img imgSrc="Web/Icons/favicon.ico" style="height: 46px;" *ngIf="showneutrinosLogo" class="tenant-logo common-margin-buttom"></div>
    </div>
  </div>
</div>
<div fxLayout="column wrap" class="white-color common-sidenav-top" fxLayoutGap="1.5em" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" fxLayoutGap.xs="0.4em">
  <div fxLayout="row " class="common-menu-padding cursor-pointer" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" matTooltip="Daily Summary" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;DailySummary&apos;)" [ngStyle.xs]="&apos;width: 97%;cursor: none;&apos;" fxLayoutAlign.xs="start center" *ngxPermissionsOnly="[&apos;GENERAL_MINE&apos;]">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="(selectedMenu == &apos;DailySummary&apos;) ? &apos;element-border-xs&apos; : &apos;&apos;" [ngClass.gt-xs]="(selectedMenu == &apos;DailySummary&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;"></div>
    <div [ngStyle.gt-xs]="&apos;margin-left: -2px;&apos;" class="daily-summary-image common-menu-image">
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 84%;&apos;">
      <div [fxHide.gt-xs]="true" [ngClass.xs]="&apos;color-view&apos;" class="font-Family">
        Daily Summary</div>
    </div>
  </div>
  <div fxLayout="row " class="common-menu-padding cursor-pointer" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false" matTooltip="Leads" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;Leads&apos;)" [ngStyle.xs]="&apos;width: 97%;cursor: none;&apos;" *ngIf="leadsList.length" [fxHide.xs]="true">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="(selectedMenu == &apos;Leads&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;" [ngClass.xs]="(selectedMenu == &apos;Leads&apos;) ? &apos;element-border-xs&apos; : &apos;&apos;"></div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [matMenuTriggerFor]="Leads">
      <div click-stop-propagation (click)="makeCall(val)" class="leads-image common-menu-image">
      </div><img imgSrc="Web/Icons/dropdown.svg" class="lead-drop-image-gt-xs">
    </div>
  </div>
  <div fxLayout="row " class="common-menu-padding cursor-pointer" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" matTooltip="Contacts" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;Contacts&apos;)" [ngStyle.xs]="&apos;width: 97%;cursor: none;&apos;" fxLayoutAlign.xs="start center" *ngxPermissionsOnly="[&apos;CONTACT_MINE&apos;, &apos;CONTACT_TEAM&apos;]">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="(selectedMenu == &apos;Contacts&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;" [ngClass.xs]="(selectedMenu == &apos;Contacts&apos;) ? &apos;element-border-xs&apos; : &apos;&apos;"></div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="!leadsList.length">
      <div class="leads-image common-menu-image">
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 84%;&apos;">
      <div [fxHide.gt-xs]="true" [ngClass.xs]="&apos;color-view&apos;" class="font-Family">
        Contacts</div>
    </div>
  </div>
  <div fxLayout="row wrap" class="cursor-pointer" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false" matTooltip="Leads" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;Leads&apos;)" [ngClass]="(selectedMenu == &apos;Leads&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;" [ngStyle.xs]="&apos;width: 95%;cursor: none;&apos;" [fxHide.gt-xs]="true" *ngIf="leadsList.length">
    <bh-blmenutree></bh-blmenutree>
  </div>
  <div fxLayout="row " class="common-menu-padding cursor-pointer" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" matTooltip="{{label.deals | titlecase}}" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;Deals&apos;)" [ngStyle.xs]="&apos;width: 97%;cursor: none;&apos;" fxLayoutAlign.xs="start center" *ngxPermissionsOnly="[&apos;DEAL_MINE&apos;, &apos;DEAL_TEAM&apos;]    ">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="(selectedMenu == &apos;Deals&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;" [ngClass.xs]="(selectedMenu == &apos;Deals&apos;) ? &apos;element-border-xs&apos; : &apos;&apos;"></div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div class="deals-image common-menu-image">
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 84%;&apos;">
      <div [fxHide.gt-xs]="true" [ngClass.xs]="&apos;color-view&apos;" class="font-Family">
        {{label.deals | titlecase}}</div>
    </div>
  </div>
  <div fxLayout="row " class="common-menu-padding cursor-pointer" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" matTooltip="Dashboard" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;Dashboard&apos;)" [ngStyle.xs]="&apos;width: 97%;cursor: none;&apos;" fxLayoutAlign.xs="start center" *ngxPermissionsOnly="[&apos;GENERAL_MINE&apos;, &apos;GENERAL_TEAM&apos;]">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="(selectedMenu == &apos;Dashboard&apos;) ? &apos;element-border-xs&apos; : &apos;&apos;" [ngClass.gt-xs]="(selectedMenu == &apos;Dashboard&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;"></div>
    <div [ngStyle.gt-xs]="&apos;margin-left: -2px;&apos;" class="dashboard-image common-menu-image">
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 84%;&apos;">
      <div [fxHide.gt-xs]="true" [ngClass.xs]="&apos;color-view&apos;" class="font-Family">
        Dashboard</div>
    </div>
  </div>
  <div fxLayout="row " class="common-menu-padding cursor-pointer" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" matTooltip="Import Contact History" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;importContactStatus&apos;)" [ngStyle.xs]="&apos;width: 97%;cursor: none;&apos;" fxLayoutAlign.xs="start center" *ngxPermissionsOnly="[&apos;GENERAL_MINE&apos;]">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="(selectedMenu == &apos;importContactStatus&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;" [ngClass.xs]="(selectedMenu == &apos;importContactStatus&apos;) ? &apos;element-border-xs&apos; : &apos;&apos;"></div>
    <div class="imported-contacts-image common-menu-image">
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 84%;&apos;">
      <div [fxHide.gt-xs]="true" [ngClass.xs]="&apos;color-view&apos;" class="font-Family">
        Import Contact</div>
    </div>
  </div>
  <div fxLayout="row " class="common-menu-padding cursor-pointer" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" matTooltip="Settings" [matTooltipPosition]="[&apos;right&apos;]" (click)="navigateRoute(&apos;Settings&apos;)" [ngStyle.xs]="&apos;width: 97%;cursor: none;&apos;" fxLayoutAlign.xs="start center" *ngxPermissionsOnly="[&apos;SETTINGS_ADMIN&apos;]">
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="(selectedMenu == &apos;Settings&apos;) ? &apos;common-menu-selected&apos; : &apos;&apos;" [ngClass.xs]="(selectedMenu == &apos;Settings&apos;) ? &apos;element-border-xs&apos; : &apos;&apos;"></div>
    <div class="settings-image common-menu-image">
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 84%;&apos;">
      <div [fxHide.gt-xs]="true" [ngClass.xs]="&apos;color-view&apos;" class="font-Family">
        Settings</div>
    </div>
  </div>
</div>
<mat-menu #Leads panelClass="common-menu-item-panel" class="common-menu-item-panel" #menu="matMenu" overlapTrigger="true">
  <p class="common-menu-content common-text-align-center common-menu-content-align font-Family">
    Leads</p>
  <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let val of leadsList"><button mat-menu-item class="font-Family menu-content-align"> {{val}} </button></div>
</mat-menu>