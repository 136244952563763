<div fxLayout="column wrap" class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="depFields.length === 0">
  <div fxLayout="row wrap" class="height-100" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <div class="margin-auto label-font-color">
      Configure field dependencies using &#x201C;add dependency&#x201D; option</div>
  </div>
</div>
<div fxLayout="column " class="padding-top-1 height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="depFields.length &gt; 0" [ngClass]="{&apos;disabled-appearance&apos;: disableActions }">
  <div fxLayout="row wrap" class="lead-list-header" fxFlex="48px" fxLayoutGap="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="lr-padding" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" *ngFor="let header of headers" [fxFlex]="fxFlex">
        <h3 class="color-view">
          {{header.displayText}}</h3>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " class="border-bottom-gt-xs color-black" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let field of depFields">
      <div fxLayout="row wrap" class="lr-padding height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex]="fxFlex">
        <h3 class="font-normal-size text-ellipsis">
          {{_udfSync.getDepMappingText(field)}}</h3>
      </div>
      <div fxLayout="row wrap" class="lr-padding" fxLayoutGap="1rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex]="fxFlex">
        <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
          <div (click)="openEditDepForm(field)" matTooltip="Edit Field Dependency" class="edit-dep-image cursor-pointer">
          </div>
        </div>
        <div fxLayout="column wrap" class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
          <div (click)="deleteDep(field)" matTooltip="Delete Field Dependency" class="delete-dep-image cursor-pointer">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>