/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { userservice } from 'app/sd-services/userservice';
import { NeutrinosOAuthClientService } from 'neutrinos-oauth-client';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-blprofileview',
    templateUrl: './blprofileview.template.html'
})

export class blprofileviewComponent extends NBaseComponent implements OnInit {
    userDisplayName:string;
    constructor(private userService: userservice, private logoutS: NeutrinosOAuthClientService) {
        super();
    }

    ngOnInit() {
        this.userService.getcu().then(data=>{
            this.userDisplayName = data.local.user.displayName;
        });
    }
    logout() {
        this.logoutS.logout('/home').then( async res => {
            console.log(res);
            if (!res) {
                await this.logoutS.login();
            }
        });
    }
}
