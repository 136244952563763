export class UtilityMethods {
	/**
	 * 
	 * @param timeInamppm (12:00pm, 01:00pm,i:0pm)
	 */
	static timeampmTo24(timeInampm) {
		if (!UtilityMethods.validateAMPMTimeString(timeInampm)) {
			throw new Error('Invalid Date Format');
		}
		const time_groups = timeInampm.match(/^(\d+):(\d+)(\w{2})$/);
		/* 12:15AM => 
		[1] = 12
		[2] = 15
		[3] = AM
		*/
		const hour = parseInt(time_groups[1]);
		const minute = time_groups[2];
		const ampm = time_groups[3].toLowerCase();
		let t24Hr = hour;
		if (ampm === 'pm' && hour !== 12) {
			t24Hr = (hour + 12) % 24;
		} else if (ampm === 'am') {
			t24Hr = hour % 12;
		}
		return `${t24Hr}:${minute}:00`;
	}
	static validateAMPMTimeString(timeInampm) {
		if (!timeInampm) {
			return false;
		}
		const time_groups = timeInampm.match(/^(\d+):(\d+)(\w{2})$/);
		if (!time_groups) {
			return false;
		}
		/* 12:15AM => 
		[1] = 12
		[2] = 15
		[3] = AM
		*/
		const hour = parseInt(time_groups[1]);
		const minute = time_groups[2];
		const ampm = time_groups[3].toLowerCase();
		if (hour > 12 || minute > 60 || (hour === 12 && ampm === 'am')) {
			return false;
		}
		//validate am pm string 
		if (ampm !== 'am' && ampm !== 'pm') {
			return false;
		}
		return true;
	}
	static dateObjToStringYYYYMMDD(date: Date) {
		if (isNaN(date.getDay())) {
			throw new Error('Invalid date object');
		}
		return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	}
	/**
	 * 
	 * @param date '2020-12-12'
	 * @param time '13:45:00'
	 */
	static concatenateDateTimeToDateObj(date: string, time: string) {
		let dateObj = new Date(`${date} ${time}`);
		if (isNaN(dateObj.getDay())) {
			throw new Error('Invalid date/time');
		}
		return dateObj;
	}

	static reverseKv(obj) {
		return Object.keys(obj).reduce((a, k) => {
			a[obj[k]] = k;
			return a;
		}, {});
	}

	static swap(arr: any[], i1: number, i2: number) {
		const temp = arr[i2];
		arr[i2] = arr[i1];
		arr[i1] = temp;
		return arr;
	}

	/**
	 * 
	 * @param arr 
	 * @param idx 
	 * 
	 * @returns the element deleted
	 */
	static deleteAtIdx(arr: any[], idx: number) {
		return arr.splice(idx, 1);
	}

	static insertAt(arr: any[], idx: number, v: any) {
		let i = arr.length - 1;
		while (i >= 0 && i !== idx - 1) {
			arr[i + 1] = arr[i];
			i--;
		}
		arr[i + 1] = v;
		return arr;
	}

	static generateUUID() {
		return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
	}

	static s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	static findFirst(arr: any[], value: any, key?: string, mapTo?: string) {
		const found = arr.find(v => key ? (v[key] === value) : (v === value));
		if (mapTo) {
			return found[mapTo];
		}
		return found;
	}

	static JSONClone<T>(o: T): T {
		return JSON.parse(JSON.stringify(o));
	}

	static mapFromArr(arr: { [key: string]: any }[], keyPath: string) {
		const keys = keyPath.split('.');
		return arr.reduce((map, item) => {
			const v = keys.reduce((v, k) => {
				const r = v[k];
				return r;
			}, item);
			map[v] = item;
			return map;
		}, {});
	}

	static getValFromKeyPath(o: any, keyPath: string) {
		if (!o || typeof o !== 'object' || !keyPath) {
			return o;
		}
		const keyPathArr = keyPath.split('.');
		let v = null;
		for (let i = 0; i < keyPathArr.length; i++) {
			v = o[keyPathArr[i]];
			if (typeof v === 'undefined') {
				return '';
			}
		}
		return v;
	}

	static setValForKeyPath(o: any, keyPath: string, v: any) {
		if (typeof o === 'string' || !keyPath) {
			return o;
		}
		const keyPathArr = keyPath.split('.');
		let i = 0, t = o;
		for (; i < keyPathArr.length - 1; i++) {
			const k = keyPathArr[i];
			(!t[k] || typeof t[k] !== 'object') ? t[k] = {} : t[k] = t[k];
			t = t[k];
		}
		t[keyPathArr[i]] = v;
		return o;
	}
}