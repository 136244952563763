/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormControl, Validators } from '@angular/forms';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-dynemail',
	templateUrl: './dynemail.template.html',
	styles: [
		`
            :host {
                width: 100%;
            }

            mat-form-field {
                width: 100%;
            }
        `
	]
})

export class dynemailComponent extends NBaseComponent implements OnInit {
	@Input('fc') fc = new FormControl();
	@Input('placeholder') placeholder = 'Mobile Number';
	@Input('field_name') field_name = '';
	@Input('required') required = false;
	@Input('changeCallbackFn') changeCallbackFn;
	constructor() {
		super();
	}

	ngOnInit() {
		if (this.fc) {
			this.fc.setValidators(Validators.email);
			if (this.required) {
				this.fc.setValidators(Validators.compose([Validators.email, Validators.required]));
			}
			this.fc.updateValueAndValidity();
		}
	}

	// ngOnChanges(s: SimpleChanges) {
	//     if(s?.required) {
	// 		if(s.required.currentValue) {
	// 			this.fc?.setValidators(Validators.required);
	// 		} else {
	// 			this.fc?.clearValidators();
	// 		}
	// 		this.fc?.updateValueAndValidity();
	//     }
	// }
}
