<div fxLayout="column wrap" class="line-height-view" fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="activity">
  <div class="all-activities-details">
    <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/user_icon.svg" class="circle-icon"></div>
      <div>
        {{activity?.user_name}}</div>
      <div fxLayout="row wrap" class="circle-icon" fxLayoutGap="5px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/clock_icon.svg" class="circle-icon"></div>
      <div>
        {{activity.created_at | date: &apos;dd&apos;}} - {{activity.created_at | date:&apos;MM&apos;}} - {{activity.created_at | date: &apos;yyyy&apos;}}, {{activity.created_at | date: &apos;HH:mm&apos;}}</div>
    </div><span class="appointment-details-label" *ngIf="activity?.deal_name"><span>{{dealNameLabel | titlecase}}: </span><span class="font-normal-size">{{activity?.deal_name}}</span><br></span>
  </div><audio controls [autoplay]="false" *ngIf="activity.recordingid" [ngClass.xs]="&apos;width-100&apos;" [ngClass.sm]="&apos;width-100&apos;" class="no-outline audioPlayer">
    <source src="{{audioFilepath}}/{{activity.recordingid}}?contactid={{contactid}}" type="audio/mpeg">
  </audio>
  <div fxLayout="row " fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="activity?.callfeedbackid">
    <div *ngIf="activity?.callfeedbackid" class="all-activities-details">
      <span class="font-weight-size">Outcome : </span> <span class="common-word-wrap">{{getOutcomeLabel()}}</span></div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="(activity?.contact_owner == userservice?.user?.srmUserid)">
      <div (click)="openForm()" class="edit-feedback-image cursor-pointer">
      </div>
    </div>
  </div>
  <div fxLayout="row " fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="activity?.feedback_notes">
    <div fxFlex="90% 0 0" class="all-activities-details">
      <span class="font-weight-size">Feedback : </span> <span class="common-word-wrap">{{activity.feedback_notes}}</span></div>
  </div><button mat-raised-button class="width-fit-content add-feedback-btn" *ngIf="!activity?.callfeedbackid  &amp;&amp;  (activity?.contact_owner == userservice?.user?.srmUserid)" color="var(--primary-color)" (click)="openForm()">Add Feedback</button>
</div>