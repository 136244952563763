<div fxLayout="column wrap" style="height:70px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " fxLayoutGap="0.5rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="mode === &apos;VIEW&apos;" fxFill="fxFill">
    <div class="label">
      {{label | titlecase}}</div>
    <div (click)="navigateToContactDetails($event)" (keyup.enter)="navigateToContactDetails($event)" tabindex="0" class="width-fit-content nav-link">
      {{displayWith(selectedid)}}</div>
  </div>
  <div *ngIf="intializationComplete &amp;&amp; mode === &apos;EDIT&apos;">
    <mat-form-field class="width-100 search-field" floatLabel="always">

      <mat-label>{{label | titlecase}}</mat-label>
      <input type="text" [formControl]="fc" placeholder="Type and Select" aria-label="Number" matInput [matAutocomplete]="autoContact" (focus)="autoCompleteFocused()" #fci>
      <img matSuffix src="assets/Web/Icons/ac-clear-icon.svg" class="autocomplete-clear" *ngIf="fc.value &amp;&amp; !searchInProgress" (click)="clear()">
      <mat-spinner matSuffix *ngIf="searchInProgress"></mat-spinner>
      <mat-autocomplete autoActiveFirstOption #autoContact="matAutocomplete" [displayWith]="dwbind" class="search-autocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let option of filteredDeals" [value]="option.dealid">
          <div>{{option.deal_name}}</div>
          <div class="label" *ngIf="option.contact_display_name">Contact: {{option.contact_display_name}}</div>
          <div class="label" *ngIf="option.contact_owner_name">Contact Owner: {{option.contact_owner_name}}</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>