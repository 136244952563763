/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, Input, OnInit } from '@angular/core';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { labelService } from 'app/services/label/label.service';
import { userservice } from 'app/sd-services/userservice';
import { ActivatedRoute, Router } from '@angular/router';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-activities_appointments',
    templateUrl: './activities_appointments.template.html'
})

export class activities_appointmentsComponent extends NBaseComponent implements OnInit {
    @Input('activity') activity;
    @Input('videoFilepath') videoFilepath;
    environmentValue;
    private routeParamsSub;
    contactid;

    feedbackOutcome = '';

    private _fbOutcomeMap: { [key: string]: AppointmentFeedbackOutcome } = {};
    dealNameLabel: string;

    constructor(private pubsub: NPubSubService,
        private _caches: cacheservice,
        private label: labelService,
        public userservice: userservice,
        private activatedRoute: ActivatedRoute
    ) {
        super();
        this.routeParamsSub = this.activatedRoute.params.subscribe((result: { contactid }) => {
            this.contactid = Number(result.contactid);
        })
    }

    async ngOnInit() {
        this.dealNameLabel = `${this.label.deal} Name`;
        this._fbOutcomeMap = (await this._caches.getc('APPOINTMENTS_FEEDBACK_OUTCOMES_MAP')).local.data;
    }

    async openForm() {
        const formKey = 'FEEDBACK_FORM_APPOINTMENTS_ACTIVITY';
        await this._caches.setc(this.activity, formKey)
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: formKey
        });
    }

    getOutcomeLabel() {
        return this._fbOutcomeMap?.[this.activity.appointment_feedback_id]?.feedback_label;
    }

    ngOnDestroy() {
        if (this.routeParamsSub) {
            this.routeParamsSub.unsubscribe();
        }
        this._fbOutcomeMap = null;
    }
    showRecording(recordingId) {
        window.open(`${this.videoFilepath}${recordingId}?contactid=${this.contactid}`);
    }
}

export interface AppointmentFeedbackOutcome {
    appointmentfeedbackid: number,
    feedback_label: string,
	tenantid: number,
	sort_order: number
}