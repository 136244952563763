<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" style="order: -1;" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/editevent.svg" class="add-contact-icon">
          <h2>
            DEAL MODULE RENAME</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="form-sections-title" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          Configure deal module name</div>
      </div>
      <form NgForm fxLayout="column" fxFlex="calc(100% - 64px)" class="add-lead-content">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <mat-form-field class="flex-auto" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="Ex. Case" [required]="true" [formControl]="singularName">
            <mat-label>Module name (Singular)</mat-label>
          </mat-form-field>
          <mat-form-field class="flex-auto" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="Ex. Cases" [required]="true" [formControl]="plularName">
            <mat-label>Module name (Plural)</mat-label>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div fxLayout="column wrap" class="add-lead-content lr-padding buttons-pad" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="saveModuleName()" [disabled]="disableAddButton">Save</button></div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </div>
</div>