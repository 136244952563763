<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/add_lead_icon.svg" class="add-contact-icon">
          <h2>
            ADD CONTACT</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <form NgForm [formGroup]="addLeadFormGroup" fxLayout="column" fxFlex="calc(100% - 64px)" class="add-lead-content" (ngSubmit)="add()">
    <div fxLayout="column wrap" fxFlex="calc(100%-px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="First Name" [required]="true" trim="blur" [formControl]="addLeadFormGroup.controls.first_name">

          </mat-form-field>
        </div>
        <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100">
          <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="Last Name" [required]="false" trim="blur" [formControl]="addLeadFormGroup.controls.last_name">

          </mat-form-field>
        </div>
      </div>
      <bh-dynphoneno [phoneCountryCodeControl]="addLeadFormGroup.controls.phone_country_code" [fc]="addLeadFormGroup.controls.phone_no"></bh-dynphoneno>
      <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
        <input matInput type="email" placeholder="Email Address" [required]="false" [formControl]="addLeadFormGroup.controls.email_id">

      </mat-form-field>
      <mat-error align *ngIf="isLeadFormInvalid &amp;&amp; this.addLeadFormGroup?.errors?.oneOfRequired">Either Mobile Number or Email Address is required.</mat-error>
      <mat-error align *ngIf="this.addLeadFormGroup?.errors?.phoneNumberError">Country code and Phone number are required</mat-error>
    </div>
    <div fxLayout="column wrap" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view add-button" [disabled]="disableAddButton">Add</button></div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>