/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
// import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class userrolesresolverService /* implements Resolve<Promise<UserRolesData>>  */{

	constructor(/* private _roles: roleservice */) { }

	// async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //  const permissionGroups = (await this._perms.getPermissionGroups()).local.res || [];
	// 	const roles = (await this._roles.getRoles()).local.res || [];
	// 	return { roles };
	// }
}

