/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { emailService } from 'app/services/email/email.service';
import { HttpClient } from '@angular/common/http';


/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-newemail',
    templateUrl: './newemail.template.html'
})

export class newemailComponent extends NBaseComponent implements OnInit {
    @Input('emailData') emailData: any;
    isCc = false;
    isBcc = false;
    emailFrom = "neutrinos.helpline@gmail.com"
    newEmailForm: FormGroup;
    public Editor = ClassicEditor;
    config = {
        toolbar: ['bold', 'italic', 'link', 'bulletList', 'insertTable', 'undo', 'redo']
    }
    emailDataObj: any;
    imgArr = [];
    imagearr: any = []
    fileObj = {};
    fileData: File;
    isFileUploaded = false;
    fileAttachments = [];
    constructor(
        private pubsub: NPubSubService,
        private formBuilder: FormBuilder,
        private emailservice: emailService,
        private http: HttpClient
    ) {
        super();
    }

    ngOnInit() {
        this.initializeform();
        console.log(this.emailData)
        if (this.emailData && this.emailData['emailType'] == 'reply') {
            this.newEmailForm.patchValue({
                emailFrom: this.emailFrom,
                to_email: this.emailData['to_Email'],
                emailTopicLine: this.emailData['topic']
            });
        }
    }
    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }
    initializeform() {
        this.newEmailForm = this.formBuilder.group({
            emailFrom: [''],
            emailTopicLine: [''],
            bcc_email: [''],
            cc_email: [''],
            to_email: [''],
            emailContent: ['']
        });
    }
    saveSetting(formData) {
        let dealId = this.emailData['dealId']
        this.emailDataObj = {
            from: this.emailFrom,
            to: formData.value.to_email.split(','),
            cc: formData.value.cc_email.split(','),
            bcc: formData.value.bcc_email.split(','),
            topic: formData.value.emailTopicLine,
            text: formData.value.emailContent,
            html: formData.value.emailContent,
            attachments: this.fileAttachments,
            dealId: dealId
        }
        console.log(this.emailDataObj)
        this.http.post(`/sendEmail`, this.emailDataObj).subscribe(res => {
            if (res) {
                this.close()
            }
        })
    }

    fileBrowseHandler(files: FileList) {
        console.log(files)
        if (files.length) {
            this.isFileUploaded = true;
            this.fileData = files[0];
            this.fileObj = {
                fileName: this.fileData['name'],
                fileSize: (this.fileData['size'] / (1024 * 1024)).toFixed(2)
            };
            console.log(this.fileData)
            let formData: FormData = new FormData()
            formData.append("files", this.fileData);
            console.log(formData)
            this.http.post('/upload', formData).subscribe(res => {
                console.log(res)
                this.fileAttachments.push(this.fileData['name'])
                this.imgArr.push(this.fileObj);
                this.imagearr.push(this.fileData);
                this.fileObj = {};
            });
        };
    }

    removeUpload(item, ind) {
        this.imagearr.splice(ind, 1)
        this.imgArr.splice(ind, 1)
        this.emailservice.removeUpload(item)
    }
}
