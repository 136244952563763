/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { contactservice } from 'app/sd-services/contactservice';
import { Router } from '@angular/router';
import { Header } from '../usersComponent/users.component';
import { SortOrder, SortChangeEvent } from '../sortComponent/sort.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { dialogService } from '../../services/dialog/dialog.service';
import { cacheservice } from 'app/sd-services/cacheservice';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';


interface Contact {
    contact_name: string;
    contact_owner: number;
    contactid: number;
    created_by: number;
    first_name: string;
    last_name: string;
    phone_country_code: string;
    phone_no: string;
    tenantid: number;
    updated_at: string;
}
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-teamcontacts',
    templateUrl: './teamcontacts.template.html',
})

export class teamcontactsComponent extends NBaseComponent implements OnInit, OnChanges {
    mycontacts: Contact[] = [];
    totalContacts = 0;
    pageSize = 50;
    pageOffset = 0;
    scrollPageOffset = 0;
    nameSorting = 'ASC';
    lastUpdateSorting = 'DESC';
    sum = 50;
    checkAppendStatus = false;
    filters = []
    reportingUsersCount = 0;
    reportingUsers = [];

    watcher: Subscription;
    activeMediaQuery = '';

    private sortOrder: { key: string, order: SortOrder }[] = [
        {
            key: 'display_name',
            order: 'ASC'
        },
        {
            key: 'updated_at',
            order: 'DESC'
        },
    ];
    headers: Header[] = [
        { displayText: 'Name', sort: true, sortKey: 'display_name', sortValue: 'ASC' },
        { displayText: 'Mobile' },
        { displayText: 'Email' },
        { displayText: 'Last Updated Date', sort: true, sortKey: 'updated_at', sortValue: 'DESC' },
        { displayText: 'Contact Owner' }
    ];
    currentSortKey = 'updated_at';
    @Output('listRefreshed') listRefreshed = new EventEmitter();
    @Output('selectedContacts') selectContacts = new EventEmitter();
    @Output('displayedContactsCount') displayedContactsCount = new EventEmitter();
    @Output() reassignContact = new EventEmitter();
    @Input('media_view') media_view;
    
    @Input('totalCountP') totalCountP;

    @Input('pageSizeP') pageSizeP;

    @Input('pageOffsetP') pageOffsetP;

    @Input('readyP') readyP;

    @Output() paginationEvent = new EventEmitter<string>();

    selectedContactsArr = [];
    selectedContactsCount = 0;
    selectAllCheck;
    disableCheck;
    contactRefreshSub;
    sortSub;

    constructor(private contactService: contactservice,
        private pubsub: NPubSubService,
        private router: Router,
        private dialog: dialogService,
        private cacheService: cacheservice,
        private mediaObserver: MediaObserver,
        public matdialog: MatDialog) {
        super();
        this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;
        });
    }

    ngOnInit() {

        this.contactRefreshSub = this.pubsub.$sub('refreshteamcontactlist', () => {
            this.cacheService.getc('MY_TEAM_CONTACT_FILTER').then((result) => {
                let d = result.local.data;
                if (d) {
                    this.filters = d.filters;
                    this.reportingUsersCount = d.filters.length;
                } else {
                    this.filters = [];
                    this.reportingUsersCount = 0
                }
                this.getTeamContacts(this.pageSize, this.pageOffset, this.filters);
                // this.cacheService.setc(null, 'APPLY_CONTACT_FILTER');
            }).catch(e => console.log(e));
        })

        this.sortSub = this.pubsub.$sub('team_contacts_sort', (result) => {
            this.checkAppendStatus = true;
            let contactsortCount = this.mycontacts.length
            this.mycontacts = [];
            this.findNReplaceSortOrder(result.res.data.key, result.res.data.sort_type);
            this.getTeamContacts(contactsortCount, 0, this.filters);
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.media_view) {
            this.pageSize = 50;
            this.pageOffset = 0;
            this.getTeamContacts(this.pageSize, this.pageOffset, this.filters);
        }
        else if(Object.keys(changes).includes("totalCountP") || Object.keys(changes).includes("pageSizeP") || Object.keys(changes).includes("pageOffsetP")||Object.keys(changes).includes("readyP") ){

        }
    }

    onScroll() {
        this.checkAppendStatus = true;
        this.scrollPageOffset += 1;
        this.getTeamContacts(this.sum, this.scrollPageOffset, this.filters);
    }

    pageEvents($event) {
         this.paginationEvent.next($event);;
    }


    navigateToContactDeails(contactid) {
        this.router.navigate(['/home/contacts/' + contactid])
    }

    sortChange($event: SortChangeEvent) {
        this.currentSortKey = $event.key;
        this.findNReplaceSortOrder($event.key, $event.sort);
        this.getTeamContacts(this.pageSize, this.pageOffset, this.filters);
    }

    findNReplaceSortOrder(key, sort: 'ASC' | 'DESC') {
        let i = this.sortOrder.findIndex(v => {
            return v.key === key;
        });

        this.sortOrder.splice(i, 1);
        this.sortOrder.unshift({
            key,
            order: sort
        })
    }

    getTeamContacts(pageSize: number, pageOffset: number, filters: any[]) {
        this.selectedContactsArr = [];
        this.selectedContactsCount = 0;
        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
        this.selectAllCheck = false;
        this.disableCheck = true;
        const sortBy = this.sortOrder.map(v => v.key + ' ' + v.order).join(',');

        if (typeof pageSize == 'undefined') {
            pageSize = 50;
        }

        if (typeof pageOffset == 'undefined') {
            pageOffset = 0;
        }

        this.pageSize = pageSize;
        this.pageOffset = pageOffset;

        this.contactService.getTeamContacts(pageSize, pageOffset, sortBy, filters).then((bh: {
            input: any,
            local: {
                result: {
                    data: {
                        count: number,
                        result: Contact[]
                    }
                }
            }
        }) => {
            if (bh && bh.local && bh.local.result && bh.local.result.data && bh.local.result.data.count) {
                if (this.checkAppendStatus) {
                    let temp = this.mycontacts;
                    this.mycontacts = bh.local.result.data.result;
                    this.mycontacts = temp.concat(this.mycontacts);
                    this.totalContacts = Number(bh.local.result.data.count);
                    this.checkAppendStatus = false;
                } else {
                    this.mycontacts = bh.local.result.data.result;
                    this.totalContacts = Number(bh.local.result.data.count);
                }
                this.displayedContactsCount.emit({value:this.mycontacts.length});
            }else{

                    if( this.activeMediaQuery =='lg'){
                        this.mycontacts = [];
                        this.totalContacts = 0;
                        this.displayedContactsCount.emit({value:this.mycontacts.length});
                    }

            }
            this.listRefreshed.emit({ value: true });
            this.disableCheck = false;
        })
            .catch(error => {
                console.log(error);
                this.listRefreshed.emit();
            })
    }

    onRowClick(event, contactid, contactowner) {
        this.selectAllCheck = false;
        if (event.checked) {
            this.selectedContactsArr.push({ 'contactid': contactid, 'contact_owner': contactowner });
            this.selectedContactsCount += 1;
            if (this.selectedContactsCount == this.mycontacts.length) {
                this.selectAllCheck = true;
                if(localStorage.getItem('usersChoiceSelectAll')=='true')
                this.pubsub.$pub('allContactsSelected', {
                    'allContactsSelected': 'true'
                });
            }
        } else {
            for (let i = 0; i < this.selectedContactsArr.length; i++) {
                if (this.selectedContactsArr[i]['contactid'] == contactid) {
                    this.selectedContactsArr.splice(i, 1);
                    this.selectedContactsCount -= 1;
                }
            }
        }
        if (this.selectedContactsCount != this.mycontacts.length) {
            this.pubsub.$pub('allContactsSelected', {
                'allContactsSelected': 'false'
            });
        }

        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
    }
    selectAll(event) {
        this.selectedContactsArr = [];
        if (event.checked) {
            for (let i in this.mycontacts) {
                this.mycontacts[i]['isSelected'] = true;
                this.selectedContactsArr.push({ 'contactid': this.mycontacts[i].contactid, 'contact_owner': this.mycontacts[i]['contact_owner_id'] });
            }
            this.selectedContactsCount = this.mycontacts.length;
        } else {
            for (let i in this.mycontacts) {
                this.mycontacts[i]['isSelected'] = false;
                this.selectedContactsArr = [];
            }
            this.selectedContactsCount = 0;
            localStorage.setItem('allContactsSelected','false');
            localStorage.setItem('usersChoiceSelectAll','false')
        }
        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
    }

    resetSelectedContacts() {
        this.selectAllCheck=false
        for (let i in this.mycontacts) {
            this.mycontacts[i]['isSelected'] = false;
            this.selectedContactsArr = [];
        }
        this.selectedContactsCount = 0;
        this.selectContacts.emit({ count: this.selectedContactsCount, obj: this.selectedContactsArr });
    }


    reassign(contact) {
        this.reassignContact.emit({ count: 1, obj: [{ contactid: contact.contactid, contact_owner: contact.contact_owner }] });
    }

    ngOnDestroy() {
        if (this.contactRefreshSub) {
            this.contactRefreshSub.unsubscribe();
        }
        if (this.sortSub) {
            this.sortSub.unsubscribe();
        }
    }
}
