/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class utilservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_utilservice

  async constructBaseUrl(segmentToappend = '', ...others) {
    try {
      var bh = {
        input: {
          segmentToappend: segmentToappend
        },
        local: {
          url: ''
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.getBaseUrl2(bh);
      //appendnew_next_constructBaseUrl
      return (
        // formatting output variables
        {
          input: {},
          local: {
            url: bh.local.url
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_awvgMv1FwRTJJldv');
    }
  }

  //appendnew_flow_utilservice_Start

  async getBaseUrl2(bh) {
    try {
      bh.local.baseUrl = bh.system.environment.properties.ssdURL;
      bh = await this.appendSegment(bh);
      //appendnew_next_getBaseUrl2
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_QPxUV9bW9Y1kR6jG');
    }
  }

  async appendSegment(bh) {
    try {
      const seg = '' + bh.input.segmentToappend;
      bh.local.url =
        bh.local.baseUrl + (seg.startsWith('/') ? seg.slice(1) : seg);
      //appendnew_next_appendSegment
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_aiVY3JVRDOZwRnO5');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_utilservice_Catch
}
