<div fxLayout="column " fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="stageValue">
  <div fxLayout="column wrap" class="padding-0-1 border-light-gray" fxFlex="48px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <h3 class="font-weight-size">
        {{stageValue.title}}</h3>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y padding-1" fxFlex="calc(100% - 48px)" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="stageValue &amp;&amp; stageValue.list">
    <div fxLayout="column" class="no-box-shadow summary-info-card">
      <div fxLayout="row wrap" class="summary-info-card-row stage-margin-list top-zero-padding" fxFlex="50px" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
        <div class="font-weight-size h1">
          <span class="font-text-color">{{stageValue.count}}</span><br>
          <h5 class="font-weight-size">{{stageValue.name}}</h5>
        </div>

        <div fxLayout="row wrap" style="height: 82%;" fxFlex="10" fxLayoutAlign="center flex-end" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" style="height: 100%;" class="summary-outer-circle circle-padding" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
            <div *ngIf="stageValue.name == &apos;Total Count&apos;" class="summary-stage-image">
            </div>
            <div *ngIf="stageValue.name == &apos;Total Amount&apos;" class="summary-amount-image">
            </div>
          </div>
        </div>
      </div>
      <div class="summary-info-card-content-section">
        <h3 *ngFor="let row of stageValue.list;" class="summary-info-card-row stage-margin-list top-zero-padding">
          <span style="width: 100% !important;" class="summary-info-card-row-col summary-info-card-title stats-row-width dashboard-stats-label stage-list-card">
	<div fxLayout="row" class="font-normal-size" fxLayoutAlign="space-between center">

          <span>{{row.key}}</span> <span>{{row.value}}</span>
      </div>
      </span>

      </h3>

    </div>
  </div>
</div>
</div>