<div class="tree-list-xs">
  <mat-tree class="tree-list-xs" [dataSource]="data" [treeControl]="tc">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li>
        <div>
          <button mat-icon-button disabled>
          </button>
          {{node.name}}
        </div>
      </li>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon>
              {{tc.isExpanded(node) ? &apos;arrow_drop_up&apos; : &apos;arrow_drop_down&apos;}}
            </mat-icon>
          </button> <img class="common-add-list-images menu-margin-xs" imgSrc="/Web/Icons/Leads.png">{{node.name}}
        </div>
        <ul [hidden]="!tc.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>