/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { pipelineservice } from 'app/sd-services/pipelineservice';
import { srmappservice } from 'app/sd-services/srmappservice';
// import { tenantservice } from 'app/sd-services/tenantservice';
import { NeutrinosOAuthClientService } from 'neutrinos-oauth-client';
import { settingsService } from '../settings/settings.service';
import { udfsyncService } from '../udfsync/udfsync.service';

@Injectable()
export class tenantresolverService {

    constructor(private srmappService: srmappservice,
        private authService: NeutrinosOAuthClientService,
		private _pls: pipelineservice,
		private _udfSyncservice: udfsyncService,
		private settings: settingsService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise(async (resolve, reject) => {
            if (this.authService['currentUserInfo'] && this.authService['currentUserInfo'].hasOwnProperty('srmTenantid')) {
                // get and init tenant settings
                try {
					// Maintaining a reference for phone and currency code in udfSyncservince so that it can be 
					// accessed synchronously in the udf setting component tree
                    this._udfSyncservice.phoneCountryCodes = (await this.srmappService.initPhoneCountryCodes()).local.phonecountrycodes?.data;
                    this._udfSyncservice.currencyCodes = (await this.srmappService.initCurrencyCodes()).local.currencycodes?.data;
					await this._pls.getActivePipeline('deal');
					await this.settings.refreshSettings();
                    return resolve();
                } catch (error) {
                    return reject(error);
                }
            }
        })
    }

    
}
