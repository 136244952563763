/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, SimpleChange } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { labelService } from 'app/services/label/label.service';

export interface Pipe {
    pipe_name: string; // "Call Scheduled"
    pipe_order: number; // 2
    pipeid: number; // 2
    pipelineid: number; // 1
    probabilty: number; // 10
    checked?: boolean; // for UI
}

export interface PipelineStatus {
    label: string;
    value: string;
    won_status?: boolean;
    lost_status?: boolean;
}

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-pipeline',
    templateUrl: './pipeline.template.html'
})

export class pipelineComponent extends NBaseComponent implements OnInit, OnChanges {
    @Input('pipeline') pipeline = []; // Array<Pipe>
    @Input('statuses') statuses = []; // Array<PipelineStatus>
    @Input('selected_pipe_name') selected_pipe_name;
    @Input('selected_status') selected_status;
    @Input('accessDenied') accessDenied = true;
    @Output() statusChangeEvent = new EventEmitter();
    @Output() pipeChangeEvent = new EventEmitter();
    status_label = '';
    statusCssFlag = false;
    statusesFiltered = [];
    constructor(public label: labelService) {
        super();
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selected_pipe_name || changes.pipeline || changes.selected_status) {
            if (this.pipeline.length) {
                this.checkMarkTillSelectedPipe(this.selected_pipe_name);
                this.statusCssFlag = this.isWonOrLost();
                this.filterSelectedStatus();
                this.createStatusLabel();
            }
        }
    }

    checkMarkTillSelectedPipe(selected_pipe_name) {
        if (this.pipeline instanceof Array) {
            this.pipeline = this.pipeline.map(v => {
                v.checked = false;
                return v;
            })

            const selectedPipeIndex = this.pipeline.findIndex((v: Pipe) => {
                return v.pipe_name == selected_pipe_name;
            })
            for (let i = 0; i <= selectedPipeIndex; i++) {
                this.pipeline[i].checked = true;
            }
        }
    }

    createStatusLabel() {
        if (this.statusCssFlag) {
            this.status_label = this.selected_status;
        } else {
            const won = this.statuses.find((v: PipelineStatus) => {
                return v.won_status;
            })['label'];
    
            const lost = this.statuses.find((v: PipelineStatus) => {
                return v.lost_status;
            })['label'];
    
            this.status_label = won + '/' + lost;
        }
    }

    statusChange(status) {
        this.statusChangeEvent.emit(status);
    }

    updatePipe(value) {
        if (this.checkIfLostStatus()) {
            return;
        }
        this.pipeChangeEvent.emit(value);
    }

    isWonOrLost() {
        const s: PipelineStatus = this.statuses.find((v: PipelineStatus) => {
            return v.value == this.selected_status;
        });

        if (s && (s.lost_status || s.won_status)) {
            return true;
        }
        return false;
    }

    filterSelectedStatus() {
        if (this.selected_status) {
            this.statusesFiltered = this.statuses.filter((p: PipelineStatus) => {
                return p.value !== this.selected_status;
            })
        }
    }

    checkIfLostStatus() {
        const lost: PipelineStatus = this.statuses.find((v: PipelineStatus) => {
            return v.lost_status;
        });

        return this.selected_status == lost.value;
    }

    getPipeType(value) {
        var temp = { status: value, existing_status: this.selected_status }
        if (this.pipeline.length) {
            this.statusChangeEvent.emit({ temp });
        }
    }

}
