/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cacheservice } from 'app/sd-services/cacheservice';
import { contactservice } from 'app/sd-services/contactservice';
import { dealservice } from 'app/sd-services/dealservice';
import { userservice } from 'app/sd-services/userservice';
import { labelService } from 'app/services/label/label.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Contact } from '../contactdetailsComponent/contactdetails.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

let contactValues: Array<any> = [];

@Component({
    selector: 'bh-adddeal',
    templateUrl: './adddeal.template.html'
})

export class adddealComponent extends NBaseComponent implements OnInit, OnDestroy {
    addDealFormGroup = this.fb.group({
        deal_name: ['', Validators.required],
        deal_currency: [''],
        deal_value: [''],
        related_contact: ['', Validators.required]
    });
    private currentUserId;
    errorMsg = "";
    @ViewChild(MatAutocompleteTrigger) trigger;
    currencyOptions: string[] = ['Singapore Dollar(S$)', 'USA Dollar(US$)'];
    filteredOptions: Observable<string[]>;
    contactArr = [];
    contactId;
    readonly page_size = 100;
    autocompleteContactLatest = { timestamp: Date.now() };
    contact_page_no = 1;
    private unsubNotifier$ = new Subject();
    disableAdd = false;
    contactRequired = true;

    constructor(private fb: FormBuilder,
        private pubsub: NPubSubService,
		private dealS: dealservice,
        private userS: userservice,
		private snackBar: MatSnackBar,
		private contactS: contactservice,
        private cacheService: cacheservice,
        public label: labelService) {
        super();
    }

    ngOnInit() {
        this.filteredOptions = this.addDealFormGroup.controls.deal_currency.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
        this.currentUserId = this.userS.user.srmUserid;
        this.cacheService.getc('CONTACT_ID_VALUE')
            .then((result) => {
                if (result && result.local.data) {
					const d: Contact = result.local.data;
                    this.addDealFormGroup.patchValue({ related_contact: d.contactid });
                    contactValues = [d];
                    this.contactArr = contactValues;
                    this.contactId = d.contactid;
                }
            })
        this.cacheService.getc('DEAL_ID_VALUE')
            .then((result) => {
                if (result && result.local.data) {
                    this.addDealFormGroup.patchValue({ related_contact: result.local.data['contactId'] });
                    contactValues = [{ 'contactid': result.local.data['contactId'], 'first_name': result.local.data['first_name'], 'last_name': result.local.data['last_name'], display_name: result.local.data.contactName}];
                    this.contactArr = contactValues;
                    this.contactId = result.local.data['contactId'];
                }
            })
    }

    displayContact(v) {
        if (v) {
            if (contactValues.length) {
                const selectedValue = contactValues.filter(val => {
                    return val.contactid == v;
                })
                return selectedValue[0] ? selectedValue[0]['first_name'] + ' ' + selectedValue[0]['last_name'] : null;
            }
        }
        return null;
    }


    getContactNameByID(value) {
        this.contactS.getContactById(value).then((res) => {
            if (res) {
                this.addDealFormGroup.patchValue({ related_contact: res.local.result.data['contactid'] });
                contactValues = [{ 'contactid': res.local.result.data['contactid'], 'first_name': res.local.result.data['first_name'], 'last_name': res.local.result.data['last_name'] }]
                this.contactId = res.local.result.data.contactid;
            }
        })
    }

    private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
        return this.currencyOptions.filter(option => option.toLowerCase().includes(filterValue));
    }

    getContactId(contactid) {
        this.contactId = contactid;
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' })
    }

	validateInputData(): boolean {
		if (this.addDealFormGroup.get('related_contact').invalid) {
			this.errorMsg = 'Invalid Related Contact';
		} else if (this.addDealFormGroup.get('deal_name').invalid) {
			this.errorMsg = 'Invalid Name';
		} else if (this.addDealFormGroup.get('deal_currency').invalid) {
			this.errorMsg = 'Invalid currency';
		}
		return this.addDealFormGroup.valid;
	}

	add() {
		if (!this.validateInputData()) {
			return;
		}
		this.disableAdd = true;
		let deal_currency_value = (!this.addDealFormGroup.value.deal_currency) ? null : this.addDealFormGroup.value.deal_currency;
		this.dealS.createDeal(this.addDealFormGroup.value.deal_name,
			deal_currency_value,
			this.addDealFormGroup.value.deal_value,
			this.addDealFormGroup.value.related_contact)
			.then(result => {
				if (result) {
					this.snackBar.open(`${this.label.toTitleCase(this.label.deal)} was successfully created.`, 'OK', {
						duration: 3000
					})
					this.close();
					this.pubsub.$pub('deal-added-event');
					this.pubsub.$pub('refreshstatslist');
				}
				this.disableAdd = false;
			})
			.catch(error => {
				this.snackBar.open(`An error was encountered while creating a ${this.label.toTitleCase(this.label.deal)}.`, 'OK', {
					duration: 3000
				})
				this.disableAdd = false;
			})
	}

    ngOnDestroy() {
        this.unsubNotifier$.next();
        this.unsubNotifier$.complete();
    }

}
