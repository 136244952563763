/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-filteroptionsconfig',
    templateUrl: './filteroptionsconfig.template.html'
})

export class filteroptionsconfigComponent extends NBaseComponent implements OnInit {
    @Input('header') header = '';
    @Input('filterOptions') filterOptions = [];
    @Output() filters = new EventEmitter();
    @Input('selectedFilters') selectedFilters = [];
    @Input('beingUsedInTeamFilter') beingUsedInTeamFilter;

    constructor() {
        super();
    }

    ngOnInit() {

    }
    selectFilters(event, tag) {
        if (tag !== 'Select All') {
            if (event.checked ) {
                this.selectedFilters.push(tag)
            } else {
                for (let i = 0; i < this.selectedFilters.length; i++) {
                    if (this.selectedFilters[i] == tag) {
                        this.selectedFilters.splice(i, 1);
                    }
                }
            }
            if (this.selectedFilters.length < this.filterOptions.length - 1) {
                this.filterOptions['0']['isSelected'] = false;
            }
            if (this.selectedFilters.length == this.filterOptions.length - 1) {
                this.filterOptions['0']['isSelected'] = true;
            }
        }
        if (tag === 'Select All') {
            this.selectedFilters = [];
            if (event.checked) {
                for (let i in this.filterOptions) {
                    if (this.filterOptions[i]['tag'] !== 'Select All') {
                        this.filterOptions[i]['isSelected'] = true;
                        if (!this.beingUsedInTeamFilter)
                            this.selectedFilters.push(this.filterOptions[i]['tag']);
                        else
                            this.selectedFilters.push(this.filterOptions[i]['user_id']);
                    }
                }
            } else {
                for (let i in this.filterOptions) {
                    this.filterOptions[i]['isSelected'] = false;
                }
            }
        } 
        this.filters.emit({ count: this.selectedFilters.length, filters: this.selectedFilters });
    }
}
