/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class activityservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_activityservice

  async getAllActivities(
    moduleName: any = undefined,
    moduleId: any = undefined,
    offset: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          offset: offset
        },
        local: {
          activityData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_yrS0NzAQoo01OEeL(bh);
      //appendnew_next_getAllActivities
      return (
        // formatting output variables
        {
          input: {},
          local: {
            activityData: bh.local.activityData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_pxnIGJtCNg1M82Qd');
    }
  }

  async getAppointments(
    moduleName: any = undefined,
    moduleId: any = undefined,
    offset: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          offset: offset
        },
        local: {
          activityData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_APMZpn5uwKo4V4Dq(bh);
      //appendnew_next_getAppointments
      return (
        // formatting output variables
        {
          input: {},
          local: {
            activityData: bh.local.activityData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_gNYqeLtM73a5CHgi');
    }
  }

  async getCalls(
    moduleName: any = undefined,
    moduleId: any = undefined,
    offset: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          offset: offset
        },
        local: {
          activityData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_hWGor8LSoa633wkj(bh);
      //appendnew_next_getCalls
      return (
        // formatting output variables
        {
          input: {},
          local: {
            activityData: bh.local.activityData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_A7V7qvnB88AWM9RQ');
    }
  }

  async getTasks(
    moduleName: any = undefined,
    moduleId: any = undefined,
    offset: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          offset: offset
        },
        local: {
          activityData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_14dAOJ4TjIhScZ8q(bh);
      //appendnew_next_getTasks
      return (
        // formatting output variables
        {
          input: {},
          local: {
            activityData: bh.local.activityData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_aeBGprNQ3i2Cjmz5');
    }
  }

  //appendnew_flow_activityservice_Start

  async sd_yrS0NzAQoo01OEeL(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'activities/all/' +
        bh.input.moduleName +
        '/' +
        bh.input.moduleId +
        '?offset=' +
        bh.input.offset;
      bh = await this.sd_geTaxjmFFTovIKkQ(bh);
      //appendnew_next_sd_yrS0NzAQoo01OEeL
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_yrS0NzAQoo01OEeL');
    }
  }

  async sd_geTaxjmFFTovIKkQ(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.activityData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_geTaxjmFFTovIKkQ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_geTaxjmFFTovIKkQ');
    }
  }

  async sd_APMZpn5uwKo4V4Dq(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'activities/appointments/' +
        bh.input.moduleName +
        '/' +
        bh.input.moduleId +
        '?offset=' +
        bh.input.offset;
      bh = await this.sd_O5DjJMk0J6YeiZ9S(bh);
      //appendnew_next_sd_APMZpn5uwKo4V4Dq
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_APMZpn5uwKo4V4Dq');
    }
  }

  async sd_O5DjJMk0J6YeiZ9S(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.activityData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_O5DjJMk0J6YeiZ9S
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_O5DjJMk0J6YeiZ9S');
    }
  }

  async sd_hWGor8LSoa633wkj(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'activities/calls/' +
        bh.input.moduleName +
        '/' +
        bh.input.moduleId +
        '?offset=' +
        bh.input.offset;
      bh = await this.sd_nhHxd40IQgJJkgwr(bh);
      //appendnew_next_sd_hWGor8LSoa633wkj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_hWGor8LSoa633wkj');
    }
  }

  async sd_nhHxd40IQgJJkgwr(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.activityData = await this.sdService.nHttpRequest(requestOptions);
      bh = await this.sd_7n3KBnohyttLYoKz(bh);
      //appendnew_next_sd_nhHxd40IQgJJkgwr
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_nhHxd40IQgJJkgwr');
    }
  }

  async sd_7n3KBnohyttLYoKz(bh) {
    try {
      bh.local.activityData.data = bh.local.activityData.data.map(a => {
        if (a.mode === 'outgoing') {
          const tpcc = a.to_phone_country_code || '';
          a.activity_title =
            'Dialed Number: ' +
            (tpcc
              ? tpcc + ' ' + a.to_phone_number.slice(tpcc.length - 1)
              : a.to_phone_number);
        } else {
          const upcc = a.user_phone_country_code || '';
          a.activity_title =
            'Incoming Number: ' +
            (upcc
              ? upcc + ' ' + a.user_phone_number.slice(upcc.length - 1)
              : a.user_phone_number);
        }
        a.activity_title_prefix_icon = `${a.mode}-call-image`;
        return a;
      });
      //appendnew_next_sd_7n3KBnohyttLYoKz
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_7n3KBnohyttLYoKz');
    }
  }

  async sd_14dAOJ4TjIhScZ8q(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'activities/tasks/' +
        bh.input.moduleName +
        '/' +
        bh.input.moduleId +
        '?offset=' +
        bh.input.offset;
      bh = await this.sd_8WjyIudQ0xr3G3OF(bh);
      //appendnew_next_sd_14dAOJ4TjIhScZ8q
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_14dAOJ4TjIhScZ8q');
    }
  }

  async sd_8WjyIudQ0xr3G3OF(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.activityData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_8WjyIudQ0xr3G3OF
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_8WjyIudQ0xr3G3OF');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_activityservice_Catch
}
