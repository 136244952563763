/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class externallinkservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_externallinkservice

  async getExternalLinks(
    modulename: any = undefined,
    moduleid: any = undefined,
    supermodulename: any = undefined,
    supermoduleid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          modulename: modulename,
          moduleid: moduleid,
          supermodulename: supermodulename,
          supermoduleid: supermoduleid
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_ZLiV8e27kDZv8Oa3(bh);
      //appendnew_next_getExternalLinks
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lcgtmkqfTlUnUGfl');
    }
  }

  async setExternalLinks(
    modulename: any = undefined,
    externalLinks: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          modulename: modulename,
          externalLinks: externalLinks
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_MGdEysuztHcnAosV(bh);
      //appendnew_next_setExternalLinks
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_KYPzFKU713644KoK');
    }
  }

  //appendnew_flow_externallinkservice_Start

  async sd_ZLiV8e27kDZv8Oa3(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'external-links/' +
        bh.input.modulename +
        '/' +
        bh.input.moduleid;

      if (bh.input.supermodulename) {
        bh.url += '/' + bh.input.supermodulename;
      }

      if (bh.input.supermoduleid) {
        bh.url += '/' + bh.input.supermoduleid;
      }
      bh = await this.sd_DpA7LMb3bLmrS7Tb(bh);
      //appendnew_next_sd_ZLiV8e27kDZv8Oa3
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ZLiV8e27kDZv8Oa3');
    }
  }

  async sd_DpA7LMb3bLmrS7Tb(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_DpA7LMb3bLmrS7Tb
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_DpA7LMb3bLmrS7Tb');
    }
  }

  async sd_MGdEysuztHcnAosV(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'external-links/' +
        bh.input.modulename +
        '/' +
        bh.input.moduleid;

      bh = await this.sd_pUnrsHymXcVs2Kt1(bh);
      //appendnew_next_sd_MGdEysuztHcnAosV
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MGdEysuztHcnAosV');
    }
  }

  async sd_pUnrsHymXcVs2Kt1(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.externalLinks
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_pUnrsHymXcVs2Kt1
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_pUnrsHymXcVs2Kt1');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_externallinkservice_Catch
}
