<div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column wrap" fxFlex="10" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="circle-dp" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
      <div>
        J</div>
    </div>
  </div>
  <div fxLayout="column wrap" fxFlex="90" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="20" fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="60" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div class="font-normal-size font-color-emial-item common-font-small">
          {{user_name}}</div>
      </div>
      <div fxLayout="row wrap" fxFlex="40" fxLayoutGap="10px" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
        <div class="font-normal-size font-color-emial-item small-font">
          {{date | date:&apos;d MMM y, h:mm a&apos;}}</div><img imgSrc="Web/Icons/reply.svg" style="align-self: start" class="padding-top-1px">
      </div>
    </div>
    <div class="font-normal-size small-font font-color-emial-item">
      {{email_content}}</div>
  </div>
</div>