<div fxLayout="column " class="common-calendar-gt-xs border-radius background-primary" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" fxFlex="10" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " class="height-100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="24" fxLayoutAlign="center start" [fxShow]="true" [fxHide]="false" fxFlex.md="32"><span class="font-weight-size font-18">
                CALENDAR</span></div>
      <div fxLayout="column wrap" fxFlex="44" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false" fxFlex.xs="100" fxFlex.md="34">
        <div fxLayout="row " fxLayoutGap="15px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="calendarView == &apos;week&apos;">
          <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <mat-icon class="cursor-pointer" mwlCalendarPreviousView [view]="calendarView" [(viewDate)]="viewDate" (click)="getPreviousValue(&apos;Week&apos;)"><i class="material-icons"> keyboard_arrow_left </i></mat-icon>
          </div>
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <span class="calendarDayMonth" mwlCalendarToday [(viewDate)]="viewDate">{{viewDate | date: &quot;fullDate&quot;}} </span></div>
          </div>
          <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <mat-icon class="cursor-pointer" [view]="calendarView" [(viewDate)]="viewDate" mwlCalendarNextView (click)="getNextValue(&apos;Week&apos;)"><i class="material-icons"> keyboard_arrow_right </i></mat-icon>
          </div>
        </div>
        <div fxLayout="row " fxLayoutGap="15px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="calendarView == &apos;month&apos;">
          <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <mat-icon class="cursor-pointer" mwlCalendarPreviousView [view]="calendarView" [(viewDate)]="viewDate" (click)="getPreviousValue(&apos;Month&apos;)"><i class="material-icons"> keyboard_arrow_left </i></mat-icon>
          </div>
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <span class="calendarDayMonth" mwlCalendarToday [(viewDate)]="viewDate">{{viewDate | date: &quot;MMM yyyy&quot;}} </span>
            </div>
          </div>
          <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <mat-icon class="cursor-pointer" [view]="calendarView" [(viewDate)]="viewDate" mwlCalendarNextView (click)="getNextValue(&apos;Month&apos;)"><i class="material-icons"> keyboard_arrow_right </i></mat-icon>
          </div>
        </div>
        <div fxLayout="row " fxLayoutGap="15px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="calendarView == &apos;day&apos;">
          <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" (click)="weekHeader(viewDate)">
            <mat-icon class="cursor-pointer" mwlCalendarPreviousView [view]="calendarView" [(viewDate)]="viewDate" (click)="getPreviousValue(&apos;Day&apos;)"><i class="material-icons"> keyboard_arrow_left </i></mat-icon>
          </div>
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              <span class="calendarDayMonth" mwlCalendarToday [(viewDate)]="viewDate">{{viewDate | date:&apos;fullDate&apos;}} </span>
            </div>
          </div>
          <div fxLayout="column wrap" class fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" (click)="weekHeader(viewDate)">
            <mat-icon class="cursor-pointer" [view]="calendarView" [(viewDate)]="viewDate" mwlCalendarNextView (click)="getNextValue(&apos;Day&apos;)"><i class="material-icons"> keyboard_arrow_right </i></mat-icon>
          </div>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="32" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false" fxFlex.md="38">
        <div>
          <form #toggel="ngForm">
            <button mat-raised-button (click)="getMonthValue(); getCurrentDate()" mwlCalendarToday class="add-feedback-btn" [(viewDate)]="viewDate">Today</button>
            <mat-button-toggle-group (click)="resetCalendarDate()" class="custom-mat-toggle margin-left-1" name="buttonTogg" [(ngModel)]="calendarView">
              <mat-button-toggle value="month" aria-label="Text align center"> Month
              </mat-button-toggle>
              <mat-button-toggle value="week" aria-label="Text align center"> Week </mat-button-toggle>
              <mat-button-toggle value="day" aria-label="Text align left"> Day </mat-button-toggle>
            </mat-button-toggle-group>
          </form>
        </div>
      </div>
      <div>
        <ng-template #headerTemplate let-days="days" let-locale="locale" let-dayClicked="dayClicked" let-eventDropped="eventDropped">
          <div class="cal-cell-row cal-header">
            <div class="cal-cell" *ngFor="let day of days" [class.cal-past]="day.isPast" [class.cal-future]="day.isFuture" [class.cal-drag-over]="day.dragOver" mwlDroppable (dragEnter)="day.dragOver = true" (dragLeave)="day.dragOver = false" (drop)="day.dragOver = false; eventDropped.emit({event: $event.dropData.event, newStart: day.date, fromHeader: true})" fxLayoutAlign="center center">
              {{day.date | date: &apos;EE&apos;}}
            </div>
          </div>
        </ng-template>
        <ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayClicked="dayClicked" let-eventDropped="eventDropped">
          <div class="cal-day-headers">
            <div class="cal-header" id="valueDate" *ngFor="let day of days" [class.cal-past]="day.isPast" [class.cal-future]="day.isFuture" [class.cal-drag-over]="day.dragOver" (mwlClick)="weekHeader(day.date)" mwlDroppable (dragEnter)="day.dragOver = true" (dragLeave)="day.dragOver = false" (drop)="day.dragOver = false; eventDropped.emit({event: $event.dropData.event, newStart: day.date, fromHeader: true})">
              <div>
                <div *ngIf="calendarView == &apos;week&apos;">
                  <span>{{ day.date |date:&quot;EE&quot;}}</span> <span [ngClass]="((calenderWHeader ==  day.date.getDate()) &amp;&amp; (calenderMonth ==  day.date.getMonth())) ? &apos;current-date&apos;: &apos;&apos; "> {{&quot; &quot;}}{{day.date | date:&quot;dd &quot;}}</span>
                </div>
                <div *ngIf="calendarView == &apos;day&apos;">
                  <span>{{ day.date |date:&quot;EE&quot;}}</span> <span [ngClass]="(calenderWHeader ==  day.date.getDate()) ? &apos;current-date&apos;: &apos;&apos; "> {{&quot; &quot;}}{{day.date | date:&quot;dd &quot;}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #customCellTemplate let-day="day " let-locale="locale ">
          <div class="cal-cel-view">
            <div>
              <span class="cal-day-number">{{ day.date | calendarDate:&apos;monthViewDayNumber&apos;:locale }}</span>
              <span *ngIf="day.events.length &gt; 2" class="eventsCount"> {{day.events.length - 2}} more </span>
            </div>
            <div style="margin-top: 32px;">
              <div *ngFor="let event of day.events;">
                <span class="cal-title-align text-ellipsis">
                <span class="custom-round-icon"></span>
                <span class="font-primary-color">{{(event[&apos;color&apos;][&apos;secondary&apos;] == &quot;#0f9d7c&quot;) ? &apos;Task - &apos; : &apos;App - &apos;}}{{ event.title}}</span>
                </span>

              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y" fxFlex="90" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngSwitch]="calendarView" #scrollContainer>
    <div>
      <mwl-calendar-week-view class="day-view-border" *ngSwitchCase="&apos;day&apos;" [viewDate]="viewDate" [events]="calendarEvents" [headerTemplate]="weekHeaderTemplate" [refresh]="refresh">
      </mwl-calendar-week-view>
    </div>
    <div>
      <mwl-calendar-month-view *ngSwitchCase="&apos;month&apos;" [viewDate]="viewDate" [events]="calendarEvents" (dayClicked)="viewDate = $event.day.date; calendarView = &apos;day&apos;;calenderWHeader = $event.day.date.getDate();" [headerTemplate]="headerTemplate" [cellTemplate]="customCellTemplate" [refresh]="refresh">
      </mwl-calendar-month-view>
    </div>
    <div>
      <mwl-calendar-week-view *ngSwitchCase="&apos;week&apos;" style="overflow-y:scroll;" class="cursor-pointer cal-event-border" [viewDate]="viewDate" [events]="calendarEvents" [headerTemplate]="weekHeaderTemplate" (eventClicked)="handleEvent($event.event)" [refresh]="refresh">
      </mwl-calendar-week-view>
    </div>
    <div *ngSwitchCase="&apos;day&apos;">
      <mwl-calendar-day-view class="cal-event-border" [viewDate]="viewDate" [events]="calendarEvents" [refresh]="refresh" (eventClicked)="handleEvent( $event.event)">
      </mwl-calendar-day-view>
    </div>
  </div>
</div>