<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center center">
      <div fxLayout="column " class="small-screen-width" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="88" [ngClass.xs]="&apos;mobile-screen-resize&apos;">
        <div>
          <div class="common-search-input" [ngClass.xs]="&apos;background-primary search-input.xs&apos;">
            <mat-form-field class="search-icon" floatLabel="never">
              <img matPrefix [matMenuTriggerFor]="searchtask" class="search-icon-border" [src]="&apos;assets/Web/Icons/&apos;+displayImage+&apos;.svg&apos;" click-stop-propagation>
              <input matInput name="tableSearch" placeholder="Search" [(ngModel)]="searchValue" (keyup)="search()">
              <img class="cursor-pointer" *ngIf="searchValue" (click)="clear()" matSuffix style="margin-left: -35px;margin-top: 1px;padding-left: 17px;" imgSrc="/Web/Icons/close.svg ">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-card fxLayout="column" *ngIf="searchValue.length &amp;&amp; !checkSearchList" class="padding-0 search-card-element">
  <mat-card-content class="overflow-y" *ngIf="contactSearchArr || dealSearchArr">
    <div fxLayout="column " class="search-list-height width-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="(contactSearchArr.length || dealSearchArr.length)">
      <div fxLayout="column " class="overflow-hidden" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="contactSearchArr &amp;&amp; contactSearchArr.length" [ngStyle]="{&apos;height&apos; : (contactSearchArr.length &gt; 5) ? &apos;26.5em&apos; : &apos;100%&apos;}">
        <div fxLayout="column wrap" class="light-mid-blue-color font-weight-size padding-value search-label" fxFlex="40px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div>
            <img class="common-add-list-images label-padding-right" imgSrc="/Web/Icons/searchcontact.svg">Contacts</div>
        </div>
        <div fxLayout="column " class="overflow-y" fxFlex="calc(100% - 80px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" class="element-border padding-value" fxFlex="1 0 auto" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let val of contactSearchArr; let i = index;">
            <div fxLayout="row wrap" class="cursor-pointer width-100" fxFlex="48px" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" (click)="navigateContact(val, i)">
              <div fxLayout="column " fxFlex="75" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <div fxLayout="column wrap" fxFlex="16px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                  <div *ngIf="val.display_name" class="color-black">
                    <!-- {{val.display_name }} -->
                    <div [innerHTML]="val.display_name | highlight: searchValue"></div>
                    <!-- <div *ngIf="val.contact_phone_no" [innerHTML]="val.contact_phone_no | highlight: searchValue"></div> | <div *ngIf="val.contact_email_id" [innerHTML]="val.contact_email_id | highlight: searchValue"></div> -->
                  </div>
                </div>
                <div fxLayout="row " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                  <h6 *ngIf="val.contact_phone_no" class="label-padding-right search-value-label">
                    <!-- {{val.contact_phone_no}} -->
                    <div [innerHTML]="val.contact_phone_country_code | highlight: searchValue"></div>
                  </h6>
                  <h6 *ngIf="val.contact_phone_no" class="search-value-label">
                    <!-- {{val.contact_phone_no}} -->
                    <div [innerHTML]="val.contact_phone_no | highlight: searchValue"></div>
                  </h6>
                  <h6 *ngIf="val.contact_phone_no &amp;&amp; val.contact_email_id" class="border-align-view">
                  </h6>
                  <h6 *ngIf="val.contact_email_id" class="display-grid search-value-label">
                    <!-- {{val.contact_email_id}} -->
                    <div class="text-ellipsis" [innerHTML]="val.contact_email_id | highlight: searchValue"></div>
                  </h6>
                </div>
              </div>
              <div fxLayout="column " fxFlex="25" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
                <div fxLayout="column wrap" fxFlex="16px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/owner.svg"></div>
                <h6 *ngIf="val.contact_owner_name" class="line-height-view">
                  {{val.contact_owner_name}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" style="text-align: center;" fxFlex="40px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [ngClass]="(contactsCount == contactSearchArr.length) ? &apos;display-none&apos; : &apos;&apos;">
          <h5 (click)="viewMore(&apos;contacts&apos;)" class="cursor-pointer view-older">
            View More</h5>
        </div>
      </div>
      <div fxLayout="column " class="overflow-hidden" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="dealSearchArr &amp;&amp; dealSearchArr.length" [ngStyle]="{&apos;height&apos; : (dealSearchArr.length &gt; 5) ? &apos;26.5em&apos; : &apos;100%&apos;}">
        <div fxLayout="column wrap" class="light-mid-blue-color font-weight-size padding-value search-label" fxFlex="40px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div>
            <img class="common-add-list-images label-padding-right" imgSrc="/Web/Icons/searchdeal.svg">{{label.deals | titlecase}}</div>
        </div>
        <div fxLayout="column " class="overflow-y" fxFlex="calc(100% - 80px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" class="element-border padding-value" fxFlex="1 0 auto" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let val of dealSearchArr; let i = index;">
            <div fxLayout="row wrap" class="cursor-pointer" fxFlex="48px" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" (click)="navigateDeal(val, i)">
              <div fxLayout="column wrap" fxFlex="75" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <div fxLayout="column wrap" fxFlex="16px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                  <div *ngIf="val.deal_name" class="color-black">
                    <!-- {{val.display_name }} -->
                    <div [innerHTML]="val.deal_name | highlight: searchValue"></div>
                    <!-- <div *ngIf="val.contact_phone_no" [innerHTML]="val.contact_phone_no | highlight: searchValue"></div> | <div *ngIf="val.contact_email_id" [innerHTML]="val.contact_email_id | highlight: searchValue"></div> -->
                  </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                  <h6 *ngIf="val.contact_display_name" class="search-value-label">
                    <!-- {{val.contact_phone_no}} -->
                    <div [innerHTML]="val.contact_display_name | highlight: searchValue"></div>
                  </h6>
                </div>
              </div>
              <div fxLayout="column wrap" fxFlex="25" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
                <div fxLayout="column wrap" fxFlex="16px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/owner.svg"></div>
                <h6 *ngIf="val.contact_owner_name" class="line-height-view">
                  {{val.contact_owner_name}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" style="text-align: center;" fxFlex="40px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" [ngClass]="(dealsCount == dealSearchArr.length) ? &apos;display-none &apos; : &apos;&apos;">
          <h5 (click)="viewMore(&apos;deals&apos;)" class="cursor-pointer view-older">
            View More</h5>
        </div>
      </div>
    </div>
    <div fxLayout="column wrap" class="padding-3 height-100" fxFlex="100" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="!searchValue.length || (!contactSearchArr.length &amp;&amp; !dealSearchArr.length)">
      <div class="no-search-image">
      </div>
      <h5 class="light-gray-color margin-top-1 line-height-view">
        No results found</h5>
    </div>
  </mat-card-content>
</mat-card>
<mat-menu #searchtask class="commom-menu-filter" xPosition="after" #menu="matMenu"><button mat-menu-item [ngClass]="(searchTask == &apos;All&apos;) ? &apos;common-menu-select-task&apos; : &apos;common-menu-select-option&apos;" (click)="selectedValue(&apos;All&apos;)" class="color-black common-menu-item-align font-Weight-size font-Family"> <img class="common-add-list-images" imgSrc="/Web/Icons/allsearch.svg">All </button><button mat-menu-item [ngClass]="(searchTask == &apos;Contacts&apos;) ? &apos;common-menu-select-task&apos; : &apos;common-menu-select-option&apos;" (click)="selectedValue(&apos;Contacts&apos;)" class="color-black common-menu-item-align font-Weight-size font-Family"> <img class="common-add-list-images" imgSrc="/Web/Icons/contact.svg">Contacts </button><button mat-menu-item [ngClass]="(searchTask == &apos;Deals&apos;) ? &apos;common-menu-select-task&apos; : &apos;common-menu-select-option&apos;" (click)="selectedValue(&apos;Deals&apos;)" class="color-black common-menu-item-align font-Weight-size font-Family"> <img class="common-add-list-images common-menu-task-margin" imgSrc="/Web/Icons/deal.svg">{{label.deals | titlecase}} </button></mat-menu>