/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-sidenavformheader',
	templateUrl: './sidenavformheader.template.html'
})

export class sidenavformheaderComponent extends NBaseComponent implements OnInit {
	@Input('title') title = '';
	@Input('titleIconUrl') titleIcon = '';
	@Output('closeClicked') _closeClicked = new EventEmitter<any>();

	// @ViewChild('titleIconElm') icon: ElementRef;

	// _iconStyle = [
	// 	'width: 1.25rem',
	// 	'height: 1.25rem',
	// 	`mask: url(${this.titleIcon}) no - repeat center / contain`,
	// 	`-webkit-mask: url(${this.titleIcon}) no - repeat center / contain`,
	// 	'background-color: var(--color-black)',
	// ]
	constructor() {
		super();
	}

	ngOnInit() {
		this.title = this.title.toUpperCase();
	}

	ngAfterViewInit() {
		// (<HTMLElement>this.icon.nativeElement).style.cssText = this._iconStyle.join(';');
	}

	close() {
		this._closeClicked.next()
	}
}
