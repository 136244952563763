/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';

@Injectable()
export class labelService {
    private __deal = 'deal';
    private __deals = 'deals';
    set deal(deal) {
        if (deal) {
            this.__deal = deal;
        }
    }

    get deal() {
        return this.__deal;
    }

    set deals(deals) {
        if (deals) {
            this.__deals = deals;
        }
    }

    get deals() {
        return this.__deals;
    }

    toTitleCase(txt) {
        if (typeof txt == 'string') {
            return txt.split(/\s|\t/)/* .map(w => w.trim()) */.map(w => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase()).join(' ');
        }
        return txt;
    }

    capitalize(txt) {
        if (typeof txt == 'string') {
            return txt.toUpperCase();
        }
        return txt;
    }
}
