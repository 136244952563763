<div fxLayout="column wrap" style="width: 400px;position: fixed;bottom: 0;z-index:1001" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div #ccpDiv id="containerDiv" [ngClass]="ccpHide?&apos;hide-ccp&apos;:&apos;&apos;" style="height: 500px;">
  </div>
  <mat-icon style="margin:33px;cursor:pointer;color:white" (click)="ccpHide=!ccpHide">call</mat-icon>
</div>
<bh-bltoolbar (openMenu)="sidenavToggle()"></bh-bltoolbar>
<mat-sidenav #sidenav #sidenavform class="sidenav-form" [mode]="&apos;over&apos;" [position]="&apos;end&apos;" [opened]="false" autoFocus="true" disableClose="true" fixedBottomGap="0" fixedInViewport="true" fixedTopGap="0">
  <bh-addcontact *ngIf="loadInSidenav === &apos;ADD_CONTACT&apos;"></bh-addcontact>
  <bh-scheduleappointment *ngIf="loadInSidenav === &apos;SCHEDULE_APPOINTMENT&apos;"></bh-scheduleappointment>
  <bh-adddeal *ngIf="loadInSidenav === &apos;ADD_DEAL&apos;"></bh-adddeal>
  <bh-editcontact *ngIf="loadInSidenav === &apos;EDIT_CONTACT&apos;"></bh-editcontact>
  <bh-editdeal *ngIf="loadInSidenav === &apos;EDIT_DEAL&apos;"></bh-editdeal>
  <bh-addtask *ngIf="loadInSidenav === &apos;ADD_TASK&apos;"></bh-addtask>
  <bh-feedback_form_call *ngIf="loadInSidenav === &apos;FEEDBACK_FORM_CALL_ACTIVITY&apos;"></bh-feedback_form_call>
  <bh-feedback_form_appointment *ngIf="loadInSidenav === &apos;FEEDBACK_FORM_APPOINTMENTS_ACTIVITY&apos;"></bh-feedback_form_appointment>
  <bh-form_add_role *ngIf="loadInSidenav === &apos;FORM_ADD_ROLE&apos;"></bh-form_add_role>
  <bh-form_add_user *ngIf="loadInSidenav === &apos;FORM_ADD_USER&apos;"></bh-form_add_user>
  <bh-setting_feedbackoutcomes *ngIf="loadInSidenav === &apos;SETTING_FEEDBACK_OUTCOMES&apos;"></bh-setting_feedbackoutcomes>
  <bh-form_udf_field *ngIf="loadInSidenav === Page.UDFForm"></bh-form_udf_field>
  <bh-form_udf_dependency *ngIf="loadInSidenav === Page.UDFDepForm"></bh-form_udf_dependency>
  <bh-form_contact_reassign *ngIf="loadInSidenav === &apos;FORM_CONTACT_REASSIGN&apos;"></bh-form_contact_reassign>
  <bh-adddocument *ngIf="loadInSidenav === &apos;ADD_DOCUMENT&apos;"></bh-adddocument>
  <bh-form_theme_logo *ngIf="loadInSidenav === &apos;UPD_THEME&apos;"></bh-form_theme_logo>
  <bh-importcontacts *ngIf="loadInSidenav === &apos;IMPORT_CONTACT&apos;"></bh-importcontacts>
  <bh-assigncontacts *ngIf="loadInSidenav === &apos;ASSIGN_CONTACT&apos;"></bh-assigncontacts>
  <bh-setting_phone_country_code *ngIf="loadInSidenav === &apos;SETTING_PHONE_COUNTRY&apos;"></bh-setting_phone_country_code>
  <bh-setting_curr *ngIf="loadInSidenav === &apos;SETTING_CURRENCY&apos;"></bh-setting_curr>
  <bh-module_rename *ngIf="loadInSidenav === &apos;SETTING_MODULE_RENAME&apos;"></bh-module_rename>
  <bh-setting_dealpipeline *ngIf="loadInSidenav === &apos;SETTING_DEAL_PIPE&apos;"></bh-setting_dealpipeline>
  <bh-form_contact_label *ngIf="loadInSidenav === &apos;CONTACT_LABEL&apos;"></bh-form_contact_label>
  <bh-form_contact_filter *ngIf="loadInSidenav === &apos;CONTACT_FILTER&apos;"></bh-form_contact_filter>
  <bh-form_team_contact_filter *ngIf="loadInSidenav === &apos;CONTACT_TEAM_FILTER&apos;"></bh-form_team_contact_filter>
  <bh-setting_vcall_msg *ngIf="loadInSidenav === &apos;SETTING_VOICE_CALL_REC&apos;  "></bh-setting_vcall_msg>
  <bh-emailtrail *ngIf="loadInSidenav === &apos;EMAIL_TRAIL&apos;" [email]="emailData"></bh-emailtrail>
  <bh-newemail *ngIf="loadInSidenav === &apos;NEW_EMAIL&apos;" [emailData]="emailData"></bh-newemail>
</mat-sidenav>
<mat-sidenav-container fxFill [ngClass.xs]="&apos;static-position&apos;" [ngClass.gt-xs]="&apos;back-drop-view-gt-xs&apos;" [hasBackdrop]="hasBackDrop" class="toolbar-left-align" (backdropClick)="(activeMediaQuery == &apos;xs&apos;) ? sidenavMenu.toggle() : &apos;&apos;">
  <bh-loader class="loader" [showLoader]="showLoader"></bh-loader>
  <mat-sidenav #sidenav [ngClass.xs]="&apos;sidenav-md&apos;" #sidenavMenu class="desktop-sidenav" [mode] [position]="&apos;start&apos;" [opened]="opened" disableClose="true" fixedInViewport="true">
    <bh-blmenu [MenuList]="sidenavMenu" (out)="updatelog($event)" #menu (menuTrigger)="sidenavToggle()" [sideNavOpened]="sidenavform.opened"></bh-blmenu>
  </mat-sidenav>
  <div fxLayout="column wrap" class="common-landing-element" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;left: 0;padding: 0px;top: 3.5em;height: calc(100% - 60px);&apos;" [ngStyle.gt-xs]="&apos;padding: 1em 0em 1em 6em;&apos;">
    <div fxLayout="column " class="common-landing" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;border-radius: 0px;&apos;" [ngClass.xs]="&apos;left-content-xs&apos;">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="&apos;mobile-footer&apos;">
    <bh-blfooter></bh-blfooter>
  </div>
</mat-sidenav-container>