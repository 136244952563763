/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { cacheservice } from 'app/sd-services/cacheservice';
import { roleservice } from 'app/sd-services/roleservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { PageEvtData, Role } from '../settinguserandrolesComponent/settinguserandroles.component';
import { SortChangeEvent, SortOrder } from '../sortComponent/sort.component';
import { Header } from '../usersComponent/users.component';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-roles',
	templateUrl: './roles.template.html'
})
export class rolesComponent extends NBaseComponent implements OnInit, OnDestroy {

	progressCheck = false;
	@Output() pageData = new EventEmitter<{ pageSize: number, pageOffset: number, totalCount: number }>();
	headers: Header[] = [
		{ displayText: 'Role Name', sort: true, sortKey: 'role_name', sortValue: 'ASC' },
		{ displayText: 'No. of Users' },
		{ displayText: 'Actions' }
	]
    currentSortKey = 'role_name';
	roles: Role[] = [];
	private _sortStatesInPriorityOrder: { sortKey: string, sortValue: SortOrder }[] = [];
	private _evtKey = 'PAGE_EVENT_SETTING_ROLES';
	private _formKey = 'FORM_ADD_ROLE';
	private _roleSub: Subscription;
	private _curPageSize = 50;
	private _curPageOffset = 0;

	constructor(private _pubsub: NPubSubService,
		private _cahces: cacheservice,
		private _roles: roleservice) {
		super();
	}

	async ngOnInit() {
		this._sortStatesInPriorityOrder = this.headers.filter(v => v.sort).map((v) => ({ sortKey: v.sortKey, sortValue: v.sortValue }));
		this._roleSub = this._pubsub.$sub(this._evtKey, (d: { evt: 'refresh-list', evtdata: PageEvtData }) => {
			if (d.evt === 'refresh-list') {
				this._refreshRolesList(d.evtdata)
			}
		});
	}

	private async _refreshRolesList(d: PageEvtData = {}) {
		this._curPageSize = d.pageSize ?? this._curPageSize;
		this._curPageOffset = d.pageOffset ?? this._curPageOffset;
		this.roles = (await this._roles.getRoles(this._curPageSize, this._curPageOffset, null, this._sortStatesInPriorityOrder)).local.res || [];
		this._emitTotalCount(this.roles[0]?.count || '0');
	}

	_emitTotalCount(n: string) {
		this.pageData.next({
			pageSize: this._curPageSize,
			pageOffset: this._curPageOffset,
			totalCount: parseInt(n),
		});
	}

	async openEditRoleForm(role: any) {
		await this._cahces.setc(role, this._formKey);
		this._pubsub.$pub('sidenavformevents', {
			ACTION: 'open',
			PAGE: this._formKey
		});
	}

	sortChange(e: SortChangeEvent) {
        this.currentSortKey = e.key;
		this.prioritizeFilter(e);
		this._refreshRolesList()
	}

	prioritizeFilter(v: SortChangeEvent) {
		const state = this._sortStatesInPriorityOrder.find(s => s.sortKey === v.key);
		state.sortValue = v.sort;
		this._sortStatesInPriorityOrder = this._sortStatesInPriorityOrder.filter(s => s !== state);
		this._sortStatesInPriorityOrder.unshift(state);
	}

	trackByRoleId(index: number, role: Role) {
		role.roleid;
	}

	_clearCache() {
		this._cahces.deletec(this._formKey);
	}

	ngOnDestroy() {
		this._roleSub.unsubscribe();
		this._clearCache();
	}
}


