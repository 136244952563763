<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" style="order: -1;" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/assign_user_black.svg" class="add-contact-icon">
          <h2>
            ASSIGN CONTACTS</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="sample-csv" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          Assign imported contacts by selecting users and number of contacts</div>
      </div>
      <form NgForm fxLayout="column" fxFlex="calc(100% - 64px)" class="add-lead-content">
        <div fxLayout="column wrap" fxFlex="calc(100%-px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="column wrap" class="padding-right-5" fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
              <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
                <input matInput placeholder="10,000" value="{{totalAvailableContacts}}" [required]="true" trim="blur" readonly="true">
                <mat-label>Total available contacts</mat-label>
              </mat-form-field>
            </div>
            <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
              <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
                <input matInput placeholder="5,000" value="{{totalContactsSelected}}" [required]="true" trim="blur" readonly="true">
                <mat-label>Contacts selected for assignment</mat-label>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row wrap" class="user-total-row" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let recordGroup of  contactShare.controls; let indx = index; ">
              <div fxLayout="column wrap" class="padding-right-5" fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <bh-dynusersearch [inputFormControl]="recordGroup.get(&apos;userid&apos;)" [placeholder]="&apos;Search Users..&apos;" [label]="User" (resultSelected)="selected($event)" [onlyHeirarchy]="&apos;true&apos;"></bh-dynusersearch>
              </div>
              <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
                  <input matInput placeholder="Enter number" [required]="true" trim="blur" [formControl]="recordGroup.get(&apos;totalContacts&apos;)">
                  <mat-label>Number of contacts to be assigned</mat-label>
                </mat-form-field>
              </div>
              <div (click)="removeRecord(indx)" class="add-row-button">
                <div class="circle-remove">
                  <div class="horizontal-plus"></div>
                </div>
              </div>
            </div>
            <mat-error align *ngIf="formErrorMessage.length &gt; 0">{{formErrorMessage}}</mat-error>
            <div (click)="addRecord()" class="add-row-button">
              <div class="circle">
                <div class="horizontal-plus"></div>
                <div class="vertical-plus"></div>
              </div><span class="add-text">Add</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div fxLayout="column wrap" class="buttons-pad" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="assignTOContacts()" [disabled]="disableAddButton || contactShare.controls.length == 0 || contactShare.invalid">Add</button></div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-action-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </div>
</div>