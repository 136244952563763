/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { importcontactservice } from 'app/sd-services/importcontactservice';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-importcontacts',
    templateUrl: './importcontacts.template.html',
    styleUrls: ['./importcontacts.style.scss']
})

export class importcontactsComponent extends NBaseComponent implements OnInit {
    showPreFileImport = true;
    showPostFileImport = false;
    filename = 'File name.CSV';
    ssdURL = environment.properties.ssdURL;
    sampleDownloadLink = `${this.ssdURL}imports/sample/contact`;
    selectedFile: File;
    formErrorMessage = '';
    disableImportButton = false;
    constructor(
        private pubsub: NPubSubService,
        private importContactService: importcontactservice,
        private snackBar: MatSnackBar,
    ) {
        super();
    }

    ngOnInit() {

    }
    close(refresh?) {
        if (refresh) {
            this.pubsub.$pub('refreshimporthistory');
        }
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }
    onFileDropped(file: FileList) {

        if (file[0] && this.checkIfCSV(file[0].name)) {
            this.showPostFileImport = true;
            this.showPreFileImport = false;
            this.formErrorMessage = '';
            this.filename = file[0].name;
            this.selectedFile = file[0];
        } else {
            this.formErrorMessage = "Invalid File Format";
            this.filename = '';
            this.selectedFile = null;
        }
    }

    // Checking for extension is a better solution
    // rather than file type, because it's file type
    // property gets overridden if the file is last
    // opened in a Microsoft sheet app or perhaps
    // other app's do it too.
    checkIfCSV(filename) {
        if (typeof filename != 'string') {
            return false;
        }
        const i = filename.lastIndexOf('.');
        return filename.substr(i + 1, filename.length) == 'csv';
    }

    removeFile() {
        this.filename = null;
        this.showPostFileImport = false;
        this.showPreFileImport = true;
    }

    uploadFile() {
        this.disableImportButton = true;
        this.importContactService.uploadFile(this.selectedFile, this.filename).then((response) => {
            this.showPreFileImport = true;
            this.showPostFileImport = false;
            this.filename = null;
            this.selectedFile = null;
            this.snackbarMsg(response.local.response.message);
            this.close(true);
            this.disableImportButton = false;
        })
            .catch((err) => {
                this.snackbarMsg("File upload Error");
                console.log(err);
                this.disableImportButton = false;
            });
    }
    snackbarMsg(msg) {
        this.snackBar.open(msg, 'OK', {
            duration: 3000
        })
    }

    cancel() {
        this.close();
    }
}
