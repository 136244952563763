/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, ViewChild } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { mydealsComponent } from '../mydealsComponent/mydeals.component';
import { teamdealsComponent } from '../teamdealsComponent/teamdeals.component';
import { labelService } from 'app/services/label/label.service';
import { environment } from 'environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { cacheservice } from 'app/sd-services/cacheservice';
import { sortdailogService } from 'app/services/sortdailog/sortdailog.service';
import { dealservice } from 'app/sd-services/dealservice';

export interface Deal {
    contact_created_by: number;
    contact_display_name: string;
    contact_email_id: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_last_updated_at: string;
    contact_owner_id: number;
    contact_owner_name: string;
    contact_owner_phone_country_code: string;//"+91"
    contact_owner_phone_no: string; //"8107456717"
    contact_owner_username: string; //"paul.thomas@neutrinos.co"
    contact_phone_country_code: string; //"+91"
    contact_phone_no: string; //""
    contact_updated_at: string; //"2020-07-23T05:06:07.299Z"
    contactid: number; //139
    count: string; //"2"
    currencyid: number; // 1
    deal_created_at: string; // "2020-07-23T07:07:19.364Z"
    deal_created_by: number; // null
    deal_name: string // "a"
    deal_pipe_name: string; // "New"
    deal_pipeid: number; // 1
    deal_status: string; // null
    deal_udf: any; // null
    deal_updated_at: string; //"2020-07-23T07:07:19.364Z"
    deal_value: string; //"1"
    dealid: number; //138
    tenantid: number; //1
    udf_data: any;
}
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-deals',
    templateUrl: './deals.template.html'
})

export class dealsComponent extends NBaseComponent implements OnInit {
    pageSize = 50;
    pageOffset = 0;
    totalCount = 0;
    ready = false;
    selectedIndex = 0;
    sortCache = [];
    permissionsList;

    selectedDealsCount = 0;
    selectedDealsObj = {};
    allDealsSelected = false
    displayedDealsCountValue=0
    watcher: Subscription;
    activeMediaQuery = '';
    mydealList = [{ name: 'Name (A to Z)', key: 'deal_name', sort_type: 'ASC' }, { name: 'Name (Z to A)', key: 'deal_name', sort_type: 'DESC' },
    { name: 'Stage (A to Z)', key: 'deal_stage_name', sort_type: 'ASC' }, { name: 'Stage (Z to A)', key: 'deal_stage_name', sort_type: 'DESC' },
    { name: 'Last Updated Date (Newest First)', key: 'deal_updated_at', sort_type: 'DESC' }, { name: 'Last Updated Date (Oldest First)', key: 'deal_updated_at', sort_type: 'ASC' }];
    teamdealList = [{ name: 'Name (A to Z)', key: 'deal_name', sort_type: 'ASC' }, { name: 'Name (Z to A)', key: 'deal_name', sort_type: 'DESC' },
    { name: 'Contact Name (A to Z)', key: 'contact_display_name', sort_type: 'ASC' }, { name: 'Contact Name  (Z to A)', key: 'contact_display_name', sort_type: 'DESC' },
    { name: 'Deal Value (Max to Min)', key: 'deal_value', sort_type: 'ASC' }, { name: 'Deal Value (Min to Max)', key: 'deal_value', sort_type: 'DESC' },
    { name: 'Stage (A to Z)', key: 'deal_pipe_name', sort_type: 'ASC' }, { name: 'Stage (Z to A)', key: 'deal_pipe_name', sort_type: 'DESC' },
    { name: 'Contact Owner (A to Z)', key: 'contact_owner_name', sort_type: 'ASC' }, { name: 'Contact Owner (Z to A)', key: 'contact_owner_name', sort_type: 'DESC' },
    { name: 'Last Updated Date (Newest First)', key: 'deal_updated_at', sort_type: 'DESC' }, { name: 'Last Updated Date (Oldest First)', key: 'deal_updated_at', sort_type: 'ASC' }];

    @ViewChild(mydealsComponent) mydeals: mydealsComponent;
    @ViewChild(teamdealsComponent) teamdeals: teamdealsComponent;

    constructor(private pubsub: NPubSubService,
        public label: labelService,
        private ngxPermissionsService: NgxPermissionsService,
        private mediaObserver: MediaObserver,
        private cacheService: cacheservice,
        private dealService: dealservice,
        private sortdialogservice: sortdailogService) {
        super();
        this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;
            this.pageOffset = 0;
            this.pageSize = 50;
            this.setSortChache(this.pageSize, this.pageOffset);
        });
    }
    async ngOnInit() {
        this.pubsub.$pub('actionlist', { list: 'deals' });
        this.setSortChache(this.pageSize, this.pageOffset);
        this.ngxPermissionsService.permissions$.subscribe((permissions) => {
            this.permissionsList = permissions;
        })

        this.pubsub.$sub('allDealsSelected', (res) => {
            if (res.allDealsSelected == 'true') {
                this.allDealsSelected = true
                localStorage.setItem('allDealsSelected', 'true');
            } else if (res.allContactsSelected == 'false') {
                this.allDealsSelected = false
                localStorage.setItem('allDealsSelected', 'false');
            }
        })
    }

    paginate($event){
        this.pageEvents($event);
    }

    pageEvents($event) {
        this.pageSize = $event.pageSize;
        this.pageOffset = $event.pageIndex;
        this.refresh(this.pageSize, this.pageOffset);
        this.setSortChache(this.pageSize, this.pageOffset);
    }

    /**
     * 
     * @param c template reference of the component
     */
    totalCountEvent(c: mydealsComponent | teamdealsComponent, event) {
        if (event == false && this.selectedIndex == 0) {
            this.ready = event;
        }
        if (!this.ready) {
            this.ready = true;
            this.totalCount = c.totalDeals;
        }
    }

    async tabChange(index) {
        if (this.sortCache[index]) {
            this.pageSize = this.sortCache[index].pageSize;
            this.pageOffset = this.sortCache[index].pageOffset;
        }
        this.refresh(this.pageSize, this.pageOffset);
        this.selectedDealsCount = 0 ;
	    this.selectedDealsObj = {};
    }

    refresh(pageSize, pageOffset) {
        this.ready = false;
        this.selectedDealsCount = 0;
        this.selectedDealsObj = {};
        if (this.selectedIndex == 0) {
            this.mydeals.totalDeals = 0;
            this.mydeals.mydeals = [];
            let sortObj = {};
            sortObj['name'] = "my_deals_sort";
            sortObj['sortlist'] = this.mydealList;
            this.sortdialogservice.setSortObj(sortObj);
            this.mydeals.getMyDeals(pageSize, pageOffset);
        }

        if (this.selectedIndex == 1) {
            this.teamdeals.totalDeals = 0;
            this.teamdeals.mydeals = [];
            let sortObj = {};
            sortObj['name'] = "team_deals_sort";
            sortObj['sortlist'] = this.teamdealList;
            this.sortdialogservice.setSortObj(sortObj);
            this.teamdeals.getTeamDeals(pageSize, pageOffset);
        }
    }

    selectedDeals(event) {
        this.selectedDealsCount = event.count;
        this.selectedDealsObj = event.obj;
        if(this.selectedDealsCount!=this.pageSize){
            this.allDealsSelected=false;
            localStorage.setItem('allDealsSelected','false');
            localStorage.setItem('usersDealChoiceSelectAll','false')
        }
    }

    setSortChache(pageSize, pageOffset) {
        let sortObj = {};
        sortObj['name'] = "my_deals_sort";
        sortObj['sortlist'] = this.mydealList;
        this.sortdialogservice.setSortObj(sortObj);
        this.sortCache[this.selectedIndex] = {
            pageSize,
            pageOffset
        }
    }

    selectAllDeals(){        
        this.allDealsSelected=true;
        localStorage.setItem('allDealsSelected','true');
        localStorage.setItem('usersDealChoiceSelectAll','true')
    }

    displayedDealsCount(event){
        this.displayedDealsCountValue=event.value
    }

    deselectAllSelectedDeals(){
        this.allDealsSelected=false;
        localStorage.setItem('allDealsSelected','false');
        localStorage.setItem('usersDealChoiceSelectAll','false')
        this.mydeals.resetSelectedDeals()
    }



    downloadDealasCSV() {
        let dealsIds = []
        for (var c in this.selectedDealsObj) {
            dealsIds.push(this.selectedDealsObj[c].dealid)
        }
        if (this.allDealsSelected)
            dealsIds = []
        this.dealService.exportDeals(dealsIds).then(r => {
            var data = r.local.result;
            let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
            let dwldLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", 'deals' + ".csv");
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        })
    }
}
