import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appFileDragDrop]'
})
export class FileDragDropDirective {

  @Output() fileDropped = new EventEmitter<any>();
  constructor() { }  
  @HostListener('drop',['$event']) ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    this.fileDropped.emit(files);
    console.log('files dragged : ',files);
  }
  @HostListener('mouseenter') onMouseEnter() {
    console.log('mouse enter event : ');
    
  }
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
}
