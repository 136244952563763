import { AbstractControl, ValidationErrors, ValidatorFn, Validators, FormGroup } from '@angular/forms';
import { UtilityMethods } from './UtilityMethods';

export class CustomValidators {

	static timeInputValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			let result = UtilityMethods.validateAMPMTimeString(control.value);
			return result === true ? null : { invalidValue: 'invalid time format' };
		};
	}

	/**
	 * @description checks if the `control`'s value is empty-ish. For now, empty-ish is
	 * - Any empty string
	 * - Any string with only `space`s
	 */
	static emptyish(): ValidatorFn {
		// TODO: @sankarshanaj - 
		// 1. Accept "emptyish" charectes array as parameter
		// 2. In the value of the control, replace those chars with empty string
		// 3. Check the kenght and resturn `null` or `{ emptyish: true }` accordingly
		return (control: AbstractControl): ValidationErrors | null => {
			return (typeof control.value !== 'string') || (control.value && control.value.trim().length) ?
				null : { emptyish: true };
		}
	}


	/**
	 * @description checks if one of the `controls` satisfies the `required` constraint
	 * @param controls 
	 */
	static oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			for (const aControl of controls) {
				if (!Validators.required(aControl)) {
					return null;
				}
			}
			return { oneOfRequired: true };
		};
	}
	static phoneNumberAndCountryCode(phoneCountryCodeControlName: string,
		phoneNumberConroleName: string): ValidatorFn {
		return (control: FormGroup): { [key: string]: any } | null => {
			if (control.get(phoneNumberConroleName).value && control.get(phoneNumberConroleName).value.toString().length > 0 &&
				control.get(phoneCountryCodeControlName).value.length === 0
			) {
				return { phoneNumberError: true };
			}
			return null;
		}
	}
	static atleastNTruthy(n: number, controls: AbstractControl[], ): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			let count = 0;
			for (let i = 0; i < controls.length; i++) {
				const control = controls[i];
				if (control.value) count++;
				if (count === n) return null;
			}
			return { atleastOneTruthy: true };
		}
	}
}