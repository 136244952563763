/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CacheKey, Page } from 'app/constants';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { usernotificationservice } from 'app/sd-services/usernotificationservice';
import { ConfirmOpts, dialogService } from 'app/services/dialog/dialog.service';
import { udfsyncService } from 'app/services/udfsync/udfsync.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-udf_field',
	templateUrl: './udf_field.template.html'
})

export class udf_fieldComponent extends NBaseComponent implements OnInit, OnDestroy {

	@Input('udfsConfig') config: UDFConfig[] = <UDFConfig[]>[];
	@Input('disableActions') disableActions = false;

	@Output('fieldsChanged') fieldsChanged = new EventEmitter();

	@ViewChild('iconRef') iconRef: ElementRef;

	public typeUrlMap: Record<string, string> = {};

	constructor(private _ds: dialogService,
		private _udfSync: udfsyncService,
		private _usernotification: usernotificationservice,
		private _ps: NPubSubService) {
		super();
	}

	ngOnInit() {
		// const baseUri = 'assets/Web/Icons/';
		// this.typeUrlMap = this._scache.getc('field_ui_types')?.reduce((o, type) => {
		// 	o[type] = `${baseUri}udf-field-type-${type}.svg`;
		// 	return o;
		// }, {});
	}

	emitFieldsChanged(action: ActionOnUDF, ufc: UDFConfig, data?: any) {
		const d: UDFSectionFieldChangedEventData = {
			action: action,
			ufc: ufc,
			data: data
		}
		this.fieldsChanged.next(d);
	}

	udfDrop($event: CdkDragDrop<UDFConfig[]>) {
		if (!this.disableActions) {
			const fromIdx = $event.previousIndex;
			const toIdx = $event.currentIndex;
			if (!(this.config[fromIdx].default || this.config[toIdx].default) &&
				(fromIdx !== toIdx)) {
				moveItemInArray(this.config, fromIdx, toIdx);
				this.emitFieldsChanged('moved', this.config[toIdx], {
					fromIdx,
					toIdx
				});
			}
		}
	}

	deleteField(udf: UDFConfig, i: number, e: MouseEvent) {
		if (!this.disableActions) {
			e.stopImmediatePropagation();
			if (this.config.length === 1) {
				this._usernotification.openSnackbar('Cannot delete field. Section should have atleast one field in it.')
			} else {
				const opts: ConfirmOpts = {
					header: `Delete ${udf.field_placeholder} Field`,
					subtitle: `Deleting the field will delete all existing field data and corresponding dependency configuration`,
					dangerWarning: 'Confirm Delete?'
				};
				this._ds.openConfirmationDialog(opts).subscribe((v) => {
					if (v && v.option === 'yes') {
						const deleted = JSON.parse(JSON.stringify(this.config[i]));
						U.deleteAtIdx(this.config, i);
						this.emitFieldsChanged('delete', deleted);
					}
				});
			}
		}
	}

	openUDFEditForm(udf: UDFConfig) {
		if (!udf.default && !this.disableActions) {
			this._udfSync.setc(U.JSONClone(udf), CacheKey.udfEditForm);
			this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
				ACTION: 'open',
				PAGE: Page.UDFForm
			});
		}
	}

	get disableLooking() {
		return {
			'disabled-appearance': this.disableActions,
			'cursor-pointer': !this.disableActions
		}
	}

	get dragHandleDisableLooking() {
		return {
			'disabled-appearance': this.disableActions,
			'cursor-move': !this.disableActions
		}
	}

	ngOnDestroy() {
		this._udfSync.deletec(CacheKey.udfEditForm);
	}


}


type ActionOnUDF = 'add' | 'update' | 'delete' | 'moved';
export interface UDFSectionFieldChangedEventData {
	action: ActionOnUDF,
	data?: any,
	ufc?: UDFConfig
}

export interface UDFConfig {
	field_name: string,
	field_placeholder: string,
	field_section: string,
	field_ui_type: string,
	field_is_required: boolean,
	// field_section_name: string,
	field_sort_order: number,
	field_options?: string[],
	field_value?: string,
	dependency_field_name: string,
	dependency_field_value: any,
	dependency_comparator: DependencyOperator,
	update_by_dependency_as: DependentFieldState,
	field_country_code_field?: string,
	field_country_code_value?: string
	default?: boolean
}

export interface DependencyConfig {
	dependency_field_name: string,
	dependency_field_value: any,
	dependency_comparator: DependencyOperator,
	update_by_dependency_as: DependentFieldState,
}

export type DependencyOperator = 'eq' | 'neq';
export type DependentFieldState = 'hidden' | 'required';