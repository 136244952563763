/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { genericService } from 'app/services/generic/generic.service';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-timeline',
    templateUrl: './timeline.template.html'
})

export class timelineComponent extends NBaseComponent implements OnInit {

    @Input('activityArr') activityArr = [];
    @Input('showViewOlderBtn') showViewOlderBtn;
    @Input('component') component;
    @Input('audioFilepath') audioFilepath;
    @Input('videoFilepath') videoFilepath;
    @Output() viewOldData = new EventEmitter();

    constructor(  private cacheService: cacheservice,private pubsub: NPubSubService, private generic : genericService) {
         
        super();
       
    }

    ngOnInit() {

    }

    viewOlder() {
        this.viewOldData.emit({});
    } 

    async viewTaskandAppointmentDetails(value: any) {
        if (value['activitytaskid']) {
            // TODO: No need to construct this object. Directly send the `value` for the form
            var tempObj = {
                title: value['task_title'],
                description: value['task_description'],
                duedate: new Date(value['due_date']),
                duetime: this.hourwithAMPM(_moment(value['due_date'])),
                completed: (value['task_status'] == true) ? 'Yes' : 'No',
            }
            let taskObj = {
                taskEventObj: tempObj,
                taskIdObj: value
            };
            await this.cacheService.setc(taskObj, 'EDIT_TASK_FORM');
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'ADD_TASK'
            })
        }

        if (value['activityeventid']) {
            var appointmentEventObj = {
                appointment_feedback_id: value['appointment_feedback_id'],
                contactText: "",
                dealText: "",
                end_date: value['end_time'],
                end_time: this.hourwithAMPM(_moment(value['end_time'])),
                event_description: value['event_description'],
                event_location: value['event_location'],
                event_title: value['event_title'],
                feedback_notes: value['feedback_notes'],
                location: "",
                meeting_url: "",
                room_id: value['room_id'],
                start_date: value['start_time'],
                start_time: this.hourwithAMPM(_moment(value['start_time']))
            }
            let appointmentObj = {
                appointmentEventObj: appointmentEventObj,
                appointmentIdObj: value
            };
            this.generic.fromappView = true;
            await this.cacheService.setc(appointmentObj, 'EDIT_APPOINTMENT_FORM');

            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'SCHEDULE_APPOINTMENT'
            })
        }

    }
    
    hourwithAMPM(inputDate: Moment) {
        let d = _moment();
        if (inputDate) {
            d = _moment(inputDate);
        }
        let ampm = (d.hour() >= 12) ? "pm" : "am";
        let hours = (d.hour() > 12) ? d.hour() - 12 : d.hour();
        return (hours < 10 ? '0' + hours.toString() : hours) + ':' + (d.minute() < 10 ? '0' + d.minute() : d.minute()) + ampm;
    }
    getTime(date: Date) {
        return this.hourwithAMPM(_moment(date));
    }
}
