/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class leadservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_leadservice

  async getAllLeads(
    pageSize: any = undefined,
    pageOffset: any = undefined,
    userid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          pageSize: pageSize,
          pageOffset: pageOffset,
          userid: userid
        },
        local: {
          leadsData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_AgBLvonkWEoJ54ys(bh);
      //appendnew_next_getAllLeads
      return (
        // formatting output variables
        {
          input: {},
          local: {
            leadsData: bh.local.leadsData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EOVmlCVe8bsXIJ4S');
    }
  }

  async getLeadById(leadid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          leadid: leadid
        },
        local: {
          leadsData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_HJhKtsM3S6bV4rip(bh);
      //appendnew_next_getLeadById
      return (
        // formatting output variables
        {
          input: {},
          local: {
            leadsData: bh.local.leadsData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_f2feZ9awhRJdDQno');
    }
  }

  async getTeamLeads(
    managerid: any = undefined,
    pageSize: any = undefined,
    pageOffset: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          managerid: managerid,
          pageSize: pageSize,
          pageOffset: pageOffset
        },
        local: {
          leadsData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_RXXjEQ5HLFwbr5DB(bh);
      //appendnew_next_getTeamLeads
      return (
        // formatting output variables
        {
          input: {},
          local: {
            leadsData: bh.local.leadsData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_RPD92c8Z07Cix56J');
    }
  }

  async createLead(
    lead_owner: any = undefined,
    name: any = undefined,
    phone_no: any = undefined,
    phone_country_code: any = undefined,
    email_id: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          lead_owner: lead_owner,
          name: name,
          phone_no: phone_no,
          phone_country_code: phone_country_code,
          email_id: email_id
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_xEe1MoXeP2zKeU05(bh);
      //appendnew_next_createLead
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BY3xdujNlMEkIH2f');
    }
  }

  async addNotes(leadid: any = undefined, notes: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          notes: notes
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_mmsz94zrIKVfLluV(bh);
      //appendnew_next_addNotes
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_H5fstf4O8Vm92EkH');
    }
  }

  async getAllNotes(leadid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          leadid: leadid
        },
        local: {
          notesData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_6P48gFJO9JnOxzTP(bh);
      //appendnew_next_getAllNotes
      return (
        // formatting output variables
        {
          input: {},
          local: {
            notesData: bh.local.notesData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_WETIAAvRq9odq21c');
    }
  }

  async deleteNotes(
    leadid: any = undefined,
    notesid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          notesid: notesid
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_jLuwKmKWuUq5dNUw(bh);
      //appendnew_next_deleteNotes
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_pFKetWN3C3cf51kw');
    }
  }

  async updateNotes(
    leadid: any = undefined,
    notesid: any = undefined,
    notes: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          notesid: notesid,
          notes: notes
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_Juw5HQWoa2EvFKnY(bh);
      //appendnew_next_updateNotes
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HMQSLJ8TIMXhsD0t');
    }
  }

  async updateLeadPipe(
    leadid: any = undefined,
    pipeid: any = undefined,
    currentPipeName: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          pipeid: pipeid,
          currentPipeName: currentPipeName
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_BKOZobggdQGgMkXt(bh);
      //appendnew_next_updateLeadPipe
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mp3LblZ8PXKoXuBl');
    }
  }

  async updateLeadStatus(
    leadid: any = undefined,
    lead_status: any = undefined,
    existing_lead_status: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          lead_status: lead_status,
          existing_lead_status: existing_lead_status
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_PnvUidjKjTf0eSf5(bh);
      //appendnew_next_updateLeadStatus
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_7bQPY8Q0KODqx2c8');
    }
  }

  async updateLeadUDF(
    leadid: any = undefined,
    udf: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          udf: udf
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_qvm4fe5PEwIKBr4e(bh);
      //appendnew_next_updateLeadUDF
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_506dDyQo0xCeRohZ');
    }
  }

  async makeLeadCall(
    leadid: any = undefined,
    phonenumber: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          phonenumber: phonenumber
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_BLgGtCZxtuILMkHs(bh);
      //appendnew_next_makeLeadCall
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_WP69bpIDda7rZjSi');
    }
  }

  async checkPhoneNumber(leadid: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          leadid: leadid
        },
        local: {
          phoneValue: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_p0rvRksxuK5rocR6(bh);
      //appendnew_next_checkPhoneNumber
      return (
        // formatting output variables
        {
          input: {},
          local: {
            phoneValue: bh.local.phoneValue
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_AvHriHg61s17Yq4y');
    }
  }

  async updateLeadDetails(
    leadid: any = undefined,
    name: any = undefined,
    phone_no: any = undefined,
    phone_country_code: any = undefined,
    email_id: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          leadid: leadid,
          name: name,
          phone_no: phone_no,
          phone_country_code: phone_country_code,
          email_id: email_id
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_FVWUMIXrDTDBjLL2(bh);
      //appendnew_next_updateLeadDetails
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_XT3iqn6N2KZQsJzw');
    }
  }

  async getAppointments(...others) {
    try {
      var bh = {
        input: {},
        local: {
          appointmentData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_nPSsdinp9T6WPTQY(bh);
      //appendnew_next_getAppointments
      return (
        // formatting output variables
        {
          input: {},
          local: {
            appointmentData: bh.local.appointmentData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_XNKmtetePGx2gNWW');
    }
  }

  //appendnew_flow_leadservice_Start

  async sd_AgBLvonkWEoJ54ys(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'leads?';

      if (bh.input.pageSize) {
        bh.url += 'pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }

      if (bh.input.userid) {
        bh.url += '&userid=' + bh.input.userid;
      }
      bh = await this.sd_BCUNrbnCnOeHsS6j(bh);
      //appendnew_next_sd_AgBLvonkWEoJ54ys
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_AgBLvonkWEoJ54ys');
    }
  }

  async sd_BCUNrbnCnOeHsS6j(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.leadsData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_BCUNrbnCnOeHsS6j
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BCUNrbnCnOeHsS6j');
    }
  }

  async sd_HJhKtsM3S6bV4rip(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'lead/' + bh.input.leadid;
      bh = await this.sd_c7EYy1mOC8HfQt30(bh);
      //appendnew_next_sd_HJhKtsM3S6bV4rip
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HJhKtsM3S6bV4rip');
    }
  }

  async sd_c7EYy1mOC8HfQt30(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.leadsData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_c7EYy1mOC8HfQt30
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_c7EYy1mOC8HfQt30');
    }
  }

  async sd_RXXjEQ5HLFwbr5DB(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'teamleads/' +
        bh.input.managerid +
        '?';

      // bh.url = bh.system.environment.properties.ssdURL + 'leads?';

      if (bh.input.pageSize) {
        bh.url += 'pageSize=' + bh.input.pageSize;
      }

      if (bh.input.pageOffset) {
        bh.url += '&pageOffset=' + bh.input.pageOffset;
      }
      bh = await this.sd_KISKBQInujfIAUGg(bh);
      //appendnew_next_sd_RXXjEQ5HLFwbr5DB
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_RXXjEQ5HLFwbr5DB');
    }
  }

  async sd_KISKBQInujfIAUGg(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.leadsData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_KISKBQInujfIAUGg
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_KISKBQInujfIAUGg');
    }
  }

  async sd_xEe1MoXeP2zKeU05(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'lead';

      bh.body = {
        name: bh.input.name,
        phone_no: bh.input.phone_no,
        phone_country_code: bh.input.phone_country_code,
        lead_owner: bh.input.lead_owner,
        email_id: bh.input.email_id
      };
      bh = await this.sd_CEPrSykkKPGDQSp2(bh);
      //appendnew_next_sd_xEe1MoXeP2zKeU05
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_xEe1MoXeP2zKeU05');
    }
  }

  async sd_CEPrSykkKPGDQSp2(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_CEPrSykkKPGDQSp2
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_CEPrSykkKPGDQSp2');
    }
  }

  async sd_mmsz94zrIKVfLluV(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'note/' + bh.input.leadid;
      bh.body = {
        notes: bh.input.notes
      };
      bh = await this.sd_MZNEICegTN7OUICS(bh);
      //appendnew_next_sd_mmsz94zrIKVfLluV
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_mmsz94zrIKVfLluV');
    }
  }

  async sd_MZNEICegTN7OUICS(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_MZNEICegTN7OUICS
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MZNEICegTN7OUICS');
    }
  }

  async sd_6P48gFJO9JnOxzTP(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'notes/' + bh.input.leadid;

      bh = await this.sd_jKkm1ccxkg7I3NQj(bh);
      //appendnew_next_sd_6P48gFJO9JnOxzTP
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_6P48gFJO9JnOxzTP');
    }
  }

  async sd_jKkm1ccxkg7I3NQj(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.notesData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_jKkm1ccxkg7I3NQj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jKkm1ccxkg7I3NQj');
    }
  }

  async sd_jLuwKmKWuUq5dNUw(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'note/' +
        bh.input.leadid +
        '/' +
        bh.input.notesid;

      bh = await this.sd_0MN37hG8kEYg9Zp2(bh);
      //appendnew_next_sd_jLuwKmKWuUq5dNUw
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jLuwKmKWuUq5dNUw');
    }
  }

  async sd_0MN37hG8kEYg9Zp2(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_0MN37hG8kEYg9Zp2
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0MN37hG8kEYg9Zp2');
    }
  }

  async sd_Juw5HQWoa2EvFKnY(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'note/' +
        bh.input.leadid +
        '/' +
        bh.input.notesid;
      bh.body = {
        notes: bh.input.notes
      };
      bh = await this.sd_BZKJBKGIkH4nDH96(bh);
      //appendnew_next_sd_Juw5HQWoa2EvFKnY
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Juw5HQWoa2EvFKnY');
    }
  }

  async sd_BZKJBKGIkH4nDH96(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_BZKJBKGIkH4nDH96
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BZKJBKGIkH4nDH96');
    }
  }

  async sd_BKOZobggdQGgMkXt(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'lead/' +
        bh.input.leadid +
        '?currentPipeName=' +
        bh.input.currentPipeName;
      bh.body = {
        pipeid: bh.input.pipeid
      };
      bh = await this.sd_8724Vwm2McKOiPxT(bh);
      //appendnew_next_sd_BKOZobggdQGgMkXt
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BKOZobggdQGgMkXt');
    }
  }

  async sd_8724Vwm2McKOiPxT(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_8724Vwm2McKOiPxT
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_8724Vwm2McKOiPxT');
    }
  }

  async sd_PnvUidjKjTf0eSf5(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'lead/' +
        bh.input.leadid +
        '?existing_lead_status=' +
        bh.input.existing_lead_status;
      bh.body = {
        lead_status: bh.input.lead_status
      };
      bh = await this.sd_Wv226kAhHEBHmnYL(bh);
      //appendnew_next_sd_PnvUidjKjTf0eSf5
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_PnvUidjKjTf0eSf5');
    }
  }

  async sd_Wv226kAhHEBHmnYL(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_Wv226kAhHEBHmnYL
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Wv226kAhHEBHmnYL');
    }
  }

  async sd_qvm4fe5PEwIKBr4e(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'lead/' + bh.input.leadid;
      bh.body = {
        lead_udf: bh.input.udf
      };
      bh = await this.sd_nRAcBJY79dUuIuWE(bh);
      //appendnew_next_sd_qvm4fe5PEwIKBr4e
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_qvm4fe5PEwIKBr4e');
    }
  }

  async sd_nRAcBJY79dUuIuWE(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_nRAcBJY79dUuIuWE
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_nRAcBJY79dUuIuWE');
    }
  }

  async sd_BLgGtCZxtuILMkHs(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'call/lead/' +
        bh.input.leadid;
      bh.body = {
        phonenumber: bh.input.phonenumber
      };
      bh = await this.sd_HX8skBpNVqt1uUUf(bh);
      //appendnew_next_sd_BLgGtCZxtuILMkHs
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BLgGtCZxtuILMkHs');
    }
  }

  async sd_HX8skBpNVqt1uUUf(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_HX8skBpNVqt1uUUf
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HX8skBpNVqt1uUUf');
    }
  }

  async sd_p0rvRksxuK5rocR6(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'call/phonenumbers/' +
        bh.input.leadid;

      bh = await this.sd_SIHThhOlPHgSSsck(bh);
      //appendnew_next_sd_p0rvRksxuK5rocR6
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_p0rvRksxuK5rocR6');
    }
  }

  async sd_SIHThhOlPHgSSsck(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.phoneValue = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_SIHThhOlPHgSSsck
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_SIHThhOlPHgSSsck');
    }
  }

  async sd_FVWUMIXrDTDBjLL2(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'lead/' + bh.input.leadid;
      bh.body = {
        name: bh.input.name,
        email_id: bh.input.email_id,
        phone_no: bh.input.phone_no,
        phone_country_code: bh.input.phone_country_code
      };
      bh = await this.sd_RhXgx9iclAyAHqI6(bh);
      //appendnew_next_sd_FVWUMIXrDTDBjLL2
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_FVWUMIXrDTDBjLL2');
    }
  }

  async sd_RhXgx9iclAyAHqI6(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_RhXgx9iclAyAHqI6
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_RhXgx9iclAyAHqI6');
    }
  }

  async sd_nPSsdinp9T6WPTQY(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'appointments/1';

      bh = await this.sd_JfsvZH5i0GaxoqXY(bh);
      //appendnew_next_sd_nPSsdinp9T6WPTQY
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_nPSsdinp9T6WPTQY');
    }
  }

  async sd_JfsvZH5i0GaxoqXY(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.appointmentData = await this.sdService.nHttpRequest(
        requestOptions
      );
      //appendnew_next_sd_JfsvZH5i0GaxoqXY
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_JfsvZH5i0GaxoqXY');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_leadservice_Catch
}
