<div>
  <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " class="cursor-pointer settings-setting-item" fxLayoutGap="2rem" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div class="settings-setting-item-img-wrp">
        <div [style.mask]="&apos;url(assets/Web/Icons/&apos; + icon + &apos;) no-repeat center / contain&apos;" [style.-webkit-mask]="&apos;url(assets/Web/Icons/&apos; + icon + &apos;) no-repeat center / contain&apos;" class="settings-setting-item-img">
        </div>
      </div>
      <div>
        {{label}}</div>
    </div>
  </div>
</div>