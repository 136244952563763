<div fxLayout="column " class="height-100 common-lead-list-padding" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="users.length &gt; 0">
  <div fxLayout="row wrap" class="lead-list-header" fxFlex="48px" fxLayoutGap="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div fxLayout="row wrap" class="lr-padding" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;lead-header-field-gt-xs&apos;" *ngFor="let header of headers">
        <h3 class="color-view">
          {{header.displayText}}</h3>
        <bh-sort [sortKey]="header.sortKey" [sort]="header.sortValue" (changeEvent)="sortChange($event)" *ngIf="header.sort" [ngClass]="(header.sortKey != currentSortKey) ? &apos;not-sorting-icon&apos; : &apos;&apos;"></bh-sort>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" class="display-block border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let user of users; trackBy:trackByUserId">
      <div fxLayout="row wrap" class="color-black" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="user">
        <div fxLayout="row wrap" class="lr-padding height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <h3 class="font-normal-size text-ellipsis">
            <div *ngIf="user.display_name" class="text-ellipsis">{{user.display_name}}</div>
            <div *ngIf="!user.display_name" class="label-font-color text-ellipsis">-------</div>
          </h3>
        </div>
        <div fxLayout="row wrap" class="lr-padding height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <h3 class="font-normal-size text-ellipsis">
            <div *ngIf="user.email_id" class="text-ellipsis">{{user.email_id}}</div>
            <div *ngIf="!user.email_id" class="label-font-color text-ellipsis">-------</div>
          </h3>
        </div>
        <div fxLayout="row wrap" class="lr-padding height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <h3 class="font-normal-size text-ellipsis">
            <!-- {{!user.phone_no ? '' : ((user.phone_country_code || '') + ' ' + user.phone_no)}} -->
            <div *ngIf="user.phone_country_code || user.phone_no" class="text-ellipsis">{{user.phone_country_code}} {{user.phone_no}}</div>
            <div *ngIf="!user.phone_country_code &amp;&amp; !user.phone_no" class="label-font-color text-ellipsis">-------</div>
          </h3>
        </div>
        <div fxLayout="row wrap" class="lr-padding height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <h3 class="font-normal-size text-ellipsis">
            <div *ngIf="user.role_name" class="text-ellipsis">{{user.role_name}}</div>
            <div *ngIf="!user.role_name" class="label-font-color text-ellipsis">-------</div>
          </h3>
        </div>
        <div fxLayout="row wrap" class="lr-padding height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <h3 class="font-normal-size text-ellipsis">
            {{user.active_status ? &apos;Active&apos; : &apos;Inactive&apos;}}</h3>
        </div>
        <div fxLayout="row wrap" class="lr-padding height-gt-xs" fxFlex="16" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div (click)="openEditUserForm(user)" click-stop-propagation matTooltip="Edit User" class="cursor-pointer edit-role-image">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="column wrap" fxLayoutGap="1rem" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" fxFill *ngIf="users.length === 0"><img imgSrc="/Web/Icons/settings-no_users.svg">
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <h2 [ngClass]="(!progressCheck) ? &apos;icon-disable&apos; : &apos;&apos; " class="illustration-label">
      NO USERS</h2>
  </div>
</div>