/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { AppointmentFeedbackOutcome } from 'app/components/activities_appointmentsComponent/activities_appointments.component';
import { CallFeedbackOutcome } from 'app/components/activities_callsComponent/activities_calls.component';

@Injectable()
export class feedbackoutcomessyncService {
	appointment_feedback_outcomes: AppointmentFeedbackOutcome[] = [];
	call_feedback_outcomes: CallFeedbackOutcome[] = [];
	get outcomes() {
		return ({
			appointment_feedback_outcomes: this.appointment_feedback_outcomes,
			call_feedback_outcomes: this.call_feedback_outcomes
		});
	}
}
