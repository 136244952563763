<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill id="reassign-sidenav-form">
  <div fxLayout="column wrap" class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div class="theme-logo-form-icon">
          </div>
          <h2>
            Theme &amp; Logo</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="form-sections-title" fxFlex="44px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <h3>
      Maximum size for image upload is 25 MB</h3>
  </div>
  <div fxLayout="row wrap" class="display-block padding-1" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row" fxLayoutAlign="start start" class="upload-layout width-100">
      <div *ngIf="!file_name" class="height-100 padding-1-0" fxLayout="column" fxLayoutAlign="center center" fxFlex="100" fxLayoutGap="15px" fileDrag (files)="handleChange($event)">
        <div><img class="video-icon" src="assets/Web/Icons/logo_upload.svg"></div>
        <input #fileInput type="file" accept="image/*" hidden (change)="fileUpload($event.target.files)">
        <h3 mat-button class="primary-font-color cursor-pointer" (click)="fileInput.click()">Upload the image here</h3>
        <h3>or</h3>
        <h3>Drag the image</h3>
      </div>

      <div *ngIf="file_name" class="height-100 padding-1-0" fxLayout="column" fxLayoutAlign="center center" fxFlex="100" fxLayoutGap="10px">
        <div><img class="video-icon" src="assets/Web/Icons/logo_upload.svg"></div>
        <span class="padding-0-1-1-1" *ngIf="file">{{file_name}}</span>
        <div *ngIf="file" class="primary-font-color cursor-pointer" (click)="removeFile()">Remove</div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="lr-padding" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <mat-error align *ngIf="invalidImage">{{errorMsg}}</mat-error>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row" fxLayoutAlign="start start" class="cursor-pointer lr-padding">
      <!-- <div class="colorpicker" style="width: 23px; height: 22px; border: 2px solid black" [ngStyle] = "{'background-color':primaryColor !== '' ? primaryColor : 'var(--color-white)' }" (click)="openPicker()"></div>  -->
      <div class="colorpicker" style="width: 23px; height: 22px;" [style.background]="primaryColor" [(colorPicker)]="primaryColor" (colorPickerClose)="onEventLog(&apos;colorPickerClose&apos;, $event)">
        <!-- (colorPickerOpen)="onEventLog('colorPickerOpen', $event)" (colorPickerClose)="onEventLog('colorPickerClose', $event)" (cpInputChange)="onEventLog('cpInputChange', $event)" (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)" (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)" (click)="openPicker()" -->
      </div>
    </div>
    <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
      <input matInput [(ngModel)]="primaryColor" placeholder="Primary Color Code" [required]="true" [disabled]="true">

    </mat-form-field>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row" fxLayoutAlign="start start" class="cursor-pointer lr-padding">
      <!-- <div class="colorpicker" style="width: 23px; height: 22px; border: 2px solid black" [ngStyle] = "{'background-color':secondaryColor !== '' ? secondaryColor : 'var(--color-white)' }" (click)="openPicker()"></div>  -->
      <div class style="width: 23px; height: 22px;" [style.background]="secondaryColor" [(colorPicker)]="secondaryColor" (colorPickerClose)="onEventLog2(&apos;colorPickerClose&apos;, $event)">
        <!-- (colorPickerOpen)="onEventLog2('colorPickerOpen', $event)" (colorPickerClose)="onEventLog2('colorPickerClose', $event)" (cpInputChange)="onEventLog2('cpInputChange', $event)" (cpSliderDragStart)="onEventLog2('cpSliderDragStart', $event)" (cpSliderDragEnd)="onEventLog2('cpSliderDragEnd', $event)" (click)="openPicker()" -->
      </div>
    </div>
    <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
      <input matInput [(ngModel)]="secondaryColor" placeholder="Secondary Color Code" [required]="true" [disabled]="true">

    </mat-form-field>
  </div>
  <div fxLayout="column wrap" class="add-lead-content" fxFlex="calc(100% - 483px)" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="save()" [disabled]="disableButton">Save</button></div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="cancel-button color-view" (click)="close()" type="button">Cancel</button></div>
    </div>
  </div>
</div>