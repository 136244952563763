<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column " class="white-back sticky add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" style="order: -1;" class="add-contact-title lr-padding" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="90" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/microphone.svg" class="add-contact-icon">
          <h2>
            CONFIGURE VOICE CALL RECORDING MESSAGE</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="10" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="grey-title" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          Enter custom voice call recording message to be played to the client</div>
      </div>
      <form NgForm fxLayout="column" fxFlex="calc(100% - 64px)" class="add-lead-content">
        <div>
          <label class="phone-number-label">
            <div class="label-phone-code"><span class="place-holder-paddingr">
        Voice Call Recording message
    </span>

              <span class="info-icon" matTooltipClass="tooltip-width" matTooltip="The recording message will be played to the client informing them about the call being recorded prior to connecting the voice call." matTooltipPosition="right"><img imgSrc="Web/Icons/exclamationgrey.svg">
    <!-- <span class="tooltip">Search example: Singapore (+65)</span> -->
              </span>


            </div>

          </label>

          <mat-form-field floatLabel="never" class="width-100">
            <input matInput placeholder="Enter recording message" [formControl]="vcall_msg">
          </mat-form-field>
        </div>
      </form>
    </div>
    <div fxLayout="column wrap" class="add-lead-content lr-padding buttons-pad" fxFlex="64px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="saveSetting()" [disabled]="disableAddButton">Save</button></div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view cancel-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </div>
</div>