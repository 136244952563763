/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable()
export class titleService {
    activeLabel;
    constructor(private router: Router) {
    }

    getActiveMenu(values) {
        this.router.events.pipe(
            filter((u) => u instanceof NavigationEnd),
            map((u) => {
                const r = values.find(([_, route]) => this.router.isActive(route, false));
                if (r) {
                    return r[0];
                }
                return null;
            }),
            distinctUntilChanged()
        ).subscribe((v) => {
            this.activeLabel = v;
        })
    }

}
