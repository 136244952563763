/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Inject } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NPubSubService } from 'neutrinos-seed-services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { taskservice } from 'app/sd-services/taskservice';
import { appointmentservice } from 'app/sd-services/appointmentservice';
import { fileservice } from 'app/sd-services/fileservice';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-confirmpopup',
    templateUrl: './confirmpopup.template.html'
})

export class confirmpopupComponent extends NBaseComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<confirmpopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private pubsub: NPubSubService,
        private snackBar: MatSnackBar,
        private taskS: taskservice,
        private appointmentService: appointmentservice,
        private fileservice: fileservice) {
        super();
    }

    ngOnInit() {

    }

    delete(id) {
        if (this.data.name == 'Task') {
            this.taskS.deleteTask(id['activitytaskid'], id['contactid'])
                .then(result => {
                    if (result) {
                        this.snackBar.open('Task was deleted successfully.', 'OK', {
                            duration: 3000
                        })
                        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' })
                        this.pubsub.$pub('refreshtasklist');
                        this.pubsub.$pub('refreshevents');
                        this.close(true);
                    }
                })
        } else if (this.data.name == 'Appointment') {
            this.appointmentService.deleteAppointment(id['activityeventid'], id['contactid'])
                .then(result => {
                    if (result) {
                        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' })
                        this.pubsub.$pub('refreshappointmentslist');
                        this.pubsub.$pub('refreshevents');
                        this.close(true);
                    }
                })
        } else if (this.data.name == 'Document') {
            let tempObj = {};
            tempObj['tags'] = id.tags;
            tempObj['contactid'] = id.contactid;
            if (id.dealid) {
                tempObj['dealid'] = id.dealid;
            }

            this.fileservice.deleteDocument(id.activityfileid, tempObj)
                .then(result => {
                    if (result) {
                        this.pubsub.$pub('refreshdocumentlist');
                        this.close();
                    }
                })
        } else {
            // for generic dialogs
            this.close(true);
        }
    }

    close(postitive = false) {
        if (postitive) {
            this.dialogRef.close({ option: 'yes', optionData: this.data?.yes });
        } else {
            this.dialogRef.close({ option: 'no', optionData: this.data?.no });
        }
    }
}
