/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NPubSubService } from 'neutrinos-seed-services';
import { dialogService } from '../../services/dialog/dialog.service';
import { userservice } from 'app/sd-services/userservice';
import { labelService } from 'app/services/label/label.service';
import { dealservice } from 'app/sd-services/dealservice';
import { contactservice } from 'app/sd-services/contactservice';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class deleteService {
    contact_ownerid;
    deal_ownerid;
    accessDenied;

    constructor(private router: Router,
        public dialog: MatDialog,
        private pubsub: NPubSubService,
        private dialogService: dialogService,
        public label: labelService,
        public user: userservice,
        private dealService: dealservice,
        private contactservice: contactservice,
        private snackBar: MatSnackBar) {
    }

    deleteContact(contactid) {
        if (contactid) {
            this.dialogService.deleteContactOrDeal({
                msg: `Are you sure you want to permanently delete the contact? `,
                description: `Deleting a contact would delete all associated ${this.label.deals} and audit logs as well.`
            }).subscribe(result => {
                if (result) {
                    this.contactservice.contactDelete(contactid).then(res => {
                        if (res) {
                            this.snackBar.open('Contact deleted successfully.', 'OK', {
                                duration: 3000
                            })
                            this.router.navigate(['home/contacts'])
                        }

                    });
                }
            })
        }
    }

    deleteDeal(dealid) {
        this.dialogService.deleteContactOrDeal({
            msg: `Are you sure you want to permanently delete the ${this.label.deal}? `,
            description: `Deleting a ${this.label.deal} would delete all associated documents and activities of the ${this.label.deal} from associated contact.`
        }).subscribe(result => {
            if (result) {
                this.dealService.deleteDeal(dealid).then(result => {
                    if (result) {
                        this.snackBar.open('Deal deleted successfully.', 'OK', {
                            duration: 3000
                        })
                        this.router.navigate(['home/deals'])
                    }

                });
            }
        })
    }
}
