/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { cacheservice } from 'app/sd-services/cacheservice';
import { userservice } from 'app/sd-services/userservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { PageEvtData } from '../settinguserandrolesComponent/settinguserandroles.component';
import { SortChangeEvent, SortOrder } from '../sortComponent/sort.component';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-users',
	templateUrl: './users.template.html'
})

export class usersComponent extends NBaseComponent implements OnInit, OnDestroy {
	@Output() pageData = new EventEmitter<{ pageSize: number, pageOffset: number, totalCount: number }>();;
	progressCheck = false;
	headers: Header[] = [
		{ displayText: 'Name', sort: true, sortKey: 'display_name', sortValue: 'ASC' },
		{ displayText: 'Email' },
		{ displayText: 'Mobile' },
		{ displayText: 'Role', sort: true, sortKey: 'role_name', sortValue: 'ASC' },
		{ displayText: 'Status', sort: true, sortKey: 'active_status', sortValue: 'ASC' },
		{ displayText: 'Actions' }
	]
    currentSortKey = 'display_name';
	private _evtKey = 'PAGE_EVENT_SETTING_USERS';
	private _formKey = 'FORM_ADD_USER';
	private _usersSub: Subscription;

	users: UserDetails[] = []
	private _sortStatesInPriorityOrder: { sortKey: string, sortValue: SortOrder }[] = [];
	// sortStates = {
	// 	display_name: 'ASC',
	// 	role_name: 'ASC',
	// 	active_status: 'ASC',
	// }
	private _curPageSize: any;
	private _curPageOffset: any;

	constructor(private _pubsub: NPubSubService,
		private _users: userservice,
		private _caches: cacheservice) {
		super();
	}

	ngOnInit() {
		this._sortStatesInPriorityOrder = this.headers.filter(v => v.sort).map((v) => ({ sortKey: v.sortKey, sortValue: v.sortValue }));
		this._usersSub = this._pubsub.$sub(this._evtKey, (d: { evt: UsersEvent, evtdata: UsersEventData }) => {
			if (d.evt === 'refresh-list') {
				this._refreshUsersList(d.evtdata)
			}
		});
	}

	private async _refreshUsersList(d: PageEvtData = {}) {
		this._curPageSize = d.pageSize ?? this._curPageSize;
		this._curPageOffset = d.pageOffset ?? this._curPageOffset;
		this.users = (await this._users.getUsersFromDb(this._curPageSize, this._curPageOffset, this._sortStatesInPriorityOrder)).local.res?.data || [];
		this._emitTotalCount(this.users[0]?.count || '0');
	}

	_emitTotalCount(n: string) {
		this.pageData.next({
			pageSize: this._curPageSize,
			pageOffset: this._curPageOffset,
			totalCount: parseInt(n),
		});
	}

	async openEditUserForm(user: UserDetails) {
		await this._caches.setc({
			userObj: user,
		}, this._formKey);
		this._pubsub.$pub('sidenavformevents', {
			ACTION: 'open',
			PAGE: this._formKey
		});
	}

	trackByUserId(index: number, user: UserDetails) {
		user.userid;
	}

	_clearCache() {
		this._caches.deletec(this._formKey);
	}

	sortChange(e: SortChangeEvent) {
        this.currentSortKey = e.key;
		this.prioritizeFilter(e);
		this._refreshUsersList()
	}

	prioritizeFilter(v: SortChangeEvent) {
		const state = this._sortStatesInPriorityOrder.find(s => s.sortKey === v.key);
		state.sortValue = v.sort;
		this._sortStatesInPriorityOrder = this._sortStatesInPriorityOrder.filter(s => s !== state);
		this._sortStatesInPriorityOrder.unshift(state);
	}

	ngOnDestroy() {
		this._usersSub.unsubscribe();
		this._clearCache();
	}

}

type UsersEvent = 'refresh-list';
type UsersEventData = PageEvtData;
export interface UserDetails {
	userid: number,
	user_name: string,
	email_id: string,
	phone_no: string,
	role_name: string,
	active_status: boolean,
	roleid: number,
	first_name: string,
	last_name: string,
	phone_country_code: number,
	count: string,
}
// SAMPLE User
/**
{
    "userid": 10,
    "user_name": "dummyUser123@@",
    "email_id": "dummy123@gmail.com",
    "phone_no": null,
    "role_name": "SRM_ADMIN_Finexis",
    "active_status": true,
    "reporting_to_name": null,
    "reporting_to": null,
    "roleid": 9,
    "first_name": "dummy123",
    "last_name": "dummyBhai123",
    "phone_country_code": null,
    "count": "11"
}
 */

export interface Header {
	displayText: string,
	sort?: boolean,
	sortKey?: string,
	sortValue?: SortOrder
}