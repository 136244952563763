/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';

interface Row {
    title: string;
    value: string;
    type?: any;
}

interface Section {
    title: string;
    rows: Row[];
}

interface SummaryConfig {
    title: string;
    sections: Section[];
    showEditButton?: boolean;
}

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-summary',
    templateUrl: './summary.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./summary.style.scss']
})

export class summaryComponent extends NBaseComponent implements OnInit, OnChanges {

    @Input('config') config: any = {}; // SummaryConfig
    @Input() media_flex: any;
    @Output('editButtonClicked') editButtonClicked = new EventEmitter();
    @Output('panelChange') panelChange = new EventEmitter();

    displaySections: Section[] = [];
    pageSize;
    pageOffset = 0;
    totolCount = 0;
    showActionButtons = true;
    constructor(private pubsub: NPubSubService) {
        super();
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && changes.config.currentValue) {
            if (this.config['sections'] instanceof Array) {
                if (this.config['sections'].length < 3) {
                    this.pageSize = this.config['sections'].length;
                }
                this.totolCount = this.config['sections'].length;
                this.setDisplaySections();
                if (this.totolCount <= this.pageSize) {
                    this.showActionButtons = false;
                }
            }
        }
        if (this.config && this.config['sections']) {
            if (this.media_flex == 'xs') {
                this.pageSize = 1;
                this.showActionButtons = true;
            } else if ((this.media_flex == 'md') || (this.media_flex == 'sm')) {
                this.pageSize = 2;
                this.showActionButtons = true;
            } else {
                this.pageSize = 3;
                if (this.totolCount <= this.pageSize) {
                    this.showActionButtons = false;
                }
            }
            this.setDisplaySections();
        }
    }

    toggleChange(val) {
        this.panelChange.emit({ val });
    }

    setDisplaySections() {
        const initIndex = this.pageSize * this.pageOffset;
        this.displaySections = [];
        for (let i = initIndex; i < initIndex + this.pageSize; i++) {
            this.displaySections.push(this.config['sections'][i]);
        }
    }

    nextPage() {
        this.outOfBoundsNext();
        this.setDisplaySections();
    }

    previousPage() {
        this.outOfBoundsPrevious();
        this.setDisplaySections();
    }

    outOfBoundsPrevious() {
        if (this.pageOffset === 0) {
            this.pageOffset = Math.trunc(this.totolCount / this.pageSize);
        } else {
            this.pageOffset--;
        }
    }

    outOfBoundsNext() {
        if (this.totolCount <= ((this.pageOffset + 1) * this.pageSize)) {
            this.pageOffset = 0;
        } else {
            this.pageOffset++;
        }
    }

    edit() {
        this.editButtonClicked.emit();
    }
    editView() {
        if (this.config && this.config['title'] == "Contact Summary") {
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'EDIT_CONTACT_XS'
            })
        }
        if (this.config && this.config['title'] == "Deal Summary") {
            this.pubsub.$pub('sidenavformevents', {
                ACTION: 'open',
                PAGE: 'EDIT_DEAL_XS'
            })
        }
    }
}
