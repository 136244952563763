<div fxLayout="column wrap" style="padding-bottom: 8px;" class="contact_expansion" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let section of udf_for_view; let i = index;" [fxHide.gt-xs]="true">
  <mat-expansion-panel #panel (opened)="toggleChange(i)" (closed)="toggleClose(i)" hideToggle="true" [expanded]="panelCount == i ? true : false">
    <mat-expansion-panel-header class="form-sections-title">

      <mat-panel-title>

        <h3>
          {{section.field_section === &apos;__srm__default__section__&apos; ? &apos;Details&apos;: section.field_section}}</h3>
      </mat-panel-title>
      <div>
        <mat-icon class="expansion-icon">{{(panel?.expanded) ? &apos;remove&apos; : &apos;add&apos;}}</mat-icon>
      </div>
    </mat-expansion-panel-header>
    <div fxLayout="row wrap" class="padding-1-0" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="center start" [fxShow]="true" [fxHide]="false" *ngFor="let field of section.fields;" [fxFlex.xs]="100">
        <div class="width-100 height-100">
          <ndc-dynamic [ndcDynamicComponent]="field.field_ui" [ndcDynamicInputs]="field.fieldInputOptions"></ndc-dynamic>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let section of udf_for_view;" [fxHide.xs]="true">
  <h3 class="form-sections-title margin-bottom-1">
    {{section.field_section === &apos;__srm__default__section__&apos; ? &apos;Details&apos;: section.field_section}}</h3>
  <div fxLayout="row wrap" class="padding-0-1" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="center start" [fxShow]="true" [fxHide]="false" *ngFor="let field of section.fields;">
      <div class="width-100 height-100">
        <ndc-dynamic [ndcDynamicComponent]="field.field_ui" [ndcDynamicInputs]="field.fieldInputOptions"></ndc-dynamic>
      </div>
    </div>
  </div>
</div>