<div fxLayout="column wrap" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="row wrap" class="padding-1 border-bottom" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <h2>
        CANCEL</h2>
    </div>
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <mat-icon style="border-color: none !important;" class="border-none close-icon close-button" (click)="cancel()">close</mat-icon>
    </div>
  </div>
  <h4 style="text-align:center;" class="padding-1">
    Cancel Pending Assignment?</h4>
  <div fxLayout="row wrap" fxFlex="60px" fxLayoutGap="5px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false"><button mat-raised-button class="primary-action-button mat-elevation-z0" tabindex="0" (click)="yes()">Yes</button><button mat-raised-button class="mat-elevation-z0 secondary-action-button" tabindex="1" (click)="cancel()">No</button></div>
</div>