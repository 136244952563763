/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms';
import { srmappservice } from 'app/sd-services/srmappservice';
import { tenantservice } from 'app/sd-services/tenantservice';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-currencycodeselect',
	templateUrl: './currencycodeselect.template.html'
})

export class currencycodeselectComponent extends NBaseComponent implements OnInit {

	@Input('currencyCodeformControl') fc = new FormControl('');
	@Input('placeholder') placeholder = '';
	@Input('options') currencies: CurrencyCode[] = [];
	@Input('valueMapsTo') vMap = '';
	@Input('viewMapsTo') vwMap = '';

	constructor(private srmappS: srmappservice,
		private tenantS: tenantservice) {
		super();
	}

	async ngOnInit() {
		await this.getPhoneCountryCodes();
		this.currencies.unshift({
			currency_symbol: '',
			currency_name: 'None',
			currency_code: '',
			country: '',
			currencyid: <any>''
		});
	}

	async getPhoneCountryCodes() {
		try {
			this.currencies = (await this.srmappS.getCurrencyCodes()).local.currencycodes.filter((p: CurrencyCode) => p.currency_code);
			const tenantSettings = (await this.tenantS.getTenantSettings()).local.tenantSettings;
			if (!this.fc.value && tenantSettings && tenantSettings.CURRENCY_CODE) {
				this.fc.setValue(parseInt(tenantSettings.CURRENCY_CODE));
			} else {
				console.log('No default currency code set for the tenant');
			}
		} catch (e) {
			console.error(e);
		}
	}
}

export interface CurrencyCode {
	currencyid: number,
	country: string,
	currency_name: string,
	currency_code: string,
	currency_symbol: string
}