import { Injectable } from '@angular/core';
import { ActivatedRoute, RouterStateSnapshot, Router, Route, ActivatedRouteSnapshot } from '@angular/router';
import { NeutrinosOAuthClientService } from 'neutrinos-oauth-client';

@Injectable({
  providedIn: 'root'
})
export class srmauthguardService {

  private currUrl;

  constructor(private _neutrinosOAuthClientService: NeutrinosOAuthClientService,
    private _router: Router, private _arouter: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
      this.currUrl = state.url;
      if (this._neutrinosOAuthClientService.isLoggedIn) {
        // Sending User to unauthorized if he doesn't have route permission
        if (!this.checkPermissions()) {
          this._router.navigate(['/unauthorized']);
        }

        return resolve(true);
      } else {
        this.getUserInfo()
          .then(() => {
            return resolve(true);
          })
          .catch(err => {
            console.error(err);
            if (err && err.code == 'auth-cancel') {
              // Added setTimeout for reloading the current route
              setTimeout(() => {
                this._router.navigateByUrl(this.currUrl, { skipLocationChange: true }).then(() => {
                  return resolve(false);
                });
              }, 500);
            } else {
              return resolve(false);
            }
          })
      }
    })
  }

  async getUserInfo() {
    try {
      await this._neutrinosOAuthClientService.getUserInfo();
    } catch (error) {
      console.log(error);
      if (error.status === 401 || error.status === 500) {
        this._router.navigate(['/unauthorized'])
      } else if (error.status === 403) {
        await this._neutrinosOAuthClientService.login();
        this._router.navigate([this.currUrl]);
      }
    }
  }

  checkPermissions() {
    if (this._neutrinosOAuthClientService['currentUserInfo'] && this._neutrinosOAuthClientService['currentUserInfo'].hasOwnProperty('srmTenantid')) {
      // route permissions -- checking user permissions for route
      // order matters - longest sub route should be inserted first
      let baseRoutesWithPermissions = {
        '/home/summary': ['GENERAL_MINE', 'GENERAL_TEAM'],
        '/home/contacts': ['CONTACT_MINE', 'CONTACT_TEAM', 'CONTACT_ALL'],
        '/home/deals': ['DEAL_MINE', 'DEAL_TEAM', 'DEAL_ALL'],
        '/home/settings/user-and-roles': ['SETTINGS_ADMIN'],
        '/home/settings': ['SETTINGS_MINE']
      }

      const currentRoute = Object.keys(baseRoutesWithPermissions).find(v => { return this.currUrl.startsWith(v)});

      if (currentRoute) {
        const permissions: Array<string> = baseRoutesWithPermissions[currentRoute];
        const userPermissions: Array<string> = this._neutrinosOAuthClientService['currentUserInfo']['srmPermissions'];
        let matched = false;
        for (let i = 0; i < permissions.length; i++) {
          for (let j = 0; j < userPermissions.length; j++) {
            if (permissions[i] == userPermissions[j]) {
              matched = true;
              break;
            }
          }
        }
        return matched;
      }
    }
    return true;
  }

}
