/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { feedbackservice } from 'app/sd-services/feedbackservice';
import { usernotificationservice } from 'app/sd-services/usernotificationservice';
import { feedbackoutcomessyncService } from 'app/services/feedbackoutcomessync/feedbackoutcomessync.service';
import { labelService } from 'app/services/label/label.service';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { AppointmentFeedbackOutcome } from '../activities_appointmentsComponent/activities_appointments.component';
import { CallFeedbackOutcome } from '../activities_callsComponent/activities_calls.component';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { OptionDroppedEvtData, OptionEvtData, SerializeableOption } from '../optionsconfiguratorComponent/optionsconfigurator.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-setting_feedbackoutcomes',
	templateUrl: './setting_feedbackoutcomes.template.html'
})

export class setting_feedbackoutcomesComponent extends NBaseComponent implements OnInit {
	title = 'CONFIGURE FEEDBACK OUTCOME';
	initApptFBOcs = [];
	initCallFBOcs = [];
	setApptFeedbackErr = true;
	setCallFeedbackErr = true;
	optionsAddBtnLabel = 'Add Outcome';
	sectionName = 'Configure appointment and call outcome options';
	outcomeTypes = [
		{ value: 'Appointment' },
		{ value: 'Call' }
	]
	outcomemap: { [key in OutcomeType]: { idKey: string, fkey: 'appointment_feedback_outcomes' | 'call_feedback_outcomes', mem } } = {
		appointment: {
			idKey: 'appointmentfeedbackid',
			fkey: 'appointment_feedback_outcomes',
			mem: 'apptfbocs'
		},
		call: {
			idKey: 'callfeedbackid',
			fkey: 'call_feedback_outcomes',
			mem: 'callfbocs'
		}
	}
	showApptConfig = true;
	formGroup = new FormGroup({
		appointment_feedback_outcomes: new FormControl('', Validators.required),
		call_feedback_outcomes: new FormControl('', Validators.required),
	});
	disableActions: boolean;
	apptfbocs: AppointmentFeedbackOutcome[];
	callfbocs: CallFeedbackOutcome[];
	ocBackup: { appointment_feedback_outcomes: AppointmentFeedbackOutcome[]; call_feedback_outcomes: CallFeedbackOutcome[]; };

	constructor(public fbOcsService: feedbackoutcomessyncService,
		private _fbS: feedbackservice,
		private _ps: NPubSubService,
		private _uns: usernotificationservice,
		private _label: labelService) {
		super();
		this.formGroup.patchValue({
			appointment_feedback_outcomes: this.fbOcsService.appointment_feedback_outcomes,
			call_feedback_outcomes: this.fbOcsService.call_feedback_outcomes,
		});
		this.apptfbocs = this.fbOcsService.appointment_feedback_outcomes;
		this.callfbocs = this.fbOcsService.call_feedback_outcomes;
		this.ocBackup = U.JSONClone(this.fbOcsService.outcomes);
	}

	ngOnInit() { }

	get disableSubmit() {
		return this.formGroup.invalid || this.disableActions;
	}

	switchOutComesConfig(e) {
		this.showApptConfig = e === 'Appointment';
	}

	async updateOption(e: OptionEvtData<CallFeedbackOutcome | AppointmentFeedbackOutcome>, type: OutcomeType) {
		const ocObj = this.outcomemap[type];
		this.disableActions = true;
		const oc = e.value;
		oc.sort_order = e.index + 1;
		let msg = `"${oc.feedback_label}" added to ${this._label.toTitleCase(type)} outcomes.`;
		try {
			if (e.isNew) {
				await this._fbS.createFbOutcome(type, oc.feedback_label, oc.sort_order);
			} else {
				await this._fbS.updateFbOutcome(type, oc.feedback_label, null, oc[ocObj.idKey]);
				msg = `"${oc.feedback_label}" ${this._label.toTitleCase(type)} outcome updated.`;
			}
            this._ps.$pub('refresh-settings');
		} catch (e) {
			msg = e.error?.message || e.error;
		}
		await this._setNewOutcomes(type, msg);
		this.disableActions = false;
	}

	async updateOptionOrder(es: OptionDroppedEvtData, type: OutcomeType) {
		this.disableActions = true;
		const ems = es.map(e => { e.value.sort_order = e.index + 1; return e.value });
		let msg = `Order of ${this._label.toTitleCase(type)} outcomes updated.`;
		try {
			await this._fbS.updateBulkFbOutcome(type, ems);
		} catch (e) {
			msg = e.error?.message || e.error;
		}
		await this._setNewOutcomes(type, msg);
		this.disableActions = false;
	}

	async deleteOption(e: OptionEvtData<CallFeedbackOutcome | AppointmentFeedbackOutcome>, type: OutcomeType) {
		const ocObj = this.outcomemap[type];
		if (!e.isNew) {
			this.disableActions = true;
			const oc = e.value;
			oc.sort_order = e.index + 1;
			let msg = `"${oc.feedback_label}" deleted from ${type} outcomes.`;
			try {
				await this._fbS.delFbOutcome(oc[ocObj.idKey], type);
			} catch (e) {
				msg = e.error?.message || e.error;
			}
			await this._setNewOutcomes(type, msg);
			this.disableActions = false;
		}
	}

	private async _setNewOutcomes(type: string, msg = '') {
		const ocObj = this.outcomemap[type];
		try {
			this[ocObj.mem] = this.fbOcsService[ocObj.fkey] = (await this._fbS.getFeedbackOutcomes(type)).local.res?.data;
		} catch (e) {
			msg = e.error?.message || e.error;
		}
		this._uns.openSnackbar(msg);
	}

	private backup(type, rawOpts: SerializeableOption[]) {
		const ocObj = this.outcomemap[type];
		this.ocBackup[ocObj.fkey] = U.JSONClone(rawOpts.map(r => r.value));
	}

	private restore(type) {
		const ocObj = this.outcomemap[type];
		this[ocObj.mem] = U.JSONClone(this.ocBackup)[ocObj.fkey];
	}

	close() {
		if (!this.disableActions) {
			this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
				ACTION: 'close'
			})
		}
	}
}

type OutcomeType = 'call' | 'appointment';
