<div fxLayout="column " class="background-primary height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column wrap" class="width-100" fxFlex="40px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="font-weight-size width-100" fxFlex="100" fxLayoutAlign="space-evenly center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="78" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          {{data?.header || &apos;DELETE&apos;}}</div>
      </div>
      <div fxLayout="row wrap" fxFlex="8" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <mat-icon class="cursor-pointer" (click)="close()" *ngIf="!data.hideCloseButton">close</mat-icon>
      </div>
    </div>
  </div>
  <mat-divider>
  </mat-divider>
  <div fxLayout="column " class="padding-1" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" class="margin-bottom-1" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <h5 class="font-normal-size">
          {{data.title}}</h5>
      </div>
      <div fxLayout="row wrap" class="margin-bottom-1" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="data.subtitle">
        <h5 class="font-normal-size">
          {{data.subtitle}}</h5>
      </div>
      <div fxLayout="row wrap" class="color-danger margin-bottom-1" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="data.dangerWarning">
        <h5 class="font-normal-size">
          {{data.dangerWarning}}</h5>
      </div>
    </div>
    <div fxLayout="row wrap" class="padding-0-1 add-lead-content" fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="delete(data.idValue)">Yes</button></div>
      <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view cancel-button" (click)="close()" type="button">No</button></div>
    </div>
  </div>
</div>