/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { SideNavFormEventData } from '../baselayoutComponent/baselayout.component';
import { contactservice } from 'app/sd-services/contactservice';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cacheservice } from 'app/sd-services/cacheservice';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-form_contact_filter',
    templateUrl: './form_contact_filter.template.html'
})

export class form_contact_filterComponent extends NBaseComponent implements OnInit {
    title = 'SET FILTER';
	optionsAddBtnLabel = 'Add Label';
	sectionName = 'Define label filters for my contacts';
    disableActions: boolean;
    filterOptions = [];
    // searchOption = '';
    formGroup = new FormGroup({
		searchOption: new FormControl('')
	});
    filterCount = 0;
    filters = [];
    appliedFilters = [];
    disableFilter = true

    constructor(
        private _ps: NPubSubService,
        private cs: contactservice,
         private cacheService: cacheservice
    ) {
        super();
    }

    async ngOnInit() {
        await this.getFilterOptions();
        if (this.filterCount > 0 ) {
            this.disableFilter = false;
        }
    }
    close() {
		if (!this.disableActions) {
			this._ps.$pub('sidenavformevents', <SideNavFormEventData>{
				ACTION: 'close'
			})
		}
	}
    
    async getFilterOptions() {
        this.filterOptions = (await this.cs.getContactLabels()).local.result.data;
        this.cacheService.getc('MY_CONTACT_FILTER').then((result) => {
            let d = result.local.data;
            if (d) {
                this.appliedFilters = U.JSONClone(d.filters);
                if (this.appliedFilters.length > 0) {
                    for (let i in this.filterOptions) {
                        this.filterOptions[i]['isSelected'] = false;
                        for (let j in this.appliedFilters) {
                            if (this.filterOptions[i]['tag'] == this.appliedFilters[j]) {
                                this.filterOptions[i]['isSelected'] = true;
                            }
                            
                        }
                    }
                    // this.filterCount = this.appliedFilters.length;
                    // this.filters = this.appliedFilters;
                    this.disableFilter = false;
                }
            }
            // this.cacheService.setc(null, 'MY_CONTACT_FILTER');
        }).catch(e => console.log(e));
        this.filterOptions = this.filterOptions.length > 0 ? this.filterOptions.filter(o => o.tag.toLowerCase().includes(this.formGroup.controls.searchOption.value.toLowerCase())) : this.filterOptions;
        if (this.filterOptions.length > 0) {
            this.filterOptions.unshift({tag: "Select All", isSelected: false}, {tag: "No Labels", isSelected: false});
        } else {
            this.filterOptions.unshift({tag: "No Labels", isSelected: false});
        }
    }
    selectedFilters(event) {
        if (event.count > 0) {
            console.log(event)
            this.disableFilter = false;
            this.filterCount = event.count;
            this.filters = event.filters;
        }
    }
    async applyFilter() {
        if (this.filterCount > 0) {
            await this.cacheService.setc({ 'filters': this.filters }, 'MY_CONTACT_FILTER');
            this._ps.$pub('refreshcontactlist');
            this._ps.$pub('refreshfilter');
            this.disableFilter = false;
            this.close()
        }
    }
}
