/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class callcontactsservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_callcontactsservice

  async getmodulephonenumbers(
    moduleName: any = undefined,
    moduleId: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          moduleName: moduleName,
          moduleId: moduleId,
          contactid: contactid
        },
        local: {
          phoneValue: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_I0P3YGEeB5qT2tnw(bh);
      //appendnew_next_getmodulephonenumbers
      return (
        // formatting output variables
        {
          input: {},
          local: {
            phoneValue: bh.local.phoneValue
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_zgcsGNWchGOyRLVl');
    }
  }

  async makeCallToContact(
    phonenumber: any = undefined,
    contactid: any = undefined,
    phone_country_code: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          phonenumber: phonenumber,
          contactid: contactid,
          phone_country_code: phone_country_code
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_AHkHwoJvBS368ixn(bh);
      //appendnew_next_makeCallToContact
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BuH9nIirRGYLjqPg');
    }
  }

  async makeCallToDeal(
    phonenumber: any = undefined,
    contactid: any = undefined,
    dealid: any = undefined,
    phone_country_code: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          phonenumber: phonenumber,
          contactid: contactid,
          dealid: dealid,
          phone_country_code: phone_country_code
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_TLlKFvhP4Yqhm0Y6(bh);
      //appendnew_next_makeCallToDeal
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Ca1ClV13QSz9y2lz');
    }
  }

  //appendnew_flow_callcontactsservice_Start

  async sd_I0P3YGEeB5qT2tnw(bh) {
    try {
      let m = bh.input.moduleName;
      let mId = bh.input.moduleId;

      let url =
        bh.system.environment.properties.ssdURL +
        'call/phonenumbers/' +
        m +
        '/' +
        mId;

      if (bh.input.contactid) {
        url += '?contactid=' + bh.input.contactid;
      }
      bh.url = url;
      bh = await this.sd_UF6HDCQvqJ3mY2f9(bh);
      //appendnew_next_sd_I0P3YGEeB5qT2tnw
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_I0P3YGEeB5qT2tnw');
    }
  }

  async sd_UF6HDCQvqJ3mY2f9(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.phoneValue = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_UF6HDCQvqJ3mY2f9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_UF6HDCQvqJ3mY2f9');
    }
  }

  async sd_AHkHwoJvBS368ixn(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'call/contact/' +
        bh.input.contactid;
      bh.body = {
        phonenumber: bh.input.phonenumber,
        phone_country_code: bh.input.phone_country_code
      };
      bh = await this.sd_W3a4QZFCNfa4fkTC(bh);
      //appendnew_next_sd_AHkHwoJvBS368ixn
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_AHkHwoJvBS368ixn');
    }
  }

  async sd_W3a4QZFCNfa4fkTC(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_W3a4QZFCNfa4fkTC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_W3a4QZFCNfa4fkTC');
    }
  }

  async sd_TLlKFvhP4Yqhm0Y6(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'call/contact/' +
        bh.input.contactid +
        '/deal/' +
        bh.input.dealid;
      bh.body = {
        phonenumber: bh.input.phonenumber,
        phone_country_code: bh.input.phone_country_code
      };
      bh = await this.sd_goZb7U0amitdar8a(bh);
      //appendnew_next_sd_TLlKFvhP4Yqhm0Y6
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_TLlKFvhP4Yqhm0Y6');
    }
  }

  async sd_goZb7U0amitdar8a(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_goZb7U0amitdar8a
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_goZb7U0amitdar8a');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_callcontactsservice_Catch
}
