<div fxLayout="column wrap" class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column wrap" class="lr-padding add-contact-title sticky white-back add-lead-tile" fxFlex="64px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="60" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/appointment_create.svg">
          <h2>
            {{appointmentLabelName}}</h2>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="40" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" style="width:100%" fxLayoutGap="1em" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="appointmentLabelName == &apos;VIEW APPOINTMENT&apos; ">
            <mat-icon (click)="dupAppointment()">content_copy</mat-icon>
          </div>
          <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="appointmentLabelName == &apos;VIEW APPOINTMENT&apos; "><img imgSrc="Web/Icons/editevent.svg" (click)="editAppointment()" class="cursor-pointer"></div>
          <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" *ngIf="appointmentLabelName == &apos;VIEW APPOINTMENT&apos;"><img imgSrc="Web/Icons/deleteevent.svg" (click)="deleteAppointment()" class="cursor-pointer"></div>
          <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
            <mat-icon (click)="close()">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form NgForm [formGroup]="appointmentGroup" fxLayout="column" id="form_add_appointment" style="height: calc(100% - 67px)" class="tb-padding">
    <div fxLayout="column " fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" fxFill="fxFill">
      <div class="padding-1 display-block overflow-y-only">
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div [ngClass]="disableLooking">

            <mat-form-field class="width-100" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
              <input matInput placeholder="Ex. Finish Linda&apos;s SNA." [required]="true" formControlName="event_title" [readonly]="appointmentLabelName == &apos;VIEW APPOINTMENT&apos;" trim="blur">
              <mat-label>Title</mat-label>
            </mat-form-field>
          </div>
          <div class="contact-search">
            <bh-dyncontactsearch [fc]="appointmentGroup?.controls.contactId" [placeholder]="relatedContactPlaceholder" [defaultcontacts]="defaultContactValues" (resultSelected)="contactSelected($event)" [value]="editingConId" [mode]="searchmode" (navigationInit)="close()"></bh-dyncontactsearch>
          </div>
          <mat-error align *ngIf="rematedcontactnoemialwarning">{{rematedcontactnoemialwarning}}</mat-error>
          <div *ngIf="editingDealId || appointmentLabelName == &apos;ADD APPOINTMENT&apos;" class="deal-search">
            <bh-dyndealssearch [fc]="appointmentGroup?.controls.dealId" [placeholder]="relatedDealPlaceholder" (resultSelected)="dealSelected($event)" [label]="relatedDealLabel" [value]="editingDealId" [dealContactId]="editingConId" [disabled]="dealDisabled" #dealSearch [defaultdeals]="defaultDeals" [mode]="searchmode" (navigationInit)="close()" [disable]="dealDisabled"></bh-dyndealssearch>
          </div>
          <div [ngClass]="disableLooking">

            <mat-form-field class="width-100" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;always&apos;" [hideRequiredMarker]="false">
              <input matInput placeholder="Ex. No.9A, Central Library, 489539" [required]="false" formControlName="event_location" [readonly]="appointmentLabelName == &apos;VIEW APPOINTMENT&apos;">
              <mat-label>Location</mat-label>
            </mat-form-field>
          </div>
          <div [ngClass]="disableLooking" style="pointer-events: all !important;" class="input-focus-color">
            <div fxLayout="column" fxLayoutAlign="center start" class="task-description">
              <label class="label" ng-reflect-disabled="true" ng-reflect-ng-switch="false"><span>Description</span></label>
              <mat-form-field class="width-100" appearance="outline" [ngClass.xs]="&apos;description-padding&apos;">
                <textarea placeholder="Enter appointment description." [readonly]="appointmentLabelName == &apos;VIEW APPOINTMENT&apos;" matInput formControlName="event_description"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row wrap" class="stage-list-card" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="disableLooking" [ngStyle.xs]="&apos;margin-left: 5px;flex-wrap: nowrap;&apos;">
            <div fxFlex="5" [fxHide.gt-xs]="true">
              <img class="video-icon" [ngStyle.xs]="&apos;margin-left: -3px;&apos;" src="assets/Web/Icons/clock.svg"></div>
            <div>
              <div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="start center" [ngStyle.xs]="&apos;width: 100%;&apos;" [ngClass.gt-xs]="&apos;clock-height&apos;">

                <mat-form-field class="cursor-pointer no-border-field width-dt" style="padding-right: 4px;" [ngClass.xs]="&apos;date-time-align&apos;">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1.1em" [ngStyle.xs]="&apos;width: 84%;margin-left: 22px;&apos;">
                    <img class="clock-icon" [ngStyle.xs]="&apos;display: none;&apos;" src="assets/Web/Icons/clock.svg">
                    <input matInput class="custom-date-input" formControlName="start_date" [matDatepicker]="pickerFrom" (focus)="appointmentLabelName !== &apos;VIEW APPOINTMENT&apos; &amp;&amp; pickerFrom.open()" (click)="appointmentLabelName !== &apos;VIEW APPOINTMENT&apos; &amp;&amp; pickerFrom.open()" readonly>

                    <mat-datepicker #pickerFrom></mat-datepicker>
                  </div>
                </mat-form-field>

                <mat-form-field class="no-border-field width-time" [ngClass.xs]="&apos;date-time-align&apos;">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <input type="text" aria-label="Number" matInput formControlName="start_time" class="custom-date-input" [readonly]="appointmentLabelName == &apos;VIEW APPOINTMENT&apos;" [style.color]="appointmentGroup.get(&apos;start_time&apos;).valid ? &apos;inherit&apos;:&apos;red&apos;" (click)="setFromTimeScroll()" [matAutocomplete]="autofrom">
                    <mat-autocomplete class="fromtime-auto-container" autoActiveFirstOption #autofrom="matAutocomplete">
                      <mat-option [class]="&apos;from-&apos;+(option.replace(&apos;:&apos;,&apos;_&apos;))" *ngFor="let option of timeSugestionValues" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <span class="padding-6">to</span>
                    </div>
                  </div>

                </mat-form-field>

                <mat-form-field style="padding-right: 4px;" class="no-border-field width-time-to" [ngClass.gt-xs]="&apos;margin-left-1&apos;" [ngClass.xs]="&apos;date-time-align&apos;" [ngStyle.xs]="&apos;margin-left: 22px;&apos;">
                  <div fxLayout="row" fxFlex="82" fxLayoutAlign="start center">
                    <input type="text" aria-label="Number" class="custom-date-input" matInput formControlName="end_time" [readonly]="appointmentLabelName == &apos;VIEW APPOINTMENT&apos;" [style.color]="appointmentGroup.get(&apos;end_time&apos;).valid ? &apos;inherit&apos;:&apos;red&apos;" [matAutocomplete]="autoto" (click)="setToTimeScroll()">
                    <mat-autocomplete class="totime-auto-container" autoActiveFirstOption #autoto="matAutocomplete">
                      <mat-option [class]="&apos;to-&apos;+(option.replace(&apos;:&apos;,&apos;_&apos;))" *ngFor="let option of timeSugestionValues" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </mat-form-field>

                <div class="input-date">
                  <mat-form-field class="no-border-field width-dt cursor-pointer" [ngClass.xs]="&apos;date-time-align&apos;">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="70">
                      <input matInput class="custom-date-input" formControlName="end_date" [matDatepicker]="pickerTo" (focus)="appointmentLabelName !== &apos;VIEW APPOINTMENT&apos; &amp;&amp; pickerTo.open()" (click)="appointmentLabelName !== &apos;VIEW APPOINTMENT&apos; &amp;&amp; pickerTo.open()" readonly>
                      <!-- <label id="date-from" [matDatepicker]="pickerTo" (click)="pickerTo.open()">{{todayDate}}</label> -->
                      <!-- <label id="time-from" >12:00pm</label>  -->
                      <mat-datepicker #pickerTo></mat-datepicker>
                    </div>
                    <mat-error>
                      Invalid Time
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" class="padding-top-1" fxLayoutGap="10px" fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false">
          <div fxFlex="5">
            <img class="video-icon" src="assets/Web/Icons/video-camera.svg"></div><button mat-button class="video-conf button-normal color-view" *ngIf="showVidConf" (click)="openUrlLink()">Join Video Conferencing</button><button mat-button class="video-conf button-normal color-view" *ngIf="showAddConf" [ngClass]="disableLooking" (click)="generateVideoCallUUID()" [disabled]="isViewing">Add Video Conferencing</button>
        </div>
        <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="disableLooking">
          <div>
            <div class="link-container" fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="space-between center" *ngIf="appointmentGroup.get(&apos;room_id&apos;).value">
              <span id="div1" class="h3 common-word-wrap" style="text-decoration: underline;font-weight: 100 !important;">{{videoConfUrl}}{{appointmentGroup.get(&apos;room_id&apos;).value}}</span>
              <mat-icon class="cursor-pointer" (click)="copyLink(&apos;div1&apos;)">content_copy</mat-icon>
              <mat-icon *ngIf="!isViewing" class="cursor-pointer" (click)="!isViewing &amp;&amp; appointmentGroup.get(&apos;room_id&apos;).reset()">clear</mat-icon>
            </div>
          </div>
        </div>
        <mat-error align *ngIf="formErrorMessage.length &gt; 0">{{formErrorMessage}}</mat-error>
      </div>
      <div fxLayout="row " style="height: 10%;" class="padding-top-1 lr-padding" fxLayoutGap="8px" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false" *ngIf="appointmentLabelName != &apos;VIEW APPOINTMENT&apos;">
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="appointmentLabelName == &apos;EDIT APPOINTMENT&apos;" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="primary-form-action-button" [ngClass.xs]="&apos;width-100&apos;" (click)="createAppointment()" [disabled]="disableAddButton || isViewing">Update</button></div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="appointmentLabelName == &apos;ADD APPOINTMENT&apos;" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="primary-form-action-button" [ngClass.xs]="&apos;width-100&apos;" (click)="createAppointment()" [disabled]="disableAddButton || isViewing">Add</button></div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.gt-xs]="20" [fxFlex.xs]="30"><button mat-button class="secondary-form-action-button" [ngClass.xs]="&apos;width-100&apos;" (click)="cancel()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>