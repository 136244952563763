/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class autocompleteservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_autocompleteservice

  async getLeads(
    lead_name: any = undefined,
    page_no: any = undefined,
    page_size: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          lead_name: lead_name,
          page_no: page_no,
          page_size: page_size
        },
        local: {
          leadsData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_MSd1EwuRGRa0nPFO(bh);
      //appendnew_next_getLeads
      return (
        // formatting output variables
        {
          input: {},
          local: {
            leadsData: bh.local.leadsData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Jt8Yia0an3tXcGtH');
    }
  }

  async getDeals(
    deal_name: any = undefined,
    page_no: any = undefined,
    page_size: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          deal_name: deal_name,
          page_no: page_no,
          page_size: page_size
        },
        local: {
          dealsData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_uhH6g9KE2WmU9MZv(bh);
      //appendnew_next_getDeals
      return (
        // formatting output variables
        {
          input: {},
          local: {
            dealsData: bh.local.dealsData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BbqO4CvGmlNiZYAe');
    }
  }

  async getContacts(
    contact_name: any = undefined,
    page_no: any = undefined,
    page_size: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          contact_name: contact_name,
          page_no: page_no,
          page_size: page_size
        },
        local: {
          contactsData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_VKGDTnKrWvmm79Di(bh);
      //appendnew_next_getContacts
      return (
        // formatting output variables
        {
          input: {},
          local: {
            contactsData: bh.local.contactsData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_moEf2phaGbFxVaIK');
    }
  }

  //appendnew_flow_autocompleteservice_Start

  async sd_MSd1EwuRGRa0nPFO(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'autocomplete/leads?';
      if (bh.input.lead_name && bh.input.lead_name.length > 0) {
        bh.url += 'lead_name=' + bh.input.lead_name || '';
        bh.url += '&';
      }
      bh.url += 'page_no=' + (bh.input.page_no || 1);
      bh.url += '&page_size=' + (bh.input.page_size || 10);

      bh = await this.sd_s1Tu2klJSg7ID5tZ(bh);
      //appendnew_next_sd_MSd1EwuRGRa0nPFO
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MSd1EwuRGRa0nPFO');
    }
  }

  async sd_s1Tu2klJSg7ID5tZ(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.leadsData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_s1Tu2klJSg7ID5tZ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_s1Tu2klJSg7ID5tZ');
    }
  }

  async sd_uhH6g9KE2WmU9MZv(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'autocomplete/deals?';

      if (bh.input.deal_name && bh.input.deal_name.length > 0) {
        bh.url += 'deal_name=' + bh.input.deal_name || '';
        bh.url += '&';
      }
      bh.url += 'page_no=' + (bh.input.page_no || 1);
      bh.url += '&page_size=' + (bh.input.page_size || 10);

      bh = await this.sd_ktmZBrjUuZHbRFgt(bh);
      //appendnew_next_sd_uhH6g9KE2WmU9MZv
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_uhH6g9KE2WmU9MZv');
    }
  }

  async sd_ktmZBrjUuZHbRFgt(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.dealsData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_ktmZBrjUuZHbRFgt
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ktmZBrjUuZHbRFgt');
    }
  }

  async sd_VKGDTnKrWvmm79Di(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'autocomplete/contacts?';

      if (bh.input.contact_name && bh.input.contact_name.length > 0) {
        bh.url += 'contact_name=' + bh.input.contact_name || '';
        bh.url += '&';
      }
      bh.url += 'page_no=' + (bh.input.page_no || 1);
      bh.url += '&page_size=' + (bh.input.page_size || 10);

      bh = await this.sd_5PbE8MtlSaMVXKc4(bh);
      //appendnew_next_sd_VKGDTnKrWvmm79Di
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_VKGDTnKrWvmm79Di');
    }
  }

  async sd_5PbE8MtlSaMVXKc4(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.contactsData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_5PbE8MtlSaMVXKc4
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_5PbE8MtlSaMVXKc4');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_autocompleteservice_Catch
}
