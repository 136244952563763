/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class taskservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_taskservice

  async createTask(taskDetails: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          taskDetails: taskDetails
        },
        local: {
          queryResult: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_qSMLpovI2WRqDWw5(bh);
      //appendnew_next_createTask
      return (
        // formatting output variables
        {
          input: {},
          local: {
            queryResult: bh.local.queryResult
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BkAI0DWDR6jUulug');
    }
  }

  async getTask(
    start_time: any = undefined,
    end_time: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          start_time: start_time,
          end_time: end_time
        },
        local: {
          taskData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_x0xXN9fEazQXxpkV(bh);
      //appendnew_next_getTask
      return (
        // formatting output variables
        {
          input: {},
          local: {
            taskData: bh.local.taskData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ZKfLQxK3HRqW48H8');
    }
  }

  async updateTask(
    taskid: any = undefined,
    taskDetails: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          taskid: taskid,
          taskDetails: taskDetails
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_zuvET7AKhuwVBanJ(bh);
      //appendnew_next_updateTask
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_NSDjjPepU87pF8ac');
    }
  }

  async deleteTask(
    taskid: any = undefined,
    contactid: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          taskid: taskid,
          contactid: contactid
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_2F1l6hkVN6vPc6Up(bh);
      //appendnew_next_deleteTask
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_EYGBnanC22nMrVZW');
    }
  }

  async updateTaskstatus(
    taskid: any = undefined,
    taskDetails: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          taskid: taskid,
          taskDetails: taskDetails
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_lpxu5zV8cefnvTPG(bh);
      //appendnew_next_updateTaskstatus
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_BWUmjhc2zHR58uf4');
    }
  }

  //appendnew_flow_taskservice_Start

  async sd_qSMLpovI2WRqDWw5(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'task';
      bh = await this.sd_HO2LkfTsw29CtUUp(bh);
      //appendnew_next_sd_qSMLpovI2WRqDWw5
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_qSMLpovI2WRqDWw5');
    }
  }

  async sd_HO2LkfTsw29CtUUp(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.taskDetails
      };
      bh.local.queryResult = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_HO2LkfTsw29CtUUp
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_HO2LkfTsw29CtUUp');
    }
  }

  async sd_x0xXN9fEazQXxpkV(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'tasks/calendar?';

      if (bh.input.start_time) {
        bh.url += '&fromDate=' + bh.input.start_time.replace('+', '%2B');
      }

      if (bh.input.end_time) {
        bh.url += '&toDate=' + bh.input.end_time.replace('+', '%2B');
      }

      bh = await this.sd_N2jgIkkmAPwLvsnW(bh);
      //appendnew_next_sd_x0xXN9fEazQXxpkV
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_x0xXN9fEazQXxpkV');
    }
  }

  async sd_N2jgIkkmAPwLvsnW(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.taskData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_N2jgIkkmAPwLvsnW
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_N2jgIkkmAPwLvsnW');
    }
  }

  async sd_zuvET7AKhuwVBanJ(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'task/' + bh.input.taskid;

      bh = await this.sd_3N1RBLhrvGsiSlye(bh);
      //appendnew_next_sd_zuvET7AKhuwVBanJ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_zuvET7AKhuwVBanJ');
    }
  }

  async sd_3N1RBLhrvGsiSlye(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.taskDetails
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_3N1RBLhrvGsiSlye
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3N1RBLhrvGsiSlye');
    }
  }

  async sd_2F1l6hkVN6vPc6Up(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'task/' +
        bh.input.taskid +
        '?';

      if (bh.input.contactid) {
        bh.url += 'contactid=' + bh.input.contactid;
      }
      bh = await this.sd_s4RYbgQ37FhyEv3z(bh);
      //appendnew_next_sd_2F1l6hkVN6vPc6Up
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_2F1l6hkVN6vPc6Up');
    }
  }

  async sd_s4RYbgQ37FhyEv3z(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_s4RYbgQ37FhyEv3z
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_s4RYbgQ37FhyEv3z');
    }
  }

  async sd_lpxu5zV8cefnvTPG(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'taskstatus/' +
        bh.input.taskid;

      bh = await this.sd_jyPDyFix4frgl1i9(bh);
      //appendnew_next_sd_lpxu5zV8cefnvTPG
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lpxu5zV8cefnvTPG');
    }
  }

  async sd_jyPDyFix4frgl1i9(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.taskDetails
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_jyPDyFix4frgl1i9
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jyPDyFix4frgl1i9');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_taskservice_Catch
}
