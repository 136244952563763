/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cacheservice } from 'app/sd-services/cacheservice';
import { SearchedUser } from '../dynusersearchComponent/dynusersearch.component';
import { contactservice } from 'app/sd-services/contactservice';
import { MatSnackBar } from '@angular/material/snack-bar';
import { userservice } from 'app/sd-services/userservice';
import { Router } from '@angular/router';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-form_contact_reassign',
    templateUrl: './form_contact_reassign.template.html'
})

export class form_contact_reassignComponent extends NBaseComponent implements OnInit {

    reassignFormGroup = new FormGroup({
        contactcount: new FormControl({ value: '', disabled: true }, Validators.required),
        ownerid: new FormControl('', { validators: Validators.required }),
    });
    isReassignFormInvalid = false;
    disableAddButton = false;
    selectedUser;
    contactCount;
    contactsObj;
    contactsArr = [];
    reportingUsers;
    currentUser;
    currentTab;
    ownerName;
    filters = []
    constructor(
        private _pubsub: NPubSubService,
        private cacheService: cacheservice,
        private snackBar: MatSnackBar,
        private contactService: contactservice,
        private _users: userservice,
        private router: Router
    ) {
        super();
    }

    async ngOnInit() {
        this.reportingUsers = (await this._users.getReportingusers()).local.reportingUsers;
        this.currentUser = (await this._users.getcu()).local.user;
        if (this.reportingUsers) {
            this.reportingUsers.push(this.currentUser.srmUserid);
        } else {
            this.reportingUsers = this.currentUser.srmUserid;
        }
        this._cacheData();
        this.disableAddButton = true;
        this.isReassignFormInvalid = true;
        this.cacheService.getc('CONTACT_TAB_INDEX').then((result) => {
            let d = result.local.data;
            if (d) {
                this.currentTab = d.index;
                if (this.currentTab == 0) {
                    this.cacheService.getc('MY_CONTACT_FILTER').then((result) => {
                        let d = result.local.data;
                        if (d) {
                            this.filters = d.filters;
                        } else {
                            this.filters = [];
                        }
                    }).catch(e => console.log(e));
                } else {
                    this.cacheService.getc('MY_TEAM_CONTACT_FILTER').then((result) => {
                        let d = result.local.data;
                        if (d) {
                            this.filters = d.filters;
                        } else {
                            this.filters = [];
                        }
                    }).catch(e => console.log(e));
                }
            }
        }).catch(e => console.log(e));
    }
    close() {
        this._pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }

    cancel() {
        this.close();
    }
    add() {
        if (typeof this.reassignFormGroup.get('ownerid').value !== 'object') {
            this.isReassignFormInvalid = true;
            this.reassignFormGroup.get('ownerid').setErrors({ 'incorrect': true });
        }
        if (this.reassignFormGroup.valid) {
            this.disableAddButton = true;
            // perform contact reassign
            this.contactService.contactReassign(this.contactsArr, this.reassignFormGroup.get('ownerid').value.userid, this.ownerName,this.currentTab,this.filters)
                .then((result: {
                    input: {}
                    local: {
                        result: {
                            data: any[],
                            message: string
                        }
                    }
                }) => {
                    this.snackBar.open(result.local.result.message, 'OK', {
                        duration: 3000
                    });
                    this.close();
                    if (this.router.url.includes('/home/contacts')) {
                        this._pubsub.$pub('refreshcontactlist');
                        this._pubsub.$pub('refreshteamcontactlist');
                    }
                    this.disableAddButton = false;
                })
                .catch(error => {
                    this.snackBar.open(error.message, 'OK', {
                        duration: 3000
                    })
                    this.disableAddButton = false;
                });
        } else {
            this.isReassignFormInvalid = true;
            this.disableAddButton = true;
        }
    }
    _cacheData() {
     
        this.cacheService.getc('FORM_CONTACT_REASSIGN').then((result) => {
            let d = result.local.data;
               console.log("_cacheData called",d)
            if (d) {
                this.contactCount = d.contacts_count;
                this.contactsObj = d.contacts_obj;
                this.reassignFormGroup.get('contactcount').setValue(this.contactCount)
            }
            this.cacheService.setc(null, 'FORM_CONTACT_REASSIGN');
        }).catch(e => console.log(e));
        this.cacheService.getc('CONTACT_TAB_INDEX').then((result) => {
            let d = result.local.data;
            if (d) {
                this.currentTab = d.index;
            }
            // this.cacheService.setc(null, 'CONTACT_TAB_INDEX');
        }).catch(e => console.log(e));
    }
    selected(event: SearchedUser): void {
        this.contactsArr = [];
        this.disableAddButton = false;
        this.isReassignFormInvalid = false;
        this.ownerName = event.display_name;
        // console.log(event.display_name)
        // this.selectedUser = event;
        this.reassignFormGroup.get('ownerid').setValue(event)
        // console.log(this.reassignFormGroup.get('ownerid').value)
        for (let i = 0; i < this.contactCount; i++) {
            if (this.contactsObj[i]['contact_owner'] != this.reassignFormGroup.get('ownerid').value.userid) {
                this.contactsArr.push(this.contactsObj[i]['contactid'])
            }
        }
        if (this.contactsArr.length > 0) {
            this.disableAddButton = false;
            this.isReassignFormInvalid = false;
        } else {
            this.isReassignFormInvalid = true;
            this.disableAddButton = true;
        }
    }
    filter = ((value: SearchedUser): boolean => {
        return this.reportingUsers.includes(value.userid)
    }).bind(this);
}
