/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class cacheservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_cacheservice

  async setc(data: any = undefined, key: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          data: data,
          key: key
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_B2JthAc5eH39FnmC(bh);
      //appendnew_next_setc
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_1wCPtIVLtx0GpBn4');
    }
  }

  async getc(key: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          key: key
        },
        local: {
          data: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_sC8s0TVHhRFabZQb(bh);
      //appendnew_next_getc
      return (
        // formatting output variables
        {
          input: {},
          local: {
            data: bh.local.data
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wQBeZqiUGsiWcQeg');
    }
  }

  async deletec(key: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          key: key
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_WiB97tBMZ2clOOCG(bh);
      //appendnew_next_deletec
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_PMFl7web90qt9uPK');
    }
  }

  async updatec(data: any = undefined, key = '', ...others) {
    try {
      var bh = {
        input: {
          data: data,
          key: key
        },
        local: {
          data: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.updateCacheObj(bh);
      //appendnew_next_updatec
      return (
        // formatting output variables
        {
          input: {},
          local: {
            data: bh.local.data
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_f7qJUaHwPHmoc8Ki');
    }
  }

  //appendnew_flow_cacheservice_Start

  async sd_B2JthAc5eH39FnmC(bh) {
    try {
      this[bh.input.key] = bh.input.data;
      //appendnew_next_sd_B2JthAc5eH39FnmC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_B2JthAc5eH39FnmC');
    }
  }

  async sd_sC8s0TVHhRFabZQb(bh) {
    try {
      bh.local.data = this[bh.input.key];
      //appendnew_next_sd_sC8s0TVHhRFabZQb
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_sC8s0TVHhRFabZQb');
    }
  }

  async sd_WiB97tBMZ2clOOCG(bh) {
    try {
      delete this[bh.input.key];
      //appendnew_next_sd_WiB97tBMZ2clOOCG
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_WiB97tBMZ2clOOCG');
    }
  }

  async updateCacheObj(bh) {
    try {
      const o = this[bh.input.key] || {};
      this[bh.input.key] = Object.assign(o, bh.input.data);
      bh.local.data = this[bh.input.key];
      //appendnew_next_updateCacheObj
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_rTQApuTplqukue4x');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_cacheservice_Catch
}
