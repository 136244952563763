/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { cacheservice } from 'app/sd-services/cacheservice';
import { roleservice } from 'app/sd-services/roleservice';
import { usernotificationservice } from 'app/sd-services/usernotificationservice';
import { userservice } from 'app/sd-services/userservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { dynusersearchComponent, SearchedUser } from '../dynusersearchComponent/dynusersearch.component';
import { UserDetails } from '../usersComponent/users.component';


/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-form_add_user',
	templateUrl: './form_add_user.template.html'
})

export class form_add_userComponent extends NBaseComponent implements OnInit {

	formBuildData: FormBuildData = {
		users: [],
		roles: []
	}
	addDisabled = false;
	permissionGroupMap: Record<string, string> = {};

	// #region chip autocomplete properties
	@ViewChild('usersearch') usersearchComp: dynusersearchComponent;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	chipsArrayData: SearchedUser[] = [];
	filteredUsers: Observable<SearchedUser[]>;
	// #endregion chip autocomplete properties

	userFormGroup = new FormGroup({
		first_name: new FormControl('', { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) }),
		last_name: new FormControl('', { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) }),
		phone_country_code: new FormControl(''),
		phone_no: new FormControl(''),
		email_id: new FormControl('', { validators: Validators.compose([Validators.required, Validators.email]) }),
		roleid: new FormControl('', { validators: Validators.required }),
		reporting_to: new FormControl(''),
		active_status: new FormControl('active')
	});
	mode: 'ADD' | 'EDIT' = 'ADD';
	private formKey = 'FORM_ADD_USER';
	private _userObject: UserDetails;

	constructor(private _caches: cacheservice,
		private _pubsub: NPubSubService,
		private _notify: usernotificationservice,
		private _roles: roleservice,
		private _users: userservice) {
		super();
		this.filteredUsers = this.userFormGroup.get('reporting_to').valueChanges.pipe(
			map((user_name: string | null) => this.filter(user_name))
		);
	}

	ngOnInit() {
		this._remapFormData();
	}

	async _remapFormData() {
		this._cacheRolesLiteData();
		this._caches.getc(this.formKey).then(({ local: { data: userData } }) => {
			if (userData) {
				if (userData.userObj) {
					this._userObject = userData.userObj;
					this.mode = 'EDIT';
					this.userFormGroup.patchValue({
						first_name: this._userObject.first_name,
						last_name: this._userObject.last_name,
						phone_no: this._userObject.phone_no,
						phone_country_code: this._userObject.phone_country_code,
						email_id: this._userObject.email_id,
						roleid: this._userObject.roleid,
						active_status: this._userObject.active_status === true ? 'active' : 'inactive'
					});
					this._createChipsData();
				}
			}
		});
	}

	async _createChipsData() {
		this._getReportingToData().then((reporting_tos: SearchedUser[]) => {
			this.chipsArrayData = Array.isArray(reporting_tos) ? reporting_tos : <SearchedUser[]>[];
		});
	}

	private async _cacheRolesLiteData() {
		const roles = (await this._roles.getRoles(null, null, true)).local.res;
		this.formBuildData.roles = roles || [];
	}

	private async _getReportingToData() {
		return <SearchedUser[]>(await this._users.getUserReportingTo(this._userObject.userid)).local.res?.data;
	}

	close() {
		this._pubsub.$pub('sidenavformevents', { ACTION: 'close' });
	}

	cancel() {
		this.close();
	}

	async update() {
		this.addDisabled = true;
		const dynMsgPrt = this.mode === 'ADD' ? 'added' : 'updated';
		if (this.userFormGroup.invalid) {
			this._notify.openSnackbar(`User cannot be ${dynMsgPrt}. Form is invalid.`);
		} else {
			const postableUserObject: UserAPIBody = {
				user_name: this.userFormGroup.controls.email_id.value || this.userFormGroup.controls.user_name.value,
				first_name: this.userFormGroup.controls.first_name.value,
				last_name: this.userFormGroup.controls.last_name.value,
				email_id: (this.userFormGroup.controls.email_id.value).toLowerCase(),
				phone_no: this.userFormGroup.controls.phone_no.value,
				phone_country_code: this.userFormGroup.controls.phone_country_code.value,
				roleid: this.userFormGroup.controls.roleid.value,
				reporting_to: this.chipsArrayData.map(u => u.userid),
				active_status: this.userFormGroup.controls.active_status.value === 'active'
			}
			try {
				if (this.mode === 'ADD') {
					await this.addUser(postableUserObject)
				} else {
					await this.updateUser(postableUserObject);
				}
				this._notify.openSnackbar(`"${postableUserObject.first_name + ' ' + postableUserObject.last_name}" user ${dynMsgPrt} succesfully.`);
				this._pubsub.$pub('PAGE_EVENT_SETTING_USERS', {
					evt: 'refresh-list',
				});
                this._pubsub.$pub('refresh-settings');
				this.close();
			} catch (e) {
				const msg = e.error && e.error.error ? e.error.error
					: `${postableUserObject.first_name + ' ' + postableUserObject.last_name} Could not be ${dynMsgPrt}`
				this._notify.openSnackbar(msg);
			}
		}
		this.addDisabled = false;
	}

	async addUser(userObj: UserAPIBody) {
		const userid = (await this._users.addUser(userObj)).local.res;
		userid;
	}

	async updateUser(userObj: UserAPIBody) {
		const res = (await this._users.updateUser(userObj, this._userObject.userid)).local.res
	}

	private async _clearCache() {
		await this._caches.deletec(this.formKey)
	}

	//#region chip autocomplete methods
	// add(event: MatChipInputEvent): void {
	// 	const { input, value } = event;
	// 	const u = this.formBuildData.users.find(u => u.display_name === value);
	// 	if (u) {
	// 		this.chipsArrayData.push(u)
	// 	}
	// 	if (input) {
	// 		input.value = '';
	// 	}
	// 	this.userFormGroup.controls.reporting_to.setValue('');
	// }

	remove(user: SearchedUser): void {
		this.chipsArrayData = this.chipsArrayData.filter(u => u.userid !== user.userid);
	}

	username(v: SearchedUser) {
		return v ? v.display_name || v.email_id : '';
	}

	selected(event: SearchedUser): void {
		this.chipsArrayData.push(event);
		this.usersearchComp.userSearchInput.nativeElement.value = '';
		this.userFormGroup.controls.reporting_to.setValue('');
	}

	filter = ((value: SearchedUser): boolean => {
		return this._userObject?.userid ? this._userObject?.userid !== value.userid && this.chipsArrayData.every(u => u.userid !== value.userid)
			: this.chipsArrayData.every(u => u.userid !== value.userid)
	}).bind(this);
	//#endregion chip autocomplete methods

	async ngOnDestroy() {
		await this._clearCache();
	}
}

interface FormBuildData {
	roles?: Array<RoleNameId>,
	users?: Array<SearchedUser>
}

interface RoleNameId { role_name: string, roleid: number }
// interface SearchedUser { display_name: string, user_name: string, userid: number }

interface UserAPIBody {
	user_name: string,
	first_name: string,
	last_name: string,
	email_id: string,
	phone_no: string,
	phone_country_code: number,
	roleid: number,
	reporting_to?: number[],
	active_status: boolean
}