<div fxLayout="column " class="background-primary height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column wrap" class="width-100" fxFlex="40px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="font-weight-size width-100" fxFlex="100" fxLayoutAlign="space-evenly center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="92" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div>
          DELETE</div>
      </div>
      <div fxLayout="row wrap" fxFlex="8" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <mat-icon class="cursor-pointer" (click)="dialogRef.close(false)">close</mat-icon>
      </div>
    </div>
  </div>
  <mat-divider>
  </mat-divider>
  <div class="margin-top-1">
    {{data.msg}}</div>
  <div>
    {{data.description}}</div>
  <div fxLayout="column " fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="padding-1 add-lead-content" fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="dialogRef.close(true)">Yes</button></div>
      <div fxLayout="column wrap" fxFlex="48" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view cancel-button" (click)="dialogRef.close(false)" type="button">No</button></div>
    </div>
  </div>
</div>