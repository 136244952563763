/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class summaryservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_summaryservice

  async getActiveDeal(...others) {
    try {
      var bh = {
        input: {},
        local: {
          activeData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_N1IMMuL9skoDOJJa(bh);
      //appendnew_next_getActiveDeal
      return (
        // formatting output variables
        {
          input: {},
          local: {
            activeData: bh.local.activeData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_RuBb1WtxFCapyzUU');
    }
  }

  async getDealAmount(...others) {
    try {
      var bh = {
        input: {},
        local: {
          amountData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_LJEbjRX41unPobct(bh);
      //appendnew_next_getDealAmount
      return (
        // formatting output variables
        {
          input: {},
          local: {
            amountData: bh.local.amountData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_kGFR8lChqW7HVzJ6');
    }
  }

  async getPendingEvents(due_date: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          due_date: due_date
        },
        local: {
          pendingEventsData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_GUhG7yHDy3y9rX79(bh);
      //appendnew_next_getPendingEvents
      return (
        // formatting output variables
        {
          input: {},
          local: {
            pendingEventsData: bh.local.pendingEventsData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_uA82Jh8e67gk0n0y');
    }
  }

  async getOverdueTask(due_date: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          due_date: due_date
        },
        local: {
          overdueTaskData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_AoYpMwa9YYCyhGmK(bh);
      //appendnew_next_getOverdueTask
      return (
        // formatting output variables
        {
          input: {},
          local: {
            overdueTaskData: bh.local.overdueTaskData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_DYJOFSPrhZyDFpzr');
    }
  }

  //appendnew_flow_summaryservice_Start

  async sd_N1IMMuL9skoDOJJa(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'summary/deal/stage/active';

      bh = await this.sd_dm3kHV7uZQkgP9Tz(bh);
      //appendnew_next_sd_N1IMMuL9skoDOJJa
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_N1IMMuL9skoDOJJa');
    }
  }

  async sd_dm3kHV7uZQkgP9Tz(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.activeData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_dm3kHV7uZQkgP9Tz
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_dm3kHV7uZQkgP9Tz');
    }
  }

  async sd_LJEbjRX41unPobct(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL + 'summary/deal/stage/amount';

      bh = await this.sd_lzmsPv92Yzwhdn0b(bh);
      //appendnew_next_sd_LJEbjRX41unPobct
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_LJEbjRX41unPobct');
    }
  }

  async sd_lzmsPv92Yzwhdn0b(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.amountData = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_lzmsPv92Yzwhdn0b
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_lzmsPv92Yzwhdn0b');
    }
  }

  async sd_GUhG7yHDy3y9rX79(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'feedback/pending?';

      if (bh.input.due_date) {
        bh.url += '&due_date=' + bh.input.due_date;
      }

      bh = await this.sd_ImfUriXZ0tVk1nrE(bh);
      //appendnew_next_sd_GUhG7yHDy3y9rX79
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_GUhG7yHDy3y9rX79');
    }
  }

  async sd_ImfUriXZ0tVk1nrE(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.pendingEventsData = await this.sdService.nHttpRequest(
        requestOptions
      );
      //appendnew_next_sd_ImfUriXZ0tVk1nrE
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ImfUriXZ0tVk1nrE');
    }
  }

  async sd_AoYpMwa9YYCyhGmK(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'overdue/tasks?';

      if (bh.input.due_date) {
        bh.url += 'dueDate=' + bh.input.due_date;
      }

      bh = await this.sd_wSIpgw2jlZzBEDTR(bh);
      //appendnew_next_sd_AoYpMwa9YYCyhGmK
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_AoYpMwa9YYCyhGmK');
    }
  }

  async sd_wSIpgw2jlZzBEDTR(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.overdueTaskData = await this.sdService.nHttpRequest(
        requestOptions
      );
      //appendnew_next_sd_wSIpgw2jlZzBEDTR
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wSIpgw2jlZzBEDTR');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_summaryservice_Catch
}
