/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cacheservice } from 'app/sd-services/cacheservice';
import { feedbackservice } from 'app/sd-services/feedbackservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { AppointmentFeedbackOutcome } from '../activities_appointmentsComponent/activities_appointments.component';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-feedback_form_appointment',
    templateUrl: './feedback_form_appointment.template.html'
})

export class feedback_form_appointmentComponent extends NBaseComponent implements OnInit {

    outcomes: AppointmentFeedbackOutcome[] = [];
	isAddDisabled = false;
	feedbackObject: any;
    feedbackFormGroup = new FormGroup({
		contact_name: new FormControl(''),
        deal_name: new FormControl(''),
        appointment_feedback_id: new FormControl('', Validators.required),
        feedback_notes: new FormControl(''),
    });

    private _baseUrl = '/home/';
    formKey = 'FEEDBACK_FORM_APPOINTMENTS_ACTIVITY';
    relatedDealLabel = '';
    contactLable = 'Related Contact';
    conUrl = '';
    dealUrl = '';

    constructor(
        private pubsub: NPubSubService,
        private snackBar: MatSnackBar,
        // private dealS: dealservice,
        private label: labelService,
        private feedbackService: feedbackservice,
        private _caches: cacheservice
    ) {
        super();
    }

    ngOnInit() {
        this.relatedDealLabel = `Related ${this.label.deal}`
        this._getCachedData().then(([{ local: { data: outcomes } }, { local: { data: feedback } }]) => {
            this.feedbackObject = feedback;
            this.outcomes = outcomes;
            if (this.feedbackObject && Object.keys(this.feedbackObject).length) {
                this.conUrl = this._baseUrl + 'contacts/' + this.feedbackObject.contactid;
                this.dealUrl = this._baseUrl + 'deals/' + this.feedbackObject.dealid + '/contact/' + this.feedbackObject.contactid;
                this.feedbackFormGroup.patchValue(this.feedbackObject);
            }
        })
    }

    private _getCachedData() {
        return Promise.all([
            this._caches.getc('APPOINTMENTS_FEEDBACK_OUTCOMES'),
            this._caches.getc(this.formKey)
        ])
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' });
    }

    cancel() {
        this.close();
    }

    async update() {
        if (!this.feedbackFormGroup.invalid) {
            const body = {
                feedback_notes: this.feedbackFormGroup.get('feedback_notes').value || '',
                feedbackid: this.feedbackFormGroup.get('appointment_feedback_id').value
            }
            this.isAddDisabled = true; 
            try {
                const result = await this.feedbackService.upateFeedback(body, 'appointment', this.feedbackObject.activityeventid, this.feedbackObject.contactid);
                this.notify({ data: body }, 'Appointment log was succesfully updated.');
                this.isAddDisabled = false;
            } catch (e) {
                this.isAddDisabled = false;
                this.notify({ err: e }, 'Error occured when trying to update appointment log.');
            }
        } else {
            this.notify({ err: true }, 'Outcome is required.');
        }

    }

    notify({ data, err }: { data?: any, err?: any }, message: string) {
        const sbOpts = { duration: 3000 };
        if (data) {
            this._caches.updatec({
                appointment_feedback_id: data.feedbackid,
                feedback_notes: data.feedback_notes
            }, this.formKey)
            this.pubsub.$pub('getFeedbackevents');
            this.snackBar.open(message, 'OK', sbOpts);
            this.close();
        } else if (err) {
            this.snackBar.open(message, 'OK', sbOpts);
        }
    }

    private async _clearCache() {
        await this._caches.deletec('FEEDBACK_FORM_APPOINTMENTS_ACTIVITY')
    }

    async ngOnDestroy() {
        this.feedbackObject = null;
        this.outcomes = null;
        await this._clearCache();
    }
}
