/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class tenantservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_tenantservice

  async getpipeline(modulename: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          modulename: modulename
        },
        local: {
          data: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_jFiW46FL3oUfXgFT(bh);
      //appendnew_next_getpipeline
      return (
        // formatting output variables
        {
          input: {},
          local: {
            data: bh.local.data
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_vMYzygGGYv4SpD0O');
    }
  }

  async initTenantSettings(tenantSettings: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          tenantSettings: tenantSettings
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_wVttBkX8WPgZ8ZPJ(bh);
      //appendnew_next_initTenantSettings
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_RfDxklGAGJ9yHqXq');
    }
  }

  async getTenantSettings(...others) {
    try {
      var bh = {
        input: {},
        local: {
          tenantSettings: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_dX4Nz8z1R3qg9K4F(bh);
      //appendnew_next_getTenantSettings
      return (
        // formatting output variables
        {
          input: {},
          local: {
            tenantSettings: bh.local.tenantSettings
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_sZJFkXFwYO0sf6yi');
    }
  }

  async updateTenantIcon(file: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          file: file
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_CMBLiUt6vW1Wyk8a(bh);
      //appendnew_next_updateTenantIcon
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_6wnudSIo2KltkXIX');
    }
  }

  async updateTenantSetting(
    settings_key: any = undefined,
    settings_value: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          settings_key: settings_key,
          settings_value: settings_value
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_u6zd0Y5NhztoHVW1(bh);
      //appendnew_next_updateTenantSetting
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_QZf03aJMbY2zBTgY');
    }
  }

  async setTenantSettingValue(
    settings_key: any = undefined,
    settings_value: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          settings_key: settings_key,
          settings_value: settings_value
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_2764GtvTmRiky5az(bh);
      //appendnew_next_setTenantSettingValue
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_dZlBzD3h7pZkAUki');
    }
  }

  async updateTheme(
    primary_color: any = undefined,
    secondary_color: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          primary_color: primary_color,
          secondary_color: secondary_color
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_N1RfLYw7Dc9KbdxL(bh);
      //appendnew_next_updateTheme
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_le7y2m64dCUZeBXH');
    }
  }

  async renameDealModule(
    singular_name: any = undefined,
    plural_name: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          singular_name: singular_name,
          plural_name: plural_name
        },
        local: {
          result: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_NE3EoyjwWtKJIKoM(bh);
      //appendnew_next_renameDealModule
      return (
        // formatting output variables
        {
          input: {},
          local: {
            result: bh.local.result
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_PpJ9jmcTK2FnDAwK');
    }
  }

  //appendnew_flow_tenantservice_Start

  async sd_jFiW46FL3oUfXgFT(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'pipeline/' +
        bh.input.modulename;

      bh = await this.sd_LMbLjm8EZMZJDzzC(bh);
      //appendnew_next_sd_jFiW46FL3oUfXgFT
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jFiW46FL3oUfXgFT');
    }
  }

  async sd_LMbLjm8EZMZJDzzC(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.data = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_LMbLjm8EZMZJDzzC
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_LMbLjm8EZMZJDzzC');
    }
  }

  async sd_wVttBkX8WPgZ8ZPJ(bh) {
    try {
      let o = {};
      if (bh.input.tenantSettings instanceof Array) {
        for (let i = 0; i < bh.input.tenantSettings.length; i++) {
          o[bh.input.tenantSettings[i].setting_key] =
            bh.input.tenantSettings[i].setting_value;
        }
      }
      bh.local.data = o;
      bh = await this.sd_O9EYB33XjRaO0j61(bh);
      //appendnew_next_sd_wVttBkX8WPgZ8ZPJ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_wVttBkX8WPgZ8ZPJ');
    }
  }

  //__client_service_designer_class_variable_declaration__tenantSettings
  tenantSettings: any;
  async sd_O9EYB33XjRaO0j61(bh) {
    try {
      this.tenantSettings = bh.local.data;
      //appendnew_next_sd_O9EYB33XjRaO0j61
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_O9EYB33XjRaO0j61');
    }
  }

  async sd_dX4Nz8z1R3qg9K4F(bh) {
    try {
      bh.local.tenantSettings = this.tenantSettings;
      //appendnew_next_sd_dX4Nz8z1R3qg9K4F
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_dX4Nz8z1R3qg9K4F');
    }
  }

  async sd_CMBLiUt6vW1Wyk8a(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'tenant-icon';

      // bh.body = {
      //     document: bh.input.file
      // }
      bh = await this.sd_0kSwa5bnd8WpdHeG(bh);
      //appendnew_next_sd_CMBLiUt6vW1Wyk8a
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_CMBLiUt6vW1Wyk8a');
    }
  }

  async sd_0kSwa5bnd8WpdHeG(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.file
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_0kSwa5bnd8WpdHeG
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0kSwa5bnd8WpdHeG');
    }
  }

  async sd_u6zd0Y5NhztoHVW1(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'tenant-settings';

      bh.body = {
        settings_key: bh.input.settings_key,
        settings_value: bh.input.settings_value
      };
      bh = await this.sd_tf8EoxCpUbS1K4AE(bh);
      //appendnew_next_sd_u6zd0Y5NhztoHVW1
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_u6zd0Y5NhztoHVW1');
    }
  }

  async sd_tf8EoxCpUbS1K4AE(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_tf8EoxCpUbS1K4AE
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_tf8EoxCpUbS1K4AE');
    }
  }

  async sd_2764GtvTmRiky5az(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'tenant-settings';
      bh.body = {
        settings_key: bh.input.settings_key,
        settings_value: bh.input.settings_value
      };
      bh = await this.sd_2SLHJnxAGGIyVpzi(bh);
      //appendnew_next_sd_2764GtvTmRiky5az
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_2764GtvTmRiky5az');
    }
  }

  async sd_2SLHJnxAGGIyVpzi(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.data = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_2SLHJnxAGGIyVpzi
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_2SLHJnxAGGIyVpzi');
    }
  }

  async sd_N1RfLYw7Dc9KbdxL(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'theme-settings';

      bh.body = {
        primary_color: bh.input.primary_color,
        secondary_color: bh.input.secondary_color
      };
      bh = await this.sd_FVumJLwZa9Bo4gae(bh);
      //appendnew_next_sd_N1RfLYw7Dc9KbdxL
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_N1RfLYw7Dc9KbdxL');
    }
  }

  async sd_FVumJLwZa9Bo4gae(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_FVumJLwZa9Bo4gae
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_FVumJLwZa9Bo4gae');
    }
  }

  async sd_NE3EoyjwWtKJIKoM(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'rename-deal';

      bh.body = {
        singular_name: bh.input.singular_name,
        plural_name: bh.input.plural_name
      };
      bh = await this.sd_vWovGsjPaqLkftpa(bh);
      //appendnew_next_sd_NE3EoyjwWtKJIKoM
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_NE3EoyjwWtKJIKoM');
    }
  }

  async sd_vWovGsjPaqLkftpa(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'patch',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_vWovGsjPaqLkftpa
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_vWovGsjPaqLkftpa');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_tenantservice_Catch
}
