/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, Output, ViewChild, AfterViewInit } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { cacheservice } from 'app/sd-services/cacheservice';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-baselayoutadd',
    templateUrl: './baselayoutadd.template.html'
})

export class baselayoutaddComponent extends NBaseComponent implements OnInit, AfterViewInit {
    @Input() eventList;
    @ViewChild('sidenavform') sidenavform;


    constructor(private pubsub: NPubSubService,
        private cacheService: cacheservice,
        public label: labelService) {
        super();
    }

    ngOnInit() {

    }

    addLead() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_LEAD'
        })
    }

    addTask() {
        this.cacheService.setc({}, 'EDIT_TASK_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_TASK'
        })
    }

    scheduleAppointment() {
        this.cacheService.setc({}, 'EDIT_APPOINTMENT_FORM');
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'SCHEDULE_APPOINTMENT'
        })
    }

    addDeal() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_DEAL'
        })
    }

    addContact() {
        this.pubsub.$pub('sidenavformevents', {
            'ACTION': 'open',
            PAGE: 'ADD_CONTACT'
        })
    }
    openImportContact(){
        this.pubsub.$pub('sidenavformevents', {
            'ACTION': 'open',
            PAGE: 'IMPORT_CONTACT'
        })
    }
    viewEmailTrail() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'EMAIL_TRAIL'
        })
    }
    ngAfterViewInit() {
    }
}
