<div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill *ngIf="!false">
  <div fxLayout="column wrap" style="padding: 0 10px;     border-bottom: 2px solid #DEDEDE;" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let item of emails; let i = index">
    <div fxLayout="row " style="min-height: 3em" fxLayoutAlign="center space-between" [fxShow]="true" [fxHide]="false" (click)="openEmail(item)">
      <div fxLayout="column " fxFlex="25" fxLayoutAlign="center start" [fxShow]="true" [fxHide]="false">
        <div>
          {{item[&apos;emailData&apos;][&apos;from&apos;]}}</div>
      </div>
      <div fxLayout="column " fxFlex="45" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
        <div>
          {{item[&apos;emailData&apos;][&apos;topic&apos;]}}</div>
      </div>
      <div fxLayout="column " fxFlex="10" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div>
          <span *ngIf="item[&apos;direction&apos;] == 0" class="material-icons"> keyboard_backspace </span>
          <span *ngIf="item[&apos;direction&apos;] == 1" class="material-icons"> arrow_forward </span></div>
      </div>
      <div fxLayout="column " fxFlex="10" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div *ngIf="item[&apos;sentiment&apos;]">
          <span *ngIf="item[&apos;sentiment&apos;][&apos;tag_name&apos;] == &apos;Positive&apos;" class="material-icons" style="color: green"> sentiment_satisfied_alt </span>
          <span *ngIf="item[&apos;sentiment&apos;][&apos;tag_name&apos;] == &apos;Negative&apos;" class="material-icons" style="color: red"> sentiment_very_dissatisfied </span>
          <span *ngIf="item[&apos;sentiment&apos;][&apos;tag_name&apos;] == &apos;Neutral&apos;" class="material-icons" style="color: black"> sentiment_neutral </span>

        </div>
      </div>
      <div fxLayout="column " fxFlex="10" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="item[&apos;emailData&apos;][&apos;date&apos;]">
        <div>
          <span *ngIf="changeTime(item[&apos;emailData&apos;][&apos;date&apos;])">{{item[&apos;emailData&apos;][&apos;date&apos;] | date:&quot;HH:mm a&quot; }}</span>
          <span *ngIf="!changeTime(item[&apos;emailData&apos;][&apos;date&apos;])">{{item[&apos;emailData&apos;][&apos;date&apos;] | date:&quot;MMM&quot;}} {{item[&apos;emailData&apos;][&apos;date&apos;]| date:&quot;dd&quot;}}</span></div>
      </div>
    </div>
  </div>
</div>