<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <bh-sidenavformheader [title]="title" [titleIconUrl]="&apos;assets/Web/Icons/udf-add-dependency-form.svg&apos;" (closeClicked)="close()"></bh-sidenavformheader>
  <form [formGroup]="fg" style="height: calc(100% - 70px);" (ngSubmit)="add()" (reset)="clear()">
    <div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <bh-formsectiontitle [sectionName]="&apos;Configure field dependency mapping&apos;"></bh-formsectiontitle>
      <div fxLayout="column " class="overflow-y-only height-100 lr-padding" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column " fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" class="select-container" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
            <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
              <mat-select formControlName="dependency_field_name" placeholder="Select controlling field" [required]="true">
                <ng-template ngFor let-option [ngForOf]="controllingFields" let-i="index">
                  <mat-option [value]="option.field_name">{{option.field_placeholder}}</mat-option>
                </ng-template>
              </mat-select>

            </mat-form-field>
            <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
              <mat-select formControlName="field_name" placeholder="Select dependent field" [required]="true">
                <ng-template ngFor let-option [ngForOf]="dependentFields" let-i="index">
                  <mat-option [value]="option.field_name">{{option.field_placeholder}}</mat-option>
                </ng-template>
              </mat-select>

            </mat-form-field>
            <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
              <mat-select formControlName="dependency_comparator" placeholder="Select Operator" [required]="true">
                <ng-template ngFor let-option [ngForOf]="operators" let-i="index">
                  <mat-option [value]="option.value">{{option.display}}</mat-option>
                </ng-template>
              </mat-select>

            </mat-form-field>
          </div>
          <div fxLayout="row " style="height: 24.625rem;" class="partitions-container width-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="column " class="height-100 partition-1" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
              <div *ngIf="!showValueSelector; else valueSelector;" class="common-text-align-center label-font-color lr-padding margin-auto">
                Select controlling field to choose controlling value</div>
              <ng-template #valueSelector>
                <div fxLayout="row wrap" class="partition-header" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                  <div class="partition-header-text-container">
                    {{controllingPartHeaderLabel}}</div>
                </div>
                <div fxLayout="column " class="partition-body" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                  <div class="search-input-container">
                    <input [placeholder]="searchInputPlaceholder" [formControl]="searchInputFC" class="search-input">
                  </div>
                  <div class="selectable-list overflow-y-only">
                    <div *ngFor="let v of filteredControllingFieldValues | async; let i = index;" (click)="toggleSelection(v, i)" [ngClass]="{ &apos;fill-color-bg&apos;: this.fg.controls.dependency_field_value?.value === v }" matRipple tabindex="0" (keyup.Space)="toggleSelection(v, i)" class="selectable-list-item cursor-pointer">
                      <div>{{v}}</div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div fxLayout="column " class="height-100 partition-2" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
              <div *ngIf="!showOpSelector; else opSelector;" class="common-text-align-center label-font-color lr-padding margin-auto">
                Select dependant field to configure its behavior</div>
              <ng-template #opSelector>
                <div fxLayout="row wrap" class="partition-header" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                  <div class="partition-header-text-container">
                    {{depPartHeaderLabel}}</div>
                </div>
                <div fxLayout="column " style="padding-top: 0 !important;" class="partition-body" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                  <div fxLayout="row wrap" class="padding-1 cursor-pointer" fxLayoutAlign="start stretch" [fxHide]="dependencyOperations[i].hide" *ngFor="let c of fg.controls.update_by_dependency_as.controls; let i = index;">
                    <mat-checkbox value="{{dependencyOperations[i].display}}" [required]="false" [formControl]="c">{{dependencyOperations[i].display}}</mat-checkbox>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row " class="tb-padding lr-padding" fxLayoutGap="1rem" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-form-action-button" type="reset">Reset</button></div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="primary-form-action-button" [disabled]="disableAddButton">{{addLabel}}</button></div>
          <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-form-action-button" (click)="close()" type="button">Cancel</button></div>
        </div>
      </div>
    </div>
  </form>
</div>