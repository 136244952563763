/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChange, SimpleChanges, HostListener } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-sort',
    templateUrl: './sort.template.html'
})

export class sortComponent extends NBaseComponent implements OnInit, OnChanges {
    @Input('sortKey') sortKey;
    @Input('sort') sort = 'ASC';
    @Input('disabled') disabled = false;
    @Output('changeEvent') changeEvent = new EventEmitter<SortChangeEvent>();
    sortCSSFlag: SortOrder = 'ASC';

    constructor() {
        super();
    }

    ngOnInit() {

    }

    @HostListener('keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        console.log(this.sortCSSFlag);
        if (this.sortCSSFlag == 'ASC') {
            this.click('DESC');
        } else {
            this.click('ASC');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sort) {
            if (this.sort && ['ASC', 'DESC'].includes(this.sort)) {
                this.sortCSSFlag = <SortOrder>this.sort;
            }
        }
    }

    click($event: SortOrder) {
        if (this.disabled) return;

        console.log(this.sortKey, $event)
        this.changeEvent.emit({
            key: this.sortKey,
            sort: $event
        });
        this.sortCSSFlag = $event;
    }
}

export interface SortChangeEvent {
	key: string,
	sort: SortOrder
}

export type SortOrder = 'ASC' | 'DESC';