<div fxLayout="column " fxLayoutGap="5px" fxLayoutAlign="  none" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" class="lr-padding border-radius card-like" fxFlex="60px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <h2>
      IMPORT CONTACT HISTORY</h2>
  </div>
  <div fxLayout="column " class="border-radius card-like" fxFlex="60px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row " class="height-100 l-padding width-100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <h3>
        IMPORT LIST</h3>
      <div class="height-100 common-pagination">
        <mat-paginator [length]="totalCount" [pageSize]="pageSize" [pageIndex]="pageOffset" [pageSizeOptions]="[50, 200, 500]" (page)="pageEvents($event)" [disabled]="!ready" class="height-100"></mat-paginator>
      </div>
    </div>
  </div>
  <div fxLayout="column wrap" class="border-radius card-like" fxLayoutGap="1em" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="myfilestatuses &amp;&amp; myfilestatuses.length === 0" fxFill>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <h2 class="light-gray-color">
        No Import Contact History Available</h2>
    </div>
  </div>
  <div fxLayout="column " class="padding-1 border-radius card-like" fxFlex="calc(100% - 130px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="table-header" fxFlex="48px" fxLayoutGap="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
        <div fxLayout="row wrap" class="lr-padding" fxFlex="14.28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngClass.gt-xs]="&apos;lead-header-field-gt-xs&apos;" *ngFor="let header of headers; let i = index;">
          <h3 class="color-view">
            {{header.displayText}}</h3>
          <bh-sort [sortKey]="header.sortKey" [sort]="header.sortValue" (changeEvent)="sortChange($event)" *ngIf="header.sort" [ngClass]="(header.sortKey != currentSortKey) ? &apos;not-sorting-icon&apos; : &apos;&apos;" [disabled]="!ready"></bh-sort>
        </div>
      </div>
    </div>
    <div fxLayout="column " class="padding-1-0 overflow-y" fxFlex="calc(100% - 48px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" class="display-block leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let item of myfilestatuses">
        <div fxLayout="row wrap" class="height-gt-xs color-black" fxFlex="100" fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="14.28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <h3 class="font-normal-size text-ellipsis">
              {{item.file_name}}</h3>
          </div>
          <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="14.28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <h3 class="font-normal-size text-ellipsis">
              {{item.imported_date}}</h3>
          </div>
          <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="14.28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <h3 class="font-normal-size text-ellipsis">
              {{item.valid_contacts}}</h3>
          </div>
          <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="14.28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <h3 class="font-normal-size">
              <!-- {{val.lead_last_updated_at}} -->
              {{item.invalid_contacts}}</h3>
          </div>
          <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="14.28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <h3 class="font-normal-size">
              <!-- {{val.lead_last_updated_at}} -->
              {{item.assigned_contacts}}</h3>
          </div>
          <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxFlex="14.28" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <h3 class="font-normal-size">
              <!-- {{val.lead_last_updated_at}} -->
              {{item.status}}</h3>
          </div>
          <div fxLayout="row wrap" class="height-gt-xs lr-padding" fxFlex="14.28" fxLayoutGap="5px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation matTooltip="Download" (click)="download(item)" tabindex="0" (keydown.enter)="download(item)">
              <div class="download-image">
              </div>
            </div>
            <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation matTooltip="Assign To User" (click)="openAssignContact(item)" tabindex="0" (keydown.enter)="openAssignContact(item)" *ngIf="item.import_status == &apos;CONTACT_FILE_PENDING_ASSIGNMENT&apos;">
              <div class="assign-user-image">
              </div>
            </div>
            <div fxLayout="column wrap" class="cursor-pointer" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" (click)="cancel(item)" click-stop-propagation matTooltip="Cancel" tabindex="0" (keydown.enter)="cancel(item)" *ngIf="item.import_status != &apos;CONTACTS_FILE_ALL_IMPORTED&apos; &amp;&amp; item.import_status != &apos;CONTACTS_FILE_CANCELLED&apos;">
              <div class="delete-image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>