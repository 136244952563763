/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { FormControl, Validators } from '@angular/forms';
import { srmappservice } from 'app/sd-services/srmappservice';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { tenantservice } from 'app/sd-services/tenantservice';
import * as fuzzysort from 'fuzzysort'; // https://www.npmjs.com/package/fuzzysort

interface Config {
    placeholder: string;
    country_codes: Array<{ country_code: string, country_code_display_value: string }>;
    default_country_code?: string;
    required?: boolean;
    hint_message?: string;
    error_message?: string;
}
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dynphoneno',
    templateUrl: './dynphoneno.template.html'
})

export class dynphonenoComponent extends NBaseComponent implements OnInit, OnChanges {
    @Input('phoneCountryCodeControl') phoneCountryCodeControl = new FormControl();
    @Input('fc') fc = new FormControl();
    @Input('placeholder') placeholder = 'Mobile Number';
    @Input('field_name') field_name = '';
    // TODO: Implement [required] for every dyn* comoponent 
    @Input() required = false;
    @ViewChild(MatAutocompleteTrigger) trigger;
    // { country: string, phone_country_code: string }
    phoneCountryCodes: Array<any> = [];
    focusedLabel = false;
    filteredPhoneCountryCodes: Observable<Array<{ country: string, phone_country_code: string }>>;
    displayAutoComplete = false;

    constructor(public srmappS: srmappservice, private tenantService: tenantservice) {
        super();
    }

    ngOnInit() {
        this.srmappS.getPhoneCountryCodes()
            .then(result => {
                this.phoneCountryCodes = result.local.phonecountrycodes;
                this.tenantService.getTenantSettings()
                    .then(result => {

                        if (!this.phoneCountryCodeControl.value && result.local.tenantSettings && result.local.tenantSettings.PHONE_COUNTRY_CODE) {
                            this.phoneCountryCodeControl.setValue(result.local.tenantSettings.PHONE_COUNTRY_CODE);
                        } else {
                            console.log('No default country code set for the tenant');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(e => {
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.phoneCountryCodeControl) {
            this.filteredPhoneCountryCodes = changes.phoneCountryCodeControl.currentValue.valueChanges.pipe(
                startWith(''),
                map(v => this.filter(v))
            );
            this.displayAutoComplete = true;
        }
        if(changes.required?.firstChange && changes.required.currentValue === true) {
            this.fc.setValidators(Validators.required);
        }
    }

    focused() {
        this.focusedLabel = true;
    }

    autoCompleteFocused() {
        if (this.phoneCountryCodeControl.value == '') {
            this.trigger._onChange("");
            this.trigger.openPanel();
        } else if (this.phoneCountryCodeControl.value) {
            this.trigger._onChange(this.phoneCountryCodeControl.value)
            this.trigger.openPanel();
        }
    }

    blurred() {
        this.focusedLabel = false;
    }

    private filter(searchValue) {
        if (searchValue === '') {
            return this.phoneCountryCodes;
        }

        // handling for invalid search string
        if (typeof searchValue != 'string') {
            return this.phoneCountryCodes;
        }

        return fuzzysort.go(searchValue, this.phoneCountryCodes, {
            keys: ['country', 'phone_country_code']
        })
        .map(v => {
            return v.obj;
        })
    }
}
