/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { NPubSubService } from 'neutrinos-seed-services';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { cacheservice } from 'app/sd-services/cacheservice';
import { fileservice } from 'app/sd-services/fileservice';
import { MatSnackBar } from '@angular/material/snack-bar';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-adddocument',
    templateUrl: './adddocument.template.html'
})

export class adddocumentComponent extends NBaseComponent implements OnInit {
    file: any = [];
    file_name: any;
    file_size;
    errorMsg = "";
    tagErrorMsg = "";
    contactid;
    dealid;
    file_id;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    documentTitle;
    disableButton = false;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    tags_Arr = [];

    constructor(private pubsub: NPubSubService,
        private cacheService: cacheservice,
        private fileservice: fileservice,
        private snackBar: MatSnackBar,
    ) {
        super();
        this.documentTitle = "ADD DOCUMENT";
        this.cacheService.getc('CONTACT_ID_VALUE').then((result) => {
            if (result && result.local.data) {
                const d = result.local.data;
                this.contactid = d.contactid;
            }
        })
        this.cacheService.getc('DEAL_ID_VALUE').then((result) => {
            if (result && result.local.data) {
                const d = result.local.data;
                this.dealid = d.dealId;
                this.contactid = d.contactId;
            }
        })
    }

    ngOnInit() {
        this.cacheService.getc('EDIT_DOCUMENT').then((result) => {
            if (result && result.local && result.local.data && result.local.data.activityfileid) {
                this.file_id = result.local.data.activityfileid;
                this.file_name = result.local.data.file_name;
                this.tags_Arr = result.local.data.tags;
                this.documentTitle = "EDIT DOCUMENT TAG(S)";
            }
        }).catch(e => { });

    }

    add(event): void {
        const input = event.input;
        const value = event.value;

        // Add tags to array
        if ((value || '').trim()) {
            this.tags_Arr.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tags_name): void {
        const index = this.tags_Arr.indexOf(tags_name);

        if (index >= 0) {
            this.tags_Arr.splice(index, 1);
        }
    }

    removeFile() {
        this.file = [];
        this.file_name = '';
    }

    handleChange(files) {
        this.file = [];
        if (files && files[0] && files[0].file) {
            this.file = files[0].file;
            this.file_name = this.file.name;
            this.file_size = this.file.size;
        }
        if (this.file && this.file.length != 0) {
            this.errorMsg = "";
            return false;
        }
    }

    fileUpload(files: FileList) {
        this.file = [];
        if (files && files.length) {
            this.file = files[0];
            this.file_name = this.file.name;
            this.file_size = this.file.size;
        }
        if (this.file && this.file.length != 0) {
            this.errorMsg = "";
            return false;
        }
    }


    addDocument() {
        if (this.file && this.file.length === 0) {
            this.errorMsg = "Upload Document";
            return false;
        }
        for (var i = 0; i < this.tags_Arr.length; i++) {
            if (this.tags_Arr.indexOf(this.tags_Arr[i]) !== this.tags_Arr.lastIndexOf(this.tags_Arr[i])) {
                this.tagErrorMsg = "Duplicate tags cannot be inserted";
                return false;
            }
        }
        if (this.file_size >= (1024 * 1024 * 100)) {
            this.errorMsg = "Maximum size for document upload is 100 MB";
            return false;
        }
        if (this.file && this.file.length === 0) {
            this.errorMsg = "Upload Document";
            return false;
        } else {
            this.disableButton = true;
        }

        if (this.documentTitle == "ADD DOCUMENT") {
            let body: FormData = new FormData();
            body.append('document', this.file, this.file.name);
            body.append('contactid', this.contactid);
            if (this.dealid) {
                body.append('dealid', this.dealid);
            }
            this.fileservice.addDocument(body).then((res) => {
                if (res && res.local && res.local.result && res.local.result.data) {
                    this.disableButton = false;
                    if (this.tags_Arr && this.tags_Arr.length) {
                        this.addTags(res.local.result.data.fileid, this.contactid, this.dealid);
                    } else {
                        this.pubsub.$pub('refreshdocumentlist');
                        this.close();
                    }
                }
            })
                .catch(e => {
                    this.disableButton = false;
                    if (e.error.status === 415) {
                        this.snackbarMsg(e.error.error);
                    } else {
                        this.snackbarMsg("File could not be uploaded");
                    }
                });
        }
    }

    updateDocument() {
        for (var i = 0; i < this.tags_Arr.length; i++) {
            if (this.tags_Arr.indexOf(this.tags_Arr[i]) !== this.tags_Arr.lastIndexOf(this.tags_Arr[i])) {
                this.tagErrorMsg = "Duplicate tags cannot be inserted";
                return false;
            }
        }
        this.disableButton = true;
        if (this.documentTitle == "EDIT DOCUMENT TAG(S)") {
            let tempObj = {};
            tempObj['tags'] = this.tags_Arr;
            tempObj['contactid'] = this.contactid;
            if (this.dealid) {
                tempObj['dealid'] = this.dealid;
            }
            this.fileservice.updateFileTags(this.file_id, tempObj).then((res) => {
                if (res) {
                    this.disableButton = false;
                    this.pubsub.$pub('refreshdocumentlist');
                    this.close();
                }
            })
                .catch(e => { });
        }
    }

    addTags(fileid, contactid, dealid) {
        this.disableButton = true;
        this.fileservice.addFileTags(fileid, this.tags_Arr, contactid, dealid).then((res) => {
            if (res) {
                this.disableButton = false;
                this.pubsub.$pub('refreshdocumentlist');
                this.close();
            }
        })
            .catch(e => { });
    }

    close() {
        this.pubsub.$pub('sidenavformevents', { ACTION: 'close' })
    }

    ngOnDestroy() {
        this.cacheService.deletec('EDIT_DOCUMENT');
    }
    snackbarMsg(msg) {
        this.snackBar.open(msg, 'OK', {
            duration: 3000
        })
    }
}
