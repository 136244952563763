/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UtilityMethods as U } from 'app/functions/UtilityMethods';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
	selector: 'bh-optionsconfigurator',
	templateUrl: './optionsconfigurator.template.html'
})

export class optionsconfiguratorComponent extends NBaseComponent implements OnInit, OnDestroy {

	@Input('optionsFormControl') fc: FormControl;
	@Input('header') header = '';
	@Input('optionPlaceholder') placeholder = 'Enter New Option';
	@Input('initialOptions') initialOptions: OptionItem[] = [];
	@Input('setFCErrors') setFCErrors = true;
	@Input('filterInvalidOptions') filterInvalidOptions = true;
	@Input('uniqueValidation') uniqueValidation = true;
	@Input('addBtnLabel') addBtnLabel = 'Add Option';
	@Input('iem') iem = false;
	@Input('labelPath') labelPath = '';
	@Input('disableActions') disableActions = false;
	@Input('insertionIndex') insertionIndex = null;

	// @Input('trackByKeyPath') trackByKeyPath = null;

	@Output('optionSaved') optionSaved = new EventEmitter<OptionEvtData<any>>();
	@Output('optionDeleted') optionDeleted = new EventEmitter<OptionEvtData<any>>();
	@Output('optionsOrdered') optionsOrdered = new EventEmitter<OptionDroppedEvtData>();

	options: Option[] = [];
	valChangeSubs: { [key: string]: Subscription } = {};
	initialized = false;
	editing = false;

	constructor() {
		super();
	}

	ngOnChanges(s: SimpleChanges) {
		if (s.initialOptions?.currentValue && this.initialized) {
			this.options = [];
            if(localStorage.getItem("bulkAddLabel")==null){
			    this.populateExisting(this.initialOptions);
            }
		}
	}

	ngOnInit() {
        if(localStorage.getItem("bulkAddLabel")==null){
            this.populateExisting(this.initialOptions || this.fc.value);
            if (!this.initialOptions) {
                this.addOption();
            }
            this.initialized = true;
        }else{
            
            if (!this.initialOptions) {
                this.addOption();
            }
        }

	}

	populateExisting(vs: OptionItem[]) {
		Array.isArray(vs) && vs.forEach(v => {
			this.addOption(v, v.__optionConfig__, false).save().completeAdding();
		});
	}

	addOption(v: any = null, opConfig: OptionConfig = {}, considerInsertionIndex = true): Option {
		opConfig.labelPath = this.labelPath;
		const o = new Option(v, opConfig);
		if (typeof this.insertionIndex !== 'number' || !considerInsertionIndex) {
			this.options.push(o);
		} else {
			U.insertAt(this.options, this.insertionIndex % this.options.length, o);
		}
		this.addOptFC(o);
		this.setFCErrsAndVal();
		return o;
	}

	drop(e: CdkDragDrop<Option[]>) {
		const fromIdx = e.previousIndex;
		const toIdx = e.currentIndex;
		if (fromIdx !== toIdx && this.options[fromIdx].sortable && this.options[toIdx].sortable) {
			moveItemInArray(this.options, fromIdx, toIdx);
			this.setFCErrsAndVal(true);
			const d = this._computeAndSetFCVal()?.map((o, i) => this._getEventObj(o, i));
			this.optionsOrdered.next(d);
		}
	}

	removeOption(i: number) {
		this.removeOptFC(i);
		const evtObj = this._getEventObj(this.options[i], i);
		U.deleteAtIdx(this.options, i);
		this.triggerCustomValidationForAllOpts();
		this.setFCErrsAndVal();
		this._computeAndSetFCVal();
		this.optionDeleted.next(evtObj);
	}

	addOptFC(o: Option) {
		this.valChangeSubs[o.uid] = o.ifc.valueChanges.subscribe(v => {
			this.triggerCustomValidationForAllOpts();
			this.setFCErrsAndVal();
		});
	}

	removeOptFC(i: number) {
		this.valChangeSubs[this.options[i].uid]?.unsubscribe();
	}

	private _getEventObj(option: Option, i: number): OptionEvtData<any> {
		return {
			value: option.value,
			display: option.display,
			isNew: option.adding,
			index: i,
			rawOptions: this.options.map(o => o.serializeable),
		};
	}

	triggerCustomValidationForAllOpts() {
		this.options.forEach(o => {
			const dup = this.uniqueValidation ? this.duplicate(o.display, o.uid) : null;
			if (o.ifc.errors) {
				if (o.ifc.errors.duplicate && !dup) {
					delete o.ifc.errors.duplicate;
				}
				if (Object.keys(o.ifc.errors).length > 0) {
					const e = { ...o.ifc.errors, ...dup }
					o.ifc.setErrors(e);
				} else {
					o.ifc.setErrors(null);
				}
			} else if (dup) {
				o.ifc.setErrors(dup);
			}
		});
	}

	private setFCErrsAndVal(skipErrorCalc = false) {
		let errors = null;
		if (!skipErrorCalc && this.setFCErrors) {
			errors = this._getFCErrs();
		}
		if (!this.iem) {
			this._computeAndSetFCVal(true);
		}
		if (!skipErrorCalc && this.setFCErrors) {
			this.fc.setErrors(errors);
		}
	}

	private _getFCErrs() {
		let errors = null;
		const required = !this.options || !this.options.length || this.options.some(v => v.ifc.errors?.required);
		const duplicate = this.options.some(v => v.ifc.errors?.duplicate);
		if (required) {
			errors = { required: true }
		}
		if (duplicate) {
			errors = errors || {};
			errors.duplicate = true;
		}
		return errors;
	}

	enterEditMode(i: number) {
		if (this.iem) {
			this.options[i].editing = true;
		}
	}

	saveOption(i: number) {
        if (this.options[i].ifc.value === ' ') {
            // this.options[i].ifc.setErrors({'whitespace': true}) ;
        } else {
            if (this.options[i].ifc.valid) {
                this.options[i].save();
                this.optionSaved.next(this._getEventObj(this.options[i], i));
                this.options[i].completeAdding();
                this._computeAndSetFCVal();
            }
        }
	}

	_computeAndSetFCVal(save = false) {
		if (save) {
			this.options.forEach(o => o.save());
		}
		const addedOpts = this.iem ? this.options.filter(o => !o.adding) : this.options;
		const filteredOpts = this.filterInvalidOptions ? addedOpts.filter(o => o.valid) : addedOpts;
		const finalOpts = filteredOpts.map(o => o.serializeable);
		const val = finalOpts?.length ? finalOpts : null;
		this.fc.setValue(val);
		return filteredOpts;
	}

	readOnly(option: Option) {
		return !option.editing && !option.adding;
	}

	disabledLooking(option: Option) {
		return {
			'disabled-appearance': this.readOnly(option)
		}
	}

	duplicate(value: string, uid: string): { duplicate: boolean } | null {
		const farray = this.options;
		for (let i = 0; i < farray.length; i++) {
			if (farray[i].display === value && farray[i].uid !== uid) {
				return { duplicate: true }
			}
		}
		return null;
	}

	ngOnDestroy() {
		Object.values(this.valChangeSubs).forEach(s => s.unsubscribe());
	}
}

class Option {

	uid = U.generateUUID();
	ifc: FormControl;
	value: any = null;
	editing = false;
	adding = true;

	// configs
	editable = true;
	deletable = true;
	sortable = true;

	private _labelPath = '';

	constructor(value: any, config: OptionConfig) {
		this._assignConfig(config);
		this.value = value;
		this.ifc = new FormControl(U.getValFromKeyPath(value, this._labelPath), [ Validators.required, this.noWhitespaceValidator]);
		// TODO: @sankarshanaj - Subscribe to fc valueChanges and update the value[...labelPath...] with the fc's value
	}
    private noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
	private _assignConfig(config: OptionConfig) {
		config = Object.assign({
			labelPath: '',
			editable: true,
			deletable: true,
			sortable: true,
		}, config);
		this._labelPath = config.labelPath;
		this.editable = config.editable;
		this.deletable = config.deletable;
		this.sortable = config.sortable;
	}

	get display() {
		return this.ifc.value;
	}

	get valid() {
		return this.ifc.valid;
	}

	save() {
		this.editing = false;
		if (this._labelPath) {
			this.value = U.setValForKeyPath(this.value || {}, this._labelPath, this.display);
		}
		return this;
	}

	completeAdding() {
		this.adding = false;
	}

	get serializeable(): SerializeableOption {
		return {
			value: this.value,
			display: this.display,
			editing: this.editing,
			adding: this.adding,
			valid: this.valid,
		}
	}
}

export interface OptionEvtData<T> {
	value: T,
	display: string,
	index: number,
	isNew: boolean,
	rawOptions: SerializeableOption[],
}

export interface SerializeableOption {
	value: any,
	display: string,
	editing: boolean,
	adding: boolean,
	valid: boolean,
}

export interface OptionConfig {
	labelPath?: string,
	editable?: boolean,
	deletable?: boolean,
	sortable?: boolean,
}

export interface OptionItem {
	__optionConfig__: OptionConfig,
	[key: string]: any
}

export type OptionDroppedEvtData = OptionEvtData<any>[]; 