<div fxLayout="column " fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="summary">
  <div fxLayout="column wrap" class="padding-0-1 border-light-gray" fxFlex="48px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <h3 class="font-weight-size">
        {{summary.title}}</h3>
      <h3 *ngIf="summary &amp;&amp; summary.list" class="font-weight-size">
        {{summary.list.length &gt; 9 ? &quot;&quot; + summary.list.length : &quot;0&quot; + summary.list.length}}</h3>
    </div>
  </div>
  <div fxLayout="column " class="display-block overflow-y padding-1" fxFlex="calc(100% - 48px)" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="summary.list &amp;&amp; summary.list.length != 0">
    <div fxLayout="column " class="cursor-pointer summary-list-card" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let value of summary.list">
      <div fxLayout="row wrap" class="width-100" fxFlex="100" fxLayoutGap="12px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" style="height: 60px;" fxFlex="18" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
          <div fxLayout="column wrap" class="summary-outer-circle" fxFlex="76" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="((summary.type_name == &apos;task&apos;) || (summary.type_name == &apos;due_task&apos;))">
            <div class="summary-task-image">
            </div>
          </div>
          <div fxLayout="column wrap" class="summary-outer-circle" fxFlex="82" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="((summary.type_name == &apos;appointment&apos;) || (summary.type_name == &apos;event&apos;) &amp;&amp; !value.hasOwnProperty(&apos;activitycallid&apos;))">
            <div class="summary-appointment-image">
            </div>
          </div>
          <div fxLayout="column wrap" class="summary-outer-circle" fxFlex="76" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="value.hasOwnProperty(&apos;activitycallid&apos;)">
            <div class="summary-call-image">
            </div>
          </div>
        </div>
        <div fxLayout="column " fxFlex="74" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="(value.contact_name.length &gt; 2 || value.deal_name) ? &apos;&apos; : &apos;list-margin-top&apos;">
          <h5 *ngIf="value.event_title" (click)="getsummary(value, summary)" class="text-ellipsis font-primary-color">
            {{value.event_title}}</h5>
          <div fxLayout="row " class="text-ellipsis" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <h6 *ngIf="value.contact_name.length &gt; 2 || value.deal_name" style="margin-right: 5px;">
              Related to: </h6>
            <h6 *ngIf="value.contact_name">
              {{value.contact_name}}</h6>
            <h6 *ngIf="value.contact_name.length &gt; 2 &amp;&amp; value.deal_name" style="margin: 2px 10px 2px 10px!important;" class="border-align-view">
            </h6>
            <h6 *ngIf="value.deal_name" class="text-ellipsis">
              {{value.deal_name}}</h6>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
            <h6 *ngIf="value.event_title" class="text-value-label">
              <img class="clock-icon clock-image" src="assets/Web/Icons/clock.svg"> <span>{{value.start_time | date: &apos;dd&apos;}} - {{value.start_time | date:&apos;MM&apos;}} - {{value.start_time | date: &apos;yyyy&apos;}}, {{value.start_time | date: &apos;hh:mm aa&apos;}}</span><span *ngIf="value.end_time"> - {{value.end_time | date: &apos;hh:mm aa&apos;}}</span><br>
            </h6>
            <mat-checkbox [(ngModel)]="value.task_status" (change)="updateTaskStatusToDone(value)" [required]="false" matTooltip="{{value.task_status ? &apos;Mark task as incomplete&apos;: &apos;Mark task as complete&apos;}}" *ngIf="((summary.type_name == &apos;task&apos;) || (summary.type_name == &apos;due_task&apos;))"></mat-checkbox>
          </div>
          <div fxLayout="row " fxFlex="35" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="summary.type_name == &apos;event&apos;"><button mat-raised-button class="h5 width-fit-content add-feedback-btn" color="var(--primary-color)" (click)="addFeedback(value)">Add Feedback</button></div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="overflow-y padding-1" fxFlex="calc(100% - 48px)" fxLayoutGap="8px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="summary.list &amp;&amp; summary.list.length == 0">
    <div fxLayout="column wrap" class="margin-bottom-1" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxLayoutGap="1em" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" fxFill><img [src]="&apos;assets/Web/Icons/&apos;+summary.image_name+&apos;.svg&apos;">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <h4 class="light-gray-color">
            No {{summary.title | titlecase}} Available</h4>
        </div>
      </div>
    </div>
  </div>
</div>