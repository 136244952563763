<div class="task-line-height all-activities-details">
  <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/user_icon.svg"></div>
    <div>
      {{activity.user_name}}</div>
    <div fxLayout="row wrap" class="circle-icon" fxLayoutGap="5px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/clock_icon.svg" class="circle-icon"></div>
    <div>
      {{activity.created_at | date: &apos;dd&apos;}} - {{activity.created_at | date:&apos;MM&apos;}} - {{activity.created_at | date: &apos;yyyy&apos;}}, {{activity.created_at | date: &apos;HH:mm&apos;}}</div>
  </div><span class="appointment-details-label" *ngIf="activity?.deal_name"><span>{{dealNameLabel | titlecase}}: </span><span class="font-normal-size">{{activity?.deal_name}}</span><br></span>
  <div fxLayout="row wrap" fxLayoutAlign="start start" [fxShow]="true" [fxHide]="false">
    <span class="appointment-details-label padding-0">Completed: {{activity.task_status == true ? &apos;Yes&apos; : &apos;No&apos;}}</span>
    <div *ngIf="(activity?.contact_owner == userservice?.user?.srmUserid)" style="margin: 2px 2px 0px 14px;" (click)="editTask(activity)" class="edit-feedback-image"></div>
  </div>
  <span class="appointment-details-label">Due Date: </span>{{activity.due_date | date: &apos;dd&apos;}} - {{activity.due_date | date:&apos;MM&apos;}} - {{activity.due_date | date: &apos;yyyy&apos;}}, {{activity.due_date | date: &apos;HH:mm&apos;}}<br>
  <span class="appointment-details-label">Description: </span><span class="common-word-wrap">{{activity.task_description}}</span><br>
</div>