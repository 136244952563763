<div fxLayout="column wrap" fxLayoutGap="1em" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="mydeals.length === 0" fxFill>
  <div class="no-deal-image">
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <h2 class="light-gray-color">
      No {{label.deal | titlecase}} Available</h2>
  </div>
</div>
<div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="mydeals.length &gt; 0" [ngClass.xs]="&apos;circle-padding absolute-position width-100&apos;" [ngStyle.gt-xs]="&apos;padding: 10px 0px 10px 0px;&apos;">
  <div fxLayout="column " class="overflow-y" fxFlex="calc(100% - 48px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.xs]="&apos;border-radius-4 circle-padding background-primary&apos;">
    <div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div class="height-100">
        <table class="height-100">
          <div fxLayout="column wrap" style="height: 60px;" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <tr class="lead-list-header">
              <!-- <mat-checkbox></mat-checkbox> -->
              <div fxlayout="row wrap" fxflex="5" fxlayoutalign="center center" class="lr-padding lead-header-field-gt-xs ng-star-inserted" ng-reflect-fx-layout="row wrap" ng-reflect-fx-layout-align="center center" ng-reflect-fx-flex="5" ng-reflect-fx-show="true" ng-reflect-fx-hide="false" ng-reflect-ng-class.gt-xs="lead-header-field-gt-xs" style="flex-flow: row wrap; box-sizing: border-box; display: flex; place-content: center; align-items: center; flex: 1 1 5%; max-width: 5%;">
                <mat-checkbox color="var(--color-white)" (change)="selectAll($event)" [(ngModel)]="selectAllCheck" class="mat-checkbox select-all-check mat-var(--color-white) ng-untouched ng-pristine ng-invalid" ng-reflect-color="var(--color-white)" ng-reflect-model="false" ng-reflect-checked="false" ng-reflect-required="true" ng-reflect-disabled="false" ng-reflect-is-disabled="false" [disabled]="disableCheck" id="mat-checkbox-1" required style="padding-top: 10px;padding-left: 10px;"></mat-checkbox>
              </div>
              <th fxLayout="row" class="lr-padding" fxFlex="16" [fxFlex.sm]="header.displayText != &apos;Last updated date&apos; ? &apos;15&apos; : &apos;24&apos;" fxLayoutAlign="start center" *ngFor="let header of headers">

                <h3 class="color-view">
                  {{header.displayText}}</h3>
                <bh-sort [sortKey]="header.sortKey" [sort]="header.sortValue" (changeEvent)="sortChange($event)" *ngIf="header.sort" [ngClass]="(header.sortKey != currentSortKey) ? &apos;not-sorting-icon&apos; : &apos;&apos;">
                </bh-sort>
              </th>
            </tr>
          </div>
          <div fxLayout="column" style="height: calc(100% - 60px);" class="overflow-y display-block" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <tr fxLayout="row " class="leads-border-bottom-gt-xs cursor-pointer" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let deal of mydeals" (click)="navigateToDeal(deal.dealid, deal.contactid)">
              <td fxLayout="row " class="lr-padding leads-height-gt-xs" fxFlex="5" [fxFlex.sm]="5" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                <h3 class="font-normal-size text-ellipsis">
                  <div fxlayout="row wrap" fxflex="5" fxlayoutalign="center center" click-stop-propagation class="lr-padding leads-height-gt-xs ng-star-inserted" ng-reflect-fx-layout="row wrap" ng-reflect-fx-layout-align="center center" ng-reflect-fx-flex="5" ng-reflect-fx-show="true" ng-reflect-fx-hide="false" ng-reflect-fx-hide.xs="true" style="flex-flow: row wrap; box-sizing: border-box; display: flex; place-content: center; align-items: center; flex: 1 1 5%; max-width: 5%;">
                    <mat-checkbox (change)="onRowClick($event, deal.dealid, deal.deal_created_by)" [(ngModel)]="deal.isSelected" class="mat-checkbox reassign-checkbox mat-accent ng-untouched ng-pristine ng-invalid" ng-reflect-model="false" ng-reflect-required="true" ng-reflect-disabled="false" ng-reflect-is-disabled="false" required [disabled]="disableCheck"></mat-checkbox>
                  </div>
                </h3>
              </td>
              <td fxLayout="row " class="lr-padding leads-height-gt-xs display-grid" fxFlex="16" [fxFlex.sm]="15" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                <!-- <mat-checkbox click-stop-propagation [checked]="true" [color]="primary" ></mat-checkbox> -->
                <h3 class="font-normal-size text-ellipsis">
                  <div class="text-ellipsis" *ngIf="deal.deal_name">{{deal.deal_name}}</div>
                  <div *ngIf="!deal.deal_name" class="label-font-color">-------</div>
                </h3>
              </td>
              <td fxLayout="row " class="lr-padding leads-height-gt-xs" fxFlex="16" [fxFlex.sm]="15" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                <h3 class="font-normal-size text-ellipsis">
                  <div *ngIf="deal.contact_display_name">{{deal.contact_display_name}}</div>
                  <div *ngIf="!deal.contact_display_name" class="label-font-color">-------</div>
                </h3>
              </td>
              <td fxLayout="row " class="lr-padding leads-height-gt-xs" fxFlex="16" [fxFlex.sm]="15" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                <h3 class="font-normal-size text-ellipsis">
                  <div *ngIf="deal.currencyid || deal.deal_value">{{deal.currencyid | currencySymbol | async}}
                    {{deal.deal_value}}</div>
                  <div *ngIf="!deal.currencyid &amp;&amp; !deal.deal_value" class="label-font-color">-------</div>
                </h3>
              </td>
              <td fxLayout="row " class="lr-padding leads-height-gt-xs" fxFlex="16" [fxFlex.sm]="15" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                <h3 class="font-normal-size">
                  <div *ngIf="deal.deal_pipe_name">{{deal.deal_stage_name}}</div>
                  <div *ngIf="!deal.deal_pipe_name" class="label-font-color">-------</div>
                </h3>
              </td>
              <td fxLayout="row " class="lr-padding leads-height-gt-xs" fxFlex="16" [fxFlex.sm]="21" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                <h3 class="font-normal-size">
                  <!-- {{val.lead_last_updated_at}} -->
                  <div *ngIf="deal.deal_updated_at">{{deal.deal_updated_at | date: &apos;dd&apos;}} -
                    {{deal.deal_updated_at | date:&apos;MM&apos;}} -
                    {{deal.deal_updated_at | date: &apos;yyyy&apos;}},
                    {{deal.deal_updated_at | date: &apos;HH:mm&apos;}}</div>
                  <div *ngIf="!deal.deal_updated_at" class="label-font-color">-------</div>
                </h3>
              </td>
              <td fxLayout="row " class="lr-padding leads-height-gt-xs" fxFlex="16" [fxFlex.sm]="15" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
                <div fxLayout="column " class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation (click)="initialCall(deal)" matTooltip="Call">
                  <div click-stop-propagation (click)="initialCall(deal)" class="add-call-image">
                  </div>
                </div>
                <div fxLayout="column " class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation (click)="createTask(deal)" matTooltip="Add Task">
                  <div class="common-lead-task-image add-task-image">
                  </div>
                </div>
                <div fxLayout="column " class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation (click)="scheduleAppointment(deal)" matTooltip="Add Appointment">
                  <div class="add-appointment-image">
                  </div>
                </div>
              </td>
            </tr>
            <div id="common-pagination-mydeals" class="common-pagination pagination-gt-xs" [ngClass.sm]="deal-page-label">
              <mat-paginator [length]="totalCountP" [pageSize]="pageSizeP" [pageIndex]="pageOffsetP" [pageSizeOptions]="[50, 200, 500]" (page)="pageEvents($event)" [disabled]="!readyP"></mat-paginator>
            </div>

          </div>
        </table>
      </div>
    </div>
    <div fxLayout="column " class="height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
      <div class="overlay-scroll height-100 search-results">
        <cdk-virtual-scroll-viewport infinite-scroll style="height: 100%" [itemSize]="187" [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="200" [scrollWindow]="false" (scrolled)="onScroll()">
          <div fxLayout="column wrap" class="display-block leads-border-bottom-gt-xs" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *cdkVirtualFor="let deal of mydeals" (click)="navigateToDeal(deal.dealid, deal.contactid)" [ngClass.xs]="&apos;menu-margin-xs&apos;">
            <div fxLayout="row wrap" class="cursor-pointer color-black" fxFlex="100" fxLayoutAlign="space-evenly stretch" [fxShow]="true" [fxHide]="false" *ngIf="deal" [ngClass.xs]="&apos;border-lightgray-1px border-radius-4 mobile-tap-view&apos;">
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs display-grid" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Name : </span>{{deal.deal_name}}</span>
                  <span *ngIf="!deal.deal_name"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Name : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.contact_display_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Contact Name : </span>{{deal.contact_display_name}}</span>
                  <span *ngIf="!deal.contact_display_name"><span class="font-weight-size" [fxHide.gt-xs]="true">Contact Name : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.currencyid || deal.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Value : </span>{{deal.currencyid | currencySymbol | async}}
                  {{deal.deal_value}}</span>
                  <span *ngIf="!deal.currencyid &amp;&amp; !deal.deal_value"><span class="font-weight-size" [fxHide.gt-xs]="true"> {{label.deal | titlecase}} Value : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size text-ellipsis">
                  <span *ngIf="deal.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true">{{label.deal | titlecase}} Stage : </span>{{deal.deal_stage_name}}</span>
                  <span *ngIf="!deal.deal_pipe_name"><span class="font-weight-size" [fxHide.gt-xs]="true"> {{label.deal | titlecase}} Stage : </span><span class="label-font-color">-------</span></span>
                </h3>
              </div>
              <div fxLayout="row wrap" class="lr-padding leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngStyle.xs]="&apos;height: 10%;padding: 4px 8px;&apos;">
                <h3 class="font-normal-size">
                  <!-- {{val.lead_last_updated_at}} -->
                  <span *ngIf="deal.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date : </span>{{deal.deal_updated_at | date: &apos;dd&apos;}}
                  -
                  {{deal.deal_updated_at | date:&apos;MM&apos;}} -
                  {{deal.deal_updated_at | date: &apos;yyyy&apos;}},
                  {{deal.deal_updated_at | date: &apos;HH:mm&apos;}}</span>
                  <span *ngIf="!deal.deal_updated_at"><span class="font-weight-size" [fxHide.gt-xs]="true">Last Updated Date: </span><span class="label-font-color">------</span>
                  </span></h3>
              </div>
              <div fxLayout="row wrap" class="leads-height-gt-xs" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxFlex.xs]="100" [fxFlex.gt-xs]="16" [ngClass.gt-xs]="&apos;lr-padding&apos;" [ngStyle.xs]="&apos;border-top: 1px solid #b8bdc7&apos;">
                <div fxLayout="column " class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation (click)="initialCall(deal)" [fxFlex.xs]="33.3" fxLayoutAlign.xs="center center" [ngStyle.xs]="&apos;border-right: 1px solid  #b8bdc7&apos;">
                  <div click-stop-propagation (click)="initialCall(deal)" class="add-call-image">
                  </div>
                </div>
                <div fxLayout="column " class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation (click)="createTask(deal)" [fxFlex.xs]="33.3" fxLayoutAlign.xs="center center" [ngStyle.xs]="&apos;border-right: 1px solid  #b8bdc7&apos;">
                  <div class="common-lead-task-image add-task-image">
                  </div>
                </div>
                <div fxLayout="column " class="height-gt-xs" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" click-stop-propagation (click)="scheduleAppointment(deal)" [fxFlex.xs]="33.3" fxLayoutAlign.xs="center center">
                  <div class="add-appointment-image">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</div>