<div fxLayout="column " class="overflow-y background-primary height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true">
  <div fxLayout="column wrap" class="width-100" fxFlex="40px" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" class="padding-0-1 width-100" fxFlex="100" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="70" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/sort_icon.svg">
          <h2>
            SORT BY</h2>
        </div>
      </div>
      <div fxLayout="row wrap" fxFlex="30" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
          <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <mat-divider>
  </mat-divider>
  <div fxLayout="column " class="overflow-y padding-1" fxFlex="calc(100% - 45px);" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let option of data.sortlist; let i = index">
        <div fxLayout="row wrap" class="border-bottom" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" (click)="selectedOption(option)" [ngClass]="(sortValue == option) ? &apos;light-mid-blue-color&apos; : &apos;&apos;">
          <h3 style="padding: 14px 0px 6px 0px;">
            {{option.name}}</h3>
        </div>
      </div>
    </div>
    <div fxLayout="row " class="add-lead-content" fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="color-view add-button" (click)="sortColumn()">Apply</button></div>
      <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="cancel-button color-view" (click)="close()" type="button">Cancel</button></div>
    </div>
  </div>
</div>