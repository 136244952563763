<mat-menu #notesList class="mobile-notes" xPosition="after" #menu="matMenu"><button mat-menu-item (click)="notesView()" class="color-black common-menu-item-align font-Weight-size font-Family"> <span><img class="common-add-list-images" imgSrc="/Web/Icons/add_deal_icon.svg">View Notes</span> </button></mat-menu>
<div fxLayout="column " fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill>
  <div fxLayout="column wrap" fxFlex="125px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center center" [ngClass.xs]="&apos;background-secondary&apos;" [ngStyle.xs]="&apos;height: 74px;border-radius: 0px 0px 24px 24px;padding-top: 14px;&apos;" [ngStyle.gt-xs]="&apos;height: 65px&apos;" [fxHide.gt-xs]="true">
    <div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center center" [ngClass.xs]="&apos;width-100 detail-header-xs&apos;" [ngStyle.xs]="{&apos;display&apos;: (!shownotes) ? &apos;flex&apos; : &apos;none&apos;}">
      <div fxLayout="row wrap" style="height:60px" class="notes-header lr-padding card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true" [ngStyle.xs]="&apos;min-height: 80px;width: 94%;border-radius: 6px 6px 0px 0px;&apos;">
        <div fxLayout="column wrap" fxFlex="90" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center start">
          <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div fxLayout="row " class="width-100" fxFlex="100" fxLayoutGap="6px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
              <div fxLayout="column wrap" fxFlex="10" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><img imgSrc="/Web/Icons/deal_2x_icon.svg" *ngIf="dealdetails &amp;&amp; dealdetails.deal_name"></div>
              <div fxLayout="column wrap" fxFlex="90" fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
                <h2 class="width-100 text-ellipsis">
                  {{dealdetails?.deal_name | uppercase}}</h2>
                <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="dealdetails">
                  <div fxLayout="row " fxLayoutGap="5px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;border-right: 1px solid #dedede;padding-right: 5px;&apos;"><img imgSrc="Web/Icons/user_profile_icon.svg" *ngIf="dealdetails.contact_display_name">
                    <h5>
                      <span (click)="contactClicked()" class="nav-link">{{dealdetails?.contact_display_name}}</span></h5>
                  </div>
                  <div fxLayout="row " fxLayoutGap="5px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;border-right: 1px solid #dedede;padding-right: 5px;&apos;"><img imgSrc="Web/Icons/contact_icon.svg" *ngIf="dealdetails.contact_owner_name">
                    <h5>
                      {{dealdetails?.contact_owner_name}}</h5>
                  </div>
                  <div fxLayout="row " fxLayoutGap="5px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="Web/Icons/id_icon.svg" *ngIf="dealdetails.dealid">
                    <h5>
                      {{dealdetails?.dealid}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" class="padding-1-0" fxFlex="10" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
          <mat-icon class="font-primary-color" [matMenuTriggerFor]="notesList">more_vert</mat-icon>
        </div>
      </div>
      <div fxLayout="row wrap" style="height: 40px;" class="padding-0-1 card-like" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;min-height: 40px;width: 94%;border-radius: 0px 0px 6px 6px;&apos;">
        <bh-pipeline class="width-100" [pipeline]="pipeline" [statuses]="pipelinestatuses" [selected_pipe_name]="selected_pipe_name" [selected_status]="selected_status" (statusChangeEvent)="statusChange($event)" (pipeChangeEvent)="updateDealPipe($event)" [accessDenied]="accessDenied"></bh-pipeline>
      </div>
    </div>
  </div>
  <div fxLayout="column wrap" class="card-like" fxFlex="120px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
    <div fxLayout="row wrap" class="lr-padding" fxFlex="60px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <div fxLayout="column wrap" fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxFlex.sm]="30">
        <div fxLayout="row wrap" class="width-100" fxLayoutGap="5px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false"><img imgSrc="/Web/Icons/deal_2x_icon.svg">
          <h2 class="text-ellipsis width-100">
            {{dealdetails?.deal_name}}</h2>
        </div>
      </div>
      <div fxLayout="row wrap" fxFlex="50" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false" [ngStyle.sm]="&apos;flex-wrap: nowrap&apos;" [fxFlex.sm]="70">
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <h2>
            Contact: <span (click)="contactClicked()" class="nav-link">{{dealdetails?.contact_display_name}}</span> {{label.deal | titlecase}} Owner: {{dealdetails?.contact_owner_name}}</h2>
          <h2 style="text-align:right;">
            {{label.deal | titlecase}} ID: {{dealdetails?.dealid}}</h2>
        </div>
        <div fxLayout="column wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="margin-left-1 action-button" matTooltip="Call" *ngIf="!accessDenied" (click)="initialCall()">
              <div class="call-image"></div>
            </button><button mat-button class="margin-left-1 action-button" matTooltip="Delete" *ngIf="canDelete" (click)="deleteDeal()">
              <div class="document_delete"></div>
            </button></div>
        </div>
      </div>
    </div>
    <div fxLayout="column wrap" fxFlex="60px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
      <bh-pipeline [pipeline]="pipeline" [statuses]="pipelinestatuses" [selected_pipe_name]="selected_pipe_name" [selected_status]="selected_status" (statusChangeEvent)="statusChange($event)" (pipeChangeEvent)="updateDealPipe($event)" [accessDenied]="accessDenied"></bh-pipeline>
    </div>
  </div>
  <div fxLayout="column wrap" fxFlex="calc(100% - 125px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;padding-top: 12px;margin-top: 24px;&apos;" [ngClass.xs]="(!shownotes) ? &apos;&apos; : &apos;notes-position-xs&apos;">
    <div fxLayout="row wrap" class="width-100 relative-position height-100" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" fxLayoutAlign.xs="center center">
      <div fxLayout="column " class="overflow-y height-100" fxLayoutGap="5px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="shownotes" [fxFlex.gt-xs]="69" [fxFlex.xs]="94" [ngClass]="(!shownotes) ? &apos;display-block&apos; : &apos;&apos;">
        <div fxLayout="column " class="summary-element" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass]="(panelView == &apos;open&apos;) ? &apos;height-100&apos; : &apos;&apos;">
          <bh-summary class="height-100" [config]="summaryConfig" (editButtonClicked)="editSummary($event)" [media_flex]="activeMediaQuery" (panelChange)="panelChange($event)"></bh-summary>
        </div>
        <div fxLayout="column wrap" style="flex-grow: 1;" class="activity-min-height border-radius card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [ngClass.sm]="&apos;activity-tab-sm&apos;">
          <div fxLayout="row wrap" fxLayoutAlign="end end" [fxShow]="true" [fxHide]="false" [ngStyle.xs]="&apos;width: 100%&apos;" fxLayoutAlign.xs="center center" [ngClass.gt-xs]="&apos;pagination-align-gt-xs&apos;" *ngIf="!accessDenied" [fxHide.xs]="true"><button mat-raised-button style="    margin-top: 1.2em;     margin-right: 1em;" *ngIf="selectedIndex == 2" (click)="openNewEmail()"><span class="material-icons"> add </span> New Email</button><button mat-button style="    color: #f3e5e5;    margin-right: 10px;" class="action-button" *ngIf="selectedIndex == 2" (click)="getNewEmail()"><span class="material-icons"> refresh </span> </button><button mat-button style="  margin-top: 1.2em; margin-right: 1em;" class="action-button" matTooltip="Add Document" *ngIf="selectedIndex == 0" (click)="add_document()">
              <div class="add-notes-image"></div>
            </button></div>
          <mat-tab-group mat-align-tabs="start" class="document-tab h3-tab-label height-100 lead-details-tabs" [(selectedIndex)]="selectedIndex" [ngClass.xs]="&apos;details-mobile-tab&apos;" animationDuration="0" (selectedTabChange)="changeTab($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                <span [ngClass]="(documentArr.length) ? &apos;mat-tab-badge&apos; : &apos;mat-badge-none&apos;" matBadge="{{documentArr.length}}" matBadgeOverlap="false">DOCUMENTS</span>
                <mat-icon class="display-none">home</mat-icon>
              </ng-template>
              <bh-documents [documentsArr]="documentArr" (download_file_id)="downloadDocument($event.value)" [accessDenied]="accessDenied"></bh-documents>
            </mat-tab>
            <mat-tab label="EXTERNAL LINKS">
              <bh-externallinks class="height-100" [links]="externallinks" *ngIf="!accessDenied"></bh-externallinks>
            </mat-tab>
            <mat-tab label="EMAIL">
              <bh-emailist [emails]="emails" *ngIf="selectedIndex == 2"></bh-emailist>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="30" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="!shownotes" [fxFlex.xs]="94" [ngClass.xs]="&apos;height-100&apos;" [ngClass.gt-xs]="&apos;deal-detais-notes-wrp&apos;">
        <bh-notes class="height-100" [notes]="notes" (newNote)="addNote($event)" (deletedNote)="deleteNote($event)" (editedNote)="updateNote($event)" (exitNotes)="backtosummary()" (editedPinned)="updatePinnednote($event)" (editedUnpinned)="updateUnpinnednote($event)"></bh-notes>
      </div>
    </div>
  </div>
</div>