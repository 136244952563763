/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class appointmentservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_appointmentservice

  async createAppointment(appointmentPayload: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          appointmentPayload: appointmentPayload
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_OZmNIJmZ3bCjVHrt(bh);
      //appendnew_next_createAppointment
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_2sKbkMh0WpPRWETy');
    }
  }

  async getAppointments(
    start_time: any = undefined,
    end_time: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          start_time: start_time,
          end_time: end_time
        },
        local: {
          appointmentData: undefined
        }
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_3oXhcQv0deXvzNgG(bh);
      //appendnew_next_getAppointments
      return (
        // formatting output variables
        {
          input: {},
          local: {
            appointmentData: bh.local.appointmentData
          }
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_exCV273yY4HE24BP');
    }
  }

  async updateAppointment(
    appointmentid: any = undefined,
    appointmentData: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          appointmentid: appointmentid,
          appointmentData: appointmentData
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_ecdJVpopAZSBHk2P(bh);
      //appendnew_next_updateAppointment
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_JeuxqWDh3XIpr53D');
    }
  }

  async deleteAppointment(
    appointmentid: any = undefined,
    contactid: any = undefined,
    send_email: any = undefined,
    ...others
  ) {
    try {
      var bh = {
        input: {
          appointmentid: appointmentid,
          contactid: contactid,
          send_email: send_email
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_MiooVeaUQBAl6ZwZ(bh);
      //appendnew_next_deleteAppointment
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_WJqnd3ZGy6vhYwtD');
    }
  }

  async getRoomID(result: any = undefined, ...others) {
    try {
      var bh = {
        input: {
          result: result
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_MPihcjstyNgoURvS(bh);
      //appendnew_next_getRoomID
      return (
        // formatting output variables
        {
          input: {
            result: bh.input.result
          },
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_CgId1gy5jzqXbEAq');
    }
  }

  //appendnew_flow_appointmentservice_Start

  async sd_OZmNIJmZ3bCjVHrt(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'appointments';

      bh = await this.sd_bJuMbaXoETzMHKFy(bh);
      //appendnew_next_sd_OZmNIJmZ3bCjVHrt
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_OZmNIJmZ3bCjVHrt');
    }
  }

  async sd_bJuMbaXoETzMHKFy(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'post',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.appointmentPayload
      };
      bh.local.queryResult = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_bJuMbaXoETzMHKFy
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_bJuMbaXoETzMHKFy');
    }
  }

  async sd_3oXhcQv0deXvzNgG(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'appointments?';

      if (bh.input.start_time) {
        bh.url += '&start_time=' + bh.input.start_time.replace('+', '%2B');
      }

      if (bh.input.end_time) {
        bh.url += '&end_time=' + bh.input.end_time.replace('+', '%2B');
      }

      bh = await this.sd_ZiFaPW0EqQD0pkZz(bh);
      //appendnew_next_sd_3oXhcQv0deXvzNgG
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3oXhcQv0deXvzNgG');
    }
  }

  async sd_ZiFaPW0EqQD0pkZz(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.local.appointmentData = await this.sdService.nHttpRequest(
        requestOptions
      );
      //appendnew_next_sd_ZiFaPW0EqQD0pkZz
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ZiFaPW0EqQD0pkZz');
    }
  }

  async sd_ecdJVpopAZSBHk2P(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'appointment/' +
        bh.input.appointmentid;

      bh = await this.sd_18s4Qpzo2AmKOQUm(bh);
      //appendnew_next_sd_ecdJVpopAZSBHk2P
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_ecdJVpopAZSBHk2P');
    }
  }

  async sd_18s4Qpzo2AmKOQUm(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'put',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.input.appointmentData
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_18s4Qpzo2AmKOQUm
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_18s4Qpzo2AmKOQUm');
    }
  }

  async sd_MiooVeaUQBAl6ZwZ(bh) {
    try {
      bh.url =
        bh.system.environment.properties.ssdURL +
        'appointment/' +
        bh.input.appointmentid +
        '?';

      if (bh.input.contactid) {
        bh.url += 'contactid=' + bh.input.contactid;
      }

      bh.url += '&send_email=' + bh.input.send_email;

      bh = await this.sd_3sskQgx28j6VcZKb(bh);
      //appendnew_next_sd_MiooVeaUQBAl6ZwZ
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MiooVeaUQBAl6ZwZ');
    }
  }

  async sd_3sskQgx28j6VcZKb(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'delete',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: bh.body
      };
      bh.local.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_sd_3sskQgx28j6VcZKb
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_3sskQgx28j6VcZKb');
    }
  }

  async sd_MPihcjstyNgoURvS(bh) {
    try {
      bh.url = bh.system.environment.properties.ssdURL + 'room/id';
      bh = await this.getRoomIDReq(bh);
      //appendnew_next_sd_MPihcjstyNgoURvS
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_MPihcjstyNgoURvS');
    }
  }

  async getRoomIDReq(bh) {
    try {
      let requestOptions = {
        url: bh.url,
        method: 'get',
        responseType: 'json',
        reportProgress: undefined,
        headers: {},
        params: {},
        body: undefined
      };
      bh.input.result = await this.sdService.nHttpRequest(requestOptions);
      //appendnew_next_getRoomIDReq
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_jqKFCe48qbur67rM');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_appointmentservice_Catch
}
