/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { tenant_logo_serviceService } from 'app/services/tenant_logo_service/tenant_logo_service.service';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, throwError } from 'rxjs';
import { NPubSubService } from 'neutrinos-seed-services';
import { titleService } from 'app/services/title/title.service';
import { labelService } from 'app/services/label/label.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-bltoolbar',
    templateUrl: './bltoolbar.template.html',
    styleUrls: ['./bltoolbar.style.scss']
})

export class bltoolbarComponent extends NBaseComponent implements OnInit {
    @Output() openMenu = new EventEmitter();
    ssdURL = environment.properties.ssdURL;
    tenantIcon = `${this.ssdURL}tenant-icon`;
    showMainLogo = true;
    showneutrinosLogo = false;
    logoSubscription: Subscription;
    selectedTitle = 'CONTACTS';
    private actionSub;
    
    constructor(private router: Router,
    private pubsub: NPubSubService,
        private tenantLogoService: tenant_logo_serviceService,
        public titleservice: titleService,
        public label: labelService) {
        super();
    }

    ngOnInit() {
        this.checkTenantLogoExist();
        this.logoSubscription = this.pubsub.$sub('refresh-logo', () => {
            this.checkTenantLogoExist();
        });
        
    }

    private checkTenantLogoExist() {
        this.tenantLogoService.checkTenantLogoExist()
            .subscribe(() => {
            }, resObj => {
                if (resObj.status !== 200) {
                    this.showneutrinosLogo = true;
                    this.showMainLogo = false;
                }
            });
    }
    menuClicked() {
        this.openMenu.emit();
    }

    navigateCalendar() {
        this.router.navigate(['home/calendar'])
    }

    ngOnDestroy() {
        if (this.actionSub) {
            this.actionSub.unsubscribe();
        }
    }
}
