/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Deal } from 'app/components/dealsComponent/deals.component';
import { Pipe, PipelineStatus } from 'app/components/pipelineComponent/pipeline.component';
import { cacheservice } from 'app/sd-services/cacheservice';
import { dealservice } from 'app/sd-services/dealservice';
import { externallinkservice } from 'app/sd-services/externallinkservice';
import { fileservice } from 'app/sd-services/fileservice';
import { navservice } from 'app/sd-services/navservice';
import { notesservice } from 'app/sd-services/notesservice';
import { pipelineservice } from 'app/sd-services/pipelineservice';
import { tenantservice } from 'app/sd-services/tenantservice';
import { udfservice } from 'app/sd-services/udfservice';
import { userservice } from 'app/sd-services/userservice';
import { dialogService } from 'app/services/dialog/dialog.service';
import { labelService } from 'app/services/label/label.service';
import { environment } from 'environments/environment';
import { NPubSubService } from 'neutrinos-seed-services';
import { Subscription } from 'rxjs';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { deleteService } from 'app/services/delete/delete.service';
import { emailService } from 'app/services/email/email.service';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-dealdetails',
    templateUrl: './dealdetails.template.html'
})

export class dealdetailsComponent extends NBaseComponent implements OnInit, OnDestroy {
    routeSub: Subscription;
    dealid;
    contactid;
    dealdetails: Deal;
    pipeline = [];
    selectedIndex = 0;
    documentArr = [];
    private documentSub;
    canDelete = false;
    emails: any = [];

    pipelinestatuses: Array<PipelineStatus> = [
        {
            label: 'Won',
            value: 'Won',
            won_status: true
        },
        {
            label: 'Ongoing',
            value: 'Ongoing',
        },
        {
            label: 'Lost',
            value: 'Lost',
            lost_status: true
        }
    ];
    selected_pipe_name;
    selected_status;
    summaryConfig;
    psSub: Subscription;
    notes = [];
    notesData;
    pinnednotesResult;
    unpinnednotesResult;
    dealValue: any = {};
    externallinks = [];
    isComponentDestroyed = false;
    ownerid;
    shownotes = false;
    selectedPipe;
    watcher: Subscription;
    activeMediaQuery = '';
    panelView;

    // Access Control
    accessDenied = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dealService: dealservice,
        private pipelineservice: pipelineservice,
        private udfService: udfservice,
        private cacheService: cacheservice,
        private pubsub: NPubSubService,
        private notesService: notesservice,
        private dialog: dialogService,
        private externallinkService: externallinkservice,
        public label: labelService,
        public user: userservice,
        private navService: navservice,
        private cdr: ChangeDetectorRef,
        private fileservice: fileservice,
        private mediaObserver: MediaObserver,
        private snackBar: MatSnackBar,
        private deleteService: deleteService,
        private emailservice: emailService
    ) {
        super();
        this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;
        });
    }

    ngOnInit() {
        this.routeSub = this.activatedRoute.paramMap.subscribe((result) => {
            this.dealid = Number(result.get('dealid'));
            this.contactid = Number(result.get('contactid'));
            this.getDeal();
            this.getDocuments();
            this.externallinkService.getExternalLinks('deal', this.dealid)
                .then((result: {
                    input: {},
                    local: {
                        result: {
                            result: any,
                            status: number
                        }
                    }
                }) => {
                    if (result.local.result && result.local.result.result instanceof Array) {
                        this.externallinks = result.local.result.result;
                    }
                })
                .catch(e => { });
        });

        this.pubsub.$pub('actionlist', { list: 'dealDetails', dealid: this.dealid, contactid: this.contactid });

        if (this.pipelineservice.pipeData?.deal?.pipes?.length) {
            this.pipeline = this.pipelineservice.pipeData?.deal?.pipes;
        } else {
            console.log('No pipeline was configured for this tenant or deal module');
        }
        this.psSub = this.pubsub.$sub('refreshdealsummary', () => {
            this.getDeal();
        })

        this.documentSub = this.pubsub.$sub('refreshdocumentlist', () => {
            this.getDocuments();
        })

        this.getNotes();
        this.getNewEmail();
    }


    ngAfterViewInit() {

    }

    getDeal() {
        this.dealService.getDealById(this.dealid, this.contactid)
            .then(async (result: {
                input: {},
                local: {
                    dealsValue: Deal
                }
            }) => {
				/**
				 * `this.isComponentDestroyed` check is there, because,
				 * if the user exits this page befaore this call returns,
				 * cache gets deleted in ngOnDestriy AND THEN, it gets set in this callback
				 */
                if (result.local.dealsValue && !this.isComponentDestroyed) {
                    this.dealdetails = result.local.dealsValue;
                    this.deleteService.deal_ownerid = result.local.dealsValue.contact_owner_id;
                    this.ownerid = result.local.dealsValue.contact_owner_id;
                    this.dealValue['dealId'] = this.dealdetails['dealid'];
                    this.dealValue['dealName'] = this.dealdetails['deal_name'];
                    this.dealValue['contactId'] = this.dealdetails['contactid'];
                    this.dealValue['contactName'] = this.dealdetails['contact_display_name'];
                    this.dealValue['first_name'] = this.dealdetails['contact_first_name'];
                    this.dealValue['last_name'] = this.dealdetails['contact_last_name'];
                    this.selected_pipe_name = this.dealdetails.deal_pipe_name;
                    // TODO: [SDS-408] @paulthomas - Confirm with Nisarg and Keerthish about hardcoding deal status on the UI/DB
                    this.selected_status = this.dealdetails.deal_status || 'Ongoing';

                    this.selectedPipe = this.dealdetails.deal_pipe_name;

                    this.accessCheck();
                    if (!this.accessDenied) {
                        this.cacheService.setc(this.dealValue, 'DEAL_ID_VALUE');
                    }
                    if (this.dealdetails.udf_data instanceof Array && this.dealdetails.udf_data.length) {
                        const all_udfs = JSON.parse(JSON.stringify(this.dealdetails.udf_data));

                        // get refreshed UDF list
                        const newUDFResult: {
                            input: {
                                udf_data: any
                            },
                            local: {}
                        } = await this.udfService.refreshUDFListByDependency(all_udfs);

                        // get summary sections
                        const summarySectionsResult: {
                            input: {},

                            local: {
                                summaryConfig: any
                            }
                        } = await this.udfService.createSummarySections(
                            newUDFResult.input.udf_data,
                            this.selected_status == 'Ongoing' && this.dealdetails.contact_owner_id == this.user.user.srmUserid,
                            this.label.toTitleCase(this.label.deal) + ' Summary'
                        );
                        this.summaryConfig = summarySectionsResult.local.summaryConfig;

                        //////////////////////////////////////////////
                        const dynFormSections: {
                            input: {},
                            local: {
                                dynFormSections: any
                            }
                        } = await this.udfService.createDynFormSections(newUDFResult.input.udf_data);

                        // Caching form sections for EDIT CONTACT
                        this.cacheService.setc({
                            dealid: this.dealid,
                            contactid: this.contactid,
                            sections: dynFormSections.local.dynFormSections,
                            all_udfs
                        }, 'EDIT_DEAL_FORM');
                    }

                    if (this.user.user.srmPermissions.includes('DEAL_DELETE') && (this.user.user.srmUserid == this.ownerid ||
                        (this.user.user.srmPermissions.includes('DEAL_ALL') || this.user.user.srmPermissions.includes('GENERAL_ALL')))) {
                        this.canDelete = true;
                    }
                }
            })
            .catch(e => {
                if (e.status == 417) {
                    this.navService.navToDefaultRoute();
                }
            })
    }

    panelChange(event) {
        this.panelView = event.val;
    }

    accessCheck() {
        if (this.dealdetails.contact_owner_id !== this.user.user.srmUserid) {
            this.accessDenied = true;
            this.deleteService.accessDenied = true;
        } else {
            this.accessDenied = false;
            this.deleteService.accessDenied = false;
        }
        this.cdr.detectChanges();
    }

    updateDealPipe($event: Pipe) {
        if (this.accessDenied) {
            return;
        }

        if ($event.pipe_name === this.selected_pipe_name || this.selected_status !== 'Ongoing') {
            return;
        }
        this.dealService.updateDeal({
            pipeid: $event.pipeid
        }, this.dealid, this.contactid)
            .then(result => {
                this.getDeal();
            })
            .catch(e => {
                if (e.status == 417) {
                    this.navService.navToDefaultRoute();
                }
            });
    }

    statusChange($event) {
        if (this.accessDenied) {
            return;
        }
        this.dealService.updateDeal({
            deal_status: $event.value
        }, this.dealid, this.contactid)
            .then(result => {
                this.getDeal();
            })
            .catch(e => {
                if (e.status == 417) {
                    this.navService.navToDefaultRoute();
                }
            });
    }

    editSummary($event) {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'EDIT_DEAL'
        })
    }

    updatePipe(event) {
        this.pipeline.filter((ele, i) => {
            {
                if (ele.pipe_name == event) {
                    this.updateDealPipe(ele);
                }
            }
        })
    }

    // NOTES
    addNote($event) {
        this.notesService.addNoteByModule('deals', this.dealid, $event.note, 'contacts', this.contactid, $event.pinnedSequence)
            .then(result => {
                this.getNotes();
            })
            .catch(e => {
                if (e.status == 417) {
                    this.navService.navToDefaultRoute();
                }
            })
    }
    getNotes() {
        this.notesService.getAllNotesByModule('deals', this.dealid, 'contacts', this.contactid)
            .then((result: {
                input: {},
                local: {
                    result: {
                        data: any[]
                    }
                }
            }) => {
                if (result.local.result && result.local.result.data instanceof Array) {
                    // Handling Access
                    let data = result.local.result.data;

                    data = data.map(v => {
                        if (v.created_by === this.user.user.srmUserid) {
                            v.accessDenied = false;
                        } else {
                            v.accessDenied = true;
                        }
                        return v;
                    })

                    this.notes = data;
                    this.notesData = this.notes;
                    this.pinnednotesResult = this.notesData.filter(({ pinnedsequence }) => pinnedsequence > 0);
                    this.unpinnednotesResult = this.notesData.filter(({ pinnedsequence }) => pinnedsequence === 0 || pinnedsequence == null);
                    this.notes = [...this.pinnednotesResult, ...this.unpinnednotesResult];
                    this.notes.forEach((note) => {
                        note.ispinned = note.pinnedsequence > 0 ? true : false
                    })
                }
            })
            .catch(e => {
                if (e.status == 417) {
                    this.navService.navToDefaultRoute();
                }
            });
    }
    updateNote($event) {
        this.notesService.updateNoteByidByModule('deals', this.dealid, $event.data.activitynotesid, $event.note, 'contacts', this.contactid)
            .then(result => {
                this.getNotes();
            })
            .catch(e => {
                if (e.status == 417) {
                    this.navService.navToDefaultRoute();
                }
            });
    }
    updatePinnednote($event) {
        this.notesService.updateNoteByidByModule('dealspinnednote', this.dealid, $event.data.activitynotesid, $event.note, 'contacts', this.contactid)
            .then(result => {
                this.getNotes();
            })
            .catch(e => { });
    }
    updateUnpinnednote($event) {
        this.notesService.updateNoteByidByModule('dealsunpinnednote', this.dealid, $event.data.activitynotesid, $event.note, 'contacts', this.contactid, $event.pinnedSequence)
            .then(result => {
                this.getNotes();
            })
            .catch(e => { });
    }
    deleteNote($event) {
        this.notesService.deleteNoteByIdByModule('deals', this.dealid, $event.data.activitynotesid, 'contacts', this.contactid)
            .then(result => {
                this.getNotes();
            })
            .catch(e => {
                if (e.status == 417) {
                    this.navService.navToDefaultRoute();
                }
            })
    }

    contactClicked() {
        this.router.navigate([
            '/home/contacts/' + this.dealdetails.contactid
        ])
    }

    initialCall() {
        if (this.dealdetails.contactid) {
            this.dialog.makeCallDialog({
                modulename: 'deals',
                moduleid: this.dealid,
                contactid: this.dealdetails.contactid
            });
        }
    }

    add_document() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_DOCUMENT'
        })
    }

    notesView() {
        this.shownotes = true;
    }

    backtosummary() {
        this.shownotes = false;
    }

    getDocuments() {
        this.fileservice.getFileByDealid(this.dealid, this.contactid)
            .then((result) => {
                if (result.local.result && result.local.result.data) {
                    this.documentArr = result.local.result.data;
                }
            })
            .catch(e => { })
    }

    downloadDocument(file_id) {
        window.location.assign(`${environment.properties.ssdURL}file/activityfileid/${file_id}?dealid=${this.dealid}`);
        // this.fileservice.downloadDocument(file_id)
        //     .then((res) => {
        //     })
        //     .catch(e => { })
    }

    deleteDeal() {
        this.deleteService.deleteDeal(this.dealid);
    }

    ngOnDestroy() {
		/**
		 * This BS needs to be done because contact details are fetched with a delay.
		 * And, if the user exits this page befaore that call returns,
		 * cache gets deleted here AND THEN, it gets set in the http request's callback 
		 */
        this.isComponentDestroyed = true;
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
        this.cacheService.deletec('DEAL_ID_VALUE');
        if (this.psSub) {
            this.psSub.unsubscribe();
        }

        if (this.documentSub) {
            this.documentSub.unsubscribe();
        }
    }

    openNewEmail() {
        console.log('openNewEmail')
        let emailData = {
            emailType: 'new',
            from_email:'',
            to_Email: '',
            dealId : this.dealValue['dealId']
        }
        this.pubsub.$pub('sidenavformevents', {
            'ACTION': 'open',
            PAGE: 'NEW_EMAIL',
            DATA: emailData
        })
    }
    getNewEmail() {
        let dealId = this.dealValue['dealId']
        this.emailservice.getEmail(dealId).subscribe(res=>{
        console.log("dealdetailsComponent -> getNewEmail -> res", res)
            this.emails = res;
        })
    }
    changeTab(event) {
        if (event.index == 2) {
            this.getNewEmail();
        }
    }

}
