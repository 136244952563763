/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit, OnDestroy } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
import { contactservice } from 'app/sd-services/contactservice';
import { ActivatedRoute, Router } from '@angular/router';
import { notesservice } from 'app/sd-services/notesservice';
import { cacheservice } from 'app/sd-services/cacheservice';
import { NPubSubService } from 'neutrinos-seed-services';
import { udfservice } from 'app/sd-services/udfservice';
import { usernotificationservice } from 'app/sd-services/usernotificationservice'
import { labelService } from 'app/services/label/label.service';
import { userservice } from 'app/sd-services/userservice';
import { navservice } from 'app/sd-services/navservice';
import { fileservice } from 'app/sd-services/fileservice';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { deleteService } from 'app/services/delete/delete.service';

interface Row {
    title: string;
    value: string;
    type?: any;
}

interface Section {
    title: string;
    rows: Row[];
}

interface SummaryConfig {
    title: string;
    sections: Section[];
    showEditButton?: boolean;
}

export interface Contact {
    contact_email_id: string;
    contact_last_updated_at: string;
    contact_owner_id: number;
    contact_owner_name: string;
    contact_owner_phone_country_code: string;
    contact_owner_phone_no: string;
    contact_owner_username: string;
    contact_phone_country_code: string;
    contact_phone_no: string;
    contact_udf: any;
    contactid: number;
    created_by: number;
    display_name: string;
    first_name: string;
    last_name: string;
    tenantid: number;
    udf_data: any;
    updated_at: string;
    labels: any
}

/**
 * field_country_code_field: "basic_details__mobile__country_code"
field_country_code_value: 65
field_is_required: false
field_name: "basic_details__mobile"
field_options: null
field_placeholder: "Mobile"
field_section: "Basic Details"
field_section_name: "basic_details"
field_ui_type: "phonenumber"
 */

interface ContactUDF {
    field_country_code_field: string;
    field_country_code_value: string;
    field_is_required: boolean;
    field_name: string;
    field_options: any;
    field_placeholder: string;
    field_section: string;
    field_section_name: string;
    field_ui_type: string;
    field_value: any;

    // field dependency operations
    dependency_comparator?: string;
    dependency_field_name?: string;
    dependency_field_value?: string;
    update_by_dependency_as?: string;
    ___markedForHidden__?: boolean;
}

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-contactdetails',
    templateUrl: './contactdetails.template.html'
})

export class contactdetailsComponent extends NBaseComponent implements OnInit, OnDestroy {
    private routeParamsSub;
    private ps;
    private documentSub;
    summaryConfig;
    notes;
    notesData;
    pinnednotesResult;
    unpinnednotesResult;
    contactid;
    contact: any = {};
    contactValue: any = {};
    selectedIndex = 0;
    accessDenied = true;
    isComponentDestroyed = false;
    documentArr = [];
    watcher: Subscription;
    activeMediaQuery = '';
    shownotes = false;
    panelView;
    


    constructor(private contactService: contactservice,
        private activatedRoute: ActivatedRoute,
        private notesService: notesservice,
        private cacheService: cacheservice,
        private pubsub: NPubSubService,
        private notify: usernotificationservice,
        private udfService: udfservice,
        public label: labelService,
        public user: userservice,
        private nav: navservice,
        private fileservice: fileservice,
        private mediaObserver: MediaObserver,
        private deleteService: deleteService) {
        super();
        this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change.mqAlias;
        });
    }

    ngOnInit() {
        this.routeParamsSub = this.activatedRoute.params.subscribe((result: { contactid }) => {
            this.contactid = Number(result.contactid);
            if (!isNaN(this.contactid)) {
                this.getContactDetails();
                this.getNotes();
                this.getDocuments();
            }
        })
        this.pubsub.$pub('actionlist', { list: 'contactDetails', contactid: this.contactid });

        this.ps = this.pubsub.$sub('refreshcontactsummary', () => {
            this.getContactDetails();
        })

        this.documentSub = this.pubsub.$sub('refreshdocumentlist', () => {
            this.getDocuments();
        })
    }

    getContactDetails() {
        this.contactService.getContactById(this.contactid)
            .then(async (result: {
                input: {},
                local: {
                    result: {
                        data: Contact
                    }
                }
            }) => {
				/**
				 * `this.isComponentDestroyed` check is there, because,
				 * if the user exits this page befaore this call returns,
				 * cache gets deleted in ngOnDestriy AND THEN, it gets set in this callback
				 */
                if (result.local.result && result.local.result.data && !this.isComponentDestroyed) {
                    this.contact = result.local.result.data;
                    // TODO: to clone or not to clone...that is the question.
                    this.deleteService.contact_ownerid = this.contact.contact_owner_id;
                    this.accessCheck();
                    if (!this.accessDenied) {
                        this.cacheService.setc(JSON.parse(JSON.stringify(this.contact)), 'CONTACT_ID_VALUE');
                    }
                    let all_udfs = this.contact.udf_data;


                    // get refreshed UDF list
                    const newUDFResult: {
                        input: {
                            udf_data: ContactUDF
                        },
                        local: {}
                    } = await this.udfService.refreshUDFListByDependency(all_udfs);

                    // get summary sections
                    const summarySectionsResult: {
                        input: {},
                        local: {
                            summaryConfig: SummaryConfig
                        }
                    } = await this.udfService.createSummarySections(
                        newUDFResult.input.udf_data,
                        !this.accessDenied,
                        'Contact Summary'
                    );
                    this.summaryConfig = summarySectionsResult.local.summaryConfig;

                    //////////////////////////////////////////////
                    const dynFormSections: {
                        input: {},
                        local: {
                            dynFormSections: any
                        }
                    } = await this.udfService.createDynFormSections(newUDFResult.input.udf_data);

                    // Caching form sections for EDIT CONTACT
                    this.cacheService.setc({
                        contactid: this.contactid,
                        sections: dynFormSections.local.dynFormSections,
                        all_udfs
                    }, 'EDIT_CONTACT_FORM');
                    // caching contact labels
                    this.cacheService.setc({
                        contact_labels: this.contact.labels,
                        contactid: this.contactid
                    }, 'CONTACT_LABELS');
                    this.pubsub.$pub('refreshlabelslist');
                }
            })
            .catch(e => {
                if (e.status === 417) {
                    this.nav.navToDefaultRoute();
                }
            });
    }

    panelChange(event) {
        this.panelView = event.val;
    }

    getNotes() {
        // this.notes = [];
        this.notesService.getAllNotesByModule('contacts', this.contactid)
            .then((result: {
                input: {}
                local: {
                    result: {
                        data: any[]
                    }
                }
            }) => {
                if (result.local.result && result.local.result.data instanceof Array) {
                    let data = result.local.result.data;

                    // mapping data for defining access control
                    data = data.map(v => {
                        if (v.created_by !== this.user.user.srmUserid) {
                            v.accessDenied = true;
                        } else {
                            v.accessDenied = false;
                        }
                        return v;
                    })
                    this.notes = data;
                    this.notesData = this.notes;
                    this.pinnednotesResult = this.notesData.filter(({pinnedsequence})=>pinnedsequence>0);
                    this.unpinnednotesResult = this.notesData.filter(({pinnedsequence})=>pinnedsequence === 0 || pinnedsequence == null);
                    this.notes = [...this.pinnednotesResult, ...this.unpinnednotesResult];
                    this.notes.forEach((note)=>{
                    note.ispinned =  note.pinnedsequence > 0 ? true : false
                    })
                }
            })
            .catch(e => { })
    }

    

    notesView() {
        this.shownotes = true;
    }

    backtosummary() {
        this.shownotes = false;
    }

    accessCheck() {
        if (this.user.user.srmUserid != this.contact.contact_owner_id) {
            this.accessDenied = true;
            this.deleteService.accessDenied = true;
        } else {
            this.accessDenied = false;
            this.deleteService.accessDenied = false;
        }
    }

    noteAdded($event) {
        this.notesService.addNoteByModule('contacts', this.contactid, $event.note,'','', $event.pinnedSequence)
            .then(result => {
                this.getNotes();
            })
            .catch(e => { });
    }

    noteDeleted($event: {
        data: {
            activitynotesid: number,
            contactid: number
        }
    }) {
        const oldNotes = JSON.parse(JSON.stringify(this.notes))
        this.notes = this.notes.filter(n => n.activitynotesid !== $event.data.activitynotesid);
        this.notesService.deleteNoteByIdByModule('contacts', this.contactid, $event.data.activitynotesid)
            .then(result => {
                // this.getNotes();
            })
            .catch(e => {
                this.notes = oldNotes;
                this.notify.openSnackbar('Failed to delete the note');
            });
    }

    noteUpdated($event: {
        data: {
            activitynotesid: number,
            contactid: number
        },
        note: string
    }) {
        this.notesService.updateNoteByidByModule('contacts', this.contactid, $event.data.activitynotesid, $event.note)
            .then(result => {
                this.getNotes();
            })
            .catch(e => { });
    }

    pinnednoteUpdated($event: {
        data: {
            activitynotesid: number,
            contactid: number,
        },
        note: string
    }) {
        this.notesService.updateNoteByidByModule('contactpinnednote', this.contactid, $event.data.activitynotesid, $event.note)
            .then(result => {
                this.getNotes();
            })
            .catch(e => { });
    }

    unpinnednoteUpdated($event: {
        data: {
            activitynotesid: number,
            contactid: number,
        },
        note: string,
        pinnedSequence: number
    }){
        this.notesService.updateNoteByidByModule('contactunpinnednote', this.contactid, $event.data.activitynotesid, $event.note,'','',$event.pinnedSequence)
            .then(result => {
                this.getNotes();
            })
            .catch(e => { });
    }

    setSummarySections(contact_udf: Array<ContactUDF>) {
        if (contact_udf instanceof Array) {
            const sections: Section[] = [];
            contact_udf.map(udf => {
                const sectionIndex = sections.findIndex(section => {
                    return section.title === udf.field_section;
                });

                if (sectionIndex == -1) {
                    if (udf.field_section) {
                        sections.push({
                            title: udf.field_section,
                            rows: [
                                {
                                    title: udf.field_placeholder,
                                    value: udf.field_value,
                                    type: udf.field_ui_type
                                }
                            ]
                        })
                    }
                } else {
                    sections[sectionIndex].rows.push({
                        title: udf.field_placeholder,
                        value: udf.field_value,
                        type: udf.field_ui_type
                    })
                }
            })

            this.summaryConfig = {
                title: 'Contact Summary',
                sections: sections,
                showEditButton: true
            }
        }
    }

    setDynFormSections(udf_data: Array<ContactUDF>) {
        if (udf_data instanceof Array) {
            let sections = [];
            for (let i = 0; i < udf_data.length; i++) {
                const udf: ContactUDF = udf_data[i];
                const sectionIndex = sections.findIndex(v => {
                    return udf && (udf.field_section === v.field_section);
                })

                if (udf.field_section) {
                    if (sectionIndex == -1) {
                        sections[sections.length] = {
                            field_section: udf.field_section,
                            feild_section_name: udf.field_section_name,
                            fields: [udf]
                        }
                    } else {
                        sections[sectionIndex].fields.push(udf);
                    }
                }
            }

            // caching form sections information to be used in edit-contact form
            this.cacheService.setc({ contactid: this.contactid, sections, udf_data }, 'EDIT_CONTACT_FORM');
        }
    }

    editContact() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'EDIT_CONTACT'
        })
    }

    add_document() {
        this.pubsub.$pub('sidenavformevents', {
            ACTION: 'open',
            PAGE: 'ADD_DOCUMENT'
        })
    }


    refreshActivities() {
        this.pubsub.$pub('refresh-activities', {})
    }

    getDocuments() {
        this.fileservice.getFileByContactid(this.contactid)
            .then((result) => {
                if (result.local.result && result.local.result.data) {
                    this.documentArr = result.local.result.data;
                }
            })
            .catch(e => { })
    }

    downloadDocument(file_id) {
        window.location.assign(`${environment.properties.ssdURL}file/activityfileid/${file_id}?contactid=${this.contactid}`);
        // this.fileservice.downloadDocument(file_id)
        //     .then((res) => {
        //     })
        //     .catch(e => { })
    }

    ngOnDestroy() {
		/**
		 * This BS needs to be done because contact details are fetched with a delay.
		 * And, if the user exits this page befaore that call returns,
		 * cache gets deleted here AND THEN, it gets set in the http request's callback 
		 */
        this.isComponentDestroyed = true;

        if (this.routeParamsSub) {
            this.routeParamsSub.unsubscribe();
        }
        this.cacheService.deletec('CONTACT_ID_VALUE');
        if (this.ps) {
            this.ps.unsubscribe();
        }
        if (this.documentSub) {
            this.documentSub.unsubscribe();
        }
    }
}
export interface ContactLabel {
    labelid: number,
    contact_label: string,
    sort_order: number
}
