<div fxLayout="column " fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" fxFill id="role-sidenav-form">
  <div fxLayout="column wrap" class="sidenav-form-tile" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
      <div fxLayout="column wrap" fxFlex="80" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
          <div class="role-form-icon">
          </div><span class="role-form-title">
          {{mode}} USER</span>
        </div>
      </div>
      <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="center end" [fxShow]="true" [fxHide]="false">
        <mat-icon class="close-button" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="userFormGroup" fxLayout="column" id="form-add-user" class="height-100 sidenav-form-content" (ngSubmit)="update()">
    <div fxLayout="column " class="height-100" fxLayoutGap="0.5rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="0.5rem" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <mat-form-field class="width-100" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="Enter First Name" [required]="false" trim="blur" [formControl]="userFormGroup?.controls.first_name" id="add-user-firstname">
            <mat-error align="start" *ngIf="userFormGroup?.get(&apos;first_name&apos;)?.errors?.required">First Name cannot be empty</mat-error>
            <mat-error align="start" *ngIf="userFormGroup?.get(&apos;first_name&apos;)?.errors?.maxlength">First Name can have a maximum of 50 characters</mat-error>
            <mat-label>First Name</mat-label>
          </mat-form-field>
        </div>
        <div fxLayout="row " fxFlex="50" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <mat-form-field class="width-100" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
            <input matInput placeholder="Enter Last Name" [required]="false" trim="blur" [formControl]="userFormGroup?.controls.last_name" id="add-user-lastname">
            <mat-error align="start" *ngIf="userFormGroup?.get(&apos;username&apos;)?.errors?.required">Last Name cannot be empty</mat-error>
            <mat-error align="start" *ngIf="userFormGroup?.get(&apos;username&apos;)?.errors?.maxlength">Last Name can have a maximum of 50 characters</mat-error>
            <mat-label>Last Name</mat-label>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row " fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <bh-dynphoneno class="width-100" [phoneCountryCodeControl]="userFormGroup.get(&apos;phone_country_code&apos;)" [fc]="userFormGroup.get(&apos;phone_no&apos;)" [required]="true"></bh-dynphoneno>
      </div>
      <div fxLayout="row " fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <mat-form-field class="width-100" [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;" [hideRequiredMarker]="false">
          <input matInput placeholder="Enter User&apos;s Email ID" [required]="true" trim="blur" [formControl]="userFormGroup.controls.email_id">
          <mat-label>Email Address</mat-label>
        </mat-form-field>
      </div>
      <div fxLayout="row " fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false" id="form-add-user_select-role">
        <mat-form-field [appearance]="&apos;legacy&apos;" [floatLabel]="&apos;auto&apos;">
          <mat-select placeholder="Select Role" [required]="true" [formControl]="userFormGroup.controls.roleid">
            <ng-template ngFor let-option [ngForOf]="formBuildData?.roles" let-i="index">
              <mat-option [value]="option?.roleid">{{option?.role_name}}</mat-option>
            </ng-template>
          </mat-select>

        </mat-form-field>
      </div>
      <div fxLayout="row " fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
        <div class="width-100">
          <bh-dynusersearch [placeholder]="&apos;Search Users..&apos;" (resultSelected)="selected($event)" [inputFormControl]="userFormGroup.controls?.reporting_to" [name]="&apos;usersearch&apos;" [filterWith]="filter" #usersearch="dynUserSearch" [label]="&apos;Reports To&apos;"></bh-dynusersearch>
          <mat-chip-list class="form-add-user_reporting-to-chips-list" [selectable]="true" [multiple]="true" #chipList>

            <mat-chip *ngFor="let ru of chipsArrayData; let i = index;" [selectable]="true" (removed)="remove(ru)" [removable]="true" [matTooltip]="ru.email_id + &apos;(&apos; + ru.role_name + &apos;)&apos;">
              {{username(ru)}}
              <mat-icon class style matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <mat-radio-group class="width-100" formControlName="active_status" [required]="false">
          <div fxLayout="row wrap" class="font-normal-size" fxLayoutGap="1rem" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
            <div>
              User Status:</div>
            <mat-radio-button value="active" [required]="true">Active</mat-radio-button>
            <mat-radio-button value="inactive" [required]="true">Inactive</mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="column wrap" fxFlex="4rem" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row " fxLayoutGap="8px" fxLayoutAlign="end stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="primary-form-action-button" [disabled]="addDisabled" type="submit">{{ mode === &apos;EDIT&apos; ? &apos;Update&apos; : &apos;Add&apos; }}</button></div>
        <div fxLayout="column wrap" fxFlex="20" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false"><button mat-button class="secondary-form-action-button" (click)="close()" type="button">Cancel</button></div>
      </div>
    </div>
  </form>
</div>