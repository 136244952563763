/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';
//CORE_REFERENCE_IMPORTS
import { SDBaseService } from '../../app/n-services/SDBaseService';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//append_imports_start

//append_imports_end

declare const window: any;
declare const cordova: any;

@Injectable()
export class usernotificationservice {
  constructor(
    private sdService: SDBaseService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  //   service flows_usernotificationservice

  async openSnackbar(message = '', action = 'Ok', duration = 5000, ...others) {
    try {
      var bh = {
        input: {
          message: message,
          action: action,
          duration: duration
        },
        local: {}
      };
      bh = this.sdService.__constructDefault(bh);
      bh = await this.sd_Nnj7Kx5RlaxqZTpO(bh);
      //appendnew_next_openSnackbar
      return (
        // formatting output variables
        {
          input: {},
          local: {}
        }
      );
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_0zr3bvubmb8VGugB');
    }
  }

  //appendnew_flow_usernotificationservice_Start

  async sd_Nnj7Kx5RlaxqZTpO(bh) {
    try {
      this.matSnackBar.open(bh.input.message, bh.input.action, {
        duration: bh.input.duration,
        direction: 'ltr',
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
      //appendnew_next_sd_Nnj7Kx5RlaxqZTpO
      return bh;
    } catch (e) {
      return await this.errorHandler(bh, e, 'sd_Nnj7Kx5RlaxqZTpO');
    }
  }

  //appendnew_node

  async errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;

    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_usernotificationservice_Catch
}
