<div fxLayout="column " style="height: 100%" class="border-radius card-like" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
  <div fxLayout="column wrap" class="notes-header lr-padding" fxFlex="57px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
    <div fxLayout="column wrap" fxFlex="48px" fxLayoutAlign="center stretch" [fxShow]="true" [fxHide]="false">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " fxLayoutGap="10px" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.gt-xs]="true" (click)="back()"><img imgSrc="Web/Icons/back_icon.svg">
          <h3>
            NOTES</h3>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" [fxHide.xs]="true">
          <h3>
            NOTES</h3>
        </div><button mat-button class="margin-left-1 action-button" matTooltip="Add Note" (click)="newAddNotes()">
          <div class="add-notes-image"></div>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="column " class="circle-padding overflow-hidden height-100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="showNotesEditor">
    <form [formGroup]="addNotesFormGroup" fxLayout="column" class="height-100">
      <div fxLayout="column " class="height-100 notes-text-field" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div fxLayout="row " class="padding-0-1" fxFlex="40px" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false">
          <div fxLayout="row " fxFlex="52" fxLayoutAlign="start center" [fxShow]="true" [fxHide]="false">
            <h5>
              {{notesAction}}
              <h6 class="font-normal-size"></h6>
            </h5>
          </div>
          <div fxLayout="row wrap" fxFlex="48" fxLayoutAlign="end center" [fxShow]="true" [fxHide]="false"><button mat-button class="cursor-pointer image-view-size" matTooltip="Save" (click)="addNewNote()" [disabled]="addNotesFormGroup.invalid">
              <div style="margin-top: -4px;margin-left: -6px" class="save-notess-image">
                <div></div>
              </div>
            </button>
            <div (click)="close()" matTooltip="Cancel" class="close-notes-image cursor-pointer margin-left-1">
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" class="notes-textarea" fxFlex="100" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <mat-form-field>
            <textarea matInput [formControl]="addNotesFormGroup.controls.notes" oninput="this.value = this.value.replace(/^[_\s]*$/, &apos;&apos;)" [required]="true"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div fxLayout="column " class="padding-1 lr-padding tb-padding overflow-y" fxFlex="calc(100% - 60px)" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngIf="notes &amp;&amp; notes.length &gt; 0 &amp;&amp; !showNotesEditor">
    <div fxLayout="column " style="margin-bottom: 12px" class="padding-1 notes-list-gt-xs display-block color-black" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false" *ngFor="let val of notes; let i = index;">
      <div fxLayout="row " fxFlex="40px" fxLayoutAlign="space-between stretch" [fxShow]="true" [fxHide]="false" [ngStyle.md]="&apos;margin-bottom: 5px;&apos;" [ngStyle.sm]="&apos;margin-bottom: 5px;&apos;">
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
          <h5>
            {{val.display_name}}
            <h6 class="font-normal-size">
              {{val.updated_at | date: &apos;dd&apos;}} - {{val.updated_at | date:&apos;MM&apos;}} - {{val.updated_at | date: &apos;yyyy&apos;}}, {{val.updated_at | date: &apos;HH:mm&apos;}}</h6>
          </h5>
        </div>
        <div fxLayout="row " fxFlex="30" fxLayoutAlign="end start" [fxShow]="true" [fxHide]="false">
          <div (click)="togglePin(val)" [ngStyle.gt-xs]="&apos;margin-top: 2px;&apos;" *ngIf="val.ispinned &amp;&amp; !val.accessDenied" matTooltip="Unpin Note" class="filled_pin-image margin-right-1 cursor-pointer">
          </div>
          <div (click)="togglePin(val)" [ngStyle.gt-xs]="&apos;margin-top: 2px;&apos;" *ngIf="!val.ispinned &amp;&amp; !val.accessDenied" matTooltip="Pin Note" class="defalut_pin-image margin-right-1 cursor-pointer">
          </div>
          <div (click)="updateNotes(val)" *ngIf="!val.accessDenied" matTooltip="Edit Note" class="edit-notes-image cursor-pointer">
          </div>
          <div (click)="deleteNote(val)" [ngStyle.gt-xs]="&apos;margin-top: 2px;&apos;" *ngIf="!val.accessDenied" matTooltip="Delete Note" class="close-notes-image cursor-pointer margin-left-1">
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" [fxShow]="true" [fxHide]="false">
        <div style="overflow: hidden; text-overflow: ellipsis;display: -webkit-box; -webkit-line-clamp: 3;-webkit-box-orient: vertical;" class="break-space common-word-wrap">
          {{val.notes}}</div>
      </div>
    </div>
  </div>
  <div fxLayout="column wrap" fxFlex="calc(100% - 60px)" fxLayoutAlign="center center" [fxShow]="true" [fxHide]="false" *ngIf="notes &amp;&amp; notes.length == 0 &amp;&amp; !showNotesEditor">
    <div class="no-notes-image">
    </div>
    <div style="margin-top: 0.5em" class="light-gray-color">
      No notes available.</div>
  </div>
</div>